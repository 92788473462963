import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import * as Lib from './lib'
import { Content } from 'common/components/ui-kit/content'
import { Cards } from './cards'
import { Tabs } from './charts'
import { Progress } from './progress'
import { ProducedProducts } from './producedProducts'
import { ProductionAnalysis } from './productionAnalysis'
import { ProductionLineAnalysis } from './productinLineAnalysis'
import { MobileViewProvider } from './lib/context'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const Home = (): JSX.Element => {
  // const history = useHistory()
  // useEffect(() => {
  //   history.push('/products/list')
  // }, [])

  return (
    <>
      <PanelWrapper tab={['reports', 'reports']} title="Reports">
        <MobileViewProvider>
          <Content padding="0">
            <Lib.S.Container>
              {/* <Cards /> */}
              <Tabs />
              {/* <Progress /> */}
              {/* <ProducedProducts /> */}
              {/* <ProductionAnalysis /> */}
              {/* <ProductionLineAnalysis /> */}
            </Lib.S.Container>
          </Content>
        </MobileViewProvider>
      </PanelWrapper>
    </>
  )
}
export default Home
