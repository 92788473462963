import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Crypto } from 'common/helpers/crypto.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import swal from 'sweetalert'
import * as Lib from './lib'

export class FavoriteService {
  accessToken: string
  endpoint = 'favorite-products'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      FavoriteService.dontAccept()
    }
    if (!user!.accessToken) {
      FavoriteService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async createFavorite(args: Lib.T.Favorite.CreateFavoriteProductArgs): Promise<Lib.T.Favorite.CreateFavoriteProductResponse> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    })
    const { status, data } = response

    if (status !== 201) {
      swal({
        text: 'Something went wrong.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false, favorite: null }
    }
    return { success: true, favorite: data.favoriteProducts }
  }

  async getFavorites(): Promise<Lib.T.Favorite.GetFavoriteProductResponse> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response

    if (status !== 200) {
      swal({
        text: 'Something went wrong.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false, favorite: null }
    }
    return { success: true, favorite: data }
  }

  async deleteFavorite(): Promise<{ success: boolean }> {
    const response = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    })
    const { status } = response

    if (status !== 200) {
      swal({
        text: 'Something went wrong.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true }
  }

  static dontAccept() {
    throw 'not-acceptable'
  }
}
