export const combineStoneSizes = (length: number | string, width?: number | string | null, height?: number | string | null) => {
  if (length > 0) {
    return `${length}${width ? 'x' + width : ''}${height ? 'x' + height : ''}`
  } else {
    return ''
  }
}

export const splitUpStoneSizes = (stoneSize: string | null) => {
  if (!stoneSize) {
    return { length: null, width: null, height: null }
  }
  const splitted = <[string, string?, string?]>stoneSize.split('x')
  return {
    length: splitted[0],
    width: splitted[1] || null,
    height: splitted[2] || null,
  }
}
