import styled from 'styled-components'

export const imageSlider = styled.div`
  height: 100%;
  > div {
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }

  > div > div {
    width: 100%;
    height: 100%;
  }

  > div > div > div {
    width: 100%;
    height: 100%;
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: 5px;
    transition: all 150ms linear;

    &:nth-child(3) > svg {
      transform: rotate(180deg);
    }

    &.true {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  * {
    user-select: none;
  }

  .arrows {
    display: flex;
    width: 100%;
    height: 50px;
    padding-top: 5px;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    align-items: center;
    color: #fff;

    > span {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 5px;
      transition: all 150ms linear;

      &:hover {
        background: #0000000d;
      }

      &:nth-child(3) > svg {
        transform: rotate(180deg);
      }

      &.true {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    > p {
      flex: 1;
      margin: 0;
      text-align: center;

      > span {
        margin: 0 1px;

        &:nth-child(1) {
          font-size: 15pt;
          font-family: var(--f-bo);
        }
        &:nth-child(2) {
          font-size: 13pt;
          font-family: var(--f-se-bo);
        }
        &:nth-child(3) {
          font-size: 13pt;
          font-family: var(--f-se-bo);
        }
      }
    }
  }
`
