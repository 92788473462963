import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import swal from 'sweetalert'
import { Chain } from './lib/types/chains'
import { ChainsService } from './chains'
import { CreateMine } from './lib/types/sub-pieces'

export class SubPiecesService {
  accessToken: string
  subPiece: Lib.T.SubPieces.SubPieces

  constructor(subPiece: Lib.T.SubPieces.SubPieces) {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      SubPiecesService.dontAccept()
    }
    if (!user!.accessToken) {
      SubPiecesService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
    this.subPiece = subPiece
  }

  async edit(id: number, args: Lib.T.SubPieces.EditArgs): Promise<{ success: boolean; data: Chain | null }> {
    const result = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece).concat('/' + id),
      headers: { authorization: this.accessToken },
      data: args,
    })

    if (result.status === 200) {
      return { success: true, data: result.data.chain }
    }
    return { success: false, data: null }
  }

  async create<T>(args: Lib.T.SubPieces.CreateMine) {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece),
      headers: { authorization: this.accessToken },
      data: { ...args },
    })

    const { status } = response

    if (status !== 201) {
      SubPiecesService.unhandled()
      return { success: false, data: null }
    }

    const data = <T>response.data

    return { success: true, data }
  }

  async read<T>(filters?: string): Promise<Lib.T.SubPieces.ReadReturnType<T>> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece).concat('?' + filters ?? ''),
      headers: { authorization: this.accessToken },
    })

    const { status } = response
    if (status !== 200) {
      return { success: false }
    }

    const data = <T>response.data

    return { success: true, data }
  }

  async createStone(
    stoneType: number,
    stoneShape: number,
    stoneSize: number,
    stoneColor: number,
    image: string,
    weight: number,
    price: number,
  ): Promise<Lib.T.SubPieces.CreateStoneReturn> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece),
      headers: { authorization: this.accessToken },
      data: {
        stoneColor,
        stoneShape,
        stoneType,
        stoneSize,
        image,
        weight,
        price,
      },
    })

    const { status, data: _data } = response

    if (status === 403 && _data.message.includes('record')) {
      SubPiecesService.duplicate('taş')
      return { success: false }
    }

    if (status === 400 && _data.message.includes('duplicate')) {
      return swal({
        text: 'Bu taştan bir kez oluşturulmuş.',
        icon: 'error',
        dangerMode: true,
      })
    }

    const data: Lib.T.SubPieces.CreateStoneDate = response.data

    if (status !== 201) {
      return { success: false }
    }

    return {
      success: true,
      stone: data.stone,
    }
  }

  async readStonesByFilter<T>(filter: Lib.T.SubPieces.GetStonesFilter) {
    let queryString = ''
    const filterKeys = Object.keys(filter)
    const filterValues = Object.values(filter)
    filterKeys.forEach((item, index) => {
      if (item && filterValues[index]) {
        queryString += `${item}=${filterValues[index]}&`
      }
    })
    return this.read<T>(queryString)
  }

  async readOneStone(id: number): Promise<Lib.T.SubPieces.ReadOneStoneResult> {
    const { data, status } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece).concat('/' + id),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200 || !data.stone) {
      return { success: false }
    }
    return {
      success: true,
      stone: data.stone,
    }
  }

  async createChain(data: Lib.T.SubPieces.CreateChainArgs): Promise<Lib.T.SubPieces.CreateAndReadChainResult> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece),
      headers: { authorization: this.accessToken },
      data,
    })

    const { status, data: _data } = response

    if (status === 400 && _data.message.includes('title')) {
      SubPiecesService.duplicate('zincir')
      return { success: false }
    }

    if (status !== 201 || !response.data) {
      swal({
        text: 'Zincir oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    const { chain } = <Omit<Lib.T.SubPieces.CreateAndReadChainResult, 'success'>>response.data

    return { success: true, chain }
  }

  async getChainDetails(id: number): Promise<Lib.T.SubPieces.CreateAndReadChainResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece + '/').concat(id.toString()),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response
    if (!status || !data) {
      swal({
        text: 'Seçtiğiniz zincirle ilişkili taşların listesine ulaşılamadı.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, chain: data }
  }

  async delete(id: number): Promise<boolean> {
    const response = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subPiece + '/').concat(id.toString()),
      headers: { authorization: this.accessToken },
    })

    const { status } = response

    if (status !== 200) {
      swal({
        text: 'Seçtiğiniz kayıt silinemiyor.',
        icon: 'error',
        dangerMode: true,
      })
      return false
    }

    return true
  }

  static dontAccept() {
    throw 'not-acceptable'
  }

  static unhandled() {
    swal({
      text: 'Bir şeyler ters gitti, lütfen tekrar deneyin.',
      icon: 'error',
      dangerMode: true,
    })
  }
  static duplicate(item: string) {
    swal({
      text: `Önceden girilmiş bir ${item} yeniden ekleyemezsiniz.`,
      icon: 'error',
      dangerMode: true,
    })
  }
}
