import { useState } from 'react'
import * as Lib from '.'

export const useImageSlider = (imageDetail: Lib.T.ImageDetail[], activeIndex: number) => {
  const [active, setActive] = useState<number>(activeIndex ?? 0)

  const change = (right: boolean) => {
    if (right) {
      setActive(p => (p < imageDetail.length - 1 ? p + 1 : p))
    } else {
      setActive(p => (p > 0 ? p - 1 : p))
    }
  }

  return {
    get: {
      active,
    },
    set: {
      setActive,
    },

    on: {
      change,
    },
  }
}
