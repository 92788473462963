import { Modal } from 'antd'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Content } from 'common/components/ui-kit/content'
import { Icon } from 'common/components/ui-kit/icon'
import { Sticky } from 'common/components/ui-kit/sticky'
import { getQueryParam } from 'common/helpers/getQueryParam'
import { Piece } from 'common/services/lib/types/pieces'
import { useHistory } from 'react-router-dom'
import * as Lib from './lib'
import { useDetails } from './lib/hooks'

function ProductDetailPage() {
  const { get } = useDetails()

  const priceRange = `${get.productDetail?.priceCategory.minPrice} - ${get.productDetail?.priceCategory.maxPrice} `

  const date = new Date(get.productDetail?.creatingDate!)

  const history = useHistory()

  return (
    <PanelWrapper tab={['products', '/products/detail/:id']} title="Ürün detaylar">
      <Sticky>
        <Lib.S.StickyBtns>
          <div className="navigator">
            <Button
              text="Ürün Detaylar"
              mood="accent"
              className="detail"
              onClick={() => history.push(`/products/detail/${getQueryParam()}`)}
            />
            <Button
              text="Teknik Resim"
              mood="accent"
              className="color"
              onClick={() => history.push(`/products/technical/${getQueryParam()}`)}
            />
          </div>
        </Lib.S.StickyBtns>
      </Sticky>
      <Content>
        <Lib.S.Main>
          <div className="image-details">
            <div className="image">
              <img src={process.env.REACT_APP_DOMAIN! + get.productDetail?.productImage} alt="" />
            </div>
            {!get.loading && (
              <div className="details">
                <div className="header">
                  <span className="title">Ürün Detayları</span>
                  <div className="actions">
                    <div
                      onClick={() => {
                        get.handleCopyProduct(getQueryParam(), get.productDetail?.productCode!)
                      }}
                    >
                      <Icon name="copy_outline" size={18} color="#4D4D4D" style={{ cursor: 'pointer' }} />
                    </div>
                    <div onClick={() => window.open(`/products/edit/${getQueryParam()}`, '_blank')}>
                      <Icon name="edit_outline" size={18} color="#4D4D4D" style={{ cursor: 'pointer' }} />
                    </div>
                    <div onClick={() => get.ConfirmModalVisibleHandler()}>
                      <Icon name="trash_bin_outline" size={18} color="#4D4D4D" style={{ cursor: 'pointer' }} />
                    </div>
                  </div>
                </div>
                <div className="body">
                  <Lib.C.TableItem
                    value={[
                      get.productDetail?.productCode,
                      get.productDetail?.weight,
                      priceRange,
                      'design issue',
                      get.productDetail?.designer.name,
                      get.productDetail?.modeler.name,
                      get.productDetail?.supporter.name,
                      date.toLocaleDateString(),
                    ]}
                  />
                </div>
              </div>
            )}
          </div>
          {!get.loading && (
            <div className="pieces">
              {get.productDetail?.pieces.map((pieces: Piece, index) => {
                return <Lib.C.Pieces key={index} type={pieces.type} weight={pieces.weight} image={pieces.pieceImage} />
              })}
            </div>
          )}
        </Lib.S.Main>
        <Modal
          visible={get.isConfirmModalVisible}
          onCancel={get.handleCancelConfirmModal}
          footer={null}
          title={null}
          className="noHeaderModal scrollWidthModal"
        >
          <Lib.S.ConfirmModalContainer>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et </p>
            <div className="btns">
              <Button text="Vazgeç" mood="orang" className="submitButton" callback={() => get.handleCancelConfirmModal()} />
              <Button
                mood="accent"
                className="submitButton"
                text="Onayla"
                callback={() => {
                  get.handleDeleteProduct(getQueryParam())
                }}
                parentStyles={{ marginLeft: '30px' }}
              />
            </div>
          </Lib.S.ConfirmModalContainer>
        </Modal>
      </Content>
    </PanelWrapper>
  )
}

export default ProductDetailPage
