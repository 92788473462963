import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 20px 40px 0 40px;
  .inputs {
    .date-picker {
      .ant-picker {
        height: 55%;
        border-radius: 7px;
      }
    }
  }

  .table {
    margin-top: 30px;
    text-align: center;
    table {
      width: 100%;
      caption-side: bottom;
      border-collapse: collapse;
      background-color: #f8f8f8;

      thead {
        tr {
          border-bottom: 1px solid #000;
          th {
            position: sticky;
            top: 0;
            background-color: #fff;
          }
        }
      }

      tbody {
        background-color: #fff;
        td:first-child {
          padding-left: 5px;
        }

        td:last-child {
          padding-right: 13px;
        }
        tr {
          > a {
            text-align: center;
            color: #000;
            text-decoration: none;
            display: table-cell;
            > td {
              width: 100%;
            }
          }
          border: none;
        }
        tr:nth-child(even) {
          background-color: #e5edec;
        }
      }
    }
  }
`
