import { Icon } from 'common/components/ui-kit/icon'
import { FC } from 'react'
import { Link, useHistory } from 'react-router-dom'
import * as Lib from '.'

export const NotificationList: FC<Lib.T.INotificationListProps> = ({ list, events, loader }) => {
  if (!list.length && !loader) {
    return <NoData />
  }
  return (
    <Lib.S.NotificationsHolder>
      {list.map((notification, index) => {
        const time = events.timeHandler(notification.createdAt)
        return (
          <Notification
            notification={notification}
            time={time}
            key={index}
            analysisNotify={events.analysisNotify}
            deleteHandler={events.deleteNotification}
            updateHandler={events.updateToRead}
          />
        )
      })}
    </Lib.S.NotificationsHolder>
  )
}
const NoData: FC = () => {
  return <Lib.S.EmptyList>herhangi bir bildiriminiz yok.</Lib.S.EmptyList>
}
const Notification: FC<Lib.T.INotificationProps> = ({ notification, deleteHandler, time, updateHandler, analysisNotify }) => {
  const history = useHistory()
  const { href, icon } = analysisNotify(
    notification.body.data.key,
    notification.body.data.id,
    notification.body.notification.body.split(' ')[0],
  )

  return (
    <Lib.S.NotificationView read={notification.read} title={notification.body.notification.details}>
      <span className="read" />

      <div className="header ">
        <div style={{ flex: 3 }}>
          <Link to={href} style={{ textDecoration: 'none' }}>
            <Lib.S.Message onClick={() => history.push(href)}>
              <span className="pe-3 ">
                <Icon name={icon} />
              </span>
              {notification.body.notification.body}
            </Lib.S.Message>
          </Link>
        </div>
        <Lib.S.Time>
          <span>{time}</span>

          {notification.read ? null : (
            <Lib.S.CloseButton
              onClick={() => {
                updateHandler(notification.id)
              }}
            >
              <Icon name="check" size={18} color="var(--accent)" />
            </Lib.S.CloseButton>
          )}
          {/* <Lib.S.CloseButton
            onClick={() => {
              deleteHandler(notification.id)
            }}
          >
            <Icon name="close" size={15} color="var(--accent)" />
          </Lib.S.CloseButton> */}
        </Lib.S.Time>
      </div>
      <div className="description " onClick={() => history.push(href)}>
        {notification.body.notification.description}
      </div>
    </Lib.S.NotificationView>
  )
}
