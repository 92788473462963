import styled from 'styled-components'
import StickyBox from 'react-sticky-box'
import { mediaQueries as mq } from 'common/constants/media-queries'

export const sticky = styled(<any>StickyBox)`
  width: 100%;
  background-color: #f8f8f8;
  border-top-left-radius: 20px;
  height: 70px;
  box-shadow: 0px 5px 7px rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  padding: 0 25px;
  z-index: 18;

  > .title {
    margin: 0;
    flex: 1;
    font-size: 26px;
    font-family: var(--f-bo);
  }

  > .submitButton {
    width: 160px;
  }

  ${mq.max[768]} {
    height: 70px;

    > .title {
      font-size: 20px;
    }

    > .submitButton {
      width: unset;

      > div {
        padding: 0 20px;
      }
    }
  }
`

export const content = styled.div`
  float: left;
  width: 100%;
  padding: 40px 30px;

  > .section {
    float: left;
    height: auto;
  }
`
