import { Popover } from 'antd'
import { FC, useEffect } from 'react'
import * as Lib from '.'
import { Button } from 'common/components/ui-kit/button'
import { imageAddresser } from 'common/helpers/image.helper'
import { DropDown2 } from 'common/components/ui-kit/dropdown2'

export const MonacoChainList: FC<Lib.T.MonacoListProps> = ({ subCategory, selectedMonaco, setSelectedMonaco, fetchTypes }) => {
  return (
    <Lib.S.MonacoListContainer>
      {subCategory.map((category, index) => {
        return (
          <Monaco
            category={category}
            active={category.id === selectedMonaco}
            key={category.id}
            onClick={() => {
              setSelectedMonaco(category.id)
              fetchTypes(category.id)
            }}
          />
        )
      })}
    </Lib.S.MonacoListContainer>
  )
}
export const Monaco: FC<Lib.T.SingleChainProps> = ({ active, category, onClick }) => {
  return (
    <Lib.S.SingleMonaco
      active={active}
      background={process.env.REACT_APP_DOMAIN! + category.productSubCategoryImage}
      onClick={() => onClick()}
    >
      <div className="image"></div>
      <div className="ms-2">
        <div className="title">Monaco Chain</div>
        <div className="type">
          {category.productSubCategory.length > 12 ? (
            <Popover content={category.productSubCategory}>
              <span className="name text-truncate ">{category.productSubCategory}</span>
            </Popover>
          ) : (
            <span className="name text-truncate ">{category.productSubCategory}</span>
          )}
        </div>
      </div>
    </Lib.S.SingleMonaco>
  )
}
export const SelectChainAndLock: FC<Lib.T.SelectChainAndLockProps> = ({
  chainImages,
  lockImages,
  selectChainImage,
  scroll,
  selectLockImage,
}) => {
  return (
    <Lib.S.SelectChainWarper className="mx-auto mt-5">
      <div className="imagesWarper">
        <div
          style={{ height: '100%', marginTop: chainImages.length === 1 ? 200 : chainImages.length <= 3 ? 100 : 'unset' }}
          onScroll={(e: any) => {
            e.preventDefault()
          }}
          onWheel={e => scroll(e.deltaY, 'chain')}
          className="chains"
        >
          {chainImages.map((i, k) => {
            return (
              <div
                className={`chainItems item${chainImages.length === 1 ? k + 2 : chainImages.length <= 3 ? k + 1 : k} chains`}
                key={k}
                onClick={() => {
                  selectChainImage(k)
                }}
              >
                {i}
              </div>
            )
          })}
        </div>
        <div
          className={`locks `}
          style={{ height: '100%', marginTop: lockImages.length === 1 ? 201 : 'unset', marginRight: 28 }}
          onScroll={(e: any) => {
            e.preventDefault()
          }}
          onWheel={e => scroll(e.deltaY, 'lock')}
        >
          {lockImages.map((d, i) => (
            <div
              key={i}
              onClick={() => {
                selectLockImage(i)
              }}
              className={`chainItems item${lockImages.length === 1 ? i + 2 : lockImages.length <= 3 ? i + 1 : i} chainLocks`}
            >
              {d}
            </div>
          ))}
        </div>
      </div>
    </Lib.S.SelectChainWarper>
  )
}

export const AddMonacoProductToCart: FC<Lib.T.AddMonacoProductToCartPropTypes> = ({
  height,
  width,
  pieceWidthHeight,
  handleCountInputs,
  handleSubmitOrder,
  karats,
  handleChangeInputsState,
  handleKaratDropdown,
  handleChangeInputStateForCalcWeight,
  weight,
  ayarId,
  nameLockChain,
  handleCalculateWeight,
  alreadyAddedPieces,
  presentationMode,
  color,
  productWeight,
  language,
  karat,
  forPDF,
  original,
  type
}) => {
  useEffect(() => {
    if (karat?.karatId && handleKaratDropdown) {
      handleKaratDropdown(karat.karatId)
    }
  }, [karat?.karatId])

  return (
    <Lib.S.AddToCartContainer presentationMode={presentationMode} forPDF={forPDF}>
      {/* header */}
      <div className="header">
        <MonacoChainAndLockPicture
          chainImagePath={nameLockChain?.chainImage!}
          lockImagePath={nameLockChain?.lockImage!}
          width={forPDF ? 300 : 500}
          useThumb={forPDF}
        />
        <span className="name">{nameLockChain?.name}</span>
      </div>
      {/* table */}
      <div className="table-wrapper">
        <table>
          <thead>
            <tr className="header-row">
              <th>
                <div className="identifier">
                  <span>{language && language.includes('eng') ? 'Size' : 'Boy'} </span>
                  <span>{language && language.includes('eng') ? 'Width' : 'Genişlık'} </span>
                </div>
              </th>
              {height?.map(size => {
                return (
                  <th className="green-header">
                    <div className="green-header-inner">
                      <span>{size.heightInch}"</span>
                      <span>{size.heightCM}</span>
                    </div>
                  </th>
                )
              })}
              {presentationMode ? (
                <th className="price-identifier">
                  <span>Price</span>
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {width?.map((wid, index) => {
              const price = alreadyAddedPieces?.find(piece => piece?.width?.width === wid)?.price
              return (
                <tr>
                  <td className="orange-identifier">{wid}</td>
                  {height?.map((ht: any) => {
                    const isNotDisabled = pieceWidthHeight?.some(piece => piece.height === ht.heightCM && piece.width === wid)
                    const pieceId = pieceWidthHeight?.filter(piece => {
                      return piece.height === ht.heightCM && piece.width === wid
                    })[0]
                    const alreadyAddedCount = alreadyAddedPieces?.filter(item => {
                      return forPDF ? (forPDF && original ==='true' ? item.id === pieceId?.pieceId : item.parent && item.parent.id === pieceId?.pieceId) : item.id === pieceId?.pieceId
                    })
                    return (
                      <td className="inputs">
                        {presentationMode ? (
                          <div
                            style={{
                              backgroundImage: isNotDisabled ? 'unset' : 'url(/images/png/Transparency.png)',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: '64px 58px',
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {alreadyAddedCount?.length ? alreadyAddedCount[0].count.toString() : ''}
                          </div>
                        ) : (
                          <Lib.S.CustomInput
                            className="input"
                            onKeyDown={event => {
                              if (event.key === 'e' || event.key === '.') event.preventDefault()
                            }}
                            id="inputs"
                            type="number"
                            onFocus={e =>
                              e.target.addEventListener(
                                'wheel',
                                function (e) {
                                  e.preventDefault()
                                },
                                { passive: false },
                              )
                            }
                            placeholder={alreadyAddedCount?.length ? alreadyAddedCount[0].count.toString() : ''}
                            data-pieceId={pieceId?.pieceId}
                            onChange={e => {
                              handleCountInputs && handleCountInputs(e, pieceId?.pieceId, pieceId?.productId)
                            }}
                            disabled={!isNotDisabled || alreadyAddedCount?.length || presentationMode ? true : false}
                            style={{
                              backgroundImage: isNotDisabled ? 'unset' : 'url(/images/png/Transparency.png)',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: '64px 58px',
                            }}
                          />
                        )}
                      </td>
                    )
                  })}
                  {presentationMode ? (
                    <td className="price-identifier">
                      <span style={{ color: 'color' }}>{type ? '' : price ? price + ' $' : ''}</span>
                    </td>
                  ) : null}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {/* presentaion mode details */}
      {!presentationMode ? null : (
        <div className="d-flex mt-2 gap-5">
          <div>
            <span style={{ fontSize: forPDF ? '0.9rem' : '1.1rem', fontWeight: 'bold' }}>
              {language && language === 'eng' ? 'Total weight' : 'Urun Toplam Ağırlığı'}:
              <span style={{ color: 'var(--accent)', fontSize: forPDF ? '0.9rem' : '1.1rem', fontWeight: 'bold' }}>{productWeight}</span>
            </span>
          </div>
          <div>
            <span style={{ fontSize: forPDF ? '0.9rem' : '1.1rem', fontWeight: 'bold' }}>
              {language && language === 'eng' ? 'Color' : 'Renk'}:
              <span style={{ color: 'var(--accent)', fontSize: forPDF ? '0.9rem' : '1.1rem', fontWeight: 'bold' }}>{color}</span>
            </span>
          </div>
        </div>
      )}

      {!handleKaratDropdown ? null : (
        <div className=" footer row align-items-center mt-3">
          <div className="col-md-1"></div>
          <div className="col-md-2 dropdown">
            <DropDown2
              options={karats}
              label="Ayar"
              lessBorder
              onChange={handleKaratDropdown}
              //defaultValue={karat ? karat.karat?.trim() : '14'}
              disabled={!!karat}
              value={ayarId}
            />
          </div>
          <div className="col-md-4 weight d-flex align-items-center">
            {' '}
            <span className="col-lg-6">
              Ağırlık: <span>{weight} gr</span>
            </span>
            <div className="col-lg-4">
              <Button mood="accent" text="Hesapla" callback={handleCalculateWeight} onMouseDown={handleChangeInputStateForCalcWeight} />
            </div>
          </div>
          <div className="col-3"></div>
          {!handleSubmitOrder ? null : (
            <div className="col-md-2">
              <Button
                mood="accent"
                text="Sepete Ekle"
                containerStyles={{ cursor: weight ? 'pointer' : 'not-allowed' }}
                onMouseDown={handleChangeInputsState}
                onMouseUp={() => {
                  if (!weight) return
                  handleSubmitOrder()
                }}
                callback={() => {}}
              />
            </div>
          )}
        </div>
      )}
    </Lib.S.AddToCartContainer>
  )
}

export const MonacoChainAndLockPicture: FC<Lib.T.MonacoChainAndLockPictureType> = ({
  chainImagePath,
  lockImagePath,
  style,
  imageStyle,
  chainStyle,
  lockStyle,
  width,
  onClick,
  stylesForModal,
  useThumb = false,
}) => {
  const calculatedHeight = width! / 7.5
  const calculatedWidth = width! / 5
  const calculatedRight = width! / 6.8

  return (
    <div style={{ ...style, width: width ?? 500, height: stylesForModal ? 100 : 'unset' }} onClick={onClick}>
      <Lib.S.MonacoChainAndLockPicture style={{ marginTop: stylesForModal ? 50 : 'unset' }}>
        <div className="chain" style={chainStyle}>
          <img src={imageAddresser(chainImagePath, useThumb)} alt="" style={imageStyle} />
        </div>
        <div className="lock-wrapper">
          <div
            className="lock"
            style={{
              ...lockStyle,
              height: calculatedHeight,
              width: calculatedWidth,
              right: `-${calculatedRight}px`,
              top: width! <= 200 ? 0 : -3,
            }}
          >
            <img src={imageAddresser(lockImagePath, useThumb)} alt="" style={imageStyle} />
          </div>
        </div>
      </Lib.S.MonacoChainAndLockPicture>
    </div>
  )
}
