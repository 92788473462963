import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { useHistory } from 'react-router-dom'
import * as Lib from './lib'
function NotFound() {
  const history = useHistory()
  return (
    <PanelWrapper tab={['notFound', '/notFound']}>
      <Lib.S.PageWrapper>
        <img src="/images/svg/404.svg" alt="" />
        <p>This page does not exist or was removed! we suggest you go back.</p>

        <Button
          text="Go Back"
          mood="accent"
          className="btn"
          callback={() => {
            history.goBack()
          }}
        />
      </Lib.S.PageWrapper>
    </PanelWrapper>
  )
}

export default NotFound
