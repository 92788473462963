export const typeLocalizer = (text: string) => {
  switch (text) {
    case 'Necklace':
      return 'Kolye';
    case 'necklace':
      return 'Kolye';
    case 'Pendant':
      return 'Kolye Ucu';
    case 'pendant':
      return 'Kolye Ucu';
    case 'Ring':
      return 'Yüzük';
    case 'ring':
      return 'Yüzük';
    case 'Earrings':
      return 'Küpe';
    case 'earrings':
      return 'Küpe';
    case 'Bracelet':
      return 'Bileklik';
    case 'bracelet':
      return 'Bileklik';
    case 'Cuff':
      return 'Kelepçe';
    case 'cuff':
      return 'Kelepçe';
    case 'Ear-rings':
      return 'Küpe';
    case 'ear-rings':
      return 'Küpe';
    default:
      return 'no translation found';
  }
}