import { Piece } from './../../services/lib/types/pieces'
import * as types from 'common/constants/action-types'
import { SavedUser } from 'common/typing/saved-user.interface'

export const saveUser = (payload: SavedUser | null) => {
  return {
    type: types.SAVE_USER,
    payload,
  }
}

export const toggleSidebar = (payload: boolean) => {
  return {
    type: types.TOGGLE_SIDEBAR,
    payload,
  }
}

export const toggleDisableSidebar = (payload: boolean) => {
  return {
    type: types.TOGGLE_DISABLE_SIDEBAR,
    payload,
  }
}

export const fsLoader = (payload: boolean) => {
  return {
    type: types.FULL_SCREEN_LOADER,
    payload,
  }
}

export const setPieceAction = (payload: Piece) => {
  return async (dispatch: any) => {
    dispatch({
      type: types.SET_NEW_CREATED_PIECE,
      payload,
    })
  }
}

export const fetchUnreadNotifications = (payload: number) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: types.GET_UNREAD_NOTIFICATION_SUCCESS,
        payload,
      })
    } catch (e) {
      dispatch({
        type: types.GET_UNREAD_NOTIFICATION_FAIL,
      })
    }
  }
}

export const handleUnapprovedProblems = (payload: number) => {
  return async (dispatch: any) => {
    dispatch({
      type: types.UPDATE_UNAPPROVED_PROBLEMS,
      payload,
    })
  }
}

export const handleSetSocket = (payload: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: types.SET_SOCKET,
      payload,
    })
  }
}

export const handleToggleToast = () => {
  return async (dispatch: any) => {
    dispatch({
      type: types.HANDLE_TOGGLE_TOAST,
    })
  }
}

export const handleSetDate = (payload: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: types.SET_DATE,
      payload,
    })
  }
}
