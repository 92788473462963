import { Gap } from 'common/components/ui-kit/gap'
import { Input } from 'common/components/ui-kit/input'
import { FC } from 'react'
import * as Lib from '.'

export const Contents: FC<Lib.T.ContentsProps> = ({ gramMultiplier, karat, purity, onEnter }): JSX.Element => {
  return (
    <>
      <Input label="Ayar" required onEnter={onEnter} value={karat.val || ''} onInput={evt => karat.set(evt.currentTarget.value)} />
      <Gap mood="H" size={20} />

      <Input label="Milyem" value={purity.val || ''} onEnter={onEnter} onInput={evt => purity.set(evt.currentTarget.value)} />
      <Gap mood="H" size={20} />

      <Input
        label="Gram Çarpanı"
        onEnter={onEnter}
        value={gramMultiplier.val || ''}
        onInput={evt => gramMultiplier.set(evt.currentTarget.value)}
      />
      <Gap mood="H" size={20} />
    </>
  )
}
