import { createContext, useState, useContext } from 'react'

interface IMobileViewContext {
  isMobileView?: number
  setMobileView: (view: number) => void
}

class MobileViewState implements IMobileViewContext {
  constructor(public isMobileView?: number) {}

  setMobileView(view: number) {
    this.isMobileView = view
  }
}

const MobileViewContext = createContext(new MobileViewState())

export const MobileViewProvider: React.FC = ({ children }) => {
  const [isMobileView, setMobileView] = useState<number>(window.innerWidth)
  window.addEventListener('resize', () => setMobileView(window.innerWidth))
  return <MobileViewContext.Provider value={{ isMobileView, setMobileView }}>{children}</MobileViewContext.Provider>
}
export const useMobileView = () => useContext(MobileViewContext)
