import ReactDOM from 'react-dom'
import { App } from './pages'
import { Provider } from 'react-redux'
import store from 'common/store'
import serviceWorker from './serviceWorker'

import './i18n'

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)
serviceWorker()
