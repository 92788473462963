import React from "react";
import { useLocation } from "react-router-dom";

export const getQueryParam = () => {
  return parseInt(window.location.pathname.split('/')!.pop()!)
}
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}