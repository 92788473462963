import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { EmployeesService, LeaveService, LeaveTypeService } from 'common/services'
import { useEffect, useState } from 'react'
import * as Lib from './index'
import { LeaveType } from 'common/services/lib/types/leaveType'
import moment from 'moment'

export const useHistoryLeave = () => {
  const { setLoader } = useLoader()
  const search = window.location.search
  const params = new URLSearchParams(search)
  const employeeId = params.get('id')

  const [fetchAgain, setFetchAgain] = useState(false)
  const [query, setQuery] = useState({ description: '', start: '', end: '', typeId: undefined })
  const [types, setTypes] = useState([{ id: 0, title: '' }])
  const [employee, setEmployee] = useState({})
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const tableColumns = ['Tarih', 'Süre', 'İzin Tipi', 'Durum', 'Açıklama']
  const tableSizes = ['unset', 'unset', 'unset', 'unset', '30%']
  const tableIdIndex = 6

  const getHistoryLeave = async () => {
    if (employeeId) {
      const service = new LeaveService()
      setLoader(true)
      const { success, leaves } = await service.getHistoryLeaveList(parseInt(employeeId), {
        description: query.description,
        start: query.start,
        end: query.end,
        typeId: query.typeId,
      })
      setLoader(false)
      if (success && leaves) {
        setTableData(prevData => [
          ...leaves.map(leave => {
            const duration = leave.type.isHourly && moment.duration(moment(leave.end).diff(moment(leave.start)))
            const hours = duration ? Math.floor(duration.asHours()) : 0
            const minutes = duration ? duration.minutes() : 0
            const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`
            const hourAndMinute = `${hours}:${minutesString}`

            return [
              <div>{moment(leave.start).format('DD-MM-YYYY')}</div>,
              <div>{leave.dayCount === 0 ? hourAndMinute : leave.dayCount + ' gün'}</div>,
              <div>{leave.type.type}</div>,
              <Lib.S.tableStatus type={leave.status}>
                {leave.status === 'approved' ? 'Onaylandı' : leave.status === 'deleted' ? 'Silindi' : leave.status}
              </Lib.S.tableStatus>,
              <div>{leave.description}</div>,
            ]
          }),
        ])
      }
    }
  }

  const getEmployee = async () => {
    if (employeeId) {
      const service = new EmployeesService()
      setLoader(true)
      const { success, employee } = await service.getEmployee({ isManager: true }, parseInt(employeeId))
      setLoader(false)
      if (success && employee) {
        setEmployee(employee)
      }
    }
  }

  const getTypes = async () => {
    const service = new LeaveTypeService()
    const { leaveTypes, success } = await service.getList()
    if (success && leaveTypes) {
      setTypes(() => {
        return leaveTypes.map((item: LeaveType) => ({
          id: item.leaveType_id,
          title: item.leaveType_type,
        }))
      })
    }
  }

  useAsyncEffect(getEmployee)
  useEffect(() => setTableData([]), [fetchAgain])
  useAsyncEffect(getTypes)
  useAsyncEffect(getHistoryLeave, [fetchAgain])

  return {
    val: {
      tableColumns,
      tableSizes,
      tableData,
      tableIdIndex,
    },
    set: {
      setQuery,
      setFetchAgain,
    },
    on: {},
    get: {
      query,
      types,
      employee,
    },
  }
}
