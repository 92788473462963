import { FC } from 'react'
import * as Lib from './lib'

export const Sticky: FC = ({ children }): JSX.Element => {
  return (
    <>
      <Lib.S.sticky>{children && children}</Lib.S.sticky>
    </>
  )
}
