import styled, { keyframes } from 'styled-components'

const loadingRotation = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`

export const paginationLoading = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    margin: 2px 0 0 11px;
  }

  > span {
    display: inline;

    > svg {
      animation: 1s ${loadingRotation} infinite;
    }
  }
`
