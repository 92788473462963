import * as Lib from './lib'
import { FC, Fragment } from 'react'
import { Scrollbar } from 'react-scrollbars-custom'
import { Icon } from '../icon'
import { Link } from 'react-router-dom'
import { Button } from '../button'
import { useRef, useCallback } from 'react'
import { Spin } from 'antd'

export const Table: FC<Lib.T.TableProps> = ({
  columns,
  data,
  footer,
  rowBackColor = true,
  rowBorder,
  unActivatedIndex,
  maxHeight,
  className,
  onRowClick,
  viewButton,
  idIndex,
  disabledIndexes,
  onLoadMore,
  paginate,
  totalData,
  columnSizes,
  subData,
  contentStyle,
  hasMore,
  paginateLoader,
  infiniteScroll,
}): JSX.Element => {
  const observer = useRef<IntersectionObserver | null>(null)

  const lastItem = useCallback(
    node => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && onLoadMore && hasMore && infiniteScroll) {
          onLoadMore()
        }
      })
      if (node) observer.current.observe(node)
    },
    [hasMore],
  )

  return (
    <>
      <Scrollbar style={{ width: '100%', height: maxHeight || 500 }}>
        <Lib.S.container rowBackColor={rowBackColor} rowBorder={rowBorder} className={className}>
          <div className="tableContainer">
            <div className="tr header">
              {columns.map((column, index) => (
                <div className="td header" key={index} style={{ width: columnSizes ? columnSizes[index] : 'unset' }}>
                  {column}
                </div>
              ))}
            </div>

            {paginateLoader && (
              <div className="w-100 text-center">
                <Spin className="mt-3" />
              </div>
            )}

            {data.map((item1, index1) => (
              <Fragment key={index1}>
                {infiniteScroll && data.length === index1 + 1 && paginateLoader ? (
                  <div className="w-100 text-center d-flex justify-content-center position-absolute">
                    <Spin className="mt-3" />
                  </div>
                ) : (
                  <div
                    ref={data.length === index1 + 1 ? lastItem : null}
                    className={`tr body ${unActivatedIndex ? (item1[unActivatedIndex] ? 'disabled' : '') : ''}`}
                    onClick={() => {
                      if (onRowClick) onRowClick(index1)
                    }}
                  >
                    {item1.map((item2, index2) => {
                      if (!disabledIndexes?.includes(index2)) {
                        return (
                          <div className="td" key={index2}>
                            <div className="content" style={contentStyle}>
                              {item2}
                            </div>
                          </div>
                        )
                      }
                    })}
                    {viewButton && idIndex && (
                      <div className="td">
                        <div className="content" style={{ padding: '9px 0' }}>
                          <Link to={`/${viewButton}/${item1[idIndex]}`} className="tableButton">
                            <Icon name="eye" size={20} />
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {subData && subData.length > 0 && subData[index1] && (
                  <Lib.S.innerTableContainer className="div" h={subData[index1].data?.length * 24 + 32}>
                    <div className="details">
                      <div className="gap" />
                      <div className="innerTableContainer">
                        <table className="table">
                          <thead>
                            <tr>
                              {subData[index1].columns.map((i, index) => (
                                <th key={index}>{i}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {subData[index1]?.data?.map((i, index) => (
                              <tr key={index}>
                                {i.map((j, index) => (
                                  <td key={index}>{j}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Lib.S.innerTableContainer>
                )}
              </Fragment>
            ))}

            {!infiniteScroll && paginate && data.length > 0 && (
              <div className="tr body filterContainer">
                <div className="filter td">
                  <Button
                    mood={totalData! / paginate.page <= paginate.limit ? 'gray' : 'accent'}
                    callback={onLoadMore}
                    text={totalData! / paginate.page <= paginate.limit ? 'daha fazla yok' : 'Daha fazla göster'}
                  />
                </div>
              </div>
            )}

            {footer && (
              <div className="tr footer">
                {columns.map((column, index) => (
                  <div className="td footer" key={index}>
                    {column}
                  </div>
                ))}
              </div>
            )}
          </div>
        </Lib.S.container>
      </Scrollbar>
    </>
  )
}
