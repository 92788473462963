import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Crypto } from 'common/helpers/crypto.helper'
import { DOM } from 'common/helpers/dom'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import swal from 'sweetalert'
import * as Lib from './lib'
import { AreaWeightArgs, AreaWeightResponse } from './lib/types/report'

export class ReportService {
  accessToken: string
  endpoint = 'reports/'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      throw ''
    }
    if (!user!.accessToken) {
      throw ''
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async deliveredWeight(args: Lib.T.Order.WeightReportArgs): Promise<{ success: boolean; report: any | null }> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('deliveredWeight')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
      data: args,
    })

    if (status !== 200) {
      return { success: false, report: null }
    }
    return { success: true, report: data.report }
  }

  async customerWeight(args?: Lib.T.Report.CustomerWeightArgs): Promise<Lib.T.Report.CustomerWeightResponse> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('customerWeight')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      return { success: false, report: null }
    }
    return { success: true, report: data.report }
  }

  async categoryWeight(args?: Lib.T.Report.CategoryWeightArgs): Promise<Lib.T.Report.CategoryWeightResponse> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('categoryWeight')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      return { success: false, report: null }
    }
    return { success: true, report: data.report }
  }

  async areaWeight(args?: Lib.T.Report.AreaWeightArgs): Promise<Lib.T.Report.AreaWeightResponse> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('areaWeight')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      return { success: false, report: null }
    }
    return { success: true, report: data.report }
  }

  async subCategoryWeight(args?: Lib.T.Report.SubCategoryWeightArgs): Promise<Lib.T.Report.SubCategoryWeightResponse> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('subCategoryWeight')
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      return { success: false, report: null }
    }
    return { success: true, report: data.report }
  }
}
