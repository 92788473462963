import 'antd/dist/antd.css'
import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { AuthInit } from 'common/components/layouts/auth/auth-init'
import { FullScreenLoader } from 'common/components/ui-kit/full-screen-loader'
import { GET_UNSEEN_MESSAGES_SUCCESS, SET_SOCKET } from 'common/constants/action-types'
import { Routes } from 'common/routes'
import { RootState } from 'common/store/root-reducer'
import { GlobalStyle } from 'common/styles/global.styles'
import 'common/styles/global.styles.css'
import React, { Suspense, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { BrowserRouter } from 'react-router-dom'
import 'react-slidedown/lib/slidedown.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import io from 'socket.io-client'
import 'moment/locale/tr'

export const App: React.FC = (): JSX.Element => {
  const { fsLoader, user, unSeenMessages } = useSelector((state: RootState) => state.globals)
  const dispatch = useDispatch()
  const socketRef = useRef<any>(null)

  useEffect(() => {
    const socket = io(process.env.REACT_APP_DOMAIN!, {
      transports: ['websocket'],
      auth: {
        token: `Bearer ${user.accessToken}`,
      },
    })

    console.log('envs : ', process.env)

    socketRef.current = socket

    dispatch({
      type: SET_SOCKET,
      payload: socket,
    })

    socket.on('connect', () => {
      console.log('Connected! from index')
    })

    // socket.on('new-message', function (data: any, m: any) {
    //   if (window.location.pathname.includes('chat')) return
    //   if (!user.accessToken) return

    //   console.log(data, 'data from index new message')

    //   data.forEach((dt: any) => {
    //     if (dt.messages[0].room.isGroup) {
    //       toast(`${dt.messages[0].room.groupData?.name}  kişisinden mesajınız var`, {
    //         onClick: () => {
    //           window.location.href = `${window.location.origin}/chat?roomId=${dt.roomId}`
    //         },

    //         autoClose: 3000,
    //       })
    //     } else {
    //       toast(`${dt.messages[0].sender.name}  kişisinden mesajınız var`, {
    //         onClick: () => {
    //           window.location.href = `${window.location.origin}/chat?roomId=${dt.roomId}`
    //         },
    //         delay: 3000,
    //         autoClose: 3000,
    //       })
    //     }
    //   })
    // })

    socket.on('exception', function (s: any, m: any) {
      console.log('exception', s)
    })

    socket.on('disconnect', function (s: any, m: any) {
      console.log('disconnect', s)
    })

    return () => {
      socket.disconnect()
    }
  }, [user.accessToken, dispatch])

  useEffect(() => {
    const getUnseenMessages = (data: any, m: any) => {
      data.forEach((dt: any) => {
        dt.messages.forEach((msg: any) => {
          if (msg.sender.id === user.id) return
          if (msg?.seenStatus < 3) {
            dispatch({ type: GET_UNSEEN_MESSAGES_SUCCESS, payload: unSeenMessages + 1 })
          }
        })
      })
    }

    socketRef.current.on('new-message', getUnseenMessages)

    return () => {
      socketRef.current.off('new-message', getUnseenMessages)
    }
  }, [unSeenMessages])

  return (
    <>
      <Suspense fallback={<FullScreenLoader />}>
        <BrowserRouter>
          <ToastContainer position="top-center" />
          {fsLoader && <FullScreenLoader />}
          <AuthInit>
            <GlobalStyle />
            <Routes />
          </AuthInit>
        </BrowserRouter>
      </Suspense>
    </>
  )
}
