import CryptoJS from 'crypto-js'

export class Crypto {
  static encrypt(value: string): string | false {
    try {
      return CryptoJS.AES.encrypt(value, <string>process.env.REACT_APP_SECRET_KEY).toString()
    } catch (error) {
      return false
    }
  }

  static decrypt(value: string): string | false {
    try {
      return CryptoJS.AES.decrypt(value, <string>process.env.REACT_APP_SECRET_KEY!).toString(CryptoJS.enc.Utf8)
    } catch (error) {
      return false
    }
  }

  static encryptedToUrl(encryptedValue: string) {
    return encryptedValue
      .replace(/\//g, '[{FORWARD_SLASH_SIGN}]')
      .replace(/&/g, '[{AMPERSAND_SIGN}]')
      .replace(/=/g, '[{EQUALS_SIGN}]')
      .replace(/\?/g, '[{QUESTION_SIGN}]')
      .replace(/\+/g, '[{PLUS_SIGN}]')
      .replace(/-/g, '[{MINUS_SIGN}]')
      .replace(/\\/g, '[{BACK_SLASH_SIGN}]')
  }

  static UrlToEncrypted(url: string) {
    return url
      .replace(/\[{FORWARD_SLASH_SIGN}]/g, '/')
      .replace(/\[{AMPERSAND_SIGN}]/g, '&')
      .replace(/\[{EQUALS_SIGN}]/g, '=')
      .replace(/\[{QUESTION_SIGN}]/g, '?')
      .replace(/\[{PLUS_SIGN}]/g, '+')
      .replace(/\[{MINUS_SIGN}]/g, '-')
      .replace(/\[{BACK_SLASH_SIGN}]/g, '\\')
  }
}
