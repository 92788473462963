import styled from 'styled-components'

export const Container = styled.div`
  label {
    color: #fff;
    input {
      border-width: 0 !important;
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: #fff;
    }
  }

  .button {
    z-index: 2;
    position: relative;
  }
`

export const topDescription = styled.div`
  float: left;
  width: 100%;
  position: relative;
  z-index: 100;
  height: auto;
  text-align: center;
  color: #fff;
  > p {
    font-size: 18px;
    margin: 0 0 10px 0;
  }

  > span {
    margin: 0 0 25px 0;
    display: block;
  }
`

export const counter = styled.div`
  float: left;
  width: 100%;
  height: auto;
  text-align: center;
  margin: 20px 0 50px 0;
  color: #fff;
  > div {
    display: block;

    > span {
      margin-right: 20px;
    }
  }
`

export const link = styled.div`
  float: left;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 100;
  text-align: center;
  margin-top: 30px;
  color: #fff;

  > p {
    float: left;
    width: 100%;
    margin: 0;

    > span {
      font-family: var(--f-bo);
      cursor: pointer;
    }
  }
`
