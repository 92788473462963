import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { Crypto } from 'common/helpers/crypto.helper'
import { SavedUser } from 'common/typing/saved-user.interface'
import { SignedInUserData } from 'pages/public/login/lib/typing'
import * as Lib from './lib'
import swal from 'sweetalert'
import { toInteger } from 'lodash'

export class AuthenticationService {
  username: string = ''
  password: string = ''
  firebaseToken: string | void | undefined = ''
  constructor(username: string, password: string, firebaseToken: string) {
    this.username = username
    this.password = password
    this.firebaseToken = firebaseToken
  }

  signIn() {
    return ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat('auth/login'),
      data: {
        username: this.username,
        password: this.password,
        fcmtoken: this.firebaseToken,
      },
    })
  }

  static async getNewAccessToken() {
    const user = Storage.get<SavedUser>('user')
    if (!user) {
      this.logOut()
    }
    const { refreshToken, accessToken } = user!
    if (!refreshToken || !accessToken) {
      this.logOut()
    }
    // const decryptedRefreshToken = Crypto.decrypt(refreshToken)
    // const decryptedAccessToken = Crypto.decrypt(accessToken)
    if (!refreshToken || !accessToken) {
      this.logOut()
    }
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat('tokens/getNew'),
      headers: { authorization: accessToken },
      data: { refresh_token: refreshToken },
    })

    const { status } = response
    const data: Omit<SignedInUserData, 'refresh_token'> = response.data
    if (status !== 201 || !data) {
      this.logOut()
    }
    const { access_token } = data
    if (!access_token) {
      this.logOut()
    }
    //const encryptedAccessToken = <string>access_token
    return access_token
  }

  async verify(code: string, fcmtoken: string, OTP: string): Promise<Lib.T.Auth.VerifyReturnType> {
    if (!code || code.length < 4) {
      swal({
        icon: 'error',
        dangerMode: true,
        text: 'Hatalı doğrulama kodu. Lütfen tekrar deneyin veya yeni bir tane oluşturun.',
      })
      return { success: false }
    }
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat('auth/verify-code'),
      data: {
        //username: this.username,
        //password: this.password,
        code: toInteger(code),
        OTPToken: OTP,
        fcmtoken,
      },
    })
    const { status, data } = response
    if (status === 400) {
      swal({
        icon: 'error',
        dangerMode: true,
        text: 'Hatalı doğrulama kodu. Lütfen tekrar deneyin veya yeni bir tane oluşturun.',
      })
    }
    if (status !== 201 || !data.access_token) {
      return { success: false }
    }
    return {
      success: true,
      data,
    }
  }

  static async logOut(firebaseToken?: string | void | undefined) {
    const user = Storage.get<SavedUser>('user')
    const { refreshToken } = user!

    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat('auth/logout'),
      data: {
        fcmtoken: firebaseToken,
      },
      headers: { authorization: `Bearer ${refreshToken}` },
    })
    const { status } = response

    if (status === 201) {
      localStorage.clear()
      window.location.href = '/authenticate/login'
    }

    return
  }
}
