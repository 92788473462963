import styled from 'styled-components'

export const threeInputContainer = styled.div`
  float: left;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-end;

  > div {
    flex: 1;

    input {
      text-align: center;
      border-width: 1px !important;
    }
  }

  > span {
    width: 40px;
    display: inline-block;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    padding: 7px 0 0 0;
  }
`
