import styled from 'styled-components'

export const PageWrapper = styled.section`
  /* display: flex; */
  padding: 20px 0;
  width: 100%;
  column-gap: 10px;
  row-gap: 50px;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  font-size: 11px;
  background-color: #fff;

  .item {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    align-self: start;
    position: relative;

    .logo {
      position: absolute;
      top: 300px;
      right: 26px;
      width: 169px;
      height: 132.39px;
      img {
        width: 100%;
        object-fit: contain;
        opacity: 0.6;
        height: 100%;
      }
    }

    .image-wrapper {
      .image {
        width: 300px;
        height: 300px;
        border: 1px solid #000;
        border-radius: 12px;

        img {
          width: 100%;
          border-radius: 12px;
          height: 100%;
        }
      }

      .code {
        display: flex;
        margin-top: 5px;
        justify-content: space-between;
        span {
          color: var(--accent);

          font-weight: bold;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      height: 120px;

      gap: 5px;
      span {
        color: #000;

        font-weight: bold;
      }
    }
  }

  .start {
    justify-self: start;
  }

  .end {
    justify-self: end;
  }
`
