import styled from 'styled-components'

import { mediaQueries as mq, createQuery as cq } from 'common/constants/media-queries'

const FILTER_WIDTH = '250px'
const FILTER_PADDING = '0 20px 0 0'

export const container = styled.div``

export const topBar = styled.div`
  float: left;
  width: 100%;
  display: flex;

  ${mq.max[768]} {
    display: block;
  }

  > .searchSection {
    flex: 1;
    height: 0;

    ${mq.max[768]} {
      width: calc(100% + 8px);
      float: left;
      padding: 0;
      margin: 0 0 0 -8px;
    }

    > .header {
      float: left;
      width: 100%;
      display: flex;

      > span {
        display: inline-block;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        cursor: pointer;
        transition: all 150ms linear;

        &:hover {
          background: #f0f0f0;
        }
      }

      > div {
        flex: 1;
        padding: 0 0 0 10px;
        position: relative;

        input {
          border: none;
          border-radius: 15px;
          height: 40px;

          & + span {
            height: 40px;
            padding: 4px 0 0 0;
          }
        }

        .filterButton {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 40px;
          right: 4px;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          display: none;

          ${mq.max[768]} {
            display: flex;
          }
        }
      }
    }
  }

  > .filterSection {
    width: ${FILTER_WIDTH};
    cursor: pointer;

    ${mq.max[768]} {
      display: none;
    }

    > .title {
      float: left;
      width: 100%;
      display: flex;

      > span {
        width: 30px;
        height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
      }

      > p {
        flex: 1;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
      }
    }
  }
`

export const contentBody = styled.div`
  display: flex;
  float: right;
  flex-direction: row-reverse;
  width: 100%;

  ${mq.max[768]} {
    display: block;
    float: right;
  }
`

export const filter = styled.div<{ visibility: boolean }>`
  width: ${FILTER_WIDTH};
  display: ${({ visibility }) => (visibility ? 'block' : 'none')};

  > .buttons {
    float: left;
    width: 100%;
  }

  > .mobileTitle {
    position: fixed;
    top: 0;
    left: 0;
    font-weight: 100;
    height: 50px;
    background: #f8f8f8;
    width: 100%;
    display: none;
    align-items: center;

    > span {
      display: inline-block;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > p {
      flex: 1;
      margin: 0;
      text-align: center;
      padding-right: 50px;
    }
  }

  ${mq.max[768]} {
    width: 100%;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 10;
    background: #f8f8f8;
    max-height: calc(100vh - 50px);
    overflow: auto;
    padding: 15px 20px 50px 20px;

    > .mobileTitle {
      display: flex;
    }
  }
`

export const content = styled.div<{ visibility: boolean }>`
  flex: 1;
  padding: ${({ visibility }) => (visibility ? FILTER_PADDING : 0)};

  ${mq.max[768]} {
    width: 100%;
    float: right;
    padding: 0;
    margin-top: 10px;
  }

  > .tableContainer {
    float: left;
    width: 100%;
    margin: 10px 0 0 0;
    border-radius: 15px;
    padding: 0;

    .body {
      > .td {
        :first-child {
          background-color: #fff !important;
        }
      }
    }

    .tableContainer {
      padding: 0 0px;

      .filter {
        border: none;
        background: #f8f8f8 !important;
      }
    }

    > p {
      margin: 0;
      padding: 5px 0 15px 25px;
      font-size: 19px;
      color: #252733;
      font-family: var(--f-se-bo);
    }
  }
`

export const verticalRadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  direction: rtl;

  > .pagination {
    > div {
      flex-direction: row-reverse;
      > p {
        direction: ltr;
      }
    }
  }

  &.radio {
    direction: ltr;

    > div {
      flex-direction: row-reverse;
      padding: 9px 9px 9px 4px;

      > span {
        width: 15px;
        height: 15px;
      }
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    justify-content: space-between;

    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      direction: ltr;
      max-width: calc(100% - 30px);
    }
  }

  .ant-checkbox-group-item {
    margin-right: 8px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #004e42;
    border-color: #004e42;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #004e42 !important;
  }

  .ant-checkbox-wrapper::after {
    display: none;
  }

  > div {
    padding: 9px 4px;

    &:not(:last-child) {
      border-bottom: 1px solid #ededed;
    }

    > p {
      font-size: 16px;
      color: #4d4d4d99;
    }

    > span {
      border-color: #4d4d4d99;
    }
  }

  > div > label {
    padding: 9px 4px;

    &:not(:last-child) {
      border-bottom: 1px solid #ededed;
    }

    > p {
      font-size: 16px;
      color: #4d4d4d99;
    }

    > span {
      border-color: #4d4d4d99;
    }
    .more {
      text-align: center;
    }
  }
`

export const sliderContainer = styled.div`
  padding: 55px 20px;
`

export const tableImage = styled.div`
  width: 30px;
  height: 30px;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  ${mq.max[992]} {
    width: 60px;
    height: 60px;
  }
`

export const tableButton = styled.span`
  display: block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.effect {
    svg {
      transition: all 150ms linear;
    }

    &.rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &:hover {
    background: #f8f8f8;
  }
`

export const tableButtons = styled.div`
  display: flex;
  align-items: center;
`

export const gridViewContainer = styled.div<{ filterVisibility: boolean }>`
  float: left;
  width: 100%;

  > div {
    width: ${({ filterVisibility }) => (filterVisibility ? '25%' : '20%')};

    ${mq.max[1200]} {
      width: ${({ filterVisibility }) => (filterVisibility ? '33.3333333333%' : '25%')};
    }

    ${mq.max[992]} {
      width: 50%;
    }

    ${cq(850, 'max')} {
      width: 100%;
    }

    ${mq.max[768]} {
      width: calc(100% / 3);
    }

    ${mq.max[576]} {
      width: 50%;
    }

    ${cq(350, 'max')} {
      width: 100%;
    }
  }
`

export const gridView = styled.div`
  float: left;
  padding: 10px;

  > img {
    width: 100%;
    border-radius: 10px;
  }

  > div {
    float: left;
    width: 100%;
    background-color: white;
    margin: 10px 0 0 0;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    overflow: hidden;

    > .top {
      float: left;
      width: 100%;
      border-bottom: 1px solid #00000033;
      display: flex;
      padding: 5px;
      font-size: 9pt;

      > p {
        margin: 0;
        flex: 1;
        font-family: var(--f-bo);
      }

      > span {
        font-family: var(--f-bo);
        border-left: 1px solid #00000033;
        padding-left: 15px;
      }
    }

    > .bottom {
      float: left;
      width: 100%;
      display: flex;
      padding: 5px;
      font-size: 11pt;

      > p {
        margin: 0;
        flex: 1;
        font-family: var(--f-bo);
      }

      > span {
      }
    }

    > .gridViewTopRightShape {
      position: absolute;
      top: 0;
      right: 0;
    }

    > .gridViewBottomLeftShape {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
`

export const modal = styled.div<{ priceCondition: boolean }>`
  float: left;
  width: 100%;
  height: auto;
  min-width: 912px;

  > .header {
    float: left;
    width: 100%;
    padding: 0 17%;

    > div {
      float: left;
      width: calc(100% / ${({ priceCondition: pc }) => (pc ? 3 : 2)});
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      border-bottom: 1px solid #b7b7b759;

      > p {
        float: left;
        height: 100%;
        margin: 0;
        text-align: left;

        &.key {
          color: black;
          margin: 0 10px 0 0;
        }

        &.value {
          color: var(--accent);
          font-family: var(--f-se-bo);
        }
      }
    }
  }

  .tableContainer {
    float: left;
    width: 100%;
    margin-top: 20px;

    td > .DD {
      > div {
        max-width: 100px;
      }

      .dropdownContainer {
        height: 45px;
        border: 1px solid rgba(0, 78, 66, 0.32) !important;
      }

      .icon {
        width: 25px;
      }

      .optionsContainer {
        > span {
          padding: 0 5px;
          font-size: 14px;
        }
      }
    }

    .price {
      height: 45px;
      text-align: center;
      border: 1px solid rgba(0, 78, 66, 0.32) !important;
      border-radius: 5px;
      background: rgba(0, 78, 66, 0.01);
    }

    .explain {
      background-color: #f8f8f8;
      text-align: center;
      border: none;
      min-height: 45px;
      width: 244px;
      padding: 7px;
      height: 45px;
    }

    td {
      text-align: center;
    }

    tbody {
      tr:nth-child(1) {
        td {
          padding-top: 14px;
        }
      }

      td {
        border: none;
        padding: 3px 4px;
      }

      tr {
        > td:nth-child(1) {
          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 45px;
            border: 1px solid rgba(0, 78, 66, 0.32) !important;
            border-radius: 7px;
          }
        }
      }
    }
  }

  .footer {
    float: left;
    width: 100%;
    height: auto;
    padding-top: 10px;

    > div {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      float: left;
      padding: 0 7px;

      &:nth-child(2) {
        justify-content: flex-start;
      }

      > div {
        width: unset;
        height: unset;

        > div {
          padding: 0 30px;
        }
      }
    }
  }
`

export const detailNumbers = styled.div`
  float: left;
  width: calc(6 * 51px);
  height: 45px;

  > div {
    float: left;
    width: 45px;
    height: 45px;
    margin: 0 3px;

    &:not(.withInput) {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f8f8f8;
      color: black;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;
      transition: all 150ms linear;

      &.true {
        background-color: var(--accent);
        color: white;
      }
    }

    &.withInput {
      > input {
        float: left;
        width: 100%;
        height: 100%;
        font-size: 16px;
        margin: 0;
        padding: 0;
        text-align: center;
        border: 1px solid rgba(0, 78, 66, 0.32);
        border-radius: 5px;
        background: rgba(0, 78, 66, 0.01);
      }
    }
  }

  input.noArrow {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`

export const searchSection = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  gap: 10px;
  > p {
    margin: 0 20px 0 0;
    font-size: 26px;
    font-family: var(--f-bo);
  }

  > div {
    flex: 1;
    max-width: 500px;
    display: flex;
    align-items: center;

    > .inputContainer {
      flex: 1;
      position: relative;

      > .filterButton {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 45px;
        right: 0;
        display: none;
        align-items: center;
        justify-content: center;

        ${mq.max[768]} {
          display: flex;
        }
      }
    }

    > .buttonContainer {
      width: unset;
      margin: 0 0 0 5px;

      > .parent {
        padding: 0;
        min-height: unset;
        width: 45px;
        height: 45px;
      }
    }
  }
`

export const confirmModal = styled.div`
  float: left;
  width: 100%;
  height: auto;

  > p {
    margin: 0;
    float: left;
    width: 100%;
    font-size: 18px;
    text-align: center;
  }

  > div {
    float: left;
    width: 100%;
    text-align: center;
    margin: 30px 0 0 0;

    > div {
      width: unset !important;
      float: none !important;
      display: inline-flex !important;
      padding: 0 40px;
    }
  }
`

export const DetailModalWrapper = styled.div`
  p {
    margin: 0;
  }
  > p {
    text-align: right;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 20px;

    .avatar {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .item {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      padding: 0 20px 0 10px;
      .item-title {
        font-weight: bold;
      }
      .item-content {
        color: var(--accent);
        font-weight: 500;
      }

      &:first-child {
        align-items: start;
      }
      &:not(:last-child) {
        border-right: 1px solid #eee;
      }
    }

    .details {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .top {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      .bottom {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  .employees {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .employee {
      display: flex;
      justify-content: space-between;

      border: 1px solid var(--accent);
      border-radius: 8px;
      padding: 15px;

      .field {
        width: 100%;
      }
      span:first-child {
        font-weight: bold;
      }
    }
  }

  .category-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .categories {
      display: flex;
      gap: 10px;
      background-color: #fafafa;
      padding: 15px;
      border-radius: 8px;
      flex-wrap: wrap;

      .category {
        background-color: var(--accent);
        padding: 5px 45px;
        color: #fff;
        border-radius: 8px;
        &-selected {
          background-color: inherit;
          color: var(--accent);
        }
      }
    }
  }
`

export const RadioGroup = styled('div')<{ withLtr?: boolean }>`
  display: flex;
  flex-direction: column;
  direction: ${({ withLtr }) => (withLtr ? 'ltr' : 'rtl')};

  .radio-wrapper {
    > div {
      margin-right: 10px;
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .ant-checkbox-group-item {
    margin-right: 8px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #004e42;
    border-color: #004e42;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #004e42 !important;
  }

  /* .ant-checkbox + span {
    padding-right: 4px;
    padding-left: 4px;
  } */

  .ant-checkbox-wrapper::after {
    display: none;
  }

  > div {
    &:not(:last-child) {
      border-bottom: 1px solid #ededed;
    }

    > p {
      font-size: 16px;
      color: #4d4d4d99;
    }

    > span {
      border-color: #4d4d4d99;
    }
  }

  > div > label {
    &:not(:last-child) {
      border-bottom: 1px solid #ededed;
    }

    > p {
      font-size: 16px;
      color: #4d4d4d99;
    }

    > span {
      border-color: #4d4d4d99;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      direction: ltr;
      max-width: 126px;
    }
    .more {
      text-align: center;
    }
  }
`
