import React from 'react'
import { Gap } from 'common/components/ui-kit/gap'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from '.'

export const Contents: React.FC<Lib.T.ContentsProps> = ({ height, length, width, onEnter }): JSX.Element => (
  <>
    <Lib.S.threeInputContainer>
      <div>
        <Input
          required
          onEnter={onEnter}
          placeholder="Ölçü"
          value={length.val || ''}
          onInput={evt => length.set(evt.currentTarget.value)}
        />
      </div>
      <span>x</span>
      <div>
        <Input required onEnter={onEnter} placeholder="Ölçü" value={width.val || ''} onInput={evt => width.set(evt.currentTarget.value)} />
      </div>
      <span>x</span>
      <div>
        <Input
          required
          onEnter={onEnter}
          placeholder="Ölçü"
          value={height.val || ''}
          onInput={evt => height.set(evt.currentTarget.value)}
        />
      </div>
    </Lib.S.threeInputContainer>
    <Gap mood="H" size={20} />
  </>
)
