import { Span } from './../../../../../../common/components/ui-kit/table-list/lib/styles'
import { url } from 'inspector'
import styled from 'styled-components'

export const container = styled.div`
  padding-left: 20px;
  .orderContent {
    display: flex;
    justify-content: space-between;
    margin-top: 30px 0;

    @media only screen and (min-width: 1380px) {
      margin: 30px 50px;
    }
    @media only screen and (min-width: 1440px) {
      margin: 30px 100px;
    }
  }
`
export const StickyContainer = styled.div`
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    p {
      font-size: 26px;
      font-family: var(--f-bo);
      margin: 0;
    }
    .left {
      display: flex;
      align-items: center;
      justify-content: start;
      .search-container {
        display: flex;
        align-items: center;
        flex: 1.5;
        margin-left: 35px;
        label {
          width: 250px;
        }
        div:last-child {
          .parent {
            width: 50px;
            min-height: 43px;
            align-items: center;
            margin-left: 10px;
          }
        }
      }
    }
    .right {
      span {
        margin-right: 35px;
      }
    }
  }
`
export const MonacoListContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px;
  row-gap: 17px;
  column-gap: 19.5px;
  justify-content: center;
  margin-top: 20.5px;
  @media only screen and (max-width: 1326px) {
    grid-template-columns: 200px 200px;
  }
`
export const SingleMonaco = styled.div<{ active: boolean; background: string }>`
  display: block;
  width: 200px;
  height: 90px;
  background: ${({ active }) => (active ? '#004E42' : '#ffffff')};
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .image {
    width: 70px;
    height: 70px;
    transform: rotate(-44.68deg);
    margin-left: 10px;
    background-size: cover;
    background-image: url(${({ background }) => background});
    border-radius: 50%;
  }
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    display: flex;
    align-items: center;

    color: ${({ active }) => (active ? '#ffffff' : '#004e42')};
  }
  .type {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    display: flex;
    align-items: center;

    color: ${({ active }) => (active ? '#ffffff' : '#000000')};
  }
  .name {
    width: 100px;
  }
`

export const SelectChainWarper = styled.div`
  width: 1082px;
  height: 508px;

  background: #004e42;
  border-radius: 10px;
  overflow: hidden;
  transition: 1s;
  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
    display: none;
  }
  .imagesWarper {
    display: flex;
    /* grid-template-columns: 80% 20%; */
  }
  /* .chains,
  .locks {
    display: grid;
    width: 100%;
    height: 100%;
    grid-row-gap: 10px;
    grid-template-columns: 100%;
    grid-template-rows: 100px 100px 100px 100px 100px;
  } */
  .chains {
    flex: 1;
    height: 550px;
    overflow: auto;
    padding-left: 50px;
  }
  .locks {
    width: 167px;
    padding-right: 35px;
    transform: translateX(-57px);
  }
  .item0 {
    transform: scale(0.7);
    opacity: 0.5;
    &:hover {
      transform: scale(0.75);
    }
  }

  .item1 {
    transform: scale(0.8);
    opacity: 0.5;
    &:hover {
      transform: scale(0.85);
    }
  }
  .item2 {
    transform: none;
    opacity: 1 !important;
  }
  .item3 {
    transform: scale(0.8);
    opacity: 0.5;
    &:hover {
      transform: scale(0.85);
    }
  }
  .item4 {
    transform: scale(0.7);
    opacity: 0.5;
    &:hover {
      transform: scale(0.75);
    }
  }

  .chainLocks {
    width: 167px;
    height: 100px;
    & > img {
      width: 100%;
      height: 100% !important;
      border-radius: 10px;
      cursor: pointer;
    }
  }

  .chains {
    width: 836px;
    height: 100px;
    & > img {
      width: 100%;
      height: 100% !important;
      cursor: pointer;
    }
  }

  .chainItems {
    display: flex;
    justify-content: center;
    transition: 0.5s;

    & > img {
      height: 100px;
      cursor: pointer;
    }
  }
  .chainItems.active {
    transform: none;
  }
`
export const chainItems = styled.div<{ index: number }>``

export const AddToCartContainer = styled('div')<{ presentationMode: boolean | undefined; forPDF: boolean | undefined }>`
  width: ${({ presentationMode }) => (presentationMode ? '100%' : 'unset')};

  page-break-inside: avoid;

  .header {
    display: flex;
    padding: 0 50px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    .name {
      font-size: ${({ presentationMode, forPDF }) => (presentationMode && !forPDF ? '1.1rem' : forPDF ? '0.9rem' : '15px')};
      font-weight: ${({ presentationMode }) => (presentationMode ? 'bolder' : 'normal')};
    }

    .image-container {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    span {
      color: #004e42;
      font-size: 1.2rem;
      font-weight: bold;
      margin-left: 4rem;
    }
  }

  /* table styles */

  .header {
    min-width: ${({ presentationMode }) => (presentationMode ? 'unset' : '800px')};
    max-width: ${({ presentationMode }) => (presentationMode ? 'unset' : '966px')};
  }

  .table-wrapper {
    display: flex;
    justify-content: start;
    page-break-inside: avoid;
    table {
      overflow-x: scroll;
      width: ${({ presentationMode }) => (presentationMode ? '100%' : 'unset')};

      overflow-y: scroll;
      border: 1px solid #d9d9d9;
      overflow-x: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      ::-webkit-scrollbar {
        display: none;
      }

      thead {
        .price-identifier {
          background-color: #fff;
          color: var(--accent);
          vertical-align: middle;
          font-size: ${({ presentationMode, forPDF }) => (presentationMode && !forPDF ? '1.1rem' : forPDF ? '0.5rem' : '1rem')};

          text-align: center;
        }

        .header-row {
          th:first-child {
            background-color: #fff !important;
          }
          background-color: #004e42;
        }
        .identifier {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px;
          > span {
            font-size: ${({ presentationMode, forPDF }) => (presentationMode && !forPDF ? '1rem' : forPDF ? '0.5rem' : '12px')};

            font-weight: ${({ presentationMode }) => (presentationMode ? 'bolder' : 'normal')};
          }

          span:first-child {
            color: #004e42;
          }
          span:last-child {
            color: #f0ad4b;
          }
        }

        .green-header {
          color: #fff;
          line-height: 25px;
          position: relative;
          width: ${({ presentationMode }) => (presentationMode ? 'unset' : '45px')};
          border-top: 1px solid var(--accent);
          border-left: 0.1px solid var(--accent);
          border-right: 0.1px solid var(--accent);
          :last-child {
            border-right: 1px solid var(--accent);
          }

          .green-header-inner {
            display: flex;
            flex-direction: column;
            border-right: ${({ forPDF }) => (forPDF ? '1px solid #fff' : 'unset')};
            align-items: center;

            span {
              font-size: ${({ forPDF }) => (!forPDF ? 'unset' : '0.6rem')};
            }
          }
        }

        .green-header:not(:last-child):after {
          content: '';
          position: absolute;
          border-left: ${({ forPDF }) => (forPDF ? 'unset' : '0.5px solid #fff')};

          top: 19%;
          right: 0;
          height: 66%;
          margin-top: auto;
          margin-bottom: auto;
        }
      }

      tbody {
        tr {
          line-height: 14px;
          page-break-inside: avoid;

          @media print {
            .table-wrapper {
              page-break-inside: auto;
            }

            .tbody > tr {
              page-break-inside: auto;
            }
          }

          :nth-child(even) {
            td {
              input {
                background-color: #eaf2f1;
              }
            }
          }
          td {
            text-align: center;
          }
        }

        .price-identifier {
          background-color: #fff !important;
          position: relative;
          height: 25px;
          width: ${({ presentationMode }) => (presentationMode ? '45px' : 'unset')};
          font-size: ${({ presentationMode, forPDF }) => (presentationMode && !forPDF ? '1.1rem' : forPDF ? '0.5rem' : '1rem')};
          font-weight: ${({ presentationMode }) => (presentationMode ? 'bolder' : 'normal')};
          border: 2px solid #000;
          
          span {
            font-size: 14px;
          }
        }

        .orange-identifier {
          background-color: #f0ad4b !important;
          color: #fff;
          position: relative;
          height: 25px;
          width: ${({ presentationMode }) => (presentationMode ? '45px' : 'unset')};
          font-size: ${({ presentationMode, forPDF }) => (presentationMode && !forPDF ? '1.1rem' : forPDF ? '0.5rem' : '1rem')};
          font-weight: ${({ presentationMode }) => (presentationMode ? 'bolder' : 'normal')};
          border: ${({ forPDF }) => (forPDF ? '2px solid #000' : 'unset')};
        }

        ${({ forPDF, presentationMode }) =>
          !forPDF
            ? `
        .orange-identifier:after {
          content: '';
          position: absolute;
          border-bottom: ${forPDF ? 'unset' : '0.5px solid #fff'};
          right: ${presentationMode ? '4px' : '12px'};
          width: ${presentationMode ? '90%' : '66%'};
          top: -1px;
        }`
            : null}
      }

      .inputs {
        border: none;
        border: ${({ presentationMode }) => (presentationMode ? '2px solid #000' : '0.5px solid #000')};
        width: ${({ presentationMode }) => (presentationMode ? '45px' : 'unset')};
        font-size: ${({ presentationMode, forPDF }) => (presentationMode && !forPDF ? '1.1rem' : forPDF ? '0.5rem' : '1rem')};
        font-weight: ${({ presentationMode }) => (presentationMode ? 'bolder' : 'normal')};

        input {
          border: none;
          width: 100%;
          height: 100%;

          :focus {
            border: none;
            outline: none;
          }
        }
        div {
            font-size: 14px;
        }
      }
    }
  }

  .footer {
    padding: 0 50px;
    .dropdown {
      > div {
        display: flex;
        align-items: center;
        > span {
          flex: unset;
          width: unset;
          margin-right: 10px;
          font-size: 16px;

          font-weight: bold;
        }
      }
    }
    .weight {
      span {
        font-weight: bold;
        font-size: 16px;
        > span {
          color: #004e42;
          font-weight: bold;
        }
      }
    }
  }
`

export const CustomInput = styled('input')<{ disable?: boolean }>``

export const MonacoChainAndLockPicture = styled.div`
  width: inherit;
  position: relative;
  display: flex;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .chain {
    width: inherit;
  }

  .lock-wrapper {
    z-index: 2;

    .lock {
      height: 65px;
      z-index: 2;
      width: 100px;
      position: absolute;
      top: -3px;
      right: -74px;
    }
  }
`
