import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import swal from 'sweetalert'
import { DOM } from 'common/helpers/dom'

export class DashboardService {
  accessToken: string
  endpoint = 'dashboard'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      DashboardService.dontAccept()
    }
    if (!user!.accessToken) {
      DashboardService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async getAnnouncementsAndNews(filters?: Lib.T.Dashboard.GetListFilters): Promise<Lib.T.Dashboard.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/announcements`).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || !data.announcements) {
      swal({
        text: 'Grup listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true, announcements: data.announcements, news: data.announcements }
  }

  async getFoodList(): Promise<Lib.T.Dashboard.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/food-menu`),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || (data && !data.foodItems)) {
      swal({
        text: 'Grup listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true, foodList: data }
  }

  async getTodoList(todoListEndpoint: string, filters?: Lib.T.Dashboard.GetListFilters): Promise<Lib.T.Dashboard.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(todoListEndpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || !data.result) {
      swal({
        text: 'Grup listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true, todoList: data.result }
  }

  async putTodoList(todoListEndpoint: string, args: Lib.T.Dashboard.todoListArg, id: number): Promise<Lib.T.Dashboard.ListResult> {
    const response = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(todoListEndpoint).concat(id ? `/${id}` : ''),
      headers: { authorization: this.accessToken },
      data: args,
    })
    const { data, status } = response
    if (id) {
      if (status !== 200) {
        DashboardService.unhandled(Array.isArray(data.message) ? data.message[0] : data.message)

        return { success: false }
      }
    } else {
      if (status !== 201) {
        DashboardService.unhandled(Array.isArray(data.message) ? data.message[0] : data.message)

        return { success: false }
      }
    }

    return {
      success: true,
    }
  }

  async getTodayBirthdays(): Promise<Lib.T.Dashboard.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/today-birthdays`),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || !data) {
      swal({
        text: 'Grup listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true, todayBirthdays: data }
  }

  async getInWeekBirthdays(): Promise<Lib.T.Dashboard.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/in-week-birthdays`),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || !data) {
      swal({
        text: 'Grup listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true, inWeekBirthdays: data }
  }

  async getDrinks(): Promise<Lib.T.Dashboard.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat('automation/restaurant/drink'),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || !data) {
      swal({
        text: 'Grup listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true, drinks: data }
  }

  async putDrink(arg: Lib.T.Dashboard.drinkArg): Promise<Lib.T.Dashboard.ListResult> {
    const response = await ApiCaller({
      method: 'PUT',
      url: process.env.REACT_APP_SERVER_URL!.concat('automation/restaurant/drink'),
      headers: { authorization: this.accessToken },
      data: arg,
    })
    const { data, status } = response
    if (arg) {
      if (status !== 200) {
        DashboardService.unhandled(Array.isArray(data.message) ? data.message[0] : data.message)

        return { success: false }
      }
    } else {
      if (status !== 201) {
        DashboardService.unhandled(Array.isArray(data.message) ? data.message[0] : data.message)

        return { success: false }
      }
    }

    return {
      success: true,
    }
  }

  async getCurrencyPrice(todoListEndpoint: string, filters?: Lib.T.Dashboard.GetListFilters): Promise<Lib.T.Dashboard.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(todoListEndpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || !data.data) {
      swal({
        text: 'Grup listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true, currencies: data.data }
  }

  async getWeather(todoListEndpoint: string, filters?: Lib.T.Dashboard.GetListFilters): Promise<Lib.T.Dashboard.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(todoListEndpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || !data.data) {
      swal({
        text: 'Grup listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true, weather: data.data }
  }

  static dontAccept() {
    throw 'not-acceptable'
  }

  static unhandled(errorMessage?: string) {
    if (errorMessage) {
      return swal({
        text: errorMessage,
        dangerMode: true,
        icon: 'error',
      })
    }
  }
}
