import styled from 'styled-components'

export const Content = styled.div`
  margin-top: 40px;
  & > svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  & > p {
    margin-top: 20px;
    font-weight: bold;
  }
`
