import React from 'react'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Col } from 'reactstrap'
import { List } from 'common/components/ui-kit/list'
import * as Lib from './lib'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Content } from 'common/components/ui-kit/content'

export const BraceletSizes: React.FC = ({}) => {
  const { list, on, size, editSize } = Lib.H.useSize()

  return (
    <>
      <PanelWrapper tab={['options', '/options/product-codes']} title="Seçenek Oluşturma">
        <Sticky>
          <p className="title">Bileklik Ölçüsü</p>
          <Button text="Ekle" callback={on.create} mood="accent" className="submitButton" />
        </Sticky>

        <Content>
          <Col lg={6} md={12} className="section">
            <Lib.C.Contents size={size.value} setSize={size.setValue} onEnter={on.create} />
          </Col>

          <Col lg={6} md={12} className="section">
            {list && (
              <List
                items={list}
                border
                onDelete={on.delete}
                edit={{
                  onSubmit: on.edit,
                  beforeConfirm: on.beforeConfirmEdit,
                  content: <Lib.C.Contents size={editSize.value} setSize={editSize.setValue} onEnter={on.edit} />,
                  overrideVisibilityState: editSize.visibility,
                }}
              />
            )}
          </Col>
        </Content>
      </PanelWrapper>
    </>
  )
}
