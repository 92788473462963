import { Checkbox, DatePicker, Modal, Switch } from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'
import { Button } from 'common/components/ui-kit/button'
import { Drawer } from 'common/components/ui-kit/drawer'
import { DropDown2 } from 'common/components/ui-kit/dropdown2'
import { Icon } from 'common/components/ui-kit/icon'
import { ImageSlider } from 'common/components/ui-kit/image-slider'
import ImageModal from 'common/components/ui-kit/imageModal'
import { Input } from 'common/components/ui-kit/input'
import { Radio } from 'common/components/ui-kit/radio'
import { Textarea } from 'common/components/ui-kit/textarea'
import { PIECES, PIECES_EN, PIECES_TR } from 'common/constants/pieces'
import { imageAddresser, pathTothumb } from 'common/helpers/image.helper'
import { typeLocalizer } from 'common/helpers/localization'
import { numberInputTypeGuard } from 'common/helpers/numberInputTypeGuard'
import { combineStoneSizes } from 'common/helpers/stone-size'
import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { Managers, RolesTRType } from 'common/routes/types/roles.type'
import { OptionService, UserService } from 'common/services'
import { Piece } from 'common/services/lib/types/pieces'
import { updateDate } from 'common/store/actions/filters.action'
import { RootState } from 'common/store/root-reducer'
import moment from 'moment'
import 'moment/locale/tr'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import * as Lib from '.'
import { FilterSelectStatusType } from './typing'

const { RangePicker } = DatePicker

const onClickHandler = (
  evt: React.MouseEvent<HTMLImageElement, MouseEvent> | React.MouseEvent<HTMLSpanElement, MouseEvent>,
  callback: Function,
) => {
  evt.preventDefault()
  evt.stopPropagation()
  callback()
}

export const TableImage: FC<Lib.T.TableImageProps> = ({ src, alt, onClick }): JSX.Element => {
  return (
    <>
      <Lib.S.tableImage>
        <img src={src} alt={alt} onClick={evt => onClickHandler(evt, onClick)} />
      </Lib.S.tableImage>
    </>
  )
}

export const TableButton: FC<Lib.T.TableButtonProps> = ({ callback, icon, color }): JSX.Element => {
  return (
    <>
      <Lib.S.tableButton onClick={evt => onClickHandler(evt, callback)}>
        <Icon name={icon} color={color} size={20} />
      </Lib.S.tableButton>
    </>
  )
}

export const GridView: FC<Lib.T.GridDataType> = ({
  code,
  weight,
  image,
  productImageThumbnail,
  id,
  setProductId,
  showModal,
  openDetails,
  style,
  alreadyAddedProducts,
  setAlreadyAddedProducts,
  handleOpenImageModal,
  index,
  createFavoriteProduct,
  favoriteProductIds,
  setFavoriteProductIds,
  setViewedFavoriteProductIds,
  viewedFavoriteProductIds,
}): JSX.Element => {
  const isAddedToCart = alreadyAddedProducts.includes(id)
  const isAFavorite = favoriteProductIds?.includes(id)
  const isViewedFavorite = viewedFavoriteProductIds?.includes(id)
  const { group } = useSelector((state: RootState) => state.globals.user)

  const addToCardPermission = Managers.includes(group.name) || group.name === 'Satış Temsilcisi'

  const handleCreateFavoriteClick = () => {
    if (!isAFavorite) {
      createFavoriteProduct(id, true)
    } else {
      createFavoriteProduct(id, false)
    }
  }

  const accessToFavorite: RolesTRType[] = useMemo(() => ['Satış Temsilcisi', 'Satış Yönetim', 'Yönetim', 'Üretim Yönetim'], [])
  // const { user } = useSelector((state: RootState) => state.globals)

  return (
    <>
      <Lib.S.gridView style={style} isAddedToCart={isAddedToCart} isAFavorite={isAFavorite} isViewedFavorite={isViewedFavorite}>
        <div className="image">
          {accessToFavorite.includes(group.name) ? (
            <div className="favorite">
              <Icon
                name="star_filled"
                size={20}
                color={isAFavorite ? 'var(--accent)' : isViewedFavorite ? '#C6A662' : '#fff'}
                style={{ marginRight: '8px', cursor: 'pointer' }}
                onClick={handleCreateFavoriteClick}
              />
            </div>
          ) : null}
          <img src={pathTothumb(productImageThumbnail)} alt={code} onClick={() => handleOpenImageModal(index)} />
          <div className="dotIcon">
            <span
              onClick={() => {
                if (setProductId && showModal) {
                  setProductId(id)
                  showModal()
                }
              }}
            >
              <Icon name="menu_dots" size={20} color="#fff" />
            </span>
          </div>
          {addToCardPermission ? (
            <div className="icon">
              <span
                style={{ cursor: isAddedToCart ? 'not-allowed' : 'pointer' }}
                onClick={() => {
                  if (isAddedToCart) {
                    return
                  } else {
                    if (openDetails) {
                      openDetails(id)
                      setAlreadyAddedProducts(perval => [...perval, id])
                    }
                  }
                }}
              >
                <Icon name="buy" size={20} color={isAddedToCart ? 'var(--accent)' : '#fff'} />
              </span>
            </div>
          ) : null}
        </div>

        <div>
          <div className="top">
            <p>{code}</p> <span>{weight}</span>
          </div>
        </div>
      </Lib.S.gridView>
    </>
  )
}

export const FilterWeight: FC<Lib.T.FilterWeightProps> = ({ setWeight, weight }): JSX.Element => {
  return (
    <>
      <Lib.S.sliderContainer>
        <Input
          label="min"
          onChange={e => setWeight([parseFloat(e.target.value), weight[1]])}
          value={weight[0]}
          onFocus={e => {
            e.target.addEventListener(
              'wheel',
              function (e) {
                e.preventDefault()
              },
              { passive: false },
            )
            setWeight([0, weight[1]])
          }}
          type="number"
        />
        <Input
          label="max"
          value={weight[1]}
          onChange={e => setWeight([weight[0], parseFloat(e.target.value)])}
          onFocus={e => {
            setWeight([weight[0], 0])

            e.target.addEventListener(
              'wheel',
              function (e) {
                e.preventDefault()
              },
              { passive: false },
            )
          }}
          type="number"
        />
      </Lib.S.sliderContainer>
    </>
  )
}

export const FilterSubPrices: FC<Lib.T.FilterSubPricesProps> = ({ subPiece, setSubPiece }): JSX.Element => {
  const kalems: Lib.T.Kalem[] = [
    { label: 'Hepsi', value: null },
    { label: 'Kalemli', value: true },
    { label: 'Kalemsiz', value: false },
  ]
  const chains: Lib.T.Chain[] = [
    { label: 'Hepsi', value: null },
    { label: 'Zincirle', value: true },
    { label: 'Zincirsiz', value: false },
  ]
  const enamels: Lib.T.Enamel[] = [
    { label: 'Hepsi', value: null },
    { label: 'Minele', value: true },
    { label: 'Minesiz', value: false },
  ]

  return (
    <>
      <Drawer title="Zincir" isSubItem maxHeight={130}>
        <Lib.S.verticalRadioGroup>
          <div className="radio-wrapper">
            {chains.map((item, index) => (
              <Radio
                key={index}
                label={item.label}
                selected={subPiece.chain === item.label}
                callback={() => setSubPiece({ ...subPiece, chain: item.label })}
              />
            ))}
          </div>
        </Lib.S.verticalRadioGroup>
      </Drawer>

      <Drawer title="Mine" isSubItem maxHeight={130}>
        <Lib.S.verticalRadioGroup>
          <div className="radio-wrapper">
            {enamels.map((item, index) => (
              <Radio
                key={index}
                label={item.label}
                selected={subPiece.enamel === item.label}
                callback={() => setSubPiece({ ...subPiece, enamel: item.label })}
              />
            ))}
          </div>
        </Lib.S.verticalRadioGroup>
      </Drawer>

      <Drawer title="Kalem" isSubItem maxHeight={130}>
        <Lib.S.verticalRadioGroup>
          <div className="radio-wrapper">
            {kalems.map((item, index) => (
              <Radio
                key={index}
                label={item.label}
                selected={subPiece.Kalem === item.label}
                callback={() => setSubPiece({ ...subPiece, Kalem: item.label })}
              />
            ))}
          </div>
        </Lib.S.verticalRadioGroup>
      </Drawer>
    </>
  )
}

export const FilterSelectProperty: FC<Lib.T.FilterSelectPropertyType> = ({ selectProperty, setSelectProperty }): JSX.Element => {
  const chains: Lib.T.Property[] = [
    { label: 'Hepsi', value: null },
    { label: 'Kriterli', value: true },
    { label: 'Kritersiz', value: false },
  ]

  return (
    <>
      <Lib.S.verticalRadioGroup>
        <div className="radio-wrapper">
          {chains.map((item, index) => (
            <Radio
              key={index}
              label={item.label}
              selected={selectProperty === item.value}
              callback={() => {
                setSelectProperty(item.value!)
              }}
            />
          ))}
        </div>
      </Lib.S.verticalRadioGroup>
    </>
  )
}

export const FilterSelectStatus: FC<Lib.T.FilterSelectStatusType> = ({
  selectActive,
  setSelectActive,
  selectDisabled,
  setSelectDisabled,
  setSelectedName,
}): JSX.Element => {
  const chains: Lib.T.Property[] = [
    { label: 'Aktif', value: true },
    { label: 'Pasif', value: false },
  ]

  return (
    <>
      <Lib.S.verticalRadioGroup>
        <div className="radio-wrapper">
          {chains.map((item, index) => (
            <Radio
              key={index}
              label={item.label}
              selected={selectDisabled === item.value}
              callback={() => {
                setSelectDisabled(item.value!)
                setSelectedName(item.label)
              }}
            />
          ))}
        </div>
      </Lib.S.verticalRadioGroup>
    </>
  )
}

export const FilterStone: FC<Lib.T.FilterStoneProps> = ({ stone, setStone }): JSX.Element => {
  const [shapeFilter, setShapeFilter] = useState<Lib.T.InfiniteScrollFilter>({
    limit: 5,
    page: 1,
  })
  const [sizeFilter, setSizeFilter] = useState<Lib.T.InfiniteScrollFilter>({
    limit: 5,
    page: 1,
  })
  const [colorFilter, setColorFilter] = useState<Lib.T.InfiniteScrollFilter>({
    limit: 5,
    page: 1,
  })
  const [typeFilter, setTypeFilter] = useState<Lib.T.InfiniteScrollFilter>({
    limit: 5,
    page: 1,
  })
  const [shapes, setShapes] = useState<Lib.T.InfiniteScrollResult>({
    items: [],
    total: 5,
  })
  const [sizes, setSizes] = useState<Lib.T.InfiniteScrollResult>({
    items: [],
    total: 5,
  })
  const [colors, setColors] = useState<Lib.T.InfiniteScrollResult>({
    items: [],
    total: 5,
  })
  const [types, setTypes] = useState<Lib.T.InfiniteScrollResult>({
    items: [],
    total: 5,
  })

  const getShapes = async () => {
    const { limit, page } = shapeFilter
    const service = new OptionService({ type: 'stone-shape' })
    const { success, options } = await service.read()
    if (success && options) {
      setShapes({
        items: [
          ...shapes.items,
          ...options.map(i => {
            return { id: i.id, name: i.stoneShape! }
          }),
        ],
        total: 10,
      })
    }
  }

  const getSizes = async () => {
    const { limit, page } = sizeFilter
    const service = new OptionService({ type: 'stone-size' })
    const { success, options } = await service.read()
    if (success && options) {
      setSizes({
        items: [
          ...sizes.items,
          ...options.map(i => {
            return {
              id: i.id,
              name: combineStoneSizes(i.stoneLength!, i.stoneWidth, i.stoneHeight),
            }
          }),
        ],
        total: 10,
      })
    }
  }

  const getColors = async () => {
    const { limit, page } = colorFilter
    const service = new OptionService({ type: 'stone-color' })
    const { success, options } = await service.read()
    if (success && options) {
      setColors({
        items: [
          ...colors.items,
          ...options.map(i => {
            return { id: i.id, name: i.stoneColor! }
          }),
        ],
        total: 10,
      })
    }
  }

  const getTypes = async () => {
    const { limit, page } = typeFilter
    const service = new OptionService({ type: 'stone-type' })
    const { success, options } = await service.read()
    if (success && options) {
      setTypes({
        items: [
          ...types.items,
          ...options.map(i => {
            return { id: i.id, name: i.stoneType! }
          }),
        ],
        total: 10,
      })
    }
  }

  useAsyncEffect(getShapes, [])
  useAsyncEffect(getSizes, [])
  useAsyncEffect(getColors, [])
  useAsyncEffect(getTypes, [])
  return (
    <>
      <Drawer title="Taş Şekli" isSubItem>
        <Lib.S.verticalRadioGroup>
          <Checkbox.Group
            value={stone.shape}
            options={
              shapes.items.map(item => {
                return {
                  label: item?.name!,
                  value: item?.id!,
                }
              })!
            }
            onChange={val => setStone({ ...stone, shape: val })}
          />
        </Lib.S.verticalRadioGroup>
      </Drawer>

      <Drawer title="Taş Ölçüsü" isSubItem>
        <Lib.S.verticalRadioGroup>
          <Checkbox.Group
            value={stone.size}
            options={
              sizes.items.map(item => {
                return {
                  label: item?.name!,
                  value: item?.id!,
                }
              })!
            }
            onChange={val => setStone({ ...stone, size: val })}
          />
        </Lib.S.verticalRadioGroup>
      </Drawer>

      <Drawer title="Taş Rengi" isSubItem>
        <Lib.S.verticalRadioGroup>
          <Checkbox.Group
            value={stone.color}
            options={
              colors.items.map(item => {
                return {
                  label: item?.name!,
                  value: item?.id!,
                }
              })!
            }
            onChange={val => setStone({ ...stone, color: val })}
          />
        </Lib.S.verticalRadioGroup>
      </Drawer>

      <Drawer title="Taşin Tipi" isSubItem>
        <Lib.S.verticalRadioGroup>
          <Checkbox.Group
            value={stone.type}
            options={
              types.items.map(item => {
                return {
                  label: item?.name!,
                  value: item?.id!,
                }
              })!
            }
            onChange={val => setStone({ ...stone, type: val })}
          />
        </Lib.S.verticalRadioGroup>
      </Drawer>
    </>
  )
}

export const FilterProfit: FC<Lib.T.FilterProfitProps> = ({ profit, setProfit }): JSX.Element => {
  return (
    <>
      <Lib.S.sliderContainer>
        <Input
          label="min"
          onChange={e => setProfit([parseFloat(e.target.value), profit[1]])}
          value={numberInputTypeGuard(profit[0]) ? profit[0] : ''}
          type="number"
          onFocus={e => {
            setProfit([undefined, profit[1]])

            e.target.addEventListener(
              'wheel',
              function (e) {
                e.preventDefault()
              },
              { passive: false },
            )
          }}
        />
        <Input
          label="max"
          type="number"
          value={numberInputTypeGuard(profit[1]) ? profit[1] : ''}
          onChange={e => setProfit([profit[0], parseFloat(e.target.value)])}
          onFocus={e => {
            setProfit([profit[0], undefined])

            e.target.addEventListener(
              'wheel',
              function (e) {
                e.preventDefault()
              },
              { passive: false },
            )
          }}
        />
      </Lib.S.sliderContainer>
    </>
  )
}

export const FilterPrices: FC<Lib.T.FilterPricesProps> = ({ price, setPrice }): JSX.Element => {
  const [priceFilter, setPriceFilter] = useState<Lib.T.InfiniteScrollFilter>({
    limit: 5,
    page: 1,
  })
  const [prices, setPrices] = useState<Lib.T.InfiniteScrollResult>({
    items: [],
    total: 5,
  })

  const getPrices = async () => {
    const { limit, page } = priceFilter
    const service = new OptionService({ type: 'price-category' })
    const { success, options } = await service.read()
    if (success && options) {
      setPrices({
        items: [
          ...prices.items,
          ...options.map(i => {
            return { id: i.id, name: i.minPrice + '-' + i.maxPrice }
          }),
        ],
        total: 10,
      })
    }
  }

  useAsyncEffect(getPrices, [])
  return (
    <>
      <Lib.S.verticalRadioGroup>
        <Checkbox.Group
          value={price}
          options={
            prices.items.map(item => {
              return {
                label: item?.name!,
                value: item?.id!,
              }
            })!
          }
          onChange={setPrice}
        />
      </Lib.S.verticalRadioGroup>
    </>
  )
}

export const FilterPieces: FC<Lib.T.FilterPiecesProps> = ({ piece, setPiece }): JSX.Element => {
  return (
    <>
      <Lib.S.verticalRadioGroup>
        <Checkbox.Group
          value={piece}
          options={
            PIECES.TR.map((item, index) => {
              return {
                label: item,
                value: PIECES.EN[index],
              }
            })!
          }
          onChange={setPiece}
        />
      </Lib.S.verticalRadioGroup>
    </>
  )
}

export const FilterDrawing: FC<Lib.T.FilterDrawingProps> = ({ drawing, setDrawing }): JSX.Element => {
  const sortFunction = (a: any, b: any) => {
    const nameA = a.name.toUpperCase() // ignore upper and lowercase
    const nameB = b.name.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    return 0
  }

  const [users, setUsers] = useState<Lib.T.InfiniteScrollResult>({
    items: [],
    total: 5,
  })

  const getUsers = async () => {
    // const { limit, page } = usersFilter
    const service = new UserService()
    const { success, users: list, total } = await service.getList({ group: 'Ar-Ge', disabled: false })

    if (success && list?.length) {
      const usersArray = list
        .map(i => {
          return { id: i.id, name: i.name }
        })
        .sort(sortFunction)

      setUsers({
        items: usersArray,
        total,
      })
    }
  }

  useAsyncEffect(getUsers, [])

  // const LoadMoreButton = () => (
  //   <Button
  //     callback={() => {
  //       if (users.total / usersFilter.page <= usersFilter.limit) {
  //         return
  //       }
  //       setUsersFilter(perval => {
  //         return { ...perval, page: perval.page + 1 }
  //       })
  //     }}
  //     mood={users.total / usersFilter.page <= usersFilter.limit ? 'gray' : 'accent'}
  //     text={users.total / usersFilter.page <= usersFilter.limit ? 'Daha fazla yok ' : 'Daha fazla göster'}
  //   />
  // )

  return (
    <>
      <Lib.S.verticalRadioGroup>
        <Checkbox.Group
          value={drawing.modeler}
          options={
            users.items.map(item => {
              return {
                label: item?.name!,
                value: item?.id!,
              }
            })!
          }
          onChange={val => setDrawing({ ...drawing, modeler: val })}
        />
      </Lib.S.verticalRadioGroup>
      {/* <LoadMoreButton /> */}
    </>
  )
}

export const FilterProperties: FC<Lib.T.FilterPropertiesProps> = ({ properties, setProperties }): JSX.Element => {
  const sortFunction = (a: any, b: any) => {
    const nameA = a.name.toUpperCase() // ignore upper and lowercase
    const nameB = b.name.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    return 0
  }

  const [propertiesState, setPropertiesState] = useState<Lib.T.InfiniteScrollResult>({
    items: [],
    total: 5,
  })

  const getProperties = async () => {
    const service = new OptionService({ type: 'property' })

    const { options, success } = await service.read()
    if (!success) {
      return
    }

    const dataForState = options?.map(opt => ({ id: opt.id, name: opt.property }))

    setPropertiesState(perv => ({ ...perv, items: dataForState as Lib.T.RadioSelector[] }))
  }

  useAsyncEffect(getProperties, [])

  return (
    <>
      <Lib.S.verticalRadioGroup>
        <Checkbox.Group
          value={properties.properties}
          options={
            propertiesState.items.map(item => {
              return {
                label: item?.name!,
                value: item?.id!,
              }
            })!
          }
          onChange={val => setProperties({ ...properties, properties: val })}
        />
      </Lib.S.verticalRadioGroup>
      {/* <LoadMoreButton /> */}
    </>
  )
}

export const FilterDepartment: FC<Lib.T.FilterDepartmentsProps> = ({ department, setDepartment }): JSX.Element => {
  const departmentsList = [
    { name: 'Döküm', value: 'castingDepartment' },
    { name: 'Reçine', value: 'rosinDepartment' },
    { name: 'Projet', value: 'projectDepartment' },
  ]

  return (
    <>
      <Lib.S.verticalRadioGroup className="radio">
        <Checkbox.Group
          value={department}
          options={
            departmentsList.map(item => {
              return {
                label: item?.name!,
                value: item?.value!,
              }
            })!
          }
          onChange={val => setDepartment(val)}
        />
      </Lib.S.verticalRadioGroup>
    </>
  )
}

export const FilterDate: FC<Lib.T.FilterDateProps> = ({ date, setDate }): JSX.Element => {
  const dispatch = useDispatch()
  const { date: globalDate } = useSelector((state: RootState) => state.filters)

  return (
    <>
      <div style={{ padding: '0 16px' }}>
        <RangePicker
          //size="small"
          value={date && date[0] && date[1] ? [moment(date[0]), moment(date[1])] : undefined}
          // value={
          //   setDate
          //     ? date && date[0] && date[1]
          //       ? [moment(date[0]), moment(date[1])]
          //       : undefined
          //     : [moment(globalDate.selected[0]), moment(globalDate.selected[1])]
          // }
          locale={locale}
          style={{ width: '100%', borderRadius: 8, height: 40 }}
          onChange={(_v, dates) =>
            setDate
              ? setDate([new Date(dates[0]).toISOString(), new Date(dates[1]).toISOString()])
              : dispatch(updateDate([new Date(dates[0]).toISOString(), new Date(dates[1]).toISOString()]))
          }
        />
      </div>
    </>
  )
}

export const FilterCodes: FC<Lib.T.FilterCodesProps> = ({ code, setCode }): JSX.Element => {
  const [productCodes, setProductCodes] = useState<Lib.T.RadioSelector[]>([])

  const getOptions = async () => {
    const optionService = new OptionService({ type: 'product-code' })
    const { success, options } = await optionService.read()
    if (success && options) {
      setProductCodes(
        options.map(i => {
          return { id: i.id, name: i.productCode! }
        }),
      )
    }
  }

  useAsyncEffect(getOptions, [])

  return (
    <>
      <Lib.S.verticalRadioGroup>
        <Checkbox.Group
          value={code}
          options={
            productCodes.map(item => {
              return {
                label: item?.name!,
                value: item?.id!,
              }
            })!
          }
          onChange={val => {
            setCode(val)
          }}
        />
      </Lib.S.verticalRadioGroup>
    </>
  )
}

export const DetailNumbers: FC<Lib.T.DetailNumbersProps> = ({ defaultValue, onChange }): JSX.Element => {
  const { NUMBERS, getValueFromInput, getValueFromItems, number } = Lib.H.useDetailNumbers({ defaultValue, onChange })

  return (
    <>
      <Lib.S.detailNumbers>
        {NUMBERS.map((item, index) => (
          <div key={index} className={`${number >= 1 && number === item}`} onClick={() => getValueFromItems(item)}>
            {item}
          </div>
        ))}

        <div className="withInput">
          <input
            type="number"
            onFocus={e =>
              e.target.addEventListener(
                'wheel',
                function (e) {
                  e.preventDefault()
                },
                { passive: false },
              )
            }
            value={number}
            className="noArrow"
            onInput={getValueFromInput}
          />
        </div>
      </Lib.S.detailNumbers>
    </>
  )
}

export const DetailModal: FC<Lib.T.DetailModal> = ({
  modalProps,
  pieces,
  toggleModal,
  addToCart,
  color,
  enamel,
  statesContainer,
  setStatesContainer,
}): JSX.Element => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const minPrice = statesContainer.length > 0 ? (statesContainer[0].minMaxPrice as string).split('-') : []
  }, [modalProps.visible])
  const initialMaster: Lib.T.AddToCartStatesContainer = useMemo(() => {
    const minPrice = statesContainer.length > 0 ? (statesContainer[0].minMaxPrice as string).split('-') : []

    return {
      color: color[0]?.id,
      description: '',
      enamel: [],
      number: 0,
      price: 0,
      size: 0,
      id: 0,
      minMaxPrice: '',
      productCode: '',
      weight: 0,
      image: '',
    }
  }, [statesContainer])

  const [weight, setWeight] = useState<number>(0)
  const [master, setMaster] = useState<Lib.T.AddToCartStatesContainer>(initialMaster)
  const [imageUrlForPopup, setImageUrlForPopup] = useState<string>('')
  const changeStatesContainer = (indexToChange: number, newValue: any, type: Lib.T.AddToCartStatesContainerKeys) => {
    const prevStates = statesContainer.slice(0, indexToChange)
    const rowState = statesContainer[indexToChange]
    const nextStates = statesContainer.slice(indexToChange + 1, statesContainer.length)
    rowState[type] = newValue

    setStatesContainer(prevStates.concat(rowState).concat(nextStates))
  }
  const changeMaster = (value: any, type: Lib.T.AddToCartStatesContainerKeys) => {
    setMaster(prev => {
      return {
        ...prev,
        [type]: value,
      }
    })
  }

  const onMasterChange = (type: Lib.T.AddToCartStatesContainerKeys) => {
    const newStateContainer: Lib.T.AddToCartStatesContainer[] = []
    statesContainer.map((_i, index) =>
      newStateContainer.push({
        ...statesContainer[index],
        [type]: type === 'enamel' ? (master[type].length > 0 ? master[type] : statesContainer[index].enamel) : master[type],
      }),
    )
    setStatesContainer(newStateContainer)
  }

  const calculateWeight = () => {
    if (!statesContainer || !statesContainer[0]) {
      return
    }
    const totalWeights = statesContainer.map(i => i.weight * i.number)
    setWeight(totalWeights.reduce((a, b) => a + b, 0))
  }

  useEffect(() => {
    changeMaster(null, 'number')

    setMaster(initialMaster)
  }, [modalProps.visible])
  useEffect(() => onMasterChange('color'), [master.color])
  useEffect(() => onMasterChange('description'), [master.description])
  useEffect(() => onMasterChange('enamel'), [master.enamel])
  useEffect(() => onMasterChange('number'), [master.number])
  useEffect(() => onMasterChange('price'), [master.price])
  useEffect(() => onMasterChange('size'), [master.size])
  useEffect(() => onMasterChange('image'), [master.image])
  useEffect(calculateWeight, [statesContainer])

  const disableMasterMine = pieces.every(item => {
    return item.hasEnamel !== true
  })

  return (
    <>
      <Modal {...modalProps}>
        <Lib.S.modal priceCondition={true}>
          <div className="header">
            <div>
              <p className="key">Ürün Kodu:</p>
              {statesContainer && statesContainer[0] && <p className="value">{statesContainer[0].productCode}</p>}
            </div>
            <div>
              <p className="key">Toplam Gram:</p>
              <p className="value">{weight.toFixed(2)}</p>
            </div>
            <div>
              <p className="key">Fiyat:</p>
              {statesContainer && statesContainer[0] && <p className="value">{statesContainer[0].minMaxPrice}</p>}
            </div>
          </div>

          <div className="tableContainer">
            <table className="table">
              <thead>
                <tr>
                  <td width={150}>Parçalar</td>
                  <td width={110}>Boy</td>
                  <td width={110}>Renk</td>
                  <td width={110}>Mine</td>
                  <td width={310}>Adet</td>
                  <td width={100}>Fiyat</td>
                  <td>Açıklama</td>
                </tr>
              </thead>
              <tbody>
                <ImageModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  image={imageUrlForPopup}
                  downloadImageLink={imageUrlForPopup}
                  withZoom
                />

                {pieces.length !== 1 && (
                  <tr>
                    <td>
                      <div className="image">
                        <img
                          src={(statesContainer.length > 0 && statesContainer[0].image) || ''}
                          alt="product"
                          onClick={() => {
                            setImageUrlForPopup(statesContainer[0].image)
                            setShowModal(perval => !perval)
                          }}
                        />
                        <span>{'ürün'}</span>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      {color.length > 0 && (
                        <DropDown2 className="DD" options={color} value={master.color} onChange={val => changeMaster(val, 'color')} />
                      )}
                    </td>
                    <td style={{ verticalAlign: 'middle' }}>
                      <DropDown2
                        className="DD"
                        options={enamel}
                        mode="multiple"
                        value={master.enamel.reverse()}
                        onChange={val => changeMaster(val, 'enamel')}
                        disabled={disableMasterMine}
                      />
                    </td>

                    <td>
                      <Lib.C.DetailNumbers defaultValue={master.number} onChange={val => changeMaster(val, 'number')} />
                    </td>
                    <td>
                      <Input value={master.price} className="price" onChange={val => changeMaster(val.currentTarget.value, 'price')} />
                    </td>
                    <td>
                      <Textarea
                        className="explain"
                        value={master.description}
                        onChange={val => changeMaster(val.currentTarget.value, 'description')}
                      />
                    </td>
                  </tr>
                )}

                {pieces.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div
                          className="image"
                          onClick={() => {
                            setImageUrlForPopup(item.image)
                            setShowModal(perval => !perval)
                          }}
                        >
                          <img src={item.image} />
                          <span className="name p-1">{PIECES_TR[PIECES_EN.indexOf(item.name as PIECES_EN)]}</span>
                        </div>
                      </td>
                      <td>
                        <DropDown2
                          className="DD"
                          options={item.sizes}
                          disabled={item.sizes.length === 0}
                          value={statesContainer[index]?.size}
                          onChange={val => changeStatesContainer(index, val, 'size')}
                        />
                      </td>
                      <td>
                        {color.length > 0 && (
                          <DropDown2
                            className="DD"
                            options={color}
                            value={statesContainer[index]?.color}
                            onChange={val => changeStatesContainer(index, val, 'color')}
                          />
                        )}
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <DropDown2
                          className="DD"
                          options={enamel}
                          mode="multiple"
                          value={statesContainer[index]?.enamel.reverse()}
                          onChange={val => changeStatesContainer(index, val, 'enamel')}
                          disabled={!item.hasEnamel}
                        />
                      </td>

                      <td>
                        <Lib.C.DetailNumbers
                          defaultValue={statesContainer[index]?.number}
                          onChange={val => {
                            changeStatesContainer(index, val, 'number')
                          }}
                        />
                      </td>
                      <td>
                        <Input
                          value={statesContainer[index]?.price}
                          className="price"
                          onInput={val => {
                            pieces.length === 1
                              ? changeMaster(val.currentTarget.value, 'price')
                              : changeStatesContainer(index, val.currentTarget.value, 'price')
                          }}
                        />
                      </td>
                      <td>
                        <Textarea
                          className="explain"
                          value={statesContainer[index]?.description}
                          onInput={val => changeStatesContainer(index, val.currentTarget.value, 'description')}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className="footer">
            <div>
              <Button text="Vazgeç" mood="orang" callback={() => toggleModal(false)} />
            </div>
            <div>
              <Button text="Sepete Ekle" mood="accent" callback={() => addToCart()} />
            </div>
          </div>
        </Lib.S.modal>
      </Modal>
    </>
  )
}

export const ProductDetailModal: FC<Lib.T.ProductDetailProps> = ({
  productModal,
  productId,
  fromPendingProducts,
  setFetchAgain,
  categories,
  subCategories,
  subCategoriesPopup,
  triggerFetchSubCategories,
  triggerFetchModalUpdate,
}) => {
  const { get, val, set, on } = Lib.H.useDetails(
    setFetchAgain!,
    categories,
    subCategories,
    productModal.toggle,
    triggerFetchSubCategories,
    triggerFetchModalUpdate,
    subCategoriesPopup,
  )
  const [tableValues, setTableValues] = useState<[string, string, string, string, string, string]>()
  const { user } = useSelector((state: RootState) => state.globals)
  const isAccurateWeight = useRef<boolean | undefined>()
  const [productState, setProductState] = useState(false)

  useEffect(() => {
    if (productId) {
      get.setProductId(productId)
    }
  }, [productId])

  useEffect(() => {
    setTableValues([
      get.productDetail?.productCode!,
      `${get.productDetail?.weight!} gr`,
      get.priceRange,
      `${get.productDetail?.stoneProfit!} $`,
      get.productDetail?.modeler?.name!,
      `${get.productDetail?.productCategory.productCategory} - ${get.productDetail?.productSubCategory.productSubCategory}`,
    ])

    isAccurateWeight.current = get.productDetail?.pieces.every(piece => piece.accurateWeight)
  }, [get.priceRange, productId, get.productDetail])

  const [modalMood, setModalMood] = useState<'detail' | 'technical'>('detail')

  return (
    <Lib.S.ProductDetailContainer>
      {/* price Modal */}
      <Modal {...get.priceModalProps} destroyOnClose>
        <div className="col-lg-12 d-flex justify-content-end">
          <Icon name="close_square" size={20} style={{ cursor: 'pointer' }} onClick={() => set.setShowPriceModal(false)} />
        </div>
        <div className="row d-flex align-items-center">
          <div className="col-lg-7">
            <DropDown2 options={get.priceList} label="Fiyat" value={get.priceId} onChange={id => set.setPriceId(id)} />
          </div>
          <div className="col-lg-4 mt-4">
            <Button text="Düzenle" callback={on.updatePrice} mood="accent" containerStyles={{ marginTop: 10, minHeight: 45 }} />
          </div>
        </div>
      </Modal>
      {/* property modal */}
      <Modal {...get.propertyModalProps} destroyOnClose>
        <Lib.S.PropertyModalWrapper properties={get.properties}>
          <div className="col-lg-12 d-flex justify-content-end">
            <Icon name="close_square" size={20} style={{ cursor: 'pointer' }} onClick={get.propertyModalProps.onCancel} />
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-lg-12">
              <DropDown2
                className="property"
                options={get.categoriesForDropDown}
                label="Kategori"
                lessBorder
                value={get.selectedCategoryId}
                onChange={val => {
                  set.setSelectedCategoryId(+val)
                  set.setSelectedSubCategoryId(undefined)
                  if (triggerFetchModalUpdate)
                    triggerFetchModalUpdate(perv => ({
                      ...perv,
                      productCategorySelected: { ...perv.productCategorySelected, productCategoryId: +val, isSelected: false },
                    }))
                }}
              />
            </div>
            <div className="col-lg-12">
              <DropDown2
                className="property"
                options={get.subCategoriesForDropDown}
                label="Alt Kategori"
                lessBorder
                value={get.selectedSubCategoryId}
                onChange={val => {
                  set.setSelectedSubCategoryId(+val)
                }}
              />
            </div>
            <div className="col-lg-12">
              <DropDown2
                className="property"
                options={get.propertyList}
                mode="multiple"
                label="Kriter"
                lessBorder
                value={get.properties}
                onChange={val => set.setProperties(val)}
              />
            </div>

            <div className="col-lg-4 mt-4">
              <Button
                text="Düzenle"
                callback={async () => {
                  await on.updateProperty()
                  if (triggerFetchModalUpdate)
                    triggerFetchModalUpdate(perv => ({
                      ...perv,
                      //productCategorySelected: { ...perv.productCategorySelected, productCategoryId: get.selectedCategoryId || 0 },
                      productSubCategorySelected: {
                        ...perv.productSubCategorySelected,
                        productSubCategoryId: get.selectedSubCategoryId || 0,
                      },
                      isUpdate: true,
                      isPaginate: false,
                    }))
                }}
                mood="accent"
                containerStyles={{ marginTop: 10, minHeight: 45 }}
              />
            </div>
          </div>
        </Lib.S.PropertyModalWrapper>
      </Modal>
      <Modal
        {...productModal.props}
        onCancel={() => {
          productModal.toggle()
          setModalMood('detail')
        }}
        destroyOnClose
      >
        <Lib.S.Main modalMood={modalMood}>
          <div className="top-header">
            <div className="detail-mood" onClick={() => setModalMood('detail')}>
              Ürün Detaylar
            </div>
            <div className="technical-mood" onClick={() => setModalMood('technical')}>
              Teknik Resim
            </div>
          </div>
          <div className="image-details">
            {modalMood === 'detail' ? (
              <div className="image" id="zoom-1">
                <TransformWrapper key={2}>
                  <TransformComponent key={2}>
                    <img className="productImg" src={get.productDetail?.productImage} alt="detail-image" />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            ) : (
              <div className="image main-image">
                <div className="image" id="zoom-1">
                  <ImageSlider
                    images={get.productDetail?.technicalImage.split(',') as string[]}
                    baseUrl={process.env.REACT_APP_DOMAIN}
                    hideCloseIcon
                  />
                </div>
              </div>
            )}
            <div className="details">
              {!get.loading && modalMood === 'detail' ? (
                <>
                  <div className="header">
                    <span className="title">Ürün Detayları</span>
                    <div className="actions">
                      <div className="d-flex align-items-center" style={{ width: 100 }}>
                        <span className="me-2">Ayar</span>
                        <DropDown2
                          lessBorder
                          size="middle"
                          height="25px"
                          value={val.karat ? val.karat : '14'}
                          onChange={value => set.setKarat(value)}
                          options={val.karats}
                        />
                      </div>
                      {/* disable button */}
                      {Managers.includes(user.group.name) ? (
                        <Switch
                          defaultChecked={!get.productDetail?.disabled}
                          //checked={!get.disableModal}
                          onClick={val => {
                            setProductState(!val)
                            set.setDisableModal(true)
                          }}
                        />
                      ) : // (
                      //   <Icon name="bag" size={18} onClick={() => set.setDisableModal(true)} style={{ cursor: 'pointer' }} />
                      // )
                      null}
                      {/* edit button */}
                      {(Managers.includes(user.group.name) || user.group.name === 'Ar-Ge Müdürü' || user.group.name === 'Ar-Ge') && (
                        <div onClick={() => window.open(`/products/edit/${productId}`, '_blank')}>
                          <Icon name="edit_outline" size={18} color="#4D4D4D" style={{ cursor: 'pointer' }} />
                        </div>
                      )}
                      {(Managers.includes(user.group.name) || user.group.name === 'Ar-Ge Müdürü') && (
                        <div onClick={() => get.ConfirmModalVisibleHandler()}>
                          <Icon name="trash_bin_outline" size={18} color="#4D4D4D" style={{ cursor: 'pointer' }} />
                        </div>
                      )}
                      {!fromPendingProducts && Managers.includes(user.group.name) ? (
                        <div onClick={() => set.setShowPriceModal(true)}>
                          <Icon name="dollar-outline" size={18} color="#4D4D4D" style={{ cursor: 'pointer' }} />
                        </div>
                      ) : null}
                      {!fromPendingProducts && Managers.includes(user.group.name) ? (
                        <div
                          onClick={() => {
                            // if (triggerFetchSubCategories) {
                            //   triggerFetchSubCategories(perv => ({
                            //     ...perv,
                            //     productCategorySelected: {
                            //       ...perv.productCategorySelected,
                            //       productCategoryId: get.productDetail?.productCategory.id!,
                            //       preProductCategoryId: get.productDetail?.productCategory.id!,
                            //       refreshProducts: false,
                            //     },
                            //     productSubCategorySelected: {
                            //       ...perv.productSubCategorySelected,
                            //       preProductSubCategoryId: get.productDetail?.productSubCategory.id!
                            //     }
                            //   }))
                            // }
                            if (triggerFetchModalUpdate) {
                              triggerFetchModalUpdate(perv => ({
                                ...perv,
                                productCategorySelected: {
                                  ...perv.productCategorySelected,
                                  productCategoryId: get.productDetail?.productCategory.id!,
                                },
                                productSubCategorySelected: {
                                  ...perv.productSubCategorySelected,
                                  productSubCategoryId: get.productDetail?.productSubCategory.id!,
                                },
                              }))
                            }
                            set.setPropertyModalShow(true)
                          }}
                        >
                          <Icon name="bookmark_remove" size={18} color="#4D4D4D" style={{ cursor: 'pointer' }} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="body">
                    <Lib.C.TableItem value={tableValues as any[]} isAccurateWeight={isAccurateWeight} />
                    {!get.loading && (
                      <div>
                        <div className="pieces">
                          {val.allPieces.map((piece: Piece, index) => {
                            return (
                              <Lib.C.Pieces
                                key={piece.id}
                                accurateWeight={piece.accurateWeight}
                                profit={piece.stoneProfit}
                                type={piece.type}
                                weight={piece.weight}
                                image={piece.pieceImage}
                                imageLink={piece.pieceImageLink}
                                thumbnail={piece.pieceImageThumbnail}
                                purpleDot={piece.projectDepartment}
                                redDot={piece.rosinDepartment}
                              />
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : !get.loading && modalMood === 'technical' ? (
                <>
                  {get?.productDetailAllData?.stones?.length > 0 && (
                    <div className="total-container">
                      <div className="total-item">
                        <PieceListSecondary data={get.productDetailAllData} type="stone" />
                      </div>
                      {get.productDetailAllData.product.pieces.map((piece: any) => {
                        return (
                          <div className="total-item">
                            <PieceListSecondary piece={piece} type="piece" />
                          </div>
                        )
                      })}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Lib.S.Main>
        <Modal
          visible={get.isConfirmModalVisible}
          onCancel={get.handleCancelConfirmModal}
          footer={null}
          title={null}
          className="noHeaderModal scrollWidthModal"
        >
          <Lib.S.ConfirmModalContainer>
            <p>Bu ürünü silmek istediğinizden emin misiniz?</p>
            <div className="btns">
              <Button text="Vazgeç" mood="orang" className="submitButton" callback={() => get.handleCancelConfirmModal()} />
              <Button
                mood="accent"
                className="submitButton"
                text="Onayla"
                callback={() => {
                  get.handleDeleteProduct(productId!)
                }}
                parentStyles={{ marginLeft: '30px' }}
              />
            </div>
          </Lib.S.ConfirmModalContainer>
        </Modal>
        {/* disable Modal */}
        <Modal {...get.disableModalProps}>
          <Lib.S.disableModalContent>
            <p>Ürün Listeye Eklenecek. Emin misiniz?</p>
            <div>
              <Button
                text="İptal"
                mood="orang"
                callback={() => {
                  set.setDisableModal(false)
                }}
              />
              <Button text="Onay" mood="accent" callback={() => on.handleDisableProduct(productState)} />
            </div>
          </Lib.S.disableModalContent>
        </Modal>
      </Modal>
    </Lib.S.ProductDetailContainer>
  )
}

export const Pieces: React.FC<Lib.T.ProductDetailPeiceProps> = ({
  type,
  weight,
  image,
  thumbnail,
  imageLink,
  purpleDot,
  redDot,
  profit,
  accurateWeight,
}) => {
  const [showModal, setShowModal] = useState(false)

  const { user } = useSelector((state: RootState) => state.globals)

  return (
    <Lib.S.PiecesWrapper>
      <div className="image" onClick={() => setShowModal(true)}>
        <img src={pathTothumb(thumbnail)} alt="" width={80} height={80} />
      </div>
      <div className="details">
        <div className="top">
          <span className="value"> {typeLocalizer(type)}</span>
        </div>
        <div className="bottom">
          <span className="value" style={{ color: accurateWeight ? 'blue' : '#000' }}>
            {' '}
            {weight} gr
          </span>
          {Managers.includes(user.group.name) ? (
            <p className="value" style={{ margin: 0 }}>
              ({profit} $)
            </p>
          ) : null}

          <div className="dots">
            {purpleDot === true && <div className="purple-dot"></div>}
            {redDot === true && <div className="red-dot"></div>}
          </div>
        </div>
      </div>
      <ImageModal image={imageLink!} downloadImageLink={imageLink!} setShowModal={setShowModal} showModal={showModal} withZoom />
    </Lib.S.PiecesWrapper>
  )
}

export const TableItem: React.FC<Lib.T.TableItemsProps> = ({ value, isAccurateWeight }) => {
  const [vals, setVals] = useState<any>([])
  const { user } = useSelector((state: RootState) => state.globals)
  let items: string[] = []

  items = ['Kod', 'Toplam Gram', 'Modelleyen Kişi', 'Koleksiyon']
  if (Managers.includes(user.group.name)) {
    items = ['Kod', 'Toplam Gram', 'Fiyat Grubu', 'Taş karı', 'Modelleyen Kişi', 'Koleksiyon']
  }

  useEffect(() => {
    if (Managers.includes(user.group.name)) {
      setVals(value as any)
    } else {
      const filtredArray = value.filter((_: any, index: number) => index !== 2 && index !== 3)
      setVals(filtredArray)
    }
  }, [value])

  return (
    <div style={{ backgroundColor: '#f8f8f8', borderRadius: 6 }}>
      {items.map((item, itemIndex) => {
        return (
          <div className="item" key={itemIndex}>
            <span className="title">{item}</span>
            {vals.length &&
              vals.map((val: any, valIndex: number) => {
                return (
                  itemIndex === valIndex && (
                    <span
                      className="value"
                      key={valIndex}
                      style={{ color: isAccurateWeight.current && val === 'Toplam Gram' ? 'blue' : 'var(--accent)' }}
                    >
                      {val}
                    </span>
                  )
                )
              })}
          </div>
        )
      })}
    </div>
  )
}
export const StoneList: FC<any> = ({ stone }) => {
  return (
    <Lib.S.PieceListContainer>
      <div className="header">
        <span>{stone.stoneType.stoneType}</span>
        <span className="gram"> {stone.weight} gr</span>
      </div>
      <div className="body">
        <div className="body-items-container">
          <div className="image">
            <img src={stone?.image} alt="" />
          </div>
          <div className="info-wrapper">
            <div className="info" style={{ color: stone.technicalColor?.technicalColor }}>
              <div className="count-size">
                <span>
                  {stone.count} - {combineStoneSizes(stone.stoneSize.stoneLength, stone.stoneSize.stoneWidth, stone.stoneSize.stoneHeight)}
                </span>
              </div>
              <div className="some-color">
                <span>
                  {stone.stoneShape.stoneShape} - {stone.stoneColor.stoneColor}
                </span>
              </div>
            </div>
            <div
              className="color"
              style={{
                color: stone.technicalColor?.technicalColor,
                backgroundColor: stone.technicalColor?.technicalColor,
              }}
            ></div>
          </div>
        </div>
      </div>
    </Lib.S.PieceListContainer>
  )
}
export const PieceList: React.FC<Lib.T.PieceListProps> = ({ piece, style }) => {
  const { user } = useSelector((state: RootState) => state.globals)
  const [showModal, setShowModal] = useState(false)
  return (
    <Lib.S.PieceListContainer style={style}>
      <div className="header">
        <span className="total">{piece?.type !== undefined && typeLocalizer(piece?.type)}</span>
        {user.group.name === 'Döküm Departmanı' ||
          (Managers.includes(user.group.name) && (
            <span className="gram" style={{ color: '#004E42' }}>
              {' '}
              {piece && piece?.weight.toFixed(2)} gr
            </span>
          ))}
      </div>
      <div className="body">
        {piece &&
          piece?.pieceToStones?.map((stone, index) => {
            if (index === piece?.pieceToStones.length - 1) {
              return (
                <div className="body-items-container" style={{ border: 'none' }} key={index}>
                  <div className="image">
                    <div className="icon-container">
                      <img src={pathTothumb(stone?.stone?.image)} alt="" />
                    </div>
                    <div className="info-wrapper">
                      <div className="info" style={{ color: stone.technicalColor?.technicalColor }}>
                        <div className="infoBox">
                          <div className="count-size">
                            <span>
                              {stone?.count} Adet -{' '}
                              {combineStoneSizes(
                                stone?.stone?.stoneSize?.stoneLength,
                                stone?.stone?.stoneSize?.stoneWidth,
                                stone?.stone?.stoneSize?.stoneHeight,
                              )}
                            </span>
                          </div>
                          <div className="some-color">
                            <span>
                              {stone?.stone?.stoneShape?.stoneShape} - {stone?.stone?.stoneColor?.stoneColor}
                            </span>
                          </div>
                        </div>
                        <div
                          className="color"
                          style={{
                            color: stone?.technicalColor?.technicalColor,
                            backgroundColor: stone?.technicalColor?.technicalColor,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            return (
              <div className="body-items-container" key={index}>
                <div className="image">
                  <div className="icon-container">
                    <img src={pathTothumb(stone?.stone?.image)} alt="" />
                  </div>
                  <div className="info-wrapper">
                    <div className="info" style={{ color: stone.technicalColor?.technicalColor }}>
                      <div className="infoBox">
                        <div className="count-size">
                          <span>
                            {stone?.count} Adet -{' '}
                            {combineStoneSizes(
                              stone?.stone?.stoneSize?.stoneLength,
                              stone?.stone?.stoneSize?.stoneWidth,
                              stone?.stone?.stoneSize?.stoneHeight,
                            )}
                          </span>
                        </div>
                        <div className="some-color">
                          <span>
                            {stone?.stone?.stoneShape?.stoneShape} - {stone?.stone?.stoneColor?.stoneColor}
                          </span>
                        </div>
                      </div>
                      <div
                        className="color"
                        style={{
                          color: stone?.technicalColor?.technicalColor,
                          backgroundColor: stone?.technicalColor?.technicalColor,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        <div className="chains-container">
          {piece &&
            piece?.chains?.length > 0 &&
            piece?.chains?.map(chain => {
              return (
                <div className="chain-item" key={chain.id}>
                  <img width="25px" height="25px" className="chain-image" src={chain.image} alt="" onClick={() => setShowModal(true)} />
                  <div className="chain-title">{chain?.title}</div>
                  <ImageModal
                    image={chain.image!}
                    downloadImageLink={chain.image!}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    withZoom
                  />
                </div>
              )
            })}
        </div>
      </div>
    </Lib.S.PieceListContainer>
  )
}

export const PieceListSecondary: React.FC<Lib.T.SecondaryPieceListProps> = ({ data, type, piece }) => {
  const { user } = useSelector((state: RootState) => state.globals)
  const { t } = useTranslation()

  const renderPiece = () => {
    if (type === 'stone') {
      return data?.stones?.map((stone: any, index: number) => {
        return (
          <div className="body-items-container" style={{ border: 'none' }} key={stone?.id}>
            <div className="image">
              <div className="icon-container">
                <img src={pathTothumb(stone?.image)} alt="stone-image" />
              </div>
              <div className="info-wrapper">
                <div className="info" style={{ color: stone?.technicalColor?.technicalColor }}>
                  <div className="infoBox">
                    <div className="count-size">
                      <span>
                        {stone.count} Adet -{' '}
                        {combineStoneSizes(stone?.stoneSize?.stoneLength, stone?.stoneSize?.stoneWidth, stone?.stoneSize?.stoneHeight)}
                      </span>
                    </div>
                    <div className="some-color">
                      <span>
                        {stone?.stoneShape?.stoneShape} - {stone?.stoneColor?.stoneColor}
                      </span>
                    </div>
                  </div>
                  <div
                    className="color"
                    style={{
                      color: stone?.technicalColor?.technicalColor,
                      backgroundColor: stone?.technicalColor?.technicalColor,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )
      })
    } else {
      return piece?.pieceToStones.map(p => {
        return (
          <div className="body-items-container" style={{ border: 'none' }} key={p?.stone.id}>
            <div className="image">
              <div className="icon-container">
                <img src={pathTothumb(p.stone?.image)} alt="stone-image" />
              </div>
              <div className="info-wrapper">
                <div className="info" style={{ color: p?.technicalColor?.technicalColor }}>
                  <div className="infoBox">
                    <div className="count-size">
                      <span>
                        {p.count} Adet -{' '}
                        {combineStoneSizes(
                          p?.stone.stoneSize?.stoneLength,
                          p?.stone.stoneSize?.stoneWidth,
                          p?.stone.stoneSize?.stoneHeight,
                        )}
                      </span>
                    </div>
                    <div className="some-color">
                      <span>
                        {p?.stone.stoneShape?.stoneShape} - {p?.stone.stoneColor?.stoneColor}
                      </span>
                    </div>
                  </div>
                  <div
                    className="color"
                    style={{
                      color: p?.technicalColor?.technicalColor,
                      backgroundColor: p?.technicalColor?.technicalColor,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        )
      })
    }
  }
  return (
    <Lib.S.PieceListContainer>
      <div className="header">
        <span className="total">{type === 'stone' ? 'Toplam' : t(piece?.type! || '')}</span>
        {user.group.name === 'Döküm Departmanı' ||
          (Managers.includes(user.group.name) && (
            <span className="gram" style={{ color: '#004E42' }}>
              {type === 'stone'
                ? `${(data && data?.totalStonesWeight?.toFixed(2)) || 0} gr`
                : `${piece?.totalStonesWeight?.toFixed(2) || 0} gr`}
            </span>
          ))}
      </div>
      <div className="body">{renderPiece()}</div>
    </Lib.S.PieceListContainer>
  )
}

export const TableView: FC<Lib.T.TableViewPropTypes> = ({ tableData, onClickToSort, sort, dataInformation, handleShowMore }) => {
  const { group } = useSelector((state: RootState) => state.globals.user)

  const addToCardPermission = Managers.includes(group.name) || group.name === 'Satış Temsilcisi'
  const editIconPermission = group.name === 'Ar-Ge Müdürü' || group.name === 'Ar-Ge' || Managers.includes(group.name)
  const stoneProfitPermission = Managers.includes(group.name)

  return (
    <Lib.S.TableViewWrapper>
      <InfiniteScroll
        dataLength={tableData.length}
        next={handleShowMore}
        hasMore={dataInformation?.total! / dataInformation.pagination.page > dataInformation.pagination.limit}
        loader={''}
        height={'90vh'}
        style={{ width: '100%' }}
      >
        <table>
          <thead>
            <tr className="header-row">
              <th>Resim</th>
              <th>
                <div>
                  <span style={{ marginRight: 8 }}>Ürün Kodu</span>
                  {sort.includes('DESC') ? (
                    <Icon name="arrow-up" size={20} style={{ cursor: 'pointer' }} onClick={() => onClickToSort('productCode', 'ASC')} />
                  ) : sort.includes('ASC') ? (
                    <Icon name="arrow-down" size={20} style={{ cursor: 'pointer' }} onClick={() => onClickToSort('productCode', 'DESC')} />
                  ) : (
                    <Icon name="arrow-down" size={20} style={{ cursor: 'pointer' }} onClick={() => onClickToSort('productCode', 'DESC')} />
                  )}
                </div>
              </th>

              {(stoneProfitPermission || group.name === 'Satış Temsilcisi') && (
                <th>
                  <div>
                    <span style={{ marginRight: 8 }}>Toplam Ağırlık (gr)</span>
                    {sort.includes('DESC') ? (
                      <Icon name="arrow-up" size={20} style={{ cursor: 'pointer' }} onClick={() => onClickToSort('weight', 'ASC')} />
                    ) : sort.includes('ASC') ? (
                      <Icon name="arrow-down" size={20} style={{ cursor: 'pointer' }} onClick={() => onClickToSort('weight', 'DESC')} />
                    ) : (
                      <Icon name="arrow-down" size={20} style={{ cursor: 'pointer' }} onClick={() => onClickToSort('weight', 'DESC')} />
                    )}
                  </div>
                </th>
              )}
              {stoneProfitPermission && (
                <th>
                  <div>
                    <span style={{ marginRight: 8 }}>Taş Karı</span>
                    {sort.includes('DESC') ? (
                      <Icon name="arrow-up" size={20} style={{ cursor: 'pointer' }} onClick={() => onClickToSort('stone_profit', 'ASC')} />
                    ) : sort.includes('ASC') ? (
                      <Icon
                        name="arrow-down"
                        size={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => onClickToSort('stone_profit', 'DESC')}
                      />
                    ) : (
                      <Icon
                        name="arrow-down"
                        size={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => onClickToSort('stone_profit', 'DESC')}
                      />
                    )}
                  </div>
                </th>
              )}
              {editIconPermission && <th></th>}
              {addToCardPermission && <th></th>}
            </tr>
          </thead>
          <tbody>
            {tableData.length
              ? tableData.map(dt => {
                  return (
                    <tr key={dt.code}>
                      <td onClick={() => dt.imageOnClick(dt.image)}>
                        <div className="image-wrapper">
                          <img src={imageAddresser(dt.image, true)} alt={dt.code} />
                        </div>
                      </td>
                      <td>{dt.code || '-'}</td>
                      {(stoneProfitPermission || group.name === 'Satış Temsilcisi') && <td>{dt.weight || '-'}</td>}

                      {stoneProfitPermission && <td>{dt.profit || '-'}</td>}
                      {editIconPermission && (
                        <td>
                          <Icon
                            name="edit_outline"
                            size={22}
                            onClick={() => window.open(`/products/edit/${dt.id}?s=${dt.status}`, '_blank')}
                            style={{ cursor: 'pointer' }}
                          />
                        </td>
                      )}
                      {addToCardPermission && (
                        <td>
                          <Icon name="cart_outline" size={22} onClick={() => dt.cardOnClick(dt.id)} style={{ cursor: 'pointer' }} />
                        </td>
                      )}
                    </tr>
                  )
                })
              : null}
          </tbody>
        </table>
      </InfiniteScroll>
    </Lib.S.TableViewWrapper>
  )
}
