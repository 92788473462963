export interface CardDetailsProps {
  title: string
  value: string
}

export type Tabs = 'daily' | 'weekly' | 'monthly' | 'yearly'

export interface DataType {
  label: string
  value: number
}

export type Property = 'daily' | 'weekly' | 'monthly' | 'yearly'

export interface ChartProps {
  tabs: {
    name: string
    column: string
    row: string
    data: DataType[]
    property: Property
  }[]
  activeTab?: number
}

export enum HeaderTabEnum {
  AREA = 'Bölge Analizi',
  URETIM = 'Üretim Analizi',
  KATEGORI = 'Ürün Analizi',
  MUSTERI = 'Müşteri Analizi',
}
export type HeaderTabType = HeaderTabEnum

export type CategoryWeightChartType = {
  id: string
  label: string
  value: number
  color: string
  isMonacoCategory?: boolean
  percentage: number
  categoryName?: string
  subCategoryName?: string
}

export type AreaWeightChartType = {
  id: string
  label: string
  value: number
  color: string
  percentage: number
  code?: string
  country?: string
  username?: string
}

export enum ChartMode {
  CATEGORY = 'category',
  SUBCATEGORY = 'subcategory',
  SUBCATEGORY_TABLE = 'subcategory_table',
}

export enum AreaChartMode {
  AREA = 'area',
  COUNTRY = 'country',
  CUSTOMER = 'customer'
}

export enum ChartHeader {
  ALL = 'Kategoriler',
  MONACO = 'MonacoChain',
  PARIS = 'PARIS',
}
