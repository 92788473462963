import { Gap } from 'common/components/ui-kit/gap'
import { Input } from 'common/components/ui-kit/input'
import { FC } from 'react'
import * as Lib from '.'

export const Contents: FC<Lib.T.ContentsProps> = ({ setSize, size, onEnter }): JSX.Element => {
  return (
    <>
      <Input label="Ölçüsü" required onEnter={onEnter} value={size || ''} onInput={evt => setSize(evt.currentTarget.value)} />
      <Gap mood="H" size={20} />
    </>
  )
}
