import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { AutomationCoffeeTime } from 'common/services'
import { useCallback, useState } from 'react'
import swal from 'sweetalert'
import * as Lib from '.'
import moment, { Moment } from 'moment'
import 'moment-timezone';

const modalInitialProps: Lib.T.modalPropsType = {
  kind: '',
  id: 0,
}

export const useCoffeeTime = () => {
  const { setLoader } = useLoader()

  const tableIdIndex = 8

  const [fetchAgain, setFetchAgain] = useState(false)
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const tableColumns = ['Başlangıç', 'Bitiş', '']
  const tableSizes = ['unset', 'unset', '7%']
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalProps, setModalProps] = useState<Lib.T.modalPropsType>(modalInitialProps)
  const [showModal, setShowModal] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [startHour, setStartHour] = useState<string | undefined | Moment>('')
  const [endHour, setEndHour] = useState<string | undefined | Moment>('')

  const getCoffeeTimes = async () => {
    const service = new AutomationCoffeeTime()
    setLoader(true)
    const { success, coffeeTimes } = await service.getDrinkTypes()
    setLoader(false)
    if (success && coffeeTimes) {
      setTableData(prevData => [
        ...coffeeTimes.map(coffeeTime => {
          return [
            <div>{moment.utc(coffeeTime.start, 'HH:mm:ssZ').tz("Europe/Istanbul").format('HH:mm')}</div>,
            <div>{moment.utc(coffeeTime.end, 'HH:mm:ssZ').tz("Europe/Istanbul").format('HH:mm')}</div>,
            <Lib.S.tableButtons>
              <Lib.C.TableButton
                icon="trash_bin"
                callback={() => {
                  setShowDeleteModal(true)
                  setModalProps({ ...modalInitialProps, kind: 'delete', id: coffeeTime.id })
                }}
              />
            </Lib.S.tableButtons>,
          ]
        }),
      ])
    }
  }

  const deleteCoffeeTime = useCallback(
    async (id: number) => {
      const service = new AutomationCoffeeTime()
      setShowDeleteModal(false)
      setLoader(true)
      try {
        const success = await service.deleteCoffeeTime(id)
        if (success) {
          swal({ className: 'rounded-swal', icon: 'success', timer: 700, buttons: [false] }).then(() => {
            setShowDeleteModal(false)
          })
          setFetchAgain(prev => !prev)
        }
      } catch (error) {
        console.error('Failed to edit/create neighbourhood: ', error)
      } finally {
        setLoader(false)
      }
    },
    [modalProps],
  )

  const CreateCoffeeTime = async () => {
    const service = new AutomationCoffeeTime()
    const start = moment(startHour, 'HH:mm')
    const end = moment(endHour, 'HH:mm')
    const isAfterHour = start.isAfter(end)

    if (!Boolean(startHour) || !Boolean(endHour)) {
      swal({
        text: 'lütfen tüm alanı doldurun.',
        icon: 'error',
      })
      setIsEmpty(true)
      return
    }

    if (isAfterHour) {
      swal({
        text: 'Başlangıç ​​saati bitiş saatinden büyük olamaz',
        icon: 'error',
      })
      return
    }

    const turkeyMomentStart = moment.tz(moment(startHour).format('HH:mm'), 'HH:mm', 'Europe/Istanbul');
    const gmtMomentStart = turkeyMomentStart.clone().tz('Etc/GMT');
    const convertStartHour = gmtMomentStart.format('HH:mm');

    const turkeyMomentEnd = moment.tz(moment(endHour).format('HH:mm'), 'HH:mm', 'Europe/Istanbul');
    const gmtMomentEnd = turkeyMomentEnd.clone().tz('Etc/GMT');
    const convertEndHour = gmtMomentEnd.format('HH:mm');

    const args = {
      start: convertStartHour,
      end: convertEndHour,
    };

    const success = await service.createCoffeeTime(args)

    if (success) {
      swal({
        className: 'rounded-swal',
        icon: 'success',
        timer: 700,
        buttons: [false],
      }).then(() => {
        handleModal()
      })
      setLoader(false)
    }

    setFetchAgain(prev => !prev)
    setLoader(false)
  }

  const handleDeleteModalVisibility = useCallback(() => {
    setShowDeleteModal(prev => !prev)
  }, [])

  const handleModal = () => {
    setShowModal(false)
    setModalProps(modalInitialProps)
    setIsEmpty(false)
    setStartHour('')
    setEndHour('')
  }

  useAsyncEffect(getCoffeeTimes, [fetchAgain])

  return {
    val: {
      tableData,
      tableColumns,
      tableSizes,
      showDeleteModal,
      modalProps,
      tableIdIndex,
      showModal,
      isEmpty,
      startHour,
      endHour,
    },

    set: {
      setFetchAgain,
      setShowDeleteModal,
      setModalProps,
      setShowModal,
      setStartHour,
      setEndHour,
    },

    on: {
      deleteCoffeeTime,
      handleDeleteModalVisibility,
      handleModal,
      CreateCoffeeTime,
    },
  }
}
