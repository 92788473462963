import styled from 'styled-components'
import * as Lib from '.'
import { mediaQueries as mq } from 'common/constants/media-queries'

export const container = styled.div<Pick<Lib.T.TableProps, 'rowBackColor' | 'rowBorder'>>`
  float: left;
  width: 100%;
  background-color: white;
  max-width: 100%;
  border-radius: 15px;
  overflow-y: scroll;
  position: relative;
  z-index: 1;

  > .tableContainer {
    display: table;
    width: 100%;

    .tr {
      display: table-row;

      &.body {
        position: relative;

        &.disabled {
          color: #afafaf;
        }

        > .td:first-child > .content {
          border-radius: 5px 0 0 5px;
        }
        > .td:last-child > .content {
          border-radius: 0 5px 5px 0;
        }

        &.filterContainer {
          height: 75px;
        }
      }
    }
    .head-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;
      padding-top: 10px;
      gap: 3px;
      margin-top: -5px;

      span {
        font-size: 13px;
        > svg {
          margin-bottom: 1px;
        }
      }

      p {
        flex: 1;
        margin: 0;
        display: inline-block;
        font-size: 11pt;
      }
    }

    .td {
      display: table-cell;
      padding: 0;
      border-bottom: ${({ rowBorder }) => (rowBorder ? '1px solid #004E421A' : 'unset')};
      background-color: white;
      vertical-align: middle;
      transition: all 0.3s ease-out;

      &.header {
        color: black;
        border-bottom: 2px solid #004e424d;
        position: sticky;
        top: 0px;

        padding: 10px;
        font-family: var(--f-bo);
        font-size: 16px;
        background-color: white;
        z-index: 10000;

        &.true {
          cursor: pointer;
        }

        > span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px 0 0;
        }

        > p {
          margin: 0;
          display: inline-block;
          font-size: 11pt;
        }
      }

      &.footer {
        background-color: white;
        color: var(--accent);
        border-top: 2px solid #004e424d;
        position: sticky;
        padding: 10px 15px 20px 15px;
        bottom: 0px;
        font-family: var(--f-bo);
        font-size: 16px;
      }

      > .content {
        background-color: ${({ rowBackColor }) => (rowBackColor ? '#F8F8F8' : 'transparent')};
        padding: 10px;

        ${mq.max[992]} {
          padding: 8px;
        }

        &.true {
          padding: 0;
        }

        .tableButton {
          border: none;
          padding: 5px 15px;
          border-radius: 10px;
          background-color: transparent;
          transition: all 150ms linear;
          display: inline-block;

          &:hover {
            background-color: #eaeaea;
          }
        }
      }
    }

    .filter {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        width: unset;

        > .parent {
          padding: 10px 30px;
          min-height: unset;
        }
      }
    }
  }
  .backgroundWhite {
    .content {
      background-color: #fff !important;
    }
  }
`
