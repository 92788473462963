import styled from 'styled-components'
import { mediaQueries as mq } from 'common/constants/media-queries'

export const cardContainer = styled.div<{ disabled: boolean | undefined }>`
  float: left;
  height: 80px;
  padding: 5px !important;
  width: 20%;

  .wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 22px 15px;
    border-radius: 9px;
    background-color: ${props => (props.disabled ? 'rgba(255, 255, 255, 0.5)' : 'white')};

    > a {
      float: left;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 18px;
      text-decoration: none;
      color: ${props => (props.disabled ? ' rgba(77, 77, 77, 0.2)' : 'black')};
      transition: all 150ms linear;
      text-align: center;
      cursor: unset !important;
    }
    &:hover {
      ${props => (props.disabled ? '' : 'box-shadow: 0 0 20px 2px #0000000d;')}
    }
  }

  ${mq.max[1200]} {
    width: 25%;
  }

  ${mq.max[992]} {
    width: 33.3333333%;
  }

  ${mq.max[768]} {
    width: 50%;
  }

  ${mq.max[576]} {
    width: 100%;
  }
`
