import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import { DOM } from 'common/helpers/dom'
import swal from 'sweetalert'

export class SubCategoryType {
  accessToken: string
  endpoint = 'sub-category-type'
  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      SubCategoryType.dontAccept()
    }
    if (!user!.accessToken) {
      SubCategoryType.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async create({ image, title, parent, baklas }: Lib.T.SubCategoryType.CreateArgs): Promise<Lib.T.SubCategoryType.CreateResult> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: {
        image,
        title,
        parent,
        baklas,
      },
    })

    const { status, data } = response
    if (status !== 201 || !data.subCategoryType) {
      swal({
        title: 'Alt kategori oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, data: data.subCategoryType }
  }

  async getList(args?: Lib.T.SubCategoryType.GetListArgs): Promise<Lib.T.SubCategoryType.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response

    if (status !== 200) {
      swal({
        title: 'Alt kategori oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, types: data.subCategoryTypes }
  }

  async delete(id: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/${id}`),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      swal({
        title: 'Alt kategori silinemedi. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
    }

    return status === 200
  }

  async edit(id: number, args: Lib.T.SubCategoryType.EditArgs): Promise<Lib.T.SubCategoryType.EditResult> {
    const { status, data } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    })

    if (status !== 200 || !data.subCategoryType) {
      swal({
        text: 'Alt kategori düzenlenemedi. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })

      return { success: false }
    }

    return { success: true, data: data.subCategoryType }
  }

  static dontAccept() {
    throw 'not-acceptable'
  }
}
