export const PIECES_TR = ['Bileklik', 'Yüzük', 'Kolye', 'Küpe', 'Kelepçe', 'Kolye Ucu'] as const

export const PIECES_EN = ['bracelet', 'ring', 'necklace', 'ear-rings', 'cuff', 'pendant'] as const

export const PIECES = {
  TR: PIECES_TR,
  EN: PIECES_EN,
} as const

export type PIECES_TR = typeof PIECES_TR[number]
export type PIECES_EN = typeof PIECES_EN[number]
export type PIECES = typeof PIECES[][number]
