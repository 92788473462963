import { FC } from 'react'
import { Icon } from '../icon'
import * as Lib from './lib'
import { Modal } from 'antd'
import { Button } from 'common/components/ui-kit/button'
import { Scrollbar } from 'react-scrollbars-custom'
import { Popover } from 'antd'
import { PaginationLoading } from '../pagination-loading'

export const List: FC<Lib.T.ListProps> = ({ items, border, onDelete, confirmText, buttons, edit, pagination }): JSX.Element => {
  const { deleteModal, editModalProps, imgModal, on } = Lib.H.useList(onDelete, edit)

  return (
    <>
      <Modal {...deleteModal.props}>
        <Lib.S.ModalContent>
          <p>{confirmText || 'Kaydı kalıcı olarak silmek istediğinizden emin misiniz?'}</p>
          <div className="buttons">
            <Button text="Vazgeç" mood="orang" callback={deleteModal.toggle} />
            <Button text="Sil" mood="accent" callback={on.deleteHandler} />
          </div>
        </Lib.S.ModalContent>
      </Modal>

      <Modal {...imgModal.props}>
        <img src={imgModal.val.img} style={{ width: '100%' }} alt="" />
      </Modal>
      {edit && (
        <Modal {...editModalProps.props} key={items.length}>
          <Lib.S.ModalContent>
            <div className="inputs">{edit.content}</div>
            <div className="buttons">
              <Button text="Vazgeç" mood="orang" callback={editModalProps.toggle} />
              <Button text="Düzenle" mood="accent" callback={on.editHandler} />
            </div>
          </Lib.S.ModalContent>
        </Modal>
      )}
      <Lib.S.listContainer border={border}>
        <Scrollbar style={{ width: '100%', height: 620 }}>
          <div className="scrollSection">
            {items.map((item, index) => {
              return (
                <div className="item" key={index}>
                  {item.imageThumbnail && <img src={item.imageThumbnail} alt={''} onClick={() => imgModal.set({ status: true, img: item.image || '' })} />}

                  {item.color && (
                    <Popover content={item.color}>
                      <span className="color" style={{ background: item.color }} />
                    </Popover>
                  )}

                  <p>
                    <span>{item.name}</span>
                    {item.description && <ins>{item.description}</ins>}
                  </p>

                  {buttons &&
                    buttons.map(
                      ({ callback, icon, styles, enabled }, index) =>
                        enabled &&
                        enabled(parseInt(item.id.toString())) && (
                          <span onClick={() => callback(parseInt(item.id + ''))} style={styles} className="otherButtons" key={index}>
                            <Icon name={icon} size={16} />
                          </span>
                        ),
                    )}

                  <span onClick={() => on.confirmDelete(parseInt(item.id + ''))}>
                    <Icon name="trash_bin" size={16} />
                  </span>

                  {edit && (
                    <span onClick={() => on.confirmEdit(item)} className="otherButtons">
                      <Icon name="edit_outline" size={16} />
                    </span>
                  )}
                </div>
              )
            })}

            <div className="pagination">
              {pagination &&
                (pagination.loading ? (
                  <PaginationLoading />
                ) : items.length < pagination.total ? (
                  <Button text="Daha fazla göster" callback={pagination.onLoadMore} mood="accent" />
                ) : (
                  <Button text="daha fazla yok" callback={() => {}} mood="gray" />
                ))}
            </div>
          </div>
        </Scrollbar>
      </Lib.S.listContainer>
    </>
  )
}
