import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { useHistory } from 'react-router-dom'
import * as Lib from './lib'
function Unauthorized() {
  const history = useHistory()
  return (
    <PanelWrapper tab={['unauthorized', '/unauthorized']}>
      <Lib.S.PageWrapper>
        <img src="/images/svg/403.svg" alt="" />
        <p>Sorry, but you don’t have permission to access this page. You can go back to previous page!</p>

        <Button
          text="Go Back"
          mood="accent"
          className="btn"
          callback={() => {
            history.goBack()
          }}
        />
      </Lib.S.PageWrapper>
    </PanelWrapper>
  )
}

export default Unauthorized
