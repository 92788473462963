import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { NewsAndAnnouncements } from 'common/services'
import { newsAndAnnouncementsArgs } from 'common/services/lib/types/newsAndAnnouncements'
import { useCallback, useEffect, useState } from 'react'
import swal from 'sweetalert'
import * as Lib from '.'
import { Switch } from 'antd'
import moment from 'moment'

const modalInitial: Lib.T.modalProps = {
  kind: '',
  id: 0,
  editProps: {
    announcements: [
      {
        id: 0,
        type: '',
        title: '',
        description: '',
        active: false,
        deleted: false,
        writer: {
          id: 0,
          firstName: '',
          lastName: '',
        },
        createdAt: '',
        updatedAt: '',
      },
    ],
  },
}

export const useAutomationNews = () => {
  const { setLoader } = useLoader()

  const tableIdIndex = 8

  const [paginate, setPaginate] = useState({ limit: 30, page: 1 })
  const [totalData, setTotalData] = useState<number | undefined>()
  const [fetchAgain, setFetchAgain] = useState(false)
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const tableColumns = ['Başlık', 'Açıklama', 'Tarih', '']
  const tableSizes = ['unset', 'unset', 'unset', '7%']
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalProps, setModalProps] = useState<Lib.T.modalProps>(modalInitial)
  const [paginateLoader, setPaginateLoader] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [isActive, setIsActive] = useState(false)

  const getNews = async () => {
    const service = new NewsAndAnnouncements()
    setLoader(true)
    const { success, totalData, news } = await service.getAnnouncementsAndNews({
      limit: paginate.limit,
      type: 'news',
      isDeleted: false,
    })
    setLoader(false)
    if (success && news) {
      setTotalData(parseInt(totalData! + ''))
      setTableData(prevData => [
        ...news.map(newsItem => {
          return [
            <div>{newsItem.title.length > 25 ? `${newsItem.title.substring(0, 25)} ...` : newsItem.title}</div>,
            <div>{newsItem.description.length > 60 ? `${newsItem.description.substring(0, 60)} ...` : newsItem.description}</div>,
            <div>{moment(newsItem?.createdAt).format('DD/MM/YYYY')}</div>,
            <Lib.S.tableButtons>
              <Switch
                defaultChecked={newsItem.active}
                size="small"
                className="me-1"
                onChange={() => handleSwitchBtn(newsItem.id, newsItem.active)}
              />
              <Lib.C.TableButton icon="edit_outline" callback={() => getOneNews(newsItem.id)} />
              <Lib.C.TableButton
                icon="trash_bin"
                callback={() => {
                  setShowDeleteModal(true)
                  setModalProps({ ...modalInitial, kind: 'delete', id: newsItem.id })
                }}
              />
            </Lib.S.tableButtons>,
          ]
        }),
      ])
    }
  }

  const handleSwitchBtn = async (id: number, active: boolean) => {
    const service = new NewsAndAnnouncements()
    setLoader(true)
    const { success } = await service.putAnnouncementsAndNews({ active: !active }, id)
    setLoader(false)
  }

  const getOneNews = async (id: number) => {
    const service = new NewsAndAnnouncements()
    setLoader(true)
    const { success, newsItem } = await service.getOneAnnouncementAndNews(id)
    setLoader(false)
    if (success && newsItem) {
      setShowModal(true)
      setModalProps({
        ...modalInitial,
        kind: 'edit',
        id: newsItem?.id,
        editProps: {
          announcements: [
            {
              title: newsItem?.title,
              description: newsItem?.description,
              active: newsItem?.active,
              createdAt: newsItem?.createdAt,
            },
          ],
        },
      })
    }
  }

  const DeleteNews = useCallback(
    async (id: number) => {
      const service = new NewsAndAnnouncements()
      setShowDeleteModal(false)
      setLoader(true)
      try {
        const success = await service.delete(id)

        if (success) {
          swal({ className: 'rounded-swal', icon: 'success', timer: 700, buttons: [false] }).then(() => {
            setShowDeleteModal(false)
          })
          setFetchAgain(prev => !prev)
        }
      } catch (error) {
        console.error('Failed to edit/create neighbourhood: ', error)
      } finally {
        setLoader(false)
      }
    },
    [modalProps],
  )

  const validateArgs = (item: Lib.T.news): boolean => {
    const hasEmptyFields = !item.title.trim() || !item.description.trim()

    if (hasEmptyFields) {
      swal({
        text: 'Lütfen tüm alanları doldurun.',
        icon: 'error',
      })
      setIsEmpty(true)
      return false
    }

    setIsEmpty(false)
    return true
  }

  const handleSwal = (success: boolean, onSuccess: () => void) => {
    if (success) {
      swal({
        className: 'rounded-swal',
        icon: 'success',
        timer: 700,
        buttons: [false],
      }).then(() => {
        onSuccess()
      })
      setLoader(false)
      handleModal()
    }
  }

  const handleNewsOperation = async (operation: 'create' | 'edit', id?: number) => {
    const service = new NewsAndAnnouncements()
    const newsData = {
      title: title,
      description: description,
    }

    if (!validateArgs(newsData)) {
      setLoader(false)
      return
    }

    setLoader(true)

    const args: newsAndAnnouncementsArgs = {
      type: 'news',
      title: title,
      description: description,
      active: isActive,
    }

    let success = false

    if (operation === 'create') {
      success = await service.create(args)
    } else if (operation === 'edit' && id !== undefined) {
      const response = await service.putAnnouncementsAndNews(args, id)
      success = response.success
    }

    handleSwal(success, () => {
      setTitle('')
      setDescription('')
      setIsActive(false)
      setShowModal(false)
    })

    setFetchAgain(prev => !prev)
    setLoader(false)
  }

  const CreateNews = async () => {
    await handleNewsOperation('create')
  }

  const EditNews = async (id: number) => {
    await handleNewsOperation('edit', id)
  }

  const handleShowMore = () => {
    if (totalData! / paginate.page <= paginate.limit) {
      return
    }
    setPaginateLoader(true)
    setPaginate(prev => {
      return { ...prev, limit: prev.limit + 30 }
    })
  }

  const handleDeleteModalVisibility = useCallback(() => {
    setShowDeleteModal(prev => !prev)
  }, [])

  const handleModal = () => {
    setShowModal(false)
    setModalProps(modalInitial)
    setTitle('')
    setDescription('')
    setIsActive(false)
  }

  useAsyncEffect(getNews, [paginate, fetchAgain])

  useEffect(() => {
    setTitle(modalProps?.editProps?.announcements?.[0].title ?? '')
    setDescription(modalProps?.editProps?.announcements?.[0].description ?? '')
  }, [modalProps])

  return {
    val: {
      tableData,
      tableColumns,
      tableSizes,
      showDeleteModal,
      modalProps,
      totalData,
      paginateLoader,
      tableIdIndex,
      showModal,
      isEmpty,
      title,
      description,
      isActive,
    },

    set: {
      setFetchAgain,
      setShowDeleteModal,
      setModalProps,
      setShowModal,
      setTitle,
      setDescription,
      setIsActive,
    },

    on: {
      DeleteNews,
      handleDeleteModalVisibility,
      handleShowMore,
      handleModal,
      CreateNews,
      EditNews,
    },

    get: {
      paginate,
      totalData,
    },
  }
}
