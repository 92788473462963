import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Content } from 'common/components/ui-kit/content'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Table } from 'common/components/ui-kit/table-2'
import { useHistory } from 'react-router-dom'
import * as Lib from './lib'

const AutomationDepartmentList = () => {
  const { get, on } = Lib.H.useTable()
  const history = useHistory()

  return (
    <>
      <PanelWrapper tab={['automation', '/automation/departments']} title="Department">
        <Sticky>
          <Lib.S.searchSection>
            <p className="title">Department Listesi</p>
            <div style={{ flex: 0.2 }}>
              <Button
                mood="accent"
                callback={() => history.push('/automation/department/create')}
                text="Departman Oluştur"
                containerStyles={{ minHeight: 42 }}
              />
            </div>
          </Lib.S.searchSection>
        </Sticky>

        <Content padding="0">
          <Lib.S.container>
            <Lib.S.contentBody>
              <Lib.S.content>
                <div className="tableContainer">
                  <Table
                    columns={get.tableColumns}
                    columnSizes={get.tableSizes}
                    data={get.tableData}
                    rowBackColor={false}
                    onLoadMore={on.handleShowMore}
                    totalData={get.totalData}
                    paginate={get.paginate}
                    disabledIndexes={[get.tableIdIndex, get.tableIdIndex - 1]}
                    unActivatedIndex={get.tableIdIndex - 1}
                    maxHeight={get.tableData.length * 70 + 70 + 47 > 1400 ? 1400 + 'px' : get.tableData.length * 70 + 70 + 47 + 'px'}
                  />
                </div>
              </Lib.S.content>
            </Lib.S.contentBody>
          </Lib.S.container>
        </Content>
      </PanelWrapper>
    </>
  )
}

export default AutomationDepartmentList
