import { FavoriteService } from 'common/services/favorite'
import { FavoriteAd } from 'common/services/lib/types/favorite'
import { useEffect, useState } from 'react'

export const usePDF = () => {
  const [favoriteAds, setFavoriteAds] = useState<FavoriteAd[] | undefined>()

  useEffect(() => {
    fetchFavoriteProducts()
  }, [])

  async function fetchFavoriteProducts() {
    const service = new FavoriteService()

    try {
      const { success, favorite } = await service.getFavorites()

      if (success) {
        setFavoriteAds(favorite!)
      }
    } catch (e) {}
  }

  return {
    get: { favoriteAds },
    set: {},
    on: {},
  }
}
