export interface MonthObject {
  [month: string]: number
}

export const monthDict: Record<string, string> = {
  January: 'Ocak',
  February: 'Şubat',
  March: 'Mart',
  April: 'Nisan',
  May: 'Mayıs',
  June: 'Haziran',
  July: 'Temmuz',
  August: 'Ağustos',
  September: 'Eylül',
  October: 'Ekim',
  November: 'Kasım',
  December: 'Aralık',
}
