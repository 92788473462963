import { Modal, Switch } from 'antd'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { AvatarUploader } from 'common/components/ui-kit/avatar-uploader'
import { Button } from 'common/components/ui-kit/button'
import { Checkbox } from 'common/components/ui-kit/checkbox'
import { Content } from 'common/components/ui-kit/content'
import { DropDown } from 'common/components/ui-kit/dropdown'
import { Icon } from 'common/components/ui-kit/icon'
import { Input } from 'common/components/ui-kit/input'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Table } from 'common/components/ui-kit/table'
import { Managers } from 'common/routes/types/roles.type'
import { RootState } from 'common/store/root-reducer'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import * as Lib from './lib'

export const CreateCustomer: FC = ({}) => {
  const { get, set, on } = Lib.H.useCreateCustomer()

  const { user } = useSelector((state: RootState) => state.globals)

  return (
    <>
      <Modal {...get.employeeModal.props}>
        <Lib.S.employeeModal>
          <div className="closeIcon">
            <span onClick={() => get.employeeModal.toggle()}>
              <Icon name="close" color="var(--accent)" size={15} />
            </span>
          </div>

          <p className="header">Kişi Ekle</p>

          <div className="row">
            <div className="col-lg-6 col-md-12">
              <Input
                label="Adı"
                required
                value={get.employee.firstName}
                onInput={evt =>
                  set.setEmployee({
                    ...get.employee,
                    firstName: evt.currentTarget.value,
                  })
                }
                id="employeeFirstName"
                onEnter={on.addOrEditEmployee}
                autoFocus
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <Input
                label="Soyadı"
                required
                value={get.employee.lastName}
                onInput={evt =>
                  set.setEmployee({
                    ...get.employee,
                    lastName: evt.currentTarget.value,
                  })
                }
                onEnter={on.addOrEditEmployee}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12">
              <Input
                label="Email"
                required
                value={get.employee.email}
                onInput={evt =>
                  set.setEmployee({
                    ...get.employee,
                    email: evt.currentTarget.value,
                  })
                }
                onEnter={on.addOrEditEmployee}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="row">
                <div className="col-sm-4" style={{ paddingRight: 0 }}>
                  <DropDown options={Lib.Const.code} setValue={evt => set.setCode(evt)} value={get.code} label="Kod *" />
                </div>
                <div className="col-sm-8">
                  <Input
                    label="Telefon"
                    required
                    value={get.employee.phone.split(' ')[1]}
                    onInput={evt =>
                      set.setEmployee({
                        ...get.employee,
                        phone: get.code + ' ' + evt.currentTarget.value,
                      })
                    }
                    onEnter={on.addOrEditEmployee}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 button">
              <Button mood="accent" text="Kişi Ekle" callback={on.addOrEditEmployee} />
            </div>
          </div>
        </Lib.S.employeeModal>
      </Modal>

      <Modal {...get.employeeDeleteModal.props}>
        <div className="deleteModal">
          <p>Kullanıcıyı silmek istediğinizden emin misiniz?</p>
          <div className="buttons">
            <Button text="Vazgeç" callback={get.employeeDeleteModal.toggle} mood="orang" />
            <Button text="Onayla" callback={on.deleteEmployee} mood="accent" />
          </div>
        </div>
      </Modal>

      <Modal {...get.customerDeleteModal.props}>
        <div className="deleteModal">
          <p>Müşteri silmek istediğinizden emin misiniz?</p>
          <div className="buttons">
            <Button text="Vazgeç" callback={get.customerDeleteModal.toggle} mood="orang" />
            <Button text="Onayla" callback={on.deleteCustomer} mood="accent" />
          </div>
        </div>
      </Modal>

      <PanelWrapper tab={['customers', '/customers/create']}>
        <Sticky>
          <p className="title">Müşteri Oluştur</p>
          <div
            style={{
              display: 'flex',
              gap: 5,
              width: Managers.includes(user.group.name) && get.editMode && get.userStatus === 'pending' ? '30%' : '10%',
            }}
          >
            {get.editMode && get.status === 'pending' && Managers.includes(user.group.name) ? (
              <Button
                className="delete"
                mood="danger"
                callback={() => on.toggleDeleteModal()}
                text="Sil"
                icon="trash_bin"
                iconProps={{ color: '#fff', size: 18, style: { marginRight: '5px' } }}
              />
            ) : null}
            <Button text="Kaydet" callback={on.createOrEdit} mood="accent" className="submitButton" />
          </div>
        </Sticky>
        <Content>
          <Lib.S.container>
            <div className="row">
              <div className="col-lg-2 text-left position-relative">
                <AvatarUploader
                  id="customer-avatar"
                  onChange={set.setAvatar}
                  style={{ display: 'inline-block' }}
                  defaultImage={get.defaultAvatar ? get.defaultAvatar : ''}
                />

                <label className="personal switchContainer">
                  <span>Personal:</span>
                  <Switch checked={get.isPersonal} onChange={v => set.setIsPersonal(v)} disabled={get.editMode} />
                </label>
                <label className="activity switchContainer">
                  <span>Aktiflik:</span>
                  <Switch checked={get.isActive} onChange={v => set.setIsActive(v)} />
                </label>
              </div>

              <div className="col-lg-3">
                <div className="row">
                  <div className="col-lg-12">
                    <Input
                      label="Firma"
                      required
                      value={get.name}
                      onEnter={on.createOrEdit}
                      onInput={evt => {
                        if (get.editMode && user.group.name === 'Satış Temsilcisi') return
                        set.setName(evt.currentTarget.value)
                      }}
                    />
                  </div>
                  <div className="col-lg-12">
                    <Input
                      label="Şifre"
                      required
                      onEnter={on.createOrEdit}
                      value={get.password}
                      onInput={evt => set.setPassword(evt.currentTarget.value)}
                      type="password"
                      autoComplete="false"
                      aria-autocomplete="none"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <Input
                          label="Firma Email"
                          required
                          onEnter={on.createOrEdit}
                          value={get.email}
                          onInput={evt => set.setEmail(evt.currentTarget.value)}
                        />
                      </div>
                      <Input
                        label="Şifre Tekrar"
                        required
                        onEnter={on.createOrEdit}
                        value={get.confPassword}
                        onInput={evt => set.setConfPassword(evt.currentTarget.value)}
                        type="password"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <DropDown
                          options={get.countries}
                          setValue={evt => set.setCountry(evt)}
                          value={get.country}
                          label="Ülke"
                          height="45px"
                          required
                        />
                      </div>
                      <div className="col-lg-12">
                        <DropDown
                          options={get.countries}
                          setValue={set.setSaleCountry}
                          value={get.saleCountry}
                          label="Sattığı Pazar"
                          mode="multiple"
                          height="45px"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <Input
                          label="Kullanıcı Adı"
                          required
                          value={get.username}
                          onInput={evt => {
                            //if (get.editMode && user.group.name === 'Satış Temsilcisi') return
                            set.setUsername(evt.currentTarget.value)
                          }}
                        />
                      </div>
                      <div className="col-lg-12">
                        <DropDown
                          options={get.carats}
                          height="45px"
                          setValue={set.setCarat}
                          value={get.carat}
                          label="Sattığı Ayar"
                          mode="multiple"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <Input
                  label="Adres"
                  required
                  onEnter={on.createOrEdit}
                  value={get.address}
                  onInput={evt => set.setAddress(evt.currentTarget.value)}
                />
              </div>
              <div className="col-lg-2">
                {Managers.includes(user.group.name) && (
                  <DropDown
                    label="Satış Sorumlusu"
                    required
                    options={get.salePersons.map(user => user.name)}
                    mode="multiple"
                    height="45px"
                    value={get.selectedSalePerson.map(user => user.title)}
                    setValue={value => {
                      const selected: { id: number; title: string }[] = []
                      value.forEach((item: string) => {
                        const user = get.salePersons.find(user => user.name === item)
                        if (user) selected.push({ id: user.id, title: user.name })
                      })
                      set.setSelectedSalePerson(selected)
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-3">
                <Input
                  label="Ad"
                  required
                  onEnter={on.createOrEdit}
                  value={get.firstName}
                  onInput={evt => set.setFirstName(evt.currentTarget.value)}
                />
              </div>
              <div className="col-lg-3">
                <Input
                  label="Soyad"
                  required
                  onEnter={on.createOrEdit}
                  value={get.lastName}
                  onInput={evt => set.setLastName(evt.currentTarget.value)}
                />
              </div>
              <div className="col-lg-3">
                <Input
                  label="Telefon"
                  required
                  onEnter={on.createOrEdit}
                  value={get.phone}
                  minLength={13}
                  onInput={evt => set.setPhone(evt.currentTarget.value)}
                />
                <span style={{fontSize: 13, color: '#7a7a7a'}}>Örnek: +909999999999</span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-3">
                <div className="d-flex justify-content-between align-items-center isLogin">
                  <span>Giriş yapabilir</span>
                  <Checkbox
                    label=""
                    name=""
                    onChange={e => {
                      set.setCanLogin(e.target.checked)
                    }}
                    checked={get.canLogin}
                    style={{ width: 'auto', marginRight: 5 }}
                  />
                </div>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-lg-12">
                <Table
                  columns={[
                    'Ad',
                    'Soyad',
                    'Telefon',
                    'Email',
                    <div className="employeeAdder">
                      <span
                        onClick={() => {
                          get.employeeModal.toggle()
                          set.setEmployeeEditMode(false)
                        }}
                      >
                        <Icon name="plus" color="var(--accent)" size={10} />
                      </span>
                    </div>,
                  ]}
                  data={get.employeesTableList}
                  viewButton="members/edit"
                  maxHeight={get.employees?.length * 55 + 70 + 'px'}
                />
              </div>
            </div>
            <Lib.S.SelectCategories>
              {get.categoriesState.map(cat => {
                return (
                  <div className="wrapper">
                    <div className="cat-item parent">
                      <span>{cat.name}</span>
                      <Checkbox
                        label=""
                        checked={cat.isSelected}
                        color="white"
                        onChange={e => {
                          set.setCategoriesState(perv => {
                            return perv.map(p => {
                              if (p.id === cat.id) {
                                p.isSelected = !p.isSelected
                                p.subItems.map(sub => (sub.isSelected = p.isSelected))
                              }
                              return p
                            })
                          })
                        }}
                      />
                    </div>
                    {cat.subItems.map(sub => {
                      return (
                        <div className="cat-item">
                          <span>{sub.name}</span>
                          <Checkbox
                            label=""
                            color="accent"
                            checked={sub.isSelected}
                            onChange={() => {
                              set.setCategoriesState(perv => {
                                return perv.map(p => {
                                  p.subItems.map(pervSub => {
                                    if (pervSub.isParent === p.id) {
                                      if (p.subItems.every(x => !x.isSelected)) {
                                        p.isSelected = false
                                      }
                                    }

                                    if (pervSub.id === sub.id) {
                                      pervSub.isSelected = !pervSub.isSelected
                                      p.isSelected = true
                                    }
                                    return pervSub
                                  })
                                  return p
                                })
                              })
                            }}
                          />
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Lib.S.SelectCategories>
          </Lib.S.container>
          <Modal {...get.deleteModalProps}>
            <Lib.S.deleteModalContent>
              <p>Müşteri kalıcı olarak silinecektir, Emin misiniz?</p>
              <div>
                <Button text="Onayla" mood="accent" callback={on.deleteCustomer} />
                <Button text="İptal" mood="danger" callback={on.toggleDeleteModal} />
              </div>
            </Lib.S.deleteModalContent>
          </Modal>
        </Content>
      </PanelWrapper>
    </>
  )
}
