import swal from 'sweetalert'

export const doneTaskSuccessfully = () => {
  return swal({
    className: 'rounded-swal',
    icon: 'success',
    timer: 700,
    buttons: [false],
  })
}
