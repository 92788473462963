import styled from 'styled-components'
import * as Lib from '.'

export const inputContainer = styled.div<Lib.T.ColorPickerProps>`
  position: relative;
  float: left;
  width: 100%;

  .sketch-picker {
    float: left;
    margin: 10px 0 0 0;
  }

  .pound {
    position: absolute;
    left: 12px;
    top: 8px;
    font-size: 21px;
    z-index: 1;
    color: #4d4d4d80;
  }

  .colorInput {
    border-width: 1px !important;
    width: 220px;
    color: #4d4d4d80;
    padding: 6px 30px 6px 33px;
    font-size: 14pt;
    text-align: center;
  }
`

export const nameInput = styled.div`
  float: left;
  width: 100%;
  margin: 0 0 14px 0;
`
