import React from 'react'
import { Gap } from 'common/components/ui-kit/gap'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from '.'

export const Contents: React.FC<Lib.T.ContentsProps> = ({ code, onEnter }): JSX.Element => (
  <>
    <Input
      label="Kodları"
      required
      onEnter={onEnter}
      value={code.val || ''}
      onInput={evt => code.set(evt.currentTarget.value)}
      capitalize
    />
    <Gap mood="H" size={20} />
  </>
)
