import { Icon } from 'common/components/ui-kit/icon'
import { FC } from 'react'
import * as Lib from '.'

export const TableImage: FC<Lib.T.TableImageProps> = ({ src, alt }): JSX.Element => {
  return (
    <>
      <Lib.S.tableImage>
        <img src={src} alt={alt} />
      </Lib.S.tableImage>
    </>
  )
}

export const TableButton: FC<Lib.T.TableButtonProps> = ({ callback, icon, color, className }): JSX.Element => {
  return (
    <>
      <Lib.S.tableButton className={className} onClick={e => callback(e)}>
        <Icon name={icon} color={color} size={20} />
      </Lib.S.tableButton>
    </>
  )
}
