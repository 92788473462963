import { Gap } from 'common/components/ui-kit/gap'
import { Input } from 'common/components/ui-kit/input'
import { FC } from 'react'
import { SketchPicker } from 'react-color'
import * as Lib from '.'

export const Contents: FC<Lib.T.ContentsProps> = ({ color, onEnter }): JSX.Element => {
  return (
    <>
      <Lib.S.inputContainer className="section col-lg-6 col-md-12">
        <span className="pound">#</span>
        <Input
          className="colorInput"
          required
          onEnter={onEnter}
          value={color.val.replace('#', '')}
          onInput={evt => color.onChange(evt, color.set)}
        />
        <SketchPicker color={color.val} onChange={colorObj => color.set(colorObj.hex)} />
        <Gap mood="H" size={20} />
      </Lib.S.inputContainer>
    </>
  )
}
