import { FormEvent, useState, useEffect } from 'react'
import { Global } from 'common/store/actions'
import { useDispatch } from 'react-redux'
import { OptionService } from 'common/services'
import { UNHANDLED } from 'common/constants/errors'
import { ListObject } from 'common/components/ui-kit/list/lib/typing'
import * as Lib from '.'
import swal from 'sweetalert'

export const useColorCategory = () => {
  const [color, setColor] = useState<string>('#fff')

  const [editColor, setEditColor] = useState<string>('#fff')
  const [itemIdToEdit, setItemIdToEdit] = useState<number | null>(null)
  const [editModal, setEditModal] = useState<boolean>(false)

  const dispatch = useDispatch()
  const setLoader = (payload: boolean) => dispatch(Global.fsLoader(payload))
  const [list, setList] = useState<ListObject[]>([])

  const handleInputChanges = (evt: FormEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget
    setColor('#'.concat(value).substr(0, 7))
  }

  const onStartUp = async () => {
    setLoader(true)
    const optionService = new OptionService({ type: 'technical-color' })
    const result = await optionService.read()

    if (!result.success || !result.options) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    const options = <Lib.T.List[]>result.options
    const listArray: ListObject[] = []
    options.forEach(item =>
      listArray.push({
        id: item.id,
        name: item.technicalColor || '',
        color: item.technicalColor,
      }),
    )
    setList(listArray)
    setLoader(false)
  }

  useEffect(() => {
    ;(async () => onStartUp())()
  }, [])

  const submit = async () => {
    if (!color) {
      swal({
        text: 'Lütfen renk seçimi yapın.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }
    setLoader(true)

    const optionService = new OptionService({
      type: 'technical-color',
      technicalColor: color,
    })

    const result = await optionService.create()
    if (!result.success || !result.option) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    const { option } = result
    const { id, technicalColor } = option
    if (!id || !technicalColor) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    setList([...list, { id, name: technicalColor, color: technicalColor }])
    setLoader(false)
  }

  const onDelete = async (id: number) => {
    const optionService = new OptionService({ type: 'technical-color' })
    const result = await optionService.delete(id)
    setLoader(true)

    if (!result) {
      setLoader(false)
      return
    }

    const listArray: ListObject[] = []
    list.forEach(item => {
      if (item.id !== id) {
        listArray.push(item)
      }
    })

    setList(listArray)
    setLoader(false)
  }

  const beforeConfirmEdit = (item: ListObject) => {
    setEditColor(item.name)
    setItemIdToEdit(+item.id)
  }

  const edit = async () => {
    if (!editColor) {
      swal({
        text: 'Lütfen renk seçimi yapın.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }
    if (!itemIdToEdit) {
      return
    }
    setLoader(true)

    const optionService = new OptionService({
      type: 'technical-color',
      technicalColor: editColor,
    })

    const { success } = await optionService.edit(itemIdToEdit)
    setLoader(false)
    setEditModal(false)

    if (!success) {
      return
    }

    const listArray: ListObject[] = []

    list.forEach(item => {
      if (item.id === itemIdToEdit) {
        listArray.push({ ...item, name: editColor, color: editColor })
      } else {
        listArray.push(item)
      }
    })

    setList(listArray)
  }

  return {
    addStates: {
      color: {
        val: color,
        set: setColor,
        onChange: handleInputChanges,
      },
    },
    editStates: {
      color: {
        val: editColor,
        set: setEditColor,
        onChange: handleInputChanges,
      },
      visibility: {
        val: editModal,
        set: setEditModal,
      },
    },
    on: {
      submit,
      onDelete,
      beforeConfirmEdit,
      edit,
    },
    list,
  }
}
