import styled from 'styled-components'
import * as Lib from '.'
import { mediaQueries as mq } from 'common/constants/media-queries'

export const container = styled.div<Pick<Lib.T.DrawerProps, 'borderBottom' | 'isSubItem' | 'maxHeight'> & { visibility: boolean }>`
  float: left;
  width: 100%;
  background: ${({ isSubItem }) => (isSubItem ? '#F5F5F5' : '#F8F8F8')};
  /* padding: ${({ isSubItem }) => (isSubItem ? '0 0 0 20px' : '0')}; */
  border-radius: 8px;
  margin-bottom: 8px;

  ${mq.max[768]} {
    margin-bottom: 10px;
    border-radius: 10px;
  }

  > .title {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    padding: ${({ isSubItem }) => (isSubItem ? '0 7px' : '7px')};
    /* border-bottom: ${({ borderBottom }) => (borderBottom ? '1px solid #4d4d4d30' : 'none')}; */
    cursor: pointer;
    user-select: none;

    ${mq.max[768]} {
      padding: ${({ isSubItem }) => (isSubItem ? '15px 15px 15px 10px' : '10px')};
      border: none;
      position: relative;

      &::after {
        ${({ visibility }) => visibility && `content: ''`};
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background: #4d4d4d80;
        width: calc(100% - 20px);
        margin: auto;
      }
    }

    > p {
      margin: 0;
      font-size: 14px;
      color: #4d4d4d;
      flex: 1;
    }

    > span {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  > .body {
    float: left;
    width: 100%;
    background: #F8F8F8;
    height: ${({ maxHeight, visibility }) =>
      !visibility ? 0 : maxHeight ? (maxHeight === 'unset' ? 'auto' : maxHeight + 'px') : '200px'} !important;
    transition: all 250ms linear;
    transform: ${({ visibility }) => (visibility ? 'scale(1)' : 'scale(0.8)')};
    opacity: ${({ visibility }) => (visibility ? '1' : '0')};

    ${mq.max[768]} {
      background-color: white;
      padding: 0 30px;
      border-radius: 10px;
    }
  }
`
