import { FC } from 'react'
import * as Lib from './lib'

export const PaginationLoading: FC = () => (
  <Lib.S.paginationLoading>
    <span>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11 1V3M16 2.34L15 4.0715M19.66 6L17.928 7M21 11H19M19.66 16L17.928 15M16 19.66L15 17.928M11 21V19M6 19.66L7 17.928M2.34 16L4.0715 15M1 11H3M2.34 6L4.0715 7M6 2.34L7 4.0715"
          stroke="#979797"
          strokeWidth="2"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
    <p>Yükleniyor...</p>
  </Lib.S.paginationLoading>
)
