import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import * as Lib from './lib'
import { Content } from 'common/components/ui-kit/content'
import { MobileViewProvider } from './lib/context'

const ComingSoon = (): JSX.Element => {

  return (
    <>
      <PanelWrapper tab={['comingSoon', 'comingSoon']} title="Coming Soon">
        <MobileViewProvider>
          <Content padding="0">
            <Lib.S.Container>
              <img src='/images/logo/new_green_logo.png' width={300} alt='' />
              Bu sayfa yakında eklenecek.
            </Lib.S.Container>
          </Content>
        </MobileViewProvider>
      </PanelWrapper>
    </>
  )
}
export default ComingSoon
