import { useEffect, useState } from 'react'
import { DashboardService } from 'common/services'
import { useLoader } from 'common/hooks/use-loader'
import * as Lib from '.'
import moment from 'moment'
import swal from 'sweetalert'
import { useNewPermissions } from 'common/routes/hooks'
import { isArray } from 'lodash'
import { Weather } from 'common/services/lib/types/dashboard'

const currentDateISO = moment().toISOString()

const newDetailModalInitialProps: Lib.T.modalProps = {
  kind: '',
  title: '',
  description: '',
  image: undefined,
}

const dayAbbreviations = [
  'Paz', // Sunday
  'Pzt', // Monday
  'Sal', // Tuesday
  'Çar', // Wednesday
  'Per', // Thursday
  'Cum', // Friday
  'Cts', // Saturday
]

export const useDashboard = () => {
  const { setLoader } = useLoader()
  const { user } = useNewPermissions()

  const [newsList, setNewsList] = useState<Lib.T.news[]>()
  const [announcementsList, setAnnouncementsList] = useState<Lib.T.announcements[]>()
  const [showNewsDetailModal, setShowNewsDetailModal] = useState(false)
  const [showAnnouncementDetailModal, setShowAnnouncementDetailModal] = useState(false)
  const [modalProps, setModalProps] = useState<Lib.T.modalProps>(newDetailModalInitialProps)
  const [foodList, setFoodList] = useState<Lib.T.foodList[]>()
  const [todoList, setTodoList] = useState<Lib.T.todoList[]>()
  const [todayBirthdays, setTodayBirthdays] = useState<Lib.T.Birthdays[]>()
  const [inWeekBirthdays, setInWeekBirthdays] = useState<Lib.T.Birthdays[]>()
  const [drinks, setDrinks] = useState<Lib.T.Drinks[]>()
  const [isEmployee, setIsEmployee] = useState(false)
  const [showDrinkModal, setShowDrinkModal] = useState(false)
  const [drink, setDrink] = useState<string | undefined>('')
  const [fetchAgain, setFetchAgain] = useState(false)
  const [currencies, setCurrencies] = useState<Lib.T.currencies[]>()
  const [weather, setWeather] = useState<Lib.T.weather[]>()

  const getNews = async () => {
    const service = new DashboardService()
    setLoader(true)
    const { success, news } = await service.getAnnouncementsAndNews({
      type: 'news',
    })
    setLoader(false)
    if (success && news) {
      setNewsList(() => {
        return news
          ?.filter(item => !item.active)
          .map(item => ({
            title: item?.title,
            description: item?.description,
          }))
      })
    }
  }

  const getAnnouncements = async () => {
    const service = new DashboardService()
    setLoader(true)
    const { success, announcements } = await service.getAnnouncementsAndNews({
      type: 'announcement-with-image',
    })
    setLoader(false)
    if (success && announcements) {
      setAnnouncementsList(() => {
        return announcements
          ?.filter(item => !item.active)
          .map(item => ({
            title: item?.title,
            description: item?.description,
            image: item?.image,
          }))
      })
    }
  }

  const getFoodList = async () => {
    const service = new DashboardService()
    setLoader(true)
    const { success, foodList } = await service.getFoodList()
    setLoader(false)
    if (success && foodList) {
      setFoodList(() => {
        return foodList?.foodItems?.map(item => ({
          id: item?.id,
          title: item?.title,
          name: item?.name,
        }))
      })
    }
  }

  const getTodoList = async () => {
    const service = new DashboardService()
    setLoader(true)
    const { success, todoList } = await service.getTodoList(user?.employee?.id ? 'automation/todo-list' : 'todo-list', {
      date: currentDateISO,
      period: 'daily',
    })
    setLoader(false)
    if (success && todoList) {
      setTodoList(() => {
        return todoList?.map(item => ({
          id: item?.id,
          isChecked: item?.isChecked,
          description: item?.description,
        }))
      })
    }
  }

  const getTodayBirthdays = async () => {
    const service = new DashboardService()
    setLoader(true)
    const { success, todayBirthdays } = await service.getTodayBirthdays()
    setLoader(false)
    if (success && todayBirthdays) {
      setTodayBirthdays(() => {
        return todayBirthdays?.map(item => ({
          id: item?.id,
          avatar: item?.avatar,
          firstName: item?.firstName,
          lastName: item?.lastName,
          birthday: item?.birthday,
        }))
      })
    }
  }

  const getInWeekBirthdays = async () => {
    const service = new DashboardService()
    setLoader(true)
    const { success, inWeekBirthdays } = await service.getInWeekBirthdays()
    setLoader(false)
    if (success && inWeekBirthdays) {
      setInWeekBirthdays(() => {
        return inWeekBirthdays?.map(item => ({
          id: item?.id,
          avatar: item?.avatar,
          firstName: item?.firstName,
          lastName: item?.lastName,
          birthday: item?.birthday,
        }))
      })
    }
  }

  const getDrinks = async () => {
    const service = new DashboardService()
    setLoader(true)
    const { success, drinks } = await service.getDrinks()
    setLoader(false)
    if (success && drinks && isArray(drinks)) {
      setDrinks(() => {
        return drinks?.map(item => ({
          id: item?.id,
          title: item?.name,
          alreadyOrdered: item?.alreadyOrdered,
        }))
      })
    }
  }

  const transformData = (data: any): Lib.T.currencies[] => {
    const replacements: { [key: string]: string } = {
      USDTRY: 'DOLAR / TL',
      ALTIN: 'ALTIN',
      ONS: 'ALTIN ONS',
    }

    return Object.keys(data).map(key => {
      const { code, alis, satis } = data[key]

      const transformedName = replacements[code] || code

      return {
        name: transformedName,
        buyPrice: alis,
        sellPrice: satis,
      }
    })
  }

  const getCurrencies = async () => {
    const service = new DashboardService()
    setLoader(true)
    const { currencies } = await service.getCurrencyPrice(
      user?.employee?.id ? 'automation/dashboard/get-currency-price' : 'dashboard/get-currency-price',
    )
    setLoader(false)

    const transformedCurrencies = transformData(currencies)
    setCurrencies(transformedCurrencies)
  }

  function getDayName(dateString: string) {
    const date = new Date(dateString)
    const dayIndex = date.getDay()
    return dayAbbreviations[dayIndex]
  }

  const transformWeatherData = (data: Weather[]): Lib.T.weather[] => {
    const limitedData = data.slice(0, 5)

    return limitedData.map(item => ({
      day: getDayName(item.valid_date),
      temperature: item.temp,
      icon: item.weather.icon,
    }))
  }

  const getWeather = async () => {
    const service = new DashboardService()
    setLoader(true)
    const { weather } = await service.getWeather(user?.employee?.id ? 'automation/dashboard/get-weather' : 'dashboard/get-weather')
    setLoader(false)

    if (weather) {
      const transformedWeather = transformWeatherData(weather)
      setWeather(transformedWeather)
    }
  }

  const updateTodoList = async (isChecked: boolean, id: number) => {
    const service = new DashboardService()
    setLoader(true)
    const { success } = await service.putTodoList(
      user?.employee?.id ? 'automation/todo-list' : 'todo-list',
      {
        isChecked: isChecked,
      },
      id,
    )
    setLoader(false)
    if (success) {
      swal({
        className: 'rounded-swal',
        icon: 'success',
        timer: 1000,
        buttons: [false],
      })

      return true
    }

    return false
  }

  const updateDrink = async (id: number) => {
    const service = new DashboardService()
    setLoader(true)
    const { success } = await service.putDrink({ drinkTypeId: id })
    setLoader(false)
    if (success) {
      swal({
        className: 'rounded-swal',
        icon: 'success',
        timer: 1000,
        buttons: [false],
      })

      setFetchAgain(prev => !prev)
      setShowDrinkModal(false)
      return true
    }

    setFetchAgain(prev => !prev)
    setShowDrinkModal(false)
    return false
  }

  const openDetailModal = (title: string, description: string, kind: string, image?: Lib.T.Image | null) => {
    if (kind === 'news') {
      setShowNewsDetailModal(true)
      setModalProps({ ...modalProps, kind: kind, title: title, description: description })
      return
    }
    setShowAnnouncementDetailModal(true)
    setModalProps({ ...modalProps, kind: kind, title: title, description: description, image: image })
  }

  const handleCancelDetailModal = (kind: string) => {
    setModalProps(newDetailModalInitialProps)
    if (kind === 'news') {
      setShowNewsDetailModal(false)
      return
    }
    setShowAnnouncementDetailModal(false)
  }

  const formatBirthday = (dateString: string) => {
    const date = new Date(dateString)
    const dayIndex = date.getDay()
    const dayName = dayAbbreviations[dayIndex]
    const dayNumber = date.getDate()
    return `${dayName}-${dayNumber}`
  }

  useEffect(() => {
    ;(async () => {
      await getAnnouncements()
      await getFoodList()
      await getNews()
      await getTodoList()
      await getTodayBirthdays()
      await getInWeekBirthdays()
      await getCurrencies()
      await getWeather()
    })()
  }, [])

  useEffect(() => {
    if (user?.employee?.id) {
      getDrinks()
      getTodoList()
      getCurrencies()
      getWeather()
      setIsEmployee(true)
      return
    }
    setIsEmployee(false)
  }, [user?.employee?.id, fetchAgain])

  useEffect(() => {
    if (drinks) {
      const alreadyOrderedDrink = drinks?.find(drink => drink.alreadyOrdered)
      setDrink(alreadyOrderedDrink ? alreadyOrderedDrink.title : '')
    }
  }, [drinks, drink, fetchAgain])

  return {
    val: {
      newsList,
      announcementsList,
      showNewsDetailModal,
      modalProps,
      showAnnouncementDetailModal,
      foodList,
      todoList,
      todayBirthdays,
      inWeekBirthdays,
      drinks,
      isEmployee,
      showDrinkModal,
      drink,
      currencies,
      weather,
    },

    on: {
      openDetailModal,
      handleCancelDetailModal,
      updateTodoList,
      updateDrink,
      formatBirthday,
    },

    set: {
      setTodoList,
      setShowDrinkModal,
    },
  }
}
