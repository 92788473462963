import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import swal from 'sweetalert'
import { DOM } from 'common/helpers/dom'

export class NotificationsService {
  accessToken: string
  endpoint = 'messages'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      NotificationsService.dontAccept()
    }
    if (!user!.accessToken) {
      NotificationsService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async getNotifications(args?: Lib.T.Notifications.GetListArgs): Promise<Lib.T.Notifications.Notifications> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(
        args
          ? DOM.objectToSearch({
              ...args,
            })
          : '',
      ),
      headers: { authorization: this.accessToken },
      customConfigs: {hidePopup: true}
    })

    const { status, data } = response

    if (status !== 200) {
      swal({
        text: 'Bildirimlere ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false, total: 0, totalUnread: 0 }
    }
    return { success: true, total: data.total, totalUnread: data.totalUnread, data: data.messages }
  }
  async delete(id: number): Promise<boolean> {
    const response = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/${id}`),
      headers: { authorization: this.accessToken },
    })
    const { status } = response
    if (status !== 200) {
      swal({
        text: 'Bildirimlere ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return false
    }
    return true
  }
  async makeRead(id: number): Promise<Lib.T.Notifications.Notifications> {
    const response = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/${id}`),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response
    if (status !== 200) {
      return { success: false, total: 0, totalUnread: 0 }
    }
    return { success: true, total: data.total, totalUnread: data.totalUnread, data: data.messages }
  }
  static dontAccept() {
    throw 'not-acceptable'
  }
}
