import { Modal } from 'antd'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Content } from 'common/components/ui-kit/content'
import { Input } from 'common/components/ui-kit/input'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Managers } from 'common/routes/types/roles.type'
import { RootState } from 'common/store/root-reducer'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import * as Lib from './lib'
import { useCreateDepartment } from './lib/hooks'
import { DropDown2 } from '../../../../../common/components/ui-kit/dropdown2'

export const CreateDepartment: FC = ({}) => {
  const { get, set, on } = Lib.H.useCreateDepartment()

  const { user } = useSelector((state: RootState) => state.globals)

  return (
    <>

      <PanelWrapper tab={['options', '/options/department/create']}>
        <Sticky>
          <p className='title'>Departman Listesi</p>
          <div
            style={{
              display: 'flex',
              gap: 5,
              width: '10%',
            }}
          >
            {get.editMode && Managers.includes(user.group.name) ? (
              <Button
                className='delete'
                mood='danger'
                callback={() => on.toggleDeleteModal()}
                text='Sil'
                iconProps={{ color: '#fff', size: 18, style: { marginRight: '5px' } }}
              />
            ) : null}
            <Button text='Kaydet' callback={on.createOrEdit} mood='accent' className='submitButton' />
          </div>
        </Sticky>
        <Content>
          <Lib.S.container>
            <div className='row'>
              <div className='col-lg-6'>
                <Input
                  label='Department Ad'
                  required
                  value={get.name}
                  onEnter={on.createOrEdit}
                  onInput={evt => {
                    //if (get.editMode) return
                    set.setName(evt.currentTarget.value)
                  }}
                />
              </div>
              <div className='col-lg-6 mt-1'>
                <span>Department Parent</span>
                <DropDown2
                  className='dropdown mt-2'
                  options={get.departmentList}
                  label=''
                  height='45px'
                  value={get.departmentSelected}
                  onChange={value => set.setDepartmentSelected(value)}
                />
              </div>
            </div>
          </Lib.S.container>
          <Modal {...get.deleteModalProps}>
            <Lib.S.deleteModalContent>
              <p>Department kalıcı olarak silinecektir, Emin misiniz?</p>
              <div>
                <Button text='Onayla' mood='danger' callback={on.deleteDepartment} />
                <Button text='İptal' mood='accent' callback={on.toggleDeleteModal} />
              </div>
            </Lib.S.deleteModalContent>
          </Modal>
        </Content>
      </PanelWrapper>
    </>
  )
}
