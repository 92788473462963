import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {
    --f-bl:         mulish-black;
    --f-bl-it:      mulish-black-italic;
    --f-bo:         mulish-bold;
    --f-bo-it:      mulish-bold-italic;
    --f-ex-bo:      mulish-extra-bold;
    --f-ex-bo-it:   mulish-extra-bold-italic;
    --f-ex-li:      mulish-extra-light;
    --f-ex-li-it:   mulish-extra-light-italic;
    --f-it:         mulish-italic;
    --f-li:         mulish-light;
    --f-li-it:      mulish-light-italic;
    --f-me:         mulish-medium;
    --f-me-it:      mulish-medium-italic;
    --f-re:         mulish-regular;
    --f-se-bo:      mulish-semi-bold;
    --f-se-bo-it:   mulish-semi-bold-italic;

    --accent: #004E42;
    --accent_10: hsl(171deg 100% 15% / 10%);
    --accent_50: hsl(171deg 100% 15% / 50%);
    --toastify-color-progress-light: var(--accent)
  }

  * {
::-webkit-scrollbar {
  width: 10px;
  height:10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #B4B4B4;
  border-radius: 10px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

 }


.filterModal{
  padding-bottom: 0;
  .ant-modal-content{
    border-radius: 20px 0px 0px 20px;
    .ant-modal-header{
      border-radius: 20px 0px 0px 0px;
      border: 0
    }
    .ant-modal-body{
      padding-top: 0;
    }
  }
}

  body {
    font-family: var(--f-re),sans-serif;
    .ant-select-dropdown {
      border-radius: 5px !important;

      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: #004e42 !important;
        color:#fff;
      }
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: #004e42 !important;
        color:#fff;

      }
    }

    .ScrollbarsCustom-TrackX {
      display: none !important;
    }

    .ant-spin-dot-item {
  background-color: var(--accent) !important;
}

    .rounded-swal {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      .swal-icon:first-child {
        margin-top: 36px;
      }

      .swal-icon--success__ring {
        width: 154px;
        height: 154px;
        border: 4px solid #a5dc86;
        left: -41px;
        top: -40px;
      }
    }


    .ant-picker:hover, .ant-picker-focused {
      border: 1px solid #d9d9d9;
    box-shadow: unset;
}
    .ant-modal-content {
    border-radius: 7px;
  }
    .swal-button {
      background-color: var(--accent);
    }

    .swal-button:hover {
      
      background-color: var(--accent) !important;
    }

    .swal-button--danger {
      background-color: var(--accent) !important;
    }
    .swal-button:hover {
      
      background-color: var(--accent) !important;
    }

   
  }

  .react-slidedown {
    transition-duration: .3s;
  }

  .float-left {
    float: left !important;
  }

  .float-right {
    float: right !important;
  }

  .p-10 {
    padding: 10px !important;
  }

  .flex-1 {
    flex:1
  }

  .ScrollbarsCustom-Thumb,
  .ScrollbarsCustom-ThumbY {
    background-color: #B4B4B4 !important;
  }

  .ScrollbarsCustom-Track,
  .ScrollbarsCustom-TrackY {
    background-color: #E4E4E4 !important;
    width: 8px !important;
    padding: unset !important;
    border-bottom-width: unset !important;
    box-shadow: unset !important;
  }

  .noHeaderModal {
    .ant-modal-content {
      border-radius: 10px;

      .ant-modal-close {
        display: none;
      }
    }
  }

  .ant-modal-wrap {
    background-color: #FFFFFF5C;
  }

  .ant-modal-body {
    padding: 20px;
    display: inline-block;
    width: 100%;
  }
  
  .userModal{
    top: 30px;
    .ant-modal-body {
      padding: 0px;
    }
  }
  
  
  .ant-select-disabled{
  opacity: 1 !important;
  }
  .scrollWidthModal {
    .ant-modal-body {
      overflow-x: auto;
      overflow-y: hidden;
    }

   
  }

  /* html,
  body {
    overflow-x: hidden;
  } */

  .twoButtons {
    float: left;
    width: 100%;

    > div {
      float: left;
      width: 50%;

      &:nth-child(1) {
        padding-right: 5px;
      }
      &:nth-child(2) {
        padding-left: 5px;
      }

      .parent {
        min-height: unset;
        padding: 8px 0;
      }
    }
  }

  .deleteModal {
    float: left;
    width: 100%;
    height: auto;

    > p {
      margin: 0;
      float: left;
      width: 100%;
      font-size: 18px;
      text-align: center;
    }

    > div.buttons {
      float: left;
      width: 100%;
      text-align: center;
      margin: 30px 0 0 0;

      > div {
        width: 110px !important;
        float: none !important;
        display: inline-flex !important;
        padding: 0 2px;

        > div {
          min-height: 35px !important;
        }
      }
    }

    > div.inputs {
      float: left;
      width: 100%;
    }
  }

  .ant-switch-checked {
    background-color: var(--accent)
  }

  .ant-select-selector {
    height: auto !important;
    align-items: center;
  }

  .ant-select-disabled {
    opacity: .3;

    .ant-select-selection-item {
      background-color: transparent !important;
    }
  }

  .ant-select-selection-item {
    height: auto !important;
    background-color: transparent !important;
    border-radius: 10px !important;

    > .ant-select-selection-item-content {
      margin: 0 5px 0 6px !important;
    }

    > .ant-select-selection-item-remove {
      margin: 0 !important;
      margin: 0 5px 0 0 !important;

      > span {
        background-color: #004E421A;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .swal-modal-container{
    .swal-icon{
      width: 50px;
      height: 50px;
      .swal-icon--warning__body{
        height: 20px;
      }
    }
    .swal-text{
      text-align: center;
    }
  }
`
