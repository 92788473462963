import styled from 'styled-components'
import { mediaQueries as mq, createQuery as ca } from 'common/constants/media-queries'
import { sidebarWidth } from 'common/constants/strings'

const SIDEBAR_BORDER_RADIUS = <const>'20px'
const SIDEBAR_TRANSITION = <const>'all 300ms linear'

export const sidebarBackdrop = styled.div<{ active: boolean }>`
  opacity: ${({ active }) => (active ? '1' : '0')};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #00000014;
  z-index: 19;
  transition: ${SIDEBAR_TRANSITION};

  ${ca(769, 'min')} {
    opacity: 0;
    visibility: hidden;
  }
`

export const sidebarContainer = styled.div<{ active: boolean; isCollapsed: boolean }>`
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  width: ${({ isCollapsed }) => (isCollapsed ? '70px' : '190px')};
  background-color: var(--accent);
  float: left;
  user-select: none;
  z-index: 20;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: ${SIDEBAR_TRANSITION};

  ${mq.max[768]} {
    transition: ${SIDEBAR_TRANSITION};
    left: ${({ active }) => (active ? '0' : `-${sidebarWidth}`)};
    border-radius: 0 ${SIDEBAR_BORDER_RADIUS} ${SIDEBAR_BORDER_RADIUS} 0;
  }
  .collapsed {
    width: 84%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 18px;
    margin-top: 20px;
    gap: 10px;

    > div {
      background-color: rgb(187 187 187 / 50%);
      border-radius: 4px;
      padding: 0 5px 0 5px;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    span {
      color: #fff;
      font-size: 12px;
    }
  }
  .userInfo {
    padding-left: 15px;
    z-index: 100;
    display: flex;
    /* > img {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      object-fit: contain;
    } */
    > p {
      color: #fff;
      font-size: 12px;
      width: 120px;
    }
  }
  .forTabletAndLess {
    ${ca(769, 'min')} {
      display: none;
    }
  }

  * {
    user-select: none;
  }

  > .logo {
    display: flex;
    justify-content: center;
    width: 72%;

    > a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      width: 70%;
      margin-top: 15px;

      > img {
        width: 100%;
      }
    }

    > .closeIcon {
      display: none;
      width: 40px;
      align-items: center;
    }

    ${mq.max[768]} {
      display: flex;

      > a {
        flex: 1;
        justify-content: flex-start;
        padding: 0 0 0 10px;

        > img {
          width: 100px;
        }
      }

      > .closeIcon {
        display: flex;
      }
    }
  }
`

export const sidebarItem = styled('div')<{ active: boolean | undefined }>`
  float: left;
  width: 100%;
  height: auto;
  padding-left: 1px;

  > .details {
    float: left;
    width: 77%;

    > a,
    > span {
      float: left;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 11px 15px;
      text-decoration: none !important;
      color: white;
      transition: all 150ms linear;
      cursor: pointer;
      font-size: 12px;

      &:hover {
        background-color: rgb(255 255 255 / 5%);
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      > span.icon {
        height: 100%;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      > .name {
        margin: 0;
        flex: 1;
        display: flex;
        height: 18px;
        justify-content: space-between;
        align-items: center;
        p {
          margin: 0;
        }
      }
      .circle {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: red;
        font-size: 11px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > span.chevron {
        height: 100%;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.true {
      background-color: #f8f8f8;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      position: relative;

      .top {
        width: 32px;
        height: 45px;
        overflow: hidden;
        top: -29px;
        right: 9px;
        position: absolute;
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);

        ::before {
          content: '';
          display: block;
          width: 200%;
          height: 200%;
          position: absolute;
          border-radius: 44%;
          bottom: 0;
          left: 0;
          box-shadow: -50px 50px 0 0 #f8f8f8;
        }
      }

      .bottom {
        width: 43px;
        height: 45px;
        overflow: hidden;
        bottom: -26px;
        right: 6px;
        position: absolute;
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -webkit-transform: rotate(-176deg);
        -ms-transform: rotate(-176deg);
        -webkit-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg);
        ::before {
          content: '';
          display: block;
          width: 200%;
          height: 200%;
          position: absolute;
          border-radius: 44%;
          bottom: 0;
          left: 0;
          box-shadow: -50px 50px 0 0 #f8f8f8;
        }
      }

      * {
        color: #004e42;
        fill: #004e42;
      }
    }
  }

  > .subItems {
    float: left;
    width: 77%;
    padding: 0 0 0 39px;

    // > div:not(:last-child) {
    //   border-bottom: 1px solid #ffffff29;
    // }
  }
`

export const sidebarSubItems = styled.div<{ active?: boolean }>`
  float: left;
  width: 100%;

  > a {
    display: block;
    width: 100%;
    float: left;
    height: auto;
    padding: 6px 0;
    text-decoration: none !important;
    color: ${p => (p.active ? '#F0AD4B' : 'white')};
    font-size: 12px;
  }
`

export const searchBox = styled.div`
  float: left;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    height: 1px;
    background: #ffffff40;
    width: calc(100% - 27px);
    margin: auto;
  }

  > div {
    height: inherit;
    > label {
      height: inherit;
      > input {
        height: inherit;
      }
      > span {
        height: inherit !important;
      }
    }
  }
`

export const ConfirmModalContainer = styled.div`
  padding: 40px;
  p {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }
  .btns {
    display: flex;
    gap: 5px;
  }
`
export const ScrollSection = styled('div')`
  .background {
    position: absolute;
    z-index: -1;
    content: '';
    width: 200px;
    height: 200px;
    bottom: 65%;
    right: 40px;
  }

  float: left;
  width: 100%;
  height: calc(100vh - 100px);
  position: relative;

  ${mq.max[768]} {
    height: calc(100vh - 140px);
  }

  .break {
    float: left;
    width: calc(100% - 20px);
    height: 1px;
    margin: 0 0 0 10px;
    background-color: #ffffff40;
  }

  > .profile {
    float: left;
    width: 100%;
    height: auto;

    > a {
      float: left;
      width: 100%;
      padding: 0 10px;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: white;

      > img {
        width: 50px;
      }

      > p {
        flex: 1;
        margin: 0;
        padding: 0 0 0 10px;
      }
    }
  }
`
