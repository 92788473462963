import { ModalProps } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { imageAddresser } from 'common/helpers/image.helper'
import { MediaService } from 'common/services'
import { MonacoChainAndLockPicture } from 'pages/private/products/list/monacoList/lib/components'
import { Dispatch, FC, useEffect, useMemo } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

interface ImageModalProps {
  showModal: boolean
  setShowModal: Dispatch<boolean>
  image: string;
  downloadImageLink:string;
  withZoom?: boolean
  isMonacoImageModal?: boolean
  monacoImageWidth?: number
  modalProps?: ModalProps
  productCode?: string
  weight?: string
}

const ImageModal: FC<ImageModalProps> = ({
  showModal,
  setShowModal,
  image,
                                           downloadImageLink,
  withZoom,
  isMonacoImageModal,
  monacoImageWidth,
  modalProps,
  productCode,
  weight,
}) => {
  const imageModalProps: ModalProps = useMemo(() => {
    return {
      footer: null,
      title: null,
      visible: showModal,
      width: modalProps?.width ?? '50%',

      style: modalProps?.style ?? { top: 20, height: '90vh' },
      onCancel: () => {
        setShowModal(false)
      },
      className: 'noHeaderModal scrollWidthModal',
      ...modalProps,
    }
  }, [showModal, modalProps, setShowModal, isMonacoImageModal])

  useEffect(() => {
    if (showModal) {
      const downloadImage = async () => {
        //const service = new MediaService()
        const originalImage = imageAddresser(downloadImageLink || image);
        //const {data} = await service.download(originalImage.trim());
        const nameSplit = originalImage.split('/')
        const duplicateName = nameSplit.pop();
        //const imageBlog = data;
        //const imageURL = URL.createObjectURL(imageBlog)
        const link = document.getElementById(`download ${image}`) as HTMLAnchorElement
        link.href = originalImage
        link.download = '' + duplicateName + ''
        // if (link) {
        //   link.addEventListener('click', () => {
        //     link.click()
        //   })
        // }
      }
      downloadImage()
    }
  }, [showModal, image, downloadImageLink])

  if (!showModal) return <></>

  return (
    <Modal {...imageModalProps}>
      <div style={{ position: 'relative', height: '30px' }}>
        {isMonacoImageModal ? null : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href=""
            target="_blank"
            id={`download ${image}`}
            style={{
              backgroundColor: 'var(--accent)',
              borderRadius: '8px',
              padding: '5px 15px',
              color: '#fff',
              textDecoration: 'none',
              position: 'absolute',
            }}
          >
            Resmi indir
          </a>
        )}

        {productCode && weight ? (
          <p style={{ textAlign: 'center', margin: 0, fontWeight: 'bold', fontSize: '1.2rem' }}>
            <span style={{ marginRight: '30px' }}>Ürün kodu : {productCode}</span>
            {/*<span>Ağırlık : {weight}</span>*/}
          </p>
        ) : null}
      </div>

      {withZoom ? (
        <TransformWrapper key={2}>
          <TransformComponent key={2} wrapperStyle={{ width: '100%', marginTop: 10 }} contentStyle={{ width: '100%' }}>
            {isMonacoImageModal ? (
              <div style={{ width: '100%' }}>
                <MonacoChainAndLockPicture
                  width={monacoImageWidth || 400}
                  stylesForModal={isMonacoImageModal}
                  style={{ margin: '0 auto' }}
                  chainImagePath={image.split(',')[0]}
                  lockImagePath={image.split(',')[1]}
                />
              </div>
            ) : (
              <img
                src={image && image.includes('https') ? image : imageAddresser(image)}
                style={{ width: '100%', height: '83vh', objectFit: 'contain' }}
                alt=""
                id="canvas"
                onError={e => (e.currentTarget.src = '/images/jpg/placeholder.jpg')}
              />
            )}
          </TransformComponent>
        </TransformWrapper>
      ) : (
        <>
          {isMonacoImageModal ? (
            <MonacoChainAndLockPicture
              width={110}
              chainImagePath={image.split(',')[0]}
              lockImagePath={image.split(',')[1]}
              style={{ margin: '0 auto' }}
            />
          ) : (
            <img
              src={image && image.includes('https') ? image : imageAddresser(image)}
              style={{ width: '100%', height: '83vh', objectFit: 'contain' }}
              alt=""
              onError={e => (e.currentTarget.src = '/images/jpg/placeholder.jpg')}
            />
          )}
        </>
      )}
    </Modal>
  )
}

export default ImageModal
