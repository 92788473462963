import { Icon } from 'common/components/ui-kit/icon'
import { FC } from 'react'
import * as Lib from './index'
import { imageAddresser } from 'common/helpers/image.helper'

export const TableImage: FC<Lib.T.TableImageProps> = ({ src, alt }): JSX.Element => {
  return (
    <>
      <Lib.S.tableImage>
        <img src={src} alt={alt} />
      </Lib.S.tableImage>
    </>
  )
}

export const TableButton: FC<Lib.T.TableButtonProps> = ({ callback, icon, color, className }): JSX.Element => {
  return (
    <>
      <Lib.S.tableButton className={className} onClick={e => callback(e)}>
        <Icon name={icon} color={color} size={20} />
      </Lib.S.tableButton>
    </>
  )
}

export const UserInfo = ({ data }: any) => {
  return (
    <div className="bg-white rounded-3 w-50 mx-auto my-5 p-3">
      <Lib.S.userImage>
        <img src={data.avatar ? imageAddresser(data.avatar.imageThumbnail, true) : '/images/png/avatar.png'} alt={data.name} />
      </Lib.S.userImage>
      <div className="row justify-content-between">
        <div className="col-lg-5 d-flex justify-content-between">
          <span className="fw-bold">İsim:</span>
          <span>{data?.firstName}</span>
        </div>
        <div className="col-lg-5 d-flex justify-content-between">
          <span className="fw-bold">Soy isim:</span>
          <span>{data?.lastName}</span>
        </div>
      </div>
      <div className="row justify-content-between mt-3">
        <div className="col-lg-5 d-flex justify-content-between">
          <span className="fw-bold">Kod:</span>
          <span>{data?.personnelCode}</span>
        </div>
        <div className="col-lg-5 d-flex justify-content-between">
          <span className="fw-bold">Departman:</span>
          <span>{data?.user?.userToRoles[0]?.department.name}</span>
        </div>
      </div>
      <div className="row justify-content-between mt-3">
        <div className="col-lg-5 d-flex justify-content-between">
          <span className="fw-bold">Telefon:</span>
          <span>{data?.phone}</span>
        </div>
        <div className="col-lg-5 d-flex justify-content-between">
          <span className="fw-bold">Mail:</span>
          <span>{data?.email}</span>
        </div>
      </div>
    </div>
  )
}
