import { Popover } from 'antd'
import { FC } from 'react'
import { Icon } from '../icon'
import * as Lib from './lib'

export const Checkbox: FC<Lib.T.CheckboxProps> = ({
  disabledLabel,
  className,
  label,
  noSpaceBetween,
  hasPopover,
  style,
  checkedColor,
  ...inputAttrs
}): JSX.Element => {
  return (
    <>
      <Lib.S.checkboxContainer checkedColor={checkedColor} style={style}>
        <label className={disabledLabel ? 'disabled' : ''} style={{ justifyContent: `${!noSpaceBetween ? 'space-between' : 'unset'}` }}>
          <input type="checkbox" {...inputAttrs} className={`${className} ${disabledLabel ? 'disabled' : ''}`} />

          <span className={`visual ${disabledLabel ? 'disabled' : ''}`}>
            <Icon name="check" size={15} color="white" />
          </span>

          {label &&
            (label.length > 5 && !noSpaceBetween && hasPopover ? (
              <Popover content={label}>
                <span className={`label ${disabledLabel ? 'disabled' : ''}`}>{label}</span>
              </Popover>
            ) : (
              <span className={`label ${disabledLabel ? 'disabled' : ''}`}>{label}</span>
            ))}
        </label>
      </Lib.S.checkboxContainer>
    </>
  )
}
