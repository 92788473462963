import styled from 'styled-components'
import { mediaQueries as mq, createQuery as cq } from 'common/constants/media-queries'

const FILTER_WIDTH = '160px'
const FILTER_PADDING = '0 40px 0 0'

export const div = styled.div`
  &.pic-modal {
  }
`

export const container = styled.div`
  float: left;
  width: 100%;
  height: auto;
  padding: 2px 2px;
  flex-direction: row-reverse;
  display: block;
`

export const filterContent = styled.div`
  position: relative;
  height: 655px;
  overflow-y: scroll;
  padding-right: 10px;
  
  > .buttons {
    display: flex;
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 1000;
    background: #fff;
  }
  
  > .mobileTitle {
    position: fixed;
    top: 0;
    left: 0;
    font-weight: 100;
    height: 50px;
    background: #f8f8f8;
    width: 100%;
    display: none;
    align-items: center;

    > span {
      display: inline-block;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > p {
      flex: 1;
      margin: 0;
      text-align: center;
      padding-right: 50px;
    }
  }

  ${mq.max[768]} {
    width: 100%;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 10;
    background: #f8f8f8;
    max-height: calc(100vh - 50px);
    overflow: auto;
    padding: 15px 20px 50px 20px;

    > .mobileTitle {
      display: flex;
    }
  }
`

export const topBar = styled.div`
  .linkToCreate {
    height: 42px;
    background-color: var(--accent);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex: 0.6 !important; */
    width: 120px;
    color: #fff;
    gap: 5px;
    padding: 2px 0 !important;
    cursor: pointer;
  }

  float: left;
  width: 100%;
  display: flex;
  align-items: center;

  ${mq.max[768]} {
    display: block;
  }

  > .searchSection {
    flex: 1;
    padding: ${FILTER_PADDING};

    ${mq.max[768]} {
      width: calc(100% + 8px);
      float: left;
      padding: 0;
      margin: 0 0 0 -8px;
    }

    > .header {
      float: left;
      width: 100%;
      display: flex;
      align-items: center;

      .search{
        .input::placeholder {
          color:#ccc;
        }
      }
      
      > p {
        margin: 0;
        color: #252733;
        font-size: 15pt;
        font-family: var(--f-se-bo);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0 0 15px;
      }

      > span {
        display: inline-block;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        cursor: pointer;
        transition: all 150ms linear;

        &:hover {
          background: #f0f0f0;
        }
      }

      > div {
        flex: 1;
        padding: 0 0 0 10px;
        position: relative;

        input {
          border: none;
          border-radius: 8px;
          height: 40px;

          & + span {
            height: 40px;
            padding: 4px 0 0 0;
          }
        }

        .filterButton {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 40px;
          right: 4px;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          display: none;

          ${mq.max[768]} {
            display: flex;
          }
        }

        .full-text {
          margin-left: 5px;
        }

        .tapToSearch {
          width: 50px;

          > .parent {
          }
        }

        .favoriteButton {
          width: unset;
          margin-left: 10px;

          > .parent {
            width: 110px;
          }
        }

        .delete-favoriteButton {
          width: unset;
          margin-left: 5px;

          > .parent {
            width: 110px;
          }
        }
      }
    }
  }

  > .filterSection {
    width: ${FILTER_WIDTH};
    cursor: pointer;

    ${mq.max[768]} {
      display: none;
    }

    > .title {
      float: left;
      width: 100%;
      display: flex;

      > span {
        width: 30px;
        height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        
        .filterFill{
          width: 9px;
          height: 9px;
          border-radius: 20px;
          background: red;
          position: absolute;
          right: 5px;
          top: 11px;
        }
      }

      > p {
        flex: 1;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
      }
    }
  }
`

export const contentBody = styled.div`
  display: flex;
  float: right;
  flex-direction: row-reverse;
  width: 100%;

  ${mq.max[768]} {
    display: block;
    float: right;
  }
`

export const filter = styled.div<{ visibility: boolean }>`
  width: ${FILTER_WIDTH};
  display: ${({ visibility }) => (visibility ? 'block' : 'none')};
  border-radius: 6px;

  > .buttons {
    float: left;
    width: 100%;
  }

  > .mobileTitle {
    position: fixed;
    top: 0;
    left: 0;
    font-weight: 100;
    height: 50px;
    background: #f8f8f8;
    width: 100%;
    display: none;
    align-items: center;

    > span {
      display: inline-block;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > p {
      flex: 1;
      margin: 0;
      text-align: center;
      padding-right: 50px;
    }
  }

  ${mq.max[768]} {
    width: 100%;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 10;
    background: #f8f8f8;
    max-height: calc(100vh - 50px);
    overflow: auto;
    padding: 15px 20px 50px 20px;

    > .mobileTitle {
      display: flex;
    }
  }
`

export const gridWrapper = styled.div`
  position: relative;
  /* height: 90vh; */
  .infinite-scroll-component__outerdiv {
    /* height: 90vh; */
    .infinite-scroll-component {
      height: 90vh;
    }
  }

  > footer {
    display: flex;
    position: sticky;
    > span {
      font-size: 11px;
      &.ml-auto {
        margin-left: auto;
      }
    }
  }
`

export const content = styled.div<{ visibility: boolean; isSubCategoryView: boolean }>`
  .list-wrapper {
    display: grid;
    grid-template-columns: ${({ isSubCategoryView }) => (isSubCategoryView ? '200px 1fr' : '100px 1fr')};

    .product-list {
      border-radius: 8px;
    }
  }

  .category-wrapper {
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    gap: 10px;
    .category {
      display: flex;
      flex-direction: column;
      text-align: center;
      > div {
        width: 170px;
        height: 170px;
        cursor: pointer;
        border-radius: 8px;
        img {
          border-radius: 8px;
          height: 100%;
          width: 100%;
        }
      }
      > span {
        font-weight: 600;
        font-size: 1rem;
        margin-top: 1rem;
      }
    }
  }

  flex: 1;
  .tableContainer {
    .content {
      padding: 2px !important;
    }
  }
  ${mq.max[768]} {
    width: 100%;
    float: right;
    padding: 0;
    margin-top: 10px;
  }

  .tableContainer {
    padding: 0 5px;
  }
  .moreLoadData {
    width: 100%;
  }
  > .tableContainer {
    float: left;
    width: 100%;
    background-color: white;
    margin: 10px 0 0 0;
    border-radius: 15px;

    > p {
      margin: 0;
      padding: 5px 0 15px 25px;
      font-size: 19px;
      color: #252733;
      font-family: var(--f-se-bo);
    }
  }

  .total {
    text-align: right;
    margin-top: 10px;
    span {
      color: rgba(0, 0, 0, 0.49);
      font-size: 15px;
    }

    strong {
      font-size: 15px;
    }
  }
`

export const verticalRadioGroup = styled('div')<{ withLtr?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  
  .radio-wrapper {
    > div {
      margin-right: 10px;
      > span {
        max-width: 19px;
        width: 19px;
        height: 19px;
        border: 2px #EEEEEE solid;
        background: #fff;
      }
    }
  }
  
  .input-container{
    > div > label > {
      input{
        height: 36px;
        color: #979797;
        ::placeholder {
          color: #979797;
        }
      }
      .icon{
        height: 36px;
        bottom: 0;
        svg{
          width: 17px;
        }
      }
  }
 } 
  .ant-checkbox{
    top: 0;
  }

  .ant-checkbox-wrapper {
    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      direction: ltr;
      max-width: 140px;
    }
  }

  &.radio {
    direction: ltr;

    > div {
      flex-direction: row-reverse;
      padding: 9px 9px 9px 4px;

      > span {
        width: 15px;
        height: 15px;
      }
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .ant-checkbox-group-item {
    margin-right: 8px !important;
    justify-content: start;
        align-items: center;
    margin-bottom: 12px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #95b4af;
    border-color: #95b4af;
    ::after{
      left: 27.5%;
      border-color: #004E42;
    }
  }
  
  .ant-checkbox .ant-checkbox-inner {
    border-radius: 5px;
    width: 22px;
    height: 22px;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #004e42 !important;
  }
  
  .ant-checkbox-wrapper:hover .ant-checkbox-checked::after{
  border: 0 !important
  }

  .ant-checkbox-wrapper::after {
    display: none;
  }

  > div {
    padding: 9px 4px;

    &:not(:last-child) {
      border-bottom: 1px solid #ededed;
    }

    > p {
      font-size: 16px;
      color: #4d4d4d99;
    }

    > span {
      border-color: #4d4d4d99;
    }
  }

  > div > label {
   

    > p {
      font-size: 16px;
      color: #4d4d4d99;
    }

    > span {
      border-color: #4d4d4d99;
    }
    .more {
      text-align: center;
    }
  }
`

export const sliderContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 10px;

  > div > label > input {
    height: 30px !important;
  }
`

export const tableImage = styled.div`
  width: 54px;
  height: 54px;
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }

  ${mq.max[992]} {
    width: 60px;
    height: 60px;
  }
`

export const tableButton = styled.span`
  display: block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f8f8f8;
  }
`

export const gridViewContainer = styled.div<{ filterVisibility: boolean; isSubCategoryView: boolean }>`
  float: left;
  width: 100%;

  > div {
    width: ${({ isSubCategoryView }) => (isSubCategoryView ? '154px' : '165px')};
    height: 175px;
  }
`

export const img = styled.img`
  &.bigImg {
    width: 100%;
    border-radius: 10px;
    max-height: 100%;
  }
`

export const gridView = styled.div<{ isAddedToCart: boolean; isAFavorite: boolean; isViewedFavorite: boolean }>`
  float: left;
  padding: 2px;

  .imgModal {
    width: 50rem;
    border-radius: 10px;
    max-height: 30rem;
  }

  > .image {
    width: 100%;
    height: 130px;
    position: relative;
    z-index: 2;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 35px;
      opacity: ${({ isAddedToCart }) => (isAddedToCart ? 1 : 0)};
      bottom: 0;
      left: 0;
      z-index: 4;
      transition: 0.3s all;
      background-color: rgba(0, 0, 0, 0.2);
    }

    &::after {
      content: '';
    }

    .favorite {
      display: flex;
      height: 35px;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 4;
      transition: 0.3s all;
      opacity: ${({ isAFavorite, isViewedFavorite }) => (isAFavorite || isViewedFavorite ? 1 : 0)};
      background-color: rgba(0, 0, 0, 0.2);
      justify-content: end;
      width: 100%;
      align-items: center;
    }

    &:hover .favorite {
      opacity: 1;
    }

    .icon {
      z-index: 5;
      opacity: ${({ isAddedToCart }) => (isAddedToCart ? 1 : 0)};
    }
    .dotIcon {
      z-index: 5;
      opacity: ${({ isAddedToCart }) => (isAddedToCart ? 1 : 0)};
    }

    &:hover::before {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 130px;
      object-fit: contain;

      z-index: 2;
      cursor: pointer;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      transition: all 0.3s ease;
      backdrop-filter: blur(2px);
    }

    &:hover {
      .icon {
        z-index: 5;
        opacity: 1;
      }
      .dotIcon {
        z-index: 5;
        opacity: 1;
      }
      img {
        /* -webkit-filter: blur(2px); */
        /* filter: grayscale(2); */
      }
    }
  }

  .icon {
    cursor: pointer;
    padding: 5px;
    opacity: 0;
    bottom: 0;
    transition: 0.3s all;
    right: 0;
    z-index: 5;
    position: absolute;
    > span {
      border-radius: 50%;
      padding: 5px;
      &:hover {
        background-color: #66666680;
      }
    }
  }
  .dotIcon {
    cursor: pointer;
    opacity: 0;
    padding: 5px;
    bottom: 0;
    transition: 0.3s all;
    left: 0;
    z-index: 5;
    position: absolute;
    > span {
      padding: 5px;
      border-radius: 50%;
      &:hover {
        background-color: #66666680;
      }
    }
  }

  > div {
    float: left;
    width: 100%;
    background-color: white;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 1;

    > .top {
      float: left;
      width: 100%;
      display: flex;
      padding: 2px 2px;
      font-size: 9pt;
      display: flex;
      align-items: center;
      font-size: 9.5px;

      > p {
        margin: 0;
        flex: 1;
        font-family: var(--f-bo);
      }

      > span {
        font-family: var(--f-bo);
        padding-left: 5px;
      }
    }
  }
`

export const modal = styled.div<{ priceCondition: boolean }>`
  float: left;
  width: 100%;
  height: auto;
  min-width: 912px;

  .ant-select-selector {
    max-height: 45px;
    overflow: hidden;
  }

  > .header {
    float: left;
    width: 100%;

    padding: 0 17%;

    > div {
      float: left;
      width: calc(100% / ${({ priceCondition: pc }) => (pc ? 3 : 2)});
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      border-bottom: 1px solid #b7b7b759;

      > p {
        float: left;
        height: 100%;
        margin: 0;
        text-align: left;

        &.key {
          color: black;
          margin: 0 10px 0 0;
        }

        &.value {
          color: var(--accent);
          font-family: var(--f-se-bo);
        }
      }
    }
  }
  .image {
    display: flex;
    padding-left: 10px;
    cursor: pointer;

    justify-content: start;
    align-items: center;
    & > img {
      width: 45px;
      height: 45px;
      border-radius: 5px;
    }
    & > span {
      margin-left: 10px;
    }
  }

  .tableContainer {
    float: left;
    width: 100%;
    margin-top: 20px;

    td > .DD {
      > div {
        max-width: 100px;
      }

      > span {
        padding: 0;
      }

      .dropdownContainer {
        height: 45px;
        border: 1px solid rgba(0, 78, 66, 0.32) !important;
      }

      .icon {
        width: 25px;
      }

      .optionsContainer {
        > span {
          padding: 0 5px;
          font-size: 14px;
        }
      }
    }

    .price {
      height: 45px;
      text-align: center;
      border: 1px solid rgba(0, 78, 66, 0.32) !important;
      border-radius: 5px;
      background: rgba(0, 78, 66, 0.01);
    }

    .explain {
      background-color: #f8f8f8;
      text-align: center;
      border: none;
      min-height: 45px;
      width: 100%;
      padding: 7px;
      height: 45px;
    }

    td {
      text-align: center;
    }

    tbody {
      tr:nth-child(1) {
        td {
          padding-top: 7px;
          padding-bottom: 7px;
          background-color: #efefef;
        }
      }

      td {
        border: none;
        padding: 3px 4px;
      }
      .price {
        background-color: #fff;
      }
      tr {
        > td:nth-child(1) {
          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 45px;
            border: 1px solid rgba(0, 78, 66, 0.32) !important;
            border-radius: 7px;
          }
        }
      }
    }
  }

  .footer {
    float: left;
    width: 100%;
    height: auto;
    padding-top: 10px;

    > div {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      float: left;
      padding: 0 7px;

      &:nth-child(2) {
        justify-content: flex-start;
      }

      > div {
        width: unset;
        height: unset;

        > div {
          padding: 0 30px;
        }
      }
    }
  }
`

export const detailNumbers = styled.div`
  float: left;
  width: calc(6 * 51px);
  height: 45px;

  > div {
    float: left;
    width: 45px;
    height: 45px;
    margin: 0 3px;

    &:not(.withInput) {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f8f8f8;
      color: black;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;
      transition: all 150ms linear;

      &.true {
        background-color: var(--accent);
        color: white;
      }
    }

    &.withInput {
      > input {
        float: left;
        width: 100%;
        height: 100%;
        font-size: 16px;
        margin: 0;
        padding: 0;
        text-align: center;
        border: 1px solid rgba(0, 78, 66, 0.32);
        border-radius: 5px;
        /* background: rgba(0, 78, 66, 0.01); */
      }
    }
  }

  input.noArrow {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`

export const Main = styled.div<{ modalMood: string }>`
  .top-header {
    display: flex;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 10px;
    justify-content: center;
    gap: 20px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;

    .detail-mood {
      border-bottom: ${({ modalMood }) => (modalMood === 'detail' ? '2px solid var(--accent)' : '2px solid transparent')};
      padding-bottom: 5px;
      transition: all 0.5s ease-in-out;
    }
    .technical-mood {
      border-bottom: ${({ modalMood }) => (modalMood === 'technical' ? '2px solid var(--accent)' : '2px solid transparent')};
      padding-bottom: 5px;
      transition: all 0.5s ease-in-out;
    }
  }

  .stones-container {
    /* min-height: 150px; */
    max-height: 260px;
    margin-top: 11px;
    margin-bottom: 30px;
  }

  .ant-spin-dot-item {
    background-color: var(--accent);
  }
  .infoBox {
    display: flex;
    flex-direction: column;
    position: relative;
    left: -18px;
  }

  .img-list-wrapper {
    flex: 1;

    .img {
      border-radius: 10px;
      margin-bottom: 10px;
      > div {
        margin-bottom: 40px;
      }
      img {
        width: 100%;
        border-radius: 10px;
      }
    }
  }
  .main-image {
    height: 381px !important;
    width: 511px !important;
    margin: 0px !important;
  }

  .main-image > div {
    height: 381px !important;
    width: 511px !important;
  }

  .main-image > div > div {
    height: 381px !important;
    width: 511px !important;
  }
  .main-image > div > div > div {
    height: 381px !important;
    width: 511px !important;
    display: flex;
    justify-content: flex-end;
  }
  .main-image > div > div > div > img {
    height: 381px !important;
    width: 381px !important;
    border: 2px solid rgba(54, 55, 64, 0.13);
    margin-right: 10px;
    border-radius: 25px;
  }
  .image > div > div {
    max-width: unset !important;
  }

  .image > div {
    /* height: 350px !important;
    width: 350px !important; */
  }
  .image {
    margin-right: 15px;
  }
  .list {
    display: flex;
    width: 100%;
    max-height: 400px;
    overflow: auto;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    padding: 30px 40px;
    margin-top: 50px;
    border-radius: 10px;
    align-items: flex-start;

    .body-items-container {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
    }

    .body-items-container:last-child {
      margin-bottom: 0;
    }

    .info-wrapper {
      font-size: 15px;
      border-bottom: 0.3px solid rgba(0, 0, 0, 0.5);
      padding-bottom: 20px;
      height: 75px;
      align-items: center;
      justify-content: space-around;
      margin-left: 30px;
      width: 80%;
      display: flex;
    }

    .image {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      img {
        width: 100%;
      }
    }

    .color {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
  .grids-wrapper {
    display: grid;
    grid-template-columns: 240px 240px;
    grid-template-rows: 265px 265px;
    grid-gap: 10px;
  }
  .total-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    .total-item {
      width: 240px;
    }
  }
  .flex-wrapper {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    height: 100%;
    .flex-item {
      width: 240px;
      height: 100%;
      margin-right: 15px;
    }
  }
  .grids-wrapper-piece {
    display: grid;
    grid-template-columns: 240px 240px;
    grid-template-rows: 265px 265px;
    grid-gap: 4px;
  }

  .image-details {
    display: flex;
    align-items: flex-start;
    width: 100% !important;
    min-height: 350px;
    justify-content: center;

    > span {
      &.close {
        position: absolute;
        right: 1%;
        top: 4%;
        cursor: pointer;
        border-radius: 5px;
        transition: all 150ms linear;

        &:hover {
          background: #0000000d;
        }
      }

      &.pic {
        top: 0;
        right: 0;
        margin: 2px;
      }
    }

    .image {
      border-radius: 10px;
      flex: 1;

      .productImg,
      .imgHolder {
        object-fit: contain;
        width: 561px;
        cursor: pointer;
        border-radius: 10px;
        max-height: 478px;
      }
      .productImg {
        border: 2px solid rgba(54, 55, 64, 0.13);
      }

      .bigImg {
        width: 70rem;
        border-radius: 10px;
        max-height: 40rem;
      }
    }
    .details {
      border-radius: 5px;
      padding: 0 0px 0px 4px;
      flex: 1;
      /* height: 350px; */
      border-radius: 8px;

      .header {
        background-color: #f8f8f8;
        padding: 3px 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(54, 55, 64, 0.69);
        border-radius: 8px;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;

        .title {
          font-family: var(--f-bl);
          font-size: 13px;
        }

        .actions {
          display: flex;
          align-items: center;
          gap: 30px;
          svg {
          }
        }
      }
      .body {
        .item {
          padding: 3px 5px 3px 5px;
          display: flex;
          justify-content: space-between;
          overflow-y: scroll;
          :not(:last-child) {
            border-bottom: 1px solid rgba(54, 55, 64, 0.13);
          }
          .title {
            color: #9c9c9c;
            font-size: 13px;
          }

          .value {
            color: #004e42;
            font-family: var(--f-se-bo);
            font-size: 13px;
          }
        }
        .parts {
          display: flex;
          padding: 10px;
          justify-content: space-between;
        }
      }
    }
  }
  .stones {
    display: flex;
    padding: 10px;
    justify-content: space-between;
  }
  .pieces {
    width: 100%;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 230px 230px;
    grid-gap: 2px;
    padding: 5px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 224px;
  }
`

export const PiecesWrapper = styled.div`
  display: flex;
  height: 80px;
  .image {
    border-radius: 10px;
    width: 40px;
    height: 40px;
    margin: 0px;
    cursor: pointer;
    img {
      border-radius: 10px;
    }
  }

  .details {
    padding: 10px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    font-family: var(--f-me);
    width: 80px;
    justify-content: center;
    .top {
      height: 14px;
    }
    .bottom {
      .dots {
        display: flex;
        > div {
          margin-right: 15px;
        }
        .purple-dot {
          border-radius: 50px;
          height: 17px;
          width: 17px;
          background-color: #8c8ab6;
        }
        .red-dot {
          border-radius: 50px;
          height: 17px;
          width: 17px;
          background-color: #ec255a;
        }
      }
    }
  }

  .title {
    font-size: 14px;
    color: #9c9c9c;
    font-weight: normal;
  }

  .value {
    font-weight: bolder;
    font-size: 11px;
    font-family: var(--f-bo);
  }
`
export const ConfirmModalContainer = styled.div`
  padding: 40px;
  p {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 18px;
  }
  .btns {
    display: flex;
  }
`
export const ProductDetailContainer = styled.div`
  .productModal {
    width: 1200px !important;
  }
  & .ant-modal-body > div {
    padding: 0 !important;
  }
`

export const PieceListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: #f8f8f8;
  height: 100%;

  .header {
    text-align: center;
    border-bottom: 0.3px solid #cacaca;
    width: 90%;
    padding-bottom: 3px;
    display: flex;
    justify-content: space-between;
    span:first-of-type {
      font-size: 12px;
      font-weight: bold;
      color: #000000;
    }
  }
  .count-size {
    width: 130px;
    font-size: 9px;
  }
  .some-color {
    font-size: 9px;
  }
  .body {
    display: flex;
    width: 100%;
    height: 100% !important;
    overflow-y: scroll;

    padding-left: 13px;
    padding-right: 13px;
    overflow: auto;
    flex-direction: column;
    align-items: flex-start;
    .chains-container {
      width: 100%;
      padding-right: 13px;
      padding-left: 5px;
      .chain-item {
        display: flex;
        /* justify-content: space-between; */
        width: 100%;
        .chain-image {
          cursor: pointer;
          margin-right: 7px;
        }
      }
      img {
        border-radius: 50px;
      }
      /* justify-content: space-around; */
    }

    .body-items-container {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      border-bottom: 0.3px solid rgba(0, 0, 0, 0.5);
    }

    .info-wrapper {
      font-size: 12px;
      height: 35px !important;
      width: 155px !important;
      margin-right: 10px;
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      border-radius: 0px;
      .icon-container {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 5px;
        margin-right: 21px;
      }
      img {
        width: 25px;
        height: 25px;
      }
    }

    .color {
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
  }
  .footer {
    width: 100%;
    text-align: center;
    border-top: 0.3px solid rgba(0, 0, 0, 0.5);
    padding-top: 10px;

    .total {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
    }

    .gram {
      color: #004e42 !important;
      font-style: normal;
      font-weight: 500;
      font-size: 9px;
    }
  }
`

export const TableViewWrapper = styled.div`
  table {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;

    .header-row {
      th {
        padding: 10px;
        border-bottom: 2px solid var(--accent);
      }
    }

    tbody {
      td {
        padding: 3px 10px;
      }

      .image-wrapper {
        width: 50px;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 6px;
        }
      }
    }
  }
`

export const PropertyModalWrapper = styled('div')<{ properties: number[] }>`
  .property {
    .ant-select-selection-overflow {
      background-color: ${({ properties }) => (properties.length ? '#fff' : 'transparent')};

      .ant-select-selection-item {
        margin: 5px 0 0 5px !important;
      }
    }
  }
`
export const disableModalContent = styled.div`
  float: left;
  width: 100%;
  height: auto;

  > p {
    margin: 0;
    float: left;
    width: 100%;
    font-size: 18px;
    text-align: center;
  }

  > div {
    float: left;
    width: 100%;
    text-align: center;
    margin: 30px 0 0 0;

    > div {
      width: 110px !important;
      float: none !important;
      display: inline-flex !important;
      padding: 0 2px;

      > div {
        min-height: 35px !important;
      }
    }
  }
`
