import { useDispatch } from 'react-redux'
import { Global } from 'common/store/actions'
import { getPaths } from 'common/routes/helpers/get-paths'

export const useLogout = () => {
  const dispatch = useDispatch()
  const paths = getPaths('public')

  const logOut = (): void => {
    try {
      if (!paths.includes(window.location.pathname)) {
        localStorage.clear()
        dispatch(Global.saveUser(null))
        window.location.href = '/authenticate/login'
      }
    } catch (error: any) {
      throw new Error(error)
    }
  }

  return logOut
}
