import { Modal } from 'antd'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Checkbox } from 'common/components/ui-kit/checkbox'
import { Content } from 'common/components/ui-kit/content'
import { Input } from 'common/components/ui-kit/input'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Managers } from 'common/routes/types/roles.type'
import { RootState } from 'common/store/root-reducer'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import * as Lib from './lib'

export const CreateRole: FC = ({}) => {
  const { get, set, on } = Lib.H.useCreateRole()

  const { user } = useSelector((state: RootState) => state.globals)

  return (
    <>

      <PanelWrapper tab={['options', '/options/roles/create']}>
        <Sticky>
          <p className="title">Rol Oluştur</p>
          <div
            style={{
              display: 'flex',
              gap: 5,
              width: '10%',
            }}
          >
            {get.editMode && Managers.includes(user.group.name) ? (
              <Button
                className="delete"
                mood="danger"
                callback={() => on.toggleDeleteModal()}
                text="Sil"
                iconProps={{ color: '#fff', size: 18, style: { marginRight: '5px' } }}
              />
            ) : null}
            <Button text="Kaydet" callback={on.createOrEdit} mood="accent" className="submitButton" />
          </div>
        </Sticky>
        <Content>
          <Lib.S.container>
            <div className="row mb-4">
              <div className="col-lg-3">
                <div className="row">
                  <div className="col-lg-12">
                    <Input
                      label="Rol Ad"
                      required
                      value={get.name}
                      onEnter={on.createOrEdit}
                      onInput={evt => {
                        if (get.editMode && user.group.name === 'Satış Temsilcisi') return
                        set.setName(evt.currentTarget.value)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Lib.S.SelectCategories>
              {get.categoriesState?.map(cat => {
                return (
                  <div className="wrapper">
                    <div className="cat-item parent">
                      <span>{cat.name}</span>
                      <Checkbox
                        label=""
                        checked={cat.isSelected}
                        color="white"
                        onChange={e => {
                          set.setCategoriesState(perv => {
                            return perv?.map(p => {
                              if (p.id === cat.id) {
                                p.isSelected = !p.isSelected
                                p.subItems?.map(sub => {
                                  //sub.isSelected = p.isSelected
                                  sub.disabled = p.isSelected
                                })
                              }
                              return p
                            })
                          })
                        }}
                      />
                    </div>
                    {cat.subItems?.map(sub => {
                      return (
                        <div className="cat-item">
                          <span className={`${sub.disabled ? 'disabled' : ''}`}>{sub.name}</span>
                          <Checkbox
                            label=""
                            color="accent"
                            checked={sub.isSelected}
                            checkedColor={`${sub.disabled ? '#e5e5e5' : ''}`}
                            disabled={sub.disabled}
                            onChange={() => {
                              set.setCategoriesState(perv => {
                                return perv?.map(p => {
                                  p.subItems?.map(pervSub => {
                                    // if (pervSub.isParent === p.id) {
                                    //   if (p.subItems.every(x => !x.isSelected)) {
                                    //     p.isSelected = false
                                    //   }
                                    // }

                                    if (pervSub.id === sub.id) {
                                      pervSub.isSelected = !pervSub.isSelected
                                      //p.isSelected = true
                                    }
                                    return pervSub
                                  })
                                  return p
                                })
                              })
                            }}
                          />
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Lib.S.SelectCategories>
          </Lib.S.container>
          <Modal {...get.deleteModalProps}>
            <Lib.S.deleteModalContent>
              <p>Rol kalıcı olarak silinecektir, Emin misiniz?</p>
              <div>
                <Button text="Onayla" mood="danger" callback={on.deleteRole} />
                <Button text="İptal" mood="accent" callback={on.toggleDeleteModal} />
              </div>
            </Lib.S.deleteModalContent>
          </Modal>
        </Content>
      </PanelWrapper>
    </>
  )
}
