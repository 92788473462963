import Axios, { AxiosRequestConfig } from 'axios'
import responseHandler from './axiosResponeHandler'
import { Storage } from 'common/helpers/storage'

interface IApiCallerConfig extends AxiosRequestConfig {
  readonly customConfigs?: {
    readonly hidePopup?: boolean
  }
}

export const ApiCaller = (configs: IApiCallerConfig) => {
  const deviceId = Storage.get('deviceId')
  Axios.interceptors.request.use(
    async config => {
      return config
    },
    error => {
      return Promise.reject(error)
    },
  )
  Axios.interceptors.response.use(
    response => {
      responseHandler(response, 'success', configs?.customConfigs?.hidePopup)
      return response
    },
    error => {
      responseHandler(error, 'error', configs?.customConfigs?.hidePopup)
      return error.response
    },
  )

  return Axios.request({
    ...configs,
    headers: {...configs.headers,'device-id': deviceId},
  })
}
