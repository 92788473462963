import { Icon } from 'common/components/ui-kit/icon'
import { appName } from 'common/constants/strings'
import React from 'react'
import * as Lib from './lib'
import { useDispatch, useSelector } from 'react-redux'
import { Global } from 'common/store/actions'
import { RootState } from 'common/store/root-reducer'

export const PanelHeader: React.FC<Lib.T.HeaderProps> = ({ title }): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.globals)
  const dispatch = useDispatch()

  return (
    <>
      <Lib.S.container>
        <div className="profile">
          <img src="/images/png/avatar.png" alt="username here" />
          <p>{user?.username || ''}</p>
        </div>

        <div className="icon">
          <Lib.S.optionButton hasBadge>
            <Icon name="bell" size={20} color="#C5C7CD" />
          </Lib.S.optionButton>
        </div>

        <div className="icon">
          <Lib.S.optionButton>
            <Icon name="search" size={20} color="#C5C7CD" />
          </Lib.S.optionButton>
        </div>

        <p className="title">{title}</p>

        <div className="logo">
          <img src="/images/logo/black.logo.svg" alt={appName} />
        </div>

        <div className="menuToggler">
          <Lib.S.optionButton onClick={() => dispatch(Global.toggleSidebar(true))}>
            <Icon name="menu_bars" size={20} color="black" />
          </Lib.S.optionButton>
        </div>
      </Lib.S.container>
    </>
  )
}
