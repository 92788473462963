import styled from 'styled-components'
import { mediaQueries as mq } from 'common/constants/media-queries'

export const container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url('/images/png/world-map.png');

  position: relative;
  .world-map {
    width: 90%;
    height: 90%;
    top: 10%;
    bottom: 0;
    left: 8%;
    position: absolute;
  }

  > div {
    float: left;
    width: 50%;
    height: 100%;
    min-height: 100vh;

    ${mq.max[768]} {
      min-height: unset;
      height: 50%;
      width: 100%;
    }

    &.logoContainer {
      background-color: var(--accent);
      display: flex;
      align-items: center;
      justify-content: center;

      ${mq.max[768]} {
        display: block;
        text-align: center;
      }

      .image-container {
        width: 300px;
        height: 300px;
        z-index: 20;
        > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        ${mq.max[768]} {
          max-width: 200px;
          margin: 50px 0 150px 0;
        }
      }
    }

    &.stuffContainer {
      background-color: #00322a;

      .blur {
        position: absolute;
        width: 50%;
        height: 100vh;
      }

      ${mq.max[768]} {
        border-radius: 50px 50px 0 0;
        margin-top: -50px;
        text-align: center;
        max-height: calc(100vh - 204px);
        overflow: auto;
      }

      > .header {
        width: 100%;
        height: 180px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-right: 15px;
        z-index: 20;
        position: relative;
        .header-image {
          width: 320px;
          height: 320px;
          > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          ${mq.max[768]} {
            display: none;
          }
        }
      }

      > .body,
      > .title,
      > .description {
        padding: 0 100px;

        ${mq.max[992]} {
          padding: 0 50px 50px 50px;
        }
        ${mq.max[768]} {
          padding: 0 20px 20px 20px;
        }
      }

      > .body {
        float: left;
        width: 100%;
        height: auto;
        padding-top: 140px;
        text-align: left;
      }

      > .title {
        font-size: 24px;
        font-family: var(--f-bo);
        float: left;
        width: 100%;
        padding-bottom: 10px;

        ${mq.max[768]} {
          margin: 26px 0 -10px 0;
          background-color: white;
          display: inline-block;
          float: none;
          width: auto;
        }
      }

      > .description {
        font-size: 18px;
        color: #00000099;
        float: left;
        width: 100%;
        margin: 0;
        padding-bottom: 10px;
      }
    }
  }
`
