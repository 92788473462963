export class String {
  static px2num = (asPx: string) => {
    return parseInt(asPx.replace('px', ''))
  }

  static cut = (str: string, ln: number) => {
    return str.length > ln ? str.substr(0, ln - 4).concat('...') : str
  }

  static randomString = (length = 20) => {
    const result = [],
      characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
      charactersLength = characters.length
    for (var i = 0; i < length; i++) result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
    return result.join('')
  }
}
