import { Content } from 'antd/lib/layout/layout'
import Modal from 'antd/lib/modal/Modal'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Icon } from 'common/components/ui-kit/icon'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from './lib'
import { useEmployees } from './lib/hooks'
import { Sticky } from 'common/components/ui-kit/sticky'
import { DropDown2 } from 'common/components/ui-kit/dropdown2'
import { Table } from 'common/components/ui-kit/table-2'
import { Textarea } from 'common/components/ui-kit/textarea'
import { AvatarUploader } from 'common/components/ui-kit/avatar-uploader'
import { DatePicker, TimePicker } from 'antd'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/tr_TR'
import { Checkbox } from 'common/components/ui-kit/checkbox'
import { Breaker } from 'common/components/ui-kit/breaker'

function Employees() {
  const { val, set, on, get } = useEmployees()

  return (
    <PanelWrapper tab={['automation', '/automation/employees']}>
      <Sticky>
        <Lib.S.searchSection>
          <p className="title">Personel Listesi</p>
          <div>
            <div className="inputContainer">
              <Input
                type="search"
                placeholder="Ara..."
                lessBorder
                value={get.query.name}
                onInput={value => set.setQuery({ ...get.query, name: value.currentTarget.value })}
                onEnter={() => {
                  set.setPaginate({limit: 30, page: 1})
                  set.setFetchAgain(perv => !perv)
                }}
              />
            </div>
            <Button
              className="buttonContainer"
              icon="search"
              mood="accent"
              callback={() => {
                set.setPaginate({limit: 30, page: 1})
                set.setFetchAgain(perv => !perv)
              }}
              iconProps={{ color: 'white', size: 20 }}
            />
          </div>

          <DropDown2
            className="dropdown"
            options={val.departmentIds}
            label=""
            height="40px"
            value={get.query.departmentId}
            placeholder="Departman Seç"
            onChange={value => {
              set.setQuery({ ...get.query, departmentId: value })
              set.setFetchAgain(perv => !perv)
            }}
          />

          <DropDown2
            className="dropdown"
            options={get.query.departmentId ? val.filterRoleList : val.roles}
            label=""
            height="40px"
            value={get.query.roleId}
            placeholder="Görevi Seç"
            onChange={value => {
              set.setQuery({ ...get.query, roleId: value })
              set.setFetchAgain(perv => !perv)
            }}
          />

          <div style={{ width: '100%' }} className="d-flex justify-content-end gap-2">
            <Button
              mood="accent"
              callback={() => {
                set.setQuery({ departmentId: undefined, roleId: undefined, name: '' })
                set.setPaginate({limit: 30, page: 1})
                set.setFetchAgain(perv => !perv)
              }}
              text="Filtreyi Temizle"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              containerStyles={{ minHeight: 40, width: 150 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />

            <Button
              mood="accent"
              callback={() => {
                on.handleModal()
                set.setModalProps({ ...val.modalProps, kind: 'create' })
              }}
              text="Personel Ekle"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              icon="plus"
              containerStyles={{ minHeight: 40, width: 150 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />
          </div>
        </Lib.S.searchSection>
      </Sticky>
      <Content>
        <Lib.S.PageWrapper>
          <Lib.S.content>
            <div className="tableContainer">
              <Table
                columns={val.tableColumns}
                columnSizes={val.tableSizes}
                data={val.tableData}
                onLoadMore={on.handleShowMore}
                hasMore={val.tableData.length < get.totalData!}
                paginateLoader={val.paginateLoader}
                infiniteScroll={true}
                rowBackColor={false}
                disabledIndexes={[val.tableIdIndex, val.tableIdIndex - 1]}
                maxHeight="calc(100vh - 110px)"
              />
            </div>
          </Lib.S.content>
        </Lib.S.PageWrapper>

        {/* edit or create modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showEditModal}
          onCancel={on.handleModal}
          className="noHeaderModal userModal"
          width={1000}
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>{val.modalProps.kind === 'edit' ? 'Personel Bilgilerini Düzenle' : 'Yeni Personel Oluştur'}</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={on.handleModal} style={{ cursor: 'pointer' }} />
            </div>
            <div className="row justify-content-center mt-4">
              <AvatarUploader
                id="customer-avatar"
                onChange={set.setAvatar}
                style={{ display: 'inline-block', width: 100, height: 100 }}
                defaultImage={val.defaultAvatar ?? ''}
              />
            </div>
            <div className="row mt-2 px-3">
              <div className="col-lg-4">
                <Input
                  label="İsim"
                  onChange={e => {
                    set.setFirstName(e.target.value)
                  }}
                  required
                  lessBorder
                  value={val.firstName}
                  inputStyles={
                    val.isEmpty && val.firstName.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' } : undefined
                  }
                  labelStyles={val.isEmpty && val.firstName.length < 1 ? { color: 'red' } : undefined}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  label="Soy isim"
                  onChange={e => {
                    set.setLastName(e.target.value)
                  }}
                  lessBorder
                  required
                  value={val.lastName}
                  inputStyles={
                    val.isEmpty && val.lastName.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' } : undefined
                  }
                  labelStyles={val.isEmpty && val.lastName.length < 1 ? { color: 'red' } : undefined}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  label="Mail"
                  onChange={e => {
                    set.setEmail(e.target.value)
                  }}
                  lessBorder
                  required
                  type="email"
                  value={val.email}
                  inputStyles={
                    val.isEmpty && val.email.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' } : undefined
                  }
                  labelStyles={val.isEmpty && val.email.length < 1 ? { color: 'red' } : undefined}
                />
              </div>
            </div>

            <div className="row px-3 d-flex align-items-center" style={{ marginTop: '-1rem' }}>
              <div className="col-lg-3">
                <Input
                  label="Personel kodu"
                  type="number"
                  onChange={e => {
                    set.setIDCode(e.target.value)
                  }}
                  lessBorder
                  required
                  value={val.IDCode}
                  inputStyles={
                    val.isEmpty && val.IDCode.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' } : undefined
                  }
                  labelStyles={val.isEmpty && val.IDCode.length < 1 ? { color: 'red' } : undefined}
                />
              </div>

              <div className="col-lg-3 pt-2">
                <span style={val.isEmpty && val.gender.length < 1 ? { color: 'red' } : undefined}>
                  Cinsiyet <span style={{ color: 'red' }}>*</span>
                </span>
                <DropDown2
                  className="dropdown mt-2 hello"
                  options={val.genders}
                  label=""
                  height="40px"
                  value={val.gender}
                  onChange={value => set.setGender(value === 0 ? 'Erkek' : 'Kadın')}
                  lessBorder
                  haveError={val.isEmpty && val.gender.length < 1}
                />
              </div>

              <div className="col-lg-3 pt-2">
                <div className="flex flex-column">
                  <span style={val.isEmpty && val.birthday.length < 1 ? { color: 'red' } : undefined}>
                    Doğum tarihi <span style={{ color: 'red' }}>*</span>
                  </span>
                  <DatePicker
                    value={val.birthday ? moment(val.birthday, 'YYYY-MM-DD') : null}
                    onChange={(date: moment.Moment | null) => {
                      if (date) {
                        const formattedDateForServer = date.format('YYYY-MM-DD')
                        set.setBirthday(formattedDateForServer)
                      } else {
                        set.setBirthday('')
                      }
                    }}
                    size="small"
                    locale={locale}
                    allowClear={false}
                    className="w-100"
                    style={{
                      height: 46,
                      marginTop: 6,
                      borderRadius: 7,
                      borderColor: val.isEmpty && val.birthday.length < 1 ? 'red' : 'rgb(0 0 0 / 10%)',
                      backgroundColor: val.isEmpty && val.birthday.length < 1 ? 'rgb(164, 0, 0,0.1)' : 'unset',
                    }}
                    inputReadOnly
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>

              <div className="col-lg-3 pt-4">
                <Input
                  label="Telefon"
                  onChange={e => {
                    set.setPhone(e.target.value)
                  }}
                  lessBorder
                  required
                  minLength={13}
                  value={val.phone}
                  inputStyles={
                    val.isEmpty && val.phone.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' } : undefined
                  }
                  labelStyles={val.isEmpty && val.phone.length < 1 ? { color: 'red' } : undefined}
                />
                <span style={{ fontSize: 13, color: val.isEmpty && val.phone.length < 1 ? 'red' : '#7a7a7a' }}>Örnek: +909999999999</span>
              </div>
            </div>

            <div className="row px-3 d-flex align-items-end" style={{ marginTop: '-1rem' }}>
              <div className="col-lg-3">
                <span>İlçe</span>
                <DropDown2
                  className="dropdown mt-2"
                  options={get.neighbourhoodIds}
                  label=""
                  height="40px"
                  value={val.neighbourhoodIdSelected}
                  onChange={value => set.setNeighbourhoodIdSelected(value)}
                />
              </div>
              <div className="col-lg-3">
                <span>Servis</span>
                <DropDown2
                  className="dropdown mt-2"
                  options={get.serviceIds}
                  label=""
                  height="40px"
                  value={val.serviceIdSelected}
                  onChange={value => set.setServiceIdSelected(value)}
                />
              </div>

              <div className="col-lg-6">
                <Input
                  label="Adres"
                  onChange={e => {
                    set.setAddress(e.target.value)
                  }}
                  lessBorder
                  value={val.address}
                />
              </div>
            </div>

            <div className="row px-4">
              <Breaker textBack="#f8f8f8" />
            </div>

            <div className="row px-3">
              <div className="col-lg-4">
                <span style={val.isEmpty && !val.departmentIdSelected ? { color: 'red' } : undefined}>
                  Departman <span style={{ color: 'red' }}>*</span>
                </span>
                <DropDown2
                  className="dropdown mt-2"
                  options={val.departmentIds}
                  label=""
                  height="40px"
                  value={val.departmentIdSelected}
                  onChange={value => set.setDepartmentIdSelected(value)}
                  haveError={val.isEmpty && !val.departmentIdSelected}
                />
              </div>

              <div className="col-lg-4">
                <span style={val.isEmpty && !val.roleIdSelected ? { color: 'red' } : undefined}>
                  Görev <span style={{ color: 'red' }}>*</span>
                </span>
                <DropDown2
                  className="dropdown mt-2"
                  options={val.roles}
                  label=""
                  height="40px"
                  value={val.roleIdSelected}
                  onChange={value => set.setRoleIdSelected(value)}
                  haveError={val.isEmpty && !val.roleIdSelected}
                />
              </div>

              <div className="col-lg-4">
                <span style={val.isEmpty && !val.managerIdSelected ? { color: 'red' } : undefined}>
                  Müdür <span style={{ color: 'red' }}>*</span>
                </span>
                <DropDown2
                  className="dropdown mt-2"
                  options={val.managerIds}
                  label=""
                  height="40px"
                  value={val.managerIdSelected}
                  onChange={value => set.setManagerIdSelected(value)}
                  haveError={val.isEmpty && !val.managerIdSelected}
                />
              </div>
            </div>

            <div className="row px-3 mt-2 d-flex align-items-end">
              <div className="col-lg-4">
                <div className="flex flex-column">
                  <span style={val.isEmpty && val.hireDate.length < 1 ? { color: 'red' } : undefined}>
                    İşe Alma Tarihi <span style={{ color: 'red' }}>*</span>
                  </span>
                  <DatePicker
                    value={val.hireDate ? moment(val.hireDate, 'YYYY-MM-DD') : null}
                    onChange={(date: moment.Moment | null) => {
                      if (date) {
                        const formattedDateForServer = date.format('YYYY-MM-DD')
                        set.setHireDate(formattedDateForServer)
                      } else {
                        set.setHireDate('')
                      }
                    }}
                    size="small"
                    locale={locale}
                    allowClear={false}
                    className="w-100"
                    style={{
                      height: 46,
                      marginTop: 6,
                      borderRadius: 7,
                      borderColor: val.isEmpty && val.hireDate.length < 1 ? 'red' : 'rgb(0 0 0 / 10%)',
                      backgroundColor: val.isEmpty && val.hireDate.length < 1 ? 'rgb(164, 0, 0,0.1)' : 'unset',
                    }}
                    inputReadOnly
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>

              <div className="col-lg-4">
                <Input
                  label="Yıllık İzin"
                  onChange={e => set.setLeaveNumber(e.target.value)}
                  type="number"
                  lessBorder
                  step={1}
                  value={val.leaveNumber ?? undefined}
                  onFocus={e =>
                    e.target.addEventListener(
                      'wheel',
                      function (e) {
                        e.preventDefault()
                      },
                      { passive: false },
                    )
                  }
                />
              </div>

              <div className="col-lg-4">
                <div className="d-flex justify-content-between align-items-center isManager">
                  <span>Yönetici</span>
                  <Checkbox
                    label=""
                    name=""
                    onChange={e => {
                      set.setIsManager(e.target.checked)
                    }}
                    checked={val.isManager}
                    style={{ width: 'auto', marginRight: 5 }}
                  />
                </div>
              </div>
            </div>

            <div className="row px-3 mt-2">
              <Textarea
                label="Açıklama"
                lessBorder
                rows={1}
                className="description"
                value={val.description}
                onInput={evt => set.setDescription(evt.currentTarget.value)}
              />
            </div>

            {/* Rate Box */}
            {/* <div className="px-3 mt-3 d-flex flex-column align-items-center justify-content-center w-100">
              <div>
                <span>Oran</span>
              </div>

              <div className="px-3 mt-3 d-flex align-items-center gap-3 justify-content-center w-100">
                {[...Array(5)].map((star, index) => {
                  const currentRating = index + 1
                  return (
                    <label>
                      <input
                        className="d-none"
                        type="radio"
                        name="rating"
                        value={currentRating}
                        onClick={() => set.setRating(currentRating)}
                      />
                      <Icon
                        name="star_filled"
                        color={currentRating <= (val.hoverStar || val.rating) ? '#ffc107' : '#e4e5e9'}
                        size={40}
                        onMouseEnter={() => set.setHoverStar(currentRating)}
                        onMouseLeave={() => set.setHoverStar(0)}
                      />
                    </label>
                  )
                })}
              </div>
            </div> */}

            <div className="btn">
              <Button
                text="Vazgeç"
                mood="accent_outline"
                callback={() => {
                  on.handleModal()
                }}
                className="btn"
              />
              <Button
                text={val.modalProps.kind === 'edit' ? 'Düzenle' : 'Oluştur'}
                mood="accent"
                callback={() => (val.modalProps.kind === 'edit' ? on.EditEmployee() : on.CreateEmployee())}
                className="btn"
              />
            </div>
          </Lib.S.ModalContainer>
        </Modal>

        {/* leave modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showLeaveModal}
          onCancel={on.handleCancelLeaveModal}
          className="noHeaderModal userModal"
          width={700}
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>İzin Oluştur</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={on.handleCancelLeaveModal} style={{ cursor: 'pointer' }} />
            </div>
            <div className="row px-3 mt-3">
              <div className="col-lg-6">
                <Input label="Ad" labelStyles={{ fontWeight: 'bold' }} required lessBorder disabled value={val.modalProps.editProps.name} />
              </div>
              <div className="col-lg-6 gap-2 align-content-end">
                <span className="fw-bold" style={val.leaveIsEmpty && !val.typeLeaveSelected ? { color: 'red' } : undefined}>
                  İzinTipi <span style={{ color: 'red' }}>*</span>
                </span>
                <DropDown2
                  className="dropdown mt-2"
                  options={get.typeLeave}
                  label=""
                  height="40px"
                  value={val.typeLeaveSelected}
                  onChange={value => {
                    set.setTypeLeaveSelected(value)
                    get.typeLeave.map(item => {
                      value === item.id && set.setIsHourly(item.isHourly)
                    })
                  }}
                  haveError={val.leaveIsEmpty && !val.typeLeaveSelected}
                />
              </div>
            </div>
            {val.isHourly ? (
              <div className="row mt-3 px-3">
                <div className="col-lg-4">
                  <div className="antDatePicker w-100 d-flex flex-column">
                    <span className="fw-bold" style={val.leaveIsEmpty && !Boolean(val.date) ? { color: 'red' } : undefined}>
                      Tarihi <span style={{ color: 'red' }}>*</span>
                    </span>
                    <DatePicker
                      value={val.date ? moment(val.date, 'YYYY-MM-DD') : null}
                      onChange={(date: moment.Moment | null) => {
                        if (date) {
                          const formattedDateForServer = date.format('YYYY-MM-DD')
                          set.setDate(formattedDateForServer)
                        } else {
                          set.setDate('')
                        }
                      }}
                      size="small"
                      locale={locale}
                      allowClear={false}
                      style={{
                        height: 46,
                        borderRadius: 7,
                        borderColor: val.leaveIsEmpty && !Boolean(val.date) ? 'red' : 'rgb(0 0 0 / 10%)',
                        backgroundColor: val.leaveIsEmpty && !Boolean(val.date) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                      }}
                      inputReadOnly
                      format="DD-MM-YYYY"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="antDatePicker w-100 d-flex flex-column">
                    <span className="fw-bold" style={val.leaveIsEmpty && !Boolean(val.startHour) ? { color: 'red' } : undefined}>
                      İtibaren <span style={{ color: 'red' }}>*</span>
                    </span>
                    <TimePicker
                      onChange={(_d: any, val: any) => set.setStartHour(moment(_d))}
                      value={val.startHour ? moment(val.startHour) : undefined}
                      size="small"
                      locale={locale}
                      format={'HH:mm'}
                      style={{
                        height: 46,
                        borderRadius: 7,
                        borderColor: val.leaveIsEmpty && !Boolean(val.startHour) ? 'red' : 'rgb(0 0 0 / 10%)',
                        backgroundColor: val.leaveIsEmpty && !Boolean(val.startHour) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="antDatePicker w-100 d-flex flex-column">
                    <span className="fw-bold" style={val.leaveIsEmpty && !Boolean(val.endHour) ? { color: 'red' } : undefined}>
                      ile <span style={{ color: 'red' }}>*</span>
                    </span>
                    <TimePicker
                      onChange={(_d: any, val: any) => set.setEndHour(moment(_d))}
                      value={val.endHour ? moment(val.endHour) : undefined}
                      size="small"
                      locale={locale}
                      format={'HH:mm'}
                      style={{
                        height: 46,
                        borderRadius: 7,
                        borderColor: val.leaveIsEmpty && !Boolean(val.endHour) ? 'red' : 'rgb(0 0 0 / 10%)',
                        backgroundColor: val.leaveIsEmpty && !Boolean(val.endHour) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="row mt-3 px-3">
                <div className="col-lg-4">
                  <div className="antDatePicker w-100 d-flex flex-column">
                    <span className="fw-bold" style={val.leaveIsEmpty && !Boolean(val.startDate) ? { color: 'red' } : undefined}>
                      Başlangıç tarihi <span style={{ color: 'red' }}>*</span>
                    </span>
                    <DatePicker
                      value={val.startDate ? moment(val.startDate, 'YYYY-MM-DD') : null}
                      onChange={(date: moment.Moment | null) => {
                        if (date) {
                          const formattedDateForServer = date.format('YYYY-MM-DD')
                          set.setStartDate(formattedDateForServer)
                        } else {
                          set.setStartDate('')
                        }
                      }}
                      size="small"
                      locale={locale}
                      allowClear={false}
                      style={{
                        height: 46,
                        borderRadius: 7,
                        borderColor: val.leaveIsEmpty && !Boolean(val.startDate) ? 'red' : 'rgb(0 0 0 / 10%)',
                        backgroundColor: val.leaveIsEmpty && !Boolean(val.startDate) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                      }}
                      inputReadOnly
                      format="DD-MM-YYYY"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="antDatePicker w-100 d-flex flex-column">
                    <span className="fw-bold" style={val.leaveIsEmpty && !Boolean(val.endDate) ? { color: 'red' } : undefined}>
                      Bitiş tarihi <span style={{ color: 'red' }}>*</span>
                    </span>
                    <DatePicker
                      value={val.endDate ? moment(val.endDate, 'YYYY-MM-DD') : null}
                      onChange={(date: moment.Moment | null) => {
                        if (date) {
                          const formattedDateForServer = date.format('YYYY-MM-DD')
                          set.setEndDate(formattedDateForServer)
                        } else {
                          set.setEndDate('')
                        }
                      }}
                      size="small"
                      locale={locale}
                      allowClear={false}
                      style={{
                        height: 46,
                        borderRadius: 7,
                        borderColor: val.leaveIsEmpty && !Boolean(val.endDate) ? 'red' : 'rgb(0 0 0 / 10%)',
                        backgroundColor: val.leaveIsEmpty && !Boolean(val.endDate) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                      }}
                      inputReadOnly
                      format="DD-MM-YYYY"
                    />
                  </div>
                </div>
                {!val.isHourly && (
                  <div className="col-lg-4">
                    <Input
                      label="Gün"
                      type="number"
                      required
                      onChange={event => {
                        set.setDayCount(parseInt(event.target.value))
                      }}
                      labelStyles={{
                        fontWeight: 'bold',
                        padding: 0,
                        color: val.leaveIsEmpty && !Boolean(val.dayCount) ? 'red' : 'unset',
                      }}
                      inputStyles={{
                        height: 42,
                        borderColor: val.leaveIsEmpty && !Boolean(val.dayCount) ? 'red' : 'rgb(0 0 0 / 10%)',
                        backgroundColor: val.leaveIsEmpty && !Boolean(val.dayCount) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                      }}
                      lessBorder
                      value={val.dayCount}
                      onFocus={event =>
                        event.target.addEventListener(
                          'wheel',
                          function (event) {
                            event.preventDefault()
                          },
                          { passive: false },
                        )
                      }
                    />
                  </div>
                )}
              </div>
            )}
            <div className="row px-3 mt-2">
              <Textarea
                label="Açıklama"
                lessBorder
                labelStyles={{ fontWeight: 'bold' }}
                rows={1}
                className="description"
                value={val.leaveDescription}
                onInput={evt => set.setLeaveDescription(evt.currentTarget.value)}
              />
            </div>
            <div className="btn">
              <Button
                text="Vazgeç"
                mood="accent_outline"
                callback={() => {
                  on.handleCancelLeaveModal()
                }}
                className="btn"
              />
              <Button text="Oluştur" mood="accent" callback={() => on.CreateLeave(val.modalProps.editProps.id)} className="btn" />
            </div>
          </Lib.S.ModalContainer>
        </Modal>
      </Content>
    </PanelWrapper>
  )
}

export default Employees
