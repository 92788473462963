import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import swal from 'sweetalert'

// TODO: Add SDKs for Firebase products that you want to use

const developmentFirebaseOptions = {
  apiKey: 'AIzaSyB7jXSSViU8FxdulibFELD5wDC5NMoP6Ww',
  authDomain: 'sozer-sandbox.firebaseapp.com',
  projectId: 'sozer-sandbox',
  storageBucket: 'sozer-sandbox.appspot.com',
  messagingSenderId: '242137550908',
  appId: '1:242137550908:web:9a24c435f21a2062a77339',
  measurementId: 'G-RV80LN5YGC',
}

const productionFirebaseOptions = {
  apiKey: 'AIzaSyCTTkbZyvDYNxOCXtOtyfwyeQnW4q-dsbs',
  authDomain: 'sozer-production.firebaseapp.com',
  projectId: 'sozer-production',
  storageBucket: 'sozer-production.appspot.com',
  messagingSenderId: '801004871835',
  appId: '1:801004871835:web:150de722bd070ddb5aa63e',
  measurementId: 'G-SW9W69XKD0',
}

const testFirebaseOptions = {
  apiKey: 'AIzaSyCzTrz6Dq9ZAsdMDxjkaNepfAeDOm_tifk',
  authDomain: 'test-stage-1f89b.firebaseapp.com',
  projectId: 'test-stage-1f89b',
  storageBucket: 'test-stage-1f89b.appspot.com',
  messagingSenderId: '656363800809',
  appId: '1:656363800809:web:083b2b4d418e79b8608878',
  measurementId: 'G-HMN96E0KXJ',
}

const firebaseStageConfig = {
  apiKey: "AIzaSyD3747ZVzduMtVkXfHj274BAuE-cUzpVnQ",
  authDomain: "sozer-staging.firebaseapp.com",
  databaseURL: "https://sozer-staging-default-rtdb.firebaseio.com",
  projectId: "sozer-staging",
  storageBucket: "sozer-staging.appspot.com",
  messagingSenderId: "214231655238",
  appId: "1:214231655238:web:0cc6cdde8ccf6258c03b47",
  measurementId: "G-81E2LYS3PX"
};

// Initialize Firebase
const app = initializeApp(
  process.env.REACT_APP_MODE === 'production'
    ? productionFirebaseOptions
    : firebaseStageConfig,
)

const messaging = getMessaging(app)

export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker.getRegistration('/firebase-push-notification-scope').then(serviceWorker => {
      if (serviceWorker) return serviceWorker
      return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
        scope: '/firebase-push-notification-scope',
      })
    })
  }
  throw new Error('The browser doesn`t support service worker.')
}

export function requestPermission() {
  Notification.requestPermission()
    .then(permission => {
      if (permission === 'granted') {
      } else {
        return swal({
          text: 'to continue we need notification access make sure you have it before you login',
          icon: 'error',
        })
      }
    })
    .catch(e => {})
}

export const getFirebaseToken = async () => {
  try {
    const serviceWorkerRegistration = await getOrRegisterServiceWorker();
    return await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID, serviceWorkerRegistration });
  } catch (e) {
    console.log(e, 'firebase error');
    swal({
      text: 'to continue we need notification access make sure you have it before you login',
      icon: 'error',
    });
    throw new Error();
  }
};


export const onForegroundMessage = () => new Promise(resolve => onMessage(messaging, payload =>
  resolve(payload)
))
