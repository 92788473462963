import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Content } from 'common/components/ui-kit/content'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Table } from 'common/components/ui-kit/table-2'
import Modal from 'antd/lib/modal/Modal'
import { Input } from 'common/components/ui-kit/input'
import { Icon } from 'common/components/ui-kit/icon'
import * as Lib from './lib'

const AutomationNeighbourhoodList = () => {
  const { get, on, set, val } = Lib.H.useTable()

  return (
    <>
      <PanelWrapper tab={['automation', '/automation/neighbourhood/list']} title="Neighbourhood">
        <Sticky>
          <Lib.S.searchSection>
            <p className="title">İlçe Listesi</p>
            <div style={{ flex: 0.2 }}>
              <Button
                mood="accent"
                callback={() => {
                  set.setModalProps({ ...val.modalInitialState, kind: 'create' })
                  on.handleModalVisibility()
                }}
                text="Yeni İlçe"
                containerStyles={{ minHeight: 42 }}
              />
            </div>
          </Lib.S.searchSection>
        </Sticky>

        <Content padding="0">
          <Lib.S.container>
            <Lib.S.contentBody>
              <Lib.S.content>
                <div className="tableContainer">
                  <Table
                    columns={get.tableColumns}
                    columnSizes={get.tableSizes}
                    data={get.tableData}
                    rowBackColor={false}
                    onLoadMore={on.handleShowMore}
                    totalData={get.totalData}
                    paginate={get.paginate}
                    disabledIndexes={[get.tableIdIndex, get.tableIdIndex - 1]}
                    unActivatedIndex={get.tableIdIndex - 1}
                    maxHeight={get.tableData.length * 70 + 70 + 47 > 1400 ? 1400 + 'px' : get.tableData.length * 70 + 70 + 47 + 'px'}
                  />
                </div>
              </Lib.S.content>
            </Lib.S.contentBody>
          </Lib.S.container>
        </Content>

        {/* Edit and Create modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showEditModal}
          onCancel={on.handleCancelModal}
          className="noHeaderModal userModal"
          width={700}
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>{val.modalProps.kind === 'edit' ? 'düzenle' : 'Yeni Ekle'}</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={on.handleCancelModal} style={{ cursor: 'pointer' }} />
            </div>
            <div className="row px-3 mt-3">
              <div className="col-lg-6">
                <Input
                  label="İlçe"
                  onChange={e => {
                    set.setNeighbourhood(e.target.value)
                  }}
                  required
                  lessBorder
                  value={val.neighbourhood}
                  inputStyles={
                    val.isEmpty && val.neighbourhood.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' } : undefined
                  }
                  labelStyles={val.isEmpty && val.neighbourhood.length < 1 ? { color: 'red' } : undefined}
                />
              </div>
            </div>

            <div className="btn">
              <Button
                text="Vazgeç"
                mood="accent_outline"
                callback={() => {
                  on.handleCancelModal()
                }}
                className="btn"
              />
              <Button
                text={val.modalProps.kind === 'edit' ? 'Düzenle' : 'Oluştur'}
                mood="accent"
                callback={() => on.EditOrCreateNeighbourhood()}
                className="btn"
              />
            </div>
          </Lib.S.ModalContainer>
        </Modal>

        {/* Delete modal */}
        <Modal visible={val.showDeleteModal} onCancel={on.handleDeleteModalVisibility} footer={null} title="İlçe silme">
          <Lib.S.DeleteModalContent>
            <p>İlçe silmek istediğinizden emin misiniz?</p>
            <div>
              <Button text="Vazgeç" mood="accent" callback={() => set.setShowDeleteModal(perv => !perv)} />
              <Button text="Sil" mood="danger" callback={() => on.DeleteNeighbourhood(val.modalProps.editProps.id)} />
            </div>
          </Lib.S.DeleteModalContent>
        </Modal>
      </PanelWrapper>
    </>
  )
}

export default AutomationNeighbourhoodList
