import styled from 'styled-components'
import * as Lib from '.'

export const inputContainer = styled.div<Pick<Lib.T.TextareaProps<string | symbol>, 'minHeight'>>`
  width: 100%;
  height: auto;
  float: left;

  > label {
    float: left;
    width: 100%;
    height: 100%;
    position: relative;

    > span.label {
      float: left;
      width: 100%;
      padding: 7px 0;
      font-size: 15px;

      > span {
        color: var(--accent);
        font-size: 16px;
        margin: 0 0 0 4px;
      }
    }

    > span.icon {
      position: absolute;
      bottom: 2px;
      width: 40px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.password {
        right: 2px;
        cursor: pointer;
      }

      &.custom {
        left: 2px;
      }
    }

    > textarea {
      outline: none !important;
      border: 3px solid rgb(0 0 0 / 10%);
      float: left;
      width: 100%;
      height: 55px;
      border-radius: 7px;
      padding: 10px;
      transition: all 150ms linear;
      min-height: ${({ minHeight }) => (minHeight ? minHeight + 'px' : '100px')};

      &:focus {
        border-color: var(--accent);
      }

      &::placeholder {
        color: rgb(0 0 0 / 50%);
      }
    }
  }
`
