import React from 'react'
import { Gap } from 'common/components/ui-kit/gap'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from '.'

export const Contents: React.FC<Lib.T.ContentsProps> = ({ size, onEnter }): JSX.Element => (
  <>
    <Input label="Ölçüsü" onEnter={onEnter} required value={size.val || ''} onInput={evt => size.set(evt.currentTarget.value)} />
    <Gap mood="H" size={20} />
  </>
)
