import React from 'react'
import { RoutesObject } from '../types/routes.object.type'

// Home
import Home from 'pages/private/home'
import ComingSoon from 'pages/private/comingSoon'

// Pieces
import { BraceletSizes } from 'pages/private/options/bracelet-sizes'
import { Carats } from 'pages/private/options/carats'
import { Coals } from 'pages/private/options/coals'
import { ColorCategory } from 'pages/private/options/color-category'
import { Countries } from 'pages/private/options/countries'
import { NecklaceSizes } from 'pages/private/options/necklace-sizes'
import { Patents } from 'pages/private/options/patents'
import { PendantSizes } from 'pages/private/options/pendant-sizes'
import { ProductCategory } from 'pages/private/options/product-category'
import { ProductCodes } from 'pages/private/options/product-codes'
import { ProductSubCategory } from 'pages/private/options/product-sub-category'
import { RingSizes } from 'pages/private/options/ring-sizes'
import { StoneColors } from 'pages/private/options/stone-color'
import { StoneShapes } from 'pages/private/options/stone-shape'
import { StoneSizes } from 'pages/private/options/stone-sizes'
import { StoneTypes } from 'pages/private/options/stone-types'

// orders
import { CreateCustomer } from 'pages/private/customers/create'
import CustomersList from 'pages/private/customers/list'
import NotFound from 'pages/private/notFound'
import ProductDetailPage from 'pages/private/products/detail'
import Unauthorized from 'pages/private/unauthorized'

// notifications
import FavoriteProduct from 'pages/private/featured'
import LogsList from 'pages/private/logs/list'
import { Log } from 'pages/private/logs/logDetails'
import { Notifications } from 'pages/private/notification'
import { MonacoList } from 'pages/private/products/list/monacoList'
import ProductError from 'pages/private/products/product-error'
import Report from 'pages/private/report'
import TodoList from 'pages/private/todoList'

// automation
import LeaveList from 'pages/private/automation/leave/list'
import HistoryLeave from 'pages/private/automation/leave/historyLeave'
import Employees from 'pages/private/automation/employees'
import AutomationDepartmentList from 'pages/private/automation/departments/list'
import { AutomationCreateDepartment } from 'pages/private/automation/departments/create'
import AutomationRoleList from 'pages/private/automation/roles/list'
import { AutomationCreateRole } from 'pages/private/automation/roles/create'
import AutomationNeighbourhoodList from 'pages/private/automation/neighbourhood'
import AutomationServiceIds from 'pages/private/automation/serviceId'
import LeaveTypeList from 'pages/private/automation/leaveType'
import Automation from 'pages/private/automation/list'
import Overtime from 'pages/private/automation/overtime'
import Dashboard from 'pages/private/dashboard'
import FoodMenu from 'pages/private/automation/restaurant/foodMenu'
import AutomationNews from 'pages/private/automation/news'
import Announcements from 'pages/private/automation/announcements'
import DrinkType from 'pages/private/automation/drinkType'
import CoffeeTime from 'pages/private/automation/coffeeTime'
import Drinks from 'pages/private/automation/drinks'

import { Permissions, RolesTR, SecondaryPermissions } from '../types/roles.type'

import { CreateRole } from 'pages/private/options/roles/create'
import { CreateDepartment } from 'pages/private/options/department/create'
// these top ones gonna delete (not all of them)

// Chat
const Chat = React.lazy(() => import('pages/private/chat'))

// Sub pieces
const Stone = React.lazy(() => import('pages/private/sub-pieces/stone'))
const Chain = React.lazy(() => import('pages/private/sub-pieces/chain'))
const Mine = React.lazy(() => import('pages/private/sub-pieces/mine'))

//Options
const Options = React.lazy(() => import('pages/private/options/list'))
const CreateStone = React.lazy(() => import('pages/private/options/create-stone'))
const Category = React.lazy(() => import('pages/private/options/category-tree'))
const MemberCreate = React.lazy(() => import('pages/private/options/create-member'))
const CreateSize = React.lazy(() => import('pages/private/options/create-size'))
const CreateOrders = React.lazy(() => import('pages/private/options/create-order'))
const CreateBakla = React.lazy(() => import('pages/private/options/create-bakla'))
const CreateLock = React.lazy(() => import('pages/private/options/create-lock'))
const Monaco = React.lazy(() => import('pages/private/options/create-monaco'))
const Slider = React.lazy(() => import('pages/private/options/customer-slider'))
const Printer = React.lazy(() => import('pages/private/options/printer'))
const RoleList = React.lazy(() => import('pages/private/options/roles/list'))
const DepartmentList = React.lazy(() => import('pages/private/options/department/list'))

// Products
const ProductsList = React.lazy(() => import('pages/private/products/list'))
const CreateSingleProduct = React.lazy(() => import('pages/private/products/create'))
const EditProduct = React.lazy(() => import('pages/private/products/edit'))
const PendingProducts = React.lazy(() => import('pages/private/products/pending-products'))
const MonacoChain = React.lazy(() => import('pages/private/products/monacoChain'))
const MonacoEdit = React.lazy(() => import('pages/private/products/edit/monaco-edit'))

//orders
const OrdersListTwo = React.lazy(() => import('pages/private/orders/list-2'))
const OrdersList = React.lazy(() => import('pages/private/orders/list'))
const OrdersTracking = React.lazy(() => import('pages/private/orders/order-tracking'))
const ListBarcode = React.lazy(() => import('pages/private/orders/list-barcode'))
const RecineList = React.lazy(() => import('pages/private/orders/recine-list'))
const CreateOrder = React.lazy(() => import('pages/private/orders/create'))
const EditOrder = React.lazy(() => import('pages/private/orders/edit'))
const OrderDetail = React.lazy(() => import('pages/private/orders/orderDetail'))
const EditDecrease = React.lazy(() => import('pages/private/orders/edit-decrease'))

export const privateRoutes: RoutesObject = {
  dashboard: {
    get: '/dashboard',
    return: <Dashboard />,
    title: 'Dashboard',
    permissions: RolesTR as any,
    newPermissions: Permissions as any,
    secondaryPermissions: SecondaryPermissions as any,
  },

  home: {
    get: '/reports',
    return: <Home />,
    title: 'Reports',
    permissions: RolesTR as any,
    newPermissions: Permissions as any,
    secondaryPermissions: SecondaryPermissions as any,
  },

  // options:
  list: {
    get: '/options/list',
    return: <Options />,
    title: 'Seçenekler',
    permissions: RolesTR as any,
    newPermissions: ['manage-option', 'create-option', 'read-option', 'update-option', 'delete-option', 'admin-access'],
  },

  stone_sizes: {
    get: '/options/stone-sizes',
    return: <StoneSizes />,
    title: 'Taş Ölçüsü',
    permissions: RolesTR as any,
    newPermissions: ['manage-stone', 'create-stone', 'read-stone', 'update-stone', 'delete-stone', 'admin-access'],
  },
  product_category: {
    get: '/options/product-category',
    return: <ProductCategory />,
    title: 'Ürün kategorisi',
    permissions: RolesTR as any,
    newPermissions: ['manage-product', 'read-option', 'admin-access'],
  },
  product_sub_category: {
    get: '/options/product-sub-category',
    return: <ProductSubCategory />,
    title: 'Ürün alt kategorisi',
    permissions: RolesTR as any,
    newPermissions: [
      'manage-productSubCategory',
      'manage-subCategoryType',
      'create-productSubCategory',
      'read-productSubCategory',
      'update-productSubCategory',
      'delete-productSubCategory',
      'admin-access',
    ],
  },
  color_category: {
    get: '/options/color-category',
    return: <ColorCategory />,
    title: 'Renk',
    permissions: RolesTR as any,
    newPermissions: ['manage-option', 'read-option', 'admin-access'],
  },
  stone_types: {
    get: '/options/stone-types',
    return: <StoneTypes />,
    title: 'Taş Türü',
    permissions: RolesTR as any,
    newPermissions: ['manage-stone', 'create-stone', 'read-stone', 'update-stone', 'delete-stone', 'admin-access'],
  },
  stone_shapes: {
    get: '/options/stone-shapes',
    return: <StoneShapes />,
    title: 'Taş Şekli',
    permissions: RolesTR as any,
    newPermissions: ['manage-stone', 'create-stone', 'read-stone', 'update-stone', 'delete-stone', 'admin-access'],
  },
  stone_colors: {
    get: '/options/stone-colors',
    return: <StoneColors />,
    title: 'Taş rengi',
    permissions: RolesTR as any,
    newPermissions: ['manage-stone', 'create-stone', 'read-stone', 'update-stone', 'delete-stone', 'admin-access'],
  },
  sizes_stone: {
    get: '/options/create-stone',
    return: <CreateStone />,
    title: 'Taş Oluştur',
    permissions: RolesTR as any,
    newPermissions: ['manage-stone', 'create-stone', 'read-stone', 'update-stone', 'delete-stone', 'admin-access'],
  },
  product_codes: {
    get: '/options/product-codes',
    return: <ProductCodes />,
    title: 'Ürün Kodları',
    permissions: RolesTR as any,
    newPermissions: ['manage-option', 'admin-access'],
  },
  countries: {
    get: '/options/countries',
    return: <Countries />,
    title: 'Ülkeler',
    permissions: RolesTR as any,
    newPermissions: ['manage-option', 'admin-access'],
  },
  necklace_sizes: {
    get: '/options/necklace-sizes',
    return: <NecklaceSizes />,
    title: 'Kolye Boyutu',
    permissions: RolesTR as any,
    newPermissions: ['manage-option', 'admin-access'],
  },
  ring_sizes: {
    get: '/options/ring-sizes',
    return: <RingSizes />,
    title: 'yüzük boyutu',
    permissions: RolesTR as any,
    newPermissions: ['manage-option', 'admin-access'],
  },
  bracelet_sizes: {
    get: '/options/bracelet-sizes',
    return: <BraceletSizes />,
    title: 'Bilezik Boyutu',
    permissions: RolesTR as any,
    newPermissions: ['manage-option', 'admin-access'],
  },
  // bangle_sizes: {
  //   get: '/options/bangle-sizes',
  //   return: <BangleSizes />,
  //   title: 'Bileklik Boyutu',
  //   permissions: [ 'Yönetim']
  // },
  pendant_sizes: {
    get: '/options/pendant-sizes',
    return: <PendantSizes />,
    title: 'Kolye Boyutu',
    permissions: RolesTR as any,
    newPermissions: ['manage-option', 'admin-access'],
  },
  // choker_sizes: {
  //   get: '/options/choker-sizes',
  //   return: <ChokerSizes />,
  //   title: 'gerdanlık Boyutu',
  //   permissions: [ 'Yönetim']
  // },
  coals: {
    get: '/options/coals',
    return: <Coals />,
    title: 'Maden',
    permissions: RolesTR as any,
    newPermissions: ['manage-option', 'admin-access'],
  },
  carats: {
    get: '/options/carats',
    return: <Carats />,
    title: 'Ayar',
    permissions: RolesTR as any,
    newPermissions: ['manage-option', 'admin-access'],
  },
  patents: {
    get: '/options/patents',
    return: <Patents />,
    title: 'Patentler',
    permissions: RolesTR as any,
    newPermissions: ['manage-option', 'admin-access'],
  },
  category_test: {
    get: '/options/category-tree',
    return: <Category />,
    title: 'Koleksiyonlar',
    permissions: RolesTR as any,
    newPermissions: ['manage-productSubCategory', 'admin-access'],
  },
  members_create_test: {
    get: '/options/create-member',
    return: <MemberCreate />,
    title: 'Üye Oluştur',
    permissions: RolesTR as any,
    newPermissions: ['manage-user', 'admin-access'],
  },
  monaco_create: {
    get: '/options/create-monaco',
    return: <Monaco />,
    title: 'Monaco Oluştur',
    permissions: RolesTR as any,
    newPermissions: ['manage-productSubCategory', 'manage-subCategoryType', 'admin-access'],
  },
  lock_create: {
    get: '/options/create-lock',
    return: <CreateLock />,
    title: 'Kilit Oluştur',
    permissions: RolesTR as any,
    newPermissions: ['manage-lock', 'manage-subLock', 'admin-access'],
  },

  sizes_create: {
    get: '/options/create-size',
    return: <CreateSize />,
    title: 'Ölçü Oluştur',
    permissions: RolesTR as any,
    newPermissions: ['manage-option', 'admin-access'],
  },
  orders_create: {
    get: '/options/create-order',
    return: <CreateOrders />,
    title: 'Sipariş Oluştur',
    permissions: RolesTR as any,
    newPermissions: ['manage-option', 'manage-order', 'read-order', 'create-order', 'update-order', 'delete-order', 'admin-access'],
  },

  slider: {
    get: '/options/customer-slider',
    return: <Slider />,
    title: 'Slider Oluştur',
    permissions: RolesTR as any,
    newPermissions: ['manage-slider', 'admin-access'],
  },

  printer: {
    get: '/options/printer',
    return: <Printer />,
    title: 'Printer',
    permissions: RolesTR as any,
    newPermissions: [
      'create-printer',
      'read-printer',
      'update-printer',
      'disable-printer',
      'delete-printer',
      'manage-printer',
      'admin-access',
    ],
  },

  roleList: {
    get: '/options/roles/list',
    return: <RoleList />,
    title: 'Rol',
    permissions: RolesTR as any,
    newPermissions: ['manage-role', 'create-role', 'read-role', 'update-role', 'delete-role', 'admin-access'],
  },
  create_role: {
    get: '/options/roles/create',
    return: <CreateRole />,
    title: 'Müşteri Oluştur',
    permissions: RolesTR as any,
    newPermissions: ['manage-role', 'create-role', 'admin-access'],
  },
  edit_role: {
    get: '/options/roles/edit/:id',
    return: <CreateRole />,
    title: 'Müşteri Düzenle',
    permissions: RolesTR as any,
    newPermissions: ['manage-role', 'update-role', 'admin-access'],
  },

  departmentList: {
    get: '/options/department/list',
    return: <DepartmentList />,
    title: 'Department',
    permissions: RolesTR as any,
    newPermissions: ['manage-department', 'admin-access'],
  },
  create_department: {
    get: '/options/department/create',
    return: <CreateDepartment />,
    title: 'Department Oluştur',
    permissions: RolesTR as any,
    newPermissions: ['manage-department', 'admin-access'],
  },
  edit_department: {
    get: '/options/department/edit/:id',
    return: <CreateDepartment />,
    title: 'Department Düzenle',
    permissions: RolesTR as any,
    newPermissions: ['manage-department', 'admin-access'],
  },

  // sub-pieces:
  sub_pieces_mine: {
    get: '/sub-pieces/mine',
    return: <Mine />,
    title: 'Mine Ekle',
    permissions: RolesTR as any,
    newPermissions: ['manage-enamel', 'admin-access'],
  },
  sub_pieces_chain: {
    get: '/sub-pieces/chain',
    return: <Chain />,
    title: 'zincir Ekle',
    permissions: RolesTR as any,
    newPermissions: ['manage-chain', 'admin-access'],
  },
  sub_pieces_stone: {
    get: '/sub-pieces/stone',
    return: <Stone />,
    title: 'Taş Ekle',
    permissions: RolesTR as any,
    newPermissions: ['manage-stone', 'admin-access'],
  },

  sub_pieces_bakla: {
    get: '/sub-pieces/bakla',
    return: <CreateBakla />,
    title: 'Bakla Ekle',
    permissions: RolesTR as any,
    newPermissions: ['manage-bakla', 'manage-subBakla', 'admin-access'],
  },

  // products
  products_single_create: {
    get: '/products/create',
    return: <CreateSingleProduct />,
    title: 'Ürün Oluştur',
    permissions: RolesTR as any,
    newPermissions: [
      'manage-product',
      'create-product',
      'read-product',
      'delete-product',
      'manage-monacoProduct',
      'manage-productSubCategory',
      'read-product',
      'admin-access',
    ],
  },
  product_error: {
    get: '/products/product-error',
    return: <ProductError />,
    title: 'Ürün Hatasi',
    permissions: RolesTR as any,
    newPermissions: ['problem-order', 'manage-product', 'admin-access'],
  },
  products_single_edit: {
    get: '/products/edit/:id',
    return: <EditProduct />,
    title: 'Ürün düzenleme',
    permissions: RolesTR as any,
    newPermissions: ['update-product', 'manage-product', 'update-product', 'admin-access'],
  },
  products_detail: {
    get: '/products/detail/:id',
    return: <ProductDetailPage />,
    title: 'Ürün detaylar',
    permissions: RolesTR as any,
    newPermissions: ['manage-product', 'manage-product', 'read-product', 'admin-access'],
  },
  products_list: {
    get: '/products/list',
    return: <ProductsList />,
    title: 'Koleksiyonlar',
    permissions: RolesTR as any,
    newPermissions: ['manage-product', 'manage-productSubCategory', 'read-product', 'admin-access'],
  },
  products_monaco_list: {
    get: '/products/monaco-list',
    return: <MonacoList />,
    title: 'Ürünler',
    permissions: RolesTR as any,
    newPermissions: ['manage-monacoProduct', 'admin-access'],
  },
  pending_products: {
    get: '/products/pending-products',
    return: <PendingProducts />,
    title: 'Bekleyen Ürünler',
    permissions: RolesTR as any,
    newPermissions: ['read-pending-product', 'manage-product', 'admin-access'],
  },
  monaco_chain: {
    get: '/products/monaco-chain',
    return: <MonacoChain />,
    title: 'Monaco Ürün Oluştur',
    permissions: RolesTR as any,
    newPermissions: ['manage-chain', 'create-chain', 'read-chain', 'update-chain', 'delete-chain', 'admin-access'],
  },
  monaco_edit: {
    get: '/products/monaco-edit',
    return: <MonacoEdit />,
    title: 'Monaco Ürün Duzenle',
    permissions: RolesTR as any,
    newPermissions: ['manage-monacoProduct', 'read-monacoProduct', 'admin-access'],
  },

  // orders
  create_order: {
    get: '/orders/create',
    return: <CreateOrder />,
    title: 'Sipariş Ekle',
    permissions: RolesTR as any,
    newPermissions: ['create-order', 'manage-order', 'read-order', 'admin-access'],
  },

  orders_recine_list: {
    get: '/orders/recine-list/:id',
    return: <RecineList />,
    title: 'Siparişler Listesi',
    permissions: RolesTR as any,
    newPermissions: ['manage-order', 'read-order', 'admin-access'],
  },
  orders_tracking: {
    get: '/orders/tracking',
    return: <OrdersTracking />,
    title: 'Siparişler Listesi',
    permissions: RolesTR as any,
    newPermissions: ['manage-order', 'read-order', 'admin-access'],
  },
  orders_barcode: {
    get: '/orders/listbarcode/:id',
    return: <ListBarcode />,
    title: 'Siparişler Barcode',
    permissions: RolesTR as any,
    newPermissions: Permissions as any,
    secondaryPermissions: SecondaryPermissions as any,
  },
  orders_list: {
    get: '/orders/list',
    return: <OrdersList />,
    title: 'Siparişler Listesi',
    permissions: RolesTR as any,
    newPermissions: ['manage-order', 'read-order', 'admin-access'],
  },
  orders_list_2: {
    get: '/orders/list2',
    return: <OrdersListTwo />,
    title: 'Siparişler Listesi',
    permissions: RolesTR as any,
    newPermissions: ['manage-order', 'read-order', 'admin-access'],
  },
  order_detail: {
    get: '/orders/order-detail/:id',
    return: <OrderDetail />,
    title: 'Siparişlerim',
    permissions: RolesTR as any,
    newPermissions: ['manage-order', 'read-one-order', 'admin-access'],
  },
  order_edit: {
    get: '/orders/edit/:id',
    return: <EditOrder />,
    title: 'Sipariş Düzenle',
    permissions: RolesTR as any,
    newPermissions: ['manage-order', 'update-order', 'admin-access'],
  },

  order_editDecrease: {
    get: '/orders/edit-decrease/:id',
    return: <EditDecrease />,
    title: 'Sipariş Düzenle',
    permissions: RolesTR as any,
    newPermissions: ['update-order', 'admin-access'],
  },

  // customers
  customers_list: {
    get: '/customers/list',
    return: <CustomersList />,
    title: 'Müşteriler Listesi',
    permissions: RolesTR as any,
    newPermissions: ['manage-customer', 'admin-access'],
  },

  create_customer: {
    get: '/customers/create',
    return: <CreateCustomer />,
    title: 'Müşteri Oluştur',
    permissions: RolesTR as any,
    newPermissions: ['manage-customer', 'create-customer', 'admin-access'],
  },
  edit_customer: {
    get: '/customers/edit/:id',
    return: <CreateCustomer />,
    title: 'Müşteri Düzenle',
    permissions: RolesTR as any,
    newPermissions: ['manage-customer', 'update-customer', 'admin-access'],
  },
  //notifications
  notifications: {
    get: '/notifications',
    return: <Notifications />,
    title: 'Bildirimler',
    permissions: RolesTR as any,
    newPermissions: Permissions as any,
    secondaryPermissions: SecondaryPermissions as any,
  },

  comingSoon: {
    get: '/comingSoon',
    return: <ComingSoon />,
    title: 'Coming Soon',
    permissions: RolesTR as any,
    newPermissions: Permissions as any,
    secondaryPermissions: SecondaryPermissions as any,
  },

  // report
  report: {
    get: '/report/:id',
    return: <Report />,
    title: 'Rapor',
    permissions: RolesTR as any,
    newPermissions: ['manage-order', 'read-order', 'admin-access'],
  },

  //
  featured: {
    get: '/favorite/product',
    return: <FavoriteProduct />,
    title: 'Katalog',
    permissions: RolesTR as any,
    newPermissions: Permissions as any,
    secondaryPermissions: SecondaryPermissions as any,
  },
  //logs
  logs: {
    get: '/logs/list',
    return: <LogsList />,
    title: 'Logs',
    permissions: RolesTR as any,
    newPermissions: Permissions as any,
    secondaryPermissions: SecondaryPermissions as any,
  },
  single_log: {
    get: '/logs/log-details/:id',
    return: <Log />,
    title: 'Log',
    permissions: RolesTR as any,
    newPermissions: Permissions as any,
    secondaryPermissions: SecondaryPermissions as any,
  },
  //chat
  chat: {
    get: '/chat',
    return: <Chat />,
    title: 'Chat',
    permissions: RolesTR as any,
    newPermissions: [
      'manage-messaging',
      'create-group-messaging',
      'delete-group-messaging',
      'delete-group-users-messaging',
      'add-group-user-messaging',
      'admin-access',
    ],
  },

  todoList: {
    get: '/todoList',
    return: <TodoList />,
    title: 'Notlar',
    permissions: RolesTR as any,
    newPermissions: ['manage-todoList', 'create-todoList', 'read-todoList', 'update-todoList', 'delete-todoList', 'admin-access'],
  },

  leave_list: {
    get: '/automation/leave/list',
    return: <LeaveList />,
    title: 'Leave',
    permissions: RolesTR as any,
    secondaryPermissions: ['*-secondary', 'admin-secondary-access', 'manage-secondary-leave', 'read-secondary-leave'],
  },

  history_leave: {
    get: '/automation/leave/historyLeave',
    return: <HistoryLeave />,
    title: 'History Leave',
    permissions: RolesTR as any,
    secondaryPermissions: ['*-secondary', 'admin-secondary-access', 'manage-secondary-leaveCountHistory'],
  },

  employees: {
    get: '/automation/employees',
    return: <Employees />,
    title: 'Employees',
    permissions: RolesTR as any,
    secondaryPermissions: [
      '*-secondary',
      'admin-secondary-access',
      'manage-secondary-employeeOfficer',
      'read-secondary-employeeOfficer',
      'manage-secondary-leaveCountHistory',
      'read-secondary-leaveCountHistory',
    ],
  },

  departments: {
    get: '/automation/departments',
    return: <AutomationDepartmentList />,
    title: 'Departments',
    permissions: RolesTR as any,
    secondaryPermissions: ['manage-secondary-department', 'admin-secondary-access', '*-secondary'],
  },
  create_automation_departments: {
    get: '/automation/department/create',
    return: <AutomationCreateDepartment />,
    title: 'Create Departments',
    permissions: RolesTR as any,
    secondaryPermissions: ['manage-secondary-department', 'create-secondary-department', 'admin-secondary-access', '*-secondary'],
  },
  edit_automation_departments: {
    get: '/automation/department/edit/:id',
    return: <AutomationCreateDepartment />,
    title: 'Edit Departments',
    permissions: RolesTR as any,
    secondaryPermissions: ['manage-secondary-department', 'update-secondary-department', 'admin-secondary-access', '*-secondary'],
  },

  roles: {
    get: '/automation/roles',
    return: <AutomationRoleList />,
    title: 'Roles',
    permissions: RolesTR as any,
    secondaryPermissions: ['manage-secondary-role', 'admin-secondary-access', '*-secondary'],
  },
  create_automation_role: {
    get: '/automation/role/create',
    return: <AutomationCreateRole />,
    title: 'Create Role',
    permissions: RolesTR as any,
    secondaryPermissions: ['manage-secondary-role', 'create-secondary-role', 'admin-secondary-access', '*-secondary'],
  },
  edit_automation_role: {
    get: '/automation/role/edit/:id',
    return: <AutomationCreateRole />,
    title: 'Edit Role',
    permissions: RolesTR as any,
    secondaryPermissions: ['manage-secondary-role', 'update-secondary-role', 'admin-secondary-access', '*-secondary'],
  },

  neighbourhood: {
    get: '/automation/neighbourhood/list',
    return: <AutomationNeighbourhoodList />,
    title: 'Komşu',
    permissions: RolesTR as any,
    secondaryPermissions: ['manage-secondary-neighbourhood', 'admin-secondary-access', '*-secondary'],
  },

  serviceIds: {
    get: '/automation/serviceIds',
    return: <AutomationServiceIds />,
    title: 'Servisler',
    permissions: RolesTR as any,
    secondaryPermissions: ['manage-secondary-service', 'admin-secondary-access', '*-secondary'],
  },

  automation: {
    get: '/automation/list',
    return: <Automation />,
    title: 'Seçenekler',
    permissions: RolesTR as any,
    secondaryPermissions: [
      '*-secondary',
      'admin-secondary-access',
      'manage-secondary-leaveType',
      'manage-secondary-department',
      'manage-secondary-role',
      'manage-secondary-service',
      'manage-secondary-neighbourhood',
    ],
  },

  leaveType: {
    get: '/automation/leaveType/list',
    return: <LeaveTypeList />,
    title: 'İzin Türü',
    permissions: RolesTR as any,
    secondaryPermissions: ['manage-secondary-leaveType', 'admin-secondary-access', '*-secondary'],
  },

  overtime: {
    get: '/automation/overtime',
    return: <Overtime />,
    title: 'Mesai',
    permissions: RolesTR as any,
    secondaryPermissions: ['*-secondary', 'admin-secondary-access', 'manage-secondary-officerOvertime', 'read-secondary-officerOvertime'],
  },

  foodMenu: {
    get: '/automation/restaurant/foodMenu',
    return: <FoodMenu />,
    title: 'Restoran Menüsü',
    permissions: RolesTR as any,
    secondaryPermissions: ['*-secondary', 'admin-secondary-access', 'manage-secondary-foodMenu'],
  },

  news: {
    get: '/automation/news',
    return: <AutomationNews />,
    title: 'Haberler',
    permissions: RolesTR as any,
    secondaryPermissions: ['*-secondary', 'admin-secondary-access', 'manage-secondary-announcement'],
  },

  announcements: {
    get: '/automation/announcements',
    return: <Announcements />,
    title: 'Anonslar',
    permissions: RolesTR as any,
    secondaryPermissions: ['*-secondary', 'admin-secondary-access', 'manage-secondary-announcement'],
  },

  drinkType: {
    get: '/automation/restaurant/drinkType',
    return: <DrinkType />,
    title: 'Kafeterya',
    permissions: RolesTR as any,
    secondaryPermissions: ['*-secondary', 'admin-secondary-access', 'manage-secondary-drinkType'],
  },

  coffeeTime: {
    get: '/automation/restaurant/coffeeTime',
    return: <CoffeeTime />,
    title: 'Kafeterya Çalışma Saatleri',
    permissions: RolesTR as any,
    secondaryPermissions: ['*-secondary', 'admin-secondary-access', 'manage-secondary-coffeeTime'],
  },

  drinks: {
    get: '/automation/drinks',
    return: <Drinks />,
    title: 'İçecekler',
    permissions: RolesTR as any,
    secondaryPermissions: ['*-secondary', 'admin-secondary-access', 'manage-secondary-drinkOrder'],
  },

  unauthorized: {
    get: '/403',
    return: <Unauthorized />,
    title: 'Unauthorized',
    permissions: RolesTR as any,
    newPermissions: Permissions as any,
    secondaryPermissions: SecondaryPermissions as any,
  },
  notFound: {
    get: '/404',
    return: <NotFound />,
    title: 'NotFound',
    permissions: RolesTR as any,
    newPermissions: Permissions as any,
    secondaryPermissions: SecondaryPermissions as any,
  },
}
