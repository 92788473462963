import { PanelWrapper } from 'common/components/layouts/panel/wrapper'

import { firstLetterUpperCase } from 'common/helpers/firstLetterUpperCase'
import { imageAddresser } from 'common/helpers/image.helper'
import { useTranslation } from 'react-i18next'
// external component
import { useHistory, useParams } from 'react-router-dom'
import { AddMonacoProductToCart } from '../products/list/monacoList/lib/components'
import * as Lib from './Lib'
import { getQueryParam } from '../../../common/helpers/getQueryParam'

const Report = () => {
  const { get, set, on } = Lib.H.useReport()
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const type = params.get('type');
  const original = params.get('original');

  const { t: translateLng } = useTranslation()
  const history = useHistory()

  return (
    <PanelWrapper tab={['report', '/']} title="Rapor" hiddenSidebar>
      <Lib.S.Wrapper>
        <div className="button">
          {/* <Button mood="accent" text="PDF Al" callback={on.pdfGenerator} parentStyles={{ width: '40px' }} /> */}
          {/* <Button mood="orang" text="X" callback={() => history.goBack()} containerStyles={{ width: 40 }} /> */}
        </div>
        <div id="table">
          <Lib.S.PDFContainer>
            {/* header */}
            {!get.headerData.current ? null : (
              <div className="header-container">
                <div className="row header-wrapper">
                  <div className="col-lg-8">
                    <div className="header-item">
                      <span style={{ display: 'inline-block', width: '120px' }}>Order number</span>
                      <span> : {get.headerData.current.orderId}</span>
                    </div>
                    <div className="header-item">
                      <span style={{ display: 'inline-block', width: '120px' }}>Order date</span>
                      <span> : {get.headerData.current.orderDate}</span>
                    </div>
                    <div className="header-item">
                      <span style={{ display: 'inline-block', width: '120px' }}>Karat</span>
                      <span> : {get.headerData.current.karat}</span>
                    </div>
                    <div className="header-item header-item-description">
                      <span>Description</span>
                      <span> : {get.headerData.current.description}</span>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="header-item">
                      <span style={{ display: 'inline-block', width: '145px' }}>Customer number</span>
                      <span> : {get.headerData.current.customer ?? '-'}</span>
                    </div>
                    <div className="header-item">
                      <span style={{ display: 'inline-block', width: '145px' }}>Delivery date </span>
                      <span> : {get.headerData.current.deliveryDate}</span>
                    </div>
                    <div className="header-item">
                      <span style={{ display: 'inline-block', width: '145px' }}>Stamp</span>
                      <span> : {get.headerData.current.patent}</span>
                    </div>
                    <div className="header-item">
                      <span style={{ display: 'inline-block', width: '145px' }}>Total Weight</span>
                      <span> : {get.headerData.current.totalWeight}</span>
                    </div>
                  </div>
                </div>
                <div className="logo">
                  <img src="/images/logo/new_green_logo.png" alt="" />
                </div>
              </div>
            )}

            {/* sozer table */}
            <div className="sozer-wrapper">
              {get.sozerTables.map(table => (
                <div style={{ display: 'flex', gap: '10px', marginBottom: 50 }}>
                  <div className="item" key={table.productId}>
                    <img
                      src={imageAddresser(table.image, true)}
                      alt={table.productCode}
                      onError={e => (e.currentTarget.src = '/images/jpg/placeholder.jpg')}
                    />
                    <p>{table.productCode}</p>
                  </div>

                  <div>
                    <table className="sozer-table">
                      <thead>
                        <tr>
                          {get.sozerTableHeaders.map(header => (
                            <th key={header}>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {table.pieces.map(piece => {
                          let overWritePiece = piece.type

                          if (piece.type === 'ear-rings') {
                            overWritePiece = 'Earings' as any
                          }
                          if (piece.type === 'cuff') {
                            overWritePiece = 'Bangle' as any
                          }

                          return (
                            <tr>
                              <td>{firstLetterUpperCase(overWritePiece)}</td>
                              <td>{piece?.count}</td>
                              <td>{piece?.madeColor?.madeTitle}</td>
                              <td>{piece?.size?.size}</td>
                              <td>{type ? '' : '$' + piece?.price ? piece?.price : '-'}</td>
                              <td>{piece.description ?? '-'}</td>
                            </tr>
                          )
                        })}
                        <tr style={{ position: 'relative' }}>
                          <td style={{ position: 'absolute', transform: "translate('-50%,50%')" }}>
                            <span>Total:{table.weight}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>

            <div className="monaco-wrapper">
              {/* external component for monaco table */}
              {get.monacoTables.map(table => (
                <div style={{ marginBottom: 70 }}>
                  <AddMonacoProductToCart
                    key={table.nameLockChain?.name}
                    height={table.height}
                    width={table.width}
                    pieceWidthHeight={table.toCompare}
                    nameLockChain={table.nameLockChain}
                    alreadyAddedPieces={table.alreadyAddedPieces}
                    color={table.color}
                    productWeight={table.totalWeight}
                    presentationMode
                    language="eng"
                    forPDF
                    original={original ? original : ''}
                    type={type ? type : ''}
                  />
                  {/* <hr style={{ width: '100%' }} /> */}
                </div>
              ))}
            </div>
          </Lib.S.PDFContainer>
        </div>
      </Lib.S.Wrapper>
    </PanelWrapper>
  )
}

export default Report
