import * as Lib from '../../../sub-pieces/lib'

export const automationList = [
  {
    path: '/automation/leaveType/list',
    title: 'İzin türü',
    secondaryPermissions: ['manage-secondary-leaveType', 'admin-secondary-access', '*-secondary'],
  },
  {
    path: '/automation/departments',
    title: 'Departmanlar',
    secondaryPermissions: ['manage-secondary-department', 'admin-secondary-access', '*-secondary'],
  },
  {
    path: '/automation/roles',
    title: 'Görevler',
    secondaryPermissions: ['manage-secondary-role', 'admin-secondary-access', '*-secondary'],
  },
  {
    path: '/automation/neighbourhood/list',
    title: 'İlçeler',
    secondaryPermissions: ['manage-secondary-neighbourhood', 'admin-secondary-access', '*-secondary'],
  },
  {
    path: '/automation/serviceIds',
    title: 'Servisler',
    secondaryPermissions: ['manage-secondary-service', 'admin-secondary-access', '*-secondary'],
  },
] as Lib.T.ListItem[]

export const restaurantList = [
  {
    path: '/automation/restaurant/foodMenu',
    title: 'Restoran Menüsü',
    secondaryPermissions: ['manage-secondary-foodMenu', 'admin-secondary-access', '*-secondary'],
  },
  {
    path: '/automation/restaurant/drinkType',
    title: 'Kafeterya',
    secondaryPermissions: ['manage-secondary-drinkType', 'admin-secondary-access', '*-secondary'],
  },
  {
    path: '/automation/restaurant/coffeeTime',
    title: 'Kafeterya Çalışma Saatleri',
    secondaryPermissions: ['manage-secondary-coffeeTime', 'admin-secondary-access', '*-secondary'],
  },
] as Lib.T.ListItem[]

export const dashboardList = [
  {
    path: '/automation/news',
    title: 'Haberler',
    secondaryPermissions: ['manage-secondary-announcement', 'admin-secondary-access', '*-secondary'],
  },
  {
    path: '/automation/announcements',
    title: 'Anonslar',
    secondaryPermissions: ['manage-secondary-announcement', 'admin-secondary-access', '*-secondary'],
  },
] as Lib.T.ListItem[]