import { Dispatch, FC, SetStateAction } from 'react'
import * as Lib from '.'
import { Checkbox } from 'antd'
import { Icon } from 'common/components/ui-kit/icon'
import { Radio } from 'common/components/ui-kit/radio'

export const TableButton: FC<Lib.T.TableButtonProps> = ({ callback, icon, color, className }): JSX.Element => {
  return (
    <>
      <Lib.S.tableButton className={className} onClick={e => callback(e)}>
        <Icon name={icon} color={color} size={20} />
      </Lib.S.tableButton>
    </>
  )
}

export const TableImage: FC<Lib.T.TableImageProps> = ({ src, alt }): JSX.Element => {
  return (
    <>
      <Lib.S.tableImage>
        <img src={src} alt={alt} />
      </Lib.S.tableImage>
    </>
  )
}

export const FilterCustomerKind: FC<{
  type: 'none-personnel' | 'personnel' | 'all' | undefined
  setType: Dispatch<SetStateAction<'none-personnel' | 'personnel' | 'all' | undefined>>
}> = ({ setType, type }): JSX.Element => {
  const typesArr = [
    { label: 'Müşteriler', id: 'none-personnel' },
    { label: 'Personel', id: 'personnel' },
  ]

  return (
    <>
      <Lib.S.RadioGroup withLtr>
        <div className="radio-wrapper">
          {typesArr.map((item, index) => (
            <Radio
              key={index}
              label={item.label}
              selected={type === item.id}
              callback={val => {
                setType(item.id as 'none-personnel' | 'personnel' | 'all' | undefined)
              }}
            />
          ))}
        </div>
      </Lib.S.RadioGroup>
    </>
  )
}

export const FilterCarats: FC<Lib.T.FilterCaratsProps> = ({ carat, setCarat, carats }): JSX.Element => {
  return (
    <>
      <Lib.S.verticalRadioGroup>
        <Checkbox.Group
          value={carat}
          options={carats.map(item => {
            return {
              label: item?.name!,
              value: item?.id!,
            }
          })}
          onChange={setCarat}
        />
      </Lib.S.verticalRadioGroup>
    </>
  )
}

export const FilterCountries: FC<Lib.T.FilterCountriesProps> = ({ country, setCountry, countries }): JSX.Element => (
  <Lib.S.verticalRadioGroup>
    <Checkbox.Group
      value={country}
      onChange={setCountry}
      options={
        countries.map(item => {
          return {
            label: item?.name!,
            value: item?.id!,
          }
        })!
      }
    />
  </Lib.S.verticalRadioGroup>
)

export const FilterSalesMan: FC<Lib.T.FilterSalesMenProps> = ({ saleMan, setSaleMan, salesMen }): JSX.Element => (
  <Lib.S.verticalRadioGroup>
    <Checkbox.Group
      value={saleMan}
      onChange={setSaleMan}
      options={
        salesMen.map(item => {
          return {
            label: item?.name!,
            value: item?.id!,
          }
        })!
      }
    />
  </Lib.S.verticalRadioGroup>
)

export const FilterStatus: FC<{
  type: 'active' | 'de-active' | undefined
  setType: Dispatch<SetStateAction<'active' | 'de-active' | undefined>>
}> = ({ setType, type }): JSX.Element => {
  const typesArr = [
    { label: 'Aktif', id: 'active' },
    { label: 'Pasif', id: 'de-active' },
  ]

  return (
    <>
      <Lib.S.RadioGroup withLtr>
        <div className="radio-wrapper">
          {typesArr.map((item, index) => (
            <Radio
              key={index}
              label={item.label}
              selected={type === item.id}
              callback={val => {
                setType(item.id as 'active' | 'de-active' | undefined)
              }}
            />
          ))}
        </div>
      </Lib.S.RadioGroup>
    </>
  )
}
