import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Button } from 'common/components/ui-kit/button'
import * as Lib from './lib/index'
import { Content } from 'antd/lib/layout/layout'
import { Table } from 'common/components/ui-kit/table-2'
import { DropDown2 } from 'common/components/ui-kit/dropdown2'

function Drinks() {
  const { val, set, on, get } = Lib.H.useDrinks()

  return (
    <PanelWrapper tab={['automation', '/automation/drinks']}>
      <Sticky>
        <Lib.S.searchSection>
          <p className="title">İçecekler</p>
          <Button
            mood="accent"
            callback={() => on.printContent()}
            text="Print"
            iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
            containerStyles={{ minHeight: 40, width: 150 }}
            parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
          />
          <div className="d-flex justify-content-end gap-2">
            <DropDown2
              className="dropdown"
              options={val.departmentIds}
              label=""
              height="40px"
              value={get.query.departmentId}
              placeholder="Departman Seç"
              onChange={value => {
                set.setQuery({ ...get.query, departmentId: value })
                set.setFetchAgain(perv => !perv)
              }}
              containerStyles={{ width: '300px' }}
            />

            <Button
              mood="accent"
              callback={() => {
                set.setQuery({ departmentId: undefined, roleId: undefined, name: '' })
                set.setFetchAgain(perv => !perv)
              }}
              text="Filtreyi Temizle"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              containerStyles={{ minHeight: 40, width: 150 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />
          </div>
        </Lib.S.searchSection>
      </Sticky>

      <Content>
        <Lib.S.PageWrapper>
          <Lib.S.content>
            <div className="tableContainer">
              <Table
                columns={val.tableColumns}
                columnSizes={val.tableSizes}
                data={val.tableData}
                onLoadMore={on.handleShowMore}
                hasMore={val.tableData.length < get.totalData!}
                paginateLoader={val.paginateLoader}
                infiniteScroll={true}
                rowBackColor={false}
                disabledIndexes={[val.tableIdIndex, val.tableIdIndex - 1]}
                maxHeight="calc(100vh - 110px)"
              />
            </div>
          </Lib.S.content>
        </Lib.S.PageWrapper>
      </Content>
    </PanelWrapper>
  )
}

export default Drinks
