import { RootState } from 'common/store/root-reducer'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { PanelHeader } from '../header'
import { PanelSidebar } from '../sidebar'
import * as Lib from './lib'

export const PanelWrapper: FC<Lib.T.PanelWrapperProps> = ({
  children,
  tab,
  title,
  noCircles,
  header,
  style,
  hiddenSidebar = false,
  backgroundColor,
}): JSX.Element => {
  const { sidebar } = useSelector((state: RootState) => state.globals)
  return (
    <>
      <Lib.S.panelWrapper style={style} backgroundColor={backgroundColor}>
        {hiddenSidebar ? null : <PanelSidebar activeItem={tab} />}
        <Lib.S.PageWrapper sidebar={sidebar} hiddenSidebar={hiddenSidebar} backgroundColor={backgroundColor}>
          {header && <PanelHeader title={title || '--'} />}

          {/* <div className='container'> */}
          <div className="appProvider">{children && children}</div>

          {!noCircles && <img className="circles" src="/images/svg/two-bottom-circles.svg" />}
          {/* </div> */}
        </Lib.S.PageWrapper>
      </Lib.S.panelWrapper>
    </>
  )
}
