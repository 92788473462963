import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'

export class CategoryImageSliderService {
  accessToken: string
  endpoint = 'category-image'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      CategoryImageSliderService.dontAccept()
    }
    if (!user!.accessToken) {
      CategoryImageSliderService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async sliderImage(
    data: Lib.T.CategoryImageSlider.SliderImage,
  ): Promise<{ success: boolean }> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data,
    })

    const { status } = response

    if (status !== 200) {
      return { success: false }
    }

    return { success: true }
  }

  async getSliderImages(id?: number): Promise<Lib.T.CategoryImageSlider.GetSliderImagesResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/category').concat(`/${id}`),
      headers: { authorization: this.accessToken },
    })

    if (status === 200) {
      return { success: true, images: data?.categoryImages }
    } else {
      return { success: false }
    }
  }

  async edit(args:any, id: number): Promise<Lib.T.CategoryImageSlider.EditReturnType> {
    const { status, data } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/${id.toString()}`),
      headers: { authorization: this.accessToken },
      data: { order: args },
    })

    if (status !== 200 || !data) {
      return { success: false }
    }

    return { success: true }
  }

  async deleteSliderImage(id?: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/${id}`),
      headers: { authorization: this.accessToken },
    })
    return status === 200
  }

  static dontAccept() {
    throw 'not-acceptable'
  }
}
