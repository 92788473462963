import { ChangeEvent, DragEvent, useEffect, useState } from 'react'
import { File2Base64, resizeImage, sieveFilesFormat } from 'common/helpers/image.helper'
import Resizer from 'react-image-file-resizer'
import * as Lib from '.'

export const useUploader = ({ onChange }: Pick<Lib.T.UploaderProps, 'onChange'>) => {
  const [b64State, setB64State] = useState<string | null>(null)
  const [fileState, setFileState] = useState<File | null>(null)
  const [resizing, setResizing] = useState<boolean>(false)
  const [isOver, setIsOver] = useState<boolean>(false)

  const setCollections = (collection: File | string) => {
    setResizing(true)
    const fileHolder = sieveFilesFormat(collection as any)[0]
    let b64Holder: string = ''
    File2Base64(fileHolder).then((b64: string) => {
      resizeImage(b64, 700).then((sizes: { w: number; h: number }) => {
        Resizer.imageFileResizer(
          fileHolder,
          sizes.w,
          sizes.h,
          'JPEG',
          100,
          0,
          (uri: any) => {
            b64Holder = uri
            setB64State(b64Holder)
            setFileState(fileHolder)
            setResizing(false)
          },
          'base64',
          sizes.w,
          sizes.h,
        )
      })
    })
    return
  }

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    bubbling(event)
    let collection = event.dataTransfer.files as unknown as File
    setCollections(collection)
    setIsOver(false)
  }

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    bubbling(event)
    event.dataTransfer.dropEffect = 'copy'
    setIsOver(true)
  }

  const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    bubbling(event)
    setIsOver(false)
  }

  const openDialog = (id: string) => document.getElementById(id)!.click()

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setCollections(e.target.files as unknown as File)
    } catch {}
  }

  const handleRemove = (evt: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    evt.stopPropagation()
    setFileState(null)
    setB64State(null)
  }

  useEffect(() => {
    if (onChange) {
      onChange(fileState)
    }
  }, [fileState])

  return {
    onDrop,
    onDragOver,
    onDragLeave,
    isOver,
    openDialog,
    handleFileChange,
    handleRemove,
    b64State,
    fileState,
  }
}

const bubbling = (event: DragEvent<HTMLDivElement>) => {
  event.preventDefault()
  event.stopPropagation()
}
