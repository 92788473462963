import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { AutomationDepartmentService } from 'common/services'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Lib from '.'

export const useTable = () => {
  const history = useHistory()

  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const [totalData, setTotalData] = useState<number | undefined>()
  const [paginate, setPaginate] = useState({ limit: 30, page: 1 })
  const [fetchAgain, setFetchAgain] = useState<boolean>(true)
  const { setLoader } = useLoader()

  const tableIdIndex = 6
  const tableColumns = ['Departman Adı']
  const tableSizes = ['100%', 'unset']

  const fetchAllDepartment = async (reload?: boolean) => {
    const service = new AutomationDepartmentService()
    setLoader(true)
    const { data, total, success } = await service.getList()
    setLoader(false)

    if (data && success) {
      setTotalData(parseInt(total! + ''))
      setTableData(prevData => [
        ...(reload ? [] : prevData),
        ...data.map(i => {
          return [
            <div onClick={() =>history.push(`/automation/department/edit/${i.id}`)}>{i.name}</div>,
            <Lib.S.tableButtons>
              <Lib.C.TableButton icon="edit_outline" callback={() => history.push(`/automation/department/edit/${i.id}`)} />
            </Lib.S.tableButtons>,
          ]
        }),
      ])
    }
  }

  const handleShowMore = () => {
    if (totalData! / paginate.page <= paginate.limit) {
      return
    }
    setPaginate(perval => {
      return { ...perval, page: perval.page + 1 }
    })
  }

  useEffect(() => setTableData([]), [fetchAgain])
  useAsyncEffect(fetchAllDepartment, [paginate, fetchAgain])

  return {
    get: {
      tableData,
      totalData,
      paginate,
      fetchAgain,
      tableIdIndex,
      tableColumns,
      tableSizes,
    },
    set: {
      setFetchAgain,
    },
    on: {
      handleShowMore,
    },
  }
}
