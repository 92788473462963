import styled from 'styled-components'

export const imageSlider = styled.div`
  height: 100%;
  > div {
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }

  > div > div {
    width: 100%;
    height: 100%;
  }
  
  > div > div > div {
    width: 100%;
    height: 100%;
  }

  > div > div > div > div {
    width: 100%;
    height: 100%;
    border-right: 1px #ccc solid;
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: 5px;
    transition: all 150ms linear;

    &:nth-child(3) > svg {
      transform: rotate(180deg);
    }

    &.true {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  * {
    user-select: none;
  }

  
    .left {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) -66.51%, rgba(0, 0, 0, 0) 100%);
      position:absolute;
      left:0;
      top:0;
    }

    .right {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.7) -66.51%, rgba(0, 0, 0, 0) 100%);
      position:absolute;
      right:0;
      top:0;
      
      & > svg{
        transform: rotate(180deg);
      }
    }

     .arrow {
      width: 40px;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 100;

      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
      transition: all 150ms linear;


      &.true {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    > p {
      flex: 1;
      margin: 0;
      text-align: center;

      > span {
        margin: 0 1px;

        &:nth-child(1) {
          font-size: 15pt;
          font-family: var(--f-bo);
        }
        &:nth-child(2) {
          font-size: 13pt;
          font-family: var(--f-se-bo);
        }
        &:nth-child(3) {
          font-size: 13pt;
          font-family: var(--f-se-bo);
        }
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid #ccc;

    > p {
      flex: 2;
    }

    > div {
      flex: 1;
      gap: 15px;
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
  .piece{
    background: #f6f6f6;
  }
  .totalWeight{
    background: var(--accent);
  }
`
