import { FC } from 'react'
import * as Lib from './lib'

export const Breaker: FC<Lib.T.BreakerProps> = ({ note, className, style, textBack, noBorder }): JSX.Element => {
  return (
    <>
      <Lib.S.container className={className} data-content={note} style={style} note={note} textBack={textBack} noBorder={noBorder} />
    </>
  )
}
