import styled from 'styled-components'

export const Container = styled.div`
  label {
    color: #fff;
  }

  input {
    border-width: 0 !important;
    background-color: rgba(255, 255, 255, 0.1) !important;

    ::placeholder {
      color: #fff !important;
    }
  }

  .button {
    z-index: 2;
    position: relative;
  }
`
