import styled from 'styled-components'
import * as Lib from './'
import { mediaQueries as mq } from 'common/constants/media-queries'

export const listContainer = styled.div<Pick<Lib.T.ListProps, 'border'>>`
  float: right;
  width: 100%;
  height: auto;
  border-radius: 15px;
  background-color: white;
  max-width: 410px;
  padding: 5px 10px;

  ${mq.max[768]} {
    max-width: unset;

    > .scrollSection {
      max-width: 100% !important;
    }
  }

  .pagination {
    width: 100%;
    padding: 10px 0 0 0;

    > div {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      > .parent {
        width: 150px;
      }
    }
  }

  .scrollSection {
    float: left;
    width: 100%;
    /* height: 100%; */
    /* overflow: auto; */
    /* max-height: 620px; */
    max-width: 410px;

    > .item {
      float: left;
      width: 100%;
      /* margin: 7px 0; */
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 10px;
      position: relative;

      /* &::after {
        ${({ border }) => (border ? `content: ''` : null)};
        position: absolute;
        left: 0;
        right: 0;
        bottom: -8px;
        width: 100%;
        height: 1px;
        background: #0000001c;
      } */

      &:hover {
        background-color: #f8f8f8;
      }

      > img {
        border-radius: 10px;
        width: 60px;
        height: 60px;
        cursor: pointer;
      }

      > .color {
        width: 30px;
        height: 30px;
        display: block;
        border: 1px solid #000;
      }

      > p {
        flex: 1;
        padding: 0 10px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        > ins {
          opacity: 0.8;
          text-decoration: none;
          font-size: 10pt;
        }
      }

      > span {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 25px;
        height: 25px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 150ms linear;

        &:not(.otherButtons) {
          &:hover {
            background-color: #f4433642;

            path {
              fill: #8b0900;
            }
          }
        }

        &.otherButtons {
          &:hover {
            background-color: #b4b4b442;
          }
        }
      }
    }
  }
`

export const ModalContent = styled.div`
  float: left;
  width: 100%;
  height: auto;

  > p {
    margin: 0;
    float: left;
    width: 100%;
    font-size: 18px;
    text-align: center;
  }

  > div.buttons {
    float: left;
    width: 100%;
    text-align: center;
    margin: 30px 0 0 0;

    > div {
      width: 110px !important;
      float: none !important;
      display: inline-flex !important;
      padding: 0 2px;

      > div {
        min-height: 35px !important;
      }
    }
  }

  > div.inputs {
    float: left;
    width: 100%;
  }
`
