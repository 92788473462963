import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { LeaveTypeService } from 'common/services'
import { Icon } from 'common/components/ui-kit/icon'
import { useEffect, useState, useCallback } from 'react'
import swal from 'sweetalert'
import * as Lib from '.'

const modalInitialState: Lib.T.ModalPropsType = {
  kind: 'post',
  name: '',
  editProps: {
    id: 0,
    type: '',
    dayCount: 0,
    haveSalary: false,
    mustCalculate: false,
    isHourly: false,
  },
}

export const useTable = () => {
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const [totalData, setTotalData] = useState<number | undefined>()
  const [paginate, setPaginate] = useState({ limit: 30, page: 1 })
  const [fetchAgain, setFetchAgain] = useState<boolean>(true)
  const [showEditModal, setShowEditModal] = useState(false)
  const [leaveTypeName, setLeaveTypeName] = useState<string>()
  const [dayCount, setDayCount] = useState<number>()
  const [haveSalary, setHaveSalary] = useState<boolean>()
  const [mustCalculate, setMustCalculate] = useState<boolean>()
  const [isHourly, setIsHourly] = useState<boolean>()
  const [modalProps, setModalProps] = useState<Lib.T.ModalPropsType>(modalInitialState)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { setLoader } = useLoader()

  const tableIdIndex = 7
  const tableColumns = ['İzin Türü', 'Gün Sayısı', 'Saatlik', 'Maaş var', 'Hesaplanması Gerekiyor', '']
  const tableSizes = ['unset', 'unset', 'unset', 'unset', 'unset']

  const handleModalVisibility = useCallback(() => {
    setShowEditModal(prev => !prev)
  }, [])

  const handleDeleteModalVisibility = useCallback(() => {
    setShowDeleteModal(prev => !prev)
  }, [])

  const handleCancelModal = useCallback(() => {
    setModalProps(modalInitialState)
    handleModalVisibility()
    setLeaveTypeName('')
    setDayCount(undefined)
    setHaveSalary(false)
    setIsHourly(false)
    setMustCalculate(false)
  }, [handleModalVisibility])

  const openEditModal = (
    id: number,
    name?: string) => {
    setModalProps({
      ...modalInitialState,
      kind: 'edit',
      editProps: {
        id,
        type: name,
      },
    })
    setLeaveTypeName(name)
    handleModalVisibility()
  }

  const fetchAllLeaveType = useCallback(
    async (reload?: boolean) => {
      const service = new LeaveTypeService()
      setLoader(true)
      try {
        const { leaveTypes, total, success } = await service.getList()
        if (leaveTypes && success) {
          setTotalData(parseInt(total + ''))
          setTableData(prevData => [
            ...(reload ? [] : prevData),
            ...leaveTypes.map(item => [
              <div key={item.leaveType_id} onClick={() => openEditModal(
                item.leaveType_id,
                item?.leaveType_type,
              )}>
                {item.leaveType_type}
              </div>,
              <div>{item?.leaveType_dayCount}</div>,
              <div>{item?.leaveType_isHourly ? <Icon name="check" size={18} color="var(--accent)" /> : <Icon name="cross_with_border" size={18} color="red" />}</div>,
              <div>{item?.leaveType_haveSalary ? <Icon name="check" size={18} color="var(--accent)" /> : <Icon name="cross_with_border" size={18} color="red" />}</div>,
              <div>{item?.leaveType_mustCalculate ? <Icon name="check" size={18} color="var(--accent)" /> : <Icon name="cross_with_border" size={18} color="red" />}</div>,
              <Lib.S.tableButtons>
                <Lib.C.TableButton
                  icon="trash_bin"
                  callback={() => {
                    setShowDeleteModal(true)
                    setModalProps({ ...modalInitialState, kind: 'delete', editProps: { id: item.leaveType_id } })
                  }}
                />
                <Lib.C.TableButton icon="edit_outline" callback={() => openEditModal(
                  item.leaveType_id,
                  item.leaveType_type
                )} />
              </Lib.S.tableButtons>,
            ]),
          ])
        }
      } catch (error) {
        console.error('Failed to fetch roles:', error)
      } finally {
        setLoader(false)
      }
    },
    [handleModalVisibility],
  )

  const EditOrCreateLeaveType = useCallback(async () => {
    const service = new LeaveTypeService()
    setLoader(true)
    try {
      const args = {
        type: leaveTypeName,
        dayCount: dayCount ? dayCount : 0,
        haveSalary: haveSalary ? haveSalary : false,
        mustCalculate: mustCalculate ? mustCalculate : false,
        isHourly: isHourly ? isHourly : false,
      }
      const success = modalProps.kind === 'edit' ? await service.edit(args, modalProps.editProps.id) : await service.create(args)

      if (success) {
        swal({ className: 'rounded-swal', icon: 'success', timer: 700, buttons: [false] }).then(() => {
          setShowEditModal(false)
        })
        setFetchAgain(prev => !prev)
        //setModalProps(modalInitialState)
        setLeaveTypeName('')
        setDayCount(undefined)
        setHaveSalary(false)
        setIsHourly(false)
        setMustCalculate(false)
      }
    } catch (error) {
      console.error('Failed to edit/create Leave Type: ', error)
    } finally {
      setLoader(false)
    }
  }, [leaveTypeName,dayCount,haveSalary,mustCalculate,isHourly, modalProps])

  const DeleteLeaveType = useCallback(
    async (id: number) => {
      const service = new LeaveTypeService()
      setShowDeleteModal(false)
      setLoader(true)
      try {
        const success = await service.delete(id)

        if (success) {
          swal({ className: 'rounded-swal', icon: 'success', timer: 700, buttons: [false] }).then(() => {
            setShowEditModal(false)
          })
          setFetchAgain(prev => !prev)
        }
      } catch (error) {
        console.error('Failed to edit/create Leave Type : ', error)
      } finally {
        setLoader(false)
      }
    },
    [modalProps],
  )

  const handleShowMore = useCallback(() => {
    if (totalData! / paginate.page <= paginate.limit) return
    setPaginate(prev => ({ ...prev, page: prev.page + 1 }))
  }, [paginate, totalData])

  useEffect(() => setTableData([]), [fetchAgain])
  useAsyncEffect(fetchAllLeaveType, [paginate, fetchAgain])

  return {
    val: { leaveTypeName, dayCount, isHourly, haveSalary, mustCalculate, modalProps, showEditModal, modalInitialState, showDeleteModal },
    get: { tableData, totalData, paginate, fetchAgain, tableIdIndex, tableColumns, tableSizes },
    set: { setFetchAgain, setLeaveTypeName, setShowEditModal, setModalProps, setShowDeleteModal,
    setDayCount, setIsHourly, setHaveSalary, setMustCalculate},
    on: {
      handleShowMore,
      handleCancelModal,
      EditOrCreateLeaveType,
      handleModalVisibility,
      DeleteLeaveType,
      handleDeleteModalVisibility,
    },
  }
}
