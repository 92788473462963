import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import swal from 'sweetalert'

export class GroupService {
  accessToken: string
  endpoint = 'groups'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      GroupService.dontAccept()
    }
    if (!user!.accessToken) {
      GroupService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async getList(): Promise<Lib.T.Group.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || !data.groups) {
      swal({
        text: 'Grup listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true, data: data.groups }
  }

  static dontAccept() {
    throw 'not-acceptable'
  }
}
