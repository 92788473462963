import React from 'react'
import { Gap } from 'common/components/ui-kit/gap'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from '.'
import { Uploader } from 'common/components/ui-kit/uploader'
import { DropDown } from 'common/components/ui-kit/dropdown'

export const Contents: React.FC<Lib.T.ContentsProps> = ({ category, image, name, categories, uploaderId, onEnter }): JSX.Element => (
  <>
    {categories &&
      categories.length > 0 &&
      (category.keyword ? (
        <DropDown
          label="Parent category"
          options={categories}
          value={category.name.val || ''}
          setValue={category.name.set}
          overrideKeyword={category.keyword}
          columns={2}
        />
      ) : (
        <DropDown label="Parent category" options={categories} value={category.name.val || ''} setValue={category.name.set} columns={2} />
      ))}

    <Input label="Alt kategori ismi" required onEnter={onEnter} value={name.val || ''} onInput={evt => name.set(evt.currentTarget.value)} />
    <Gap mood="H" size={10} />
    <Uploader id={uploaderId} onChange={image.set} />
    <Gap mood="H" size={20} />
  </>
)
