import { ModalProps } from 'antd'
import { Icon } from 'common/components/ui-kit/icon'
import { NestTableItem } from 'common/components/ui-kit/nest-table/lib/typing'
import { regex } from 'common/constants/regex'
import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { Managers } from 'common/routes/types/roles.type'
import { CustomerService, MediaService, OptionService, UserService } from 'common/services'
import { Customer } from 'common/services/lib/types'
import { CustomerCategory, CustomerEmployee } from 'common/services/lib/types/customer'
import { RootState } from 'common/store/root-reducer'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert'
import * as Lib from '.'

const employeeInitialState: CustomerEmployee = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
}

export const useCreateCustomer = () => {
  const { setLoader } = useLoader()
  const history = useHistory()
  const { user } = useSelector((state: RootState) => state.globals)

  const [name, setName] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confPassword, setConfPassword] = useState<string>('')
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isPersonal, setIsPersonal] = useState<boolean>(false)
  const [canLogin, setCanLogin] = useState(false)

  const [avatar, setAvatar] = useState<File | null>(null)
  const [defaultAvatar, setDefaultAvatar] = useState<string>('')
  const [code, setCode] = useState('')
  const [userStatus, setUserStatus] = useState('')
  const [deleteModal, setDeleteModal] = useState<boolean>(false)

  const [employees, setEmployees] = useState<CustomerEmployee[]>([])
  const [employeeModal, setEmployeeModal] = useState<boolean>(false)
  const [employee, setEmployee] = useState<CustomerEmployee>(employeeInitialState)
  const [employeesTableList, setEmployeesTableList] = useState<Lib.T.EmployeesList[]>([])
  const [employeeEditMode, setEmployeeEditMode] = useState<boolean>(false)
  const [employeeToEdit, setEmployeeToEdit] = useState<number>(0)
  const [employeeToDelete, setEmployeeToDelete] = useState<number>(0)
  const [employeeDeleteModal, setEmployeeDeleteModal] = useState<boolean>(false)
  const [customerDeleteModalState, setCustomerDeleteModalState] = useState<boolean>(false)

  const [countries, setCountries] = useState<string[]>([])
  const [rawCountries, setRawCountries] = useState<{ label: string; id: number }[]>([])
  const [country, setCountry] = useState<string>('')
  const [saleCountry, setSaleCountry] = useState<string[]>([])

  const [customerCategories, setCustomerCategories] = useState<CustomerCategory[]>([])
  const [categories, setCategories] = useState<NestTableItem[]>([])
  const [customer, setCustomer] = useState<Customer.Customer>()

  const [categoriesState, setCategoriesState] = useState<
    | {
        name: string
        isParent: string
        isSelected: boolean
        id: number
        subItems: { name: string; isParent: number; isSelected: boolean; id: number }[]
      }[]
    | []
  >([])

  const [carats, setCarats] = useState<string[]>([])
  const [rawCarats, setRawCarats] = useState<{ id: number; name: string }[]>([])
  const [carat, setCarat] = useState<string[]>([])

  const [editMode, setEditMode] = useState<boolean>(false)
  const [salePersons, setSalPersons] = useState<Lib.T.Users[]>([])
  const [salePersonSearchValue, setSalePersonValue] = useState<string>('')
  const [selectedSalePerson, setSelectedSalePerson] = useState<{ id: number; title: string }[]>([])

  const employeeModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: employeeModal,
    onCancel: () => setEmployeeModal(false),
    className: 'noHeaderModal',
    width: 600,
  }

  const employeeDeleteModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: employeeDeleteModal,
    onCancel: () => setEmployeeDeleteModal(false),
    className: 'noHeaderModal',
    width: 600,
  }

  const customerDeleteModal: ModalProps = {
    footer: null,
    title: null,
    visible: customerDeleteModalState,
    onCancel: () => setCustomerDeleteModalState(false),
    className: 'noHeaderModal',
    width: 600,
  }

  const error = (msg: string) =>
    swal({
      text: msg,
      dangerMode: false,
      icon: 'error',
    })

  const validateForm = (): boolean => {
    if (!name) {
      error('please enter customer company')
      return false
    }

    if (!email.trim()) {
      error('please enter customer email')
      return false
    }

    if (!regex.email.test(email.trim())) {
      error('please enter a valid email')
      return false
    }

    if (!country) {
      error('please select customer country')
      return false
    }
    if (!saleCountry.length) {
      error('please select customer sale country')
      return false
    }

    if (!password) {
      error('please enter customer password')
      return false
    }
    if (!confPassword) {
      error('please confirm customer password')
      return false
    }

    if (password.length && confPassword.length) {
      if (password !== confPassword) {
        error('passwords are not match')
        return false
      }
    }

    // validation for no personal customers
    if (!isPersonal) {
      if (!username.trim()) {
        error('please enter customer username')
        return false
      }

      if (!address) {
        error('please enter customer address')
        return false
      }

      if (!firstName) {
        error('please enter customer Name')
        return false
      }

      if (!lastName) {
        error('please enter customer LastName')
        return false
      }

      if (!phone) {
        error('please enter customer Phone')
        return false
      }

      if (carat.length === 0) {
        error('please select at least one carat')
        return false
      }
      if (!employees || employees.length === 0) {
        error('Lütfen bir kullanıcı oluşturun')
        return false
      }
    }

    return true
  }

  const validateFormForEdit = (): boolean => {
    if (!regex.email.test(email.trim())) {
      error('please enter a valid email')
      return false
    }
    if (password.length && confPassword.length) {
      if (password !== confPassword) {
        error('passwords are not match')
        return false
      }
    }
    return true
  }

  const createOrEdit = async () => {
    let uploadedImage: string = ''

    if (editMode) {
      if (!validateFormForEdit()) {
        return
      }
    } else {
      if (!validateForm()) {
        return
      }
    }

    if (!selectedSalePerson.length && Managers.includes(user.group.name) && !isPersonal) {
      return swal({
        text: 'Lütfen en az bir satış sorumlusu seçiniz.',
        icon: 'error',
      })
    }

    if (avatar) {
      try {
        setLoader(true)
        uploadedImage = await upload(avatar)
      } catch (e) {
        return
      } finally {
        setLoader(false)
      }
    }

    let success = false
    setLoader(true)

    let productCategories: { id: number; productSubCategories: number[] }[] = []
    categoriesState.forEach(cat => {
      let ids: number[] = []
      cat.subItems.forEach(sub => {
        if (sub.isSelected) {
          ids.push(sub.id)
        }
      })
      if (cat.isSelected) {
        productCategories?.push({
          id: cat.id,
          productSubCategories: ids,
        })
      }
    })

    if (editMode) {
      success = await edit(uploadedImage, productCategories)
    } else {
      success = await create(uploadedImage, productCategories)
    }
    setLoader(false)
    if (success) {
      return swal({
        icon: 'success',
        timer: 500,
        buttons: [false],
        className: 'rounded-swal',
      }).then(() => history.replace('/customers/list'))
    }
  }

  const edit = async (uploadedImage: string, productCategories: { id: number; productSubCategories: number[] }[]): Promise<boolean> => {
    const editArgs: Customer.EditArgs = { disabled: !isActive }
    const foundCarats = findCarats()

    if (foundCarats.length > 0) {
      editArgs.karats = foundCarats
    }
    if (productCategories.length > 0) {
      editArgs.productCategories = productCategories
    }
    if (uploadedImage) {
      editArgs.avatar = uploadedImage
    }
    if (address) {
      editArgs.address = address
    }
    if (firstName) {
      editArgs.firstName = firstName
    }
    if (lastName) {
      editArgs.lastName = lastName
    }
    if (phone) {
      editArgs.phone = phone
    }
    if (name) {
      editArgs.company = name.trim()
    }
    const foundCountry = findCountry(country)
    if (foundCountry) {
      editArgs.country = foundCountry
    }
    const foundSaleCountry = findSaleCountries()
    if (foundSaleCountry.length > 0) {
      editArgs.saleCountries = foundSaleCountry
    }
    if (email) {
      editArgs.email = email.trim()
    }
    if (employees?.length > 0) {
      editArgs.employees = employees
    }
    if (password) {
      editArgs.password = password
    }
    if (username) {
      editArgs.username = username.trim()
    }
    if (selectedSalePerson.length) {
      editArgs.salePersons = selectedSalePerson.map(user => user.id)
    }

    editArgs.isPersonnel = isPersonal

    const customerService = new CustomerService()
    return await customerService.edit(editArgs, customer?.id || 0)
  }

  const create = async (uploadedImage: string, productCategories: { id: number; productSubCategories: number[] }[]): Promise<boolean> => {
    const customerService = new CustomerService()
    return await customerService.create({
      ...(findCarats().length ? { karats: findCarats() } : undefined),
      ...(productCategories.length ? { productCategories } : undefined),
      avatar: uploadedImage,
      ...(address.length ? { address: address.trim() } : undefined),
      ...(firstName.length ? { firstName: firstName.trim() } : undefined),
      ...(lastName.length ? { lastName: lastName.trim() } : undefined),
      ...(phone.length ? { phone: phone.trim() } : undefined),
      company: name.trim(),
      country: findCountry(country),
      email: email.trim(),
      ...(employees?.length ? { employees } : undefined),
      disabled: !isActive,
      canLogin,
      password,
      ...(findSaleCountries().length ? { saleCountries: findSaleCountries() } : undefined),
      ...(username.length ? { username: username.trim() } : undefined),
      ...(selectedSalePerson.map(user => user.id).length ? { salePersons: selectedSalePerson.map(user => user.id) } : undefined),
      isPersonnel: isPersonal,
    })
  }

  const addOrEditValidation = () => {
    if (!employee.firstName) {
      error('please enter firstName')
      return false
    }
    if (!employee.lastName) {
      error('please enter lastName')
      return false
    }
    if (!regex.email.test(employee.email)) {
      error('please enter a valid email')
      return false
    }

    if (!code) {
      error('please select a country code')
      return false
    }

    if (!employee.phone.split(' ')[1]) {
      error('please enter a phone number')
      return false
    }
    return true
  }

  const addOrEditEmployee = () => {
    if (!addOrEditValidation()) {
      return
    }

    if (employeeEditMode) {
      if (employeeToEdit !== 0) {
        const newEmployees: CustomerEmployee[] = []
        employees.forEach(i => {
          if (i.id! === employeeToEdit) {
            newEmployees.push(employee)
          } else {
            newEmployees.push(i)
          }
        })

        setEmployees(newEmployees)
        setEmployee(employeeInitialState)
        setEmployeeModal(false)
        setEmployeeEditMode(false)
        setEmployeeToEdit(0)
      }
    } else {
      setEmployees(prev => [...prev, { ...employee, id: Math.random() }])

      setEmployee(employeeInitialState)
      document.getElementById('employeeFirstName')?.focus()
    }
    setEmployeeModal(false)
  }

  const onEmployeesChange = () => {
    setEmployeesTableList(
      employees?.map(i => {
        return [
          i.firstName,
          i.lastName,
          i.phone,
          i.email,
          <div className="tableIcons">
            <span onClick={() => confirmDeleteEmployee(i.id!)}>
              <Icon name="trash_bin" size={20} />
            </span>
            <span onClick={() => editEmployee(i.id!)}>
              <Icon name="edit_outline" size={20} />
            </span>
          </div>,
        ]
      }),
    )
  }

  const checkForEdit = async () => {
    const { href } = window.location
    if (!href.includes('edit')) {
      return
    }
    const id = href.split('/').pop()
    if (!id) {
      return
    }
    setEditMode(true)
    const customerService = new CustomerService()
    const { success, customer: apiCustomer } = await customerService.findOne(parseInt(id))

    if (!success || !apiCustomer) {
      return
    }

    setCustomer(apiCustomer)
    const { address, username, firstName, lastName, phone, avatar, company, country, disabled, email, saleCountries, karats, employees, salePersons, isPersonnel, status, canLogin } =
      apiCustomer
    setUserStatus(apiCustomer.status)
    setIsActive(!disabled)
    setDefaultAvatar(avatar ? avatar.imageThumbnail : '')
    setName(company)
    setEmail(email)
    setCountry(country.country)
    setUsername(username)
    setAddress(address)
    setFirstName(firstName)
    setLastName(lastName)
    setPhone(phone)
    setSaleCountry(saleCountries.map(i => i.country!))
    setCarat(karats.map(i => i.karat!))
    setIsPersonal(isPersonnel)
    setStatus(status)
    setCanLogin(canLogin)

    setEmployees(employees ? employees : [])
    salePersons && setSelectedSalePerson(salePersons?.map(user => ({ id: user.id, title: user.name })))

    const replacement = apiCustomer?.productCategories.map(cat => {
      return {
        name: cat.productCategory,
        id: cat.id,
        isParent: '',
        isSelected: true,
        subItems: cat.productSubCategories.map(sub => {
          return {
            name: sub.productSubCategory,
            isParent: cat.id,
            id: sub.id,
            isSelected: true,
          }
        }),
      }
    })

    setCategoriesState(perv => {
      let copy = [...perv]
      replacement?.forEach(h => {
        const index = perv.findIndex(p => p.id === h.id)
        if (index !== -1) {
          copy[index] = { ...h, subItems: [...copy[index]?.subItems, ...h?.subItems] }
          const unique = [...new Map(copy[index].subItems.map(m => [m.id, m])).values()]
          copy[index] = { ...h, subItems: [...unique] }
        }
      })

      return copy
    })
  }

  const confirmDeleteEmployee = (id: number) => {
    setEmployeeDeleteModal(true)
    setEmployeeToDelete(id)
  }

  const approveCustomer = async () => {
    if (!selectedSalePerson.length && Managers.includes(user.group.name)) {
      return swal({
        text: 'Lütfen en az bir satış sorumlusu seçiniz.',
        icon: 'error',
      })
    }

    const service = new CustomerService()
    setLoader(true)
    await service.approve(customer?.id!)

    setUserStatus('confirmed')

    setLoader(false)
  }

  const deleteCustomer = async () => {
    const service = new CustomerService()
    setLoader(true)
    const success = await service.delete(customer?.id!)
    if (success) {
      history.replace('/customers/list')
      setLoader(false)
    } else {
      setLoader(false)
      setDeleteModal(false)
      return swal({
        text: 'something went wrong',
        icon: 'error',
        timer: 2000,
      })
    }
    setLoader(false)
  }

  const deleteEmployee = () => {
    setEmployees(prev => prev.filter(i => i.id! !== employeeToDelete))
    setEmployeeDeleteModal(false)
  }

  const editEmployee = (id: number) => {
    const employee = employees.filter(i => i.id! === id)[0]
    const [code, _] = employee.phone.split(' ')
    setCode(code)
    setEmployee(employee)
    setEmployeeModal(true)
    setEmployeeEditMode(true)
    setEmployeeToEdit(id)
  }

  const getCategories = async () => {
    const optionService = new OptionService({ type: 'product-category' })
    setLoader(true)
    const { success, data } = await optionService.getProductCategories({})
    setLoader(false)
    if (!success || !data) {
      return
    }

    setCategoriesState(
      data.map(cat => {
        return {
          name: cat.productCategory,
          id: cat.id,
          isParent: '',
          isSelected: false,
          subItems: cat.productSubCategories.map(sub => {
            return {
              name: sub.productSubCategory,
              isParent: cat.id,
              id: sub.id,
              isSelected: false,
            }
          }),
        }
      }),
    )

    setCategories(
      data.map(cat => {
        return {
          key: cat.id,
          name: cat.productCategory,
          subItems: cat.productSubCategories.map(sub => {
            return {
              key: sub.id,
              name: sub.productSubCategory,
            }
          }),
        }
      }),
    )
  }

  const getCountries = async () => {
    const optionService = new OptionService({ type: 'country' })
    const { success, options } = await optionService.read()
    if (!success || !options) {
      return
    }
    const list = options as Lib.T.CountryList[]
    const countries: string[] = []
    const rawCountries: { label: string; id: number }[] = []
    list.map(c => {
      countries.push(c.country!)
      rawCountries.push({
        id: c.id,
        label: c.country!,
      })
    })
    setCountries(countries)
    setRawCountries(rawCountries)
  }

  const getCarats = async () => {
    const optionService = new OptionService({ type: 'karat' })
    const { success, options } = await optionService.read()
    if (!success || !options) {
      return
    }
    const list = options as Lib.T.CaratList[]
    const caratsList: string[] = []
    const rawCaratsList: { id: number; name: string }[] = []
    list.forEach(item => {
      caratsList.push(item.karat!)
      rawCaratsList.push({
        id: item.id,
        name: item.karat!,
      })
    })
    setCarats(caratsList)
    setRawCarats(rawCaratsList)
  }

  const getSalePersons = async () => {
    const service = new UserService()
    const { users } = await service.getList({ group: 'Satış Temsilcisi', disabled: false })
    if (!users) {
      return
    }
    setSalPersons(users)
  }

  const findCountry = (country: string): number => rawCountries.filter(i => i.label === country)[0]?.id

  const findCarats = () => {
    const selectedCarats: number[] = []
    rawCarats.map(i => {
      carat.map(j => {
        if (i.name === j) {
          selectedCarats.push(i.id)
        }
      })
    })
    return selectedCarats
  }

  const findSaleCountries = () => {
    const selectedSaleCountries: number[] = []
    rawCountries.map(i => {
      saleCountry.map(j => {
        if (i.label === j) {
          selectedSaleCountries.push(i.id)
        }
      })
    })
    return selectedSaleCountries
  }

  const upload = async (image: File): Promise<string> => {
    if (!image) {
      return swal({
        text: 'please select an image',
        icon: 'error',
      })
    }

    const filesize = parseFloat((image.size / 1024 / 1024).toFixed(1))

    if (filesize > 2.5) {
      return swal({
        text: 'file size is more thn 2.5 megabyte',
        icon: 'error',
      })
    }
    const mediaService = new MediaService()
    const result = await mediaService.upload(image,'user')
    const { name, success } = result
    if (!success || !name) {
      return swal({
        text: 'something went wrong',
        icon: 'error',
      })
    }
    return name
  }

  const toggleDeleteModal = () => {
    setDeleteModal(perv => !perv)
  }

  const deleteModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: deleteModal,
    onCancel: () => setDeleteModal(false),
    className: 'noHeaderModal',
  }

  useAsyncEffect(getCarats, [])
  useAsyncEffect(getCategories, [])
  useAsyncEffect(getCountries, [])
  useAsyncEffect(getSalePersons, [])
  useAsyncEffect(checkForEdit, [editMode])
  useEffect(onEmployeesChange, [employees])

  return {
    get: {
      name,
      username,
      email,
      country,
      saleCountry,
      address,
      firstName,
      lastName,
      phone,
      userStatus,
      password,
      categoriesState,
      confPassword,
      categories,
      employees,
      editMode,
      deleteModalProps,
      code,
      status,
      employeeModal: {
        props: employeeModalProps,
        toggle: () => setEmployeeModal(!employeeModal),
      },
      employeeDeleteModal: {
        props: employeeDeleteModalProps,
        toggle: () => setEmployeeDeleteModal(!employeeDeleteModal),
      },
      customerDeleteModal: {
        props: customerDeleteModal,
        toggle: () => setCustomerDeleteModalState(!customerDeleteModalState),
      },
      employee,
      employeesTableList,
      countries,
      carats,
      carat,
      isActive,
      isPersonal,
      avatar,
      customerCategories,
      defaultAvatar,
      customer,
      salePersons,
      salePersonSearchValue,
      selectedSalePerson,
      canLogin
    },
    set: {
      setName,
      setIsPersonal,
      setUsername,
      setEmail,
      setCode,
      setCountry,
      setSaleCountry,
      setAddress,
      setFirstName,
      setLastName,
      setPhone,
      setPassword,
      setConfPassword,
      setCategories,
      setEmployees,
      setEmployee,
      setEmployeesTableList,
      setCarats,
      setCarat,
      setIsActive,
      setAvatar,
      setCustomerCategories,
      setEmployeeEditMode,
      setSalePersonValue,
      setSelectedSalePerson,
      setCategoriesState,
      setCanLogin,
    },
    on: {
      addOrEditEmployee,
      findCountry,
      toggleDeleteModal,
      createOrEdit,
      approveCustomer,
      deleteEmployee,
      deleteCustomer,
    },
  }
}
