import React from 'react'
import { Gap } from 'common/components/ui-kit/gap'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from '.'
import { Uploader } from 'common/components/ui-kit/uploader'

export const Contents: React.FC<Lib.T.ContentsProps> = ({ categoryName, image, uploaderId, onEnter }): JSX.Element => (
  <>
    <Input
      label="kategori ismi"
      required
      onEnter={onEnter}
      value={categoryName.val || ''}
      onInput={evt => categoryName.set(evt.currentTarget.value)}
    />
    <Gap mood="H" size={10} />
    <Uploader id={uploaderId} onChange={image.set} />
    <Gap mood="H" size={20} />
  </>
)
