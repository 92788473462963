import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import Modal from 'antd/lib/modal/Modal'
import * as Lib from './lib'
import { Icon } from 'common/components/ui-kit/icon'
import { imageAddresser } from 'common/helpers/image.helper'

function Dashboard() {
  const { val, on, set } = Lib.H.useDashboard()

  return (
    <PanelWrapper tab={['dashboard', 'dashboard']} title="Dashboard" backgroundColor="#F8F8F8">
      <Lib.S.PageWrapper>
        <Lib.C.NewsList news={val.newsList} openNews={on.openDetailModal} />
        <Lib.C.AnnouncementsList announcements={val.announcementsList} openAnnouncement={on.openDetailModal} />

        <Lib.S.WeatherAndCurrencyWrapper>
          <Lib.C.Weather weather={val.weather} />
          <Lib.C.Currencies currencies={val.currencies} />
        </Lib.S.WeatherAndCurrencyWrapper>

        <Lib.C.TodoList todoList={val.todoList} setTodoList={set.setTodoList} updateTodoList={on.updateTodoList} />
        <Lib.C.FoodList foodList={val.foodList} />

        <Lib.S.DrinkAndBirthdayWrapper>
          <Lib.C.Birthdays todayBirthdays={val.todayBirthdays} inWeekBirthdays={val.inWeekBirthdays} formatBirthday={on.formatBirthday} />
          {val.isEmployee && <Lib.C.Drinks setShowDrinkModal={set.setShowDrinkModal} drink={val.drink} drinks={val.drinks} />}
        </Lib.S.DrinkAndBirthdayWrapper>
      </Lib.S.PageWrapper>

      {/* News or Announcements detail modal */}
      <Modal
        footer={null}
        title={null}
        visible={val.modalProps.kind === 'news' ? val.showNewsDetailModal : val.showAnnouncementDetailModal}
        onCancel={() => on.handleCancelDetailModal(val.modalProps.kind)}
        className="noHeaderModal userModal"
        width={1000}
        destroyOnClose
      >
        <Lib.S.ModalContainer>
          <div className="modalTitle">
            <div>
              <span>{val.modalProps.kind === 'news' ? 'Haber detayı' : 'Duyuru Detayı'}</span>
            </div>
            <Icon
              name="close"
              color="#fff"
              size={17}
              onClick={() => on.handleCancelDetailModal(val.modalProps.kind)}
              style={{ cursor: 'pointer' }}
            />
          </div>

          <div className="modalBody">
            {val.modalProps.kind === 'announcement' && (
              <Lib.S.ModalThumbnail>
                <img
                  alt={val.modalProps.title}
                  src={val.modalProps.image ? imageAddresser(val.modalProps.image.image, true) : '/images/png/logo.png'}
                  style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                />
              </Lib.S.ModalThumbnail>
            )}

            <Lib.S.NewsTitle>{val.modalProps.title + ' : '}</Lib.S.NewsTitle>

            <Lib.S.NewsDescription style={{ textAlign: 'justify', marginTop: '-1rem' }}>{val.modalProps.description}</Lib.S.NewsDescription>
          </div>
        </Lib.S.ModalContainer>
      </Modal>

      {/* Drinks modal */}
      <Modal
        footer={null}
        title={null}
        visible={val.showDrinkModal}
        onCancel={() => set.setShowDrinkModal(false)}
        className="noHeaderModal userModal"
        width={500}
        destroyOnClose
      >
        <Lib.S.ModalContainer>
          <div className="modalTitle_2">
            <div>
              <span>İçeceğinizi seçin</span>
            </div>
            <Icon name="close" color="#000" size={17} onClick={() => set.setShowDrinkModal(false)} style={{ cursor: 'pointer' }} />
          </div>

          <div className="modalBody_2">
            {val.drinks?.map(drink => (
              <h3 className="drinkModalItem" onClick={() => on.updateDrink(drink.id)}>
                {drink.title}
              </h3>
            ))}
          </div>
        </Lib.S.ModalContainer>
      </Modal>
    </PanelWrapper>
  )
}

export default Dashboard
