import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { DOM } from 'common/helpers/dom'
import swal from 'sweetalert'

export class EmployeesService {
  accessToken: string
  endpoint = 'automation/employee/officer'
  managerUsersEndpoint = 'automation/employee/manager/users'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      EmployeesService.dontAccept()
    }
    if (!user!.accessToken) {
      EmployeesService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async getList(filters?: Lib.T.Employees.GetListFilters): Promise<Lib.T.Employees.GetListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response

    if (status !== 200 || !data.employees) {
      swal({
        text: 'Üye listesine ulaşılamıyor. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false, total: 0 }
    }
    return { success: true, employees: data.employees, total: data.total }
  }

  async getEmployee(filters?: Lib.T.Employees.GetEmployeeDataFilters, id?: number): Promise<Lib.T.Employees.GetEmployeeResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat(id ? `/${id}` : '')
        .concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response

    if (status !== 200 || !data) {
      swal({
        text: 'Üye listesine ulaşılamıyor. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }
    return { success: true, employee: data }
  }

  async getManagerUsersList(): Promise<Lib.T.Employees.GetEmployeeResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.managerUsersEndpoint),

      headers: { authorization: this.accessToken },
    })
    const { status, data } = response

    if (status !== 200 || !data) {
      swal({
        text: 'Üye listesine ulaşılamıyor. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }
    return { success: true, users: data }
  }

  async editEmployee(args: Lib.T.Employees.EditEmployeeArgs, id?: number): Promise<Lib.T.Employees.CreateUserResult> {
    const response = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id ? `/${id}` : ''),
      headers: { authorization: this.accessToken },
      data: args,
    })

    const { data, status } = response
    if (id) {
      if (status !== 200) {
        EmployeesService.unhandled(Array.isArray(data.message) ? data.message[0] : data.message)

        return { success: false }
      }
    } else {
      if (status !== 201) {
        EmployeesService.unhandled(Array.isArray(data.message) ? data.message[0] : data.message)

        return { success: false }
      }
    }

    return {
      success: true,
    }
  }

  async createEmployee(args: Lib.T.Employees.CreateEmployeeArgs): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    })
    return status === 201
  }

  static dontAccept() {
    throw 'not-acceptable'
  }

  static unhandled(errorMessage?: string) {
    if (errorMessage) {
      return swal({
        text: errorMessage,
        dangerMode: true,
        icon: 'error',
      })
    }
  }
}
