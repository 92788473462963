import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Button } from 'common/components/ui-kit/button'
import * as Lib from './lib/index'
import { Content } from 'antd/lib/layout/layout'
import { Table } from 'common/components/ui-kit/table-2'
import Modal from 'antd/lib/modal/Modal'
import { Icon } from 'common/components/ui-kit/icon'
import { TimePicker } from 'antd'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/tr_TR'

function CoffeeTime() {
  const { val, set, on } = Lib.H.useCoffeeTime()

  return (
    <PanelWrapper tab={['automation', '/automation/restaurant/coffeeTime']}>
      <Sticky>
        <Lib.S.searchSection>
          <p className="title">Kafeterya Çalışma Saatleri</p>

          <div className="d-flex justify-content-end gap-2">
            <Button
              mood="accent"
              callback={() => {
                set.setShowModal(true)
                set.setModalProps({ ...val.modalProps, kind: 'create' })
              }}
              text="Çalışma Zamanı Ekle"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              icon="plus"
              containerStyles={{ minHeight: 40, width: 200 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />
          </div>
        </Lib.S.searchSection>
      </Sticky>

      <Content>
        <Lib.S.PageWrapper>
          <Lib.S.content>
            <div className="tableContainer">
              <Table
                columns={val.tableColumns}
                columnSizes={val.tableSizes}
                data={val.tableData}
                rowBackColor={false}
                disabledIndexes={[val.tableIdIndex, val.tableIdIndex - 1]}
                maxHeight="calc(100vh - 110px)"
              />
            </div>
          </Lib.S.content>
        </Lib.S.PageWrapper>

        {/* Create and Edit modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showModal}
          onCancel={on.handleModal}
          className="noHeaderModal userModal"
          width={700}
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>Çalışma zamanı oluştur</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={on.handleModal} style={{ cursor: 'pointer' }} />
            </div>

            <div className="row mt-2 px-3">
              <div className="col-lg-6">
                <div className="antDatePicker w-100 d-flex flex-column">
                  <span className="fw-bold" style={val.isEmpty && !Boolean(val.startHour) ? { color: 'red' } : undefined}>
                    Başlangıç <span style={{ color: 'red' }}>*</span>
                  </span>
                  <TimePicker
                    onChange={(_d: any, val: any) => set.setStartHour(moment(_d))}
                    value={val.startHour ? moment(val.startHour) : undefined}
                    size="small"
                    locale={locale}
                    format={'HH:mm'}
                    style={{
                      height: 46,
                      borderRadius: 7,
                      borderColor: val.isEmpty && !Boolean(val.startHour) ? 'red' : 'rgb(0 0 0 / 10%)',
                      backgroundColor: val.isEmpty && !Boolean(val.startHour) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="antDatePicker w-100 d-flex flex-column">
                  <span className="fw-bold" style={val.isEmpty && !Boolean(val.endHour) ? { color: 'red' } : undefined}>
                    Bitiş <span style={{ color: 'red' }}>*</span>
                  </span>
                  <TimePicker
                    onChange={(_d: any, val: any) => set.setEndHour(moment(_d))}
                    value={val.endHour ? moment(val.endHour) : undefined}
                    size="small"
                    locale={locale}
                    format={'HH:mm'}
                    style={{
                      height: 46,
                      borderRadius: 7,
                      borderColor: val.isEmpty && !Boolean(val.endHour) ? 'red' : 'rgb(0 0 0 / 10%)',
                      backgroundColor: val.isEmpty && !Boolean(val.endHour) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="btn">
              <Button
                text="Vazgeç"
                mood="accent_outline"
                callback={() => {
                  on.handleModal()
                }}
                className="btn"
              />
              <Button text="Oluştur" mood="accent" callback={() => on.CreateCoffeeTime()} className="btn" />
            </div>
          </Lib.S.ModalContainer>
        </Modal>

        {/* Delete modal */}
        <Modal visible={val.showDeleteModal} onCancel={on.handleDeleteModalVisibility} footer={null} title="Çalışma Zamanını Sil">
          <Lib.S.DeleteModalContent>
            <p>Çalışma Zamanını silmek istediğinizden emin misiniz?</p>
            <div>
              <Button text="Vazgeç" mood="accent" callback={() => set.setShowDeleteModal(perv => !perv)} />
              <Button text="Sil" mood="danger" callback={() => on.deleteCoffeeTime(val.modalProps.id ?? 0)} />
            </div>
          </Lib.S.DeleteModalContent>
        </Modal>
      </Content>
    </PanelWrapper>
  )
}

export default CoffeeTime
