import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { FoodMenuService } from 'common/services'
import { EditFoodMenuArgs, CreateFoodMenuArgs } from 'common/services/lib/types/foodMenu'
import { useCallback, useEffect, useState } from 'react'
import swal from 'sweetalert'
import * as Lib from '.'
import moment from 'moment'

export const useEmployees = () => {
  const { setLoader } = useLoader()

  //edit states
  const modalInitialState: Lib.T.ModalPropsType = {
    kind: 'post',
    title: '',
    editProps: {
      id: 0,
      date: '',
      items: [],
    },
  }

  const [showEditModal, setShowEditModal] = useState(false)
  const [fetchAgain, setFetchAgain] = useState(false)
  const [query, setQuery] = useState({ name: '', date: '' })
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const tableColumns = ['Tarih', 'Menü', '']
  const tableSizes = ['unset', 'unset', '7%']
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [modalProps, setModalProps] = useState<Lib.T.ModalPropsType>(modalInitialState)
  const [totalData, setTotalData] = useState<number | undefined>()
  const [paginate, setPaginate] = useState({ limit: 30, page: 1 })
  const [date, setDate] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [paginateLoader, setPaginateLoader] = useState(false)
  const [plusName, setPlusName] = useState<Lib.T.FoodMenuItems[]>([{ title: '', name: '' }])

  const tableIdIndex = 4

  const handleDeleteModalVisibility = useCallback(() => {
    setShowDeleteModal(prev => !prev)
  }, [])

  const handleModal = () => {
    setModalProps(modalInitialState)
    setShowEditModal(prev => !prev)
    setIsEmpty(false)
  }

  const handleCancelModal = () => {
    setShowEditModal(false)
    setPlusName([{ title: '', name: '' }])
    setModalProps(modalInitialState)
    setDate('')
  }

  const getFoodMenuList = async () => {
    const service = new FoodMenuService()
    if (paginateLoader) {
      setLoader(false)
    } else {
      setLoader(true)
    }
    const { success, foodMenus, total } = await service.getList({
      limit: paginate.limit,
      date: query.date,
    })
    setPaginateLoader(false)
    setLoader(false)
    if (success && foodMenus) {
      setTotalData(parseInt(total! + ''))
      setTableData(prevData => [
        ...foodMenus.map(foodMenu => {
          const menu = foodMenu?.foodItems?.map((item, index) => (foodMenu?.foodItems.length - 1 === index ? item.name : item.name + ' , '))
          return [
            <div>{moment(foodMenu?.date).format('DD/MM/YYYY - dddd')}</div>,
            <div>{menu}</div>,
            <Lib.S.tableButtons>
              <Lib.C.TableButton icon="edit_outline" callback={() => getFoodMenu(foodMenu?.id)} />
              <Lib.C.TableButton
                icon="trash_bin"
                callback={() => {
                  setShowDeleteModal(true)
                  setModalProps({ ...modalInitialState, kind: 'delete', editProps: { id: foodMenu.id } })
                }}
              />
            </Lib.S.tableButtons>,
          ]
        }),
      ])
    }
  }

  const getFoodMenu = async (id: number) => {
    const service = new FoodMenuService()
    setLoader(true)
    const { success, foodMenu } = await service.getFoodMenu(id)
    setLoader(false)
    if (success && foodMenu) {
      handleModal()
      setModalProps({
        kind: 'edit',
        editProps: {
          id: foodMenu?.id,
          date: foodMenu.date,
          items: foodMenu.foodItems,
        },
      })
    }
  }

  const handleSwal = (success: boolean) => {
    if (success) {
      swal({
        className: 'rounded-swal',
        icon: 'success',
        timer: 700,
        buttons: [false],
      }).then(() => {
        setName('')
        setDate('')
        setShowEditModal(false)
      })
      setLoader(false)
      handleCancelModal()
    }
  }

  const validatePlusName = (items: Lib.T.FoodMenuItems[]): boolean => {
    // const hasEmptyFields = items.some(item => !item.title.trim() || !item.name.trim())
    const hasEmptyFields = items.some(item => !item.name.trim())

    if (hasEmptyFields) {
      swal({
        text: 'lütfen tüm alanı doldurun.',
        icon: 'error',
      })
      setIsEmpty(true)
      return false
    }

    setIsEmpty(false)
    return true
  }

  const EditFoodMenu = async () => {
    const service = new FoodMenuService()

    setLoader(true)

    if (!Boolean(date)) {
      swal({
        text: 'lütfen tüm alanı doldurun.',
        icon: 'error',
      })
      setIsEmpty(true)
      return
    }

    setLoader(true)

    if (!validatePlusName(plusName)) {
      setLoader(false)
      return
    }

    const args: EditFoodMenuArgs = {
      date,
      items: plusName,
    }

    const { success } = await service.editFoodMenu(args, modalProps.editProps.id)

    handleSwal(success)

    setFetchAgain(prev => !prev)
    setLoader(false)
  }

  const CreateFoodMenu = async () => {
    const service = new FoodMenuService()

    if (!Boolean(date)) {
      swal({
        text: 'lütfen tüm alanı doldurun.',
        icon: 'error',
      })
      setIsEmpty(true)
      return
    }

    setLoader(true)

    if (!validatePlusName(plusName)) {
      setLoader(false)
      return
    }

    const args: CreateFoodMenuArgs = {
      date,
      items: plusName,
    }

    const success = await service.createFoodMenu(args)

    handleSwal(success)

    setFetchAgain(prev => !prev)
    setLoader(false)
  }

  const handleShowMore = () => {
    if (totalData! / paginate.page <= paginate.limit) {
      return
    }
    setPaginateLoader(true)
    setPaginate(prev => {
      return { ...prev, limit: prev.limit + 30 }
    })
  }

  const handlePlusName = () => {
    if (plusName.length < 5) {
      setPlusName([...plusName, { title: '', name: '' }])
    }
  }

  const handleRemovePlusName = (index: number) => {
    if (plusName.length > 1) {
      setPlusName(plusName.filter((_, i) => i !== index))
    }
  }

  const handleItemChange = (index: number, key: string, value: string) => {
    const updatedItems = plusName.map((item, i) => (i === index ? { ...item, [key]: value } : item))
    setPlusName(updatedItems)
  }

  const deleteFoodMenu = useCallback(
    async (id: number) => {
      const service = new FoodMenuService()
      setShowDeleteModal(false)
      setLoader(true)
      try {
        const success = await service.delete(id)

        if (success) {
          swal({ className: 'rounded-swal', icon: 'success', timer: 700, buttons: [false] }).then(() => {
            setShowEditModal(false)
          })
          setFetchAgain(prev => !prev)
        }
      } catch (error) {
        console.error('Failed to Delete FoodMenu : ', error)
      } finally {
        setLoader(false)
      }
    },
    [modalProps],
  )

  useEffect(() => {
    setPlusName(modalProps?.editProps.items ?? [{ title: '', name: '' }])
    setDate(modalProps.editProps.date ?? '')
  }, [modalProps])

  useEffect(() => setTableData([]), [fetchAgain])

  useAsyncEffect(getFoodMenuList, [paginate, fetchAgain])

  return {
    val: {
      showEditModal,
      name,
      modalProps,
      modalInitialState,
      tableColumns,
      tableSizes,
      tableData,
      tableIdIndex,
      date,
      isEmpty,
      paginateLoader,
      showDeleteModal,
      plusName,
      title,
    },
    set: {
      setName,
      setModalProps,
      setQuery,
      setFetchAgain,
      setDate,
      setShowDeleteModal,
      setTitle,
    },
    on: {
      handleModal,
      EditFoodMenu,
      handleShowMore,
      CreateFoodMenu,
      handleCancelModal,
      handleDeleteModalVisibility,
      deleteFoodMenu,
      handlePlusName,
      handleRemovePlusName,
      handleItemChange,
    },
    get: {
      query,
      paginate,
      totalData,
    },
  }
}
