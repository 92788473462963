import { ModalProps } from 'antd'
import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { ProductSubCategory, SubCategoryType, OrdersService, OptionService } from 'common/services'
import { Locks } from 'common/services/lib/types'
import { SubCategory } from 'common/services/lib/types/product-sub-category'
import { ISubCategoryType } from 'common/services/lib/types/subCategoryType'
import { MonacoPieces } from 'common/services/monacoPieces'
import { useCallback, useEffect, useRef, useState } from 'react'
import * as Lib from '.'
import swal from 'sweetalert'
import { motion } from 'framer-motion'
import _ from 'lodash'
import { MonacoProduct } from 'common/services/lib/types/monacoPieces'

export const useMonacoList = () => {
  const [query, setQuery] = useState<string>('')
  const [gridView, setGridView] = useState<boolean>(true)
  const [categoryId, setCategoryId] = useState<number>(0)
  const [allSubCategory, setAllSubCategory] = useState<SubCategory[]>([])
  const [allSubCategoryType, setAllSubCategoryType] = useState<ISubCategoryType[]>([])
  const [filteredSubCategoryType, setFilteredSubcategoryType] = useState<ISubCategoryType[]>([])
  const [locks, setLocks] = useState<Locks.LockGet[]>([])
  const [typeImages, setTypeImages] = useState<{ imagePath: string; chainId: number }[]>()

  const { setLoader } = useLoader()
  const [lockId, setLockId] = useState(0)
  const [chainId, setChainId] = useState(0)

  const [monacoProductsState, setMonacoProductsState] = useState<MonacoProduct[]>()
  //add to cart modal state
  const [showAddCartModal, setShowAddCartModal] = useState(false)
  const [height, setHeight] = useState<Lib.T.PiecesHightForAddToCartModal[]>([{ heightCM: 0, heightInch: 0 }])
  const [width, setWidth] = useState([0])
  const [toCompare, setToCompare] = useState<Lib.T.toCompareType[]>([{ pieceId: 0, width: 0, height: 0, productId: 0 }])
  const [dataForCreateOrder, setDataForCreateOrder] = useState({ productId: 0, pieces: [{ id: 0, count: 0 }] })
  const [dataForCalcWeight, setDataForCalcWeight] = useState({ karat: 0, pieces: [{ id: 0, count: 0 }] })
  const [karats, setKarats] = useState<Lib.T.KaratsDropdownType[]>([{ id: 0, title: '' }])
  const [weight, setWeight] = useState(0)
  const [ayarId, setAyarId] = useState(0)

  const fetchData = async () => {
    setLoader(true)
    const productCategory = new ProductSubCategory()
    const { data } = await productCategory.getList({ productCategory: 'monaco chain', limit: 999999999 })
    if (!data || !data.length) {
      setLoader(false)
      return swal({
        text: 'first, create a product category as Monaco',
        icon: 'error',
      })
    }

    setAllSubCategory(data || [])
    setCategoryId(data[0].id)
    setLoader(false)
  }

  const fetchTypes = async (id: number) => {
    const subcategoryType = new SubCategoryType()
    setLoader(true)
    if (!id) id = categoryId

    const { types, success } = await subcategoryType.getList({ limit: 99999999, parent: id })
    if (success) {
      const typesImageExtract = types?.map(type => {
        return {
          imagePath: process.env.REACT_APP_DOMAIN! + type.image,
          chainId: type.id,
        }
      })

      await fetchLocks(types![2]?.id)

      setTypeImages(typesImageExtract)
    }
    setLoader(false)
  }

  const fetchLocks = useCallback(async (id: number) => {
    const monacoService = new MonacoPieces()
    setLoader(true)

    const { monacoProducts, success } = await monacoService.getLocksBaseOnCategoryTypeId(id)

    // @ts-ignore
    const locks: Locks.LockGet[] = monacoProducts.map(lock => lock.lock)

    if (success) {
      setMonacoProductsState(monacoProducts)
      setLocks(locks)
      setChainId(id)

      setLoader(false)
    }
    setLoader(false)
  }, [])

  const filterSubCategoryType = () => {
    setFilteredSubcategoryType(allSubCategoryType.filter(type => type.parent.id === categoryId))
  }

  const handleCountInputs = async (e: React.ChangeEvent<HTMLInputElement>, pieceId: number, productId: number) => {
    setDataForCreateOrder((perval: any) => {
      return {
        productId,
        pieces: [...perval.pieces, { id: pieceId, count: parseInt(e.target.value) }],
      }
    })

    setDataForCalcWeight((perval: any) => {
      return {
        karat: 0,
        pieces: [...perval.pieces, { id: pieceId, count: parseInt(e.target.value) }],
      }
    })
    setWeight(0)
  }

  const handleChangeInputsState = async () => {
    const ids = dataForCreateOrder.pieces?.map(o => o.id)
    const filteredPieces = dataForCreateOrder.pieces?.filter(({ id }, index) => !ids.includes(id, index + 1) && id !== 0)
    const finalData = filteredPieces.filter(item => item.count)

    setDataForCreateOrder((perval: any) => {
      return { ...perval, pieces: finalData }
    })
  }

  const handleChangeInputStateForCalcWeight = async () => {
    const ids = dataForCreateOrder.pieces?.map(o => o.id)
    const filteredPieces = dataForCreateOrder.pieces?.filter(({ id }, index) => !ids.includes(id, index + 1) && id !== 0)
    const finalData = filteredPieces.filter(item => item.count)

    setDataForCalcWeight((perval: any) => {
      return { ...perval, pieces: finalData }
    })
  }

  const handleSubmitOrder = async () => {
    const orderService = new OrdersService()

    setLoader(true)
    try {
      await orderService.addToCart(dataForCreateOrder)
    } catch (e) {}
    setLoader(false)

    const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('#inputs')!
    inputs.forEach(input => (input.value = ''))
  }

  // locks and chains ui code
  const [chainImages, setChainImages] = useState<JSX.Element[]>([])
  const [lockImages, setLockImages] = useState<JSX.Element[]>([])

  const chianImageRef = useRef<HTMLImageElement>(null)

  const selectChainImage = (index: number | undefined) => {
    if (index === undefined) {
      return
    }
    const centerIndex = chainImages.length <= 3 ? 1 : 2
    if (index === centerIndex) {
      return
    }

    if (index < centerIndex) {
      let newImages = chainImages
      const loopCount = centerIndex - index
      for (var i = 0; i < loopCount; i++) {
        newImages = _.slice(newImages, -1).concat(_.slice(newImages, 0, -1))
      }
      setChainImages(newImages)
    } else {
      const loopCount = index - centerIndex
      let newImages = chainImages
      for (var i = 0; i < loopCount; i++) {
        newImages = _.slice(newImages, 1).concat(_.slice(newImages, 0, 1))
      }
      setChainImages(newImages)
    }
  }

  const selectLockImage = (index: number | undefined) => {
    if (index === undefined) {
      return
    }
    const centerIndex = lockImages.length <= 3 ? 1 : 2
    if (index === centerIndex) {
      return
    }

    if (index < centerIndex) {
      let newImages = lockImages
      const loopCount = centerIndex - index
      for (var i = 0; i < loopCount; i++) {
        newImages = _.slice(newImages, -1).concat(_.slice(newImages, 0, -1))
      }
      setLockImages(newImages)
    } else {
      const loopCount = index - centerIndex
      let newImages = lockImages
      for (var i = 0; i < loopCount; i++) {
        newImages = _.slice(newImages, 1).concat(_.slice(newImages, 0, 1))
      }
      setLockImages(newImages)
    }
  }
  const scroll = (deltaY: number, type: 'chain' | 'lock') => {
    const data = type === 'chain' ? chainImages : lockImages

    if (deltaY > 0) {
      const newImages = _.slice(data, 1).concat(_.slice(data, 0, 1))
      if (type === 'chain') {
        setChainImages(newImages)
      } else {
        setLockImages(newImages)
      }
    } else if (deltaY < 0) {
      const newImages = _.slice(data, -1).concat(_.slice(data, 0, -1))
      if (type === 'chain') {
        setChainImages(newImages)
      } else {
        setLockImages(newImages)
      }
    }
  }

  const handleShowAddToCartModal = async () => {
    setShowAddCartModal(true)
    if (monacoProductsState) {
      const currentProduct = monacoProductsState.filter(prod => {
        return prod.productSubCategoryType.id === chainId && prod.lock.id === lockId
      })[0]

      let heights = currentProduct?.productSubCategoryType.pieces.map(item => {
        return { heightCM: item.height.size, heightInch: item.height.sizeInInches }
      })

      const heightCMs = heights?.map(o => o.heightCM)
      const filteredHights = heights?.filter(({ heightCM }, index) => !heightCMs.includes(heightCM, index + 1))

      const sortedHeights = filteredHights.sort((a, b) => a.heightCM - b.heightCM)

      const widths: number[] = currentProduct?.productSubCategoryType.pieces.map(item => {
        return item.width.width
      })

      const toCompare = currentProduct?.productSubCategoryType.pieces.map(item => {
        return {
          width: item.width.width,
          height: item.height.size,
          pieceId: item.id,
          productId: currentProduct.id,
        }
      })

      const uniqueWidths = widths
        ?.filter(function (item: any, pos: any) {
          return widths.indexOf(item) === pos
        })
        .sort((a, b) => a - b)

      setToCompare(toCompare)
      setHeight(sortedHeights)
      setWidth(uniqueWidths)

      //fetching karats for calculating weight

      const optionService = new OptionService({ type: 'karat' })

      const { success, options } = await optionService.read()
      if (success) {
        const dropDownData = options?.map(karat => ({ id: karat.id, title: karat?.karat! }))
        setKarats(dropDownData!)
      }
    }
  }

  const handleKaratDropdown = async (id: number) => {
    setAyarId(id)
    setWeight(0)
  }

  const handleCalculateWeight = async () => {
    const service = new OrdersService()

    const { success, weight } = await service.calculateWeight({ ...dataForCalcWeight, karat: +ayarId, lockId })
    if (success) {
      setWeight(weight)
    }
  }

  useEffect(filterSubCategoryType, [categoryId])
  useEffect(() => {
    if (categoryId) {
      fetchTypes(categoryId)
    }
  }, [categoryId])

  useEffect(() => {
    const chains: JSX.Element[] = []

    typeImages?.forEach((item, index) => {
      chains.push(
        <motion.img
          key={index}
          ref={chianImageRef}
          transition={{ duration: 0.7 }}
          style={{ opacity: 0.4 }}
          animate={{ opacity: 1, scale: 0.97 }}
          src={item.imagePath}
          onClick={() => {
            fetchLocks(item.chainId)
          }}
          data-chainId={item.chainId}
        />,
      )
    })
    setChainImages(chains)
    const locksImages: JSX.Element[] = []

    locks.forEach((item, index) => {
      locksImages.push(
        <motion.img
          key={index}
          transition={{ duration: 0.7 }}
          style={{ opacity: 0.4 }}
          animate={{ opacity: 1, scale: 0.97 }}
          onClick={() => setLockId(item?.id)}
          src={process.env.REACT_APP_DOMAIN! + item.image}
        />,
      )
    })

    setLockImages(locksImages)
  }, [typeImages])

  useEffect(() => {
    const locksImages: JSX.Element[] = []

    locks.forEach((item, index) => {
      locksImages.push(
        <motion.img
          key={index}
          transition={{ duration: 0.7 }}
          style={{ opacity: 0.4 }}
          animate={{ opacity: 1, scale: 0.97 }}
          src={process.env.REACT_APP_DOMAIN! + item.image}
          //set lock id on click
          onClick={() => setLockId(item.id)}
          data-lockId={item.id}
        />,
      )
    })
    //set lock id in initial render
    setLockId(locks[2]?.id)

    setLockImages(locksImages)
  }, [locks])

  useAsyncEffect(fetchData, [])

  const sendToCartModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: showAddCartModal,
    onCancel: () => setShowAddCartModal(false),
    className: 'noHeaderModal',
    afterClose: () => {
      setDataForCalcWeight({ karat: 0, pieces: [] })
      setDataForCreateOrder({ productId: 0, pieces: [] })
      setWeight(0)
      const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll('#inputs')!
      inputs.forEach(input => (input.value = ''))
    },
    width: '1246px',
  }

  useEffect(() => {
    if (karats.length) {
      const defaultAyar = karats.filter(karat => karat.title === '14')[0]?.id
      setAyarId(defaultAyar)
    }
  }, [karats])

  return {
    search: {
      query,
      setQuery,
    },
    get: {
      gridView,
      categoryId,
      allSubCategory,
      allSubCategoryType: filteredSubCategoryType,
      typeImages,
      locks,
      sendToCartModalProps,
      height,
      width,
      toCompare,
      chainImages,
      lockImages,
      karats,
      showAddCartModal,
      weight,
    },
    set: {
      setGridView,
      setCategoryId,
    },
    on: {
      fetchTypes,
      fetchLocks,
      handleCountInputs,
      handleSubmitOrder,
      selectChainImage,
      selectLockImage,
      scroll,
      handleShowAddToCartModal,
      handleChangeInputsState,
      handleKaratDropdown,
      handleChangeInputStateForCalcWeight,
      handleCalculateWeight,
    },
  }
}
