import * as Lib from './lib'
import { FC } from 'react'
import { Button } from '../button'

export const Radio: FC<Lib.T.RadioProps> = ({ callback, label, selected, className, style, noLabel }): JSX.Element => {
  return (
    <>
      <Lib.S.container onClick={() => callback(label || '')} className={className} style={style}>
        <span className={`${selected}`} style={selected ? { border: '3px #004E42 solid' } : {}}>
          <span></span>
        </span>
        {!noLabel && <p style={{paddingLeft: 12}}>{label}</p>}
      </Lib.S.container>
    </>
  )
}
