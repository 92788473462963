export const SAVE_USER = 'SAVE_USER'

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

export const TOGGLE_IMAGE_VIEW = 'TOGGLE_IMAGE_VIEW'

export const FULL_SCREEN_LOADER = 'FULL_SCREEN_LOADER'

export const SET_NEW_CREATED_PIECE = ' SET_NEW_CREATED_PIECE'

export const TOGGLE_DISABLE_SIDEBAR = 'TOGGLE_DISABLE_SIDEBAR'

export const GET_UNREAD_NOTIFICATION_SUCCESS = 'GET_UNREAD_NOTIFICATION_SUCCESS'
export const GET_UNREAD_NOTIFICATION_FAIL = 'GET_UNREAD_NOTIFICATION_FAIL'
export const MAKE_READ_NOTIFICATION = 'MAKE_READ_NOTIFICATION'

export const GET_UNSEEN_MESSAGES_SUCCESS = 'GET_UNSEEN_MESSAGES_SUCCESS'
export const GET_UNSEEN_MESSAGES_FAIL = 'GET_UNSEEN_MESSAGES_FAIL'

export const UPDATE_UNAPPROVED_PROBLEMS = 'UPDATE_UNAPPROVED_PROBLEMS'

// filters

export const UPDATE_SELLERS = 'UPDATE_SELLERS'
export const RESET_FILTER = 'RESET_FILTER'
export const RESET_CUSTOMER_FILTER = 'RESET_CUSTOMER_FILTER'
export const RESET_SELLER_FILTER = 'RESET_SELLER_FILTER'

export const UPDATE_STATUS = 'UPDATE_STATUS'
export const UPDATE_DEPARTMENTS = 'UPDATE_DEPARTMENTS'
export const UPDATE_TYPES = 'UPDATE_TYPES'
export const UPDATE_CUSTOMERS = 'UPDATE_CUSTOMERS'
export const UPDATE_AYAR = 'UPDATE_AYAR'
export const UPDATE_DATE = 'UPDATE_DATE'

export const SET_SOCKET = 'SET_SOCKET'
export const HANDLE_TOGGLE_TOAST = 'HANDLE_TOGGLE_TOAST'


export const SET_DATE = 'SET_DATE'