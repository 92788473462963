import styled from 'styled-components'
import * as Lib from '.'
import { tableInnerListHeight as listHeight } from './constants'
import { mediaQueries as mq } from 'common/constants/media-queries'

interface selectedCategory {
  selected: boolean
}

export const PageContainer = styled.div`
  .table-container {
    padding-top: 6px;
  }

  .top-row {
    width: 100%;
    display: grid;
    grid-template-columns: 0.1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr 1fr;
    align-items: end;
    gap: 5px;
    margin-bottom: 5px;

    .numune {
      height: 100%;
      display: flex;
      align-items: end;
    }

    .action-buttons {
      display: flex;
      height: 100%;
      justify-content: flex-end;
      align-items: end;
      gap: 20px;
    }
    .antDatePicker {
      padding: 0;
    }
  }
`

export const datePicker = styled.div`
  float: left;
  width: 100%;
  display: flex;

  > .title {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  > .day {
    width: 60px;
    margin: 0 5px;
  }

  > .month {
    width: 200px;
    margin: 0 5px;
  }

  > .year {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 15px;
    font-size: 14px;
  }
`

export const tableContainer = styled.div<{ trashView: boolean; editView: boolean; imageView: boolean }>`
  position: relative;
  padding-top: ${({ trashView }) => trashView === true && '55px'};
  height: 410px !important;
  max-height: 410px !important;

  .tr.body {
    transform: ${({ editView }) => (editView ? 'scale(.95)' : 'none')};
    transition: all 150ms linear;
    position: relative;

    > .td:last-child {
      padding-right: 10px;
    }

    &::after {
      ${({ editView }) => editView && "content: '';"}
      position: absolute;
      left: 0;
      right: 0;
      top: 5px;
      bottom: 0;
      background: #ffffff5c;
      border-radius: 9px;
      height: calc(100% - 10px);
      cursor: pointer;
    }

    &:hover {
      transform: none;

      &::after {
        background: transparent;
      }
    }

    > .td {
      border-bottom: 6px solid #f8f8f8;
    }
  }

  .tableParent {
    background-color: transparent;
    .tableContainer {
      .body {
        .td {
          border-right: 1px solid #959595;
          &:last-child {
            border-right: unset;
          }

          .content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100% !important;
            /* padding-top: 0px !important; */
            span {
              display: flex;
              flex-direction: row;
              padding-right: 10px;
              padding-left: 10px;
              font-size: 11px;
              span {
                height: 16px;
                font-size: 11px;
              }
              img {
                margin-right: 8px;
              }
            }
            .product-description {
              /* min-width: 270px; */
              width: 100%;
            }
            .product-price {
              /* min-width: 50px; */
              width: 100%;
            }
          }
        }
      }
      .header {
        height: 15px;
        .td {
          height: 15px !important;
          padding: 0px;
          p {
            height: 15px;
            line-height: 13px;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
          }
        }
      }
    }

    .header {
      z-index: 2;
      background-color: #f8f8f8 !important;
      color: black !important;
      font-family: var(--f-re) !important;
      font-size: 14px !important;
    }

    .tableContainer {
      > .tr.body {
        > .td:nth-child(1) {
          display: ${({ trashView }) => (trashView ? 'table-cell' : 'none')};
        }
      }
    }
  }

  .td {
    /* background-color: transparent !important; */

    > .content {
      float: left;
      position: relative;
      background-color: white !important;
      /* min-height: ${({ imageView }) => (imageView ? '122px' : 'unset')}; */
      /* align-items: center; */
      text-align: center;
      /* justify-content: center; */
      /* display: flex; */
      padding: 10px 0 !important;
    }

    &.header {
      text-align: center;
    }

    > p {
      width: 100%;
    }
  }

  /* .tr {
    > .td:not(:nth-child(2), :nth-child(1), :nth-child(${({ trashView }) => (trashView ? 8 : 7)}), :last-child) {
      > .content {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 1px;
          height: calc(100% - 30px);
          margin: auto;
          background-color: #dedede;
        }
      }
    }

    > .td:nth-child(2) {
      > .content {
        border-radius: ${({ trashView }) => (trashView ? 'none' : '10px 0 0 10px')};
      }
    }

    .content {
      width: 100%;
    }
  } */
`
interface TableProps {
  isMonaco: boolean
  minHeight: any
}
export const tableName = styled.span<TableProps>`
  min-width: 80px;
  display: flex;
  /* flex-direction: ${({ isMonaco }) => (isMonaco === true ? 'column' : 'row')}; */
  flex-direction: 'column !important';
  align-items: center;
  transition: all 150ms linear;
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  /* flex-direction: column; */
  min-height: 30px;
  height: 100%;
  gap: 10px;

  .sozer-image {
    width: 70px;
    height: 70px;
    border-radius: 8px;
  }

  .texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    > span {
      color: var(--accent);
      font-size: 11pt;
      font-family: var(--f-se-bo);
      /* margin: 10px 0 0 0; */
      text-align: center;
    }
    .subText {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #000000;
      text-align: center;
      margin-top: 15px;
    }
  }

  &:not(.true) {
    > img {
      opacity: 0;
      margin: -70px 0 0 0;
      visibility: hidden;
    }
  }
`

export const tableThreeDots = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${listHeight}px;
  width: ${listHeight}px;
  border-radius: 50%;
  height: 20px !important;
  cursor: pointer;
  transition: all 150ms linear;

  &:hover {
    background-color: var(--accent_10);
  }
`

export const tableInnerList = styled.div<Pick<Lib.T.TableInnerListProps, 'maxWidth' | 'minHeight'>>`
  float: left;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight || 20}px;
  /* overflow-y: auto; */

  > span {
    display: flex;
    float: left;
    width: 100%;
    font-size: 14.5px;
    height: 100%;
    text-align: center;
    color: var(--accent);
    font-family: var(--f-se-bo);
    /* overflow-y: auto; */
    text-overflow: ellipsis;
    /* white-space: nowrap; */

    &:nth-child(odd) {
      background: #f8f8f8;
    }

    > div {
      width: 20px;
      height: 14px;

      .visual {
        border-width: 2px;
        opacity: 1 !important;
        width: 14px;
        height: 14px;
        margin: 4px 0 0 0;
      }
    }

    > span {
      flex: 1;
      text-align: center;
    }
  }

  &.trashView {
    .directChild {
      div {
        label {
          .visual {
            height: 16px;
            width: 16px !important;
            min-width: 16px !important;
            padding: 0px !important;
            margin: 0px !important;
          }
        }
      }
    }
    > span {
      padding: 0 0 0 10px;

      > div {
        display: none;

        > label {
          > .checkbox,
          > .visual {
            opacity: 0.5;
          }
        }
      }
    }

    &.true {
      > span {
        > div {
          display: initial;
        }
      }
    }
  }
`

export const makeUpContainer = styled.div`
  width: 100%;
  > span {
    display: block;
    font-size: 10.7px !important;
    width: 100%;
    text-align: center;

    &:nth-child(odd) {
      background: #f8f8f8;
    }

    > svg {
      margin: 0px 0 5px 0px;
    }
  }
`

export const actions = styled.div<{ editView?: boolean }>`
  display: flex;

  > p {
    margin: 0;
    flex: 1;
  }

  > div {
    width: 85px;

    > span {
      float: right;

      &:nth-child(1) {
        margin: 0 0 0 27px;
      }
    }
  }

  > span,
  > div > span {
    width: 27px;
    height: 27px;
    cursor: ${props => (props.editView ? 'default' : 'pointer')};
    border-radius: 6px;
    transition: all 150ms linear;

    &:hover {
      background: ${props => (props.editView ? '' : 'var(--accent_10)')};
    }
  }
`

export const tableInnerCheckbox = styled.span<Pick<Lib.T.TableInnerCheckboxProps, 'minHeight'>>`
  min-height: ${({ minHeight }) => minHeight}px;
  min-width: 20px;
  display: flex;
  align-items: center;
  transition: all 150ms linear;
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  > div {
    margin: 9px 0 0 0;

    .visual {
      margin-left: 10px !important;
      padding: 0px !important;
      width: 16px !important;
      min-width: 16px;
    }
  }
`

export const trashViewHeader = styled.div`
  position: absolute;
  right: -1px;
  top: 0px;
  height: 79px;
  background-color: #f8f8f8;
  z-index: 3;
  width: calc(100% - -2px);
  display: flex;
  flex-direction: row-reverse;
  padding: 0 15px;

  > .buttons {
    width: 200px;
    padding: 16px 0;

    > div {
      float: right;
      width: 90px;
      margin: 0 0 0 10px;

      .parent {
        min-height: unset;
        padding: 4px 0;
      }
    }
  }

  > .toggler {
    flex: 1;
    padding: 19px 0;

    .label {
      margin: -2px 0 0 -5px;
    }

    label {
      justify-content: flex-start !important;
    }
  }
`

export const deleteModalContent = styled.div`
  float: left;
  width: 100%;
  height: auto;

  > p {
    margin: 0;
    float: left;
    width: 100%;
    font-size: 18px;
    text-align: center;
  }

  > div {
    float: left;
    width: 100%;
    text-align: center;
    margin: 30px 0 0 0;

    > div {
      width: 110px !important;
      float: none !important;
      display: inline-flex !important;
      padding: 0 2px;

      > div {
        min-height: 35px !important;
      }
    }
  }
`

export const imageModal = styled.div`
  width: 200px;
  float: left;
  width: 100%;

  > div {
    float: left;
    width: 100%;
    margin: 0 0 12px 0;

    > span {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      float: right;
      cursor: pointer;
      transition: all 150ms linear;

      &:hover {
        background-color: #00000017;
      }
    }
  }

  > img {
    float: left;
    width: 100%;
  }
`

export const formCheckbox = styled.div`
  height: 100%;
  width: 100%;
  display: flex;

  > div {
    height: 100%;
    width: 100%;
    padding-top: 32px;

    > label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      flex-direction: row-reverse;

      > .label {
        height: unset;
        width: unset;
        padding: 0 15px 0 0;
      }
    }
  }
`

export const formDatePicker = styled.div`
  float: left;
  width: 100%;
  height: auto;

  /* .orderDescription {
    width: 190px;
    display: none;
    label {
      width: 190px;
    }
  } */

  > .title {
    float: left;
    width: 100%;
    margin: 0;
    font-size: 14px;
    margin-top: 7px;
    font-weight: 400;
  }

  > .inputs {
    display: flex;
    align-items: center;
    float: left;
    width: 100%;

    > div {
      flex: 1;
      padding: 5px;
    }
  }

  ${mq.max[576]} {
    > .inputs {
      flex-direction: column;

      > div {
        width: 100%;
      }
    }
  }

  .antDatePicker {
    > div {
      width: 100%;
      height: 42px;
      border-radius: 5px;
      border: 1px solid rgb(0 0 0 / 10%) !important;
      margin: 2px 0 0 0;
    }
  }
`

export const enamelsModal = styled.div`
  > div.header {
    width: 100%;
    height: auto;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > span {
      display: flex;
      width: 28px;
      height: 28px;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: #efefef;
      }
    }
  }

  > p {
    text-align: center;
    display: block;
    width: 100%;
    font-size: 13pt;
    margin: 0 0 25px 0;
    padding: 0 0 8px 0;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: 100px;
      height: 1px;
      background-color: black;
      margin: auto;
    }
  }

  > div.body {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      flex: 1;
      max-width: calc(100% / 3);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      > span {
        width: 40px;
        height: 40px;
        display: block;
        border-radius: 7px;
      }

      > p {
        margin: 8px 0 0 0;
      }
    }
  }
`
export const FooterPage = styled.div`
  .page-footer {
    position: fixed;
    bottom: 0;
    left: 146px;
    height: 30px;
    padding-top: 5px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);
    z-index: 1000;

    .footer-container {
      width: calc(100% - 200px);
      display: flex;
      align-items: center;
      height: 75%;
      justify-content: space-around;
    }

    .footer-info {
      font-size: 17px;
      font-weight: 500;
      color: #000000;
    }

    .footer-value {
      color: var(--accent);
      font-weight: bold;
    }
  }
`

export const CategoryTableWrapper = styled.div`
  margin-top: 30px;
  .hidden {
    display: none;
  }
  .show {
    display: table-row;
  }

  > .category-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
    border: 0;
    padding-bottom: 100px;

    thead {
      tr {
        th {
          border-bottom: 1px solid #000;
          padding-bottom: 10px;
        }
      }
    }

    > tbody {
      > tr td:first-child {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
      tr td:last-child {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }

      tr {
        td:first-child {
          padding-left: 30px;
        }
        td {
          padding: 10px 0;
          color: var(--accent);
          font-weight: bold;
        }

        background-color: #f8f8f8;
        .image {
          div {
            width: 65px;
            height: 50px;
            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
  .page-footer {
    position: fixed;
    bottom: 0;
    left: 200px;
    height: 60px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);
    z-index: 1000;

    .footer-container {
      width: calc(100% - 200px);
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: space-around;
    }

    .footer-info {
      font-size: 17px;
      font-weight: 500;
      color: #000000;
    }

    .footer-value {
      color: var(--accent);
      font-weight: bold;
    }
  }
`
export const InfoDiv = styled.div`
  height: 180px;
  width: 100%;
  position: relative;
  display: none;
  overflow: hidden;

  .header {
    overflow: hidden;
    position: absolute;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 50px;
    display: flex;
    background-color: #f8f8f8;
    justify-content: center;
    padding: 20px;
    span {
      font-size: 22px;
    }
    div {
      position: absolute;
      overflow: hidden;
      left: 20px;
      margin-left: 10px;
    }
  }

  .content {
    overflow: hidden;
    position: absolute;
    background-color: #f8f8f8;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    top: 50px;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px;

    table {
      overflow: hidden;
      width: 100%;
      border: 0;

      .piece-image {
        display: flex;
        flex-direction: column;
        width: 100px;
        height: 100px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .piece-name {
        display: flex;
        flex-direction: column;
      }

      thead {
        tr {
          th {
            border-bottom: 1px solid #000;
            padding-bottom: 10px;
            text-align: center;
          }
        }
      }

      tbody {
        tr td:last-child {
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
        }

        tr {
          .cell {
            display: flex;
            flex-direction: column;
            img {
              width: 60px;
              height: 60px;
            }
          }

          td:first-child {
            padding-right: 30px;
          }
          td {
            padding: 20px 0;
            color: var(--accent);
            font-weight: bold;
            border-bottom: 0.3px solid rgba(0, 0, 0, 0.4);
            text-align: center;
          }

          background-color: #f8f8f8;
          .image {
            img {
              height: 60px;
              width: 60px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    margin-top: 20px;
    height: 70px;
    justify-content: center;
    .text {
      margin-right: 30px;
      font-size: 20px;
      span {
        font-weight: bold;
      }
    }
    .price {
      font-size: 20px;
      color: var(--accent);
      font-weight: bold;
    }
  }
`

export const dropdownContainer = styled.div`
  width: 100%;
  height: 100%;
  .ant-select {
    height: 42px;
    .ant-select-selector {
      height: 42px !important;
    }
  }

  .customer {
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      color: red !important;
      background-color: #fff !important;
    }
  }
`
export const inputContainer = styled.div`
  .description {
    padding: 2px;
    height: 41px;
    min-height: auto;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    outline: none;

    :focus {
      border: 1px solid #d9d9d9;
    }
  }
  .label {
    padding: 6px 0 !important;
  }

  div {
    input {
      height: 42px;
    }
  }
  .orderDescription {
    width: 100%;

    label {
      width: 100%;
      span {
        padding: 0px !important;
        margin-bottom: 7px;
      }
    }
  }
`

export const buttonContainer = styled.div`
  height: 42px;
  width: 42px;
  div {
    .parent {
      height: 42px;
      width: 42px;
      font-size: 14px;
      min-height: 42px;
    }
    label {
      input {
      }
    }
  }
  .submitButton {
    .parent {
      width: 120px;
      height: 36px;
    }
  }
`

export const extraDetailsFilterWrapper = styled.div`
  border-radius: 12px;
  background: #f1f1f1;
  width: 100%;
  height: 68px;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  border: 1px solid #004e42;
  padding-left: 10px;
  .sectionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    &:nth-last-child(2) {
      margin-right: 13px;
      /* width: 100px !important; */
      min-width: 100px !important;
    }
    &:nth-last-child(3) {
      margin-right: 33px;
    }
    &:nth-last-child(4) {
      margin-right: 13px;
    }
    .inputsWrapper {
      display: flex;
      .inputContainer {
        input {
          text-align: center;
        }
        height: 48px;
        margin-right: 4px;
        .inputHeader {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #4d4d4d;
          text-align: center;
        }
      }
      .firstInputContainer {
        div {
          label {
            input {
              width: 36px;
              height: 28px;
              padding: 2px;
              font-size: 12px;
            }
          }
        }
      }
      .secondInputContainer {
        width: 60px;
        div {
          span {
            padding: 0px;
          }
          .ant-select {
            width: 60px;
            height: 28px;
            .ant-select-selector {
              width: 60px !important;
              height: 28px !important;
              min-height: 28px !important;
              min-width: 36px !important;
              .ant-select-selection-item {
                font-size: 12px;
              }
              .ant-select-selection-search {
                .ant-select-selection-search-input {
                  height: 26px !important;
                  min-height: 26px;
                  font-size: 12px;
                }
              }
            }
            .ant-select-arrow {
              right: 5px;
              top: 15px;
            }
          }
        }
      }
    }
    .sectionTitle {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: #4d4d4d;
      margin-top: 23px;
      margin-right: 3px;
      width: 100%;
    }
  }
  .SingleInputSection {
    width: 7%;
  }
`

export const categoryFilterWrapper = styled.div<selectedCategory>`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 8px;
  border-radius: 12px;
  width: ${({ selected }) => (selected === false ? '120px' : '234px')};
  .categoryContainer {
    border-radius: 12px;
    height: 100%;
    width: 104px !important;
    min-width: 104px;
    background: #f1f1f1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    .category {
      cursor: pointer;
      width: 88px;
      height: 115px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      padding: 2px;
      .categoryTitle {
        color: #004e42;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        line-height: 14px;
        margin-top: 4px;
      }
      .imgContainer {
        width: 80px;
        height: 80px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
    .selectedCategory {
      border: 2px solid #004e42;
      padding: 0px;
    }
  }
  .subCategoryContainer {
    border-radius: 12px;
    height: 100%;
    width: 104px !important;
    min-width: 104px;
    background: #f1f1f1;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    padding-top: 10px;
    min-height: 120px;
    .selectedSubCategory {
      border: 2px solid #004e42;
    }
    .subCategory {
      cursor: pointer;
      width: 88px;
      height: 115px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      margin-bottom: 3px;
      /* margin-bottom: 15px; */
      .subCategoryTitle {
        color: #004e42;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        line-height: 14px;
        margin-top: 4px;
      }
      .imgContainer {
        width: 80px;
        height: 80px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
`

export const modal = styled.div<{ priceCondition: boolean; isMonaco: boolean }>`
  float: left;
  width: 100%;
  height: auto;
  min-width: 912px;

  .ant-select-selector {
    max-height: 45px;
    overflow: hidden;
  }

  > .header {
    float: left;
    width: 100%;
    padding: 0 1%;
    display: ${({ isMonaco }) => isMonaco === true && 'flex'};
    justify-content: ${({ isMonaco }) => isMonaco === true && 'space-between'};
    > div {
      float: left;
      /* width: calc(100% / ${({ priceCondition: pc }) => (pc ? 3 : 2)}); */
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      border-bottom: 1px solid #b7b7b759;

      > p {
        float: left;
        height: 100%;
        margin: 0;
        text-align: left;

        &.key {
          color: black;
          margin: 0 10px 0 0;
        }

        &.value {
          color: var(--accent);
          font-family: var(--f-se-bo);
        }
      }
    }
    .buttons {
      border-bottom: none;
      padding-top: 0px;
      width: 40%;
      display: flex;
      justify-content: flex-end;
      position: relative;
      top: -10px;
      > div {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        float: left;
        justify-content: flex-end;
        padding: 0 3px;

        &:nth-child(2) {
          justify-content: flex-end;
        }

        > div {
          width: unset;
          height: unset;

          > div {
            padding: 0 30px;
          }
        }
      }
    }
    .header-detail {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: none;
      > div {
        float: left;
        /* width: calc(100% / ${({ priceCondition: pc }) => (pc ? 3 : 2)}); */
        width: 20%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        border-bottom: 1px solid #b7b7b759;

        > p {
          float: left;
          height: 100%;
          margin: 0;
          text-align: left;

          &.key {
            color: black;
            margin: 0 10px 0 0;
          }

          &.value {
            color: var(--accent);
            font-family: var(--f-se-bo);
          }
        }
      }
    }
  }
  .image {
    display: flex;
    padding-left: 10px;
    cursor: pointer;
    .monaco-stone-name {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
    }
    justify-content: start;
    align-items: center;
    & > img {
      width: 45px;
      height: 45px;
      border-radius: 5px;
    }
    & > span {
      margin-left: 10px;
    }
  }

  .tableContainer {
    float: left;
    width: 100%;
    /* margin-top: 20px; */
    .table {
      thead {
        tr {
          td {
            color: #4d4d4d;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
      tbody {
        .monaco-firstRow {
          .monaco-size {
            display: flex;
            justify-content: center;
            .size-in-inches {
              width: 45px;
              height: 45px;
              margin-right: 10px;
              label {
                width: 45px;
                height: 45px;
                input {
                  padding: 5px;
                }
              }
            }
            .size-in-cm {
              width: 45px;
              height: 45px;
              label {
                width: 45px;
                height: 45px;
                input {
                  padding: 5px;
                }
              }
            }
          }
          .monaco-color {
            div {
              width: 100%;
              height: 45px;
              display: flex;
              justify-content: center;
              /* .ant-select {
                width: 45px !important;
                min-width: 45px;
                .ant-select-selector {
                  width: 45px !important;
                  min-width: 45px;
                } */
            }
            span {
              padding: 0px;
            }
          }
        }
        .monaco-count {
          div {
            display: flex;
            justify-content: center;
          }
          label {
            width: 45px;
            height: 45px;
            input {
              padding: 5px;
            }
          }
        }
        .monaco-price {
          position: relative;

          label {
            width: 73px !important;
            height: 45px;
            min-width: 73px;
            input {
              width: 73px !important;
              min-width: 73px;
              padding: 5px;
            }
          }
        }
        .monaco-width {
          div {
            display: flex;
            justify-content: center;
          }
          label {
            width: 45px;
            height: 45px;
            input {
              padding: 5px;
            }
          }
        }
        .monaco-master {
          padding-left: 15px;
        }
      }
    }
    .monaco-row {
      .monaco-size {
        display: flex;
        justify-content: center;
        .size-in-inches {
          width: 45px;
          height: 45px;
          margin-right: 10px;
          label {
            width: 45px;
            height: 45px;
            input {
              padding: 5px;
            }
          }
        }
        .size-in-cm {
          width: 45px;
          height: 45px;
          label {
            width: 45px;
            height: 45px;
            input {
              padding: 5px;
            }
          }
        }
      }
      .monaco-color {
        div {
          width: 100%;
          height: 45px;
          display: flex;
          justify-content: center;
          /* .ant-select {
                width: 45px !important;
                min-width: 45px;
                .ant-select-selector {
                  width: 45px !important;
                  min-width: 45px;
                } */
        }
        span {
          padding: 0px;
        }
      }

      .monaco-count {
        div {
          display: flex;
          justify-content: center;
        }
        label {
          width: 45px;
          height: 45px;
          input {
            padding: 5px;
          }
        }
      }
      .monaco-price {
        div {
          display: flex;
          justify-content: center;
        }
        label {
          width: 73px !important;
          height: 45px;
          min-width: 73px;
          input {
            width: 73px !important;
            min-width: 73px;
            padding: 5px;
          }
        }
      }
      .monaco-width {
        div {
          display: flex;
          justify-content: center;
        }
        label {
          width: 45px !important;
          height: 45px;
          min-width: 45px;
          input {
            padding: 5px;
          }
        }
      }
    }
  }

  td > .DD {
    > div {
      /* max-width: 100px; */
    }

    > span {
      padding: 0;
    }

    .dropdownContainer {
      height: 45px;
      border: 1px solid rgba(0, 78, 66, 0.32) !important;
    }

    .icon {
      width: 25px;
    }

    .optionsContainer {
      > span {
        padding: 0 5px;
        font-size: 14px;
      }
    }
  }

  .price {
    height: 45px;
    text-align: center;
    border: 1px solid rgba(0, 78, 66, 0.32) !important;
    border-radius: 5px;
    background: rgba(0, 78, 66, 0.01);
  }

  .explain {
    background-color: #f8f8f8;
    text-align: center;
    border: none;
    min-height: 45px;
    width: 100%;
    padding: 7px;
    height: 45px;
  }

  td {
    text-align: center;
  }

  tbody {
    tr:nth-child(1) {
      td {
        padding-top: 7px;
        padding-bottom: 7px;
        background-color: #efefef;
      }
    }

    td {
      border: none;
      padding: 3px 4px;
    }
    .price {
      background-color: #fff;
    }
    tr {
      > td:nth-child(1) {
        > span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 45px;
          border: 1px solid rgba(0, 78, 66, 0.32) !important;
          border-radius: 7px;
        }
      }
    }
  }

  .footer {
    float: left;
    width: 100%;
    height: auto;
    padding-top: 10px;

    > div {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      float: left;
      padding: 0 7px;

      &:nth-child(2) {
        justify-content: flex-start;
      }

      > div {
        width: unset;
        height: unset;

        > div {
          padding: 0 30px;
        }
      }
    }
  }
`

export const detailNumbers = styled.div`
  float: left;
  height: 45px;

  > div {
    float: left;
    width: 45px;
    height: 45px;
    margin: 0 3px;

    &:not(.withInput) {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f8f8f8;
      color: black;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;
      transition: all 150ms linear;

      &.true {
        background-color: var(--accent);
        color: white;
      }
    }

    &.withInput {
      > input {
        float: left;
        width: 100%;
        height: 100%;
        font-size: 16px;
        margin: 0;
        padding: 0;
        text-align: center;
        border: 1px solid rgba(0, 78, 66, 0.32);
        border-radius: 5px;
        /* background: rgba(0, 78, 66, 0.01); */
      }
    }
  }

  input.noArrow {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`

export const tableWrapper = styled.div<Pick<Lib.T.TableProps, 'rowBackColor' | 'rowBorder'>>`
  float: left;
  width: 100%;
  background-color: white;
  max-width: 100%;
  border-radius: 15px;

  > .tableContainer {
    display: table;
    width: 100%;

    .tr {
      display: table-row;

      &.body {
        position: relative;
        &.disabled {
          color: #afafaf;
        }

        > .td:first-child > .content {
          border-radius: 5px 0 0 5px;
        }
        > .td:last-child > .content {
          border-radius: 0 5px 5px 0;
        }
        > .td:nth-last-child(3) > .content {
          /* margin-left: 30px; */
        }

        &.filterContainer {
          height: 75px;
        }
      }
    }

    .td {
      display: table-cell;
      padding: 0;
      border-bottom: ${({ rowBorder }) => (rowBorder ? '1px solid #004E421A' : 'unset')};
      background-color: white;
      vertical-align: middle;

      &.header {
        color: black;
        border-bottom: 2px solid #004e424d;
        position: sticky;
        top: 0px;
        padding: 10px;
        font-family: var(--f-bo);
        font-size: 16px;
        background-color: white;
        z-index: 1;

        &.true {
          cursor: pointer;
        }

        > span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px 0 0;
        }

        > p {
          margin: 0;
          display: inline-block;
          font-size: 11pt;
        }
      }

      &.footer {
        background-color: white;
        color: var(--accent);
        border-top: 2px solid #004e424d;
        position: sticky;
        padding: 10px 15px 20px 15px;
        bottom: 0px;
        font-family: var(--f-bo);
        font-size: 16px;
      }

      > .content {
        background-color: ${({ rowBackColor }) => (rowBackColor ? '#F8F8F8' : 'transparent')};
        padding: 10px;

        ${mq.max[992]} {
          padding: 8px;
        }

        &.true {
          padding: 0;
        }

        .tableButton {
          border: none;
          padding: 5px 15px;
          border-radius: 10px;
          background-color: transparent;
          transition: all 150ms linear;
          display: inline-block;

          &:hover {
            background-color: #eaeaea;
          }
        }
      }
    }

    .filter {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        width: unset;

        > .parent {
          padding: 10px 30px;
          min-height: unset;
        }
      }
    }
  }
  .backgroundWhite {
    .content {
      background-color: #fff !important;
    }
  }
`

export const contentWrapper = styled.div<{ height?: number }>`
  /* height: ${({ height }) => height + 'px !important'};
  min-height: ${({ height }) => height + 'px !important'}; */
`
export const MonacoChainAndLockPicture = styled.div`
  width: 60px;
  position: relative;
  img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }

  .chain {
    width: 30px;
    height: 30px;
  }

  .lock {
    height: 30px;
    width: 30px;
    position: absolute;
    right: 0px;
    top: 0px;

    img {
      transform: scale(1.2);
    }
  }
`

export const TableWrapper = styled.div`
  display: flex;

  .modifier-table {
    width: 100%;
  }
`
