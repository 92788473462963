import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import swal from 'sweetalert'
import { DOM } from 'common/helpers/dom'

export class AutomationDrinkType {
  accessToken: string
  endpoint = 'automation/restaurant/drink/drink-type'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      AutomationDrinkType.dontAccept()
    }
    if (!user!.accessToken) {
      AutomationDrinkType.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async getDrinkTypes(filters?: Lib.T.DrinkTypes.GetListFilters): Promise<Lib.T.DrinkTypes.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || !data.drinkTypes) {
      swal({
        text: 'Grup listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true, drinkTypes: data.drinkTypes, total: data.total }
  }

  async getDrinkType(id: number): Promise<Lib.T.DrinkTypes.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id ? `/${id}` : ''),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || !data) {
      swal({
        text: 'Grup listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true, drinkType: data }
  }

  async updateDrinkType(args: Lib.T.DrinkTypes.drinkTypeArgs, id: number): Promise<Lib.T.NewsAndAnnouncements.ListResult> {
    const response = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id ? `/${id}` : ''),
      headers: { authorization: this.accessToken },
      data: args,
    })
    const { data, status } = response
    if (id) {
      if (status !== 200) {
        AutomationDrinkType.unhandled(Array.isArray(data.message) ? data.message[0] : data.message)

        return { success: false }
      }
    } else {
      if (status !== 201) {
        AutomationDrinkType.unhandled(Array.isArray(data.message) ? data.message[0] : data.message)

        return { success: false }
      }
    }

    return {
      success: true,
    }
  }

  async deleteDrinkType(id: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
    })
    return status === 200
  }

  async createDrinkType(args: Lib.T.DrinkTypes.drinkTypeArgs): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    })
    return status === 201
  }

  static dontAccept() {
    throw 'not-acceptable'
  }

  static unhandled(errorMessage?: string) {
    if (errorMessage) {
      return swal({
        text: errorMessage,
        dangerMode: true,
        icon: 'error',
      })
    }
  }
}
