import styled from 'styled-components'
import { mediaQueries as mq } from 'common/constants/media-queries'
import * as Lib from '.'

const SQUARE_SIZE = 105
const SQUARE_SIZE_CIC = 50

export const container = styled.div<Pick<Lib.T.UploaderProps, 'view' | 'fileIcon' | 'closeIsCovered' | 'size'> & { isOver: boolean }>`
  float: left;
  display: flex;
  border: ${({ isOver }) => (isOver ? '1px solid black' : 'none')};
  > .adderCustomerImage {
    stroke: aliceblue;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
      width: 72px;
      height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  > .adder {
    height: ${({ view, closeIsCovered: CIC, size }) => (view === 'row' ? `${CIC ? SQUARE_SIZE_CIC : size || SQUARE_SIZE}px` : '50px')};
    float: left;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: ${({ view }) => (view === 'row' ? `unset` : 'flex-start')};

    > span {
      background-color: var(--accent_10);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > p {
      margin: 0;
      padding: 0 0 0 10px;
      color: #4d4d4d;
      font-family: var(--f-se-bo);
    }
  }

  > div {
    ${({ closeIsCovered: CIC }) =>
      CIC &&
      `
      float: left;
    `}

    ${({ view }) =>
      view === 'col' &&
      `
      float: left;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    `}

    > .dropper {
      width: ${({ closeIsCovered: CIC, size }) => (CIC ? SQUARE_SIZE_CIC : size || SQUARE_SIZE)}px;
      height: ${({ closeIsCovered: CIC, size }) => (CIC ? SQUARE_SIZE_CIC : size || SQUARE_SIZE)}px;
      float: left;
      padding: 5px;

      > div {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 6px;
        border: 3px dashed #a8a8a8;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    > .preview {
      width: ${({ closeIsCovered: CIC, size }) => (CIC ? SQUARE_SIZE_CIC : size || SQUARE_SIZE)}px;
      height: ${({ closeIsCovered: CIC, size }) => (CIC ? SQUARE_SIZE_CIC : size || SQUARE_SIZE)}px;
      float: left;
      padding: ${({ closeIsCovered: CIC }) => (CIC ? 2 : 5)}px;

      > div {
        width: 100%;
        height: 100%;
        background-color: #c4c4c4;
        border-radius: 6px;
        position: relative;
        background-position: center;
        background-size: cover;

        > .close {
          position: absolute;
          right: 0;
          top: 0;
          width: ${({ closeIsCovered: CIC }) => (CIC ? '100%' : '30px')};
          height: ${({ closeIsCovered: CIC }) => (CIC ? '100%' : '30px')};
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background: ${({ closeIsCovered: CIC }) => (CIC ? '#00000078' : 'transparent')};
          opacity: ${({ closeIsCovered: CIC }) => (CIC ? 0 : 1)};
          border-radius: 6px;
          transition: all 150ms linear;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  ${mq.max[576]} {
    display: block;

    > .adder {
      float: left;
      width: 100%;
      height: 40px;
    }
  }
`
