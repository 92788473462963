import styled from 'styled-components'
import * as Lib from './'

export const DropdownContainer = styled.div<Pick<Lib.T.DropDownProps, 'lessBorder' | 'compactView' | 'height'> & { hasOptions: boolean }>`
  width: 100%;
  height: auto;
  float: left;

  .ant-select-selector {
    overflow-y: ${({ hasOptions }) => (hasOptions ? 'scroll' : 'unset')};
  }

  > span {
    flex: 1;
    width: 100%;
    padding: 7px 0;
    font-size: 14px;
    display: block;
  }

  > div {
    flex: 1;
    width: 100%;

    > div {
      height: ${({ height }) => (height ? height : '45px')} !important;
      min-height: 37.8px;
      input {
        height: ${({ height }) => (height ? height : '45px')} !important;
        min-height: 37.8px;
      }

      .ant-select-selection-item {
        font-size: 11pt;
      }
    }

    .ant-select-selector {
      border-radius: 7px !important;
      border-color: rgb(0 0 0 / 10%) !important;
      border-width: ${({ lessBorder }) => (lessBorder ? '1px' : '3px')} !important;
      border-style: solid !important;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
  }
`
