import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import * as types from 'common/constants/action-types'

export const updateSellers = (payload: CheckboxValueType[]) => {
  return {
    type: types.UPDATE_SELLERS,
    payload,
  }
}

export const updateOrderStatus = (payload: CheckboxValueType[]) => {
  return {
    type: types.UPDATE_STATUS,
    payload,
  }
}

export const updateDepartments = (payload: CheckboxValueType[]) => {
  return {
    type: types.UPDATE_DEPARTMENTS,
    payload,
  }
}
export const updateTypes = (payload: string) => {
  return {
    type: types.UPDATE_TYPES,
    payload,
  }
}
export const updateAyar = (payload: CheckboxValueType[]) => {
  return {
    type: types.UPDATE_AYAR,
    payload,
  }
}

export const updateDate = (payload: [string, string]) => {
  return {
    type: types.UPDATE_DATE,
    payload,
  }
}

export const updateCustomers = (payload: CheckboxValueType[]) => {
  return {
    type: types.UPDATE_CUSTOMERS,
    payload,
  }
}

export const resetFilter = () => {
  return {
    type: types.RESET_FILTER,
  }
}

export const resetCustomerFilter = (payload: boolean) => {
  return {
    type: types.RESET_CUSTOMER_FILTER,
    payload,
  }
}

export const resetSellerFilter = (payload: boolean) => {
  return {
    type: types.RESET_SELLER_FILTER,
    payload,
  }
}
