import { useLoader } from 'common/hooks/use-loader'
import { MonacoProductService } from 'common/services/monacoProducts'
import { OrdersService } from 'common/services/orders'
import domToPdf from 'dom-to-pdf'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Lib from '.'
import _ from 'lodash'

export const useReport = () => {
  // utils
  const { setLoader } = useLoader()
  const param: { id: string } = useParams()
  const id = parseInt(param.id)

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const type = params.get('original');

  const productCode = useRef<string | null>(null)
  //pdf
  const element = document.getElementById('table')
  const options = {
    filename: productCode.current,
  }

  const pdfGenerator = () => {
    setLoader(true)
    domToPdf(element, options, function (pdf: any) {
      onStartUp(true)
      setLoader(false)
    })
  }

  //   table States
  const headerData = useRef<Lib.T.HeaderData | null>(null)
  // sozer table
  const [sozerTables, setSozerTables] = useState<Lib.T.SozerTableType[]>([])
  const sozerTableHeaders = useMemo(() => {
    return ['Type', 'Quantity', 'Color', 'Length', 'Price', 'Description']
  }, [])
  // monaco table
  const [monacoTables, setMonacoTables] = useState<Lib.T.ReportTableTypes[]>([])

  const onStartUp = async (doNotShowLoader?: boolean) => {
    setLoader(true)
    if (type === 'true') {
      const originalSingleOrder = fetchOriginalSingleOrder(doNotShowLoader)
      await originalSingleOrder
    } else {
      const singleOrder = fetchSingleOrder(doNotShowLoader)
      await singleOrder
    }
  }

  const fetchSingleOrder = async (doNotShowLoader?: boolean) => {
    const orderService = new OrdersService()

    try {
      if (!doNotShowLoader) {
        setLoader(true)
      } else {
        setMonacoTables([])
        setSozerTables([])
      }

      const { success, order } = await orderService.findOnePDF(id)

      if (success && !_.isEmpty(order)) {
        // name of the file
        productCode.current = `No.${order.id}-${order?.customer?.username || 'unknown'}.pdf`
        // header data

        headerData.current = {
          customer: order.customer?.company,
          karat: order?.karat.karat ? order?.karat.karat.trim() + 'K' : '-',
          weight: order.weight,
          orderId: order.id,
          description: order?.customer?.id ? order?.description ?? '-' : null,
          orderDate: moment(order?.createdAt).format('L'),
          orderStatus: order.status,
          deliveryDate: moment(order?.deliveryDate).format('L'),
          patent: order?.patent?.patent ? order?.patent?.patent : '-',
          customerId: order?.customer?.id,
          totalWeight: order.weight,
        }

        //sozer table data
        const dataForSozerTable: Lib.T.SozerTableType[] = order.products
          .filter(prod => prod.category !== 'monaco')
          .map(prod => {
            return { productId: prod.id, image: prod.productImage, productCode: prod.productCode, pieces: prod.pieces, weight: prod.weight }
          })
          .sort((a, b) => a.productCode.localeCompare(b.productCode))

        setSozerTables(dataForSozerTable)

        // monaco table data
        const products = order?.products.sort((a, b) => a.productCode.localeCompare(b.productCode))

        for (const [index, prod] of products.entries()) {

          if (prod.category !== 'monaco') {
            setLoader(false);
            continue;
          }

          //const { data } = await monacoService.getSingleMonacoProduct(prod.id, false);

        // if (_.isEmpty(prod)) {
        //     setLoader(false)
        //     return
        //   }

          let heights = prod.productSubCategoryType.pieces.map((item:any) => {
            return { heightCM: item.height_id.size, heightInch: item.height_id.sizeInInches }
          })

          const heightCMs = heights?.map(o => o.heightCM)
          const filteredHights = heights?.filter(({ heightCM }, index) => !heightCMs.includes(heightCM, index + 1))

          const sortedHeights = filteredHights.sort((a, b) => a.heightCM - b.heightCM)

          const widths: number[] = prod.productSubCategoryType.pieces.map((item:any) => {
            return item.width_id.width
          })

          const uniqueWidths = widths
            ?.filter(function (item: any, pos: any) {
              return widths.indexOf(item) === pos
            })
            .sort((a, b) => a - b)

          const toCompare = prod?.productSubCategoryType.pieces.map((item:any) => {
            return {
              width: item.width_id.width,
              height: item.height_id.size,
              pieceId: item.id,
              productId: prod.id,
            }
          })
          if (index === 0) {
            setLoader(true)
          }
          //const { data: monacoData } = await orderService.fetchMonacoEdit(id, prod.id)

          const monacoPieces = prod.pieces?.map((m, _, perv) => {
            const title = m.madeColor.madeTitle

            return {
              color: title,
              alreadyAddedPieces: perv.filter(item => item.madeColor.madeTitle === title),
              height: sortedHeights,
              width: uniqueWidths,
              toCompare,
              nameLockChain: { name: prod.productCode, lockImage: prod.lock.image, chainImage: prod.productSubCategoryType.image },
              customer: order.customer?.username,
              karat: order.karat?.karat,
              totalWeight: prod.weight,
            }
          })

          const uniqueMonacoPieces = [...new Map(monacoPieces?.map(v => [v.color, v])).values()]

          // if (!monacoData?.length) {
          //   setLoader(false)
          //   return
          // }

          setMonacoTables(perv => [...perv, ...uniqueMonacoPieces])
          if (index === products.length - 1) {
            setLoader(false)
          }
        }
        setLoader(false)
      }
    } catch (e) {
      setLoader(false)
    }
  }

  const fetchOriginalSingleOrder = async (doNotShowLoader?: boolean) => {
    const orderService = new OrdersService()

    try {
      if (!doNotShowLoader) {
        setLoader(true)
      } else {
        setMonacoTables([])
        setSozerTables([])
      }

      const { success, order } = await orderService.originalOrderPDF(id)

      if (success && !_.isEmpty(order)) {
        // name of the file
        productCode.current = `No.${order.id}-${order?.customer?.username || 'unknown'}.pdf`
        // header data

        headerData.current = {
          customer: order.customer?.company,
          karat: order?.karat.karat ? order?.karat.karat.trim() + 'K' : '-',
          weight: order.weight,
          orderId: order.id,
          description: order?.customer?.id ? order?.description ?? '-' : null,
          orderDate: moment(order?.createdAt).format('L'),
          orderStatus: order.status,
          deliveryDate: moment(order?.deliveryDate).format('L'),
          patent: order?.patent?.patent ? order?.patent?.patent : '-',
          customerId: order?.customer?.id,
          totalWeight: order.weight,
        }

        //sozer table data
        const dataForSozerTable: Lib.T.SozerTableType[] = order.orderToProducts
          .filter(prod => prod.product.category !== 'monaco')
          .map(prod => {
            return { productId: prod.product.id, image: prod.product.productImage?.imageThumbnail, productCode: prod.product.productCode, pieces: prod.product.pieces, weight: prod.product.weight }
          })
          .sort((a, b) => a.productCode.localeCompare(b.productCode))

        setSozerTables(dataForSozerTable)

        // monaco table data
        const products = order?.orderToProducts.sort((a, b) => a.product.productCode.localeCompare(b.product.productCode))

        for (const [index, prod] of products.entries()) {

          if (prod.product.category !== 'monaco') {
            setLoader(false);
            continue;
          }

          //const { data } = await monacoService.getSingleMonacoProduct(prod.id, false);

          // if (_.isEmpty(prod)) {
          //     setLoader(false)
          //     return
          //   }

          let heights = prod.product.pieces.map((item:any) => {
            return { heightCM: item.height_id.size, heightInch: item.height_id.sizeInInches }
          })

          const heightCMs = heights?.map(o => o.heightCM)
          const filteredHights = heights?.filter(({ heightCM }, index) => !heightCMs.includes(heightCM, index + 1))

          const sortedHeights = filteredHights.sort((a, b) => a.heightCM - b.heightCM)

          const widths: number[] = prod.product.pieces.map((item:any) => {
            return item.width_id.width
          })

          const uniqueWidths = widths
            ?.filter(function (item: any, pos: any) {
              return widths.indexOf(item) === pos
            })
            .sort((a, b) => a - b)

          const toCompare = prod?.product.pieces.map((item:any) => {
            return {
              width: item.width_id.width,
              height: item.height_id.size,
              pieceId: item.id,
              productId: prod.product.id,
            }
          })
          if (index === 0) {
            setLoader(true)
          }
          //const { data: monacoData } = await orderService.fetchMonacoEdit(id, prod.id)

          const monacoPieces = prod.product.pieces?.map((m, _, perv) => {
            const title = m.madeColor.madeTitle
            return {
              color: title,
              alreadyAddedPieces: perv.filter(item => item.madeColor.madeTitle === title),
              height: sortedHeights,
              width: uniqueWidths,
              toCompare,
              nameLockChain: { name: prod.product.productCode, lockImage: prod.product.lock.image.imageThumbnail, chainImage: prod.product.productSubCategoryType.image.imageThumbnail },
              customer: order.customer?.username,
              karat: order.karat?.karat,
              totalWeight: prod.product.weight,
            }
          })

          const uniqueMonacoPieces = [...new Map(monacoPieces?.map(v => [v.color, v])).values()]

          // if (!monacoData?.length) {
          //   setLoader(false)
          //   return
          // }

          setMonacoTables(perv => [...perv, ...uniqueMonacoPieces])
          if (index === products.length - 1) {
            setLoader(false)
          }
        }
        setLoader(false)
      }
    } catch (e) {
      setLoader(false)
    }
  }
  useEffect(() => {
    onStartUp()
  }, [])

  return {
    get: { monacoTables, headerData, sozerTables, sozerTableHeaders },
    set: {},
    on: { pdfGenerator },
  }
}
