import { Content } from 'antd/lib/layout/layout'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from './lib'
import { useHistoryLeave } from './lib/hooks'
import React from 'react'
import { Sticky } from 'common/components/ui-kit/sticky'
import { DropDown2 } from 'common/components/ui-kit/dropdown2'
import { Table } from 'common/components/ui-kit/table-2'
import moment from 'moment/moment'
import locale from 'antd/es/date-picker/locale/tr_TR'
import { DatePicker, Switch, TimePicker } from 'antd'

function HistoryLeave() {
  const { val, set, on, get } = useHistoryLeave()

  return (
    <PanelWrapper tab={['automation', '/automation/leave/historyLeave']}>
      <Sticky>
        <Lib.S.searchSection>
          <p className="title">İzinler Geçmişi</p>
        </Lib.S.searchSection>
      </Sticky>
      <Content>
        <Lib.S.PageWrapper>
          <Lib.C.UserInfo data={get.employee} />
          <Lib.S.searchSection>
            <div>
              <div className="inputContainer">
                <Input
                  type="search"
                  placeholder="Açıklama ile ara"
                  lessBorder
                  value={get.query.description}
                  onInput={value => set.setQuery({ ...get.query, description: value.currentTarget.value })}
                  onEnter={() => set.setFetchAgain(perv => !perv)}
                  icon="search_alt"
                />
              </div>
            </div>
            <div style={{ maxWidth: 200 }}>
              <DatePicker
                value={get.query.start ? moment(get.query.start, 'YYYY-MM-DD') : null}
                onChange={(date: moment.Moment | null) => {
                  if (date) {
                    const formattedDateForServer = date.format('YYYY-MM-DD')
                    set.setQuery({ ...get.query, start: formattedDateForServer })
                    set.setFetchAgain(perv => !perv)
                  } else {
                    set.setQuery({ ...get.query })
                    set.setFetchAgain(perv => !perv)
                  }
                }}
                placeholder="Başlangıç tarihi"
                size="small"
                locale={locale}
                allowClear={false}
                className="w-100"
                style={{ height: 40, borderRadius: 7 }}
                inputReadOnly
                format="DD-MM-YYYY"
              />
            </div>
            <div style={{ maxWidth: 200 }}>
              <DatePicker
                value={get.query.end ? moment(get.query.end, 'YYYY-MM-DD') : null}
                onChange={(date: moment.Moment | null) => {
                  if (date) {
                    const formattedDateForServer = date.format('YYYY-MM-DD')
                    set.setQuery({ ...get.query, end: formattedDateForServer })
                    set.setFetchAgain(perv => !perv)
                  } else {
                    set.setQuery({ ...get.query })
                    set.setFetchAgain(perv => !perv)
                  }
                }}
                placeholder="Bitiş tarihi"
                size="small"
                locale={locale}
                allowClear={false}
                className="w-100"
                style={{ height: 40, borderRadius: 7 }}
                inputReadOnly
                format="DD-MM-YYYY"
              />
            </div>
            <div style={{ maxWidth: 200 }}>
              <DropDown2
                className="dropdown"
                showSearch={false}
                options={get.types}
                label=""
                height="40px"
                placeholder="İzin Tipi seç"
                value={get.query.typeId}
                onChange={value => {
                  set.setQuery({ ...get.query, typeId: value })
                  set.setFetchAgain(perv => !perv)
                }}
              />
            </div>
            <div className="flex justify-content-end">
              <Button
                mood="accent"
                callback={() => {
                  set.setQuery({ start: '', end: '', typeId: undefined, description: '' })
                  set.setFetchAgain(perv => !perv)
                }}
                text="Filtreyi Temizle"
                containerStyles={{ minHeight: 40, width: 190 }}
                parentStyles={{ maxWidth: 200 }}
              />
            </div>
          </Lib.S.searchSection>
          <Lib.S.content>
            <div className="tableContainer">
              <Table
                columns={val.tableColumns}
                columnSizes={val.tableSizes}
                data={val.tableData}
                rowBackColor={false}
                disabledIndexes={[val.tableIdIndex, val.tableIdIndex - 1]}
                maxHeight="calc(100vh - 110px)"
              />
            </div>
          </Lib.S.content>
        </Lib.S.PageWrapper>
      </Content>
    </PanelWrapper>
  )
}

export default HistoryLeave
