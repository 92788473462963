import { Modal, ModalProps } from 'antd'
import { Button } from 'common/components/ui-kit/button'
import { Checkbox } from 'common/components/ui-kit/checkbox'
import { DropDown2 } from 'common/components/ui-kit/dropdown2'
import EmptyList from 'common/components/ui-kit/empty-list'
import { Icon } from 'common/components/ui-kit/icon'
import ImageModal from 'common/components/ui-kit/imageModal'
import { Input } from 'common/components/ui-kit/input'
import { Textarea } from 'common/components/ui-kit/textarea'
import { PIECES_EN, PIECES_TR } from 'common/constants/pieces'
import { imageAddresser } from 'common/helpers/image.helper'
import { typeLocalizer } from 'common/helpers/localization'
import useMediaQuery from 'common/hooks/use-mediaQuery'
import { Managers } from 'common/routes/types/roles.type'
import { OptionService, OrdersService } from 'common/services'
import { RootState } from 'common/store/root-reducer'
import { MonacoChainAndLockPicture } from 'pages/private/products/list/monacoList/lib/components'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import swal from 'sweetalert'
import * as Lib from '.'
import { tableInnerListHeight as listHeight } from './constants'

export const TableMakeUp: FC<Lib.T.TableMakeupProps> = ({ list, onGradientClick, onClick }): JSX.Element => {
  return (
    <>
      <Lib.S.makeUpContainer onClick={onClick}>
        {list.map(({ pattern, gradient }, index) => (
          <span key={index}>
            {!pattern ? ' - ' : <Pattern />}
            {!gradient ? ' - ' : <Gradient onClick={onGradientClick} />}
          </span>
        ))}
      </Lib.S.makeUpContainer>
    </>
  )
}

export const Pattern: FC<{ size?: number }> = ({ size = 11 }): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.16692 0.409668H3.37668C1.8938 0.721357 0.725751 1.8894 0.414062 3.37228V4.16252C2.46365 4.12159 4.12284 2.4624 4.16692 0.409668Z"
      fill="#004E42"
    />
    <path
      d="M8.00148 4.16567C8.00148 2.31758 6.69491 0.774879 4.96016 0.409668H4.16992C4.21085 2.49074 5.91097 4.16567 8.00148 4.16567Z"
      fill="#004E42"
    />
    <path
      d="M4.16616 8.00024C4.16616 5.90973 2.49123 4.2096 0.410156 4.16553V4.95577C0.778515 6.69367 2.31807 8.00024 4.16616 8.00024Z"
      fill="#004E42"
    />
    <path
      d="M8.00073 4.16553C5.88188 4.16553 4.16602 5.88139 4.16602 8.00024C6.28487 8.00024 8.00073 6.28123 8.00073 4.16553Z"
      fill="#004E42"
    />
    <path d="M8 4.16567C10.0905 4.16567 11.7906 2.49074 11.8347 0.409668H11.0445C9.30657 0.774879 8 2.31758 8 4.16567Z" fill="#004E42" />
    <path
      d="M15.5907 4.16252V3.37228C15.2791 1.8894 14.111 0.721357 12.6313 0.409668H11.8379C11.882 2.4624 13.538 4.12159 15.5907 4.16252Z"
      fill="#004E42"
    />
    <path d="M8 4.16553C8 6.28438 9.71586 8.00024 11.8379 8.00024C11.8379 5.88139 10.1189 4.16553 8 4.16553Z" fill="#004E42" />
    <path
      d="M11.8379 8.00024C13.6828 8.00024 15.2255 6.69367 15.5907 4.95577V4.16553C13.5097 4.2096 11.8379 5.90973 11.8379 8.00024Z"
      fill="#004E42"
    />
    <path d="M4.16616 8C2.31807 8 0.775367 9.30657 0.410156 11.0445V11.8347C2.49123 11.7906 4.16616 10.0905 4.16616 8Z" fill="#004E42" />
    <path d="M4.16602 8C4.16602 10.1189 5.88188 11.8379 8.00073 11.8379C8.00073 9.71586 6.28487 8 4.16602 8Z" fill="#004E42" />
    <path
      d="M0.414062 11.8379V12.6281C0.725751 14.111 1.8938 15.2791 3.37668 15.5876H4.16692C4.12284 13.5349 2.46365 11.8788 0.414062 11.8379Z"
      fill="#004E42"
    />
    <path
      d="M8.00073 11.8345C5.91021 11.8345 4.21009 13.5063 4.16602 15.5873H4.95626C6.6973 15.2221 8.00073 13.6826 8.00073 11.8345Z"
      fill="#004E42"
    />
    <path d="M8 11.8344C10.1189 11.8344 11.8379 10.1154 11.8379 7.99658C9.71901 7.99973 8 9.71559 8 11.8344Z" fill="#004E42" />
    <path d="M11.8379 8C11.8379 10.0905 13.5097 11.7906 15.5907 11.8347V11.0445C15.2255 9.30342 13.6828 8 11.8379 8Z" fill="#004E42" />
    <path d="M8 11.8345C8 13.6794 9.30657 15.2221 11.0445 15.5873H11.8347C11.7938 13.5094 10.0905 11.8345 8 11.8345Z" fill="#004E42" />
    <path
      d="M15.5907 12.6281V11.8379C13.538 11.8788 11.882 13.5349 11.8379 15.5876H12.6281C14.111 15.2759 15.2791 14.111 15.5907 12.6281Z"
      fill="#004E42"
    />
    <path
      d="M12.3479 16H3.65211C1.6403 16 0 14.3628 0 12.3479V3.65211C0 1.6403 1.63715 0 3.65211 0H12.3479C14.3597 0 16 1.63715 16 3.65211V12.3479C16 14.3597 14.3629 16 12.3479 16ZM3.65211 0.629673C1.98662 0.629673 0.629674 1.98347 0.629674 3.65211V12.3479C0.629674 14.0134 1.98347 15.3703 3.65211 15.3703H12.3479C14.0134 15.3703 15.3703 14.0165 15.3703 12.3479V3.65211C15.3703 1.98662 14.0165 0.629673 12.3479 0.629673H3.65211Z"
      fill="#004E42"
    />
  </svg>
)

export const Gradient: FC<{ onClick: () => void }> = ({ onClick }): JSX.Element => (
  <svg width="11" onClick={onClick} height="11" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8516 15.7785H3.36221C1.74767 15.7785 0.439453 14.4703 0.439453 12.8558V3.36319C0.439453 1.74865 1.74767 0.44043 3.36221 0.44043H12.8548C14.4693 0.44043 15.7775 1.74865 15.7775 3.36319V12.8558C15.7775 14.4671 14.4661 15.7785 12.8516 15.7785Z"
      fill="url(#paint0_linear_3496:496)"
    />
    <path
      d="M12.5142 16.2156H3.70131C1.6624 16.2156 0 14.5563 0 12.5142V3.70131C0 1.6624 1.65921 0 3.70131 0H12.5142C14.5532 0 16.2156 1.65921 16.2156 3.70131V12.5142C16.2156 14.5532 14.5532 16.2156 12.5142 16.2156ZM3.70131 0.638156C2.01338 0.638156 0.638156 2.01019 0.638156 3.70131V12.5142C0.638156 14.2022 2.01019 15.5774 3.70131 15.5774H12.5142C14.2022 15.5774 15.5774 14.2054 15.5774 12.5142V3.70131C15.5774 2.01338 14.2054 0.638156 12.5142 0.638156H3.70131Z"
      fill="#004E42"
    />
    <defs>
      <linearGradient id="paint0_linear_3496:496" x1="3.73074" y1="0.0723186" x2="12.9881" y2="17.0708" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D32738" />
        <stop offset="0.2095" stop-color="#D1639A" />
        <stop offset="0.2851" stop-color="#E58882" />
        <stop offset="0.3565" stop-color="#F3A470" />
        <stop offset="0.4191" stop-color="#FCB565" />
        <stop offset="0.4661" stop-color="#FFBB61" />
        <stop offset="0.4973" stop-color="#F8BA66" />
        <stop offset="0.5432" stop-color="#E4B673" />
        <stop offset="0.5981" stop-color="#C4B188" />
        <stop offset="0.66" stop-color="#97A9A5" />
        <stop offset="0.7271" stop-color="#5E9FCA" />
        <stop offset="0.7429" stop-color="#4F9DD4" />
        <stop offset="0.8573" stop-color="#2B7991" />
        <stop offset="1" stop-color="#004E42" />
      </linearGradient>
    </defs>
  </svg>
)

export const TableInnerName: FC<Lib.T.TableInnerName> = ({
  image,
  minHeight,
  name,
  imageAction,
  onClick,
  subText,
  isMonacoProduct,
  monacoImage,
}): JSX.Element => {
  return (
    <>
      <Lib.S.tableName
        style={{ flexDirection: isMonacoProduct === true ? 'column' : 'row' }}
        isMonaco={isMonacoProduct === true}
        minHeight={minHeight}
        className="imageViewHandler true"
        onClick={onClick}
      >
        {isMonacoProduct === true ? (
          <MonacoChainAndLockPicture
            width={110}
            chainImagePath={monacoImage?.chainImagePath}
            lockImagePath={monacoImage?.lockImagePath}
            style={{ marginRight: 23 }}
          />
        ) : (
          <img src={image} alt={name} onClick={() => imageAction(image)} className="sozer-image" />
        )}
        <div className="texts">
          <span style={{ fontSize: '12px' }}>{name}</span>
          <span className="subText">{`${subText} gr`}</span>
        </div>
      </Lib.S.tableName>
    </>
  )
}

export const TableInnerList: FC<Omit<Lib.T.TableInnerListProps, 'minHeight'> & { ids?: number[] }> = ({
  list,
  maxWidth,
  trashView,
  checkboxCallback,
  onNameClick,
  pieceImages,
  ids,
  style,
  className,
}): JSX.Element => {
  const onNameCallback = (index: number) => {
    if (onNameClick && pieceImages) {
      onNameClick(pieceImages[index])
    }
  }

  const renderItem = (item: any) => {
    if (typeof item === 'string') {
      if (item?.length >= 65) {
        const value = item.substring(0, 65) + '...'
        return value
      } else {
        return item
      }
    } else {
      return item
    }
  }
  return (
    <>
      <Lib.S.tableInnerList
        style={style}
        maxWidth={maxWidth}
        minHeight={list.length * listHeight}
        className={`${className} ${trashView ? 'trashView' : ''}`}
      >
        {list.map((item: any, index) => (
          <span key={index} className="directChild">
            {trashView && (
              <Checkbox
                id={ids && ids[index].toString()}
                label=""
                onChange={checkboxCallback}
                className="pieceCheckbox"
                style={{ width: '16px', height: '16px' }}
              />
            )}
            <span
              onClick={() => onNameCallback(index)}
              style={{
                cursor: onNameClick ? 'pointer' : 'text',
                fontSize: '12px',
                padding: '0px',
                display: 'inline-block',
                textAlign: 'left',
              }}
              className="text-truncate"
            >
              {renderItem(item)}
            </span>
          </span>
        ))}
      </Lib.S.tableInnerList>
    </>
  )
}

export const TableInnerCheckbox: FC<Lib.T.TableInnerCheckboxProps> = ({ minHeight, checkboxCallback, id, onClick }): JSX.Element => {
  return (
    <>
      <Lib.S.tableInnerCheckbox minHeight={minHeight} onClick={onClick}>
        <Checkbox label="" id={id?.toString()} className="orderCheckbox" onChange={checkboxCallback} />
      </Lib.S.tableInnerCheckbox>
    </>
  )
}

export const TableActionColumn: FC<Lib.T.TableActionColumnProps> = ({ onEdit, onImageView, style, trashView }): JSX.Element => {
  if (trashView) {
    return <></>
  }

  return (
    <>
      <Lib.S.actions style={{ ...style, height: '15px' }}>
        <span onClick={() => onEdit()} style={{ width: '15px', height: '15px' }}>
          <Icon name="edit_outline" color="var(--accent)" size={15} />
        </span>
      </Lib.S.actions>
    </>
  )
}

export const TableRemoveAction: FC<Lib.T.TableRemoveActionProps> = ({ onTrash, editView, trashView }): JSX.Element => {
  if (trashView) {
    return <></>
  }
  return (
    <>
      <Lib.S.actions editView={editView} onClick={() => (editView ? null : onTrash())}>
        <span className="trash" style={{ width: '15px', height: '15px' }}>
          <Icon name="trash_bin" color={editView ? '#ccc' : 'var(--accent)'} size={15} />
        </span>
      </Lib.S.actions>
    </>
  )
}
export const FooterPage: FC<{ orderWeight: number | undefined; productsAmount: number | undefined }> = ({
  orderWeight,
  productsAmount,
}) => {
  return (
    <Lib.S.FooterPage>
      <div className="page-footer">
        <div className="footer-container">
          <div>
            <span className="footer-info">Ürün sayısı : </span> <span className="footer-value">{productsAmount}</span>
          </div>

          <div>
            <span className="footer-info">Siparişin Toplam Ağırlığı : </span> <span className="footer-value">{orderWeight} gr</span>
          </div>
        </div>
      </div>
    </Lib.S.FooterPage>
  )
}
export const CategoryTable: FC<Lib.T.CategoryTableProps> = ({ groupProduts, orderTotalProfit, orderTotalWeight, orderEfficiency }) => {
  const { user } = useSelector((state: RootState) => state.globals)

  const handleShowInfoDiv = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const tr = e.currentTarget.parentNode as HTMLDivElement
    if (!tr) return
    const thead = document.querySelector('.thead')! as HTMLDivElement
    const infoDiv = tr.nextSibling as HTMLDivElement

    infoDiv.classList.add('show')
    tr.classList.add('hidden')
    thead.classList.add('hidden')
  }

  const handleRemoveInfoDiv = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const thead = document.querySelector('thead')! as HTMLDivElement
    const tr = e.currentTarget.parentNode?.parentNode?.previousSibling as HTMLDivElement
    const parent = e.currentTarget.parentNode?.parentNode! as HTMLDivElement
    parent.classList.remove('show')
    thead.classList.remove('hidden')
    tr.classList.remove('hidden')
  }
  const permittedRoles = [...Managers, 'Satış Temsilcisi']
  return (
    <Lib.S.CategoryTableWrapper>
      {permittedRoles.includes(user.group.name) && groupProduts.length ? (
        <table className="category-table">
          <thead className="thead">
            <tr>
              <th></th>
              <th>Resim</th>
              <th>Kategori ismi</th>
              {Managers.includes(user.group.name) && <th>Toplam Kar</th>}
              <th>Toplam Ağırlık</th>
              {Managers.includes(user.group.name) && <th>Kategori Karlılığı</th>}
            </tr>
          </thead>
          <div className="page-footer">
            <div className="footer-container">
              <div>
                <span className="footer-info">Siparişin Toplam Ağirliği:</span> <span className="footer-value">{orderTotalWeight} gr</span>
              </div>
              {user.group.name !== 'Satış Temsilcisi' && (
                <>
                  <div>
                    <span className="footer-info">Siparişin Toplam Karı:</span> <span className="footer-value">$ {orderTotalProfit}</span>
                  </div>
                  <div>
                    <span className="footer-info">Siparişin Verimliliği:</span>{' '}
                    <span className="footer-value">{orderEfficiency ?? 0} $/gr</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <tbody>
            {groupProduts.map((cat, index) => {
              return (
                <>
                  <tr>
                    <td onClick={e => handleShowInfoDiv(e)}>
                      <Icon name="arrow-down" size={20} style={{ cursor: 'pointer' }} />
                    </td>
                    <td className="image">
                      <div>
                        <img
                          src="https://static.vecteezy.com/packs/media/components/global/search-explore-nav/img/vectors/term-bg-1-666de2d941529c25aa511dc18d727160.jpg"
                          alt=""
                        />
                      </div>
                    </td>
                    <td>{cat.productCategory}</td>
                    {Managers.includes(user.group.name) && <td>$ {cat.categoryTotalProfit}</td>}
                    <td>{cat.categoryTotalWeight} gr</td>
                    {Managers.includes(user.group.name) && <td>{cat.categoryEfficiency ?? 0} $/gr</td>}
                  </tr>
                  <Lib.S.InfoDiv style={{ height: `${cat.products.length * 170 + 50 + 30 + 90}px` }}>
                    <div className="header">
                      <div
                        onClick={e => {
                          handleRemoveInfoDiv(e)
                        }}
                      >
                        <Icon name="arrow-up" size={20} style={{ cursor: 'pointer' }} />
                      </div>
                      <span>{cat.productCategory}</span>
                    </div>
                    <div className="content">
                      <table>
                        <thead>
                          <tr>
                            <th>Ürün kod</th>
                            <th>Parçalar</th>
                            {Managers.includes(user.group.name) && <th>Taş Karı</th>}
                            <th>Fiyat</th>
                            {Managers.includes(user.group.name) && <th>Genel Kar</th>}
                            <th>Adet</th>
                            {Managers.includes(user.group.name) && (
                              <>
                                <th>Toplam Kar</th>
                                <th>Ağırlık</th>
                                <th>Toplam Ağırlık</th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {/* SUB DIV */}
                          {cat.products.map(prod => {
                            return (
                              <tr>
                                <td style={{ width: '10%' }}>
                                  <div className="piece-image">
                                    <img
                                      src={process.env.REACT_APP_DOMAIN! + prod.productImage}
                                      alt="test"
                                      style={{ marginBottom: '15px' }}
                                    />
                                  </div>
                                  <span>{prod.productCode}</span>
                                </td>
                                <td>
                                  <div className="piece-name">
                                    {prod.pieces.map(piece => {
                                      return <span>{piece.type ?? '-'}</span>
                                    })}
                                  </div>
                                </td>
                                {Managers.includes(user.group.name) && (
                                  <td>
                                    <div className="piece-name">
                                      {prod.pieces.map(piece => {
                                        return <span>$ {piece.stoneProfit}</span>
                                      })}
                                    </div>
                                  </td>
                                )}
                                <td>
                                  <div className="piece-name">
                                    {prod.pieces.map(piece => {
                                      return <span>$ {piece.price ?? 0}</span>
                                    })}
                                  </div>
                                </td>
                                {Managers.includes(user.group.name) && (
                                  <td>
                                    <div className="piece-name">
                                      {prod.pieces.map(piece => {
                                        return <span>$ {piece.generalProfit}</span>
                                      })}
                                    </div>
                                  </td>
                                )}

                                <td>
                                  <div className="piece-name">
                                    {prod.pieces.map(piece => {
                                      return <span>{piece.count}</span>
                                    })}
                                  </div>
                                </td>
                                {Managers.includes(user.group.name) && (
                                  <>
                                    <td>
                                      <div className="piece-name">
                                        {prod.pieces.map(piece => {
                                          return <span>$ {piece.totalProfit}</span>
                                        })}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="piece-name">
                                        {prod.pieces.map(piece => {
                                          return <span>{piece.weight} gr</span>
                                        })}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="piece-name">
                                        {prod.pieces.map(piece => {
                                          return <span>{piece.pieceTotalWeight} gr</span>
                                        })}
                                      </div>
                                    </td>
                                  </>
                                )}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                      <div className="footer">
                        {Managers.includes(user.group.name) && (
                          <>
                            <span className="text">
                              Kategori <span>{cat.productCategory}</span> nin toplam karı
                            </span>
                            <span className="price">$ {cat.categoryTotalProfit}</span>
                          </>
                        )}
                      </div>
                    </div>
                  </Lib.S.InfoDiv>
                </>
              )
            })}
          </tbody>
        </table>
      ) : (
        <EmptyList />
      )}
    </Lib.S.CategoryTableWrapper>
  )
}

export const ExtraDetailsFilter: React.FC<Lib.T.ExtraDetailsFilterTypes> = ({
  piecesIds,
  updateProductsData,
  filterData: queryData,
  pieceIdType,
}) => {
  const { get, set } = Lib.H.useExtraDetailsFilter()
  useEffect(() => {
    set.setSelectedNecklacePiece(0)
    set.setSelectedBraceletPiece(0)
    set.setSelectedRingPiece(0)
    set.setSelectedEaringPiece(0)
    set.setSelectedBanglePiece(0)
    set.setSelectedPendantPiece(0)
  }, [queryData.productCategorySelected || queryData.productSubCategorySelected])

  const filterDataBaseOnPieceType = (type: string) => {
    return pieceIdType?.filter(id => id.type === type).map(id => id.id)
  }

  const filterData = [
    {
      sectionTitle: 'Kolye',
      inputs: [
        {
          inputHeader: 'Adet',
          inputItem: () => {
            return (
              <Input
                lessBorder
                value={get?.selectedNecklacePiece}
                disabled={!pieceIdType?.some(piece => piece.type === 'necklace')}
                onInput={evt => {
                  if (evt?.currentTarget?.value === '') {
                    set?.setSelectedNecklacePiece(0)
                  } else if (evt?.currentTarget?.value !== '') {
                    set?.setSelectedNecklacePiece(parseInt(evt?.currentTarget?.value))
                    updateProductsData(
                      {
                        necklace: {
                          count: parseInt(evt?.currentTarget?.value),
                          pieces: filterDataBaseOnPieceType('necklace'),
                        },
                      },
                      {
                        productCategory: queryData?.productCategorySelected?.productCategoryId,
                        productSubCategory: queryData?.productSubCategorySelected?.productSubCategoryId,
                      },
                    )
                  }
                }}
              />
            )
          },
        },
        {
          inputHeader: 'Boy',
          inputItem: () => {
            return (
              <DropDown2
                lessBorder
                disabled={!pieceIdType?.some(piece => piece.type === 'necklace')}
                options={get.NecklaceSize}
                // value={get.selectedNecklaceSize}
                onChange={(item: string) => {
                  updateProductsData(
                    {
                      necklace: {
                        size: parseInt(item),
                        count: get?.selectedNecklacePiece ? get?.selectedNecklacePiece : undefined,
                        pieces: filterDataBaseOnPieceType('necklace'),
                      },
                    },
                    {
                      productCategory: queryData?.productCategorySelected?.productCategoryId,
                      productSubCategory: queryData?.productSubCategorySelected?.productSubCategoryId,
                    },
                  )
                }}
              />
            )
          },
        },
      ],
    },
    {
      sectionTitle: 'Bileklik',
      inputs: [
        {
          inputHeader: 'Adet',
          inputItem: () => {
            return (
              <Input
                lessBorder
                disabled={!pieceIdType?.some(piece => piece.type === 'bracelet')}
                value={get?.selectedBraceletPiece}
                onInput={evt => {
                  if (evt?.currentTarget?.value === '') {
                    set?.setSelectedBraceletPiece(0)
                  } else if (evt?.currentTarget?.value !== '') {
                    set?.setSelectedBraceletPiece(parseInt(evt?.currentTarget?.value))
                    updateProductsData(
                      {
                        bracelet: {
                          count: parseInt(evt?.currentTarget?.value),
                          pieces: filterDataBaseOnPieceType('bracelet'),
                        },
                      },
                      {
                        productCategory: queryData?.productCategorySelected?.productCategoryId,
                        productSubCategory: queryData?.productSubCategorySelected?.productSubCategoryId,
                      },
                    )
                  }
                }}
              />
            )
          },
        },
        {
          inputHeader: 'Boy',
          inputItem: () => {
            return (
              <DropDown2
                lessBorder
                disabled={!pieceIdType?.some(piece => piece.type === 'bracelet')}
                // containerStyles={{ width: '60px', height: '28px' }}
                options={get?.braceletSize}
                // disabled={!!!get?.selectedBraceletPiece}
                onChange={(item: string) => {
                  updateProductsData(
                    {
                      bracelet: {
                        size: parseInt(item),
                        count: get?.selectedBraceletPiece ? get?.selectedBraceletPiece : undefined,
                        pieces: filterDataBaseOnPieceType('bracelet'),
                      },
                    },
                    {
                      productCategory: queryData?.productCategorySelected?.productCategoryId,
                      productSubCategory: queryData?.productSubCategorySelected?.productSubCategoryId,
                    },
                  )
                }}
              />
            )
          },
        },
      ],
    },
    {
      sectionTitle: 'Yüzük',
      inputs: [
        {
          inputHeader: 'Adet',
          inputItem: () => {
            return (
              <Input
                lessBorder
                disabled={!pieceIdType?.some(piece => piece.type === 'ring')}
                value={get?.selectedRingPiece}
                onInput={evt => {
                  if (evt?.currentTarget?.value === '') {
                    set?.setSelectedRingPiece(0)
                  } else if (evt?.currentTarget?.value !== '') {
                    set?.setSelectedRingPiece(parseInt(evt?.currentTarget?.value))
                    updateProductsData(
                      {
                        ring: {
                          count: parseInt(evt?.currentTarget?.value),
                          pieces: filterDataBaseOnPieceType('ring'),
                        },
                      },
                      {
                        productCategory: queryData?.productCategorySelected?.productCategoryId,
                        productSubCategory: queryData?.productSubCategorySelected?.productSubCategoryId,
                      },
                    )
                  }
                }}
              />
            )
          },
        },
        {
          inputHeader: 'Boy',
          inputItem: () => {
            return (
              <DropDown2
                // disabled={!!!get?.selectedRingPiece}
                disabled={!pieceIdType?.some(piece => piece.type === 'ring')}
                lessBorder
                options={get?.RingSize}
                onChange={(item: string) => {
                  updateProductsData(
                    {
                      ring: {
                        size: parseInt(item),
                        count: get?.selectedRingPiece ? get?.selectedRingPiece : undefined,
                        pieces: filterDataBaseOnPieceType('ring'),
                      },
                    },
                    {
                      productCategory: queryData?.productCategorySelected?.productCategoryId,
                      productSubCategory: queryData?.productSubCategorySelected?.productSubCategoryId,
                    },
                  )
                }}
              />
            )
          },
        },
      ],
    },
    {
      sectionTitle: 'Kupe',
      inputs: [
        {
          inputHeader: 'Adet',
          inputItem: () => {
            return (
              <Input
                lessBorder
                disabled={!pieceIdType?.some(piece => piece.type === 'ear-rings')}
                value={get?.selectedEaringPiece}
                onInput={evt => {
                  if (evt?.currentTarget?.value === '') {
                    set?.setSelectedEaringPiece(0)
                  } else if (evt?.currentTarget?.value !== '') {
                    set?.setSelectedEaringPiece(parseInt(evt?.currentTarget?.value))
                    updateProductsData(
                      {
                        earrings: {
                          count: parseInt(evt?.currentTarget?.value),
                          pieces: filterDataBaseOnPieceType('ear-rings'),
                        },
                      },
                      {
                        productCategory: queryData?.productCategorySelected?.productCategoryId,
                        productSubCategory: queryData?.productSubCategorySelected?.productSubCategoryId,
                      },
                    )
                  }
                }}
              />
            )
          },
        },
      ],
    },
    {
      sectionTitle: 'Kelepçe',
      inputs: [
        {
          inputHeader: 'Adet',
          inputItem: () => {
            return (
              <Input
                lessBorder
                disabled={!pieceIdType?.some(piece => piece.type === 'cuff')}
                value={get?.selectedBanglePiece}
                onInput={evt => {
                  if (evt?.currentTarget?.value === '') {
                    set?.setSelectedBanglePiece(0)
                  } else if (evt?.currentTarget?.value !== '') {
                    set?.setSelectedBanglePiece(parseInt(evt?.currentTarget?.value))
                    updateProductsData(
                      {
                        cuff: {
                          count: parseInt(evt?.currentTarget?.value),
                          pieces: filterDataBaseOnPieceType('cuff'),
                        },
                      },
                      {
                        productCategory: queryData?.productCategorySelected?.productCategoryId,
                        productSubCategory: queryData?.productSubCategorySelected?.productSubCategoryId,
                      },
                    )
                  }
                }}
              />
            )
          },
        },
      ],
    },
    {
      sectionTitle: 'Kolye ucu',
      inputs: [
        {
          inputHeader: 'Adet',
          inputItem: () => {
            return (
              <Input
                lessBorder
                disabled={!pieceIdType?.some(piece => piece.type === 'pendant')}
                value={get?.selectedPendantPiece}
                onInput={evt => {
                  if (evt?.currentTarget?.value === '') {
                    set?.setSelectedPendantPiece(0)
                  } else if (evt?.currentTarget?.value !== '') {
                    set?.setSelectedPendantPiece(parseInt(evt?.currentTarget?.value))
                    updateProductsData(
                      {
                        pendant: {
                          count: parseInt(evt?.currentTarget?.value),
                          pieces: filterDataBaseOnPieceType('pendant'),
                        },
                      },
                      {
                        productCategory: queryData?.productCategorySelected?.productCategoryId,
                        productSubCategory: queryData?.productSubCategorySelected?.productSubCategoryId,
                      },
                    )
                  }
                }}
              />
            )
          },
        },
      ],
    },
    {
      inputs: [
        {
          inputHeader: 'Fiyat',
          inputItem: () => {
            return (
              <Input
                type="number"
                step="0.01"
                onFocus={e =>
                  e.target.addEventListener(
                    'wheel',
                    function (e) {
                      e.preventDefault()
                    },
                    { passive: false },
                  )
                }
                min={0}
                lessBorder
                onInput={evt => {
                  if (evt?.currentTarget?.value === '') {
                  } else {
                    updateProductsData(
                      {
                        price: {
                          price: parseFloat(evt?.currentTarget?.value),
                          pieces: piecesIds,
                        },
                      },
                      {
                        productCategory: queryData?.productCategorySelected?.productCategoryId,
                        productSubCategory: queryData?.productSubCategorySelected?.productSubCategoryId,
                      },
                    )
                  }
                }}
              />
            )
          },
        },
        {
          inputHeader: 'Renk',
          inputItem: () => {
            return (
              <DropDown2
                className={'colors'}
                lessBorder
                options={get?.productColorList}
                onChange={(item: string) => {
                  updateProductsData(
                    {
                      madeColor: {
                        madeColor: parseInt(item),
                        pieces: piecesIds,
                      },
                    },
                    {
                      productCategory: queryData?.productCategorySelected?.productCategoryId,
                      productSubCategory: queryData?.productSubCategorySelected?.productSubCategoryId,
                    },
                  )
                }}
              />
            )
          },
        },
      ],
    },
  ]

  const renderFilterData = () => {
    return filterData.map((item: any) => {
      return (
        <div className={` sectionContainer ${item?.inputs?.length < 2 && 'SingleInputSection'}`}>
          {item?.sectionTitle !== undefined && <div className="sectionTitle">{item?.sectionTitle}</div>}
          <div className="inputsWrapper">{renderInputData(item?.inputs)}</div>
        </div>
      )
    })
  }

  const renderInputData = (inputData: any) => {
    return inputData.map((item: any, index: number) => {
      return (
        <div className={`${index === 0 ? 'firstInputContainer' : 'secondInputContainer'} inputContainer`}>
          <div className="inputHeader">{item.inputHeader}</div>
          {item?.inputItem()}
        </div>
      )
    })
  }
  return <Lib.S.extraDetailsFilterWrapper>{renderFilterData()}</Lib.S.extraDetailsFilterWrapper>
}

export const CategoryFilter: React.FC<any> = ({
  categories,
  profitTable,
  onCategoryChange,
  reseter,
  setSubCategoryMonacoData,
  setSubCategoryData,
}) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(0)

  const renderCategory = () => {
    return categories.map((category: any, index: number) => {
      return (
        <div
          className={`category ${profitTable.get.selectedCategoryIndex === index && 'selectedCategory'}`}
          key={category.id}
          onClick={() => {
            setSelectedCategoryId(category.id)
            if (profitTable.get?.selectedCategoryIndex === index) {
              reseter({
                showMonaco: true,
                showOthers: true,
              })
              profitTable.set.setSelectedCategoryIndex(-1)
            } else if (profitTable.get?.selectedCategoryIndex !== index) {
              if (category?.isMonacoCategory) {
                reseter({
                  showMonaco: true,
                  showOthers: false,
                })
              } else if (!category?.isMonacoCategory) {
                reseter({
                  showMonaco: false,
                  showOthers: true,
                })
              }
              profitTable.set.setSelectedCategoryIndex(index)
              onCategoryChange('category', category.id, true)
            }
            profitTable.set.setSelectedSubCategoriesIndex(-1)
          }}
        >
          <div className="imgContainer">
            <img src={imageAddresser(category.productCategoryImage, true)} alt={category.productCategory} />
          </div>
          <div className="categoryTitle">{category.productCategory}</div>
        </div>
      )
    })
  }

  const renderSubCategory = () => {
    return categories[profitTable.get.selectedCategoryIndex].productSubCategories.map((subCategory: any, index: number) => {
      return (
        <div
          className={`subCategory ${profitTable.get.selectedSubCategoriesIndex === index && 'selectedSubCategory'}`}
          key={subCategory.id}
          onClick={() => {
            if (profitTable.get?.selectedSubCategoriesIndex === index) {
              setSubCategoryMonacoData([])
              setSubCategoryData([])
              profitTable.set.setSelectedSubCategoriesIndex(-1)
            } else if (profitTable.get?.selectedSubCategoriesIndex !== index) {
              profitTable.set.setSelectedSubCategoriesIndex(index)
              onCategoryChange('sub-category', subCategory.id, true)
            }
          }}
        >
          <div className="imgContainer">
            <img src={imageAddresser(subCategory.productSubCategoryImage, true)} alt={subCategory.productSubCategory} />
          </div>
          <div className="subCategoryTitle">{subCategory.productSubCategory}</div>
        </div>
      )
    })
  }

  return (
    <Lib.S.categoryFilterWrapper selected={profitTable.get.selectedCategoryIndex !== -1 ? true : false}>
      <div className="categoryContainer">{renderCategory()}</div>
      {profitTable.get.selectedCategoryIndex !== -1 && <div className="subCategoryContainer">{renderSubCategory()}</div>}
    </Lib.S.categoryFilterWrapper>
  )
}

export const DetailModal: FC<Lib.T.DetailModal> = ({
  modalProps,
  pieces,
  removeVariant,
  toggleModal,
  addToCart,
  color,
  enamel,
  statesContainer,
  onCreateVariant,
  setStatesContainer,
  variants,
  key,
  testingProp,
  totalVariantsWeight,
  onChangeVariantValues,
  orderValue,
  productToEdit,
  onDeleteVariant,
  setVariants,
  addDeletedPieces,
}): JSX.Element => {
  const isLaptop = useMediaQuery('(max-width:1300px)')

  const [purityModalInfo, setPurityModalInfo] = useState<{
    ContainerIndex: number
    isModalOpen: boolean
    purity?: number
    isVariant: boolean
    isMasterInput?: boolean
  }>({
    ContainerIndex: 0,
    isModalOpen: false,
    isVariant: false,
    isMasterInput: false,
  })

  const [numberForPurity, setNumberForPurity] = useState('')

  const purityModalOptions: ModalProps = {
    footer: null,
    visible: purityModalInfo.isModalOpen,
    onCancel: () => {
      setPurityModalInfo(perv => ({ ...perv, isModalOpen: false }))
      setNumberForPurity('')
    },
  }

  const initialMaster: Lib.T.AddToCartStatesContainer = useMemo(() => {
    return {
      color: undefined,
      description: '',
      enamel: [],
      number: 0,
      price: 0,
      size: 0,
      id: 0,
      minMaxPrice: '',
      productCode: '',
      weight: 0,
      image: '',
      purity: 0,
      savedPrice: false,
    }
  }, [statesContainer])

  const [showModal, setShowModal] = useState(false)

  const [weight, setWeight] = useState<number>(0)
  const [currentProduct, setCurrentProduct] = useState<any>({})

  const [hasNewPiecesState, setHasNewPiecesState] = useState<boolean | undefined>()

  const [master, setMaster] = useState<Lib.T.AddToCartStatesContainer>(initialMaster)
  const [imageUrlForPopup, setImageUrlForPopup] = useState<string>('')

  const [goldPrice, setGoldPrice] = useState(0)

  const getDeletedPieces = async () => {
    const service = new OrdersService()

    if (currentProduct?.category === 'monaco') return

    let currentProductId = 0

    orderValue.products.forEach((product: any) => {
      if (product.id === productToEdit) {
        currentProductId = product.id
      }
    })

    const { success, hasNewPieces } = await service.getDeletedPieces(currentProductId, orderValue?.id.toString())
    if (!success) {
      return
    }

    setHasNewPiecesState(hasNewPieces)
  }

  useEffect(() => {
    if (modalProps.visible && currentProduct.category !== 'monaco') {
      getDeletedPieces()
    }
  }, [modalProps.visible, currentProduct])

  const changeStatesContainer = (indexToChange: number, newValue: any, type: Lib.T.AddToCartStatesContainerKeys) => {
    const prevStates = statesContainer.slice(0, indexToChange)
    const rowState = statesContainer[indexToChange]
    const nextStates = statesContainer.slice(indexToChange + 1, statesContainer.length)
    rowState[type] = newValue

    setStatesContainer(prevStates.concat(rowState).concat(nextStates))
  }

  const changeMaster = (value: any, type: Lib.T.AddToCartStatesContainerKeys) => {
    setMaster(prev => {
      return {
        ...prev,
        [type]: value,
      }
    })
    setPurityModalInfo(perv => ({ ...perv, isMasterInput: false }))
  }

  const onMasterChange = (type: Lib.T.AddToCartStatesContainerKeys) => {
    const newStateContainer: Lib.T.AddToCartStatesContainer[] = []
    statesContainer.map((_i, index) =>
      newStateContainer.push({
        ...statesContainer[index],
        [type]: type === 'enamel' ? (master[type].length > 0 ? master[type] : statesContainer[index]?.enamel) : master[type],
      }),
    )
    setStatesContainer(newStateContainer)

    const newVariant: any[] = []
    if (variants.length) {
      variants.map((_i: any, index: number) =>
        newVariant.push({
          ...variants[index],
          [type]: type === 'enamel' ? (master[type]?.length > 0 ? master[type] : statesContainer[index]?.enamel) : master[type],
        }),
      )
    }

    setVariants(newVariant)
  }

  const calculateWeight = () => {
    if (!statesContainer || !statesContainer[0]) {
      return
    }
    let totalWeights = statesContainer.map(i => i.weight * i.number)
    if (totalVariantsWeight) {
      if (totalVariantsWeight.length > 0) {
        let combinedWeights = totalWeights.concat(totalVariantsWeight)
        setWeight(combinedWeights.reduce((a, b) => a + b, 0))
      } else {
        setWeight(totalWeights.reduce((a, b) => a + b, 0))
      }
    } else {
      setWeight(totalWeights.reduce((a, b) => a + b, 0))
    }
  }

  const [valueChangeKey, setValueChangeKey] = useState<number>(0)

  useEffect(() => {
    setValueChangeKey((prev: any) => (prev = prev + 1))
  }, [testingProp])

  useEffect(() => {
    changeMaster(null, 'number')

    setMaster(initialMaster)
  }, [modalProps.visible])

  useEffect(() => {
    if (orderValue) {
      orderValue.products.forEach((product: any) => {
        if (product.id === productToEdit) {
          setCurrentProduct(product)
        }
      })
    }
  }, [productToEdit, orderValue])

  useEffect(() => onMasterChange('color'), [master.color])
  useEffect(() => onMasterChange('description'), [master.description])
  useEffect(() => onMasterChange('enamel'), [master.enamel])
  useEffect(() => onMasterChange('number'), [master.number])
  useEffect(() => onMasterChange('price'), [master.price])
  useEffect(() => onMasterChange('size'), [master.size])
  useEffect(() => onMasterChange('image'), [master.image])
  useEffect(() => {
    calculateWeight()
  }, [statesContainer, totalVariantsWeight, valueChangeKey, calculateWeight])

  const disableMasterMine = pieces.every((item: any) => {
    return item.enamel?.length === 0
  })

  const fetchGoldPrice = async () => {
    const goldPriceService = new OptionService({ type: 'gold-price' })

    const goldPriceResult = await goldPriceService.read()
    if (!goldPriceResult.success) {
      return
    }
    setGoldPrice(goldPriceResult.options![0].goldPrice as number)
  }

  useEffect(() => {
    fetchGoldPrice()
  }, [])

  const renderFirstRow = () => {
    if (pieces.length !== 1) {
      if (currentProduct?.category === 'monaco') {
        return (
          <tr className="monaco-firstRow">
            <td>
              <div className="image monaco-master">
                <span>{'ürün'}</span>
              </div>
            </td>
            <td className="monaco-width">
              <Input className="DD" value={currentProduct?.pieces[0]?.width?.width} onInput={val => {}} disabled={true} />
            </td>
            <td className="monaco-size">
              <Input
                containerClass="size-in-inches"
                value={currentProduct?.pieces[0]?.size?.sizeInInches + ' “'}
                onInput={val => {}}
                disabled={true}
              />
              <Input containerClass="size-in-cm" value={currentProduct?.pieces[0]?.size?.size} onInput={val => {}} disabled={true} />
            </td>
            <td>
              {color.length > 0 && (
                <DropDown2 className="monaco-color" options={color} value={master.color} onChange={val => changeMaster(val, 'color')} />
              )}
            </td>
            <td className="monaco-count">
              <Input value={master.number} onInput={(val: any) => changeMaster(parseInt(val.currentTarget.value), 'number')} />
            </td>

            <td className="monaco-price">
              <div className="d-flex">
                <Input value={master?.price} className="price" onChange={val => changeMaster(val.currentTarget.value, 'price')} />
                <Icon
                  name="calculator"
                  size={15}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setPurityModalInfo(perv => ({
                      ...perv,
                      isModalOpen: true,
                      isMasterInput: true,
                      purity: orderValue.karat.purity,
                      isVariant: false,
                    }))
                  }
                />
              </div>
            </td>
            <td>
              <Textarea
                className="explain"
                value={master.description}
                onChange={val => changeMaster(val.currentTarget.value, 'description')}
              />
            </td>
          </tr>
        )
      } else {
        return (
          <tr>
            <td>
              <div className="image">
                <img
                  src={(statesContainer.length > 0 && statesContainer[0].image) || ''}
                  alt="product"
                  onClick={() => {
                    setImageUrlForPopup(statesContainer[0].image)
                    setShowModal(perval => !perval)
                  }}
                />
                <span>{'ürün'}</span>
              </div>
            </td>
            <td></td>
            <td>
              {color.length > 0 && (
                <DropDown2 className="DD" options={color} value={master.color} onChange={val => changeMaster(val, 'color')} />
              )}
            </td>

            <td>
              <Lib.C.DetailNumbers defaultValue={master.number} onChange={(val: any) => changeMaster(val, 'number')} />
            </td>
            {/* gram place holder */}
            <td></td>
            <td>
              <div className="d-flex">
                <Input value={master.price} className="price" onChange={val => changeMaster(val.currentTarget.value, 'price')} />
                <Icon
                  name="calculator"
                  size={15}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setPurityModalInfo(perv => ({
                      ...perv,
                      isModalOpen: true,
                      isMasterInput: true,
                      purity: orderValue.karat.purity,
                      isVariant: false,
                    }))
                  }
                />
              </div>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <DropDown2
                className="DD"
                options={enamel}
                mode="multiple"
                value={master.enamel}
                onChange={val => changeMaster(val, 'enamel')}
                disabled={disableMasterMine}
              />
            </td>
            <td>
              <Textarea
                className="explain"
                value={master.description}
                onChange={val => changeMaster(val.currentTarget.value, 'description')}
              />
            </td>
          </tr>
        )
      }
    }
  }

  return (
    <>
      {/* purity modal  */}
      <Modal {...purityModalOptions}>
        <div className="d-flex gap-2 align-items-center">
          <Input label="Milyem" value={numberForPurity} onChange={e => setNumberForPurity(e.target.value)} />
          <div className="d-flex" style={{ marginTop: 35 }}>
            <Button
              text="Hesapla"
              mood="accent"
              callback={() => {
                if (!numberForPurity) {
                  return swal({
                    text: 'Lütfen milyem hesaplamak için rakam girin',
                    icon: 'warning',
                  })
                }

                const value = ((parseFloat(numberForPurity) - purityModalInfo.purity!) * goldPrice) / 1000

                if (purityModalInfo.isVariant) {
                  onChangeVariantValues(value.toFixed(2), purityModalInfo.ContainerIndex, 'price')
                } else if (purityModalInfo.isMasterInput) {
                  changeMaster(value, 'price')
                } else {
                  changeStatesContainer(purityModalInfo.ContainerIndex, value.toFixed(2), 'price')
                }
                setPurityModalInfo(perv => ({ ...perv, isModalOpen: false }))
                setNumberForPurity('')
              }}
              containerStyles={{ minHeight: '42px' }}
            />
          </div>
        </div>
      </Modal>
      <Modal {...modalProps}>
        <Lib.S.modal priceCondition={true} isMonaco={currentProduct?.category === 'monaco'}>
          {currentProduct?.category === 'monaco' ? (
            <div className="header">
              <div className="header-detail">
                <div style={{ width: '100%' }}>
                  <p className="key">Ürün Kodu:</p>
                  {statesContainer && statesContainer[0] && <p className="value">{statesContainer[0].productCode}</p>}
                </div>
                <div style={{ width: '100%' }}>
                  <p className="key">Toplam Gram:</p>
                  <p className="value">{weight.toFixed(2)}</p>
                </div>
              </div>
              <div className="buttons">
                <div>
                  <Button
                    text="Son Fiyat"
                    mood="gray"
                    callback={() => {
                      addToCart(true)
                    }}
                    parentStyles={{ width: '100%' }}
                  />
                </div>
                <div>
                  <Button
                    text="Vazgeç"
                    mood="orang"
                    callback={() => {
                      removeVariant()
                      toggleModal(false)
                    }}
                    parentStyles={{ width: '100%' }}
                  />
                </div>
                <div>
                  <Button
                    text="Sepete Ekle"
                    mood="accent"
                    callback={() => {
                      addToCart(false)
                    }}
                    parentStyles={{ width: '100%' }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="header">
              <div>
                <p className="key">Ürün Kodu:</p>
                {statesContainer && statesContainer[0] && <p className="value">{statesContainer[0].productCode}</p>}
              </div>
              <div>
                <p className="key">Toplam Gram:</p>
                <p className="value">{weight.toFixed(2)}</p>
              </div>

              <div>
                <p className="key">Fiyat:</p>
                {statesContainer && statesContainer[0] && <p className="value">{statesContainer[0].minMaxPrice}</p>}
              </div>
              <div className="buttons">
                {hasNewPiecesState ? (
                  <div>
                    <Button
                      text="Parça Ekle"
                      disabled={!hasNewPiecesState}
                      mood="success"
                      callback={() =>
                        addDeletedPieces(currentProduct?.id!, {
                          maxPrice: currentProduct?.priceCategory.maxPrice!,
                          minPrice: currentProduct?.priceCategory.maxPrice!,
                          productCode: currentProduct?.productCode!,
                          productImage: currentProduct?.productImage!,
                          productCategory: currentProduct?.category!,
                        })
                      }
                      parentStyles={{ width: '100%' }}
                    />
                  </div>
                ) : null}
                <div>
                  <Button
                    text="Son Fiyat"
                    mood="gray"
                    callback={() => {
                      addToCart(true)
                    }}
                    parentStyles={{ width: '100%' }}
                  />
                </div>
                <div>
                  <Button text="Vazgeç" mood="orang" callback={() => toggleModal(false)} parentStyles={{ width: '100%' }} />
                </div>
                <div>
                  <Button
                    text="Sepete Ekle"
                    mood="accent"
                    callback={() => {
                      addToCart(false)
                    }}
                    parentStyles={{ width: '100%' }}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="tableContainer">
            <table className="table">
              <thead>
                {currentProduct?.category === 'monaco' ? (
                  <tr>
                    <td width={80}>Parçalar</td>
                    <td width={50}>Genişlık</td>
                    <td width={150}>Boy</td>
                    <td width={50}>Renk</td>
                    <td width={100}>Adet</td>
                    <td width={160}>Fiyat</td>
                    <td width={460}>Açıklama</td>
                  </tr>
                ) : (
                  <tr>
                    <td width={150}>Parçalar</td>
                    <td width={110}>Boy</td>
                    <td width={110}>Renk</td>
                    <td width={50}>Adet</td>
                    <td width={50}>Gram</td>
                    <td width={130}>Fiyat</td>
                    <td width={150}>Mine</td>
                    <td>Açıklama</td>
                  </tr>
                )}
              </thead>
              <tbody>
                <ImageModal downloadImageLink={''} showModal={showModal} setShowModal={setShowModal} image={imageUrlForPopup} withZoom />
                {renderFirstRow()}
                {currentProduct?.category === 'monaco'
                  ? currentProduct?.pieces.map((item: any, index: number) => {
                      return (
                        <>
                          <tr key={index} className="monaco-row">
                            <td>
                              <div className="image">
                                <Icon
                                  name="plus_with_border_Green"
                                  size={20}
                                  style={{ marginRight: '8px', height: '20px !important', width: '20px !important' }}
                                  onClick={() => onCreateVariant(statesContainer[index], item)}
                                />
                                <span className="name p-1 monaco-stone-name">{typeLocalizer(item?.type)}</span>
                              </div>
                            </td>
                            <td className="monaco-width">
                              <Input value={item?.width?.width} onInput={val => {}} disabled={true} />
                            </td>
                            <td className="monaco-size">
                              <Input
                                containerClass="size-in-inches"
                                value={item?.size?.sizeInInches + ' “'}
                                onInput={val => {}}
                                disabled={true}
                              />
                              <Input containerClass="size-in-cm" value={item?.size?.size} onInput={val => {}} disabled={true} />
                            </td>
                            <td width={isLaptop ? '7%' : 'unset'}>
                              {color.length > 0 && (
                                <DropDown2
                                  className="monaco-color"
                                  options={color}
                                  value={statesContainer[index]?.color}
                                  onChange={val => {
                                    changeStatesContainer(index, val, 'color')
                                  }}
                                />
                              )}
                            </td>
                            <td className="monaco-count">
                              <Input
                                value={statesContainer[index]?.number}
                                onInput={val => {
                                  if (val.currentTarget.value === '' || isNaN(parseInt(val.currentTarget.value))) {
                                    changeStatesContainer(index, 0, 'number')
                                  } else {
                                    changeStatesContainer(index, parseInt(val.currentTarget.value), 'number')
                                  }
                                }}
                              />
                            </td>
                            {/* monaco price */}
                            <td className="monaco-price" width="5%">
                              <div className="d-flex">
                                <Input
                                  value={statesContainer[index]?.price}
                                  className="price"
                                  inputStyles={{ backgroundColor: statesContainer[index]?.savedPrice ? '#ccc' : '#fff' }}
                                  onInput={val => {
                                    if (val.currentTarget.value === '' || isNaN(parseInt(val.currentTarget.value))) {
                                      pieces.length === 1 ? changeMaster(0, 'price') : changeStatesContainer(index, 0, 'price')
                                    } else {
                                      pieces.length === 1
                                        ? changeMaster(val.currentTarget.value, 'price')
                                        : changeStatesContainer(index, val.currentTarget.value, 'price')
                                    }
                                  }}
                                />
                                <Icon
                                  name="calculator"
                                  size={15}
                                  onClick={() =>
                                    setPurityModalInfo(perv => ({
                                      ...perv,
                                      isModalOpen: true,
                                      ContainerIndex: index,
                                      purity: statesContainer[index]?.purity,
                                      isVariant: false,
                                    }))
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <Textarea
                                className="explain"
                                value={statesContainer[index]?.description}
                                onInput={val => changeStatesContainer(index, val.currentTarget.value, 'description')}
                              />
                            </td>
                          </tr>
                          {variants?.length > 0 &&
                            variants.map((v: any, itemIndex: number) => {
                              if (v?.id === statesContainer[index]?.id) {
                                return (
                                  <tr key={itemIndex} className="monaco-row">
                                    <td>
                                      <div className="image">
                                        <Icon
                                          name="minus_with_border_Green"
                                          size={20}
                                          style={{ marginRight: '8px', height: '20px !important', width: '20px !important' }}
                                          onClick={() => {
                                            onDeleteVariant(itemIndex, variants)
                                            setValueChangeKey((prev: any) => (prev = prev + 1))
                                          }}
                                        />
                                        <span className="name p-1 monaco-stone-name">{typeLocalizer(v?.type)}</span>
                                      </div>
                                    </td>
                                    <td className="monaco-width">
                                      <Input value={v?.width?.width} onInput={val => {}} disabled={true} />
                                    </td>
                                    <td className="monaco-size">
                                      <Input
                                        containerClass="size-in-inches"
                                        value={v?.size?.sizeInInches + ' “'}
                                        onInput={val => {}}
                                        disabled={true}
                                      />
                                      <Input containerClass="size-in-cm" value={v?.size?.size} onInput={val => {}} disabled={true} />
                                    </td>
                                    <td>
                                      {color.length > 0 && (
                                        <DropDown2
                                          key={valueChangeKey}
                                          className="monaco-color"
                                          options={color}
                                          value={v?.color}
                                          onChange={val => onChangeVariantValues(val, itemIndex, 'color')}
                                        />
                                      )}
                                    </td>
                                    <td className="monaco-count">
                                      <Input
                                        value={v?.number}
                                        onInput={(val: any) => {
                                          setValueChangeKey((prev: any) => (prev = prev + 1))
                                          onChangeVariantValues(parseInt(val.currentTarget.value), itemIndex, 'number')
                                        }}
                                      />
                                    </td>
                                    {/* monaco variant price */}
                                    <td className="monaco-price" width="5%">
                                      <div className="d-flex">
                                        <Input
                                          value={variants[itemIndex]?.price}
                                          className="price"
                                          inputStyles={{ backgroundColor: variants[itemIndex]?.savedPrice ? '#ccc' : '#fff' }}
                                          onInput={val => {
                                            onChangeVariantValues(parseInt(val.currentTarget.value), itemIndex, 'price')
                                          }}
                                        />
                                        <Icon
                                          name="calculator"
                                          size={15}
                                          onClick={() =>
                                            setPurityModalInfo(perv => ({
                                              ...perv,
                                              isModalOpen: true,
                                              ContainerIndex: itemIndex,
                                              purity: variants[itemIndex].purity,
                                              isVariant: true,
                                            }))
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <Textarea
                                        className="explain"
                                        value={v?.description}
                                        onInput={(val: any) => onChangeVariantValues(val.currentTarget.value, itemIndex, 'description')}
                                      />
                                    </td>
                                  </tr>
                                )
                              }
                            })}
                        </>
                      )
                    })
                  : pieces.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>
                              <div className="image">
                                <Icon
                                  name="plus_with_border_Green"
                                  size={20}
                                  style={{ marginRight: '8px', height: '20px !important', width: '20px !important' }}
                                  onClick={() => onCreateVariant(statesContainer[index], item)}
                                />
                                <img
                                  src={imageAddresser(item.image, true)}
                                  onClick={() => {
                                    setImageUrlForPopup(item.image)
                                    setShowModal(perval => !perval)
                                  }}
                                  alt=""
                                />
                                <span className="name p-1">{PIECES_TR[PIECES_EN.indexOf(item.name as PIECES_EN)]}</span>
                              </div>
                            </td>
                            <td>
                              <DropDown2
                                className="DD"
                                options={item.sizes}
                                disabled={item.sizes.length === 0}
                                value={statesContainer[index]?.size}
                                onChange={val => changeStatesContainer(index, val, 'size')}
                              />
                            </td>
                            <td>
                              {color.length > 0 && (
                                <DropDown2
                                  className="DD"
                                  options={color}
                                  value={statesContainer[index]?.color}
                                  onChange={val => changeStatesContainer(index, val, 'color')}
                                />
                              )}
                            </td>

                            {/* Adet */}
                            <td>
                              <Lib.C.DetailNumbers
                                inputStyle={{
                                  color: statesContainer[index]?.multiple
                                    ? +statesContainer[index]?.number % statesContainer[index]?.multiple! !== 0
                                      ? 'red'
                                      : '#000'
                                    : '#000',
                                }}
                                onKeyPress={e => {
                                  if (e.key === 'Enter') {
                                    if (
                                      statesContainer[index]?.multiple &&
                                      +statesContainer[index]?.number % statesContainer[index]?.multiple! !== 0 &&
                                      !isNaN(statesContainer[index]?.number)
                                    ) {
                                      swal({
                                        text: `${statesContainer[index]?.multiple}'in katlarını girmelisiniz`,
                                        icon: 'error',
                                      })
                                    }
                                  }
                                }}
                                onBlur={e => {
                                  if (
                                    statesContainer[index]?.multiple &&
                                    +statesContainer[index]?.number % statesContainer[index]?.multiple! !== 0 &&
                                    !isNaN(statesContainer[index]?.number)
                                  ) {
                                    swal({
                                      text: `${statesContainer[index]?.multiple}'in katlarını girmelisiniz`,
                                      icon: 'error',
                                    }).then(() => e.target.focus())
                                  }
                                }}
                                defaultValue={statesContainer[index]?.number}
                                onChange={(val: any) => {
                                  changeStatesContainer(index, val, 'number')
                                }}
                              />
                            </td>
                            {/* gram */}
                            <td>
                              <div className="d-flex justify-content-center align-items-center" style={{ height: '43px' }}>
                                {!isNaN(statesContainer[index]?.number * item.weight!)
                                  ? (statesContainer[index]?.number * item.weight!).toFixed(2)
                                  : 0}{' '}
                              </div>
                            </td>
                            {/* fiyat */}

                            <td
                              style={{
                                color:
                                  +statesContainer[0]?.minMaxPrice.split('_')[0] > +statesContainer[index]?.price ||
                                  +statesContainer[index]?.price > +statesContainer[0]?.minMaxPrice.split('_')[1]
                                    ? 'red'
                                    : '#000',
                              }}
                            >
                              <div className="d-flex">
                                <Input
                                  value={statesContainer[index]?.price}
                                  className="price"
                                  inputStyles={{ backgroundColor: statesContainer[index]?.savedPrice ? '#ccc' : '#fff' }}
                                  onInput={val => {
                                    pieces.length === 1
                                      ? changeMaster(val.currentTarget.value, 'price')
                                      : changeStatesContainer(index, val.currentTarget.value, 'price')
                                  }}
                                />
                                <Icon
                                  name="calculator"
                                  size={15}
                                  style={{ marginTop: 4, marginLeft: 3, cursor: 'pointer' }}
                                  onClick={() =>
                                    setPurityModalInfo(perv => ({
                                      ...perv,
                                      isModalOpen: true,
                                      ContainerIndex: index,
                                      purity: statesContainer[index]?.purity,
                                      isVariant: false,
                                    }))
                                  }
                                />
                              </div>
                            </td>
                            {/* mine */}
                            <td style={{ verticalAlign: 'middle' }}>
                              <DropDown2
                                className="DD"
                                options={enamel}
                                mode="multiple"
                                value={statesContainer[index]?.enamel}
                                onChange={val => changeStatesContainer(index, val, 'enamel')}
                                disabled={!statesContainer[index]?.canHasEnamel}
                              />
                            </td>
                            <td>
                              <Textarea
                                className="explain"
                                value={statesContainer[index]?.description}
                                onInput={val => changeStatesContainer(index, val.currentTarget.value, 'description')}
                              />
                            </td>
                          </tr>
                          {variants?.length > 0 &&
                            variants.map((v: any, itemIndex: number) => {
                              if (v.id === statesContainer[index]?.id) {
                                return (
                                  <tr>
                                    <td>
                                      <div className="image">
                                        <Icon
                                          name="minus_with_border_Green"
                                          size={20}
                                          style={{ marginRight: '8px' }}
                                          onClick={() => {
                                            onDeleteVariant(itemIndex, variants)
                                            setValueChangeKey((prev: any) => (prev = prev + 1))
                                          }}
                                        />
                                        <img
                                          src={v.image}
                                          onClick={() => {
                                            setImageUrlForPopup(v.image)
                                            setShowModal(perval => !perval)
                                          }}
                                          alt=""
                                        />
                                        <span className="name p-1">{PIECES_TR[PIECES_EN.indexOf(v.name as PIECES_EN)]}</span>
                                      </div>
                                    </td>
                                    <td>
                                      <DropDown2
                                        key={valueChangeKey}
                                        className="DD"
                                        options={v?.sizes}
                                        disabled={v?.sizes.length === 0}
                                        value={v?.size}
                                        onChange={val => {
                                          onChangeVariantValues(val, itemIndex, 'size')
                                        }}
                                      />
                                    </td>
                                    <td>
                                      {color.length > 0 && (
                                        <DropDown2
                                          key={valueChangeKey}
                                          className="DD"
                                          options={color}
                                          value={v?.color}
                                          onChange={val => onChangeVariantValues(val, itemIndex, 'color')}
                                        />
                                      )}
                                    </td>

                                    <td>
                                      <Lib.C.DetailNumbers
                                        inputStyle={{
                                          color: v?.multiple ? (+v?.number % v?.multiple! !== 0 ? 'red' : '#000') : '#000',
                                        }}
                                        onKeyPress={e => {
                                          if (e.key === 'Enter') {
                                            if (
                                              statesContainer[index]?.multiple &&
                                              +statesContainer[index]?.number % statesContainer[index]?.multiple! !== 0 &&
                                              !isNaN(statesContainer[index]?.number)
                                            ) {
                                              swal({
                                                text: `${statesContainer[index]?.multiple}'in katlarını girmelisiniz`,
                                                icon: 'error',
                                              })
                                            }
                                          }
                                        }}
                                        onBlur={e => {
                                          if (v?.multiple && +v?.number % v?.multiple! !== 0 && !isNaN(v?.number)) {
                                            swal({
                                              text: `${v?.multiple}'in katlarını girmelisiniz`,
                                              icon: 'error',
                                            }).then(() => e.target.focus())
                                          }
                                        }}
                                        defaultValue={v?.number}
                                        onChange={(val: any) => {
                                          setValueChangeKey((prev: any) => (prev = prev + 1))
                                          onChangeVariantValues(val, itemIndex, 'number')
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-center align-items-center" style={{ height: '43px' }}>
                                        {!isNaN(v?.number * item.weight!) ? (v?.number * item.weight!).toFixed(2) : 0}{' '}
                                      </div>
                                    </td>
                                    <td
                                      style={{
                                        color:
                                          +variants[0]?.minMaxPrice.split('_')[0] > +variants[itemIndex]?.price ||
                                          +variants[itemIndex]?.price > +variants[0]?.minMaxPrice.split('_')[1]
                                            ? 'red'
                                            : '#000',
                                      }}
                                    >
                                      <div className="d-flex">
                                        <Input
                                          value={variants[itemIndex]?.price}
                                          inputStyles={{ backgroundColor: variants[itemIndex]?.savedPrice ? '#ccc' : '#fff' }}
                                          className="price"
                                          onInput={val => {
                                            onChangeVariantValues(val.currentTarget.value, itemIndex, 'price')
                                          }}
                                        />
                                        <Icon
                                          name="calculator"
                                          size={15}
                                          style={{ marginTop: 4, marginLeft: 3, cursor: 'pointer' }}
                                          onClick={() =>
                                            setPurityModalInfo(perv => ({
                                              ...perv,
                                              isModalOpen: true,
                                              ContainerIndex: itemIndex,
                                              purity: variants[itemIndex].purity,
                                              isVariant: true,
                                            }))
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      <DropDown2
                                        className="DD"
                                        options={enamel}
                                        mode="multiple"
                                        value={v?.ename}
                                        onChange={val => onChangeVariantValues(val, itemIndex, 'enamel')}
                                        disabled={!statesContainer[index]?.canHasEnamel}
                                      />
                                    </td>
                                    <td>
                                      <Textarea
                                        className="explain"
                                        value={v?.description}
                                        onInput={(val: any) => onChangeVariantValues(val.currentTarget.value, itemIndex, 'description')}
                                      />
                                    </td>
                                  </tr>
                                )
                              }
                            })}
                        </>
                      )
                    })}
              </tbody>
            </table>
          </div>
        </Lib.S.modal>
      </Modal>
    </>
  )
}

export const DetailNumbers: FC<Lib.T.DetailNumbersProps> = ({ defaultValue, onChange, inputStyle, onKeyPress, onBlur }): JSX.Element => {
  const { NUMBERS, getValueFromInput, getValueFromItems, number } = Lib.H.useDetailNumbers({ defaultValue, onChange })

  return (
    <>
      <Lib.S.detailNumbers>
        <div className="withInput">
          <input
            type="number"
            onFocus={e =>
              e.target.addEventListener(
                'wheel',
                function (e) {
                  e.preventDefault()
                },
                { passive: false },
              )
            }
            value={number}
            className="noArrow"
            onInput={getValueFromInput}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
          />
        </div>
      </Lib.S.detailNumbers>
    </>
  )
}
