import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import swal from 'sweetalert'
import { DOM } from 'common/helpers/dom'

export class AutomationDrinks {
  accessToken: string
  endpoint = 'automation/restaurant/drink/manage'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      AutomationDrinks.dontAccept()
    }
    if (!user!.accessToken) {
      AutomationDrinks.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async getDrinks(filters?: Lib.T.Drinks.GetListFilters): Promise<Lib.T.Drinks.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || !data.drinkOrders) {
      swal({
        text: 'Grup listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true, drinkOrders: data.drinkOrders, total: data.total }
  }

  static dontAccept() {
    throw 'not-acceptable'
  }

  static unhandled(errorMessage?: string) {
    if (errorMessage) {
      return swal({
        text: errorMessage,
        dangerMode: true,
        icon: 'error',
      })
    }
  }
}
