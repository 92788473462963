import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Serie } from '@nivo/line'
import { ComputedDatum } from '@nivo/pie'
import { ModalProps } from 'antd'
import { ImageDetail } from 'common/components/ui-kit/image-list-slider/lib/typing'
import { generateRandomHexColor } from 'common/helpers/randomHexColorGenerator'
import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { CustomerService, OptionService, UserService } from 'common/services'
import { Report, SubCategoryWeight } from 'common/services/lib/types/report'
import { ReportService } from 'common/services/report'
import {
  AreaWeightChartType,
  CategoryWeightChartType,
  ChartMode,
  AreaChartMode,
  HeaderTabEnum,
  HeaderTabType,
  Property,
} from './types'
import { AreaService } from 'common/services/area'
import _ from 'lodash'
import { monthMapping } from 'common/constants/months'
import { monthDict } from 'common/typing/months'

export const useChart = () => {
  // utils
  const { setLoader, loader } = useLoader()

  // chart weight states
  const [tab, setTab] = useState<{ label: string; value: Property }>({ label: 'Hafta', value: 'weekly' })
  const [chartProps, setChartProps] = useState<Serie[]>([])
  // common page states
  const [headerActiveTab, setHeaderActiveTab] = useState(HeaderTabEnum.AREA)
  const headerTabs = useMemo(() => [HeaderTabEnum.AREA,HeaderTabEnum.URETIM, HeaderTabEnum.KATEGORI, HeaderTabEnum.MUSTERI], [])

  //
  const isMounted = useRef(false)

  // customer table  states
  const [customerTableHeader, setCustomerTableHeader] = useState([
    { name: 'İsim', isASC: true, sortBy: 'company' },
    { name: 'Ağırlık (Kg)', isASC: true, sortBy: 'weight' },
    { name: 'Statu', isASC: true, sortBy: 'customerRank' },
    { name: 'Verimlılık ($/g)', isASC: true, sortBy: 'orderEfficiency' },
    { name: 'Getiri ($)', isASC: true, sortBy: 'stoneProfit' },
  ])
  const dateDropDownData = useMemo(
    () => [
      { id: 'monthly', title: 'Son 1 ay' },
      { id: 'quarterly', title: 'Son 3 ay' },
      { id: 'semiannually', title: 'Son 6 ay' },
      { id: 'yearly', title: 'Son 1 yıl' },
    ],
    [],
  )
  const [customerTableData, setCustomerTableData] = useState<Report[] | null>()
  const [countryDropdown, setCountryDropdown] = useState<{ id: number | string; title: string }[] | undefined>([])
  const [areaDropdown, setAreaDropdown] = useState<{ id: number | string; title: string }[] | undefined>([])
  const [countryId, setCountryId] = useState<number | undefined>()
  const [startDate, setStartDate] = useState<string | undefined>()
  const [endDate, setEndDate] = useState<string | undefined>()
  const [paginate, setPaginate] = useState({ page: 1, limit: 90 })
  const [totalData, setTotalData] = useState(0)

  // kategori tab states
  const [chartMode, setChartMode] = useState<ChartMode>(ChartMode.CATEGORY)
  const [areaChartMode, setAreaChartMode] = useState<AreaChartMode>(AreaChartMode.AREA)
  const [pieChartHeader, setPieChartHeader] = useState('Kategoriler')
  const [areaPieChartHeader, setAreaPieChartHeader] = useState('Bölge')
  const [customerDropdown, setCustomerDropdown] = useState<{ id: number | string; title: string }[] | undefined>([])
  const [karatDropdown, setKaratDropdown] = useState<{ id: number | string; title: string }[] | undefined>([])
  const [customerIds, setCustomerIds] = useState<string[] | undefined>()
  const [countriesId, setCountriesId] = useState<string[] | undefined>()
  const [areasId, setAreasId] = useState<number | undefined>()
  const [karatIds, setKaratIds] = useState<string[] | undefined>()
  const currentCategoryId = useRef(0)
  const currentAreaId = useRef(0)
  const currentSubCategoryId = useRef(0)
  const currentCountryId = useRef(0)
  const [showImageModal, setShowImageModal] = useState(false)
  const [modalForAllImages, setModalForAllImages] = useState<ImageDetail[]>([])
  const [imageActiveIndex, setImageActiveIndex] = useState(0)
  const [subCategoryTotalWeightState, setSubCategoryTotalWeightState] = useState<string | undefined>()
  const [widthOptions, setWidthOptions] = useState<
    | {
        title: string
        id: string | number
      }[]
    | undefined
  >()
  const [width, setWidth] = useState<number[] | undefined>()
  const [productSubCategoryState, setProductSubCategoryState] = useState<number | undefined>()

  const [customersLoading, setCustomersLoading] = useState(false)
  const [countriesLoading, setCountriesLoading] = useState(false)
  const [areasLoading, setAreasLoading] = useState(false)
  const [areaSingleLoading, setAreaSingleLoading] = useState(false)
  const [karatsLoading, setKaratsLoading] = useState(false)

  const [categoryWeight, setCategoryWeight] = useState<CategoryWeightChartType[] | undefined>()
  const [areaWeight, setAreaWeight] = useState<AreaWeightChartType[] | undefined>()
  const [subCategoryTable, setSubCategoryTable] = useState<SubCategoryWeight[] | undefined>([])
  const [weightSortIsAsc, setWeightSortIsAsc] = useState(true)

  // category tab common functions
  const fetchCategoryWeight = async (productCategoryId?: number) => {
    const service = new ReportService()
    try {
      setLoader(true)
      const { report } = await service.categoryWeight({
        ...(productCategoryId ? { productCategory: productCategoryId } : undefined),
        countries: countriesId,
        areaId: areasId,
        customers: customerIds,
        karats: karatIds,
        start: startDate,
        end: endDate,
      })

      if (report?.length) {
        const transformedData: CategoryWeightChartType[] = report
          ?.map(rep => ({
            id: rep.id.toString(),
            color: generateRandomHexColor(),
            label: `${rep.productCategory || rep.productSubCategory} ${rep.percentage}% - ${rep.weight} kg`,
            value: rep.weight,
            isMonacoCategory: rep.isMonacoCategory,
            percentage: rep.percentage,
            categoryName: rep.productCategory,
            subCategoryName: rep.productSubCategory,
          }))
          .sort((a, b) => {
            return b.value - a.value
          })
        setCategoryWeight(transformedData)
        setLoader(false)
      } else {
        setCategoryWeight(undefined)
        setLoader(false)
      }
    } catch (e) {
      setLoader(false)
    }
  }

  const fetchSubCategoryWeight = async (productSubCategoryId?: number, widthIds?: number[]) => {
    const service = new ReportService()

    try {
      setLoader(true)
      const { report } = await service.subCategoryWeight({
        productSubCategory: productSubCategoryId,
        sort: 'DESC',
        countries: countriesId,
        karats: karatIds,
        customers: customerIds,
        widthIds: widthIds,
        areaId: areasId,
        start: startDate,
        end: endDate,
      })

      // calc weight
      const subCategoryTotalWeight = report?.reduce((total, item) => total + item.weight, 0).toFixed(2)
      setSubCategoryTotalWeightState(subCategoryTotalWeight)
      const dataForImageModal: ImageDetail[] = report?.map(rep => ({
        image: rep.productImage as string,
        productImageLink: rep.productImageLink as string,
        productImageThumbnail: rep.productImageThumbnail as string,
        productCode: rep.productCode,
        weight: rep.weight.toFixed(2).toString(),
        chainImage: rep.subCategoryTypeImage,
        lockImage: rep.lockImage,
      }))!

      if (dataForImageModal.length) {
        setModalForAllImages(perv => [...perv, ...dataForImageModal])
      }

      setSubCategoryTable(report!)
      setLoader(false)

      setLoader(false)
    } catch (e) {
      setLoader(false)
    }
  }

  const fetchAreaWeight = async (areaId?: number, countryId?: number) => {
    const service = new ReportService()
    try {
      setLoader(true)
      const { report } = await service.areaWeight({
        country: countryId ? countryId : undefined,
        areaId: areaId ? areaId : undefined,
        karats: karatIds,
        start: startDate,
        end: endDate,
      })

      if (report?.length) {
        const transformedData: AreaWeightChartType[] = report
          ?.map(rep => ({
            id: rep.id ? rep.id.toString() : '',
            color: generateRandomHexColor(),
            label: `${rep.code || rep.country || rep.username} ${rep.percentage}% - ${rep.weight} kg`,
            code: rep.code,
            value: rep.weight,
            percentage: rep.percentage,
            country: rep?.country,
            username: rep?.username
          }))
          .sort((a, b) => {
            return b.value - a.value
          })
        setAreaWeight(transformedData)
        setLoader(false)
      } else {
        setAreaWeight(undefined)
        setLoader(false)
      }
    } catch (e) {
      setLoader(false)
    }
  }

  const fetchCustomers = async (filterCountry?: number[]) => {
    const service = new CustomerService()
    try {
      setCustomersLoading(true)
      const { customers } = await service.getList({ country: filterCountry, limit: 9999999 })

      const dataForDropdown = customers?.map(customer => ({ id: customer.id, title: customer.company as string }))

      setCustomerDropdown(dataForDropdown)
      setCustomersLoading(false)
    } catch (e) {
      setCustomersLoading(false)
    }
  }

  const fetchKarats = async () => {
    const service = new OptionService({ type: 'karat' })
    try {
      setKaratsLoading(true)
      const { options } = await service.read()

      const dataForDropdown = options?.map(karat => ({ id: karat.id, title: karat.karat as string }))
      setKaratDropdown(dataForDropdown)
      setKaratsLoading(false)
    } catch (error) {
      setKaratsLoading(false)
    }
  }

  const fetchKaratsByUser = async (customers: string[]) => {
    const service = new UserService()

    setKaratsLoading(true)
    const { success, karats } = await service.findKaratsForMultipleCustomers({ customers })
    if (success && karats?.length) {
      const transformedKarats = karats.map(karat => {
        return { id: karat.id, title: karat.karat }
      })

      setKaratDropdown(transformedKarats)
      setKaratsLoading(false)
    }
    setKaratsLoading(false)
  }

  const fetchWidths = async () => {
    const service = new OptionService({ type: 'width' })
    try {
      setLoader(true)
      const { success, options } = await service.read()

      const transform = options?.map(opt => ({ id: opt.id as number, title: opt.width?.toString()! }))

      setWidthOptions(transform)

      setLoader(false)
    } catch (e) {
      setLoader(false)
    }
  }

  const scrollTo = () => {
    const element = document.getElementById('table')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const onWidthChange = (e: any) => {
    setWidth(e)
  }

  const handleWidthFilter = (e: any) => {
    fetchSubCategoryWeight(productSubCategoryState, width)
  }

  const handleAllCategoriesOnClick = async (e: ComputedDatum<CategoryWeightChartType>) => {
    if (chartMode === ChartMode.SUBCATEGORY || chartMode === ChartMode.SUBCATEGORY_TABLE) {
      setModalForAllImages([])
      setProductSubCategoryState(e.id as number)
      await fetchSubCategoryWeight(parseInt(e.id as string))
      scrollTo()
      currentSubCategoryId.current = parseInt(e.id as string)
      setChartMode(ChartMode.SUBCATEGORY_TABLE)
      // setPieChartHeader(perv => (perv === 'MonacoChain' ? 'MonacoChain' : perv))
    } else if (chartMode === ChartMode.CATEGORY) {
      setPieChartHeader(e.data.isMonacoCategory ? 'MonacoChain' : e.data.categoryName!)
      setChartMode(ChartMode.SUBCATEGORY)
      currentCategoryId.current = parseInt(e.id as string)
      await fetchCategoryWeight(parseInt(e.id as string))
    }
  }

  const handleAllAreaOnClick = async (e: ComputedDatum<AreaWeightChartType>) => {
    if (areaChartMode === AreaChartMode.COUNTRY) {
      //setProductSubCategoryState(e.id as number)
      await fetchAreaWeight(0,parseInt(e.id as string))
      scrollTo()
      currentCountryId.current = parseInt(e.id as string)
      setAreaChartMode(AreaChartMode.CUSTOMER)
      setAreaPieChartHeader(e.data.country!)
    } else if (areaChartMode === AreaChartMode.AREA) {
      setAreaPieChartHeader(e.data.code!)
      setAreaChartMode(AreaChartMode.COUNTRY)
      currentAreaId.current = parseInt(e.id as string)
      await fetchAreaWeight(parseInt(e.id as string), 0)
    }
  }

  const handleOpenImageModal = (index: number) => {
    setShowImageModal(true)
    setImageActiveIndex(index)
  }

  const handlePieChartBackwardButton = async () => {
    await fetchCategoryWeight()
    setPieChartHeader('Kategoriler')
    setChartMode(ChartMode.CATEGORY)
    currentCategoryId.current = 0
    currentSubCategoryId.current = 0
    setModalForAllImages([])
  }

  const handleAreaPieChartBackwardButton = async () => {
    await fetchAreaWeight()
    setAreaPieChartHeader('Bölge')
    setAreaChartMode(AreaChartMode.AREA)
    currentAreaId.current = 0
    currentCountryId.current = 0
  }

  const handlePieChartFilter = async () => {
    if (chartMode === ChartMode.CATEGORY) {
      fetchCategoryWeight(currentCategoryId.current ?? undefined)
    } else {
      if (currentSubCategoryId.current) {
        fetchSubCategoryWeight(currentSubCategoryId.current ?? undefined)
        fetchCategoryWeight(currentCategoryId.current ?? undefined)
      } else {
        fetchCategoryWeight(currentCategoryId.current ?? undefined)
      }
    }
  }

  const handleAreaPieChartFilter = async () => {
    if (areaChartMode === AreaChartMode.AREA) {
      fetchAreaWeight(currentAreaId.current ?? undefined, 0)
    } else {
      if (currentCountryId.current) {
        fetchAreaWeight(0, currentCountryId.current ?? undefined)
      } else {
        fetchAreaWeight(currentAreaId.current ?? undefined, 0)
      }
    }
  }

  const handleClearAreaPieChartFilter = async () => {
    currentAreaId.current = 0
    currentCountryId.current = 0
    setKaratIds([])
    setStartDate(undefined)
    setEndDate(undefined)
    fetchKarats()
  }

  const handleClearPieChartFilter = async () => {
    setCountryId(undefined)
    setCountriesId([])
    setAreasId(undefined)
    setKaratIds([])
    setStartDate(undefined)
    setEndDate(undefined)
    setCustomerIds([])
    fetchCountries()
    fetchKarats()
    fetchCustomers()
  }

  const handleCustomerTabCountryChange = async (value: string[]) => {
    const convertToNumberArray = value.map((str: string) => parseInt(str))
    setCountriesId(value)
    await fetchCustomers(convertToNumberArray)
    setKaratIds(undefined)
    setCustomerIds(undefined)
  }

  const handleCustomerTabAreaChange = async (value: number) => {
    setAreasId(value)
    await fetchSingleArea(value)
    setCountriesId(undefined)
    setCustomerIds(undefined)
    setKaratIds(undefined)
  }

  // customer table common functions

  const fetchCountries = async () => {
    const service = new OptionService({ type: 'country' })
    try {
      setCountriesLoading(true)
      const { options } = await service.read()

      const dataForDropdown = options?.map(country => ({ id: country.id, title: country.country as string }))
      setCountryDropdown(dataForDropdown)
      setCountriesLoading(false)
    } catch (error) {
      setCountriesLoading(false)
    }
  }

  const fetchAreas = async () => {
    const service = new AreaService()
    try {
      setAreasLoading(true)
      const {data} = await service.readArea()

      const dataForDropdown = data?.map((area:any) => ({ id: area.id, title: area.code as string }))
      setAreaDropdown(dataForDropdown)
      setAreasLoading(false)
    } catch (error) {
      setAreasLoading(false)
    }
  }

  const fetchSingleArea = async (areaId: number) => {
    setCountryDropdown([])
    const service = new AreaService()
    try {
      setAreaSingleLoading(true)
      const {data} = await service.readSingleArea(areaId)
      const dataForDropdown = data?.countries?.map((country:any) => ({ id: country.id, title: country.country as string }))
      setCountryDropdown(dataForDropdown)
      setAreaSingleLoading(false)
    } catch (error) {
      setAreaSingleLoading(false)
    }
  }

  const fetchCustomerTable = useCallback(async () => {
    const service = new ReportService()
    try {
      const { report } = await service.customerWeight({
        country: countryId,
        start: startDate ?? undefined,
        end: endDate ?? undefined,
        page: paginate.page,
        limit: paginate.limit,
      })
      setTotalData(10)
      if (paginate.page > 1) {
        setCustomerTableData(perv => {
          if (perv?.length && report?.length) {
            return [...perv, ...report]
          }
        })
      } else {
        setCustomerTableData(report)
      }
    } catch (e) {}
  }, [countryId, startDate, endDate, paginate.page])

  const handlePagination = () => {
    setPaginate(perv => ({ ...perv, page: perv.page + 1 }))
  }

  useEffect(() => {
    if (isMounted.current) {
      fetchCustomerTable()
    } else {
      isMounted.current = true
    }
  }, [paginate])

  const onCountryDropDownChange = (value: number) => {
    setCountryId(value)
  }

  // const onDateDropDownChange = (value: string) => {
  //   setStartDate(value)
  //   setEndDate(value)
  // }

  const onCustomerDropDownChange = async (value: string[]) => {
    setCustomerIds(value)
    await fetchKaratsByUser(value)
    setKaratIds(undefined)
  }

  const onKaratDropDownChange = (value: string[]) => {
    setKaratIds(value)
  }

  const handleFilter = () => {
    fetchCustomerTable()
  }
  const handleClearFilter = () => {
    fetchCustomerTable()
  }

  const handleClearWidthFilter = () => {
    setWidth(undefined)
    fetchSubCategoryWeight(productSubCategoryState, undefined)
  }

  const handleSort = async (sortBy: string, sort: boolean, mode: HeaderTabEnum) => {
    if (mode === HeaderTabEnum.KATEGORI) {
      let sortedData = subCategoryTable?.length ? [...subCategoryTable] : []
      sortedData.sort((a, b) => {
        if (sort) {
          // @ts-ignore
          return a[sortBy] - b[sortBy]
        } else {
          // @ts-ignore
          return b[sortBy] - a[sortBy]
        }
      })
      return setSubCategoryTable(sortedData)
    } else {
      let sortedData = customerTableData?.length ? [...customerTableData] : []
      sortedData.sort((a, b) => {
        if (sort) {
          return a[sortBy] - b[sortBy]
        } else {
          return b[sortBy] - a[sortBy]
        }
      })
      return setCustomerTableData(sortedData)
    }
  }

  const handleToggleSort = (headerName: string) => {
    setCustomerTableHeader(perv => {
      return perv.map(p => {
        if (p.name === headerName) {
          p.isASC = !p.isASC
        }
        return p
      })
    })
  }
  // common page functions
  const handleChangeHeaderActiveTab = async (tabMenu: HeaderTabType) => {
    setHeaderActiveTab(tabMenu)

    if (tabMenu === HeaderTabEnum.MUSTERI) {
      setStartDate(undefined)
      setEndDate(undefined)
      setCountryId(undefined)
      const countries = fetchCountries()
      const areas = fetchAreas()
      const customerTable = fetchCustomerTable()
      setLoader(true)
      await countries
      await areas
      await customerTable
      setLoader(false)
    } else if (tabMenu === HeaderTabEnum.KATEGORI) {
      setStartDate(undefined)
      setEndDate(undefined)
      setCountryId(undefined)
      const categoryWeight = fetchCategoryWeight()
      const countries = fetchCountries()
      const areas = fetchAreas()
      const customers = fetchCustomers()
      const karats = fetchKarats()
      const widths = fetchWidths()
      await categoryWeight
      await countries
      await areas
      await customers
      await karats
      await widths
    } else if (tabMenu === HeaderTabEnum.URETIM) {
      let mappedReport: any[] = []

      //if (headerActiveTab !== HeaderTabEnum.URETIM) return

      setLoader(true)
      const report = await fetchReports()

      if (_.isEmpty(report)) {
        setLoader(false)
        return
      }

      switch (tab.value) {
        case 'weekly':
          mappedReport = report.map((rep: any, index: number) => {
            const [year, month, dateRange] = Object.keys(rep)[0].split(' ')
            const [startDay, endDay] = dateRange.split('-')
            const startDate = new Date(`${month} ${startDay}, ${year}`)
            const endDate = new Date(`${month} ${endDay}, ${year}`)
            // @ts-ignore
            const monthLabel = monthMapping[month]
            const x = `${monthLabel} ${startDate.getDate()} - ${endDate.getDate()}`
            const y = parseInt(rep[Object.keys(rep)[0]])
            return { week: x, Ağırlık: y }
          })

          break

        case 'monthly':
          for (let i = 0; i < report.length; i++) {
            const obj = report[i]
            const monthName = Object.keys(obj)[0]
            const turkishMonthName = monthDict[monthName] || monthName
            obj[turkishMonthName] = obj[monthName]
            delete obj[monthName]
          }

          mappedReport = report.map((rep: any, index: number) => {
            const x = Object.keys(rep)[0]
            const y = rep[x]
            return { week: x, Ağırlık: y }
          })

          break

        case 'yearly':
          mappedReport = report.map((rep: any, index: number) => {
            const x = Object.keys(rep)[0]
            const y = rep[x]
            return { week: x, Ağırlık: y }
          })

          break

        default:
          break
      }
      setChartProps(mappedReport)

      setLoader(false)
    }
  }

  const chartHeaderTabs: { label: string; value: Property }[] = useMemo(
    () => [
      {
        label: 'Hafta',
        value: 'weekly',
      },

      {
        label: 'Ay',
        value: 'monthly',
      },

      {
        label: 'Yıl',
        value: 'yearly',
      },
    ],

    [],
  )

  // weight chart functions
  async function fetchReports() {
    const service = new ReportService()

    const { report, success } = await service.deliveredWeight({ period: tab.value })

    if (!success) return null

    return report
  }

  async function onChangeAreaWeightChartTabs() {
    if (headerActiveTab !== HeaderTabEnum.AREA) return
    setStartDate(undefined)
    setEndDate(undefined)
    setCountryId(undefined)
    const areaWeight = fetchAreaWeight()
    const karats = fetchKarats()
    await areaWeight
    await karats
  }

  async function onChangeWeightChartTabs() {
    let mappedReport: any[] = []

    setLoader(true)
    const report = await fetchReports()

    if (_.isEmpty(report)) {
      setLoader(false)
      return
    }

    switch (tab.value) {
      case 'weekly':
        mappedReport = report.map((rep: any, index: number) => {
          const [year, month, dateRange] = Object.keys(rep)[0].split(' ')
          const [startDay, endDay] = dateRange.split('-')
          const startDate = new Date(`${month} ${startDay}, ${year}`)
          const endDate = new Date(`${month} ${endDay}, ${year}`)
          // @ts-ignore
          const monthLabel = monthMapping[month]
          const x = `${monthLabel} ${startDate.getDate()} - ${endDate.getDate()}`
          const y = parseInt(rep[Object.keys(rep)[0]])
          return { week: x, Ağırlık: y }
        })

        break

      case 'monthly':
        for (let i = 0; i < report.length; i++) {
          const obj = report[i]
          const monthName = Object.keys(obj)[0]
          const turkishMonthName = monthDict[monthName] || monthName
          obj[turkishMonthName] = obj[monthName]
          delete obj[monthName]
        }

        mappedReport = report.map((rep: any, index: number) => {
          const x = Object.keys(rep)[0]
          const y = rep[x]
          return { week: x, Ağırlık: y }
        })

        break

      case 'yearly':
        mappedReport = report.map((rep: any, index: number) => {
          const x = Object.keys(rep)[0]
          const y = rep[x]
          return { week: x, Ağırlık: y }
        })

        break

      default:
        break
    }
    setChartProps(mappedReport)

    setLoader(false)
  }

  const imageModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: showImageModal,
    width: '50%',
    style: { top: '20px', height: '90vh' },
    bodyStyle: { padding: 0 },
    onCancel: () => setShowImageModal(false),
    className: 'noHeaderModal scrollWidthModal',
  }

  // effects
  useAsyncEffect(onChangeAreaWeightChartTabs, [headerActiveTab === HeaderTabEnum.AREA])
  useAsyncEffect(onChangeWeightChartTabs, [tab])

  return {
    chartProps,
    tab: {
      tab,
      setTab,
    },
    chartHeaderTabs,
    header: {
      headerTabs,
      handleChangeHeaderActiveTab,
      headerActiveTab,
    },
    customerTable: {
      customerTableHeader,
      fetchCountries,
      fetchAreas,
      countryDropdown,
      areaDropdown,
      onCountryDropDownChange,
      dateDropDownData,
      //onDateDropDownChange,
      handleFilter,
      setPaginate,
      handleClearFilter,
      startDate,
      endDate,
      countryId,
      customerTableData,
      setCustomerTableHeader,
      handleToggleSort,
      handleSort,
      imageModalProps,
      setCountryId,
      totalData,
      setStartDate,
      setEndDate,
      handlePagination,
      paginate,
    },
    categoryTab: {
      width,
      customerDropdown,
      karatIds,
      onWidthChange,
      handleWidthFilter,
      handleClearWidthFilter,
      fetchSubCategoryWeight,
      karatDropdown,
      subCategoryTotalWeightState,
      handleCustomerTabCountryChange,
      handleCustomerTabAreaChange,
      countriesId,
      setCountriesId,
      setAreasId,
      areasId,
      countryDropdown,
      areaDropdown,
      customersLoading,
      countriesLoading,
      areasLoading,
      areaSingleLoading,
      karatsLoading,
      setCustomerId: setCustomerIds,
      customerIds,
      imageModalProps,
      karatId: karatIds,
      handleAllCategoriesOnClick,
      setKaratIds,
      onCustomerDropDownChange,
      imageActiveIndex,
      onKaratDropDownChange,
      categoryWeight,
      pieChartHeader,
      chartMode,
      handlePieChartBackwardButton,
      handlePieChartFilter,
      handleClearPieChartFilter,
      subCategoryTable,
      modalForAllImages,
      showImageModal,
      handleOpenImageModal,
      weightSortIsAsc,
      setWeightSortIsAsc,
      loader,
      widthOptions,
    },
    areaTab: {
      areaWeight,
      karatIds,
      karatsLoading,
      karatDropdown,
      setKaratIds,
      onKaratDropDownChange,
      setStartDate,
      setEndDate,
      handleAreaPieChartFilter,
      handleClearAreaPieChartFilter,
      loader,
      areaPieChartHeader,
      areaChartMode,
      handleAreaPieChartBackwardButton,
      handleAllAreaOnClick
    }
  }
}
