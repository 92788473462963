import styled from 'styled-components'
import * as Lib from '.'

export const PageWrapper = styled('div')<{ activeTab: Lib.T.TabsEnum }>`
  border-radius: 8px;
  padding: 10px;
  height: 99vh;

  .tabs {
    background-color: #fff;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: sticky;
    top: 0;
    z-index: 100;

    .search {
      display: flex;
      flex: 0.5;
      align-items: center;
      padding: 10px 0;
      width: 100%;

      .input-container {
        position: relative;
        width: 100%;

        .input {
          outline: none;
          border: none;
          width: 100%;

          background-color: #e5edec;
          padding: 7px 0 10px 40px;
          border-top-left-radius: 8px;
          /* border-radius: 8px; */
        }
      }

      .icon {
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        left: 10px;
        top: 0;
      }
    }

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 50%;
      height: 100%;
      font-weight: bold;
    }

    .tab-two {
      color: ${({ activeTab }) => (activeTab === Lib.T.TabsEnum.HISTORY_ERRORS_TAB ? '#fff' : 'var(--accent)')};
      background-color: ${({ activeTab }) => (activeTab === Lib.T.TabsEnum.HISTORY_ERRORS_TAB ? 'var(--accent)' : '#fff')};
      border-top-right-radius: 8px;
      border-bottom: ${({ activeTab }) => (activeTab === Lib.T.TabsEnum.HISTORY_ERRORS_TAB ? '1px solid var(--accent)' : 'unset')};
      flex: 1;
    }

    .tab-one {
      color: ${({ activeTab }) => (activeTab === Lib.T.TabsEnum.CURRENT_ERRORS_TAB ? '#fff' : 'var(--accent)')};
      background-color: ${({ activeTab }) => (activeTab === Lib.T.TabsEnum.CURRENT_ERRORS_TAB ? 'var(--accent)' : '#fff')};
      border-bottom: ${({ activeTab }) => (activeTab === Lib.T.TabsEnum.CURRENT_ERRORS_TAB ? '1px solid var(--accent)' : 'unset')};
      /* border-top-left-radius: 8px; */
      flex: 1;
    }
  }

  .table {
    table {
      width: 100%;
      font-weight: bold;
      font-size: 14px;

      thead {
        position: sticky;
        height: 50px;
        background-color: #eeee;
        z-index: 1000;
        top: 0;
        color: #000;
        font-weight: bold;
        tr {
          border-bottom: 1px solid #ccc;
        }
      }
      /* end of thead */

      tbody {
        tr {
          z-index: 1;
          :nth-child(even) {
            background-color: #e5edec;
          }
        }

        td {
          :first-child {
            padding-left: 5px;
          }
          :last-child {
            padding-right: 5px;
            cursor: initial;
          }
        }

        .product-image {
          .sozer-image {
            display: flex;
            align-items: center;
            gap: 2px;
            img {
              width: 40px;
              height: 40px;
              border-radius: 10px;
            }
          }
        }

        .image {
          img {
            border-radius: 10px;
            width: 40px;
            height: 40px;
            border-radius: 10px;
          }
        }

        .product-code {
          display: flex;
          gap: 5px;
          align-items: center;
        }

        .description {
          display: flex;
          align-items: center;
          gap: 10px;
          span {
            text-align: left;
            width: 100%;
            padding-right: 5px;
          }
        }
      }
    }
  }
`
export const ModalWrapper = styled.div`
  .close-btn {
    display: flex;
    justify-content: flex-end;
  }

  .body {
    display: grid;
    grid-template-columns: 1.1fr 1fr;
    gap: 20px;
  }

  .image {
    height: 500px;
    border: 1px solid #ccc;
    border-radius: 6px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 8px;
    }
  }

  .content {
    > div {
      display: grid;
      grid-template-columns: 1fr 4fr;
    }
    .title {
      font-weight: bold;
      font-size: 15px;
    }

    > span {
      text-align: left;
    }
  }
  .radios {
    .ant-radio-inner {
      border-color: var(--accent);

      ::after {
        background-color: var(--accent);
      }
    }
  }
`

export const DeleteModalContent = styled.div`
  float: left;
  width: 100%;
  height: auto;

  > p {
    margin: 0;
    float: left;
    width: 100%;
    font-size: 18px;
    text-align: center;
  }

  > div {
    float: left;
    width: 100%;
    text-align: center;
    margin: 30px 0 0 0;

    > div {
      width: 110px !important;
      float: none !important;
      display: inline-flex !important;
      padding: 0 2px;

      > div {
        min-height: 35px !important;
      }
    }
  }
`
