import { FC } from 'react'
import * as Lib from './lib'
import { Select } from 'antd'
const { Option } = Select

export const DropDown: FC<Lib.T.DropDownProps> = ({
  options,
  containerStyles,
  label,
  labelStyles,
  value,
  mode,
  setValue,
  lessBorder,
  className,
  disabled,
  compactView,
  height,
  onChange,
  required
}): JSX.Element => {
  return (
    <>
      <Lib.S.DropdownContainer
        className={className}
        style={containerStyles}
        lessBorder={lessBorder}
        compactView={compactView}
        height={height}
        hasOptions={typeof value === 'string' ? false : !!(value as string[])?.length}
      >
        <span style={labelStyles}>{label}{required && <span style={{ color: '#D32738' }}>*</span>}</span>

        <Select
          defaultValue={value}
          value={value}
          showSearch
          disabled={disabled}
          mode={mode}
          onChange={val => (setValue ? setValue(val) : onChange && onChange(val))}
        >
          {options.map((item, index) => (
            <Option value={item} key={index}>
              {item}
            </Option>
          ))}
        </Select>
      </Lib.S.DropdownContainer>
    </>
  )
}
