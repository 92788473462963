import styled from 'styled-components'

export const container = styled.div`
  .activity {
    position: absolute;
    bottom: -27px;
    > span {
      width: 77.45px;
    }
  }

  .personal {
    position: absolute;
    bottom: -55px;
  }

  .delete {
    position: absolute;
    bottom: -100px;
    right: 62px;
  }
  .switchContainer {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;

    align-items: center;
    margin-top: 5px;
    padding: 0 0 0 20px;

    > span {
      font-size: 14pt;
      margin: -2px 5px 0 0;
    }
  }

  .tableIcons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    > span {
      margin: 0 0 0 7px;
      display: flex;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
    }
  }

  .employeeAdder {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    > span {
      display: flex;
      width: 30px;
      height: 30px;
      background-color: red;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #004e421a;
    }
  }

  .td > .content {
    background-color: white !important;
  }

  input,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selection-item {
    background: transparent !important;
    border-width: 1px !important;
  }

  .ant-select-multiple .ant-select-selection-item {
    background-color: white !important;
  }

  .ant-select-selector {
  }
`

export const employeeModal = styled.div`
  padding: 0 15px;

  > .closeIcon {
    width: 100%;
    display: flex;

    > span {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
    }
  }

  > .header {
    border-bottom: 1px solid #00000024;
    font-size: 13pt;
    text-align: center;
    font-family: var(--f-se-bo);
    padding: 0 0 5px 0;
    margin: 0 0 15px 0;
  }

  .button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0 0 0;

    > div {
      width: unset;

      > .parent {
        min-height: unset;
        padding: 6px 26px;
        font-size: 13pt;
      }
    }
  }
`
export const SelectCategories = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  .wrapper {
    width: 240px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 200px;
    overflow-y: scroll;
    margin-bottom: 10px;
    
    .parent {
      background-color: var(--accent) !important;
      color: #fff;
      position: sticky;
      top: 0;
    
      .visual {
        border: 2px solid #fff !important;
      }
    }

    .cat-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      padding: 0 5px;
      border-radius: 8px;
      
      .disabled{
        color: #b5b5b5;
      }
      > span {
        flex: 2;
        font-size: 12px;
      }
      > div {
        display: flex;
        flex: 1;

        > label {
          display: flex;
          justify-content: flex-end !important;
        }
      }
    }
  }
  .wrapper::-webkit-scrollbar
    {
        width: 5px;
        height: 5px;
    }
    .wrapper::-webkit-scrollbar-track
    {
        border-radius: 10px;
        background-color: #F5F5F5;
    }
`
export const deleteModalContent = styled.div`
  float: left;
  width: 100%;
  height: auto;

  > p {
    margin: 0;
    float: left;
    width: 100%;
    font-size: 18px;
    text-align: center;
  }

  > div {
    float: left;
    width: 100%;
    text-align: center;
    margin: 30px 0 0 0;

    > div {
      width: 110px !important;
      float: none !important;
      display: inline-flex !important;
      padding: 0 2px;

      > div {
        min-height: 35px !important;
      }
    }
  }
`
