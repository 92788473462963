import { useState } from 'react'
import * as Lib from '.'

export const useImageSlider = ({ images }: Lib.T.ImageSliderProps) => {
  const [active, setActive] = useState<number>(0)

  const change = (right: boolean) => {
    if (right) {
      setActive(p => (p < images.length - 1 ? p + 1 : p))
    } else {
      setActive(p => (p > 0 ? p - 1 : p))
    }
  }

  return {
    get: {
      active,
    },
    on: {
      change,
    },
  }
}
