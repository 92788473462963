import { useDispatch, useSelector } from 'react-redux'
import { Global } from 'common/store/actions'
import { RootState } from 'common/store/root-reducer'

export const useLoader = () => {
  const { fsLoader } = useSelector((state: RootState) => state.globals)
  const dispatch = useDispatch()
  const setLoader = (payload: boolean) => dispatch(Global.fsLoader(payload))

  return {
    loader: fsLoader,
    setLoader,
  }
}
