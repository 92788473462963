import { useEffect, useState } from 'react'
import { OptionService, MediaService } from 'common/services'
import * as Lib from '.'
import { UNHANDLED } from 'common/constants/errors'
import { ListObject } from 'common/components/ui-kit/list/lib/typing'
import { useDispatch } from 'react-redux'
import { Global } from 'common/store/actions'
import { ServiceArgs } from 'common/services/lib/types/options'
import swal from 'sweetalert'

export const useProductCategory = () => {
  const [image, setImage] = useState<File | null>(null)
  const [categoryName, setCategoryName] = useState<string | null>(null)

  const [editImage, setEditImage] = useState<File | null>(null)
  const [editCategoryName, setEditCategoryName] = useState<string | null>(null)
  const [itemIdToEdit, setItemIdToEdit] = useState<number | null>(null)
  const [editModal, setEditModal] = useState<boolean>(false)

  const [list, setList] = useState<ListObject[]>([])
  const dispatch = useDispatch()
  const setLoader = (payload: boolean) => dispatch(Global.fsLoader(payload))

  const onStartUp = async () => {
    setLoader(true)
    const optionService = new OptionService({ type: 'product-category' })
    const result = await optionService.read()

    if (!result.success || !result.options) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    const options = <Lib.T.List[]>result.options
    const listArray: ListObject[] = []
    options.forEach(item =>
      listArray.push({
        id: item.id,
        name: item.productCategory || '',
        image: process.env.REACT_APP_DOMAIN!.concat('/'.concat(item.productCategoryImage || '')),
      }),
    )
    setList(listArray)
    setLoader(false)
  }

  useEffect(() => {
    ;(async () => onStartUp())()
  }, [])

  const onSubmit = async () => {
    if (!categoryName) {
      swal({
        text: 'Lütfen kategori ismini girin.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }
    if (!image) {
      swal({
        text: 'Lütfen bir resim seçin.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }
    setLoader(true)

    let uploadedImage: string
    try {
      uploadedImage = await upload(image)
    } catch (e) {
      return
    }

    const optionService = new OptionService({
      type: 'product-category',
      productCategory: categoryName,
      productCategoryImage: uploadedImage,
    })

    const result = await optionService.create()
    if (!result.success || !result.option) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    const { option } = result
    const { id, productCategory, productCategoryImage } = option
    if (!id || !productCategoryImage || !productCategory) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    setList([
      ...list,
      {
        id,
        name: productCategory,
        image: process.env.REACT_APP_DOMAIN!.concat('/'.concat(productCategoryImage)),
      },
    ])
    setImage(null)
    setCategoryName(null)
    setLoader(false)
  }

  const onDelete = async (id: number) => {
    const optionService = new OptionService({ type: 'product-category' })
    const result = await optionService.delete(id)
    setLoader(true)

    if (!result) {
      setLoader(false)
      return
    }

    const listArray: ListObject[] = []
    list.forEach(item => {
      if (item.id !== id) {
        listArray.push(item)
      }
    })

    setList(listArray)
    setLoader(false)
  }

  const upload = async (image: File): Promise<string> => {
    if (!image) {
      return swal({
        text: 'please select an image',
        icon: 'error',
      })
    }

    const filesize = parseFloat((image.size / 1024 / 1024).toFixed(1))

    if (filesize > 2.5) {
      return swal({
        text: 'file size is more thn 2.5 megabyte',
        icon: 'error',
      })
    }
    const mediaService = new MediaService()
    const result = await mediaService.upload(image,'product')
    const { name, success } = result
    if (!success || !name) {
      return swal({
        text: 'something went wrong',
        icon: 'error',
      })
    }
    return name
  }

  const beforeConfirmEdit = (item: ListObject) => {
    setEditCategoryName(item.name)
    setItemIdToEdit(+item.id)
  }

  const edit = async () => {
    if (!editCategoryName) {
      swal({
        text: 'Lütfen kategori ismini girin.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }
    if (!itemIdToEdit) {
      return
    }
    setLoader(true)

    let uploadedImage: string | null = null
    if (editImage) {
      try {
        uploadedImage = await upload(editImage)
      } catch (e) {
        return
      }
    }

    const editArgs: ServiceArgs = {
      type: 'product-category',
      productCategory: editCategoryName,
    }

    if (uploadedImage) {
      editArgs.productCategoryImage = uploadedImage
    }

    const optionService = new OptionService(editArgs)

    const { success } = await optionService.edit(itemIdToEdit)
    setEditModal(false)
    setLoader(false)
    if (!success) {
      return
    }

    const listArray: ListObject[] = []

    list.forEach(item => {
      if (item.id === itemIdToEdit) {
        const obj: ListObject = {
          ...item,
          name: editCategoryName,
        }
        if (uploadedImage) {
          obj.image = process.env.REACT_APP_DOMAIN!.concat('/'.concat(uploadedImage))
        }
        listArray.push(obj)
      } else {
        listArray.push(item)
      }
    })

    setList(listArray)
  }

  return {
    addStates: {
      image: {
        val: image,
        set: setImage,
      },
      categoryName: {
        val: categoryName,
        set: setCategoryName,
      },
    },
    editStates: {
      image: {
        val: editImage,
        set: setEditImage,
      },
      categoryName: {
        val: editCategoryName,
        set: setEditCategoryName,
      },
      visibility: {
        val: editModal,
        set: setEditModal,
      },
    },
    on: {
      create: onSubmit,
      delete: onDelete,
      beforeConfirmEdit,
      edit,
    },
    list,
  }
}
