import styled, { keyframes } from 'styled-components'
import { sidebarWidth } from 'common/constants/strings'
import { mediaQueries as mq } from 'common/constants/media-queries'

export const panelWrapper = styled('div')<{ backgroundColor: string | undefined }>`
  float: left;
  width: 100%;
  height: auto;
  overflow: hidden;
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#f8f8f8'};
`
export const PageWrapper = styled('div')<{ sidebar: boolean; hiddenSidebar: boolean; backgroundColor: string | undefined }>`
  float: right;
  width: ${({ sidebar, hiddenSidebar }) => (hiddenSidebar ? '100%' : !sidebar ? 'calc(100% - 146px)' : 'calc(100% - 52px)')};

  height: auto;
  z-index: 100;
  min-height: 100vh;
  position: relative;
  transition: all 300ms linear;
  -webkit-animation: hDqKcr 300ms linear;
  animation: hDqKcr 300ms linear;
  background-color: ${({ backgroundColor }) => backgroundColor ?? '#f8f8f8'};
  border-radius: 20px;

  > .circles {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  ${mq.max[768]} {
    width: 100%;
  }

  > .appProvider {
    float: left;
    width: 100%;
    z-index: 10;
  }

  > * {
    position: relative;
    z-index: 1;
  }
`
