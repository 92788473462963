import React from 'react'
import { Gap } from 'common/components/ui-kit/gap'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from '.'

export const Contents: React.FC<Lib.T.ContentsProps> = ({ color, onEnter }): JSX.Element => (
  <>
    <Input label="Renk" required onEnter={onEnter} value={color.val || ''} onInput={evt => color.set(evt.currentTarget.value)} />
    <Gap mood="H" size={20} />
  </>
)
