import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { DOM } from 'common/helpers/dom'

export class AutomationNeighbourhoodService {
  accessToken: string
  endpoint = 'automation/neighbourhood'
  permissionEndpoint = 'automation/permission/list'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      AutomationNeighbourhoodService.dontAccept()
    }
    if (!user!.accessToken) {
      AutomationNeighbourhoodService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async getList(filters?: Lib.T.AutomationServiceIds.GetListFilters): Promise<Lib.T.AutomationNeighbourhood.GetListResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    })
    if (status === 200) {
      return { success: true, neighbourhoods: data.neighbourhoods, total: data.total }
    }
    return { success: false, total: 0, neighbourhoods: [] }
  }

  async getPermissionList(): Promise<Lib.T.AutomationNeighbourhood.GetPermissionListResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.permissionEndpoint).concat('?getGrouped=true'),
      headers: { authorization: this.accessToken },
    })
    if (status === 200) {
      return { success: true, permissions: data.permissions, total: data.total }
    }
    return { success: false, total: 0, permissions: [] }
  }

  async create(args: Lib.T.AutomationNeighbourhood.CreateArgs): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    })
    return status === 201
  }

  async edit(args: Lib.T.AutomationNeighbourhood.EditArgs, id: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    })
    return status === 200
  }

  async delete(id: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
    })
    return status === 200
  }

  static dontAccept() {
    throw 'not-acceptable'
  }
}
