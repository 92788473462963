import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { AutomationDepartmentService, AutomationDrinks } from 'common/services'
import { useEffect, useState } from 'react'
import React from 'react';
import ReactDOMServer from 'react-dom/server';


export const useDrinks = () => {
  const { setLoader } = useLoader()

  const tableIdIndex = 8
  const tableColumns = ['Ad', 'Departman', 'İçecek adı']
  const tableSizes = ['unset', 'unset', 'unset']

  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const [paginate, setPaginate] = useState({ limit: 30, page: 1 })
  const [totalData, setTotalData] = useState<number | undefined>()
  const [fetchAgain, setFetchAgain] = useState(false)
  const [paginateLoader, setPaginateLoader] = useState(false)
  const [departmentIds, setDepartmentIds] = useState([{ id: 0, title: '' }])
  const [query, setQuery] = useState({ name: '', departmentId: undefined, roleId: undefined })
  const [groupByDepartmentDrinks, setGroupByDepartmentDrinks]:any = useState([])

  const getDrinks = async () => {
    const service = new AutomationDrinks()
    setLoader(true)
    const { success, drinkOrders, total } = await service.getDrinks({ limit: paginate.limit, departmentId: query.departmentId, status: ['received', 'accept']})
    setLoader(false)
    if (success && drinkOrders) {
      const groupByDepartment = (drinkOrders:any) => {
        const grouped = drinkOrders.reduce((acc:any, item:any) => {
          const departmentName = item.employee.user.userToRoles[0].department.name;

          if (!acc[departmentName]) {
            acc[departmentName] = {
              name: departmentName,
              items: []
            };
          }

          acc[departmentName].items.push(item);
          return acc;
        }, {});
        return Object.values(grouped);
      };

      const groupedData = groupByDepartment(drinkOrders);
      setGroupByDepartmentDrinks(groupedData)

      setTotalData(parseInt(total! + ''))
      setTableData(prevData => [
        ...drinkOrders.map(drink => {
          return [
            <div>{`${drink.employee.firstName} ${drink.employee.lastName}`}</div>,
            <div>{drink.employee.user.userToRoles?.[0].department.name}</div>,
            <div>{drink.drink.name}</div>,
          ]
        }),
      ])
    }
  }

  const getDepartments = async () => {
    const service = new AutomationDepartmentService()
    setLoader(true)
    const { data, success } = await service.getList()
    setLoader(false)
    if (success && data) {
      setDepartmentIds(() => {
        return data.map(item => ({
          id: item?.id,
          title: item?.name,
        }))
      })
    }
  }

  const handleShowMore = () => {
    if (totalData! / paginate.page <= paginate.limit) {
      return
    }
    setPaginateLoader(true)
    setPaginate(prev => {
      return { ...prev, limit: prev.limit + 30 }
    })
  }

  const printContent = () => {
    const customHtml = groupByDepartmentDrinks && groupByDepartmentDrinks.map((item: any) => {
      return (
        <table style={{border: '1px #ccc solid', borderCollapse: 'collapse', width: '100%', marginBottom: 20, textAlign: 'center'}} key={item.id}>
          <tr>
            <th colSpan={2} style={{textAlign: 'center', padding: 15, background: '#eee'}}>{item.name}</th>
          </tr>
          {item.items.map((drink:any) => {
            return (
              <tr style={{border: '1px #ccc solid', borderCollapse: 'collapse'}}>
                <td style={{border: '1px #ccc solid', borderCollapse: 'collapse', padding: 15}}>{drink.employee.firstName} {drink.employee.lastName}</td>
                <td style={{border: '1px #ccc solid', borderCollapse: 'collapse', padding: 15}}>{drink.drink.name}</td>
              </tr>
            )
          })}
        </table>
      );
    });

    const htmlString = ReactDOMServer.renderToString(
      <div>
        {customHtml}
      </div>
    );

    let content = window.open('', '', 'height=1000, width=1000');

    if (content) {
      content.document.write('<html>');
      content.document.write("<link rel=\"stylesheet\" href=\"print.css\" type=\"text/css\" media=\"print\"/>");
      content.document.write('<body>');
      content.document.write(htmlString);
      content.document.write('</body></html>');
      content.document.close();
      content.focus();
      setTimeout(function () {
        content!.print();
      }, 1000);
    } else {
      console.error("Failed to open a new window/tab.");
    }
  };

  useAsyncEffect(getDrinks, [paginate, fetchAgain])

  useEffect(() => {
    ;(async () => {
      await getDepartments()
    })()
  }, [])

  return {
    val: {
      tableData,
      tableColumns,
      tableSizes,
      totalData,
      paginateLoader,
      tableIdIndex,
      departmentIds,
    },

    set: {
      setFetchAgain,
      setQuery,
    },

    on: {
      handleShowMore,
      printContent
    },

    get: {
      paginate,
      totalData,
      query,
    },
  }
}
