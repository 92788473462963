import styled from 'styled-components'

export const PageWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;

  p {
    margin-top: 80px;
    font-weight: 600;
    width: 50%;
    margin: 80px auto 20px auto;
  }

  .btn {
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .parent {
      width: 460px;
    }
  }
`
