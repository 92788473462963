import { Modal } from 'antd'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Content } from 'common/components/ui-kit/content'
import { Drawer } from 'common/components/ui-kit/drawer'
import { Gap } from 'common/components/ui-kit/gap'
import { Icon } from 'common/components/ui-kit/icon'
import { Input } from 'common/components/ui-kit/input'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Table } from 'common/components/ui-kit/table-2'
import { useHistory } from 'react-router-dom'
import * as Lib from './lib'
import { imageAddresser } from 'common/helpers/image.helper'
import { Fragment } from 'react'

const CustomersList = () => {
  const { get, set, on, modal, detailModal } = Lib.H.useTable()
  const history = useHistory()

  return (
    <>
      <Modal {...modal.confirmModalProps}>
        <Lib.S.confirmModal>
          <p>Seçtiğiniz üyenin erişimini kısıtlamak istediğinizden emin misiniz ?</p>
          <Button text="Onayla" mood="accent" callback={on.disableUser} />
        </Lib.S.confirmModal>
      </Modal>

      <PanelWrapper tab={['customers', '/customers/list']} title="Müşteriler">
        <Sticky>
          <Lib.S.searchSection>
            <p className="title">Müşteriler Listesi</p>
            <div>
              <div className="inputContainer">
                <Input
                  type="search"
                  icon="search"
                  placeholder="Ara..."
                  value={get.query}
                  onInput={val => set.setQuery(val.currentTarget.value)}
                  iconProps={{ size: 15 }}
                  onEnter={() => set.setFetchAgain(perv => !perv)}
                />
                <span className="filterButton" onClick={() => set.toggleShowFilters(true)}>
                  <Icon name="filter_outline" size={20} color="#000000" />
                </span>
              </div>
              <Button
                className="buttonContainer"
                icon="search"
                mood="accent"
                callback={() => set.setFetchAgain(perv => !perv)}
                iconProps={{ color: 'white', size: 20 }}
              />
            </div>
            <div style={{ flex: 0.2 }}>
              <Button
                mood="accent"
                callback={() => history.push('/customers/create')}
                text="Müşteri Oluştur"
                containerStyles={{ minHeight: 42 }}
              />
            </div>
          </Lib.S.searchSection>
        </Sticky>

        <Content padding="0">
          <Lib.S.container>
            <Lib.S.contentBody>
              <Lib.S.filter visibility={get.showFilters}>
                <Lib.S.topBar>
                  <div
                    className="filterSection"
                    // onClick={() => set.toggleShowFilters(!get.showFilters)}
                  >
                    <div className="title">
                      <span>
                        <Icon name="filter" size={20} color="#004E4278" />
                      </span>
                      <p>Filtre</p>
                    </div>
                  </div>
                </Lib.S.topBar>
                <div className="mobileTitle">
                  <span onClick={() => set.toggleShowFilters(false)}>
                    <Icon name="arrow_left" size={20} />
                  </span>
                  <p>Filtre</p>
                </div>

                {get.filterItems.map((item, index) => {
                  return item.permissions.includes(get.group) && <Drawer key={index} {...item} />
                })}

                <div className="buttons">
                  <Gap size={10} mood="H" />
                  <Button callback={on.handleFilter} text="Filtrele" mood="accent" iconProps={{ color: 'white' }} />
                  <Gap size={5} mood="H" />
                  <Button callback={on.clearFilters} text="Filtreyi Temizle" mood="orang" />
                </div>
              </Lib.S.filter>

              <Lib.S.content visibility={get.showFilters}>
                <div className="tableContainer">
                  <Table
                    columns={get.tableColumns}
                    columnSizes={get.tableSizes}
                    data={get.tableData}
                    rowBackColor={false}
                    onLoadMore={on.handleShowMore}
                    totalData={get.totalData}
                    subData={get.tableSubData}
                    paginate={get.paginate}
                    disabledIndexes={[get.tableIdIndex, get.tableIdIndex - 1]}
                    unActivatedIndex={get.tableIdIndex - 1}
                    maxHeight={get.tableData.length * 70 + 70 + 47 > 1400 ? 1400 + 'px' : get.tableData.length * 70 + 70 + 47 + 'px'}
                  />
                </div>
              </Lib.S.content>
            </Lib.S.contentBody>
          </Lib.S.container>
        </Content>
        <Modal {...detailModal.detailModalProps}>
          <Lib.S.DetailModalWrapper>
            <p>
              <Icon name="close" size={18} color="#a3a2a2" onClick={detailModal.toggleDetailModalVisibility} />
            </p>
            <section className="header">
              <div className="avatar">
                <img
                  src={imageAddresser(detailModal.detailModalData?.avatar ? detailModal.detailModalData?.avatar.imageThumbnail as string : '')}
                  alt=""
                  onError={e => (e.currentTarget.src = '/images/png/avatar.png')}
                />
              </div>
              <div className="details">
                <div className="top">
                  <div className="item">
                    <p className="item-title">Firma</p>
                    <p className="item-content">{detailModal.detailModalData?.company}</p>
                  </div>
                  <div className="item">
                    <p className="item-title">Firma Mail</p>
                    <p className="item-content">{detailModal.detailModalData?.companyEmail}</p>
                  </div>
                  <div className="item">
                    <p className="item-title">Ülke</p>
                    <p className="item-content">{detailModal.detailModalData?.country}</p>
                  </div>
                  <div className="item">
                    <p className="item-title">Sattığı Pazar</p>
                    <div className="item-content">
                      {detailModal.detailModalData?.saleCountries.map((country, idx) => (
                        <p style={{ textAlign: 'center' }} key={country + idx}>
                          {country}
                        </p>
                      ))}
                    </div>
                  </div>
                  <div className="item">
                    <p className="item-title">Sattığı Ayar</p>
                    <div className="item-content">
                      {detailModal.detailModalData?.karats.map((karat, idx) => (
                        <p key={karat + idx} style={{ textAlign: 'center' }}>
                          {karat}
                        </p>
                      ))}
                    </div>
                  </div>
                  <div className="item">
                    <p className="item-title">Satış Sorumlusu</p>
                    <div className="item-content">
                      {detailModal.detailModalData?.salePersons.map((person, idx) => (
                        <p key={person + idx} style={{ textAlign: 'center' }}>
                          {person}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="bottom">
                  <div className="item">
                    <p className="item-title">Adres</p>
                    <p className="item-content">{detailModal.detailModalData?.address}</p>
                  </div>
                  <div className="item">
                    <p className="item-title">Ad</p>
                    <p className="item-content">{detailModal.detailModalData?.firstName}</p>
                  </div>
                  <div className="item">
                    <p className="item-title">Soyad</p>
                    <p className="item-content">{detailModal.detailModalData?.lastName}</p>
                  </div>
                  <div className="item">
                    <p className="item-title">Telefon</p>
                    <p className="item-content">{detailModal.detailModalData?.phone}</p>
                  </div>
                </div>
              </div>
            </section>
            <section className="employees my-4">
              {detailModal.detailModalData?.employees?.map((item, idx) => {
                return (
                  <div className="employee" key={item.email + idx}>
                    <div className="field">
                      <span>Ad : </span>
                      <span>{item.firstName}</span>
                    </div>
                    <div className="field">
                      <span>Soyad : </span>
                      <span>{item.lastName}</span>
                    </div>
                    <div className="field">
                      <span>Telefon : </span>
                      <span>{item.phone}</span>
                    </div>
                    <div className="field">
                      <span>Email: </span>
                      <span>{item.email}</span>
                    </div>
                  </div>
                )
              })}
            </section>
            <section className="category-section">
              {detailModal.detailModalData?.productCategories.map(category => {
                return (
                  <div className="categories" key={category.id}>
                    <div className="category">{category.categoryName}</div>
                    {category.subCategories.map(subCategory => {
                      return (
                        <Fragment key={subCategory.id}>
                          <div className="category category-selected">{subCategory.productSubCategory}</div>
                        </Fragment>
                      )
                    })}
                  </div>
                )
              })}
            </section>
          </Lib.S.DetailModalWrapper>
        </Modal>
      </PanelWrapper>
    </>
  )
}

export default CustomersList
