import styled from 'styled-components'

export const PageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
  height: 100vh;
  padding: 1rem;
`

export const NewsWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #e5edec;
  border: 2px solid #004e42;
  border-radius: 16px;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  overflow: hidden;
`

export const NewsContent = styled.div`
  padding-right: 20px;
`

export const NewsTitleContainer = styled.div`
  width: 80%;
  display: flex;
  align-items; center;
  justify-content: center;
  gap: 0.5rem;
  border-bottom: 2px solid #004e42;
  padding-bottom: 0.7rem;
`

export const NewsTitle = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  color: #004e42;
  margin: 0;
  line-height: 1.6;
`

export const NewsContainer = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1.5rem;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  overflow-x: hidden;
  overflow-y: scroll;
`

export const NewsBody = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  gap: 0.5rem;
`

export const NewsDescription = styled.p`
  font-size: 1rem;
  font-weight: normal;
  text-align: left;
  color: #004e42;
  margin: 0;
  cursor: pointer;
`

export const EmptyMessageContainer = styled.div`
  width: 100%;
  text-align: center;
`

export const EmptyMessage = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  color: #004e42;
  margin: 0;
  line-height: 1.6;
`

export const IconWrapper = styled.div`
  width: 35px;
  height: 35px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const ModalContainer = styled.div`
  div > .ant-select-selector {
    border-width: 1px !important;
  }

  .modalTitle {
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    background: var(--accent);
    color: #fff;
    border-radius: 10px 10px 0 0;
    padding: 5px 10px;

    div {
      display: flex;
    }
    span {
      font-weight: 600;
      font-size: 18px;
    }
    svg {
      margin-top: 5px;
      padding: 2px;
    }
  }

  .modalTitle_2 {
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    background: transparent;
    color: #000;
    border-radius: 10px 10px 0 0;
    padding: 15px 20px;

    div {
      display: flex;
    }
    span {
      font-weight: 600;
      font-size: 20px;
    }
    svg {
      margin-top: 8px;
      padding: 2px;
    }
  }

  .modalBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    padding: 1rem 1.5rem 2rem 1.5rem;
  }

  .btn {
    display: flex;
    margin: 10px auto 0 auto;
  }

  .modalBody_2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0 1.5rem 1rem 1.5rem;
  }

  .drinkModalItem {
    width: 100%;
    background-color: #f8f8f8;
    color: #313131;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    font-size: 17px;
    cursor: pointer;
  }
`

export const ModalThumbnail = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
`

export const AnnouncementsList = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #004e42;
`

export const AnnouncementsWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const AnnouncementsThumbnail = styled.div`
  width: 100%;
  height: 65%;
  cursor: pointer;
`

export const AnnouncementsDescription = styled.p`
  width: 100%;
  height: 35%;
  color: #fff;
  text-align: left;
  cursor: pointer;
  padding: 1rem 1.5rem;
`

export const EmptyAnnouncementList = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #004e42;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AnnouncementEmptyMessage = styled.h2`
  font-size: 1rem;
  font-weight: normal;
  color: #fff;
  margin: 0;
  line-height: 1.6;
`
export const FoodList = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #004e42;
`

export const FoodListBody = styled.div`
  width: 100%;
  height: 70%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('/images/svg/dashboardIcon.svg') no-repeat bottom right / 60%;
  padding-bottom: 1rem;
`

export const FoodListThumbnail = styled.div`
  width: 100%;
  height: 30%;
  background: url('/images/svg/food.svg') no-repeat center center / cover;
`

export const FoodListTitleContainer = styled.div`
  width: 80%;
  display: flex;
  align-items; center;
  justify-content: center;
  gap: 0.5rem;
  border-bottom: 2px solid #fff;
  padding-bottom: 0.7rem;
  margin-top: 1rem;
`

export const FoodListTitle = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  margin: 0;
  line-height: 1.6;
`

export const FoodNamesList = styled.div`
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
`

export const FoodItem = styled.p`
  font-size: 1rem;
  font-weight: normal;
  color: #fff;
  margin: 0;
  line-height: 1.6;
`

export const TodoListContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 2px solid #004e42;
  border-radius: 16px;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  overflow: hidden;
  background: url('/images/svg/dashboardIconGreen.svg') no-repeat center center / 70%;
`

export const TodoListTitleContainer = styled.div`
  width: 80%;
  display: flex;
  align-items; center;
  justify-content: center;
  gap: 0.5rem;
  border-bottom: 2px solid #004e42;
  padding-bottom: 0.7rem;
`

export const TodoListTitle = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  color: #004e42;
  margin: 0;
  line-height: 1.6;
`

export const TodoListBody = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
`

export const TodoListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  div > label > input:checked + span.visual {
    background: rgba(0, 78, 66, 0.1);
    border: 1px solid rgb(0 0 0 / 10%);
  }
  label > input:checked + span.visual > svg {
    width: 10px;
    height: 10px;
  }
  label > input:checked + span.visual > svg > path {
    stroke: #004e42;
  }
  .checked {
    text-decoration: line-through;
  }
`

export const DrinksWrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 2px solid #004e42;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
`

export const DrinksTitle = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  color: #004e42;
  margin: 0;
  line-height: 1.6;
`

export const DrinkButton = styled.div<{ isSelectDrink: boolean }>`
  border: 2px solid #004e42;
  background-color: ${props => (props.isSelectDrink ? '#e5edec' : '#004e42')};
  width: 100%;
  border-radius: 8px;
  padding: 10px 12px;
  color: ${props => (props.isSelectDrink ? '#004e42' : '#fff')};
  cursor: pointer;
  text-align: center;
`

export const WeatherAndCurrencyWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`

export const DrinkAndBirthdayWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`

export const BirthdaysContainer = styled.div`
  width: 80%;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
`

export const BirthdayEmptyMessageContainer = styled.div`
  width: 100%;
  text-align: center;
  border-top: 2px solid #004e42;
  padding-bottom: 0.5rem;
`

export const BirthdaysList = styled.div`
  width: 100%;
  height: fit-content;
  overflow: hidden;
`

export const InWeekBirthdaysContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-top: 2px solid #004e42;
`

export const InWeekBirthdaysItem = styled.div`
  width: 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const InWeekBirthdaysAvatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 0.3rem;
`

export const InWeekBirthdaysName = styled.p`
  font-size: 0.7rem;
  font-weight: bold;
  color: #004e42;
  margin: 0;
`

export const InWeekBirthdaysDate = styled.p`
  font-size: 0.8rem;
  font-weight: normal;
  text-align: left;
  color: #004e42;
  margin: 0;
`

export const TodayBirthdaysItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`

export const TodayBirthdaysAvatar = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
`

export const TodayBirthdaysName = styled.p`
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  color: #004e42;
  margin: 0;
`

export const CurrenciesWrapper = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #e5edec;
  border: 2px solid #004e42;
  border-radius: 16px;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  overflow: hidden;
  flex: 1;
`

export const CurrencyGroup = styled.div`
  width: 100%;
  height: fit-content;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const CurrencyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  height: 100%;
`

export const CurrencyTitle = styled.h2`
  // width: 100%;
  border-bottom: 2px solid #004e42;
  color: #004e42;
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  margin: 0;
  padding-bottom: 0.2rem;
  margin-bottom: 0.3rem;
`

export const CurrencyPriceTitle = styled.p`
  color: #004e42;
  font-size: 0.9rem;
  font-weight: normal;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  margin: 0;
`

export const CurrencyPrice = styled.span`
  font-weight: bold !important;
`

export const WeatherWrapper = styled.div`
  width: 100%;
  height: fit-content;
  background-color: #e5edec;
  border: 2px solid #004e42;
  border-radius: 16px;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  overflow: hidden;
  flex: 1;
`

export const WeatherIconContainer = styled.div`
  width: 40px;
  height: 40px;
`

export const WeatherIcon = styled.img`
  width: 100%;
  height: 100%;
`

export const Weather = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const WeatherContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const DayName = styled.p`
  color: #004e42;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  text-align: center;
`

export const Temperature = styled.p`
  color: #004e42;
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  text-align: center;
`
