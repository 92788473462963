import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Crypto } from 'common/helpers/crypto.helper'
import { DOM } from 'common/helpers/dom'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import swal from 'sweetalert'
import * as Lib from './lib'
import { AddTaskArgs, TaskResponse } from './lib/types/todoList'

export class TodoListService {
  accessToken: string
  endpoint = 'todo-list/'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      throw ''
    }
    if (!user!.accessToken) {
      throw ''
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async getTodoList(args: Lib.T.TodoList.Args): Promise<Lib.T.TodoList.Result> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      return { success: false, result: null, pendingResult: null }
    }
    return { success: true, result: data, pendingSuccess: true, pendingResult: data }
  }

  async delete(ids: number, date?: string): Promise<{success: boolean}> {
    const { status, data } = await ApiCaller({
      method: 'DELETE',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: {ids:[ids], date}
    })

    if (status !== 200) {
      return { success: false }
    }
    return { success: true }
  }

  async addTask(args?: Lib.T.TodoList.AddTaskArgs): Promise<Lib.T.TodoList.TaskResponse> {
    const { status } = await ApiCaller({
      method: 'POST',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args
    })

    if (status !== 201) {
      return { success: false }
    }
    return { success: true }
  }

  async editTask(args: Lib.T.TodoList.AddTaskArgs, id: number): Promise<Lib.T.TodoList.TaskResponse> {
    const { status } = await ApiCaller({
      method: 'PATCH',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat(id.toString()),
      headers: { authorization: this.accessToken },
      data: args
    })

    if (status !== 200) {
      return { success: false }
    }
    return { success: true }
  }

  async shareTask(args: Lib.T.TodoList.AddTaskArgs): Promise<Lib.T.TodoList.TaskResponse> {
    const { status } = await ApiCaller({
      method: 'POST',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('clone'),
      headers: { authorization: this.accessToken },
      data: args
    })

    if (status !== 201) {
      return { success: false }
    }
    return { success: true }
  }

}
