import { FC, FormEvent, KeyboardEvent, useRef, useState } from 'react'
import { Icon } from '../icon'
import * as Lib from './lib'

/*******
 * @param label: the label title. also will use as a flag that specifies we have a label or not.
 * @param containerStyles: will apply to the very top parent (exactly the 'inputContainer').
 * @param icon: peak and icon from 'IconTypes'.
 * @param iconProps: native Icon component props with excluded name.
 * @param inputAttrs: contains all the possible attributes for an input element (...spread).
 *
 *****/

export const Input: FC<Lib.T.InputProps<string | symbol>> = ({
  label,
  containerStyles,
  labelStyles,
  icon,
  iconProps,
  showPassColor,
  capitalize,
  inputStyles,
  hideNumberInputArrows,
  lessBorder,
  containerClass,
  onInput,
  onEnter,
  blurOnEnter = false,
  ...inputAttrs
}): JSX.Element => {
  const [showPass, setShowPass] = useState(false)
  const input = useRef() as React.MutableRefObject<HTMLInputElement>

  const onInputHandler = (evt: FormEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget
    //  evt.currentTarget.value = capitalize ? CharacterConverter(value).toUpperCase() : CharacterConverter(value)

    if (onInput) {
      onInput(evt)
    }
  }

  const onKeyUp = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (evt.keyCode === 13 && onEnter) {
      onEnter()
      if (blurOnEnter) {
        input.current.blur()
      }
    }
  }

  return (
    <>
      <Lib.S.inputContainer
        style={containerStyles}
        hideNumberInputArrows={hideNumberInputArrows}
        padding={{
          right: inputAttrs.type === 'password',
          left: typeof icon !== 'undefined',
        }}
        className={containerClass}
      >
        <label>
          {label && (
            <span className="label" style={labelStyles}>
              {label}
              {inputAttrs.required && <span style={{ color: '#D32738' }}>*</span>}
            </span>
          )}

          <input
            {...inputAttrs}
            onInput={onInputHandler}
            className="input"
            ref={input}
            onKeyUp={onKeyUp}
            onMouseDown={() => (input.current.placeholder = '')}
            type={inputAttrs.type === 'password' ? (showPass ? 'text' : 'password') : inputAttrs.type}
            style={{ ...inputStyles, borderWidth: lessBorder ? '1px' : '3px' }}
          />

          {inputAttrs.type === 'password' && (
            <span className="password icon" onClick={() => setShowPass(!showPass)}>
              <Icon name={showPass ? 'eye_closed' : 'eye'} size={20} color={showPassColor ? showPassColor : '#fff'} />
            </span>
          )}

          {icon && (
            <span className="custom icon">
              <Icon name={icon} size={20} color="#00000066" {...iconProps} />
            </span>
          )}
        </label>
      </Lib.S.inputContainer>
    </>
  )
}
