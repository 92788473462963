import { Popover } from 'antd'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import ImageModal from 'common/components/ui-kit/imageModal'
import { imageAddresser } from 'common/helpers/image.helper'
import { t } from 'i18next'
import moment from 'moment'
import { Pattern } from 'pages/private/orders/create/lib/components'
import { MonacoChainAndLockPicture } from 'pages/private/products/list/monacoList/lib/components'
import { useState } from 'react'
import * as Lib from './lib'

export const Log = () => {
  const { get, set, on } = Lib.H.useLog()

  const [showImageModal, setShowImageModal] = useState(false)
  const [productImg, setProductImg] = useState({ image: '', imageCategory: '', imageLink: '' })

  return (
    <PanelWrapper tab={['log', '/logs']} title="Log">
      <ImageModal
        downloadImageLink={productImg.imageLink}
        image={productImg.image}
        showModal={showImageModal}
        setShowModal={setShowImageModal}
        withZoom
        isMonacoImageModal={productImg.imageCategory.includes('monaco')}
      />
      <Lib.S.Wrapper>
        {/* details */}

        <div style={{ padding: '10px 20px' }}>
          <div className="details">
            <div className="detail">
              <div className="row2">
                <div className="col" style={{ flex: 1 }}>
                  <span>
                    Müşteri: <span>{get.detailData?.customer}</span>
                  </span>

                  <span>
                    Sipariş No: <span>{get.detailData?.orderId}</span>
                  </span>

                  <span>
                    Ayar: <span>{get.detailData?.karat ?? '-'}</span>
                  </span>
                  <span>
                    Patent: <span>{get.detailData?.patent ?? '-'}</span>
                  </span>
                </div>
                <div className="col" style={{ flex: 1.5 }}>
                  <span>
                    Sipariş Tarihi: <span>{moment(get.detailData?.orderCreatedAt).format('L')}</span>
                  </span>
                  <span>
                    TeslimTarihi: <span>{moment(get.detailData?.deliveryDate).format('L')}</span>
                  </span>
                  <span>
                    Sipariş Sorumlusu: <span>{get.detailData?.seller}</span>
                  </span>
                </div>

                <div className="col" style={{ flex: 3 }}>
                  <div className="title">
                    <span>Açıklama:</span> <span className="desc">{get.detailData?.description}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* table */}
          <table id="order-table-2">
            <thead className="thead">
              <tr>
                <th>Ürün kod</th>
                <th>Parçalar</th>
                <th>Adet</th>
                <th>Renk</th>
                <th>Boy</th>
                <th>Makyaj</th>
                <th>Fiyat</th>
                <th>Açıklama</th>
              </tr>
            </thead>
            <tbody>
              {get.orderLog?.orderToProducts.map((p, index) => {
                const { product } = p
                return (
                  <tr key={product.id}>
                    {/* product Code */}
                    <td className="cell code-cell" style={{ width: '14%', cursor: 'pointer' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                          height: '100%',
                        }}
                      >
                        <section className={product.category.includes('monaco') ? 'monaco-productCode' : 'productCode'}>
                          <span
                            className="image-wrapper"
                            onClick={() => {
                              setShowImageModal(true)
                              if (product.category === 'monaco') {
                                setProductImg({
                                  image: `${product.productSubCategoryType.image},${product.lock.image}`,
                                  imageCategory: product.category,
                                  imageLink: product.imageLink
                                })
                              } else {
                                setProductImg({ image: product.productImage, imageCategory: product.category , imageLink: product.imageLink })
                              }
                            }}
                          >
                            {product.category.includes('monaco') ? (
                              <MonacoChainAndLockPicture
                                width={110}
                                chainImagePath={product.productSubCategoryType.image}
                                lockImagePath={product.lock.image}
                                style={{ marginRight: 23 }}
                              />
                            ) : (
                              <img
                                src={imageAddresser(product.productImage, true)}
                                alt="test"
                                className="open-image"
                                onError={e => (e.currentTarget.src = '/images/jpg/placeholder.jpg')}
                              />
                            )}
                          </span>
                          <span style={{ width: '100%', display: 'block', textAlign: 'left' }}>
                            <span className="text-left">{product.productCode}</span>
                          </span>
                        </section>
                        {/* 
                      <div className="problem">
                        {product.problems?.length ? (
                          <div>
                            <Popover
                              content={() => (
                                <ol style={{ margin: 0, paddingLeft: '1rem' }}>
                                  {product.problems?.map(problem => (
                                    <li>{problem.description}</li>
                                  ))}
                                </ol>
                              )}
                            >
                              <Icon name="spark" size={20} style={{ marginRight: 15 }} />
                            </Popover>
                          </div>
                        ) : null}
                      </div> */}
                      </div>
                      {/* <p className="code-cell-weight">
                      <span style={{ color: product.accurateWeight ? 'blue' : '#000' }}>{product.weight} gr</span>
                    </p> */}
                    </td>
                    {/* pieces */}
                    <td className="cell" style={{ width: '8%' }}>
                      {product.pieces.map((piece, index, pieces) => {
                        let str = t(piece.type)
                        return (
                          <div key={index}>
                            <span className={index % 2 === 0 ? 'background' : ''}>{str}</span>
                          </div>
                        )
                      })}
                    </td>
                    {/* count-Adet */}
                    <td className="cell" style={{ width: '4%' }}>
                      {product.pieces.map((piece, index) => {
                        return (
                          <div key={piece.id} data-row={piece.id}>
                            <span className={index % 2 === 0 ? 'background' : ''}>{piece.count}</span>
                          </div>
                        )
                      })}
                    </td>
                    {/* Color-Renk */}
                    <td className="cell" style={{ width: '4%' }}>
                      {product.pieces.map((piece, index) => {
                        return (
                          <div key={piece.id}>
                            <span className={index % 2 === 0 ? 'background' : ''}>{piece.madeColor?.madeTitle}</span>{' '}
                          </div>
                        )
                      })}
                    </td>
                    {/* width-boy */}
                    <td className="cell" style={{ width: '4%' }}>
                      {product.pieces.map((piece, index) => {
                        return (
                          <div key={piece.id}>
                            <span className={index % 2 === 0 ? 'background' : ''}>
                              {product.category === 'monaco' ? piece.width?.width : '-'}
                            </span>{' '}
                          </div>
                        )
                      })}
                    </td>
                    {/* MAYBE WILL BE USEFULL IN MONACO */}
                    {/* <td className="cell" style={{ width: '5%' }}>
                    {product.pieces.map((piece, index) => {
                      return (
                        <div key={piece.id} className={index % 2 === 0 ? 'background' : ''}>
                          {product.category === 'monaco'
                            ? piece.size?.sizeInInches
                              ? piece.size?.sizeInInches + '"'
                              : '-'
                            : piece.size?.size
                            ? piece.size?.size
                            : '-'}
                        </div>
                      )
                    })}
                  </td> */}
                    {/* enamels-makyaj */}
                    <td className="cell mine-kalem" style={{ width: '6%' }}>
                      {product.pieces.map(piece => {
                        return (
                          <div key={piece.id}>
                            <div className="mine">
                              {piece.hasEnamel ? (
                                <Popover
                                  content={() => {
                                    return piece.enamels.map(enamel => {
                                      return (
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                          <div style={{ width: 20, height: 20, backgroundColor: enamel.title }}></div>
                                        </div>
                                      )
                                    })
                                  }}
                                >
                                  <img
                                    // onClick={() => {
                                    //   onClickEnamel(piece.enamels)
                                    // }}
                                    style={{ cursor: 'pointer' }}
                                    src="/images/png/gradiant.png"
                                    alt=""
                                    onError={e => (e.currentTarget.src = '/images/jpg/placeholder.jpg')}
                                  />
                                </Popover>
                              ) : (
                                '-'
                              )}
                            </div>
                            <div className="kalem">{piece.hasKalem ? <Pattern size={14} /> : '-'}</div>
                          </div>
                        )
                      })}
                    </td>
                    {/* price-fiyat */}
                    <td className="cell" style={{ width: '5%' }}>
                      {product.pieces.map((piece, index) => {
                        return (
                          <div key={piece.id} className={index % 2 === 0 ? 'background' : ''}>
                            {/* $ {!isTestOrder ? piece.price : 0} */}$ {piece.price}
                          </div>
                        )
                      })}
                    </td>

                    {/* description-aciklama */}
                    <td className="cell">
                      {product.pieces.map((piece, index) => {
                        return (
                          <div key={index}>
                            <span
                              style={{ textAlign: 'left' }}
                              // onClick={() => {
                              //   if (editMode) return
                              //   setDescription(piece.description)
                              //   setShowDescriptionModal(true)
                              // }}
                              className={index % 2 === 0 ? 'background' : ''}
                            >
                              {piece.description !== null
                                ? piece.description.length > 65
                                  ? piece.description.substring(0, 65) + '...'
                                  : piece.description
                                : ''}
                            </span>
                          </div>
                        )
                      })}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div className="footer">
          <div className="status">
            <div>
              <span className="title">Ürün sayısı : </span>
              <span className="value">{get.footerData.productsCount}</span>
            </div>
            <div>
              <span className="title">Parça sayısı : </span>
              <span className="value">{get.footerData.piecesCount}</span>
            </div>
            <div>
              <span className="title">Toplam Ağırlığı: </span>
              <span className="value" style={{ color: get.footerData.accurateWeight ? 'blue' : '#000' }}>
                {get.footerData.totalWeight}
              </span>
            </div>
          </div>
        </div>
      </Lib.S.Wrapper>
    </PanelWrapper>
  )
}
