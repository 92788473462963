import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { MediaService, ProductSubCategory } from 'common/services'
import { EditArgs } from 'common/services/lib/types/product-sub-category'
import { ListObject } from 'common/components/ui-kit/list/lib/typing'
import { useDispatch } from 'react-redux'
import { Global } from 'common/store/actions'
import * as CategoryLib from '../../product-category/lib'
import swal from 'sweetalert'

export const useProductCategory = () => {
  const [image, setImage] = useState<File | null>(null)
  const [editImage, setEditImage] = useState<File | null>(null)
  const [chosenCategory, setChosenCategory] = useState<string | null>(null)
  const [chosenCategoryId, setChosenCategoryId] = useState<number | null>(null)

  const [categoryName, setCategoryName] = useState<string | null>(null)
  const [editCategoryName, setEditCategoryName] = useState<string | null>(null)
  const [itemIdToEdit, setItemIdToEdit] = useState<number | null>(null)
  const [editChosenCategory, setEditChosenCategory] = useState<string | null>(null)
  const [editChosenCategoryId, setEditChosenCategoryId] = useState<number | null>(null)
  const [editCategoryKeyword, setEditCategoryKeyword] = useState<string | number>('')
  const [editModal, setEditModal] = useState<boolean>(false)

  const dispatch = useDispatch()
  const setLoader = (payload: boolean) => dispatch(Global.fsLoader(payload))
  const { list: categoriesList } = CategoryLib.H.useProductCategory()
  const [categories, setCategories] = useState<string[] | null>()
  const [list, setList] = useState<ListObject[] | null>(null)

  const makeCategories = () => {
    if (!categoriesList) {
      return
    }
    const categoriesArray: string[] = []
    categoriesList.forEach(item => categoriesArray.push(item.name))
    setCategories(categoriesArray)
  }

  const makeCategoryId = (state: string | null, setState: Dispatch<SetStateAction<number | null>>) => {
    if (!state || !categoriesList) {
      return
    }
    const foundCategory = categoriesList.filter(item => item.name === state)[0]
    if (!foundCategory) {
      return
    }
    setState(parseInt(foundCategory.id + ''))
  }

  const onStartUp = async () => {
    setLoader(true)
    const productSubCategory = new ProductSubCategory()
    const result = await productSubCategory.getList()
    const { success, data } = result
    if (!success || !data) {
      return
    }

    const listArray: ListObject[] = []
    data.forEach(item =>
      listArray.push({
        id: item.id,
        name: item.productSubCategory,
        image: process.env.REACT_APP_DOMAIN!.concat('/'.concat(item.productSubCategoryImage)),
        description: item.parent.productCategory,
      }),
    )

    setList(listArray)
    setLoader(false)
  }

  useEffect(() => {
    ;(async () => onStartUp())()
  }, [])

  const onSubmit = async () => {
    if (!categoryName) {
      swal({
        text: 'Lütfen alt kategori ismini girin.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }
    if (!image) {
      swal({
        text: 'Lütfen bir resim seçin.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }
    if (!chosenCategoryId) {
      swal({
        text: 'Lütfen ana kategoriyi seçin.',
        icon: 'error',
        dangerMode: true,
      })
      return
    }
    setLoader(true)

    let uploadedImage: string
    try {
      uploadedImage = await upload(image)
    } catch (e) {
      return
    }

    const productSubCategory = new ProductSubCategory()

    const result = await productSubCategory.create({
      image: uploadedImage,
      name: categoryName,
      parent: chosenCategoryId,
    })

    const { success, data } = result
    if (!success || !data) {
      return
    }

    const listArray = list || []
    listArray.push({
      id: data.id,
      name: data.productSubCategory,
      image: process.env.REACT_APP_DOMAIN!.concat('/'.concat(data.productSubCategoryImage)),
      description: data.parent.productCategory,
    })

    setCategoryName(null)
    setChosenCategory(null)
    setList(listArray)
    setLoader(false)
  }

  const onDelete = async (id: number) => {
    const productSubCategory = new ProductSubCategory()
    setLoader(true)
    const result = await productSubCategory.delete(id)
    setLoader(false)

    if (result) {
      setList(list?.filter(item => item.id !== id) || [])
    }
  }

  const upload = async (image: File): Promise<string> => {
    if (!image) {
      return swal({
        text: 'please select an image',
        icon: 'error',
      })
    }

    const filesize = parseFloat((image.size / 1024 / 1024).toFixed(1))

    if (filesize > 2.5) {
      return swal({
        text: 'file size is more thn 2.5 megabyte',
        icon: 'error',
      })
    }
    const mediaService = new MediaService()
    const result = await mediaService.upload(image, 'product-sub-category')
    const { name, success } = result
    if (!success || !name) {
      return swal({
        text: 'something went wrong',
        icon: 'error',
      })
    }
    return name
  }

  const beforeConfirmEdit = (item: ListObject) => {
    setEditChosenCategory(item.description!)
    setEditCategoryKeyword(item.description!)
    setEditCategoryName(item.name)
    setItemIdToEdit(+item.id)
  }

  const afterCloseEdit = () => {
    setEditChosenCategory(null)
    setEditChosenCategoryId(null)
    setEditCategoryKeyword('')
  }

  const edit = async () => {
    if (!editCategoryName) {
      swal({
        text: 'Lütfen alt kategori ismini girin.',
        icon: 'error',
        dangerMode: true,
      })
      return
    }
    if (!itemIdToEdit) {
      return
    }

    setLoader(true)

    let uploadedImage: string | null = null
    if (editImage) {
      try {
        uploadedImage = await upload(editImage)
      } catch (e) {
        return
      }
    }

    const editArgs: EditArgs = {
      productSubCategory: editCategoryName,
    }

    if (uploadedImage) {
      editArgs.productSubCategoryImage = uploadedImage
    }

    if (editChosenCategoryId) {
      editArgs.parent = editChosenCategoryId
    }

    const optionService = new ProductSubCategory()

    const { success, data } = await optionService.edit(itemIdToEdit, editArgs)
    setLoader(false)
    setEditModal(false)
    if (!success || !data) {
      return
    }

    const listArray: ListObject[] = []

    list?.forEach(item => {
      if (item.id === itemIdToEdit) {
        listArray.push({
          id: data.id,
          name: data.productSubCategory,
          description: data.productCategory,
          image: process.env.REACT_APP_DOMAIN!.concat('/'.concat(data.productSubCategoryImage)),
        })
      } else {
        listArray.push(item)
      }
    })

    setList(listArray)
  }

  useEffect(makeCategories, [categoriesList])
  useEffect(() => makeCategoryId(chosenCategory, setChosenCategoryId), [chosenCategory])
  useEffect(() => makeCategoryId(editChosenCategory, setEditChosenCategoryId), [editChosenCategory])
  return {
    addStates: {
      category: {
        name: {
          val: chosenCategory,
          set: setChosenCategory,
        },
        id: {
          val: chosenCategoryId,
          set: setChosenCategoryId,
        },
      },
      image: {
        val: image,
        set: setImage,
      },
      name: {
        val: categoryName,
        set: setCategoryName,
      },
      categories,
    },
    editStates: {
      category: {
        name: {
          val: editChosenCategory,
          set: setEditChosenCategory,
        },
        id: {
          val: editChosenCategoryId,
          set: setEditChosenCategoryId,
        },
        keyword: {
          val: editCategoryKeyword,
          set: setEditCategoryKeyword,
        },
      },
      image: {
        val: editImage,
        set: setEditImage,
      },
      name: {
        val: editCategoryName,
        set: setEditCategoryName,
      },
      categories,
      visibility: {
        val: editModal,
        set: setEditModal,
      },
    },
    on: {
      create: onSubmit,
      delete: onDelete,
      makeCategories,
      beforeConfirmEdit,
      afterCloseEdit,
      edit,
    },
    list,
  }
}
