import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import swal from 'sweetalert'
import { DOM } from 'common/helpers/dom'
import { Room } from './lib/types/messaging'

export class MessagingService {
  accessToken: string
  endpoint = 'messaging'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      MessagingService.dontAccept()
    }
    if (!user!.accessToken) {
      MessagingService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async createGroup(body: {
    userIds: number[]
    groupData: Lib.T.Messaging.MessagingGroupType
  }): Promise<{ success: boolean; room: Room | undefined }> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/group'),
      headers: { authorization: this.accessToken },
      data: body,
    })
    const { status, data } = response
    if (status !== 201) {
      swal({
        text: "couldn't create group",
        dangerMode: true,
        icon: 'error',
      })
      return { success: false, room: undefined }
    }

    return { success: true, room: response.data }
  }

  async addMemberToGroup(body: { userIds: number[]; roomId: number }): Promise<{ success: boolean; room: Room | undefined }> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/group/${body.roomId}/user`),
      headers: { authorization: this.accessToken },
      data: body,
    })
    const { status, data } = response
    if (status !== 201) {
      swal({
        text: "couldn't add member to group",
        dangerMode: true,
        icon: 'error',
      })
      return { success: false, room: undefined }
    }

    return { success: true, room: data }
  }

  async getOrCreatePersonalRoom(body: { contactUserId: number }): Promise<{ success: boolean; roomId: number | undefined }> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/room'),
      headers: { authorization: this.accessToken },
      data: body,
    })
    const { status, data } = response
    if (status !== 201) {
      swal({
        text: "couldn't create personal room",
        dangerMode: true,
        icon: 'error',
      })
      return { success: false, roomId: undefined }
    }
    return { success: true, roomId: data.roomId }
  }

  async getRoomMessages(
    roomId: number,
    page: number,
    limit: number,
    lastMessageId?: number,
    messageIds?: string,
  ): Promise<{ success: boolean; messages: Lib.T.Messaging.Message[] | undefined; count: number }> {

    const response = await ApiCaller({
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat(`/room/${roomId}`)
        .concat('/message')
        .concat(
          DOM.objectToSearch({
            page,
            limit,
            isBeforeLastMessageId: lastMessageId ? false : undefined,
            lastMessageId: lastMessageId ?? undefined,
            messageIds: messageIds ?? undefined,
          }),
        ),

      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200) {
      swal({
        text: "couldn't create/get personal room",
        dangerMode: true,
        icon: 'error',
      })
      return { success: false, messages: undefined, count: 0 }
    }
    return { success: true, messages: data.messages, count: data.count }
  }

  async sendNewMessage(body: {
    text?: string
    type: string
    file?: string
    location?: number[]
    repliedMessageId?: number
    roomId: number
  }): Promise<{ success: boolean; message: Lib.T.Messaging.Message | undefined }> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/room/${body.roomId}`).concat('/message'),
      headers: { authorization: this.accessToken },
      data: body,
    })
    const { status, data } = response
    if (status !== 201) {
      swal({
        text: "couldn't send message",
        dangerMode: true,
        icon: 'error',
      })
      return { success: false, message: undefined }
    }
    return { success: true, message: data.message }
  }

  async updateSeenRoomMessages(roomId: number): Promise<{ success: boolean }> {
    const response = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/room/${roomId}`).concat('/message'),
      headers: { authorization: this.accessToken },
      data: { roomId },
    })
    const { status, data } = response
    if (status !== 200) {
      swal({
        text: "couldn't update seen messages",
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true }
  }

  async getUserRooms(args?: { roomId: number | undefined }): Promise<{ success: boolean; rooms: Lib.T.Messaging.Room[] | undefined }> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('/room')
        .concat(args?.roomId ? DOM.objectToSearch({ roomIds: [args?.roomId] }) : ''),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response

    if (status !== 200) {
      swal({
        text: "couldn't get personal room",
        dangerMode: true,
        icon: 'error',
      })
      return { success: false, rooms: undefined }
    }
    return { success: true, rooms: data.rooms }
  }

  async getTotalUnseen(): Promise<{ success: boolean; totalCount: number }> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/unseen'),

      headers: { authorization: this.accessToken },
    })
    const { status, data } = response

    if (status !== 200) {
      swal({
        text: "couldn't get total unseen count",
        dangerMode: true,
        icon: 'error',
      })
      return { success: false, totalCount: 0 }
    }
    return { success: true, totalCount: data.totalCount }
  }

  async deleteGroup(id: number): Promise<{ success: boolean }> {
    const response = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/group/${id}`),
      headers: { authorization: this.accessToken },
    })
    const { status } = response

    if (status !== 200) {
      swal({
        text: response.data.message,
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true }
  }

  async deleteGroupUser(body: { userIds: number[]; roomId: number }, roomId: number): Promise<{ success: boolean }> {
    const response = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(`/group/${roomId}/user`),
      headers: { authorization: this.accessToken },
      data: body,
    })
    const { status } = response

    if (status !== 200) {
      swal({
        text: response.data.message,
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true }
  }
  static dontAccept() {
    throw 'not-acceptable'
  }
}
