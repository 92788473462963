import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { DatePicker, Modal } from 'antd'
import { Button } from 'common/components/ui-kit/button'
import { DropDown2 } from 'common/components/ui-kit/dropdown2'
import { Icon } from 'common/components/ui-kit/icon'
import { ImageListSlider } from 'common/components/ui-kit/image-list-slider'
import { imageAddresser } from 'common/helpers/image.helper'
import { MonacoChainAndLockPicture } from 'pages/private/products/list/monacoList/lib/components'
import InfiniteScroll from 'react-infinite-scroll-component'
import * as Lib from './lib'
import { AreaChartMode, ChartHeader, ChartMode, HeaderTabEnum } from './lib/types'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/tr_TR'
import React from 'react'

export const Tabs = () => {
  const { tab, chartProps, chartHeaderTabs, header, customerTable, categoryTab, areaTab } = Lib.H.useChart()
  const formatter = new Intl.NumberFormat('en-US')

  const renderHeaderTabs = () => {
    switch (header.headerActiveTab) {
      case HeaderTabEnum.AREA:
        return (
          <Lib.S.CategoryContainer
            isKaratItems={!!areaTab.karatIds?.length}
          >
            <section className="header">
              <div className="dropdown" style={{ width: '50%' }}>
                <span>Ayar</span>
                <DropDown2
                  lessBorder
                  size="middle"
                  className="karats-multiple"
                  loading={areaTab.karatsLoading}
                  disabled={areaTab.karatsLoading}
                  height="42px"
                  value={areaTab.karatIds}
                  mode="multiple"
                  removeIcon={false}
                  onChange={areaTab.onKaratDropDownChange}
                  options={areaTab.karatDropdown}
                  filterOption={(input, option) => {
                    return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }}
                />
              </div>
              <div className="dropdown">
                <div
                  className="antDatePicker"
                  style={{ paddingLeft: '2px', paddingRight: '0px', display: 'flex', width: '100%', alignItems: 'center' }}
                >
                  <span>Başlangıç tarihi</span>
                  <DatePicker
                    // value={customerTable.startDate ? moment(customerTable.startDate, 'YYYY-MM-DD') : null}
                    onChange={(date: moment.Moment | null) => {
                      if (date) {
                        const formattedDateForServer = date.format('YYYY-MM-DD')
                        areaTab.setStartDate(formattedDateForServer)
                      } else {
                        areaTab.setStartDate('')
                      }
                    }}
                    size="small"
                    locale={locale}
                    allowClear={false}
                    className="ms-2"
                    style={{ height: 42, width: '65%', borderRadius: 7 }}
                    inputReadOnly
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>
              <div className="dropdown">
                <div
                  className="antDatePicker"
                  style={{ paddingLeft: '2px', paddingRight: '0px', display: 'flex', width: '100%', alignItems: 'center' }}
                >
                  <span>Bitiş tarihi</span>
                  <DatePicker
                    // value={customerTable.endDate ? moment(customerTable.endDate, 'YYYY-MM-DD') : null}
                    onChange={(date: moment.Moment | null) => {
                      if (date) {
                        const formattedDateForServer = date.format('YYYY-MM-DD')
                        areaTab.setEndDate(formattedDateForServer)
                      } else {
                        areaTab.setEndDate('')
                      }
                    }}
                    size="small"
                    locale={locale}
                    allowClear={false}
                    className="ms-2"
                    style={{ height: 42, width: '63%', borderRadius: 7 }}
                    inputReadOnly
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>
              <div className="pie-btns">
                <Button text="Filtrele" callback={areaTab.handleAreaPieChartFilter} mood="accent" containerStyles={{ width: 140 }} />
                <Button
                  text="Filtreyi Temizle"
                  callback={areaTab.handleAreaPieChartFilter}
                  onMouseDown={areaTab.handleClearAreaPieChartFilter}
                  mood="danger"
                  containerStyles={{ width: 140 }}
                />
              </div>
            </section>
            <section className="chart">
              {areaTab.loader ? null : areaTab.areaWeight?.length ? (
                <>
                  <div className="detail">
                    <h3>{areaTab.areaPieChartHeader}</h3>
                    {areaTab.areaChartMode === AreaChartMode.AREA ? null : (
                      <div>
                        <Icon
                          name="backward_square"
                          size={22}
                          color="var(--accent)"
                          style={{ cursor: 'pointer' }}
                          onClick={areaTab.handleAreaPieChartBackwardButton}
                        />
                        <span>Bölge geri dön</span>
                      </div>
                    )}
                  </div>

                  <ResponsivePie
                    data={areaTab.areaWeight!}
                    cornerRadius={3}
                    onClick={areaTab.areaChartMode !== AreaChartMode.CUSTOMER ? areaTab.handleAllAreaOnClick : ()=>{}}
                    legends={[
                      {
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: -20,
                        itemsSpacing: 15,
                        itemWidth: 200,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 25,
                        onClick: datum => {
                          areaTab.areaChartMode !== AreaChartMode.CUSTOMER && areaTab.handleAllAreaOnClick(datum as any)
                        },

                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000',
                            },
                          },
                        ],
                      },
                    ]}
                    activeOuterRadiusOffset={8}
                    margin={{ top: 50, right: 200, bottom: 20, left: 0 }}
                    borderWidth={2}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    colors={{ scheme: 'set3' }}
                    // arcLabelsRadiusOffset={1.4}
                    tooltip={e => (
                      <span style={{ backgroundColor: '#fff', padding: '10px 5px', borderRadius: '8px' }}>{e.datum.data.label}</span>
                    )}
                    // innerRadius={0.2}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={1}
                    arcLinkLabelsColor={{ from: 'color' }}
                    arcLinkLabelsTextOffset={2}
                    arcLabel={d => `${d.data.percentage} %`}
                    enableArcLabels={true}
                    enableArcLinkLabels={false}
                    arcLinkLabel={d => `${d.data.country || d.data.username}`}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [['darker', 2]],
                    }}
                  />
                </>
              ) : (
                <h2 style={{ color: 'var(--accent)' }}>Bu filtreye uygun veri bulunamadı.</h2>
              )}
            </section>
          </Lib.S.CategoryContainer>
        )
      case HeaderTabEnum.URETIM:
        return (
          <Lib.S.chartContainer>
            <div className="body">
              <div className="header">
                <div className="buttons">
                  {chartHeaderTabs.map((item, index) => (
                    <span
                      key={index}
                      onClick={() => tab.setTab({ label: item.label, value: item.value })}
                      className={`${item.value === tab.tab.value}`}
                    >
                      {item.label}
                    </span>
                  ))}
                </div>
              </div>
              <div className="chart-wrapper">
                <ResponsiveBar
                  data={chartProps}
                  keys={['Ağırlık']}
                  indexBy="week"
                  margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                  padding={0.3}
                  valueScale={{ type: 'linear' }}
                  indexScale={{ type: 'band', round: true }}
                  colors={['var(--accent)']}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -35,
                    legendPosition: 'middle',
                    legendOffset: 32,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Ağırlık (kg)',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor="#fff"
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 120,
                      translateY: 0,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  role="application"
                  // barAriaLabel={e => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue}
                />
              </div>
            </div>
          </Lib.S.chartContainer>
        )
      case HeaderTabEnum.KATEGORI:
        return (
          <Lib.S.CategoryContainer
            isCountryHasItems={!!categoryTab.countriesId?.length}
            isCustomerHasItems={!!categoryTab.customerIds?.length}
            isKaratItems={!!categoryTab.karatIds?.length}
          >
            <section className="header">
              <div className="dropdown" style={{ width: '80%' }}>
                <span>Bölge</span>
                <DropDown2
                  lessBorder
                  loading={categoryTab.areasLoading}
                  disabled={categoryTab.areasLoading}
                  size="middle"
                  value={categoryTab.areasId}
                  height={'41px'}
                  onChange={categoryTab.handleCustomerTabAreaChange}
                  options={categoryTab.areaDropdown}
                  removeIcon={false}
                  maxTagTextLength={7}
                />
              </div>
              <div className="dropdown">
                <span>Ülke</span>
                <DropDown2
                  lessBorder
                  className="countries-multiple"
                  loading={categoryTab.countriesLoading || categoryTab.areaSingleLoading}
                  disabled={categoryTab.countriesLoading || categoryTab.areaSingleLoading}
                  size="middle"
                  value={categoryTab.countriesId}
                  mode="multiple"
                  height={'41px'}
                  onChange={categoryTab.handleCustomerTabCountryChange}
                  options={categoryTab.countryDropdown}
                  removeIcon={false}
                  maxTagTextLength={7}
                  filterOption={(input, option) => {
                    return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }}
                />
              </div>
              <div className="dropdown">
                <span>Müşteri</span>
                <DropDown2
                  lessBorder
                  loading={categoryTab.customersLoading}
                  disabled={categoryTab.customersLoading}
                  size="middle"
                  className="customers-multiple"
                  removeIcon={false}
                  mode="multiple"
                  height="42px"
                  value={categoryTab.customerIds}
                  onChange={categoryTab.onCustomerDropDownChange}
                  maxTagTextLength={7}
                  options={categoryTab.customerDropdown}
                  filterOption={(input, option) => {
                    return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }}
                />
              </div>
              <div className="dropdown" style={{ width: '50%' }}>
                <span>Ayar</span>
                <DropDown2
                  lessBorder
                  size="middle"
                  className="karats-multiple"
                  loading={categoryTab.karatsLoading}
                  disabled={categoryTab.karatsLoading}
                  height="42px"
                  value={categoryTab.karatIds}
                  mode="multiple"
                  removeIcon={false}
                  onChange={categoryTab.onKaratDropDownChange}
                  options={categoryTab.karatDropdown}
                  filterOption={(input, option) => {
                    return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }}
                />
              </div>
              <div className="dropdown">
                <div
                  className="antDatePicker"
                  style={{ paddingLeft: '2px', paddingRight: '0px', display: 'flex', width: '100%', alignItems: 'center' }}
                >
                  <span>Başlangıç tarihi</span>
                  <DatePicker
                    // value={customerTable.startDate ? moment(customerTable.startDate, 'YYYY-MM-DD') : null}
                    onChange={(date: moment.Moment | null) => {
                      if (date) {
                        const formattedDateForServer = date.format('YYYY-MM-DD')
                        customerTable.setStartDate(formattedDateForServer)
                      } else {
                        customerTable.setStartDate('')
                      }
                    }}
                    size="small"
                    locale={locale}
                    allowClear={false}
                    className="ms-2"
                    style={{ height: 42, width: '65%', borderRadius: 7 }}
                    inputReadOnly
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>
              <div className="dropdown">
                <div
                  className="antDatePicker"
                  style={{ paddingLeft: '2px', paddingRight: '0px', display: 'flex', width: '100%', alignItems: 'center' }}
                >
                  <span>Bitiş tarihi</span>
                  <DatePicker
                    // value={customerTable.endDate ? moment(customerTable.endDate, 'YYYY-MM-DD') : null}
                    onChange={(date: moment.Moment | null) => {
                      if (date) {
                        const formattedDateForServer = date.format('YYYY-MM-DD')
                        customerTable.setEndDate(formattedDateForServer)
                      } else {
                        customerTable.setEndDate('')
                      }
                    }}
                    size="small"
                    locale={locale}
                    allowClear={false}
                    className="ms-2"
                    style={{ height: 42, width: '63%', borderRadius: 7 }}
                    inputReadOnly
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>
            </section>
            <section className="header" style={{ justifyContent: 'flex-end' }}>
              <div className="pie-btns">
                <Button text="Filtrele" callback={categoryTab.handlePieChartFilter} mood="accent" containerStyles={{ width: 140 }} />
                <Button
                  text="Filtreyi Temizle"
                  callback={categoryTab.handlePieChartFilter}
                  onMouseDown={categoryTab.handleClearPieChartFilter}
                  mood="danger"
                  containerStyles={{ width: 140 }}
                />
              </div>
            </section>
            <section className="chart">
              {categoryTab.loader ? null : categoryTab.categoryWeight?.length ? (
                <>
                  <div className="detail">
                    <h3>{categoryTab.pieChartHeader}</h3>
                    {categoryTab.chartMode === ChartMode.CATEGORY ? null : (
                      <div>
                        <Icon
                          name="backward_square"
                          size={22}
                          color="var(--accent)"
                          style={{ cursor: 'pointer' }}
                          onClick={categoryTab.handlePieChartBackwardButton}
                        />
                        <span>Koleksiyonlara geri dön</span>
                      </div>
                    )}
                  </div>

                  <ResponsivePie
                    data={categoryTab.categoryWeight!}
                    cornerRadius={3}
                    onClick={categoryTab.handleAllCategoriesOnClick}
                    legends={[
                      {
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: -20,
                        itemsSpacing: 15,
                        itemWidth: 200,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 25,
                        onClick: datum => {
                          categoryTab.handleAllCategoriesOnClick(datum as any)
                        },

                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000',
                            },
                          },
                        ],
                      },
                    ]}
                    activeOuterRadiusOffset={8}
                    margin={{ top: 50, right: 200, bottom: 20, left: 0 }}
                    borderWidth={2}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]],
                    }}
                    colors={{ scheme: 'set3' }}
                    // arcLabelsRadiusOffset={1.4}
                    tooltip={e => (
                      <span style={{ backgroundColor: '#fff', padding: '10px 5px', borderRadius: '8px' }}>{e.datum.data.label}</span>
                    )}
                    // innerRadius={0.2}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={1}
                    arcLinkLabelsColor={{ from: 'color' }}
                    arcLinkLabelsTextOffset={2}
                    arcLabel={d => `${d.data.percentage} %`}
                    enableArcLabels={true}
                    enableArcLinkLabels={false}
                    arcLinkLabel={d => `${d.data.label}`}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [['darker', 2]],
                    }}
                  />
                </>
              ) : (
                <h2 style={{ color: 'var(--accent)' }}>Bu filtreye uygun veri bulunamadı.</h2>
              )}
            </section>
            {categoryTab.chartMode === ChartMode.SUBCATEGORY_TABLE ? (
              <div>
                <section className="table" id="table">
                  <table>
                    <thead>
                      <tr>
                        <th>Ürün Kodu</th>
                        {categoryTab.pieChartHeader === ChartHeader.MONACO ? (
                          <th>
                            <div className="width">
                              Genişlık (mm)
                              <DropDown2
                                lessBorder
                                size="small"
                                height="42px"
                                mode='multiple'
                                onChange={categoryTab.onWidthChange}
                                options={categoryTab.widthOptions}
                                value={categoryTab.width}
                                filterOption={(input, option) => {
                                  return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }}
                                style={{ width: '70px' }}
                              />
                              <Icon
                                name="close_square"
                                size={18}
                                onClick={categoryTab.handleClearWidthFilter}
                                style={{ cursor: 'pointer' }}
                              />
                              <Icon
                                name="search_alt"
                                size={18}
                                onClick={categoryTab.handleWidthFilter}
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                          </th>
                        ) : null}
                        <th>
                          <div>
                            <span>Ağırlık (gr)</span>
                            <Icon
                              name={`filled_chevron_${categoryTab.weightSortIsAsc ? 'up' : 'down'}`}
                              size={12}
                              color="#ccc"
                              style={{ cursor: 'pointer', marginLeft: '5px' }}
                              onClick={() => {
                                if (!categoryTab.subCategoryTable?.length) return
                                categoryTab.setWeightSortIsAsc(perv => !perv)
                                customerTable.handleSort('weight', categoryTab.weightSortIsAsc, HeaderTabEnum.KATEGORI)
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryTab.subCategoryTable?.length ? (
                        categoryTab.subCategoryTable?.map((subCat, idx) => (
                          <tr>
                            <td>
                              <div className="image" onClick={() => categoryTab.handleOpenImageModal(idx)}>
                                {categoryTab.pieChartHeader?.includes('MonacoChain') ? (
                                  <MonacoChainAndLockPicture
                                    chainImagePath={subCat.subCategoryTypeImage!}
                                    lockImagePath={subCat.lockImage!}
                                    width={100}
                                    useThumb
                                  />
                                ) : (
                                  <img
                                    width={40}
                                    height={40}
                                    style={{ borderRadius: 5 }}
                                    src={imageAddresser(subCat.productImageThumbnail!, true)}
                                    alt={subCat.productCode}
                                    onError={e => (e.currentTarget.src = '/images/jpg/placeholder.jpg')}
                                  />
                                )}

                                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  {subCat.productCode}
                                </span>
                              </div>
                            </td>
                            {categoryTab.pieChartHeader === ChartHeader.MONACO ? <td>{subCat.width}</td> : null}
                            <td>{subCat.weight.toFixed(2)}</td>
                          </tr>
                        ))
                      ) : (
                        <div className="empty-table">
                          <Icon name="ghost" size={200} color="var(--accent)" />
                          <span>Bu filtreye uygun veri bulunamadı.</span>
                        </div>
                      )}
                    </tbody>
                  </table>
                </section>
                <div className="footer">
                  <span className="title">Toplam Ağırlık </span> : <span>{categoryTab.subCategoryTotalWeightState} gr</span>
                </div>
              </div>
            ) : null}
            {categoryTab.showImageModal ? (
              <Modal {...categoryTab.imageModalProps}>
                <ImageListSlider
                  imageDetail={categoryTab.modalForAllImages}
                  hideCloseIcon
                  baseUrl={process.env.REACT_APP_DOMAIN}
                  activeIndex={categoryTab.imageActiveIndex}
                />
              </Modal>
            ) : null}
          </Lib.S.CategoryContainer>
        )
      case HeaderTabEnum.MUSTERI:
        return (
          <Lib.S.TableWrapper>
            <div className="filters">
              <div className="dropdown">
                <span>Ülke</span>
                <DropDown2
                  lessBorder
                  size="small"
                  height="42px"
                  value={customerTable.countryId}
                  onChange={customerTable.onCountryDropDownChange}
                  options={customerTable.countryDropdown}
                  filterOption={(input, option) => {
                    return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }}
                />
              </div>
              <div className="dropdown">
                <div
                  className="antDatePicker"
                  style={{ paddingLeft: '2px', paddingRight: '0px', display: 'flex', width: '100%', alignItems: 'center' }}
                >
                  <span>Başlangıç tarihi</span>
                  <DatePicker
                    // value={customerTable.startDate ? moment(customerTable.startDate, 'YYYY-MM-DD') : null}
                    onChange={(date: moment.Moment | null) => {
                      if (date) {
                        const formattedDateForServer = date.format('YYYY-MM-DD')
                        customerTable.setStartDate(formattedDateForServer)
                      } else {
                        customerTable.setStartDate('')
                      }
                    }}
                    size="small"
                    locale={locale}
                    allowClear={false}
                    className="ms-2"
                    style={{ height: 42, width: '65%', borderRadius: 7 }}
                    inputReadOnly
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>
              <div className="dropdown">
                <div
                  className="antDatePicker"
                  style={{ paddingLeft: '2px', paddingRight: '0px', display: 'flex', width: '100%', alignItems: 'center' }}
                >
                  <span>Bitiş tarihi</span>
                  <DatePicker
                    // value={customerTable.endDate ? moment(customerTable.endDate, 'YYYY-MM-DD') : null}
                    onChange={(date: moment.Moment | null) => {
                      if (date) {
                        const formattedDateForServer = date.format('YYYY-MM-DD')
                        customerTable.setEndDate(formattedDateForServer)
                      } else {
                        customerTable.setEndDate('')
                      }
                    }}
                    size="small"
                    locale={locale}
                    allowClear={false}
                    className="ms-2"
                    style={{ height: 42, width: '63%', borderRadius: 7 }}
                    inputReadOnly
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>
              <div style={{ flex: 0.5 }}>
                <Button text="Filterle" callback={customerTable.handleFilter} mood="accent" containerStyles={{ width: 100 }} />
              </div>
              <Button
                text="Filteryi Temizle"
                callback={customerTable.handleClearFilter}
                mood="orang"
                containerStyles={{ width: 120 }}
                onMouseDown={() => {
                  customerTable.setCountryId(undefined)
                  customerTable.setStartDate(undefined)
                  customerTable.setEndDate(undefined)
                  customerTable.setPaginate(perv => ({ ...perv, page: 1 }))
                }}
              />
            </div>
            <InfiniteScroll
              dataLength={customerTable.customerTableData?.length || 0}
              next={customerTable.handlePagination}
              hasMore={customerTable.totalData! / customerTable.paginate.page > customerTable?.paginate.limit}
              loader={''}
              height={'75vh'}
            >
              <table>
                <thead>
                  <tr>
                    {customerTable.customerTableHeader.map(header => (
                      <th key={header.name}>
                        <div className="header">
                          <span>{header.name}</span>
                          {['weight', 'orderEfficiency', 'stoneProfit'].includes(header.sortBy) ? (
                            <Icon
                              name={`filled_chevron_${header.isASC ? 'up' : 'down'}`}
                              size={12}
                              color="#ccc"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                customerTable.handleToggleSort(header.name)
                                customerTable.handleSort(header.sortBy, header.isASC, HeaderTabEnum.MUSTERI)
                              }}
                            />
                          ) : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {customerTable.customerTableData?.map(table => (
                    <tr>
                      <td>{table.company}</td>
                      <td>{parseFloat(table.weight)}</td>
                      <td>
                        <div className="image">
                          {table.customerRankImage.imageThumbnail && (
                            <img
                              src={imageAddresser(table.customerRankImage?.imageThumbnail, true)}
                              alt={table.company}
                              onError={e => (e.currentTarget.src = '/images/jpg/placeholder.jpg')}
                            />
                          )}
                        </div>
                      </td>
                      <td>{parseFloat(table.orderEfficiency)}</td>
                      <td>{formatter.format(parseFloat(table.stoneProfit))}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </InfiniteScroll>
          </Lib.S.TableWrapper>
        )

      default:
        return null
    }
  }

  return (
    <Lib.S.Wrapper>
      <div className="tabs">
        {header.headerTabs.map((tab, index) => (
          <>
            <div
              className={`tab ${tab === header.headerActiveTab ? 'active' : undefined}`}
              onClick={() => header.handleChangeHeaderActiveTab(tab)}
            >
              {tab}
            </div>
          </>
        ))}
      </div>
      {renderHeaderTabs()}
    </Lib.S.Wrapper>
  )
}
