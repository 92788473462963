import { FC } from 'react'
import { Icon } from '../icon'
import * as Lib from './lib'

export const AvatarUploader: FC<Lib.T.UploaderProps> = ({ onChange, id, defaultImage, style }): JSX.Element => {
  const dnd = Lib.H.useUploader({ onChange })

  return (
    <>
      <input type="file" hidden id={id} onChange={dnd.handleFileChange} multiple={false} accept="image/*" />
      <Lib.S.container
        style={style}
        className="avatar-uploader"
        onDrop={dnd.onDrop}
        onDragOver={dnd.onDragOver}
        onDragLeave={dnd.onDragLeave}
        isOver={dnd.isOver}
        onClick={() => dnd.openDialog(id)}
        img={dnd.b64State ? dnd.b64State : (defaultImage || '/images/png/choose-avatar.png')}
        hasImg={defaultImage !== undefined || dnd.b64State !== null}
      >
        {/*<span onClick={dnd.handleRemove}>
          <Icon name="plus" color="white" size={12} />
        </span>*/}
      </Lib.S.container>
    </>
  )
}
