import { Select } from 'antd'
import { FC } from 'react'
import * as Lib from './lib'

const { Option } = Select

export const DropDown2: FC<Lib.T.DropDownProps> = ({
  containerStyles,
  label,
  labelStyles,
  lessBorder,
  className,
  compactView,
  children,
  options,
  height,
  key,
  value,
  haveError,
  ...otherProps
}): JSX.Element => {
  return (
    <>
      <Lib.S.DropdownContainer
        className={className}
        style={containerStyles}
        lessBorder={lessBorder}
        compactView={compactView}
        height={height}
        key={key}
        hasOptions={typeof value === 'string' ? false : !!(value as string[])?.length}
        haveError={haveError}
      >
        {label ? <span style={labelStyles}>{label}</span> : null}
        <Select
          showSearch
          filterOption={(inputValue, opt) => {
            return options?.some(
              item =>
                item.id === opt?.value &&
                item.title.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
            ) || false;
          }}
          {...otherProps}
          defaultValue={value}
          value={value}
        >
          {options?.map((item, index) => {
            return (
              <Option value={item.id} key={index}>
                {item.title.toString() !== '' ? item.title.toString() : '-'}
              </Option>
            )
          })}
        </Select>
      </Lib.S.DropdownContainer>
    </>
  )
}
