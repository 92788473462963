import styled from 'styled-components'
import { mediaQueries as mq, createQuery as cq } from 'common/constants/media-queries'
import * as Lib from '.'

const SPACE_BETWEEN_LARGE = '60px'
const SPACE_BETWEEN_SMALL = '20px'

export const contentContainer = styled.div<Pick<Lib.T.ContentProps, 'padding'>>`
  float: left;
  width: 100%;
  padding: ${({ padding }) => padding || '10px 30px'};

  ${cq(500, 'max')} {
    padding: 20px 15px;
  }

  > div {
    > .section {
      float: left;
      height: auto;

      &:not(.createProduct, .full) {
        width: 50% !important;
        padding: 10px;

        ${mq.max[992]} {
          width: 100% !important;
        }
      }
    }

    > .inputs {
      float: left;
      width: 50%;
      padding: 10px;

      &:nth-child(1) {
        padding-right: ${SPACE_BETWEEN_LARGE};
      }

      &:nth-child(2) {
        padding-left: ${SPACE_BETWEEN_LARGE};
      }

      ${mq.max[1200]} {
        &:nth-child(1) {
          padding-right: ${SPACE_BETWEEN_SMALL};
        }

        &:nth-child(2) {
          padding-left: ${SPACE_BETWEEN_SMALL};
        }
      }

      ${mq.max[992]} {
        padding: 8px !important;
        width: 100%;
      }
    }
  }
`
