import styled from 'styled-components'
import * as Lib from './'
import { mediaQueries as mq } from 'common/constants/media-queries'

export const buttonContainer = styled.div<Lib.T.ButtonContainerProps>`
  float: left;
  width: 100%;
  height: auto;

  > .parent {
    min-height: 42px;
    float: left;
    width: 100%;
    border: 3px solid ${({ mood }) => Lib.HE.Detect.borderColor({ mood })};
    border-radius: 7px;
    background: ${({ mood }) => Lib.HE.Detect.background({ mood })};
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    cursor: pointer;

    * {
      color: ${({ mood }) => Lib.HE.Detect.color({ mood })};
      color: ${({ mood }) => Lib.HE.Detect.color({ mood })};
    }
  }

  ${mq.max[768]} {
    font-size: 10pt;

    > .parent {
      min-height: 40px;
    }
  }
`
