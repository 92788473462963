import { DatePicker } from 'antd'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import EmptyList from 'common/components/ui-kit/empty-list'
import { Input } from 'common/components/ui-kit/input'
import { Sticky } from 'common/components/ui-kit/sticky'
import { numberInputTypeGuard } from 'common/helpers/numberInputTypeGuard'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link, useHistory } from 'react-router-dom'
import * as Lib from './lib'

const { RangePicker } = DatePicker

function LogsList() {
  const { get, set, on } = Lib.H.useList()
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <PanelWrapper tab={['logs', '/logs']} title="Logs">
      <Sticky>
        <span style={{ fontWeight: 'bold' }}>Logs</span>
      </Sticky>
      <Lib.S.Wrapper>
        {/* inputs */}
        <section className="inputs">
          <div className="row">
            <div className="col-lg-3">
              <Input
                label="Sipariş No"
                value={numberInputTypeGuard(get.filterData?.orderId) ? get.filterData?.orderId : ''}
                onChange={e => set.setFilterData(perv => ({ ...perv, orderId: parseInt(e.target.value) }))}
                type="number"
                lessBorder
                onFocus={e =>
                  e.target.addEventListener(
                    'wheel',
                    function (e) {
                      e.preventDefault()
                    },
                    { passive: false },
                  )
                }
              />
            </div>
            <div className="date-picker col-lg-4 d-flex align-items-end ">
              <RangePicker
                showTime
                value={[moment(get.filterData.from), moment(get.filterData.to)]}
                onChange={e => {
                  set.setFilterData(perv => ({ ...perv, from: e![0]?.toISOString(), to: e![1]?.toISOString() }))
                }}
                clearIcon={false}
              />
            </div>
            <div className="col-lg-2 d-flex align-items-end ">
              <Button text="Filter" mood="accent" callback={on.handleFilter} containerStyles={{ minHeight: '44px' }} />
            </div>
            <div className="col-lg-2 d-flex align-items-end ">
              <Button text="Filter Temizle" mood="orang" callback={on.handleClearFilter} containerStyles={{ minHeight: '44px' }} />
            </div>
          </div>
        </section>
        {/* list table */}
        <section className="table">
          {!get.list?.length && get.loader ? null : !get.list?.length && !get.loader ? (
            <EmptyList text="Log bulunmamaktadır" />
          ) : (
            <InfiniteScroll
              dataLength={get?.list?.length}
              next={on.handleShowMore}
              hasMore={get?.totalData! / get?.filterData.paginate.page > get?.filterData.paginate.limit}
              loader={''}
              height={'75vh'}
            >
              <table>
                <thead>
                  <tr>
                    <th>Sipariş No</th>
                    <th>Type</th>
                    <th>Date and Time</th>
                    <th>Operator</th>
                  </tr>
                </thead>
                <tbody>
                  {get.list?.map(list => {
                    return (
                      <tr key={list.id}>
                        {/* Links are display:table-cell */}
                        <Link to={`/logs/log-details/${list.id}`}>
                          <div>{list.orderId}</div>
                        </Link>
                        <Link to={`/logs/log-details/${list.id}`}>
                          <div>{t(list.type)}</div>
                        </Link>
                        <Link to={`/logs/log-details/${list.id}`}>
                          <div>{moment(list.createdAt).format('YYYY/MM/DD - HH:mm')}</div>
                        </Link>
                        <Link to={`/logs/log-details/${list.id}`}>
                          <div>{list.creator.name}</div>
                        </Link>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </InfiniteScroll>
          )}
        </section>
      </Lib.S.Wrapper>
    </PanelWrapper>
  )
}

export default LogsList
