import { Content } from 'antd/lib/layout/layout'
import Modal from 'antd/lib/modal/Modal'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Icon } from 'common/components/ui-kit/icon'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from './lib'
import { useServiceIds } from './lib/hooks'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Table } from 'common/components/ui-kit/table-2'
import { Textarea } from 'common/components/ui-kit/textarea'

function AutomationServiceIds() {
  const { val, set, on, get } = useServiceIds()

  return (
    <PanelWrapper tab={['automation', '/automation/serviceIds']}>
      <Sticky>
        <Lib.S.searchSection>
          <p className="title">Servisler</p>
          <div>
            <div className="inputContainer">
              <Input
                type="search"
                placeholder="Ara..."
                lessBorder
                value={val.searchQuery}
                onChange={e => set.setSearchQuery(e.target.value)}
              />
            </div>
            <Button
              className="buttonContainer"
              icon="search"
              mood="accent"
              callback={() => set.setFetchAgain(perv => !perv)}
              iconProps={{ color: 'white', size: 20 }}
            />
          </div>

          <div className="d-flex w-100 justify-content-end gap-2" style={{ maxWidth: 'unset' }}>
            <div style={{ flex: 0.2 }}>
              <Button
                mood="accent"
                callback={() => {
                  set.setModalProps({ ...val.modalInitialState, kind: 'create' })
                  on.handleCancelEditModal()
                }}
                text="Yeni servis"
                icon="plus"
                iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
                containerStyles={{ minHeight: 40, width: 150 }}
              />
            </div>
          </div>
        </Lib.S.searchSection>
      </Sticky>
      <Content>
        <Lib.S.PageWrapper>
          <Lib.S.content>
            <div className="tableContainer">
              <Table
                columns={val.tableColumns}
                columnSizes={val.tableSizes}
                data={val.tableData}
                onLoadMore={on.handleShowMore}
                totalData={get.totalData}
                paginate={get.paginate}
                rowBackColor={false}
                disabledIndexes={[val.tableIdIndex, val.tableIdIndex - 1]}
                maxHeight="calc(100vh - 110px)"
              />
            </div>
          </Lib.S.content>
        </Lib.S.PageWrapper>

        {/* edit or cerate modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showEditModal}
          onCancel={on.handleCancelEditModal}
          className="noHeaderModal userModal"
          width={1000}
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>{val.modalProps.kind === 'edit' ? 'Sürücüyü düzenle' : 'Yeni servis'}</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={on.handleCancelEditModal} style={{ cursor: 'pointer' }} />
            </div>
            <div className="row mt-2 px-3">
              <div className="col-lg-4">
                <Input
                  label="İsim"
                  onChange={e => {
                    set.setName(e.target.value)
                  }}
                  required
                  lessBorder
                  value={val.name}
                  inputStyles={
                    val.isFieldEmpty && val.name.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' } : undefined
                  }
                  labelStyles={val.isFieldEmpty && val.name.length < 1 ? { color: 'red' } : undefined}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  label="Sürücü Adı"
                  required
                  onChange={e => {
                    set.setDriverName(e.target.value)
                  }}
                  lessBorder
                  value={val.driverName}
                  inputStyles={
                    val.isFieldEmpty && val.driverName.length < 1
                      ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' }
                      : undefined
                  }
                  labelStyles={val.isFieldEmpty && val.driverName.length < 1 ? { color: 'red' } : undefined}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  label="Kod"
                  type="number"
                  onChange={e => {
                    set.setCode(e.target.value)
                  }}
                  lessBorder
                  required
                  value={val.code}
                  inputStyles={
                    val.isFieldEmpty && val.code.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' } : undefined
                  }
                  labelStyles={val.isFieldEmpty && val.code.length < 1 ? { color: 'red' } : undefined}
                />
              </div>
            </div>
            <div className="row px-3">
              <div className="col-lg-4">
                <Input
                  label="Telefon"
                  onChange={e => {
                    set.setDriverPhoneNumber(e.target.value)
                  }}
                  lessBorder
                  required
                  minLength={13}
                  value={val.driverPhoneNumber}
                  inputStyles={
                    val.isFieldEmpty && val.driverPhoneNumber.length < 1
                      ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' }
                      : undefined
                  }
                  labelStyles={val.isFieldEmpty && val.driverPhoneNumber.length < 1 ? { color: 'red' } : undefined}
                />
                <span style={{ fontSize: 13, color: val.isFieldEmpty && val.driverPhoneNumber.length < 1 ? 'red' : '#7a7a7a' }}>
                  Örnek: +909999999999
                </span>
              </div>
              <div className="col-lg-4">
                <Input
                  label="Kapasite"
                  onChange={e => {
                    const value = e.target.value
                    set.setCapacity(value === '' ? '' : Number(value))
                  }}
                  lessBorder
                  required
                  min={1}
                  type="number"
                  value={val.capacity}
                  onFocus={e =>
                    e.target.addEventListener(
                      'wheel',
                      function (e) {
                        e.preventDefault()
                      },
                      { passive: false },
                    )
                  }
                  inputStyles={
                    val.isFieldEmpty && !val.capacity ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' } : undefined
                  }
                  labelStyles={val.isFieldEmpty && !val.capacity ? { color: 'red' } : undefined}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  label="Araç Plakası"
                  onChange={e => {
                    set.setPlate(e.target.value)
                  }}
                  lessBorder
                  required
                  minLength={1}
                  min={1}
                  value={val.plate}
                  inputStyles={
                    val.isFieldEmpty && val.plate.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' } : undefined
                  }
                  labelStyles={val.isFieldEmpty && val.plate.length < 1 ? { color: 'red' } : undefined}
                />
              </div>
            </div>

            <div className="row px-3 mt-2">
              <Textarea
                label="Açıklama"
                lessBorder
                rows={1}
                className="description"
                value={val.description}
                onInput={evt => set.setDescription(evt.currentTarget.value)}
              />
            </div>

            <div className="btn">
              <Button
                text="Vazgeç"
                mood="accent_outline"
                callback={() => {
                  on.handleCancelEditModal()
                }}
                className="btn"
              />
              <Button text="Düzenle" mood="accent" callback={() => on.CreateOrEditServiceId()} className="btn" />
            </div>
          </Lib.S.ModalContainer>
        </Modal>

        {/* Delete modal */}
        <Modal visible={val.showDeleteModal} onCancel={on.handleDeleteModalVisibility} footer={null} title="Servisi Sil">
          <Lib.S.DeleteModalContent>
            <p>Servisi silmek istediğinizden emin misiniz?</p>
            <div>
              <Button text="Vazgeç" mood="accent" callback={() => set.setShowDeleteModal(perv => !perv)} />
              <Button text="Sil" mood="danger" callback={() => on.DeleteServiceId(val.modalProps.id)} />
            </div>
          </Lib.S.DeleteModalContent>
        </Modal>
      </Content>
    </PanelWrapper>
  )
}

export default AutomationServiceIds
