import React from 'react'
import { Route } from 'react-router-dom'
import { routes } from './routes'
import { Helmet } from 'react-helmet'
import { appName } from 'common/constants/strings'

export const Public: React.FC = (): JSX.Element => {
  return (
    <>
      {Object.keys(routes.public).map((key, index) => {
        const { return: page, get: route, title } = routes.public[key]
        return (
          <React.Fragment key={index}>
            <Route path={route}>
              <Helmet>
                <title>
                  {title} | {appName}
                </title>
              </Helmet>
              {page}
            </Route>
          </React.Fragment>
        )
      })}
    </>
  )
}
