import styled from 'styled-components'

import { mediaQueries as mq, createQuery as cq } from 'common/constants/media-queries'

export const container = styled.div``

export const contentBody = styled.div`
  display: flex;
  float: right;
  flex-direction: row-reverse;
  width: 100%;

  ${mq.max[768]} {
    display: block;
    float: right;
  }
`

export const content = styled.div`
  flex: 1;

  ${mq.max[768]} {
    width: 100%;
    float: right;
    padding: 0;
    margin-top: 10px;
  }

  > .tableContainer {
    float: left;
    width: 100%;
    margin: 10px 0 0 0;
    border-radius: 15px;
    padding: 0;

    .body {
      > .td {
        :first-child {
          background-color: #fff !important;
        }
      }
    }

    .tableContainer {
      padding: 0 0px;

      .filter {
        border: none;
        background: #f8f8f8 !important;
      }
    }

    > p {
      margin: 0;
      padding: 5px 0 15px 25px;
      font-size: 19px;
      color: #252733;
      font-family: var(--f-se-bo);
    }
  }
`

export const tableImage = styled.div`
  width: 30px;
  height: 30px;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  ${mq.max[992]} {
    width: 60px;
    height: 60px;
  }
`

export const tableButton = styled.span`
  display: block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.effect {
    svg {
      transition: all 150ms linear;
    }

    &.rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &:hover {
    background: #f8f8f8;
  }
`

export const tableButtons = styled.div`
  display: flex;
  align-items: center;
`

export const modal = styled.div<{ priceCondition: boolean }>`
  float: left;
  width: 100%;
  height: auto;
  min-width: 912px;

  > .header {
    float: left;
    width: 100%;
    padding: 0 17%;

    > div {
      float: left;
      width: calc(100% / ${({ priceCondition: pc }) => (pc ? 3 : 2)});
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      border-bottom: 1px solid #b7b7b759;

      > p {
        float: left;
        height: 100%;
        margin: 0;
        text-align: left;

        &.key {
          color: black;
          margin: 0 10px 0 0;
        }

        &.value {
          color: var(--accent);
          font-family: var(--f-se-bo);
        }
      }
    }
  }

  .tableContainer {
    float: left;
    width: 100%;
    margin-top: 20px;

    td > .DD {
      > div {
        max-width: 100px;
      }

      .dropdownContainer {
        height: 45px;
        border: 1px solid rgba(0, 78, 66, 0.32) !important;
      }

      .icon {
        width: 25px;
      }

      .optionsContainer {
        > span {
          padding: 0 5px;
          font-size: 14px;
        }
      }
    }

    .price {
      height: 45px;
      text-align: center;
      border: 1px solid rgba(0, 78, 66, 0.32) !important;
      border-radius: 5px;
      background: rgba(0, 78, 66, 0.01);
    }

    .explain {
      background-color: #f8f8f8;
      text-align: center;
      border: none;
      min-height: 45px;
      width: 244px;
      padding: 7px;
      height: 45px;
    }

    td {
      text-align: center;
    }

    tbody {
      tr:nth-child(1) {
        td {
          padding-top: 14px;
        }
      }

      td {
        border: none;
        padding: 3px 4px;
      }

      tr {
        > td:nth-child(1) {
          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 45px;
            border: 1px solid rgba(0, 78, 66, 0.32) !important;
            border-radius: 7px;
          }
        }
      }
    }
  }

  .footer {
    float: left;
    width: 100%;
    height: auto;
    padding-top: 10px;

    > div {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      float: left;
      padding: 0 7px;

      &:nth-child(2) {
        justify-content: flex-start;
      }

      > div {
        width: unset;
        height: unset;

        > div {
          padding: 0 30px;
        }
      }
    }
  }
`

export const searchSection = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  > p {
    margin: 0 20px 0 0;
    font-size: 26px;
    font-family: var(--f-bo);
  }

  > div {
    flex: 1;
    max-width: 500px;
    display: flex;
    align-items: center;

    > .inputContainer {
      flex: 1;
      position: relative;

      > .filterButton {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 45px;
        right: 0;
        display: none;
        align-items: center;
        justify-content: center;

        ${mq.max[768]} {
          display: flex;
        }
      }
    }

    > .buttonContainer {
      width: unset;
      margin: 0 0 0 5px;

      > .parent {
        padding: 0;
        min-height: unset;
        width: 45px;
        height: 45px;
      }
    }
  }
`

