import { FC } from 'react'
import * as Lib from './lib'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Content } from 'common/components/ui-kit/content'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Button } from 'common/components/ui-kit/button'
import { Input } from 'common/components/ui-kit/input'
import { Icon } from 'common/components/ui-kit/icon'
import { Modal } from 'antd'

export const MonacoList: FC = () => {
  const { search, get, set, on } = Lib.H.useMonacoList()

  return (
    <PanelWrapper tab={['products', '/products/list']} title="Ürünler" header={false}>
      <Sticky>
        <Lib.S.StickyContainer>
          <div className="header">
            <div className="left">
              <p className="title">Monaco Ürünler</p>
              <div className="search-container">
                <Input
                  type="text"
                  icon="search"
                  className="search-input"
                  placeholder="Type Here..."
                  value={search.query}
                  onChange={e => search.setQuery(e.target.value)}
                  iconProps={{ size: 15 }}
                  // onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  //   if (search.query && e.key === 'Enter') {
                  //     fetch.on.setFetchAgain(perval => !perval)
                  //   }
                  // }}
                />
                <Button
                  icon="search_alt"
                  mood="accent"
                  callback={() => {}}
                  // onClick={() => fetch.on.setFetchAgain(perval => !perval)}
                  className="tapToSearch"
                  iconProps={{ color: 'white', size: 17 }}
                />
              </div>
            </div>
            <div className="right">
              <span onClick={() => set.setGridView(true)}>
                <Icon color={get.gridView ? '#4D4D4D' : '#004E4280'} name="four_squares" size={23} />
              </span>
              <span onClick={() => set.setGridView(false)}>
                <Icon color={get.gridView ? '#004E4280' : '#4D4D4D'} name="list_view" size={28} />
              </span>
            </div>
          </div>
        </Lib.S.StickyContainer>
      </Sticky>
      <Content padding="0">
        <Modal {...get.sendToCartModalProps}>
          <Lib.C.AddMonacoProductToCart
            height={get.height}
            width={get.width}
            pieceWidthHeight={get.toCompare}
            handleCountInputs={on.handleCountInputs}
            handleSubmitOrder={on.handleSubmitOrder}
            handleChangeInputsState={on.handleChangeInputsState}
            karats={get.karats}
            handleKaratDropdown={on.handleKaratDropdown}
            handleChangeInputStateForCalcWeight={on.handleChangeInputStateForCalcWeight}
            weight={get.weight}
            handleCalculateWeight={on.handleCalculateWeight}
          />
        </Modal>
        <Lib.S.container>
          <></>
          <Lib.C.MonacoChainList
            subCategory={get.allSubCategory}
            selectedMonaco={get.categoryId}
            setSelectedMonaco={set.setCategoryId}
            fetchTypes={on.fetchTypes}
          />
          <Lib.C.SelectChainAndLock
            selectChainImage={on.selectChainImage}
            scroll={on.scroll}
            selectLockImage={on.selectLockImage}
            chainImages={get.chainImages}
            lockImages={get.lockImages}
          />
          <div className="orderContent">
            <div className="fw-bold">Classic / Swarovski Alternate / Swarovski Kilit</div>
            <div style={{ width: 40, height: 40 }}>
              <Button
                icon="cart"
                mood="accent"
                callback={on.handleShowAddToCartModal}
                iconProps={{ color: '#fff' }}
                containerStyles={{ minHeight: 40 }}
              />
            </div>
          </div>
        </Lib.S.container>
      </Content>
    </PanelWrapper>
  )
}
