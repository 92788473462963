import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'

export class LeaveTypeService {
  accessToken: string
  endpoint = 'automation/leave-type'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      LeaveTypeService.dontAccept()
    }
    if (!user!.accessToken) {
      LeaveTypeService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async getList(): Promise<Lib.T.LeaveType.GetListResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    })
    if (status === 200) {
      return { success: true, leaveTypes: data.leaveTypes, total: data.total }
    }
    return { success: false, total: 0, leaveTypes: [] }
  }

  async create(args: Lib.T.LeaveType.CreateArgs): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    })
    return status === 201
  }

  async edit(args: Lib.T.LeaveType.EditArgs, id: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    })
    return status === 200
  }

  async delete(id: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
    })
    return status === 200
  }

  static dontAccept() {
    throw 'not-acceptable'
  }
}
