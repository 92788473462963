import { appName } from 'common/constants/strings'
import React from 'react'
import * as Lib from './lib'

export const AuthContainer: React.FC<Lib.T.AuthContainer> = ({ title, description, children }): JSX.Element => {
  return (
    <>
      <Lib.S.container>
        <img className="world-map" src="/images/png/world-map.png" alt="" />

        <div className="stuffContainer">
          <img src="/images/png/blur.png" alt="" className="blur" />
          <div className="header">
            <div className="header-image">
              <img src="/images/logo/login-logo.png" alt={appName} draggable={false} />
            </div>
          </div>

          <div className="body">{children && children}</div>
        </div>
        <div className="logoContainer">
          <div className="image-container">
            <img src="/images/logo/login-sozer-logo.png" alt={appName} draggable={false} />
          </div>
        </div>
      </Lib.S.container>
    </>
  )
}
