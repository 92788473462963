import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import { DOM } from 'common/helpers/dom'
import swal from 'sweetalert'

export class BaklaService {
  accessToken: string
  endpoint = 'baklas'
  subEndpoint = 'sub-baklas'
  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      BaklaService.dontAccept()
    }
    if (!user!.accessToken) {
      BaklaService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }
  // Bakla
  async create({ title, image }: Lib.T.Bakla.CreateArgs): Promise<Lib.T.Bakla.CreateResult> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: {
        title,
        image,
      },
    })

    const { status, data } = response
    if (status !== 201 || !data) {
      swal({
        title: 'Alt Bakla oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, data: data }
  }

  async getList(args?: Lib.T.Bakla.GetListArgs): Promise<Lib.T.Bakla.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response
    if (status !== 200 || !data.baklas) {
      swal({
        title: 'Alt Bakla oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, data: data.baklas }
  }

  async delete(id: number, subBakla?: boolean): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(subBakla ? `${this.subEndpoint}/${id}` : `${this.endpoint}/${id}`),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      swal({
        title: 'Alt bakla silinemedi. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
    }

    return status === 200
  }

  async edit(id: number, args: Lib.T.Bakla.EditArgs): Promise<Lib.T.Bakla.EditResult> {
    const { status, data } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endpoint}/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    })

    if (status !== 200 || !data) {
      swal({
        text: 'Alt bakla düzenlenemedi. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })

      return { success: false }
    }

    return { success: true, data: data }
  }

  // sub Bakla

  // here
  async createSubBakla(args: Lib.T.Bakla.CreateSubBaklaArgs): Promise<Lib.T.Bakla.ResponseCreateSubBakla> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subEndpoint),
      headers: { authorization: this.accessToken },
      data: {
        ...args,
      },
    })

    const { status, data } = response

    if (status !== 201 || !data.subBakla) {
      swal({
        title: 'Alt Bakla oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false, error: data }
    }

    return { success: true, data: data.subBakla, error: null }
  }
  async editSubBakla(id: number, args: Lib.T.Bakla.EditSubBaklaArgs): Promise<Lib.T.Bakla.EditSubBaklaResult> {
    const { status, data } = await ApiCaller({
      method: 'PUT',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.subEndpoint}/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    })

    if (status !== 200 || !data.subBakla) {
      swal({
        text: 'Alt Bakla düzenlenemedi. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })

      return { success: false }
    }

    return { success: true, data: data.subBakla }
  }
  // here

  async getSubBakla(args?: Lib.T.Bakla.GetListArgs): Promise<Lib.T.Bakla.SubBaklaResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subEndpoint).concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response
    if (status !== 200 || !data.subBaklas) {
      swal({
        title: 'Alt Bakla oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, data: data.subBaklas, total: data.total }
  }
  // here
  async findOneSubBakla(id: number): Promise<Lib.T.Bakla.FindOneResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.subEndpoint}/${id}`),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200 || !data.subBakla) {
      swal({
        text: 'we couldnt get the sub Bakla details',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }

    return {
      success: true,
      subBakla: data.subBakla,
    }
  }
  static dontAccept() {
    throw 'not-acceptable'
  }
}
