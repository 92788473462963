import styled, { keyframes } from 'styled-components'

const loadingAnimation = keyframes`
0%{
  transform: rotateY(0) scale(0.5);
opacity: 0;
}
10%{
    transform: rotateY(0) scale(0.6);
    opacity: 0.5;
}
30%{
  transform: rotateY(90deg) scale(0.6);
  opacity: 1;
}
50%{
  transform: rotateY(180deg) scale(0.6);
  opacity: 1;
}
70%{
  transform: rotateY(280deg) scale(0.6);
  opacity: 0.5;
}
90%{
  transform: rotateY(300deg) scale(0.55);
  opacity: 0.2;
}
100%{
  transform: rotateY(360) scale(0.5);
  opacity: 0;
}
`

const Rotate = keyframes`
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  
`

export const container = styled.div`
  position: fixed;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .top {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }

  h1 {
    font-size: 100px;
    color: var(--accent);
  }

  .bottom {
    /* margin-top: 20px; */
  }

  .O {
    animation: ${Rotate} 2s linear infinite;
  }
`
