/* eslint-disable @typescript-eslint/no-unused-expressions */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../button'
import { Icon } from '../icon'
import { Spin } from 'antd';
import * as Lib from './lib'

export const Table: FC<Lib.T.TableProps> = ({
  columns,
  data,
  footer,
  rowBackColor = true,
  rowBorder,
  unActivatedIndex,
  maxHeight,
  className,
  onRowClick,
  viewButton,
  idIndex,
  disabledIndexes,
  onLoadMore,
  paginate,
  totalData,
  sortable,
  columnWidths,
  headerHeight,
  striped,
  sortByHeight,
  sortByWidth,
  contentWidths,
  Containerstyle,
  loader
}): JSX.Element => {
  return (
    // <Scrollbar style={{ ...Containerstyle, width: '100%', height: maxHeight || 500 }}>
    <Lib.S.container rowBackColor={rowBackColor} rowBorder={rowBorder} className={className} style={{ maxHeight }}>
      <div className="tableContainer">
        <div className="tr header">
          {columns?.map((column, index) => (
            <div
              className={`td header ${sortable && sortable.acceptableColumns.includes(index)}`}
              style={{
                width: columnWidths && columnWidths[index] ? columnWidths[index] : 'unset',
                textAlign: columnWidths && columnWidths[index] ? 'center' : 'unset',
                height: headerHeight || '55px',
                ...(((sortByHeight || sortByWidth) && index === 3) || index === 4 ? { cursor: 'pointer' } : null),
              }}
              onClick={() => {
                sortable &&
                  sortable.onClick(
                    column + '',
                    index,
                    index === sortable.activeColumn
                      ? sortable.status === 'ASC'
                        ? 'DESC'
                        : 'ASC'
                      : sortable.status === 'NONE'
                      ? 'ASC'
                      : sortable.status,
                    sortable.status,
                  )
              }}
              key={index}
            >
              <div className="head-wrapper">
                {sortable && sortable.acceptableColumns.includes(index) && sortable.status !== 'NONE' && (
                  <span>
                    <Icon size={18} name={sortable.status === 'ASC' ? 'arrow-up' : 'arrow-down'} />
                  </span>
                )}

                <span>{column}</span>
              </div>
            </div>
          ))}
        </div>
        {data?.map((item1, index1) => {
          return (
            <div
              className={`tr body ${unActivatedIndex ? (item1[unActivatedIndex] ? 'disabled' : '') : ''} ${
                striped && index1 % 2 === 0 ? 'backgroundWhite' : ''
              }`}
              key={index1}
              onClick={() => {
                if (onRowClick && idIndex) {
                  onRowClick(parseInt(item1[idIndex]?.toString() || '') || 0)
                } else {
                  if (onRowClick) onRowClick(index1)
                }
              }}
            >
              {item1.map((item2, index2) => {
                if (!disabledIndexes?.includes(index2)) {
                  return (
                    <div className="td" key={index2}>
                      <div
                        className="content"
                        style={{
                          height: '100%',
                          textAlign: columnWidths && columnWidths[index2] ? 'center' : 'unset',
                          width: contentWidths ? contentWidths[index2] : 'auto',
                        }}
                      >
                        {item2}
                      </div>
                    </div>
                  )
                }
              })}

              {viewButton && idIndex && (
                <div className="td">
                  <div className="content" style={{ padding: '9px 0' }}>
                    <Link to={`/${viewButton}/${item1[idIndex]}`} className="tableButton">
                      <Icon name="eye" size={20} />
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )
        })}
        {loader && <div className='tr body filterContainer'>
          <div className='filter'>
            <Spin />
          </div>
        </div>}
        {paginate && data.length > 0 && (
          <div className="tr body filterContainer">
            <div className="filter td">
              <Button
                mood={totalData! / paginate.page <= paginate.limit ? 'gray' : 'accent'}
                callback={onLoadMore}
                text={totalData! / paginate.page <= paginate.limit ? 'daha fazla yok' : 'Daha fazla göster'}
              />
            </div>
          </div>
        )}
        {footer && (
          <div className="tr footer">
            {columns.map((column, index) => (
              <div className="td footer" key={index}>
                {column}
              </div>
            ))}
          </div>
        )}
      </div>
    </Lib.S.container>
    // </Scrollbar>
  )
}
