import styled from 'styled-components'

export const twoInputContainer = styled.div`
  float: left;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-end;

  > div {
    flex: 1;

    input {
      text-align: center;
      border-width: 1px !important;
    }
  }

  > span {
    width: 40px;
    display: inline-block;
    height: 45px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      background: black;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      width: 30%;
    }
  }
`
