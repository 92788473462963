import { useEffect, useState } from 'react'
import { OptionService } from 'common/services'
import * as Lib from '.'
import { UNHANDLED } from 'common/constants/errors'
import { ListObject } from 'common/components/ui-kit/list/lib/typing'
import { useDispatch } from 'react-redux'
import { Global } from 'common/store/actions'
import swal from 'sweetalert'

export const useStoneTypes = () => {
  const [type, setType] = useState<string | null>(null)

  const [editType, setEditType] = useState<string | null>(null)
  const [itemIdToEdit, setItemIdToEdit] = useState<number | null>(null)
  const [editModal, setEditModal] = useState<boolean>(false)

  const [list, setList] = useState<ListObject[]>([])
  const dispatch = useDispatch()
  const setLoader = (payload: boolean) => dispatch(Global.fsLoader(payload))

  const onStartUp = async () => {
    setLoader(true)
    const optionService = new OptionService({ type: 'stone-type' })
    const result = await optionService.read()

    if (!result.success || !result.options) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    const options = <Lib.T.List[]>result.options
    const listArray: ListObject[] = []
    options.forEach(item => listArray.push({ id: item.id, name: item.stoneType || '' }))
    setList(listArray)
    setLoader(false)
  }

  useEffect(() => {
    ;(async () => onStartUp())()
  }, [])

  const onSubmit = async () => {
    if (!type) {
      swal({
        text: 'Lütfen taş tipini girin.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }
    setLoader(true)

    const optionService = new OptionService({
      type: 'stone-type',
      stoneType: type,
    })

    const result = await optionService.create()
    if (!result.success || !result.option) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    const { option } = result
    const { id, stoneType } = option
    setList([...list, { id, name: stoneType || '' }])
    setType(null)
    setLoader(false)
  }

  const onDelete = async (id: number) => {
    const optionService = new OptionService({ type: 'stone-type' })
    const result = await optionService.delete(id)
    setLoader(true)

    if (!result) {
      setLoader(false)
      return
    }

    const listArray: ListObject[] = []
    list.forEach(item => {
      if (item.id !== id) {
        listArray.push(item)
      }
    })

    setList(listArray)
    setLoader(false)
  }

  const beforeConfirmEdit = (item: ListObject) => {
    setEditType(item.name)
    setItemIdToEdit(+item.id)
  }

  const edit = async () => {
    if (!editType) {
      return swal({
        text: 'Lütfen taş tipini girin.',
        icon: 'info',
        dangerMode: true,
      })
    }
    if (!itemIdToEdit) {
      return swal({
        text: 'Düzenlenemedi. Lütfen tekrar deneyin.',
        icon: 'info',
        dangerMode: true,
      })
    }
    setLoader(true)
    const optionService = new OptionService({
      type: 'stone-type',
      stoneType: editType,
    })
    const { success } = await optionService.edit(itemIdToEdit)
    setLoader(false)
    setEditModal(false)
    if (!success) {
      return
    }

    const listArray: ListObject[] = []
    list.forEach(item => {
      if (item.id === itemIdToEdit) {
        listArray.push({ ...item, name: editType })
      } else {
        listArray.push(item)
      }
    })

    setList(listArray)
  }

  return {
    addStates: {
      type: {
        val: type,
        set: setType,
      },
    },
    editStates: {
      type: {
        val: editType,
        set: setEditType,
      },
      visibility: {
        val: editModal,
        set: setEditModal,
      },
    },
    on: {
      create: onSubmit,
      delete: onDelete,
      beforeConfirmEdit,
      edit,
    },
    list,
  }
}
