import React, { useEffect, useMemo, useState } from 'react'
import * as Lib from '.'
import moment from 'moment'
import { useLoader } from 'common/hooks/use-loader'
import { TodoListService } from 'common/services/todoList'
import { HeaderTabEnum, HeaderTabType, MonthType, WeekType, YearType } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { handleSetDate} from 'common/store/actions/global.action'
import { RootState } from 'common/store/root-reducer'
import { Icon } from 'common/components/ui-kit/icon'
import swal from 'sweetalert'
import { ModalProps } from 'antd'
import { UserService } from '../../../../common/services'

export const useTodoList = () => {
  const dispatch = useDispatch()
  const { date } = useSelector((state: RootState) => state.globals)
  const { setLoader } = useLoader()
  const [headerActiveTab, setHeaderActiveTab] = useState(HeaderTabEnum.WEEK)
  const [year, setYear] = useState<YearType>({
    date: moment().format('y'),
    period: '',
    result: [{
      count: 0,
      monthName: ''
    }]
  })
  const [month, setMonth] = useState<MonthType>({
    date: moment().format('MMMM'),
    period: '',
    result: [{
      count: 0,
      day: 0,
      checkedCount: 0,
      unCheckedCount: 0
    }]})
  const [week, setWeek] = useState<WeekType>()
  const [pendingTask, setPendingTask] = useState<WeekType>()
  const headerTabs = useMemo(() => [HeaderTabEnum.YEAR, HeaderTabEnum.MONTH, HeaderTabEnum.WEEK], [])
  const [isShowDescription, setIsShowDescription] = useState({state: false, id: 0, date: '', mode: ''})
  const [description, setDescription] = useState('')
  const [pendingDescription, setPendingDescription] = useState('')
  const [openActions, setOpenActions] = useState({state: false, id: 0, mode: ''});
  const [isRead, setIsRead] = useState({state: false,id: 0})
  const [moveModal, setMoveModal] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const [daySelected, setDaySelected] = useState({state: false,id: 0, date: ''})
  const [spinLoader, setSpinLoader] = useState(false)
  const [spinLoaderPendingTsk, setSpinLoaderPendingTsk] = useState(false)
  const [pendingTaskMode, setPendingTaskMode] = useState('add')
  const [users, setUsers] = useState<{ id: number | string; title: string }[] | undefined>([])
  const [userLoading, setUserLoading] = useState(false)
  const [userIds, setUserIds] = useState<string[] | undefined>()

  const handleChangeHeaderActiveTab = async (tab: HeaderTabType, tabMonthDate?: string, tabWeekDate?: string) => {
    setHeaderActiveTab(tab)
    const service = new TodoListService()

    if (tab === HeaderTabEnum.YEAR) {
      setLoader(true)
      const { result, success } = await service.getTodoList({ period: 'yearly', date: date})

      if (!success) {
        setLoader(false)
        return null
      }
      setYear(result)
      setLoader(false)
    } else if (tab === HeaderTabEnum.MONTH) {
      setLoader(true)
      const { result, success } = await service.getTodoList({ period: 'monthly', date: tabMonthDate ? tabMonthDate : date})

      if (!success) {
        setLoader(false)
        return null
      }
      setMonth(result)
      setLoader(false)
    } else if (tab === HeaderTabEnum.WEEK) {
      setLoader(true)
      const { result, success } = await service.getTodoList({ period: 'weekly', date: tabWeekDate ? tabWeekDate : date})
      const { pendingResult } = await service.getTodoList({ period: 'daily', status: 'pending'})

      if (!success) {
        setLoader(false)
        return null
      }
      setWeek(result)
      setPendingTask(pendingResult)
      setLoader(false)
    }
  }

  const handelNext = async () => {
    const service = new TodoListService()
    if (headerActiveTab === HeaderTabEnum.YEAR) {
      const newDate = moment(date).add(1,'y').format('YYYY-MM-DDTHH:mm:ss.SSS')
      setLoader(true)
      const { result, success } = await service.getTodoList({ period: 'yearly', date: newDate})

      if (!success) {
        setLoader(false)
        return null
      }
      dispatch(handleSetDate(newDate))
      setYear(result)
      setLoader(false)
    } else if (headerActiveTab === HeaderTabEnum.MONTH) {
      const newDate = moment(date).add(1,'M').format('YYYY-MM-DDTHH:mm:ss.SSS')
      setLoader(true)
      const { result, success } = await service.getTodoList({ period: 'monthly', date: newDate})

      if (!success) {
        setLoader(false)
        return null
      }
      dispatch(handleSetDate(newDate))
      setMonth(result)
      setLoader(false)
    } else if (headerActiveTab === HeaderTabEnum.WEEK) {
      const newDate = moment(date).add(1,'weeks').format('YYYY-MM-DDTHH:mm:ss.SSS')
      setLoader(true)
      const { result, success } = await service.getTodoList({ period: 'weekly', date: newDate})

      if (!success) {
        setLoader(false)
        return null
      }
      dispatch(handleSetDate(newDate))
      setWeek(result)
      setMonth({...month,date: moment(newDate).locale('en').format('MMMM')})
      setLoader(false)
    }
  }

  const handelPrev = async () => {
    const service = new TodoListService()
    if (headerActiveTab === HeaderTabEnum.YEAR) {
      const newDate = moment(date).subtract(1,'y').format('YYYY-MM-DDTHH:mm:ss.SSS')
      setLoader(true)
      const { result, success } = await service.getTodoList({ period: 'yearly', date: newDate})

      if (!success) {
        setLoader(false)
        return null
      }
      dispatch(handleSetDate(newDate))
      setYear(result)
      setLoader(false)
    } else if (headerActiveTab === HeaderTabEnum.MONTH) {
      const newDate = moment(date).subtract(1,'M').format('YYYY-MM-DDTHH:mm:ss.SSS')
      setLoader(true)
      const { result, success } = await service.getTodoList({ period: 'monthly', date: newDate})

      if (!success) {
        setLoader(false)
        return null
      }
      dispatch(handleSetDate(newDate))
      setMonth(result)
      setLoader(false)
    } else if (headerActiveTab === HeaderTabEnum.WEEK) {
      const newDate = moment(date).subtract(1,'weeks').format('YYYY-MM-DDTHH:mm:ss.SSS')
      setLoader(true)
      const { result, success } = await service.getTodoList({ period: 'weekly', date: newDate})

      if (!success) {
        setLoader(false)
        return null
      }
      dispatch(handleSetDate(newDate))
      setWeek(result)
      setMonth({...month,date: moment(newDate).locale('en').format('MMMM')})
      setLoader(false)
    }
  }

  const handleDeleteTask = async (id: number, date: string) => {
    const service = new TodoListService()
      await service.delete(id,date)
      setSpinLoader(true)
      const { result, success } = await service.getTodoList({ period: 'weekly', date: date})
      if (success) {
        setWeek(result)
      }
      setSpinLoader(false)
  }

  const handleDeletePendingTask = async (id: number) => {
    const service = new TodoListService()
      await service.delete(id)
      setSpinLoaderPendingTsk(true)
      const { pendingResult, pendingSuccess } = await service.getTodoList({ period: 'daily', status: 'pending'})
      if (pendingSuccess) {
        setPendingTask(pendingResult)
      }
      setSpinLoaderPendingTsk(false)
  }

  const handleAddTask = async () => {
    const service = new TodoListService()
    if (isShowDescription.mode === 'add') {
      setSpinLoader(true)
      const { success } = await service.addTask({description, date:isShowDescription.date})
      if (success) {
        const { result } = await service.getTodoList({ period: 'weekly', date: date})
        setWeek(result)
        setSpinLoader(false)
      }
      if (!success) {
        swal({
          text: 'Ekleme başarısız oldu',
          dangerMode: true,
          icon: 'error',
        })
        setSpinLoader(false)
      }
    }
    if (isShowDescription.mode === 'edit') {
      setSpinLoader(true)
      const { success } = await service.editTask({description, date:isShowDescription.date}, openActions.id)
      if (success) {
        const { result } = await service.getTodoList({ period: 'weekly', date: date})
        setWeek(result)
        setSpinLoader(false)
      }
      if (!success) {
        swal({
          text: 'Ekleme başarısız oldu',
          dangerMode: true,
          icon: 'error',
        })
        setSpinLoader(false)
        setDescription('')
      }
    }

    setIsShowDescription({state: false, date: '', id: 0, mode: ''})
  }

  const handleAddPendingTask = async () => {
    const service = new TodoListService()
      setSpinLoaderPendingTsk(true)
      const { success } = await service.addTask({description: pendingDescription, status: 'pending'})
      if (success) {
        const { pendingResult } = await service.getTodoList({ period: 'daily', status: 'pending'})
        setPendingTask(pendingResult)
      }
      if (!success) {
        swal({
          text: 'Ekleme başarısız oldu',
          dangerMode: true,
          icon: 'error',
        })
      }
    setSpinLoaderPendingTsk(false)
    setPendingDescription('')
  }

  const handleEditPendingTask = async () => {
    const service = new TodoListService()
      setSpinLoaderPendingTsk(true)
      const { success } = await service.editTask({description:pendingDescription}, openActions.id)
      if (success) {
        const { pendingResult } = await service.getTodoList({ period: 'daily', status: 'pending'})
        setPendingTask(pendingResult)
        setPendingTaskMode('add')
      }
      if (!success) {
        swal({
          text: 'Ekleme başarısız oldu',
          dangerMode: true,
          icon: 'error',
        })
      }
    setSpinLoaderPendingTsk(false)
    setPendingDescription('')
  }

  const handleCheckTask = async () => {
    const service = new TodoListService()
    setSpinLoader(true)
    const { success } = await service.editTask({isChecked: isRead.state}, isRead.id)
    if (success) {
      const { result } = await service.getTodoList({ period: 'weekly', date: date})
      setWeek(result)
      setSpinLoader(false)
    }
    if (!success) {
      setSpinLoader(false)
    }
  }

  const handleMoveTask = async () => {
    const service = new TodoListService()
    setLoader(true)
    if (openActions.mode === 'pending') {
      const { success } = await service.editTask({date: daySelected.date, status: 'approved'}, openActions.id)
      if (success) {
        const { result } = await service.getTodoList({ period: 'weekly', date: date})
        const { pendingResult } = await service.getTodoList({ period: 'daily', status: 'pending'})
        setWeek(result)
        setPendingTask(pendingResult)
        setDaySelected({state: false, date: '', id: 0})
        setMoveModal(false)
        setLoader(false)
      }
      if (!success) {
        setLoader(false)
      }
    } else {
      const { success } = await service.editTask({date: daySelected.date}, openActions.id)
      if (success) {
        const { result } = await service.getTodoList({ period: 'weekly', date: date})
        setWeek(result)
        setDaySelected({state: false, date: '', id: 0})
        setMoveModal(false)
        setLoader(false)
     }
      if (!success) {
        setLoader(false)
      }
    }
  }

  const handleShareTask = async () => {
    const service = new TodoListService()
    setLoader(true)
    const { success } = await service.shareTask({ids:[openActions.id], userIds})
    if (success) {
      setLoader(false)
      setShareModal(false)
      setUserIds([])
    }
    setLoader(false)
  }

  const actionTask = (item:any) => {
    return (
      <Lib.S.actionsContainer>
        <div onClick={() => {
          setOpenActions({state: !openActions.state, id: item.id, mode:''})
          setDescription(item.description)
          setIsShowDescription({...isShowDescription,state: true, date: item.date, mode: 'edit'})
        }} role="button" className='border-bottom w-100 mb-1 pb-1 d-flex align-items-center'>
          <Icon name="edit_outline" size={18} />
          <span className='ms-2'>Düzenle</span>
        </div>
        <div onClick={() => {
          handleDeleteTask(item.id,item.date).then(() => setOpenActions({state: false, id: 0, mode:''}))
        }} role="button" className='border-bottom w-100 mb-1 pb-1 d-flex align-items-center'>
          <Icon name="trash_bin_outline" size={18} />
          <span className='ms-2'>Sil</span>
        </div>
        <div onClick={() => {
          setOpenActions({state: !openActions.state, id: item.id, mode:''})
          setMoveModal(true)
        }} role="button" className='border-bottom w-100 mb-1 pb-1 d-flex align-items-center'>
          <Icon name="list_view" size={18} />
          <span className='ms-2'>Taşı</span>
        </div>
        <div onClick={async () => {
          setOpenActions({state: !openActions.state, id: item.id, mode:''})
          setShareModal(true)
          await fetchUsers()
        }} role="button" className='d-flex w-100 align-items-center'>
          <Icon name="share" size={18} color='#000' />
          <span className='ms-2'>Paylaş</span>
        </div>
      </Lib.S.actionsContainer>
    );
  };

  const actionPendingTask = (item:any) => {
    return (
      <Lib.S.actionsContainer>
        <div onClick={() => {
          setPendingTaskMode('edit')
          setOpenActions({state: !openActions.state, id: item.id, mode:'pending'})
          setPendingDescription(item.description)
        }} role="button" className='border-bottom w-100 mb-1 pb-1 d-flex align-items-center'>
          <Icon name="edit_outline" size={18} />
          <span className='ms-2'>Düzenle</span>
        </div>
        <div onClick={() => {
          handleDeletePendingTask(item.id).then(() => setOpenActions({state: false, id: 0, mode:''}))
        }} role="button" className='border-bottom w-100 mb-1 pb-1 d-flex align-items-center'>
          <Icon name="trash_bin_outline" size={18} />
          <span className='ms-2'>Sil</span>
        </div>
        <div onClick={() => {
          setOpenActions({state: !openActions.state, id: item.id, mode:'pending'})
          setMoveModal(true)
        }} role="button" className='d-flex w-100 align-items-center'>
          <Icon name="list_view" size={18} />
          <span className='ms-2'>Taşı</span>
        </div>
      </Lib.S.actionsContainer>
    );
  };

  const convertDayName = (name: string) => {
    switch (name) {
      case 'January':
        return 'Ocak'
      case 'February':
        return 'Şubat'
      case 'March':
        return 'Mart'
      case 'April':
        return 'Nisan'
      case 'May':
        return 'Mayıs'
      case 'June':
        return 'Haziran'
      case 'July':
        return 'Temmuz'
      case 'August':
        return 'Ağustos'
      case 'September':
        return 'Eylül'
      case 'October':
        return 'Ekim'
      case 'November':
        return 'Kasım'
      case 'December':
        return 'Aralık'
      default:
        return name
    }
  }

  const clearTextBox = () => {
    setPendingDescription('')
    setPendingTaskMode('add')
  }

  const fetchUsers = async () => {
    const service = new UserService()
    setUserLoading(true)

    const { success, users } = await service.getList({ disabled: false, limit: 10000 })
    if (success && users?.length) {
      const dataTransform = users?.map(user => {
        return { id: user.id, title: user.name || user.username as string }
      })
      setUsers(dataTransform)

      setUserLoading(false)
    }
    setUserLoading(false)
  }

  const onUserDropDownChange = async (value: string[]) => {
    setUserIds(value)
  }
  const moveModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: moveModal,
    onCancel: () => {
      setMoveModal(false)
      setDaySelected({state: false, date: '', id: 0})
    },
    className: 'noHeaderModal',
    width: 850,
  }

  const shareModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: shareModal,
    onCancel: () => {
      setShareModal(false)
      setUserIds([])
    },
    className: 'noHeaderModal',
  }

  useEffect(() => {
    isRead.id && handleCheckTask()
  },[isRead.state, isRead.id])

  useEffect(() => {
    handleChangeHeaderActiveTab(HeaderTabEnum.WEEK)
  },[])

  return {
    header: {
      headerTabs,
      handleChangeHeaderActiveTab,
      headerActiveTab,
    },
    modal: {
      moveModalProps,
      shareModalProps
    },
    get: {
      year,
      month,
      week,
      pendingTask,
      actionTask,
      actionPendingTask,
      isShowDescription,
      description,
      pendingDescription,
      openActions,
      isRead,
      daySelected,
      spinLoader,
      spinLoaderPendingTsk,
      pendingTaskMode,
      users,
      userLoading,
      userIds
    },
    on: {
      handelNext,
      handelPrev,
      handleAddTask,
      handleCheckTask,
      convertDayName,
      handleMoveTask,
      handleAddPendingTask,
      handleEditPendingTask,
      clearTextBox,
      handleShareTask,
      onUserDropDownChange
    },
    set: {
      setIsShowDescription,
      setDescription,
      setPendingDescription,
      setOpenActions,
      setIsRead,
      setDaySelected,
      setMoveModal,
      setShareModal,
      setUserIds
    }
  }
}
