import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { AutomationDrinkType } from 'common/services'
import { useCallback, useEffect, useState } from 'react'
import swal from 'sweetalert'
import * as Lib from '.'
import { Switch } from 'antd'

const modalInitialProps: Lib.T.modalPropsType = {
  kind: '',
  editProps: {
    id: 0,
    name: '',
    active: false,
  },
}

export const useDrinkType = () => {
  const { setLoader } = useLoader()

  const tableIdIndex = 8

  const [paginate, setPaginate] = useState({ limit: 30, page: 1 })
  const [totalData, setTotalData] = useState<number | undefined>()
  const [fetchAgain, setFetchAgain] = useState(false)
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const tableColumns = ['İçecek', '']
  const tableSizes = ['unset', '7%']
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalProps, setModalProps] = useState<Lib.T.modalPropsType>(modalInitialProps)
  const [paginateLoader, setPaginateLoader] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [name, setName] = useState('')

  const getDrinkTypes = async () => {
    const service = new AutomationDrinkType()
    setLoader(true)
    const { success, drinkTypes, total } = await service.getDrinkTypes({ limit: paginate.limit })
    setLoader(false)
    if (success && drinkTypes) {
      setTotalData(parseInt(total! + ''))
      setTableData(prevData => [
        ...drinkTypes.map(drink => {
          return [
            <div onClick={() => getDrinkType(drink.id)} style={{ cursor: 'pointer', display: 'inline-block' }}>
              {drink.name}
            </div>,
            <Lib.S.tableButtons>
              <Switch
                defaultChecked={drink.active}
                size="small"
                className="me-1"
                onChange={() => handleDrinkTypeActivation(drink.id, drink.active)}
              />
              <Lib.C.TableButton icon="edit_outline" callback={() => getDrinkType(drink.id)} />
              <Lib.C.TableButton
                icon="trash_bin"
                callback={() => {
                  setShowDeleteModal(true)
                  setModalProps({ ...modalInitialProps, kind: 'delete', editProps: { id: drink.id } })
                }}
              />
            </Lib.S.tableButtons>,
          ]
        }),
      ])
    }
  }

  const handleDrinkTypeActivation = async (id: number, active: boolean) => {
    const service = new AutomationDrinkType()
    setLoader(true)
    const { success } = await service.updateDrinkType({ active: !active }, id)
    setLoader(false)
  }

  const getDrinkType = async (id: number) => {
    const service = new AutomationDrinkType()
    setLoader(true)
    const { success, drinkType } = await service.getDrinkType(id)
    setLoader(false)
    if (success && drinkType) {
      setShowModal(true)
      setModalProps({
        ...modalInitialProps,
        kind: 'edit',
        editProps: {
          id: drinkType.id,
          name: drinkType.name,
          active: drinkType.active,
        },
      })
    }
  }

  const deleteDrinkType = useCallback(
    async (id: number) => {
      const service = new AutomationDrinkType()
      setShowDeleteModal(false)
      setLoader(true)
      try {
        const success = await service.deleteDrinkType(id)
        if (success) {
          swal({ className: 'rounded-swal', icon: 'success', timer: 700, buttons: [false] }).then(() => {
            setShowDeleteModal(false)
          })
          setFetchAgain(prev => !prev)
        }
      } catch (error) {
        console.error('Failed to edit/create neighbourhood: ', error)
      } finally {
        setLoader(false)
      }
    },
    [modalProps],
  )

  const handleSwal = (success: boolean, onSuccess: () => void) => {
    if (success) {
      swal({
        className: 'rounded-swal',
        icon: 'success',
        timer: 700,
        buttons: [false],
      }).then(() => {
        onSuccess()
      })
      setLoader(false)
      handleModal()
    }
  }

  const handleDrinkTypeOperation = async (operation: 'create' | 'edit', id?: number) => {
    const service = new AutomationDrinkType()

    setLoader(true)

    let success = false

    if (operation === 'create') {
      if (name.length < 1) {
        swal({
          text: 'Lütfen tüm alanları doldurun.',
          icon: 'error',
        })
        setIsEmpty(true)
        setLoader(false)
        return
      }
      success = await service.createDrinkType({ name: name })
    } else if (operation === 'edit' && id !== undefined) {
      if (name.length < 1) {
        swal({
          text: 'Lütfen tüm alanları doldurun.',
          icon: 'error',
        })
        setIsEmpty(true)
        setLoader(false)
        return
      }
      const response = await service.updateDrinkType({ name: name }, id)
      success = response.success
    }

    handleSwal(success, () => {
      setName('')
      setShowModal(false)
    })

    setFetchAgain(prev => !prev)
    setLoader(false)
  }

  const CreateDrinkType = async () => {
    await handleDrinkTypeOperation('create')
  }

  const EditDrinkType = async (id: number) => {
    await handleDrinkTypeOperation('edit', id)
  }

  const handleShowMore = () => {
    if (totalData! / paginate.page <= paginate.limit) {
      return
    }
    setPaginateLoader(true)
    setPaginate(prev => {
      return { ...prev, limit: prev.limit + 30 }
    })
  }

  const handleDeleteModalVisibility = useCallback(() => {
    setShowDeleteModal(prev => !prev)
  }, [])

  const handleModal = () => {
    setShowModal(false)
    setModalProps(modalInitialProps)
    setName('')
    setIsEmpty(false)
  }

  useAsyncEffect(getDrinkTypes, [paginate, fetchAgain])

  useEffect(() => {
    setName(modalProps.editProps.name ?? '')
  }, [modalProps])

  return {
    val: {
      tableData,
      tableColumns,
      tableSizes,
      showDeleteModal,
      modalProps,
      totalData,
      paginateLoader,
      tableIdIndex,
      showModal,
      isEmpty,
      name,
    },

    set: {
      setFetchAgain,
      setShowDeleteModal,
      setModalProps,
      setShowModal,
      setName,
    },

    on: {
      deleteDrinkType,
      handleDeleteModalVisibility,
      handleShowMore,
      handleModal,
      CreateDrinkType,
      EditDrinkType,
    },

    get: {
      paginate,
      totalData,
    },
  }
}
