import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import { DOM } from 'common/helpers/dom'
import swal from 'sweetalert'
import { CustomerImage, FullProduct } from './lib/types/product'

export class ProductService {
  accessToken: string
  endpoint = 'refactored-products/'
  oldEndpoint = 'products/'
  customerImageEndpoint = 'customer-orders/'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      ProductService.dontAccept()
    }
    if (!user!.accessToken) {
      ProductService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async create(data: Lib.T.Product.CreateOrEditArgs): Promise<Lib.T.Product.CreateResult> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint),
      headers: { authorization: this.accessToken },
      data,
    })

    const { status, data: responseData } = response
    if (status !== 201) {
      swal({
        text: 'Ürün oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return {
        success: false,
        error: responseData,
      }
    }
    return {
      success: true,
      id: responseData.product.id,
      error: null,
    }
  }
  async createMonacoProduct(data: Lib.T.Product.CreateMonacoArg): Promise<Lib.T.Product.CreateResult> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat('monaco-products'),
      headers: { authorization: this.accessToken },
      data,
    })

    if (response.status === 400 && response.data.message.includes('duplicate product.')) {
      return swal({
        text: 'tekrarle',
        icon: 'error',
        dangerMode: true,
      })
    }

    const { status, data: monacoProduct } = response
    if (status !== 201) {
      return {
        success: false,
        error: monacoProduct,
      }
    }

    return {
      success: true,
      id: monacoProduct.id,
      error: null,
    }
  }
  async edit(
    data: Lib.T.Product.CreateOrEditArgs,
    id: number,
  ): Promise<{ success: boolean; priceCategory: { id: number; minPrice: number; maxPrice: number } | null; product: FullProduct | null }> {
    const response = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint).concat(id.toString()),
      headers: { authorization: this.accessToken },
      data,
    })

    const { status, data: result } = response

    if (status !== 200) {
      return { success: false, priceCategory: null, product: null }
    }

    return { success: true, priceCategory: result.product.priceCategory, product: result.product }
  }

  async updateProductCode(data: Lib.T.Product.UpdateProductCodeArgs, id: number,): Promise<boolean> {
    const response = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint).concat(id.toString()).concat('/update-name'),
      headers: { authorization: this.accessToken },
      data,
    })

    const { status } = response

    return status === 200
  }

  async customerImage(
    data: Lib.T.Product.CustomerImage,
    id: number,
  ): Promise<{ success: boolean }> {
    const response = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.customerImageEndpoint).concat(id.toString()),
      headers: { authorization: this.accessToken },
      data,
    })

    const { status } = response

    if (status !== 200) {
      return { success: false }
    }

    return { success: true }
  }

  async readAll(args: Lib.T.Product.ReadAllArgs): Promise<Lib.T.Product.ReadAllResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(DOM.objectToSearch(args)),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      swal({
        text: 'Ürün listesine ulaşılamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return {
      success: true,
      products: data.products,
      total: data.total,
      alreadyAddedProducts: data.alreadyAddedProducts,
      favoriteProductIds: data.favoriteProductIds,
      viewedFavoriteProductsIds: data.viewedFavoriteProductsIds,
    }
  }

  async pending(args?: Lib.T.Product.PendingArgs): Promise<Lib.T.Product.PendingResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.oldEndpoint)
        .concat('pending')
        .concat(DOM.objectToSearch(args ? args : {})),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      swal({
        text: 'Ürün listesine ulaşılamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, products: data.products, total: data.total }
  }

  // async rejectOrApprove(id: number, isRejected: boolean, rejectReason?: string): Promise<boolean> {
  //   const { status } = await ApiCaller({
  //     method: 'PATCH',
  //     url: process.env
  //       .REACT_APP_SERVER_URL!.concat(this.endpoint)
  //       .concat(id.toString())
  //       .concat(isRejected ? '/reject' : '/approve'),
  //     headers: { authorization: this.accessToken },
  //     data: { rejectReason },
  //   })

  //   if (status === 403) {
  //     swal({
  //       text: 'Bu işlemi gerçekleştiremezsiniz. Üründe tamalanması gereken yerler var. ',
  //       icon: 'warning',
  //       dangerMode: true,
  //     })
  //     return false
  //   }

  //   if (status !== 200) {
  //     swal({
  //       text: `Seçtiğiniz ürün ${rejectReason ? 'reddedilemiyor' : 'onaylanamıyor'}. Lütfen tekrar deneyin.`,
  //       icon: 'error',
  //       dangerMode: true,
  //     })
  //     return false
  //   }
  //   return true
  // }

  async approveProduct(list: number[]): Promise<Lib.T.Product.SendToProductionResult> {
    const { status } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint).concat('approve'),
      headers: { authorization: this.accessToken },
      data: { products: list },
    })

    if (status !== 200) {
      swal({
        text: 'Ürün üretime gönderilemiyor. Lütfen tekrar deneyin. ',
        icon: 'warning',
        dangerMode: true,
      })
      return {
        success: false,
      }
    }

    return { success: true }
  }

  async sendToProduction(list: number[]): Promise<Lib.T.Product.SendToProductionResult> {
    const { status, data } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint).concat('sendToProduction'),
      headers: { authorization: this.accessToken },
      data: { products: list },
    })

    const incompleteProducts = data?.incompleteProducts

    if (status !== 200) {
      if (data.message.includes('You already have an open order.')) {
        swal({
          text: 'Yeni ürün eklemek için once sepetini boşaltmalısın.',
          icon: 'warning',
          dangerMode: true,
          timer: 1000,
        })
        return {
          success: false,
        }
      }

      swal({
        text: 'Ürün üretime gönderilemiyor. Lütfen tekrar deneyin. ',
        icon: 'warning',
        dangerMode: true,
      })
      return {
        success: false,
      }
    }

    if (incompleteProducts) {
      return {
        success: false,
        incompleteProducts,
      }
    }

    return { success: true }
  }

  async checkForProduction(id: number | string): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint).concat(id.toString()).concat('/checkSendToProduction'),
      headers: { authorization: this.accessToken },
    })

    return status === 200
  }

  async findOne(id: number, karat?: string): Promise<Lib.T.Product.FindOneResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint + id)!.concat(karat ? '?karat=' + karat : ''),
      headers: { authorization: this.accessToken },
    })

    if (status === 200 && data.product) {
      return {
        success: true,
        data: data.product,
        stones: data.stones,
        allData: data,
      }
    }

    swal({
      text: 'we couldnt get the product details. please try again',
      icon: 'error',
      dangerMode: true,
    })
    return { success: false }
  }

  async assignTechnicalColor(id: number, body: Lib.T.Product.AssignTechnicalColor[]): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint + id + '/setTechnicalColor'),
      headers: { authorization: this.accessToken },
      data: {
        stones: body,
      },
    })
    if (status === 200) {
      return true
    }
    return false
  }

  async confirm(id: number): Promise<{ success: boolean; error: any }> {
    const data = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint + id).concat('/confirm'),
      headers: { authorization: this.accessToken },
    })

    if (data.status === 200) {
      return { success: true, error: null }
    }

    return { success: false, error: data.data }
  }
  async disableProduct(id: number, disabled: boolean): Promise<{ success: boolean; product: FullProduct | null }> {
    const data = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint + id).concat('/disable'),
      headers: { authorization: this.accessToken },
      data: { disabled },
    })

    if (data.status === 200) {
      return { success: true, product: null }
    }

    return { success: false, product: data.data }
  }

  async delete(id: number): Promise<boolean> {
    const data = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint + id),
      headers: { authorization: this.accessToken },
    })
    if (data.status === 200) {
      return true
    }

    return false
  }

  async copy(id: number, productCode: string): Promise<boolean> {
    const { status, data } = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.oldEndpoint + id + '/getVariant'),
      headers: { authorization: this.accessToken },
      data: {
        productCode,
      },
    })

    if (status === 200) {
      return true
    }
    return false
  }

  static dontAccept() {
    throw 'not-acceptable'
  }
}
