import styled from 'styled-components'

export const StickyBtns = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    width: 30%;
    justify-content: center;
    align-items: center;
  }

  .detail {
    background-color: var(--accent);
    color: #fff;
    margin-right: 8px;
    cursor: pointer;
    border-radius: 5px;
    padding: 10px 20px;
    width: 154px;
  }
  .color {
    background-color: #fff;
    margin-left: 8px;
    cursor: pointer;
    color: #000;
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px #000 solid;
    width: 154px;
  }

  .navigator {
    width: 100%;
  }

  .perv-btn {
    display: flex;
    flex: 0.5;
    justify-content: flex-start;
    img {
      margin-right: 15px;
    }
  }
  .next-btn {
    display: flex;
    flex: 0.5;

    justify-content: flex-end;
    img {
      margin-left: 15px;
    }
  }
`

export const Main = styled.div`
  .image-details {
    display: flex;
    gap: 20px;
    align-items: flex-start;

    /*  */
    .image {
      border-radius: 10px;
      flex: 1;

      img {
        width: 100%;
        border-radius: 10px;
        max-height: 457px;
        min-height: 457px;
      }
    }
    /*  */
    .details {
      background-color: #fff;
      border-radius: 5px;
      padding: 28px 38px;
      flex: 1;

      .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(54, 55, 64, 0.69);
        padding-bottom: 15px;

        .title {
          font-family: var(--f-bl);
          font-size: 18px;
        }

        .actions {
          display: flex;
          gap: 30px;
          svg {
          }
        }
      }
      .body {
        .item {
          padding: 10px 0 10px 0;
          display: flex;
          justify-content: space-between;
          :not(:last-child) {
            border-bottom: 1px solid rgba(54, 55, 64, 0.13);
          }
          .title {
            color: #9c9c9c;
            font-size: 16px;
          }

          .value {
            color: #004e42;
            font-family: var(--f-se-bo);
          }
        }
      }
    }
  }
  .pieces {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 44px;
  }
`

export const PiecesWrapper = styled.div`
  display: flex;

  .image {
    display: flex;
    border-radius: 10px;
    flex: 1;
    img {
      width: 100%;
      max-height: 210px;
      min-height: 120px;
      border-radius: 10px;
    }
  }

  .details {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 20px 40px;
    margin-left: 10px;
    font-family: var(--f-me);
    max-width: 150px;
    flex: 1;
  }

  .top {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .bottom {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 20px;
    color: #9c9c9c;
    font-weight: normal;
  }

  .value {
    font-weight: bolder;
    font-size: 18px;
    font-family: var(--f-bo);
  }
`

export const ConfirmModalContainer = styled.div`
  padding: 40px;
  p {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 18px;
  }
  .btns {
    display: flex;
  }
`
