import * as Lib from './'

export class Detect {
  static background(props: Lib.T.ButtonContainerProps): string {
    switch (props.mood) {
      default:
      case 'accent':
        return 'var(--accent)'
      case 'light':
        return 'white'
      case 'gray':
        return '#EAEAEA'
      case 'orang':
        return '#ED5037'
      case 'border':
        return 'transparent'
      case 'success':
        return '#2DA771'
      case 'danger':
        return '#D32738'
      case 'light_green':
        return '#dae3e2'
      case 'accent_outline':
        return 'transparent'
    }
  }

  static borderColor(props: Lib.T.ButtonContainerProps): string {
    switch (props.mood) {
      default:
      case 'accent':
        return 'var(--accent)'
      case 'light':
        return 'rgb(0 0 0 / 10%)'
      case 'gray':
        return '#EAEAEA'
      case 'orang':
        return '#ED5037'
      case 'border':
        return '#0000001A'
      case 'success':
        return '#2DA771'
      case 'danger':
        return '#D32738'
      case 'light_green':
        return '#dae3e2'
      case 'accent_outline':
        return 'var(--accent)'
    }
  }

  static color(props: Lib.T.ButtonContainerProps): string {
    switch (props.mood) {
      default:
      case 'accent':
        return 'white'
      case 'light':
        return 'var(--accent)'
      case 'gray':
        return '#4D4D4D'
      case 'border':
        return '#000000'
      case 'success':
        return '#fff'
      case 'danger':
        return '#fff'
      case 'light_green':
        return 'var(--accent)'
      case 'accent_outline':
        return 'var(--accent)'
    }
  }
}
