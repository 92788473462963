import { FC } from 'react'
import * as Lib from './lib'
import { Icon } from '../icon'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

export const ImageSlider: FC<Lib.T.ImageSliderProps> = ({ images, baseUrl, hideCloseIcon, handleRemove }) => {
  const { get, on } = Lib.H.useImageSlider({ images })
  const arrow = <Icon name="arrow_left" size={20} color="#fff" />

  return (
    <Lib.S.imageSlider>
      {!hideCloseIcon && (
        <span className="close" onClick={() => handleRemove && handleRemove(get.active)}>
          <Icon name="trash_bin" color="black" size={20} />
        </span>
      )}
      <div style={{ position: 'relative' }}>
        {/* <span className="imgHolder" style={{ backgroundImage: `url(${baseUrl ? baseUrl + images[get.active] : images[get.active]})` }} /> */}
        <TransformWrapper key={2}>
          <TransformComponent key={2}>
            <img
              src={baseUrl ? images[get.active] : images[get.active]}
              alt=""
              style={{ objectFit: 'contain' }}
              onError={e => (e.currentTarget.src = '/images/jpg/placeholder.jpg')}
            />
          </TransformComponent>
        </TransformWrapper>

        <div className="arrows">
          {images.length > 1 && (
            <>
              <span onClick={() => on.change(false)} className={`${get.active === 0}`}>
                {arrow}
              </span>
              <p>
                <span>{get.active + 1}</span>
                <span>/</span>
                <span>{images.length}</span>
              </p>
              <span onClick={() => on.change(true)} className={`${get.active === images.length - 1}`}>
                {arrow}
              </span>
            </>
          )}
        </div>
      </div>
    </Lib.S.imageSlider>
  )
}
