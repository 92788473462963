import { firstLetterUpperCase } from 'common/helpers/firstLetterUpperCase'
import * as Lib from '.'

export const Pieces: React.FC<Lib.T.ProductDetailPeiceProps> = ({ type, weight, image }) => {
  return (
    <Lib.S.PiecesWrapper>
      <div className="image">
        <img src={process.env.REACT_APP_DOMAIN! + image} alt="" />
      </div>
      <div className="details">
        <div className="top">
          <span className="title">Parca</span>
          <span className="value">{firstLetterUpperCase(type)}</span>
        </div>
        <div className="bottom">
          <span className="title">Ağırlık</span>
          <span className="value">{weight} gr</span>
        </div>
      </div>
    </Lib.S.PiecesWrapper>
  )
}

export const TableItem: React.FC<Lib.T.TableItemsProps> = ({ value }) => {
  const items = [
    'Kod',
    'Toplam Gram',
    'Fiyat Grubu',
    'İlgili Departman',
    'Tasarlayan Kişi',
    'Modelleyen Kişi',
    'Koçan Kişisi',
    'Ürün Ekleme Tarihi',
  ]

  return (
    <>
      {items.map((item, itemIndex) => {
        return (
          <div className="item" key={itemIndex}>
            <span className="title">{item}</span>
            {value.map((val, valIndex) => {
              return (
                itemIndex === valIndex && (
                  <span className="value" key={valIndex}>
                    {val}
                  </span>
                )
              )
            })}
          </div>
        )
      })}
    </>
  )
}
