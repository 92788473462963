import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Button } from 'common/components/ui-kit/button'
import * as Lib from './lib/index'
import { Content } from 'antd/lib/layout/layout'
import { Table } from 'common/components/ui-kit/table-2'
import Modal from 'antd/lib/modal/Modal'
import { Icon } from 'common/components/ui-kit/icon'
import { Input } from 'common/components/ui-kit/input'
import { Textarea } from 'common/components/ui-kit/textarea'
import { Checkbox } from 'common/components/ui-kit/checkbox'
import { Uploader } from 'common/components/ui-kit/uploader'

function Announcements() {
  const { val, set, on, get } = Lib.H.useAnnouncements()

  return (
    <PanelWrapper tab={['automation', '/automation/announcements']}>
      <Sticky>
        <Lib.S.searchSection>
          <p className="title">Anonslar</p>

          <div className="d-flex justify-content-end gap-2">
            <Button
              mood="accent"
              callback={() => {
                set.setShowModal(true)
                set.setModalProps({ ...val.modalProps, kind: 'create' })
              }}
              text="Anons Oluştur"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              icon="plus"
              containerStyles={{ minHeight: 40, width: 180 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />
          </div>
        </Lib.S.searchSection>
      </Sticky>

      <Content>
        <Lib.S.PageWrapper>
          <Lib.S.content>
            <div className="tableContainer">
              <Table
                columns={val.tableColumns}
                columnSizes={val.tableSizes}
                data={val.tableData}
                onLoadMore={on.handleShowMore}
                hasMore={val.tableData.length < get.totalData!}
                paginateLoader={val.paginateLoader}
                infiniteScroll={true}
                rowBackColor={false}
                disabledIndexes={[val.tableIdIndex, val.tableIdIndex - 1]}
                maxHeight="calc(100vh - 110px)"
              />
            </div>
          </Lib.S.content>
        </Lib.S.PageWrapper>

        {/* Create and Edit modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showModal}
          onCancel={on.handleModal}
          className="noHeaderModal userModal"
          width={1000}
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>{val.modalProps.kind === 'edit' ? 'Anons Düzenle' : 'Anons Oluştur'}</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={on.handleModal} style={{ cursor: 'pointer' }} />
            </div>

            <div className="uploader row mt-2 px-3" onClick={() => set.setClearPreview(false)}>
              <Uploader
                view="row"
                id="createPieceUploader"
                title="Fotoğraf Ekle"
                clearPreview={val.clearPreview}
                onChange={value => set.setImage(value)}
                defaultImage={val.defaultImage ?? ''}
              />
            </div>

            <div className="row mt-2 px-3">
              <div className={val.modalProps.kind === 'create' ? 'col-lg-8' : 'col-lg-12'}>
                <Input
                  label="Başlık"
                  onChange={e => {
                    set.setTitle(e.target.value)
                  }}
                  required
                  lessBorder
                  value={val.title}
                  inputStyles={
                    val.isEmpty && val.title.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' } : undefined
                  }
                  labelStyles={val.isEmpty && val.title.length < 1 ? { color: 'red' } : undefined}
                />
              </div>

              {val.modalProps.kind === 'create' && (
                <div className="col-lg-4">
                  <div className="d-flex justify-content-between align-items-center isManager">
                    <span>Anons gösterilsin mi?</span>
                    <Checkbox
                      label=""
                      name=""
                      onChange={e => {
                        set.setIsActive(e.target.checked)
                      }}
                      checked={val.isActive}
                      style={{ width: 'auto', marginRight: 5 }}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="row mt-2 px-3">
              <Textarea
                label="Açıklama"
                labelStyles={val.isEmpty && val.description.length < 1 ? { color: 'red' } : undefined}
                lessBorder
                required
                rows={1}
                className="description"
                value={val.description}
                onInput={evt => set.setDescription(evt.currentTarget.value)}
                textareaStyles={
                  val.isEmpty && val.description.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' } : undefined
                }
              />
            </div>

            <div className="btn">
              <Button
                text="Vazgeç"
                mood="accent_outline"
                callback={() => {
                  on.handleModal()
                }}
                className="btn"
              />
              <Button
                text={val.modalProps.kind === 'edit' ? 'Düzenle' : 'Oluştur'}
                mood="accent"
                callback={() => (val.modalProps.kind === 'edit' ? on.EditAnnouncement(val.modalProps.id ?? 0) : on.CreateAnnouncement())}
                className="btn"
              />
            </div>
          </Lib.S.ModalContainer>
        </Modal>

        {/* Delete modal */}
        <Modal visible={val.showDeleteModal} onCancel={on.handleDeleteModalVisibility} footer={null} title="Anonsu Sil">
          <Lib.S.DeleteModalContent>
            <p>Anonsu silmek istediğinizden emin misiniz?</p>
            <div>
              <Button text="Vazgeç" mood="accent" callback={() => set.setShowDeleteModal(perv => !perv)} />
              <Button text="Sil" mood="danger" callback={() => on.DeleteAnnouncement(val.modalProps.id ?? 0)} />
            </div>
          </Lib.S.DeleteModalContent>
        </Modal>
      </Content>
    </PanelWrapper>
  )
}

export default Announcements
