import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import { DOM } from 'common/helpers/dom'
import { DeleteSliderImage, GetSliderImagesResult } from './lib/types/customer'

export class CustomerService {
  accessToken: string
  endpoint = 'users/customer'
  listEndpoint = 'users/customer/customerList/'
  sliderEndpoint = 'slide'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      CustomerService.dontAccept()
    }
    if (!user!.accessToken) {
      CustomerService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async getList(args?: Lib.T.Customer.GetListArgs): Promise<Lib.T.Customer.GetListResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(DOM.objectToSearch(args ?? {})),
      headers: { authorization: this.accessToken },
    })
    if (status === 200) {
      return { success: true, customers: data.customers, total: data.total }
    }
    return { success: false, total: 0, customers: [] }
  }

  async getListCustomerOrderList(args?: Lib.T.Customer.GetListArgs): Promise<Lib.T.Customer.GetListResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.listEndpoint).concat(DOM.objectToSearch(args ?? {})),
      headers: { authorization: this.accessToken },
    })
    if (status === 200) {
      return { success: true, customers: data, total: data.total }
    }
    return { success: false, total: 0, customers: [] }
  }

  async create(args: Lib.T.Customer.CreateArgs): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    })
    return status === 201
  }

  async edit(args: Lib.T.Customer.EditArgs, id: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    })
    return status === 200
  }
  async approve(id: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`).concat('/approve'),
      headers: { authorization: this.accessToken },
    })
    return status === 200
  }

  async delete(id: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
    })
    return status === 200
  }

  async sliderImage(
    data: Lib.T.Customer.SliderImage,
  ): Promise<{ success: boolean }> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.sliderEndpoint),
      headers: { authorization: this.accessToken },
      data,
    })

    const { status } = response

    if (status !== 201) {
      return { success: false }
    }

    return { success: true }
  }

  async getSliderImages(): Promise<Lib.T.Customer.GetSliderImagesResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat('sliders'),
      headers: { authorization: this.accessToken },
    })

    if (status === 200) {
      return { success: true, images: data?.sliders }
    } else {
      return { success: false }
    }
  }

  async deleteSliderImage(id?: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.sliderEndpoint).concat(`/${id}`),
      headers: { authorization: this.accessToken },
    })
    return status === 200
  }

  async findOne(id: number): Promise<Lib.T.Customer.FindOneCustomerResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id),
      headers: { authorization: this.accessToken },
    })

    if (status === 200) {
      return {
        success: true,
        customer: data,
      }
    }
    return {
      success: false,
    }
  }

  static dontAccept() {
    throw 'not-acceptable'
  }
}
