import styled from 'styled-components'

export const Wrapper = styled.div`
  /* details style */
  .details {
    display: flex;
    gap: 25px;
    align-items: center;
    background-color: #fff;
    border-radius: 7px;

    .detail {
      padding: 5px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      flex: 2;
      .row2 {
        display: flex;
      }
      .col {
        display: flex;
        flex-direction: column;

        span {
          font-size: 12px;
          span {
            color: #004e42;
            font-size: 12px;
          }
        }
      }
    }

    .minus {
      svg {
        border: 1px solid var(--accent);
        border-radius: 3px;
      }
    }
    .info {
      background-color: #f8f8f8;
      padding: 20px 20px;
      border-radius: 10px;
      gap: 20px;
      flex: 1;

      .title {
        font-size: 15px;
        padding-bottom: 10px;
      }

      .desc {
        color: #004e42;
        font-size: 14px;
        text-align: justify;
        width: 100%;
      }
    }
    /*  */
    .icon {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
    }
  }
  /* table  */
  table {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 50px;

    border: 0;
    thead {
      th {
        text-align: center;
        font-size: 13px;
        border-bottom-width: 1px;

        padding-bottom: 3px;

        .edit-mode-action {
          display: flex;

          div {
            .parent {
              min-height: 35px;
            }
          }

          div:first-child {
            margin-right: 15px;
          }
        }
      }
      .icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .show-options {
        div {
          display: flex;
          justify-content: center;
        }

        span {
          display: flex;
          align-items: center;
          svg {
            margin-right: 15px;
          }
        }
        span:first-child {
          margin-right: 30px;
        }
      }
      .delete-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div:first-child {
          margin-right: 10px;
        }
        > div {
          .parent {
            min-height: unset;
            padding: 0 10px;
            span {
              font-size: 15px;
            }
          }
        }
      }
    }
    /*  */
    tbody {
      background-color: #fff;

      tr {
        border-bottom: 1px solid #000;
      }

      td {
        background-color: #fff;
        font-size: 12px;
      }

      .pd-code {
        div {
          display: flex;
          align-items: center;
          padding-left: 20px;
        }

        span {
          align-items: center;
          justify-content: center;
          margin-left: 15px;
        }
      }

      .opened {
        div {
          display: flex;
          align-items: center;
          padding-left: 30px;
        }

        span {
          align-items: center;
          position: relative;
          justify-content: center;

          svg {
            position: absolute;
            left: 10%;
          }
        }
      }

      .actions {
        span {
          display: flex;
          justify-content: space-around;
        }
      }

      .mine-kalem {
        .mine {
          width: 16px;
          height: 16px;
          justify-content: center;
        }
        .kalem {
          width: 16px;
          height: 16px;
          justify-content: center;
        }

        div {
          display: flex;
          align-items: center;
          div:first-child {
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          div:last-child {
            margin: 0;
          }
        }
      }

      tr {
        color: #004e42;
      }

      td {
        border-right: 1px solid #959595;

        :last-child {
          border-right: 0;
        }

        .background {
          background: #e5edec;
        }
        white-space: normal;
        text-align: center;
        font-size: 13px;
        .edit-count-input {
          display: flex;
          align-items: center;
          input {
            width: 40px;
            margin-right: 5px;
            height: 30px;
            border: 1px solid var(--accent);
            border-radius: 5px;
            outline: none;
          }
        }
      }

      .code-cell {
        padding: 30px 0;

        .code-cell-weight {
          margin-top: 10px;
          color: #000;
        }
      }

      .cell {
        .closed-image {
          height: 25px;
          margin-right: 20px;
        }

        .monaco-productCode {
          /* display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: center;
          gap: 10px; */
          padding-left: 10px;
        }

        .productCode {
          display: flex;
          position: relative;
          justify-content: space-around;
          align-items: center;
          gap: 10px;
          .image-wrapper {
            width: 30px;
            height: 30px;
            object-fit: unset;
          }
        }
        .problem {
          /* position: absolute;
          right: 0;
          top: -3px; */
        }

        .open-image {
          height: 30px;
          width: 30px;
          border-radius: 8px;
        }

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 17px;
          > span {
            height: 100%;
            width: 100%;
          }
        }

        .type-wrapper {
          display: flex;
          align-items: center;
          width: 100%;

          span:last-child {
            margin-right: 20px;
          }
          div {
            width: 10%;
          }
        }

        > span {
          display: flex;
          justify-content: space-around;
        }
      }
    }

    .highlight {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .checkboxes {
      display: flex;
      flex-direction: row;
      align-items: center;

      div:first-child {
        > label {
          > input {
            &:checked + span.visual {
              background: #325288;
            }
          }
        }
      }
      div:last-child {
        > label {
          > input {
            &:checked + span.visual {
              background: #ec255a;
            }
          }
        }
      }

      > div {
        margin: 0 !important;
      }
    }
    .table-row td,
    .detailed-table-row td {
      font-size: 13px;
      /* height: 70px; */
    }
  }

  .footer {
    position: fixed;
    width: 100%;
    height: 50px;
    z-index: 3;
    bottom: 0;
    border-bottom-left-radius: 20px;
    background: #fff;
    .status {
      display: flex;
      justify-content: center;
      height: 100%;
      gap: 250px;
      align-items: center;

      .title {
        font-weight: bold;
      }

      .value {
        color: var(--accent);
        font-weight: bold;
      }
    }
  }
`
