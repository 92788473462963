import React from 'react'
import * as Lib from './lib'
import { AuthContainer } from 'common/components/layouts/auth/auth-container'
import { Input } from 'common/components/ui-kit/input'
import { Button } from 'common/components/ui-kit/button'
import Timer from 'react-compound-timer'

export const Verification: React.FC = ({}) => {
  const { counter, info, on, code } = Lib.H.useVerification()

  return (
    <>
      <AuthContainer title="Doğrulama">
        <Lib.S.Container>
          <Lib.S.topDescription>
            <p>{info.phone || ''}</p>
            <span>
              {info.comingCode ? '[' + info.comingCode + ']' : ''}
            </span>
          </Lib.S.topDescription>

          <Input
            label="Doğrulama Kodu"
            required
            name="code"
            type="number"
            onFocus={e =>
              e.target.addEventListener(
                'wheel',
                function (e) {
                  e.preventDefault()
                },
                { passive: false },
              )
            }
            hideNumberInputArrows
            onEnter={on.verify}
            value={code.value || ''}
            onChange={evt => code.setValue(evt.currentTarget.value)}
          />

          <Lib.S.counter>
            <div>
              <span>Kalan Süre</span>
              <Timer
                key={counter.reloader}
                initialTime={counter.initialTime}
                direction="backward"
                checkpoints={[{ time: 0, callback: counter.onComplete }]}
              >
                <Timer.Minutes formatValue={counter.parseValue} />
                :
                <Timer.Seconds formatValue={counter.parseValue} />
              </Timer>
            </div>
          </Lib.S.counter>

          <Button callback={on.verify} text="Giriş Yap" mood="light" containerStyles={{ border: 0 }} className="button" />

          {counter.isCompleted && (
            <Lib.S.link>
              <p>
                Kodu Alamadınız mı?
                <span onClick={counter.onResend}> Yeniden Gönder</span>
              </p>
            </Lib.S.link>
          )}
        </Lib.S.Container>
      </AuthContainer>
    </>
  )
}
