import { Content } from 'antd/lib/layout/layout'
import Modal from 'antd/lib/modal/Modal'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Icon } from 'common/components/ui-kit/icon'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from './lib'
import { useEmployees } from './lib/hooks'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Table } from 'common/components/ui-kit/table-2'
import { DatePicker } from 'antd'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/tr_TR'

function FoodMenu() {
  const { val, set, on, get } = useEmployees()

  return (
    <PanelWrapper tab={['automation', '/automation/restaurant/foodMenu']}>
      <Sticky>
        <Lib.S.searchSection>
          <p className="title">Restoran Menüsü</p>

          <div className="d-flex justify-content-end gap-2">
            <div style={{ width: 300 }}>
              <DatePicker
                value={get.query.date ? moment(get.query.date, 'YYYY-MM-DD') : null}
                onChange={(date: moment.Moment | null) => {
                  if (date) {
                    const formattedDateForServer = date.format('YYYY-MM-DD')
                    set.setQuery({ ...get.query, date: formattedDateForServer })
                    set.setFetchAgain(prev => !prev)
                  } else {
                    set.setQuery({ ...get.query })
                    set.setFetchAgain(prev => !prev)
                  }
                }}
                size="small"
                locale={locale}
                allowClear={false}
                className="w-100"
                style={{ height: 40, borderRadius: 7 }}
                inputReadOnly
                format="DD-MM-YYYY"
              />
            </div>
            <Button
              mood="accent"
              callback={() => {
                set.setQuery({ name: '', date: '' })
                set.setFetchAgain(perv => !perv)
              }}
              text="Filtreyi Temizle"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              containerStyles={{ minHeight: 40, width: 150 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />

            <Button
              mood="accent"
              callback={() => {
                on.handleModal()
                set.setModalProps({
                  ...val.modalProps,
                  kind: 'create',
                  editProps: {
                    items: [{ title: '', name: '' }],
                  },
                })
              }}
              text="Menü Ekle"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              icon="plus"
              containerStyles={{ minHeight: 40, width: 180 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />
          </div>
        </Lib.S.searchSection>
      </Sticky>
      <Content>
        <Lib.S.PageWrapper>
          <Lib.S.content>
            <div className="tableContainer">
              <Table
                columns={val.tableColumns}
                columnSizes={val.tableSizes}
                data={val.tableData}
                onLoadMore={on.handleShowMore}
                hasMore={val.tableData.length < get.totalData!}
                paginateLoader={val.paginateLoader}
                infiniteScroll={true}
                rowBackColor={false}
                disabledIndexes={[val.tableIdIndex, val.tableIdIndex - 1]}
                maxHeight="calc(100vh - 110px)"
              />
            </div>
          </Lib.S.content>
        </Lib.S.PageWrapper>

        {/* edit or create modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showEditModal}
          onCancel={on.handleCancelModal}
          className="noHeaderModal userModal"
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>{val.modalProps.kind === 'edit' ? 'Yemek Menüsünü Düzenle' : 'Yemek Menüsünü Oluştur'}</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={on.handleCancelModal} style={{ cursor: 'pointer' }} />
            </div>
            <div className="row mt-2 px-3">
              <div className="col-lg-6">
                <div className="antDatePicker w-100 d-flex flex-column">
                  <span style={val.isEmpty && !Boolean(val.date) ? { color: 'red' } : undefined}>
                    Tarih <span style={{ color: 'red' }}>*</span>
                  </span>
                  <DatePicker
                    value={val.date ? moment(val.date, 'YYYY-MM-DD') : null}
                    onChange={(date: moment.Moment | null) => {
                      if (date) {
                        const formattedDateForServer = date.format('YYYY-MM-DD')
                        set.setDate(formattedDateForServer)
                      } else {
                        set.setDate('')
                      }
                    }}
                    size="small"
                    locale={locale}
                    allowClear={false}
                    style={{
                      height: 46,
                      borderRadius: 7,
                      borderColor: val.isEmpty && !Boolean(val.date) ? 'red' : 'rgb(0 0 0 / 10%)',
                      backgroundColor: val.isEmpty && !Boolean(val.date) ? 'rgb(164, 0, 0,0.1)' : 'unset',
                    }}
                    inputReadOnly
                    format="DD-MM-YYYY"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-2 px-3 align-items-end">
              <div className="col-lg-11 d-flex align-items-center gap-2">
                {/* <Input
                  label="Yemek türü"
                  onChange={e => on.handleItemChange(0, 'title', e.target.value)}
                  lessBorder
                  required
                  value={val.plusName[0]?.title}
                  inputStyles={
                    val.isEmpty && val.plusName[0]?.title.length < 1
                      ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0, 0.1)' }
                      : undefined
                  }
                  labelStyles={val.isEmpty && val.plusName[0]?.title.length < 1 ? { color: 'red' } : undefined}
                /> */}
                <Input
                  label="Yemek Adı"
                  onChange={e => on.handleItemChange(0, 'name', e.target.value)}
                  lessBorder
                  required
                  value={val.plusName[0]?.name}
                  inputStyles={
                    val.isEmpty && val.plusName[0]?.name.length < 1
                      ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0, 0.1)' }
                      : undefined
                  }
                  labelStyles={val.isEmpty && val.plusName[0]?.name.length < 1 ? { color: 'red' } : undefined}
                />
              </div>
              <div className="col-lg-1">
                {val.plusName.length < 5 && (
                  <Icon
                    name="plus_with_border_Green"
                    size={20}
                    style={{ height: '20px !important', marginBottom: 13 }}
                    onClick={on.handlePlusName}
                  />
                )}
              </div>
            </div>

            {val.plusName.length > 1 &&
              val.plusName.slice(1).map((item, index) => (
                <div key={index + 1} className="row mt-2 px-3 align-items-end">
                  <div className="col-lg-11 d-flex align-items-center gap-2">
                    {/* <Input
                      label="Yemek türü"
                      onChange={e => on.handleItemChange(index + 1, 'title', e.target.value)}
                      lessBorder
                      value={item?.title}
                      inputStyles={
                        val.isEmpty && item.title?.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0, 0.1)' } : undefined
                      }
                      labelStyles={val.isEmpty && item?.title.length < 1 ? { color: 'red' } : undefined}
                    /> */}
                    <Input
                      label="Yemek Adı"
                      onChange={e => on.handleItemChange(index + 1, 'name', e.target.value)}
                      lessBorder
                      value={item?.name}
                      inputStyles={
                        val.isEmpty && item.name?.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0, 0.1)' } : undefined
                      }
                      labelStyles={val.isEmpty && item.name?.length < 1 ? { color: 'red' } : undefined}
                    />
                  </div>
                  <div className="col-lg-1">
                    <Icon
                      name="cross_with_border"
                      color="red"
                      size={20}
                      style={{ height: '20px !important', marginBottom: 13 }}
                      onClick={() => on.handleRemovePlusName(index + 1)}
                    />
                  </div>
                </div>
              ))}

            <div className="btn">
              <Button text="Vazgeç" mood="accent_outline" callback={() => on.handleCancelModal()} className="btn" />
              <Button
                text={val.modalProps.kind === 'edit' ? 'Düzenle' : 'Oluştur'}
                mood="accent"
                callback={() => (val.modalProps.kind === 'edit' ? on.EditFoodMenu() : on.CreateFoodMenu())}
                className="btn"
              />
            </div>
          </Lib.S.ModalContainer>
        </Modal>
      </Content>
      {/* Delete modal */}
      <Modal visible={val.showDeleteModal} onCancel={on.handleDeleteModalVisibility} footer={null} title="Yemek Menüsünü Sil">
        <Lib.S.DeleteModalContent>
          <p>Yemek Menüsünü silmek istiyor musunuz?</p>
          <div>
            <Button text="Vazgeç" mood="accent" callback={() => set.setShowDeleteModal(perv => !perv)} />
            <Button text="Sil" mood="danger" callback={() => on.deleteFoodMenu(val.modalProps.editProps.id ?? 0)} />
          </div>
        </Lib.S.DeleteModalContent>
      </Modal>
    </PanelWrapper>
  )
}

export default FoodMenu
