import { String } from './string'
import axios from 'axios'

const getImageSizes = (dom: HTMLImageElement) => {
  return {
    nw: dom.naturalWidth,
    nh: dom.naturalHeight,
    ow: dom.offsetWidth,
    oh: dom.offsetHeight,
    cw: dom.clientWidth,
    ch: dom.clientHeight,
  }
}

const getImagesCollection = (tar: string) => {
  const HTMLCollection = document.querySelector(tar)?.getElementsByTagName('IMG')
  return HTMLCollection
}

const minimumResizer = (minimum: number, target: 'any' | 'height' | 'width', width: number, height: number) => {
  const widthConditioner = (minimum: number, width: number, height: number) => {
    let h: number,
      w: number = 0
    if (width < minimum) {
      const operator = minimum / width
      w = minimum
      h = height * operator
    } else {
      h = height
      w = width
    }
    return { h, w }
  }
  const heightConditioner = (minimum: number, width: number, height: number) => {
    let h: number,
      w: number = 0
    if (height < minimum) {
      const operator = minimum / height
      h = minimum
      w = width * operator
    } else {
      h = height
      w = width
    }
    return { h, w }
  }
  switch (target) {
    case 'any':
      if (width < height) {
        return widthConditioner(minimum, width, height)
      } else {
        return heightConditioner(minimum, width, height)
      }
    case 'height':
      return heightConditioner(minimum, width, height)
    case 'width':
      return widthConditioner(minimum, width, height)
    default:
      return { w: width, h: height }
  }
}

const maximumResizer = (maximum: number, target: 'any' | 'height' | 'width', width: number, height: number) => {
  const widthConditioner = (maximum: number, width: number, height: number) => {
    let h: number,
      w: number = 0
    if (width > maximum) {
      const operator = width / maximum
      w = maximum
      h = height / operator
    } else {
      h = height
      w = width
    }
    return { h, w }
  }
  const heightConditioner = (maximum: number, width: number, height: number) => {
    let h: number,
      w: number = 0
    if (height > maximum) {
      const operator = height / maximum
      h = maximum
      w = width / operator
    } else {
      h = height
      w = width
    }
    return { h, w }
  }
  switch (target) {
    case 'any':
      if (width < height) {
        return widthConditioner(maximum, width, height)
      } else {
        return heightConditioner(maximum, width, height)
      }
    case 'height':
      return heightConditioner(maximum, width, height)
    case 'width':
      return widthConditioner(maximum, width, height)
    default:
      return { w: width, h: height }
  }
}

export { getImageSizes, getImagesCollection, minimumResizer as resizer, maximumResizer }

const toBase64Worker = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export const b64ToFile = (dataUrl: string) => {
  if (dataUrl.length <= 5) return

  const arr = dataUrl.split(','),
    // @ts-ignore
    mime = arr[0].match(/:(.*?)/)[1],
    bstr = atob(arr[1])
  let n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], String.randomString(30).concat('.png'), { type: mime })
}

export const sieveFilesFormat = (files: File[]) => {
  const newFiles = []
  const formats = [
    'image/jpeg',
    'image/webp',
    'image/png',
    'image/jpg',
    'image/tiff',
    'image/pjp',
    'image/bmp',
    'image/svg',
    'image/svgz',
    'image/xbm',
    'image/tif',
    'image/dib',
    'image/pjpeg',
    'image/avif',
  ]
  for (let i = 0; i < files.length; i++) if (formats.indexOf(files[i].type) > -1) newFiles.push(files[i])
  return newFiles
}

export const resizeImage = (b64: string, maxWidth: number): any =>
  new Promise((resolve, reject) => {
    let w, h
    const img = new Image()
    img.src = b64
    img.onerror = error => reject(error)
    img.onload = () => {
      w = img.naturalWidth
      h = img.naturalHeight
      if (w > maxWidth) {
        const ref = maxWidth / w
        const newHeight = h * ref
        resolve({ w: maxWidth, h: newHeight })
      } else resolve({ w, h })
    }
  })

export const File2Base64 = async (file: File): Promise<any> => {
  const result = await toBase64Worker(file).catch(e => Error(e))
  if (result instanceof Error) {
    return result.message
  } else {
    return result
  }
}

export const decreaseSize = (base64: string, newWidth: number, newHeight: number) => {
  return new Promise((resolve, reject) => {
    var canvas = document.createElement('canvas')
    canvas.style.width = newWidth.toString() + 'px'
    canvas.style.height = newHeight.toString() + 'px'
    document.getElementById('xxxxxx')?.appendChild(canvas)
    let context = canvas.getContext('2d')
    let img = document.createElement('img')
    img.src = base64
    img.onload = function () {
      context?.scale(newWidth / img.width, newHeight / img.height)
      context?.drawImage(img, 0, 0)
      resolve(canvas.toDataURL())
    }
    img.onerror = error => reject(error)
  })
}

export const imgUrlToB64 = async (url: string) => {
  const response = await fetch(url, {
    method: "GET"
  });
  const blob = await response.blob()
  const reader = new FileReader()
  await new Promise((resolve, reject) => {
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })

  return <string>reader.result
}

export const pathTothumb: (path?: string) => string = path => path || ''

export const imageAddresser = (path: string, isThumb?: boolean) => {
  return path;
}
