import { FC, useEffect, useState } from 'react'
import { Icon } from '../icon'
import * as Lib from './lib'
import AnimateHeight from 'react-animate-height'
import { Scrollbar } from 'react-scrollbars-custom'
import { useSelector } from 'react-redux'
import { RootState } from 'common/store/root-reducer'

export const Drawer: FC<Lib.T.DrawerProps> = ({ title, children, visible, borderBottom = true, isSubItem, maxHeight }): JSX.Element => {
  const [content, showContent] = useState<number | string>(visible ? 'auto' : 0)
  const { sellers, orderStatus, customers, ayar, date, departments, types } = useSelector((state: RootState) => state.filters)

  // useEffect(() => {
  //   if (
  //     (title === sellers.title && sellers.selected.length) ||
  //     (title === orderStatus.title && orderStatus.selected.length) ||
  //     (title === customers.title && customers.selected.length) ||
  //     (title === ayar.title && ayar.selected.length) ||
  //     (title === date.title && date.selected.length) ||
  //     (title === departments.title && departments.selected.length) ||
  //     (title === types.title && !!types.selected)
  //   ) {
  //     showContent('auto')
  //   } else {
  //     showContent(0)
  //   }
  // }, [sellers, orderStatus, customers, ayar, date, title, departments])

  return (
    <>
      <Lib.S.container
        className="drawer-component"
        borderBottom={borderBottom}
        visibility={content !== 0}
        isSubItem={isSubItem}
        maxHeight={maxHeight}
      >
        <div className="title" style={{padding: '7px 20px'}} onClick={() => showContent(content === 0 ? 'auto' : 0)}>
          <p style={{fontWeight: 'bold'}}>{title}</p>
          <span>
            <Icon name={`${content === 0 ? 'chevron_down' : 'chevron_up'}`} size={12} color="black" />
          </span>
        </div>

        <Scrollbar className="body" style={{ width: '100%'}}>
          <AnimateHeight id="example-panel" duration={250} className={`${content === 0 ? 'closed' : 'opened'}`} height={content}>
            {children && children}
          </AnimateHeight>
        </Scrollbar>
      </Lib.S.container>
    </>
  )
}
