import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 10px;
  background-color: #fff;

  .button {
    width: 10%;
    margin: 0 auto;
    height: 50px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
`

export const PDFContainer = styled.div`
  margin-top: 20px;
  padding: 0 30px;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-wrapper {
      margin-bottom: 20px;
      background-color: #f8f8f8;
      border-radius: 7px;
      padding: 10px;
      flex: 3;

      .header-item-description {
        display: flex;
        gap: 33.5px;
      }

      .header-item {
        padding: 5px 0;
        > span {
          font-weight: bold;
          font-size: 0.8rem;
        }

        span:first-child {
          color: #4d4d4d;
        }
        span:last-child {
          margin-left: 5px;
        }
      }
    }
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0.5;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .sozer-wrapper {
    gap: 50px;
    margin: 50px 0;

    .sozer-table {
      float: left;
      justify-self: start;
      background-color: #f8f8f8;
      border-radius: 7px;
      margin-bottom: 20px;
      td,
      th {
        padding: 10px;
        text-align: center;
        font-size: 0.6rem;
      }

      th {
        font-weight: 600;
        background-color: #f8f8f8;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }

      tbody {
        tr:nth-child(odd) {
          background-color: #e5edec;
        }
        tr:last-child {
          td:first-child {
            border-bottom-left-radius: 7px;
          }

          td:last-child {
            border-bottom-right-radius: 7px;
          }
        }
      }
    }

    .item {
      margin-bottom: 50px;
      float: left;
      max-width: 150px;
      min-width: 150px;
      border: 0.5px solid #000;
      border-radius: 7px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 7px;
        object-fit: contain;
      }
      p {
        font-weight: bolder;
        text-align: center;
        margin: 0;
        font-size: 0.7rem;
      }
    }
  }

  .monaco-wrapper {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 20px;

    //style for table componenet
    .header {
      padding: 0 !important;
    }

    .header-item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;

      .label-item {
        color: #fff;
        padding: 2px;
        background-color: var(--accent);
        border: 1px solid #000;
      }

      .karat-item {
        border-top: none !important;
        border-bottom: none !important;
      }

      .value-item {
        background-color: #ccc;
        border: 1px solid #000;
        padding: 2px;
      }
    }
  }
`
