import { DepartmentService } from 'common/services'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from './crypto.helper'

export class Storage {
  static set<T>(key: string, value: T, nonObject?: boolean) {
    const Value = <string>(nonObject ? value : JSON.stringify(value))
    localStorage.setItem(key, Value)
  }

  static get<T>(key: string, nonObject?: boolean): T | null {
    const Value = localStorage.getItem(key) as string | null

    if (!Value) {
      return null
    }
    return nonObject ? Value : JSON.parse(Value)
  }

  static getUseraccessToken<T extends SavedUser>() {
    const user = Storage.get<T>('user')

    if (!user || !user.accessToken) {
      DepartmentService.dontAccept()
    }

    const decryptedAccessToken = Crypto.decrypt(user!.accessToken)

    if (!decryptedAccessToken) {
      DepartmentService.dontAccept()
    }

    return decryptedAccessToken
  }
}
