import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import swal from 'sweetalert'
import { DOM } from 'common/helpers/dom'
import { CreateOrEditReturnTypeArea, ReadDataTypeArea, ReadReturnTypeArea } from './lib/types/options'

export class AreaService {
  accessToken: string

  constructor() {

    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      AreaService.dontAccept()
    }

    if (!user!.accessToken) {
      AreaService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async createArea(args: string): Promise<Lib.T.Options.CreateOrEditReturnType> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat('area'),
      headers: { authorization: this.accessToken },
      data: { code: args },
    })

    const { status } = response
    if (status !== 201) {
      AreaService.unhandled(response.data.message)
      return { success: false }
    }

    return {
      success: true,
    }
  }

  async edit(id: number, args: string): Promise<Lib.T.Options.CreateOrEditReturnTypeArea> {
    const { status, data } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat('area/').concat(id.toString()),
      headers: { authorization: this.accessToken },
      data: { code: args },
    })

    if (status !== 200) {
      AreaService.unhandled(data.message)
      return { success: false }
    }

    return { success: true }
  }

  async readArea(): Promise<Lib.T.Options.ReadReturnTypeArea> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat('area'),
      headers: { authorization: this.accessToken },
    })

    try {
      const { status } = response
      if (status !== 200) {
        return { success: false, total: 0 }
      }

      const data: Lib.T.Options.ReadDataTypeArea = response.data

      return {
        success: true,
        data,
        total: data.total,
      }
    } catch (e) {
      return { success: false, total: 0 }
    }
  }

  async readSingleArea(id: number): Promise<Lib.T.Options.ReadReturnTypeArea> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat('area/')!.concat(id.toString()),
      headers: { authorization: this.accessToken },
    })

    try {
      const { status } = response
      if (status !== 200) {
        return { success: false, total: 0 }
      }

      const data: Lib.T.Options.ReadDataTypeArea = response.data

      return {
        success: true,
        data,
      }
    } catch (e) {
      return { success: false}
    }
  }

  async delete(id: number): Promise<boolean> {
    const response = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`area/${id}`),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response
    if (status !== 200 || data !== '') {
      AreaService.unhandled()
      return false
    }

    return true
  }

  static dontAccept() {
    throw 'not-acceptable'
  }

  static unhandled(errorMessage?: string) {
    if (errorMessage?.includes('productCode')) {
      return swal({
        text: 'Bir şeyler ters gitti',
        dangerMode: true,
        icon: 'error',
      })
    } else if (errorMessage?.includes('productCategoryImage is required.')) {
      return swal({
        text: 'Tüm alanlar doldurulmalıdır.',
        dangerMode: true,
        icon: 'error',
      })
    } else if (errorMessage?.includes('duplicate')) {
      return swal({
        text: 'Bu kategoridan bir kez oluşturulmuş.',
        dangerMode: true,
        icon: 'error',
      })
    } else {
      return swal({
        text: 'Bir şeyler ters gitti, lütfen tekrar deneyin.',
        dangerMode: true,
        icon: 'error',
      })
    }
  }
}
