import { useQuery } from 'common/helpers/getQueryParam'
import { useLoader } from 'common/hooks/use-loader'
import { OrdersService } from 'common/services'
import { OrderLogs } from 'common/services/lib/types/orders'
import { useEffect, useState } from 'react'
import * as Lib from '.'

const filterDataInitialState = { paginate: { limit: 80, page: 1 } }

export const useList = () => {
  const modeQueryString = useQuery().get('mode')

  const { setLoader, loader } = useLoader()

  const [list, setList] = useState<OrderLogs[]>([])
  const [totalData, setTotalData] = useState<number | undefined>()
  const [filterData, setFilterData] = useState<Lib.T.FilterDataType>(filterDataInitialState)

  const getLogs = async (args?: Lib.T.getLogsArgsType) => {
    const service = new OrdersService()
    setLoader(true)

    const { success, total, orderLogs } = await service.getLogs({
      ...args,
    })

    if (!success) {
      setLoader(false)

      return
    }

    setList(perv => [...perv, ...(orderLogs as OrderLogs[])])
    setTotalData(total)
    setLoader(false)
  }

  const handleFilter = () => {
    setFilterData(perv => ({ ...perv, paginate: { ...perv.paginate, page: 1 } }))
    setList([])
  }

  const handleClearFilter = () => {
    setFilterData(filterDataInitialState)
  }

  const handleShowMore = () => {
    if (totalData! / filterData.paginate.page <= filterData.paginate.limit) {
      return
    }
    setFilterData(perval => {
      return { ...perval, paginate: { ...perval.paginate, page: perval.paginate.page + 1 } }
    })
  }

  useEffect(() => {
    getLogs({
      orderId: filterData.orderId,
      limit: filterData.paginate.limit,
      page: filterData.paginate.page,
      from: filterData.from,
      to: filterData.to,
    })
  }, [filterData.paginate])

  return {
    get: { list, totalData, filterData, loader },
    set: { setFilterData },
    on: { handleShowMore, handleFilter, handleClearFilter },
  }
}
