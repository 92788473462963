import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import { DOM } from 'common/helpers/dom'
import swal from 'sweetalert'

export class ProductSubCategory {
  accessToken: string

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      ProductSubCategory.dontAccept()
    }
    if (!user!.accessToken) {
      ProductSubCategory.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async create({ image, name, parent, widths }: Lib.T.ProductSubCategory.CreateArgs): Promise<Lib.T.ProductSubCategory.CreateResult> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat('product-sub-categories'),
      headers: { authorization: this.accessToken },
      data: {
        productSubCategory: name,
        productSubCategoryImage: image,
        parent,
        widths,
      },
    })
    const { status, data } = response

    if (status !== 201) {
      if (response.data.message.includes('productSubCategoryImage must be a string')) {
        return swal({
          text: 'Tüm alanlar doldurulmalıdır.',
          dangerMode: true,
          icon: 'error',
        })
      } else if (response.data.message.includes('duplicate')) {
        return swal({
          text: 'Bu kategoridan bir kez oluşturulmuş.',
          dangerMode: true,
          icon: 'error',
        })
      }
    }

    if (status !== 201 || !data.productSubCategory) {
      swal({
        title: 'Alt kategori oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, data: data.productSubCategory }
  }

  async getList(args?: Lib.T.ProductSubCategory.GetListArgs): Promise<Lib.T.ProductSubCategory.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat('product-sub-categories').concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response

    if (status !== 200) {
      swal({
        title: 'Alt kategori oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, data: data.productSubCategories }
  }

  async delete(id: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`product-sub-categories/${id}`),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      swal({
        title: 'Alt kategori silinemedi. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
    }

    return status === 200
  }

  async edit(id: number, args: Lib.T.ProductSubCategory.EditArgs): Promise<Lib.T.ProductSubCategory.EditResult> {
    const { status, data } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(`product-sub-categories/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    })

    if (status !== 200 || !data.productSubCategory) {
      swal({
        text: 'Alt kategori düzenlenemedi. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })

      return { success: false }
    }

    return { success: true, data: data.productSubCategory }
  }

  static dontAccept() {
    throw 'not-acceptable'
  }
}
