import styled from 'styled-components'
import { mediaQueries as mq, createQuery as ca } from 'common/constants/media-queries'

export const container = styled.nav`
  float: left;
  width: 100%;
  height: 70px;
  background: #f8f8f8;
  border-bottom: 1px solid #36374026;
  display: flex;
  flex-direction: row-reverse;
  padding: 0 25px;

  > .title {
    color: #252733;
    margin: 0;
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: var(--f-bo);

    ${mq.max[768]} {
      display: none;
    }
  }

  > .profile {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0 0 0 15px;
    margin: 0 0 0 15px;
    position: relative;

    ${mq.max[768]} {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      background: #dfe0eb;
      height: 35px;
      margin: auto;
    }

    > img {
      width: 50px;
    }

    > p {
      margin: 0 15px 0 0;
      font-size: 18px;
    }
  }

  > .icon {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .logo {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    ${ca(769, 'min')} {
      display: none;
    }

    > img {
      max-height: 30px;
    }
  }

  > .menuToggler {
    width: 100px;
    align-items: center;
    display: flex;

    ${ca(769, 'min')} {
      display: none;
    }
  }
`

export const optionButton = styled.button<{ hasBadge?: boolean }>`
  outline: none !important;
  width: 35px;
  height: 35px;
  border: none;
  transition: all 150ms linear;
  border-radius: 40%;
  background-color: transparent;
  position: relative;

  &:hover {
    background: #1e4f4208;
  }

  &:after {
    ${({ hasBadge }) => (hasBadge ? `content: ''` : null)};
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #f0ad4b;
    border: 4px solid #f8f8f8;
  }
`
