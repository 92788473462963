import { Modal } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'
import { Icon } from '../icon'
import * as Lib from './lib'

export const Uploader: FC<Lib.T.UploaderProps> = ({
  view = 'row',
  onChange,
  id,
  title,
  defaultImage,
  modalImage,
  fileIcon,
  closeIsCovered,
  className,
  isDefaultImage,
  size,
  clearPreview,
}): JSX.Element => {
  const dnd = Lib.H.useUploader({ onChange, defaultImage, modalImage })
  const [image, setImage] = useState<string | undefined>('')
  const [largeImage, setLargeImage] = useState<string | undefined>('')

  useEffect(() => {
    return () => {
      dnd.handleRemove()
    }
  }, [])

  useEffect(() => {
    setImage(defaultImage)
    setLargeImage(modalImage)
  }, [defaultImage, modalImage])

  const input = useRef<HTMLInputElement>(null)
  return (
    <>
      {(largeImage || dnd.b64State) && (
        <Modal {...dnd.modal.props}>
          {closeIsCovered && (
            <Icon
              name="trash_bin"
              color="black"
              size={closeIsCovered ? 20 : undefined}
              onClick={() => {
                setLargeImage('')
                dnd.handleRemove()
                dnd.modal.toggle()
              }}
              style={{ cursor: 'pointer' }}
            />
          )}
          <div className="position-relative">
            <img src={dnd.b64State || largeImage || ''} style={{ width: '100%' }} alt="" />
          </div>
        </Modal>
      )}

      <input
        type="file"
        hidden
        ref={input}
        id={id}
        className="file"
        onChange={e => {
          dnd.handleFileChange(e)
        }}
        multiple={false}
        accept=".jpg, .jpeg, .png, .webp"
      />
      <Lib.S.container
        view={view}
        onDrop={dnd.onDrop}
        onDragOver={dnd.onDragOver}
        onDragLeave={dnd.onDragLeave}
        isOver={dnd.isOver}
        fileIcon={fileIcon}
        closeIsCovered={closeIsCovered}
        className={className}
        size={size}
      >
        <div
          className={`${isDefaultImage ? 'adderCustomerImage' : 'adder'}`}
          onClick={() => dnd.openDialog(id)}
          onMouseDown={() => {
            dnd.setB64State(null)
            dnd.setFileState(null)
            input.current!.files = null
            input.current!.value = ''
          }}
        >
          <span>
            <Icon name="plus" color="var(--accent)" size={15} />
          </span>
          <p>{title || 'Fotoğraf Ekle'}</p>
        </div>

        <div>
          {fileIcon && (
            <div
              className="dropper"
              onClick={() => {
                dnd.openDialog(id)
              }}
            >
              <div>
                <Icon name="folder" color="var(--accent)" size={40} />
              </div>
            </div>
          )}
          {dnd.b64State && dnd.fileState && !isDefaultImage ? (
            <div className="preview ">
              <div className={id} style={{ backgroundImage: `url(${dnd.b64State})`, cursor: 'pointer', marginLeft: 10 }} onClick={dnd.modal.toggle}></div>
            </div>
          ) : (
            !isDefaultImage && <div className="preview" onClick={dnd.onDefaultImageClick} style={{ cursor: image ? 'pointer' : 'default', marginLeft: 10 }}>
              <div className={id} style={{ backgroundImage: `url(${image || '/images/jpg/placeholder.jpg'})` }} />
            </div>
          )}
        </div>
      </Lib.S.container>
    </>
  )
}
