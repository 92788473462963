import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { ReducerType } from 'common/typing/reducer-payload.type'
import * as types from 'common/constants/action-types'

export interface InitialFiltersState {
  sellers: {
    title: 'Sipariş Sorumlusu'
    selected: CheckboxValueType[]
  }
  orderStatus: {
    title: 'Sipariş Durumu'
    selected: CheckboxValueType[]
  }
  departments: {
    title: 'Departman'
    selected: CheckboxValueType[]
  }
  types: {
    title: 'Numune'
    selected: string
  }
  customers: {
    title: 'Müşteri İsmi'
    selected: CheckboxValueType[]
  }
  ayar: {
    title: 'Ayar'
    selected: CheckboxValueType[]
  }

  date: {
    title: 'Tarih'
    selected: [string, string] | []
  }

  resetFilterCustomerSearch: false,
  resetFilterSellerSearch: false,
}

const initialState: InitialFiltersState = {
  sellers: {
    title: 'Sipariş Sorumlusu',
    selected: [],
  },
  customers: {
    title: 'Müşteri İsmi',
    selected: [],
  },
  ayar: {
    title: 'Ayar',
    selected: [],
  },
  orderStatus: {
    title: 'Sipariş Durumu',
    selected: ['in-production', 'pending', 'approved', 'in-card', 'receive-from-customer'],
  },
  departments: {
    title: 'Departman',
    selected: [],
  },
  types: {
    title: 'Numune',
    selected: '',
  },
  date: {
    title: 'Tarih',
    selected: [],
  },
  resetFilterCustomerSearch: false,
  resetFilterSellerSearch: false,
}

export const filters = (state: InitialFiltersState = initialState, { type, payload }: ReducerType): InitialFiltersState => {
  switch (type) {
    // sellers
    case types.UPDATE_SELLERS:
      return {
        ...state,
        sellers: { ...state.sellers, selected: payload },
      }

    // customers
    case types.UPDATE_CUSTOMERS:
      return {
        ...state,
        customers: { ...state.customers, selected: payload },
      }
    //   ayar
    case types.UPDATE_AYAR:
      return {
        ...state,
        ayar: { ...state.ayar, selected: payload },
      }
    //   status
    case types.UPDATE_STATUS:
      return {
        ...state,
        orderStatus: { ...state.orderStatus, selected: payload },
      }

    //   departments
    case types.UPDATE_DEPARTMENTS:
      return {
        ...state,
        departments: { ...state.departments, selected: payload },
      }
    //   types
    case types.UPDATE_TYPES:
      return {
        ...state,
        types: { ...state.types, selected: payload },
      }
    //   date
    case types.UPDATE_DATE:
      return {
        ...state,
        date: { ...state.date, selected: payload },
      }

    //reset filters
    case types.RESET_FILTER:
      return {
        ...state,
        sellers: { ...state.sellers, selected: initialState.sellers.selected },
        customers: { ...state.customers, selected: initialState.customers.selected },
        orderStatus: { ...state.orderStatus, selected: initialState.orderStatus.selected },
        departments: { ...state.departments, selected: initialState.departments.selected },
        types: { ...state.types, selected: initialState.types.selected },
        ayar: { ...state.ayar, selected: initialState.ayar.selected },
        date: { ...state.date, selected: initialState.date.selected },
      }
    case types.RESET_CUSTOMER_FILTER:
      return {
        ...state,
        resetFilterCustomerSearch: payload,
      }
    case types.RESET_SELLER_FILTER:
      return {
        ...state,
        resetFilterSellerSearch: payload,
      }
    default:
      return state
  }
}
