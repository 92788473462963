import React from 'react'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { List } from 'common/components/ui-kit/list'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Content } from 'common/components/ui-kit/content'
import * as Lib from './lib'

export const Coals: React.FC = ({}) => {
  const { on, addStates, editStates, list } = Lib.H.useCoal()

  return (
    <>
      <PanelWrapper tab={['options', '/options/product-codes']} title="Seçenek Oluşturma">
        <Sticky>
          <p className="title">Maden Rengi</p>
          <Button text="Ekle" callback={on.create} mood="accent" className="submitButton" />
        </Sticky>

        <Content>
          <div className="section col-lg-6 col-md-12">
            <Lib.C.Contents {...addStates} uploaderId="productCategoryUploader" onEnter={on.create} />
          </div>

          <div className="section col-lg-6 col-md-12">
            {list && (
              <List
                items={list}
                onDelete={on.delete}
                edit={{
                  content: <Lib.C.Contents {...editStates} uploaderId="editProductCategoryUploader" onEnter={on.edit} />,
                  beforeConfirm: on.beforeConfirmEdit,
                  onSubmit: on.edit,
                  overrideVisibilityState: editStates.visibility,
                }}
              />
            )}
          </div>
        </Content>
      </PanelWrapper>
    </>
  )
}
