import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Button } from 'common/components/ui-kit/button'
import * as Lib from './lib/index'
import { Content } from 'antd/lib/layout/layout'
import { Table } from 'common/components/ui-kit/table-2'
import Modal from 'antd/lib/modal/Modal'
import { Icon } from 'common/components/ui-kit/icon'
import { Input } from 'common/components/ui-kit/input'

function DrinkType() {
  const { val, set, on, get } = Lib.H.useDrinkType()

  return (
    <PanelWrapper tab={['automation', '/automation/restaurant/drinkType']}>
      <Sticky>
        <Lib.S.searchSection>
          <p className="title">Kafeterya</p>

          <div className="d-flex justify-content-end gap-2">
            <Button
              mood="accent"
              callback={() => {
                set.setShowModal(true)
                set.setModalProps({ ...val.modalProps, kind: 'create' })
              }}
              text="İçecek Oluştur"
              iconProps={{ size: 15, color: '#fff', style: { marginRight: 10 } }}
              icon="plus"
              containerStyles={{ minHeight: 40, width: 180 }}
              parentStyles={{ display: 'flex', justifyContent: 'flex-end', width: 'fit-content' }}
            />
          </div>
        </Lib.S.searchSection>
      </Sticky>

      <Content>
        <Lib.S.PageWrapper>
          <Lib.S.content>
            <div className="tableContainer">
              <Table
                columns={val.tableColumns}
                columnSizes={val.tableSizes}
                data={val.tableData}
                onLoadMore={on.handleShowMore}
                hasMore={val.tableData.length < get.totalData!}
                paginateLoader={val.paginateLoader}
                infiniteScroll={true}
                rowBackColor={false}
                disabledIndexes={[val.tableIdIndex, val.tableIdIndex - 1]}
                maxHeight="calc(100vh - 110px)"
              />
            </div>
          </Lib.S.content>
        </Lib.S.PageWrapper>

        {/* Create and Edit modal */}
        <Modal
          footer={null}
          title={null}
          visible={val.showModal}
          onCancel={on.handleModal}
          className="noHeaderModal userModal"
          width={700}
          destroyOnClose
        >
          <Lib.S.ModalContainer>
            <div className="modalTitle">
              <div>
                <span>{val.modalProps.kind === 'edit' ? 'İçeceği düzenle' : 'İçecek oluştur'}</span>
              </div>
              <Icon name="close" color="#fff" size={17} onClick={on.handleModal} style={{ cursor: 'pointer' }} />
            </div>

            <div className="row mt-2 px-3">
              <div className="col-lg-6">
                <Input
                  label="İçecek"
                  onChange={e => {
                    set.setName(e.target.value)
                  }}
                  required
                  lessBorder
                  value={val.name}
                  inputStyles={
                    val.isEmpty && val.name.length < 1 ? { borderColor: 'red', backgroundColor: 'rgb(164, 0, 0,0.1)' } : undefined
                  }
                  labelStyles={val.isEmpty && val.name.length < 1 ? { color: 'red' } : undefined}
                />
              </div>
            </div>

            <div className="btn">
              <Button
                text="Vazgeç"
                mood="accent_outline"
                callback={() => {
                  on.handleModal()
                }}
                className="btn"
              />
              <Button
                text={val.modalProps.kind === 'edit' ? 'Düzenle' : 'Oluştur'}
                mood="accent"
                callback={() =>
                  val.modalProps.kind === 'edit' ? on.EditDrinkType(val.modalProps.editProps.id ?? 0) : on.CreateDrinkType()
                }
                className="btn"
              />
            </div>
          </Lib.S.ModalContainer>
        </Modal>

        {/* Delete modal */}
        <Modal visible={val.showDeleteModal} onCancel={on.handleDeleteModalVisibility} footer={null} title="İçeceği Sil">
          <Lib.S.DeleteModalContent>
            <p>İçeceği silmek istediğinizden emin misiniz?</p>
            <div>
              <Button text="Vazgeç" mood="accent" callback={() => set.setShowDeleteModal(perv => !perv)} />
              <Button text="Sil" mood="danger" callback={() => on.deleteDrinkType(val.modalProps.editProps.id ?? 0)} />
            </div>
          </Lib.S.DeleteModalContent>
        </Modal>
      </Content>
    </PanelWrapper>
  )
}

export default DrinkType
