import { useEffect, useState } from 'react'
import { OptionService } from 'common/services'
import { Options } from 'common/services/lib/types'
import * as Lib from '.'
import { UNHANDLED } from 'common/constants/errors'
import { ListObject } from 'common/components/ui-kit/list/lib/typing'
import { useDispatch } from 'react-redux'
import { Global } from 'common/store/actions'
import swal from 'sweetalert'

export const useCarats = () => {
  const [karatName, setKaratName] = useState<string | null>(null)
  const [purityName, setPurityName] = useState<string | null>(null)
  const [gramMultiplierName, setGramMultiplierName] = useState<string | null>(null)

  const [editKaratName, setEditKaratName] = useState<string | null>(null)
  const [editPurityName, setEditPurityName] = useState<string | null>(null)
  const [editGramMultiplierName, setEditGramMultiplierName] = useState<string | null>(null)
  const [itemIdToEdit, setItemIdToEdit] = useState<number | null>(null)
  const [editModal, setEditModal] = useState<boolean>(false)

  const [list, setList] = useState<ListObject[]>([])
  const dispatch = useDispatch()
  const setLoader = (payload: boolean) => dispatch(Global.fsLoader(payload))

  const onStartUp = async () => {
    setLoader(true)
    const optionService = new OptionService({ type: 'karat' })
    const result = await optionService.read()

    if (!result.success || !result.options) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    const options = <Lib.T.List[]>result.options
    const listArray: ListObject[] = []
    options.forEach(item =>
      listArray.push({
        id: item.id,
        name: `${item.karat}${item.purity ? ' / ' + item.purity : ''}${item.gramMultiplier ? ' / ' + item.gramMultiplier : ''}`,
      }),
    )
    setList(listArray)
    setLoader(false)
  }

  useEffect(() => {
    ;(async () => onStartUp())()
  }, [])

  const makeRequestBody = (karat: string | null, purity: string | null, gram: string | null) => {
    if (!karat) {
      swal({
        text: 'Lütfen ayarı girin.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }

    const requestBody: Options.ServiceArgs = { type: 'karat', karat }
    if (purity) requestBody.purity = parseFloat(purity)
    if (gram) requestBody.gramMultiplier = gram

    return requestBody
  }

  const combineNames = (karat: string, purity: number | null | undefined | string, gram: string | null | undefined) => {
    return `${karat}${purity ? ' / ' + purity : ''}${gram ? ' / ' + gram : ''}`
  }

  const onSubmit = async () => {
    const requestBody = makeRequestBody(karatName, purityName, gramMultiplierName)
    if (!requestBody) {
      return
    }

    setLoader(true)
    const optionService = new OptionService(requestBody)

    const result = await optionService.create()
    if (!result.success || !result.option) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    const { option } = result
    const { id, karat, purity, gramMultiplier } = option
    setList([
      ...list,
      {
        id,
        name: combineNames(karat!, purity, gramMultiplier),
      },
    ])
    setKaratName(null)
    setPurityName(null)
    setGramMultiplierName(null)
    setLoader(false)
  }

  const onDelete = async (id: number) => {
    const optionService = new OptionService({ type: 'karat' })
    const result = await optionService.delete(id)
    setLoader(true)

    if (!result) {
      setLoader(false)
      return
    }

    const listArray: ListObject[] = []
    list.forEach(item => {
      if (item.id !== id) {
        listArray.push(item)
      }
    })

    setList(listArray)
    setLoader(false)
  }

  const beforeConfirmEdit = ({ name, id }: ListObject) => {
    const splittedName = <[string, string?, string?]>name.split('/')
    setEditKaratName(splittedName[0])
    if (splittedName[1]) {
      setEditPurityName(splittedName[1])
    }
    if (splittedName[2]) {
      setEditGramMultiplierName(splittedName[2])
    }
    setItemIdToEdit(+id)
  }

  const edit = async () => {
    const requestBody = makeRequestBody(editKaratName, editPurityName, editGramMultiplierName)
    if (!requestBody) {
      return
    }

    if (!itemIdToEdit) {
      return swal({
        text: 'Düzenlenemedi. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
    }

    setLoader(true)
    const optionService = new OptionService(requestBody)
    const { success } = await optionService.edit(itemIdToEdit)
    setLoader(false)
    setEditModal(false)

    if (!success) {
      return
    }

    const listArray: ListObject[] = []

    list.forEach(item => {
      if (item.id === itemIdToEdit) {
        listArray.push({
          ...item,
          name: combineNames(editKaratName!, editPurityName, editGramMultiplierName),
        })
      } else {
        listArray.push(item)
      }
    })

    setList(listArray)
  }

  return {
    addStates: {
      karat: {
        val: karatName,
        set: setKaratName,
      },
      purity: {
        val: purityName,
        set: setPurityName,
      },
      gramMultiplier: {
        val: gramMultiplierName,
        set: setGramMultiplierName,
      },
    },
    editStates: {
      karat: {
        val: editKaratName,
        set: setEditKaratName,
      },
      purity: {
        val: editPurityName,
        set: setEditPurityName,
      },
      gramMultiplier: {
        val: editGramMultiplierName,
        set: setEditGramMultiplierName,
      },
      visibility: {
        val: editModal,
        set: setEditModal,
      },
    },
    on: {
      create: onSubmit,
      delete: onDelete,
      beforeConfirmEdit,
      edit,
    },
    list,
  }
}
