import React from 'react'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { List } from 'common/components/ui-kit/list'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Content } from 'common/components/ui-kit/content'
import * as Lib from './lib'

export const ColorCategory: React.FC = ({}) => {
  const { addStates, on, list, editStates } = Lib.H.useColorCategory()

  return (
    <>
      <PanelWrapper tab={['options', '/options/color-category']} title="Seçenek Oluşturma">
        <Sticky>
          <p className="title">Teknik Renk</p>
          <Button text="Ekle" callback={on.submit} mood="accent" className="submitButton" />
        </Sticky>

        <Content>
          <Lib.C.Contents {...addStates} onEnter={on.submit} />

          <div className="section col-lg-6 col-md-12">
            {list && (
              <List
                items={list}
                border
                onDelete={on.onDelete}
                edit={{
                  content: <Lib.C.Contents {...editStates} onEnter={on.edit} />,
                  beforeConfirm: on.beforeConfirmEdit,
                  onSubmit: on.edit,
                  overrideVisibilityState: editStates.visibility,
                }}
              />
            )}
          </div>
        </Content>
      </PanelWrapper>
    </>
  )
}
