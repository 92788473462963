import styled from 'styled-components'
import * as Lib from '.'
import { mediaQueries as mq } from 'common/constants/media-queries'

export const container = styled.div<Pick<Lib.T.TableProps, 'rowBackColor' | 'rowBorder'>>`
  float: left;
  width: 100%;
  max-width: 100%;
  border-radius: 15px;

  > .tableContainer {
    display: table;
    width: 100%;
    padding: 0 25px;
    border-collapse: separate;
    border-spacing: 0 5px;

    .tr {
      display: table-row;

      &.body {
        position: relative;

        > .td {
          background-color: white;
          cursor: pointer;
        }

        &.disabled {
          color: #afafaf;
        }

        > .td:first-child {
          background-color: #f8f8f8;
          border-color: transparent;
        }
        > .td:nth-child(2) {
          border-radius: 8px 0 0 8px !important;
        }
        > .td:last-child {
          border-radius: 0 8px 8px 0 !important;
        }

        &.filterContainer {
          height: 75px;
        }
      }
    }

    .td {
      display: table-cell;
      vertical-align: middle;
      padding: 0;
      border-bottom: ${({ rowBorder }) => (rowBorder ? '1px solid #004E421A' : 'unset')};

      &.header {
        color: #000000;
        position: sticky;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        top: 0px;
        /* padding: 20px 15px 10px 15px; */
        padding: 10px;
        font-family: var(--f-bo);
        font-size: 14px;
        z-index: 1;
        background-color: #f8f8f8;
      }

      &.footer {
        color: var(--accent);
        border-top: 2px solid #004e424d;
        position: sticky;
        padding: 10px 15px 20px 15px;
        bottom: 0px;
        font-family: var(--f-bo);
        font-size: 16px;
      }

      > .content {
        background-color: ${({ rowBackColor }) => (rowBackColor ? '#F8F8F8' : 'transparent')};
        padding: 0 10px;

        ${mq.max[992]} {
          padding: 8px;
        }

        &.true {
          padding: 0;
        }

        .tableButton {
          border: none;
          padding: 5px 15px;
          border-radius: 10px;
          background-color: transparent;
          transition: all 150ms linear;
          display: inline-block;

          &:hover {
            background-color: #eaeaea;
          }
        }
      }
    }

    .details {
      display: flex;
      width: 100%;
      height: auto;
      position: absolute;
      z-index: 100;
      border-radius: 0 10px 10px 10px;

      > .gap {
        width: 70px;
      }

      > .innerTableContainer {
        background-color: white;
        border-radius: 0 0 10px 10px;
        padding: 0 10px;
        flex: 1;
        margin-top: -11px;

        > table {
          width: 100%;
          margin: 0 0 10px 0;

          th {
            border-color: #e7e7e7;
            font-size: 10pt;
            padding: 5px 10px;
          }

          td {
            border: none;
            padding: 2px 10px;
            color: #7c7c7c;
            font-size: 10pt;
          }
        }
      }
    }

    .filter {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        width: unset;

        > .parent {
          padding: 10px 30px;
          min-height: unset;
        }
      }
    }
  }
`

export const innerTableContainer = styled.div<{ h: number }>`
  /* h: height */
  position: relative;
  z-index: 0;
  display: none;
  height: ${({ h }) => h + 'px'};

  &.show {
    display: table-row;
  }
`
