import { Modal, Popover, Radio, Spin } from 'antd'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import EmptyList from 'common/components/ui-kit/empty-list'
import { Icon } from 'common/components/ui-kit/icon'
import { imageAddresser } from 'common/helpers/image.helper'
import useMediaQuery from 'common/hooks/use-mediaQuery'
import { Managers } from 'common/routes/types/roles.type'
import { RootState } from 'common/store/root-reducer'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
// imported from product list page
import { ImageListSlider } from 'common/components/ui-kit/image-list-slider'
import { ProductDetailModal } from '../list/lib/components'
import { MonacoChainAndLockPicture } from '../list/monacoList/lib/components'
import * as Lib from './lib'
import ImageModal from 'common/components/ui-kit/imageModal'
import { Textarea } from 'common/components/ui-kit/textarea'
import { HistoryProductProblems } from 'common/services/lib/types/orders'
import InfiniteScroll from 'react-infinite-scroll-component'

const ProductError = () => {
  const { get, set, on, modal } = Lib.H.useProductErrorTable()

  const { user } = useSelector((state: RootState) => state.globals)
  const [isMonacoProduct, setIsMonacoProduct] = useState(false)
  const isLargeScreen = useMediaQuery('(max-width:1637px)')
  const [productCategory, setProductCategory] = useState('')
  const { t } = useTranslation()

  const modalProps = useMemo(() => {
    return {
      style: productCategory === 'monaco' ? { top: '30%' } : { top: '2%' },
      width: isLargeScreen ? '70%' : '50%',
    }
  }, [isLargeScreen, productCategory])

  return (
    <PanelWrapper tab={['errors', '/products/product-error']} title="Ürün Parçalar">
      <Lib.S.PageWrapper activeTab={get.tabs} style={{ padding: '10px', marginTop: '5px' }}>
        <div className="tabs">
          <div className="search">
            <div className="input-container">
              <input className="input" placeholder="Ara..." onChange={on.handleSearch} value={get.searchTerm} />
              <div className="icon">
                <Icon name="search" size={21} color="#555454" />
              </div>
            </div>
          </div>
          <div className="tab tab-one" onClick={() => on.handleChangeTabs(Lib.T.TabsEnum.CURRENT_ERRORS_TAB)}>
            <span>Çözülmemiş Hatalar</span>
          </div>
          <div className="tab tab-two" onClick={() => on.handleChangeTabs(Lib.T.TabsEnum.HISTORY_ERRORS_TAB)}>
            <span>Geçmiş Hatalar</span>
          </div>
        </div>

        <div className="table">
          {!get.products.length && !get.loader ? (
            <EmptyList text="Henüz hata bulunmamaktadır." />
          ) : (
            <InfiniteScroll
              dataLength={get.products?.length}
              next={on.handleShowMore}
              hasMore={get?.totalData! / get?.page > get?.limit}
              loader={<Spin />}
              height={'90vh'}
              ref={get.infiniteScrollRef}
              initialScrollY={0}
            >
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '13%' }}>Hatalı Ürün</th>
                    <th style={{ width: '37%' }}>Açıklama</th>
                    {get.tabs === Lib.T.TabsEnum.HISTORY_ERRORS_TAB ? <th>Hata sayısı</th> : null}
                    <th>Departman</th>
                    <th>Modelleyen</th>
                    {get.tabs === Lib.T.TabsEnum.HISTORY_ERRORS_TAB ? (
                      <>
                        <th>Oluşturulma zamanı</th>
                        <th>Onay zamanı</th>
                      </>
                    ) : null}
                    {get.tabs === Lib.T.TabsEnum.CURRENT_ERRORS_TAB ? <th>Tarıh</th> : null}

                    {Managers.includes(user.group.name) || user.group.name === 'Ar-Ge' || user.group.name === 'Monaco' ? <th></th> : null}
                  </tr>
                </thead>
                <tbody>
                  {get.products?.map((prod, index) => {
                    return (
                      <>
                        {user.group.name === 'Monaco' && prod.category !== 'monaco' ? null : (
                          <tr key={prod.id}>
                            {/* product code */}
                            <td>
                              <div className="product-code">
                                <div className="product-image">
                                  {prod.category.includes('monaco') ? (
                                    <div>
                                      <div
                                        onClick={() => {
                                          setProductCategory(prod.category)
                                          on.handleOpenImageModal(index)
                                          setIsMonacoProduct(true)
                                        }}
                                      >
                                        <MonacoChainAndLockPicture
                                          chainImagePath={prod.productSubCategoryTypeImage}
                                          lockImagePath={prod.lockImage}
                                          width={120}
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </div>

                                      <span
                                        onClick={() => {
                                          if (user.group.name !== 'Yönetim') return
                                          set.setProductId(prod.productId)
                                          modal.productModal.toggle()
                                        }}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        {prod.productCode}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="sozer-image">
                                      <img
                                        src={imageAddresser(prod.productImageThumbnail, true)}
                                        style={{ cursor: 'pointer' }}
                                        alt=""
                                        onError={e => (e.currentTarget.src = '/images/jpg/placeholder.jpg')}
                                        onClick={() => {
                                          setProductCategory(prod.category)
                                          on.handleOpenImageModal(index)
                                          setIsMonacoProduct(false)
                                        }}
                                      />
                                      <span
                                        onClick={() => {
                                          if (!Managers.includes(user.group.name)) return
                                          set.setProductId(prod.productId)

                                          modal.productModal.toggle()
                                        }}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        {prod.productCode}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                            {/* description */}
                            <td>
                              <div className="description">
                                <div className="image" style={{ display: 'flex', gap: '10px', alignItems: 'center', cursor: 'pointer' }}>
                                  {prod.image ? (
                                    prod.image?.split(',').map(img => {
                                      return (
                                        <img
                                          src={imageAddresser(img)}
                                          onError={e => (e.currentTarget.src = '/images/jpg/placeholder.jpg')}
                                          alt=""
                                          onClick={() => {
                                            set.setShowDescriptionImageModal(true)
                                            set.setImageForDescriptionModal(img)
                                            setIsMonacoProduct(false)
                                            setProductCategory('other')
                                          }}
                                        />
                                      )
                                    })
                                  ) : (
                                    <img
                                      src={imageAddresser('/images/jpg/placeholder.jpg')}
                                      onError={e => (e.currentTarget.src = '/images/jpg/placeholder.jpg')}
                                      alt=""
                                    />
                                  )}
                                </div>
                                <span>
                                  {prod.description ? (
                                    prod.description.length > 50 ? (
                                      <Popover content={prod.description}>{prod.description.substring(0, 50) + '...'}</Popover>
                                    ) : (
                                      prod.description
                                    )
                                  ) : (
                                    'Stl dosyası yok.'
                                  )}
                                </span>
                              </div>
                            </td>
                            {/* problemCount */}
                            {get.tabs === Lib.T.TabsEnum.HISTORY_ERRORS_TAB ? (
                              <td style={{ textAlign: 'center' }}>
                                <span>{prod.problemCount}</span>
                              </td>
                            ) : null}
                            {/* department */}
                            <td>
                              <span>{t(prod.department) || '-'}</span>
                            </td>
                            {/* modeler */}
                            <td>
                              <span>{prod.modeler ?? '-'}</span>
                            </td>
                            {get.tabs === Lib.T.TabsEnum.HISTORY_ERRORS_TAB ? (
                              <>
                                {/* createdAt */}
                                <td>
                                  <span>{moment(prod.createdAt).format('YYYY/MM/DD - HH:mm')}</span>
                                </td>
                                {/* approvedAt */}
                                <td>
                                  <span>{moment((prod as HistoryProductProblems).approvedAt).format('YYYY/MM/DD - HH:mm')}</span>
                                </td>
                              </>
                            ) : null}

                            {/* date */}
                            {get.tabs === Lib.T.TabsEnum.CURRENT_ERRORS_TAB ? (
                              <td>
                                <span>{moment(prod.createdAt).format('YYYY/MM/DD - HH:mm')}</span>
                              </td>
                            ) : null}

                            {/* onay btn */}
                            {get.tabs === Lib.T.TabsEnum.HISTORY_ERRORS_TAB ? (
                              <>
                                {(prod as HistoryProductProblems).approveReason ? (
                                  <td>
                                    <Icon
                                      name="edit_2_fill"
                                      color="#D32738"
                                      size={20}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        if (!(prod as HistoryProductProblems).approveReason) return
                                        set.setApprovalModalState({
                                          reason: (prod as HistoryProductProblems).approveReason,
                                          approvedAt: (prod as HistoryProductProblems).approvedAt,
                                          createdAt: prod.createdAt,
                                        })
                                        on.toggleApproveReasonModal()
                                      }}
                                    />
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                <td>
                                  {Managers.includes(user.group.name) && <Icon
                                    name="trash_bin"
                                    color="#D32738"
                                    size={20}
                                    style={{ cursor: 'pointer', marginLeft: '0.5rem' }}
                                    onClick={() => {
                                      set.setDeleteItemId(prod.id)
                                      set.setShowDeleteModal(true)
                                    }}
                                  />}
                                </td>
                              </>
                            ) : Managers.includes(user.group.name) || user.group.name === 'Ar-Ge' || user.group.name === 'Monaco' ? (
                              <td style={{ cursor: 'pointer' }}>
                                <Button
                                  callback={() => {
                                    set.setSelectedProductId(prod.id)
                                    set.setProductCode(prod.productCode)
                                    on.toggleDescriptionModal()
                                  }}
                                  text="Onayla"
                                  mood="accent"
                                />
                              </td>
                            ) : null}
                          </tr>
                        )}
                      </>
                    )
                  })}
                </tbody>
              </table>
            </InfiniteScroll>
          )}
        </div>
      </Lib.S.PageWrapper>
      {get.showImageModal ? (
        <Modal {...get.imageModalProps}>
          <ImageListSlider
            imageDetail={get.imageSliderImageDetail}
            hideCloseIcon
            baseUrl={process.env.REACT_APP_DOMAIN}
            activeIndex={get.imageActiveIndex}
          />
        </Modal>
      ) : null}

      {get.showDescriptionImageModal ? (
        <ImageModal
          showModal={get.showDescriptionImageModal}
          setShowModal={set.setShowDescriptionImageModal}
          image={get.imageForDescriptionModal}
          downloadImageLink={get.imageForDescriptionModal}
          withZoom
          isMonacoImageModal={isMonacoProduct}
          monacoImageWidth={600}
          modalProps={modalProps}
        />
      ) : null}

      {/* detail modal */}

      <ProductDetailModal productModal={modal.productModal} productId={get.productId} />

      <Modal {...get.detailsModalProps}>
        <Lib.S.ModalWrapper>
          <div className="close-btn">
            <Icon name="close_square" size={18} onClick={() => set.setShowDetailsModal(false)} style={{ cursor: 'pointer' }} />
          </div>

          <div className="body">
            <div className="image">
              <img
                src={imageAddresser(get.detailModalState?.image || '')}
                alt=""
                onError={e => (e.currentTarget.src = '/images/jpg/placeholder.jpg')}
              />
            </div>
            <div className="content">
              <div>
                <span className="title">Ürün</span>
                <span>{get.detailModalState?.productCode}</span>
              </div>
              <div>
                <span className="title">Departman</span>
                <span>{get.detailModalState?.department}</span>
              </div>
              <div>
                <span className="title">Tarıh</span>
                <span>{moment(get.detailModalState?.createdAt).format('YYYY/MM/DD')}</span>
              </div>
              <div>
                <span className="title">Modelleyen</span>
                <span>{get.detailModalState?.modeler}</span>
              </div>
              {/*  */}
              <span>{get.detailModalState?.description}</span>
            </div>
          </div>
        </Lib.S.ModalWrapper>
      </Modal>
      {/* description modal */}
      <Modal {...get.descriptionModalProps}>
        <Lib.S.ModalWrapper>
          {/*<div className="col-lg-12 d-flex justify-content-end">
          <Icon name="close_square" size={18} color="#000" style={{ cursor: 'pointer' }} onClick={on.toggleDescriptionModal} />
        </div>*/}
          <div className="col-lg-12">
            <Textarea
              label="Not"
              onInput={evt => set.setDescription(evt.currentTarget.value)}
              value={get.description}
              lessBorder
              minHeight={200}
            />
          </div>
          <div className="pt-4 d-flex col-lg-12 radios">
            <span className="me-2">Ürünün ağırlığı değişti mi?</span>
            <Radio.Group
              onChange={e =>
                set.setChangeWeights(_perv => {
                  return !!e.target.value.includes('evet')
                })
              }
              value={get.changeWeights ? 'evet' : get.changeWeights === false ? 'hayir' : ''}
            >
              <Radio value="evet">Evet</Radio>
              <Radio value="hayir">Hayir</Radio>
            </Radio.Group>
          </div>
          <div className="col-md-12 d-flex pt-4" style={{ gap: 10 }}>
            <Button mood="accent" callback={() => on.approveProductProblem(get.selectedProductId)} text="Onay" />
          </div>
        </Lib.S.ModalWrapper>
      </Modal>
      {/* approve reason modal */}
      <Modal {...get.approveReasonModalProps}>
        <div className="col-lg-12 d-flex justify-content-end">
          <Icon name="close_square" size={18} color="#000" style={{ cursor: 'pointer' }} onClick={on.toggleApproveReasonModal} />
        </div>
        <div className="col-lg-12">
          <p className="m-0" style={{ fontWeight: 'bold', fontSize: 19, color: 'var(--accent)' }}>
            Mesaj :
          </p>
          <p>{get.approvalModalState?.reason}</p>
        </div>
        <div className="col-md-12 d-flex flex-column pt-2" style={{ gap: 10 }}>
          <span>
            <span style={{ fontWeight: 'bold', color: 'var(--accent)' }}>Oluşturulma zamanı : </span>
            {moment(get.approvalModalState?.createdAt).format('YYYY/MM/DD - HH:mm')}{' '}
          </span>
          <div className="d-flex">
            <p style={{ fontWeight: 'bold', width: '137.16px', margin: 0, color: 'var(--accent)' }}>Onay zamanı :</p>
            {moment(get.approvalModalState?.approvedAt).format('YYYY/MM/DD - HH:mm')}
          </div>
        </div>
      </Modal>
      {/* Delete Modal */}
      <Modal visible={get.showDeleteModal} onCancel={() => set.setShowDeleteModal(false)} footer={null} title="Hata simle">
        <Lib.S.DeleteModalContent>
          <p>Bu hatayı kaldırmak istiyor musunuz?</p>
          <div>
            <Button text="Vazgeç" mood="accent" callback={() => set.setShowDeleteModal(false)} />
            <Button text="Sil" mood="danger" callback={on.deleteProductProblem} />
          </div>
        </Lib.S.DeleteModalContent>
      </Modal>
    </PanelWrapper>
  )
}

export default ProductError
