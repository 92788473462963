import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import { DOM } from 'common/helpers/dom'
import swal from 'sweetalert'

export class PieceService {
  accessToken: string
  endpoint = 'pieces/'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      throw 'not-acceptable'
    }
    if (!user!.accessToken) {
      throw 'not-acceptable'
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async create(args: Lib.T.Pieces.CreateOrEditArgs): Promise<Lib.T.Pieces.CreateResult> {
    const { status, data } = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    })

    if (status !== 201 || !data.piece) {
      swal({
        text: 'Parça oluşturulamadı. Lütfen tekrar deneyin.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }

    return { success: true, piece: data.piece }
  }

  async edit(args: Lib.T.Pieces.CreateOrEditArgs, id: number): Promise<boolean> {
    const { status, data } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id.toString()),
      headers: { authorization: this.accessToken },
      data: args,
    })

    if (status !== 200) {
      // toast({ message: 'Parça oluşturulamadı. Lütfen tekrar deneyin.', model: 'error' })

      return false
    }

    return true
  }

  async getList(args: Lib.T.Pieces.GetListArgs): Promise<Lib.T.Pieces.GetListResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(DOM.objectToSearch(args)),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      swal({
        text: 'Parça oluşturulamadı. Lütfen tekrar deneyin.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }

    return {
      success: true,
      pieces: {
        total: data.total,
        list: data.pieces,
      },
    }
  }

  async clone(id: number): Promise<Lib.T.Pieces.CloneResult> {
    const { status, data } = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + id + '/getVariant'),
      headers: { authorization: this.accessToken },
    })

    if (status === 201) {
      return { success: true, data: data.piece }
    }
    return { success: false }
  }

  async findOne(id: number): Promise<Lib.T.Pieces.FindOneResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + id),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200 || !data.piece) {
      swal({
        text: 'we couldnt get the piece details',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }

    return {
      success: true,
      piece: data.piece,
    }
  }

  async delete(id: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + id),
      headers: { authorization: this.accessToken },
    })

    if (status === 200) {
      return true
    }
    return false
  }
}
