import { RoutesObject } from '../types/routes.object.type'
import { appName } from 'common/constants/strings'
import { Login } from 'pages/public/login'
import { Verification } from 'pages/public/verification'

export const publicRoutes: RoutesObject = {
  login: {
    get: '/authenticate/login',
    return: <Login />,
    title: `Login to ${appName}`,
    permissions: [],
    newPermissions: []
  },
  verification: {
    get: '/authenticate/verification',
    return: <Verification />,
    title: 'Verify your login',
    permissions: [],
    newPermissions: []
  },
}
