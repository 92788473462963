import styled from 'styled-components'

export const container = styled.div<{ isOver: boolean; img: string; hasImg: boolean }>`
  border: ${({ isOver }) => (isOver ? '1px solid black' : 'none')};
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;

  > span {
    position: absolute;
    right: 10px;
    bottom: -5px;
    background: var(--accent);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid #f8f8f8;
    transition: all 150ms linear;
    transform: rotate(${({ hasImg }) => (hasImg ? '45deg' : '0')});

    svg {
      transform: rotate(45deg);
    }
  }
  > img {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    }
`
