import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import { DOM } from 'common/helpers/dom'
import swal from 'sweetalert'
import { GetAgentListResult, GetDepartmentListResult } from './lib/types/users'
import { GetListLeaveType } from './lib/types/leave'

export class LeaveService {
  accessToken: string
  endpoint = 'automation/leave'
  leaveTypeEndpoint = 'automation/leave-type'
  historyLeaveEndpoint = 'automation/leave/employee/officer/'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      LeaveService.dontAccept()
    }
    if (!user!.accessToken) {
      LeaveService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async getList(filters?: Lib.T.Leave.GetListFilters): Promise<Lib.T.Leave.GetListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response

    if (status !== 200 || !data.leaves) {
      swal({
        text: 'Üye listesine ulaşılamıyor. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false, total: 0 }
    }
    return { success: true, leaves: data.leaves, total: data.total }
  }

  async getHistoryLeaveList(id:number, filters?: Lib.T.Leave.GetListFilters): Promise<Lib.T.Leave.GetHistoryLeaveResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.historyLeaveEndpoint).concat(id.toString()).concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response

    if (status !== 200 || !data.leaves) {
      swal({
        text: 'Üye listesine ulaşılamıyor. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false, total: 0 }
    }
    return { success: true, leaves: data.leaves, total: data.total }
  }

  async getLeaveTypeList(): Promise<Lib.T.Leave.GetListLeaveType> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.leaveTypeEndpoint),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response

    if (status !== 200 || !data.leaveTypes) {
      swal({
        text: 'Üye listesine ulaşılamıyor. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false, total: 0 }
    }
    return { success: true, leaveTypes: data.leaveTypes, total: data.total }
  }

  async createOrEdit(args: Lib.T.Leave.CreateLeaveArgs, id?: number): Promise<Lib.T.Leave.CreateLeaveResult> {
    const response = await ApiCaller({
      method: id ? 'PATCH' : 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat(id ? `/${id}` : ''),
      headers: { authorization: this.accessToken },
      data: args,
    })

    const { data, status } = response
    if (id) {
      if (status !== 200) {
        LeaveService.unhandled(Array.isArray(data.message) ? data.message[0] : data.message)

        return { success: false }
      }
    } else {
      if (status !== 201) {
        LeaveService.unhandled(Array.isArray(data.message) ? data.message[0] : data.message)

        return { success: false }
      }
    }

    return {
      success: true,
    }
  }

  async getOne(id: number): Promise<Lib.T.Leave.GetOneResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200 || !data.user) {
      swal({
        text: 'Üye detaylarına ulaşılamıyor. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, user: data.user }
  }

  async getSellers(customerId: number): Promise<Lib.T.Leave.GetSellers> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/customer/' + customerId + '/findSellers'),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200 || !data.sellers) {
      swal({
        text: 'Üye detaylarına ulaşılamıyor. Lütfen tekrar deneyin.',
        icon: 'error',
      })
      return { success: false, total: 0, sellers: [] }
    }

    return { success: true, total: data.total, sellers: data.sellers }
  }
  async getKarats(customerId: number): Promise<Lib.T.Leave.GetKarats> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/customer/' + customerId + '/findKarats'),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200 || !data.karats) {
      swal({
        text: 'Ayar detaylarına ulaşılamıyor. Lütfen tekrar deneyin.',
        icon: 'error',
      })
      return { success: false, total: 0, karats: [] }
    }

    return { success: true, total: data.total, karats: data.karats }
  }

  async delete(id: number, deleteReason: string): Promise<boolean> {
    const { status, data } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id),
      headers: { authorization: this.accessToken },
      data: {deleteReason}
    })

    if (status !== 200) {
      swal({
        text: 'Bu kullanıcıyı silemezsiniz',
        icon: 'error',
        dangerMode: true,
      })
      return false
    }

    return true
  }

  async findKaratsForMultipleCustomers(
    filters?: Lib.T.Leave.findKaratsForMultipleCustomersArgType,
  ): Promise<Lib.T.Leave.findKaratsForMultipleCustomersResponse> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env
        .REACT_APP_SERVER_URL!.concat(this.endpoint)
        .concat('/customer/findKaratsForMultipleCustomers')
        .concat(filters ? DOM.objectToSearch(filters) : ''),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response

    if (status !== 200 || !data.karats) {
      swal({
        text: 'Üye listesine ulaşılamıyor. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false, total: 0 }
    }
    return { success: true, karats: data.karats, total: data.total }
  }

  static dontAccept() {
    throw 'not-acceptable'
  }

  static unhandled(errorMessage?: string) {
    if (errorMessage) {
      return swal({
        text: errorMessage,
        dangerMode: true,
        icon: 'error',
      })
    }
  }
}
