import styled from 'styled-components'

export const container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;

  > span {
    width: 16px;
    max-width: 16px;
    display: inline-flex;
    height: 16px;
    border-radius: 50%;
    border: 1px solid var(--accent);
    align-items: center;
    justify-content: center;

    > span {
      float: left;
      width: 0;
      height: 0;
      background: var(--accent);
      border-radius: 50%;
      transition: all 150ms linear;
    }

    &.true > span {
      width: 10px;
      height: 10px;
    }
  }

  > p {
    margin: 0;
    flex: 1;
    padding: 0 8px;
    font-size: 13px;
  }
`
