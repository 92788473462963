import { FC } from 'react'
import * as Lib from '.'
import { Link, useHistory } from 'react-router-dom'
import { Icon } from 'common/components/ui-kit/icon'

export const Card: FC<Lib.T.SubPieceCardProps> = ({ path, title, icon, disabled }) => {
  const history = useHistory()

  return (
    <Lib.S.cardContainer disabled={disabled}>
      <div
        className="wrapper"
        onClick={() => (disabled === true ? null : history.push(path))}
        style={disabled ? { cursor: 'default' } : { cursor: 'pointer' }}
      >
        {icon && <Icon name={icon} size={20} color={'#000'} />}
        <Link to={disabled === true ? '#' : path}>{title}</Link>
      </div>
    </Lib.S.cardContainer>
  )
}
