import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Crypto } from 'common/helpers/crypto.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import swal from 'sweetalert'
import * as Lib from './lib'
import { bucketName } from './lib/types/medias'

export class MediaService {
  accessToken: string

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      throw ''
    }
    if (!user!.accessToken) {
      throw ''
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }
  async upload(file: File, bucketName: bucketName, endPoint?: string): Promise<Lib.T.Medias.UploadReturnType> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('bucketName', bucketName)

    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(endPoint ? endPoint : 'minio/upload'),
      data: formData,
      headers: { authorization: this.accessToken },
      customConfigs: { hidePopup: true },
    })

    const { status, data } = response
    if (status !== 201 || !data) {
      swal({
        text: 'Resm yüklenemedi.',
        dangerMode: true,
        icon: 'error',
      })
      return { thumbnail: '', name: '', imageLink: '', success: false }
    }

    const responseData: Lib.T.Medias.UploadResponse = response.data
    const { media } = responseData
    if (!media || !media.thumbnail || !media.file_name || !media.link) {
      swal({
        text: 'Resm yüklenemedi.',
        dangerMode: true,
        icon: 'error',
      })
      return { thumbnail: '', name: '', imageLink: '', success: false }
    }

    return {
      thumbnail: media.thumbnail,
      success: true,
      name: media.file_name,
      imageLink: media.link,
    }
  }

  async download(imageName: string): Promise<{ success: boolean; data: any }> {
    try {
      const response = await ApiCaller({
        headers: { authorization: this.accessToken },
        method: 'GET',
        url: imageName,
        responseType: 'blob',
        customConfigs: { hidePopup: true },
      })

      return { success: true, data: response.data }
    } catch (e) {
      return { success: false, data: null }
    }
  }
}
