import { FC } from 'react'
import * as Lib from './lib'

export const Gap: FC<Lib.T.GapProps> = ({ children, mood, size, className, style }): JSX.Element => {
  return (
    <>
      <Lib.S.gapContainer mood={mood} size={size} className={className} style={style}>
        {children && children}
      </Lib.S.gapContainer>
    </>
  )
}
