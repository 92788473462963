import { getQueryParam } from 'common/helpers/getQueryParam'
import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { ProductService } from 'common/services'
import { FullProduct } from 'common/services/lib/types/product'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert'

export const useDetails = () => {
  const [productDetail, setProductDetail] = useState<FullProduct>()
  const [loading, setLoading] = useState(true)
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const history = useHistory()

  const { setLoader } = useLoader()

  const DetailModalVisibleHandler = () => {
    setIsDetailModalVisible(perval => !perval)
  }

  const handleCancelDetailModal = () => {
    setIsDetailModalVisible(false)
  }

  const ConfirmModalVisibleHandler = () => {
    setIsConfirmModalVisible(perval => !perval)
  }

  const handleCancelConfirmModal = () => {
    setIsConfirmModalVisible(false)
  }

  const handleCopyProduct = async (id: number, productCode: string) => {
    const service = new ProductService()
    const copyProduct = await service.copy(id, productCode)
  }

  const handleDeleteProduct = async (id: number) => {
    const service = new ProductService()
    const isProductDeleted = await service.delete(id)

    if (isProductDeleted) {
      swal({
        text: 'Product Deleted Succesfully',
        icon: 'success',
        timer: 2000,
        buttons: [false],
      }).then(() => {
        history.push('/products/list')
      })
    } else {
      swal({
        text: 'Something Went Wrong',
        icon: 'error',
      })
    }
  }

  const fetchProductDetail = async () => {
    const service = new ProductService()
    setLoader(true)
    await service
      .findOne(getQueryParam())
      .then(res => setProductDetail(res.data!))
      .catch(() =>
        swal({
          text: 'Something Went Wrong',
          icon: 'error',
        }),
      )
      .finally(() => {
        setLoader(false)
        setLoading(false)
      })
  }

  useAsyncEffect(fetchProductDetail, [])

  return {
    get: {
      productDetail,
      loading,
      DetailModalVisibleHandler,
      handleCancelDetailModal,
      ConfirmModalVisibleHandler,
      handleCancelConfirmModal,
      isDetailModalVisible,
      isConfirmModalVisible,
      handleDeleteProduct,
      handleCopyProduct,
      setProductDetail,
    },
  }
}
