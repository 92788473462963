import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import swal from 'sweetalert'

export class AutomationDepartmentService {
  accessToken: string
  endpoint = 'automation/department'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      AutomationDepartmentService.dontAccept()
    }
    if (!user!.accessToken) {
      AutomationDepartmentService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async getList(): Promise<Lib.T.AutomationDepartments.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || !data.departments) {
      swal({
        text: 'Departman listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false, total: 0 }
    }
    return { success: true, total: data.total, data: data.departments }
  }

  async create(args: Lib.T.AutomationDepartments.DepartmentArgs): Promise<Lib.T.AutomationDepartments.ListResult> {
    const { status, data } = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    })
    if (status !== 201) {
      AutomationDepartmentService.unhandled(Array.isArray(data.message) ? data.message[0] : data.message)

      return { success: false }
    }

    return { success: true, total: 0 }
  }

  async edit(args: Lib.T.AutomationDepartments.DepartmentArgs, id: number): Promise<Lib.T.AutomationDepartments.ListResult> {
    const { status, data } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    })
    if (status !== 200) {
      AutomationDepartmentService.unhandled(Array.isArray(data.message) ? data.message[0] : data.message)

      return { success: false }
    }

    return { success: true, total: 0 }
  }

  async delete(id: number): Promise<Lib.T.AutomationDepartments.ListResult> {
    const { status, data } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      AutomationDepartmentService.unhandled(Array.isArray(data.message) ? data.message[0] : data.message)

      return { success: false }
    }

    return { success: true, total: 0 }
  }

  async findOne(id: number): Promise<any> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint).concat('/' + id),
      headers: { authorization: this.accessToken },
    })

    if (status === 200) {
      return {
        success: true,
        department: data,
      }
    }
    return {
      success: false,
    }
  }

  static dontAccept() {
    throw 'not-acceptable'
  }

  static unhandled(errorMessage?: string) {
    if (errorMessage) {
      return swal({
        text: errorMessage,
        dangerMode: true,
        icon: 'error',
      })
    }
  }
}
