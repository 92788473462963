import styled from 'styled-components'
import { mediaQueries as mq } from 'common/constants/media-queries'

export const PageWrapper = styled.div`
  padding: 30px 10px 10px;
`

export const ColumnWrapper = styled.div`
  p {
    margin: 0;
  }
  border-radius: 10px;
  max-width: 105px;
  min-width: 105px;
  height: calc(100vh - 30px);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-moz-scrollbar {
    width: 3px;
  }

  > p {
    background-color: var(--accent);
    color: white;
    text-align: center;
    display: block;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 13px;
  }
`
export const MembersContainer = styled.div`
  background-color: #dddddd;
  font-size: 15px;
  border-radius: 10px;
  margin-bottom: 2px;
  p {
    margin: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  text-align: center;
  p {
    display: block;
    font-size: 15px;
  }
  .icons {
    display: flex;
    justify-content: space-around;
  }
`

export const ModalContainer = styled.div`
 div > .ant-select-selector{
     border-width: 1px !important;
  }
  
  .ant-switch-handle::before{
    background-color: var(--accent);
  }
  .isManager{
    height: 43px;
    margin-top: 37px;
    padding: 0 10px;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
  }
  
  .isLogin{
    height: 43px;
    margin-top: 38px;
    padding: 0 10px;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
  }
  .modalTitle {
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    background: var(--accent);
    color:#fff;
    border-radius: 10px 10px 0 0;
    padding: 5px 10px;
    
    div {
      display: flex;
    }
    span {
      font-weight: 600;
      font-size: 18px;
    }
    svg {
      margin-top: 5px;
      padding: 2px;
    }
  }

  .uploader {
    div {
      margin-top: 5px;
    }
  }

  .btn {
    display: flex;
    margin: 10px auto 0 auto;
  }
`

export const ConfirmModalContainer = styled.div`
  padding: 40px;

  p {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }
  .btns {
    display: flex;
  }
`
export const DeleteModalContent = styled.div`
  float: left;
  width: 100%;
  height: auto;

  > p {
    margin: 0;
    float: left;
    width: 100%;
    font-size: 18px;
    text-align: center;
  }

  > div {
    float: left;
    width: 100%;
    text-align: center;
    margin: 30px 0 0 0;

    > div {
      width: 110px !important;
      float: none !important;
      display: inline-flex !important;
      padding: 0 2px;

      > div {
        min-height: 35px !important;
      }
    }
  }
`

export const tableButton = styled.span`
  display: block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.effect {
    svg {
      transition: all 150ms linear;
    }

    &.rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &:hover {
    background: #f8f8f8;
  }
`

export const tableButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const tableStatus = styled.div<{ type?: string }>`
    display: flex;
    justify-content: center;
    border: ${({ type }) => (type === 'deleted' ? '1px solid red' : '1px solid #00A42E')};
    width: 85px;
    border-radius: 8px;
    background: ${({ type }) => (type === 'deleted' ? 'rgb(164, 0, 0,0.1)' : 'rgba(0, 164, 46, 0.1)')};
    color: ${({ type }) => (type === 'deleted' ? 'red' : '#00A42E')};
    text-transform: capitalize;
`

export const tableImage = styled.div`
  width: 40px;
  height: 40px;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  ${mq.max[992]} {
    width: 60px;
    height: 60px;
  }
`

export const userImage = styled.div`
  width: 120px;
  height: 120px;
  margin: -70px auto 20px;
 
  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid var(--accent);
    object-fit: cover;
  }

  ${mq.max[992]} {
    width: 60px;
    height: 60px;
  }
`

export const content = styled.div`
  flex: 1;
  padding: 0;

  ${mq.max[768]} {
    width: 100%;
    float: right;
    padding: 0;
    margin-top: 10px;
  }
  .tableContainer{
    border-spacing: 0;
  }
  > .tableContainer {
    float: left;
    width: 100%;
    margin: 10px 0 0 0;
    border-radius: 15px;
    padding: 0;
    
    .tr.body:nth-child(odd){
      background-color: #E5EDEC;
    }
    
    .tr.body > .td:nth-child(2){
        border-radius: 0 !important;  
     }
     .tr.body > .td:last-child{
        border-radius: 0 !important;  
     }
    
    .td.header{
      border-bottom: 2px solid rgba(204, 204, 204, 1)
    }
    
    .body {
      > .td {
      background-color: transparent !important;
      padding: 5px 0;
      cursor: auto !important;
      }
    }

    .tableContainer {
      padding: 0 0px;

      .filter {
        border: none;
        background: #f8f8f8 !important;
      }
    }

    > p {
      margin: 0;
      padding: 5px 0 15px 25px;
      font-size: 19px;
      color: #252733;
      font-family: var(--f-se-bo);
    }
  }
`

export const searchSection = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  gap: 10px;
  
  div > .ant-select-selector{
     border-width: 1px !important;
  }
  
  > p {
    margin: 0 20px 0 0;
    font-size: 26px;
    font-family: var(--f-bo);
  }

  > div {
    flex: 1;
    display: flex;
    align-items: center;

    > .inputContainer {
      flex: 1;
      position: relative;

      > .filterButton {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 45px;
        right: 0;
        display: none;
        align-items: center;
        justify-content: center;

        ${mq.max[768]} {
          display: flex;
        }
      }
    }

    > .buttonContainer {
      width: unset;
      margin: 0 0 0 5px;

      > .parent {
        padding: 0;
        min-height: unset;
        width: 45px;
        height: 45px;
      }
    }
  }
`

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  direction: ltr;

  .radio-wrapper {
    display: flex;
    justify-content: space-between;
    border: 1px #d9d9d9 solid;
    border-radius: 7px;
    padding: 5px;
    
    .offType{
      span{
      border: 2px solid rgb(0, 78, 66);
        span{
          width: 9px;
          height: 9px;
        }
      }
    }
    
    > div {
      margin-right: 10px;
      > span {
        max-width: 19px;
        width: 19px;
        height: 19px;
        border: 2px #EEEEEE solid;
        background: #fff;
      }
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .ant-checkbox-group-item {
    margin-right: 8px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #004e42;
    border-color: #004e42;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #004e42 !important;
  }

  /* .ant-checkbox + span {
    padding-right: 4px;
    padding-left: 4px;
  } */

  .ant-checkbox-wrapper::after {
    display: none;
  }

  > div {
    &:not(:last-child) {
      border-bottom: 1px solid #ededed;
    }

    > p {
      font-size: 16px;
      color: #4d4d4d99;
    }

    > span {
      border-color: #4d4d4d99;
    }
  }

  > div > label {
    &:not(:last-child) {
      border-bottom: 1px solid #ededed;
    }

    > p {
      font-size: 16px;
      color: #4d4d4d99;
    }

    > span {
      border-color: #4d4d4d99;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      direction: ltr;
      max-width: 126px;
    }
    .more {
      text-align: center;
    }
  }
`