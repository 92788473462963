import styled from 'styled-components'
import * as Lib from '.'

export const container = styled.div<Pick<Lib.T.BreakerProps, 'note' | 'noBorder' | 'textBack'>>`
  float: left;
  width: 100%;
  height: 1px;
  background: ${({ noBorder }) => (noBorder ? 'transparent' : '#9c9c9c6e')};
  margin: 30px 0;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    ${({ note }) => (note ? `content: attr(data-content)` : '')};
    height: 30px;
    padding: 0 15px;
    text-align: center;
    background: ${({ textBack }) => textBack ?? '#eaeaea'};
  }
`
