import { useEffect, useState } from 'react'
import { OptionService } from 'common/services'
import * as Lib from '.'
import { UNHANDLED } from 'common/constants/errors'
import { ListObject } from 'common/components/ui-kit/list/lib/typing'
import { useDispatch } from 'react-redux'
import { Global } from 'common/store/actions'
import swal from 'sweetalert'

export const useSize = () => {
  const [sizeNumber, setSizeNumber] = useState<string | null>(null)

  const [editSizeNumber, setEditSizeNumber] = useState<string | null>(null)
  const [itemIdToEdit, setItemIdToEdit] = useState<number | null>(null)
  const [editModal, setEditModal] = useState<boolean>(false)

  const [list, setList] = useState<ListObject[]>([])
  const dispatch = useDispatch()
  const setLoader = (payload: boolean) => dispatch(Global.fsLoader(payload))

  const onStartUp = async () => {
    setLoader(true)
    const optionService = new OptionService({ type: 'necklace-size' })
    const result = await optionService.read()

    if (!result.success || !result.options) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    const options = <Lib.T.List[]>result.options
    const listArray: ListObject[] = []
    options.forEach(item => listArray.push({ id: item.id, name: item.size + '' }))
    setList(listArray)
    setLoader(false)
  }

  useEffect(() => {
    ;(async () => onStartUp())()
  }, [])

  const onSubmit = async () => {
    if (!sizeNumber) {
      swal({
        text: 'Lütfen ölçüyü girin.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }
    setLoader(true)

    const optionService = new OptionService({
      type: 'necklace-size',
      size: parseFloat(sizeNumber),
    })

    const result = await optionService.create()
    if (!result.success || !result.option) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    const { option } = result
    const { id, size } = option
    if (!id || !size) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    setList([...list, { id, name: size + '' }])
    setSizeNumber(null)
    setLoader(false)
  }

  const onDelete = async (id: number) => {
    const optionService = new OptionService({ type: 'necklace-size' })
    const result = await optionService.delete(id)
    setLoader(true)

    if (!result) {
      setLoader(false)
      return
    }

    const listArray: ListObject[] = []
    list.forEach(item => {
      if (item.id !== id) {
        listArray.push(item)
      }
    })

    setList(listArray)
    setLoader(false)
  }

  const beforeConfirmEdit = (item: ListObject) => {
    setEditSizeNumber(item.name)
    setItemIdToEdit(+item.id)
  }

  const edit = async () => {
    if (!editSizeNumber) {
      swal({
        text: 'Lütfen ölçüyü girin.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }
    if (!itemIdToEdit) {
      return
    }
    setLoader(true)
    setEditModal(false)

    const optionService = new OptionService({
      type: 'necklace-size',
      size: parseFloat(editSizeNumber),
    })

    const { success } = await optionService.edit(itemIdToEdit)
    setLoader(false)

    if (!success) {
      return
    }

    const listArray: ListObject[] = []

    list.forEach(item => {
      if (item.id === itemIdToEdit) {
        listArray.push({ ...item, name: editSizeNumber })
      } else {
        listArray.push(item)
      }
    })

    setList(listArray)
  }

  return {
    addStates: {
      size: {
        val: sizeNumber,
        set: setSizeNumber,
      },
    },
    editStates: {
      size: {
        val: editSizeNumber,
        set: setEditSizeNumber,
      },
      visibility: {
        val: editModal,
        set: setEditModal,
      },
    },
    on: {
      create: onSubmit,
      delete: onDelete,
      beforeConfirmEdit,
      edit,
    },
    list,
  }
}
