import { FC } from 'react'
import * as Lib from './lib'

const EmptyList: FC<Lib.T.IEmptyListProps> = ({ text }) => {
  return (
    <Lib.S.Content>
      <svg width="290" height="224" viewBox="0 0 390 324" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M107.743 19.4081C107.743 19.4081 61.1036 -15.8985 22.3101 8.72892C-16.4835 33.3563 7.05418 83.9187 7.27212 86.0981C7.49006 88.2775 16.6436 136.443 7.05418 156.493C-2.53523 176.544 37.7839 273.528 114.935 264.592C114.935 264.592 151.985 294.45 188.163 266.336C224.56 238.003 250.277 220.35 274.686 244.106C299.096 267.861 364.914 234.952 381.477 190.056C398.041 145.16 388.451 122.712 347.914 101.572C347.914 101.572 315.223 91.9825 300.185 92.2005C285.147 92.4184 268.584 90.021 249.623 65.3937C230.444 40.7663 107.743 19.4081 107.743 19.4081Z"
          fill="#E5EDEC"
        />
        <path
          d="M284.927 318.861H77.2285V40.5496C77.2285 33.3576 83.1129 27.4731 90.305 27.4731H271.85C279.042 27.4731 284.927 33.3576 284.927 40.5496V318.861Z"
          fill="url(#paint0_linear_9455_122)"
        />
        <path d="M262.701 36.8438H102.732V264.81H262.701V36.8438Z" fill="white" />
        <path
          d="M128.23 81.7417H237.419"
          stroke="#A5A5A5"
          strokeWidth="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M128.23 128.597H237.419"
          stroke="#A5A5A5"
          strokeWidth="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M128.23 175.456H237.419"
          stroke="#A5A5A5"
          strokeWidth="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M313.473 323H153.504V121.622C153.504 108.328 164.401 97.4307 177.695 97.4307H290.153L313.473 125.981V323Z"
          fill="url(#paint1_linear_9455_122)"
          stroke="black"
          strokeWidth="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M235.459 253.914C259.652 253.914 279.265 234.301 279.265 210.107C279.265 185.914 259.652 166.301 235.459 166.301C211.265 166.301 191.652 185.914 191.652 210.107C191.652 234.301 211.265 253.914 235.459 253.914Z"
          fill="#004E42"
        />
        <path
          d="M232.836 14.3963H194.697C194.697 7.20424 188.812 1.31982 181.62 1.31982C174.428 1.31982 168.544 7.20424 168.544 14.3963H131.93C122.776 14.3963 115.584 21.8063 115.584 30.7419V40.5493H249.182V30.7419C249.182 21.8063 241.772 14.3963 232.836 14.3963Z"
          fill="url(#paint2_linear_9455_122)"
          stroke="black"
          strokeWidth="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M290.16 97.4307V125.763H313.48L290.16 97.4307Z"
          fill="url(#paint3_linear_9455_122)"
          stroke="black"
          strokeWidth="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M216.492 226.233L252.453 194.196"
          stroke="white"
          strokeWidth="4.0714"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M252.453 226.233L216.492 194.196"
          stroke="white"
          strokeWidth="4.0714"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient id="paint0_linear_9455_122" x1="40.2138" y1="174.575" x2="149.89" y2="173.55" gradientUnits="userSpaceOnUse">
            <stop offset="0.3207" stop-color="#01352B" />
            <stop offset="0.9166" stop-color="#004E42" />
          </linearGradient>
          <linearGradient id="paint1_linear_9455_122" x1="369.032" y1="210.163" x2="217.242" y2="210.163" gradientUnits="userSpaceOnUse">
            <stop offset="0.3151" stop-color="#B1975F" />
            <stop offset="0.6341" stop-color="#F2DEB6" />
            <stop offset="1" stop-color="#B1975F" />
          </linearGradient>
          <linearGradient id="paint2_linear_9455_122" x1="183.126" y1="58.4649" x2="181.065" y2="3.86167" gradientUnits="userSpaceOnUse">
            <stop offset="0.3151" stop-color="#B1975F" />
            <stop offset="0.6341" stop-color="#F2DEB6" />
            <stop offset="1" stop-color="#B1975F" />
          </linearGradient>
          <linearGradient id="paint3_linear_9455_122" x1="290.067" y1="111.609" x2="313.416" y2="111.609" gradientUnits="userSpaceOnUse">
            <stop offset="0.3151" stop-color="#B1975F" />
            <stop offset="0.6341" stop-color="#F2DEB6" />
            <stop offset="1" stop-color="#B1975F" />
          </linearGradient>
        </defs>
      </svg>
      <p className="text-center">{text ? text : 'Henüz siparişiniz bulunmamaktadır.'}</p>
    </Lib.S.Content>
  )
}

export default EmptyList
