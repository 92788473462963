import { FC } from 'react'
import * as Lib from './lib'

export const Content: FC<Lib.T.ContentProps> = ({
  children,
  padding,
  withContainer = true,
  className,
  row = false,
  style,
}): JSX.Element => {
  return (
    <>
      <Lib.S.contentContainer padding={padding} className={className} style={style}>
        <div className={`${withContainer ? 'container ' : undefined} `} id="panelWrapper">
          {children && children}
        </div>
      </Lib.S.contentContainer>
    </>
  )
}
