import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import { DOM } from 'common/helpers/dom'
import swal from 'sweetalert'

export class LocksService {
  accessToken: string
  endPoint = 'locks'
  subEndPoint = 'sub-locks'
  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      LocksService.dontAccept()
    }
    if (!user!.accessToken) {
      LocksService.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async create({
    title,
    image,
    categories,
    projectDepartment,
    rosinDepartment,
  }: Lib.T.Locks.CreateArgs): Promise<Lib.T.Locks.CreateResult> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint),
      headers: { authorization: this.accessToken },
      data: {
        title,
        image,
        categories,
        projectDepartment,
        rosinDepartment,
      },
    })

    const { status, data } = response
    if (status !== 201 || !data.lock) {
      swal({
        title: 'Alt locks oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, data: data.lock }
  }

  async getList(args?: Lib.T.Locks.GetListArgs): Promise<Lib.T.Locks.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint).concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response
    if (status !== 200 || !data.locks) {
      swal({
        title: 'Alt locks oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, data: data.locks }
  }

  async delete(id: number, subLock?: boolean): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(subLock ? `${this.subEndPoint}/${id}` : `${this.endPoint}/${id}`),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      swal({
        title: 'Alt locks silinemedi. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
    }

    return status === 200
  }

  async edit(id: number, args: Lib.T.Locks.EditArgs): Promise<Lib.T.Locks.EditResult> {
    const { status, data } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endPoint}/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    })

    if (status !== 200 || !data.lock) {
      swal({
        text: 'Alt locks düzenlenemedi. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })

      return { success: false }
    }

    return { success: true, data: data.lock }
  }
  async editSubLock(id: number, args: Lib.T.Locks.EditSubLockArgs): Promise<Lib.T.Locks.EditSubLockResult> {
    const { status, data } = await ApiCaller({
      method: 'PUT',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.subEndPoint}/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    })

    if (status !== 200 || !data.subLock) {
      swal({
        text: 'Alt locks düzenlenemedi. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })

      return { success: false }
    }

    return { success: true, data: data.subLock }
  }
  // create sublock
  async createSubLock(args: Lib.T.Locks.CreateSubLockArgs): Promise<Lib.T.Locks.CreateSubLockResponse> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subEndPoint),
      headers: { authorization: this.accessToken },
      data: {
        ...args,
      },
    })

    const { status, data } = response
    if (status !== 201 || !data.subLock) {
      swal({
        title: 'Alt locks oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, data: data.subLock }
  }
  async getSubLocks(args?: Lib.T.Locks.GetListArgs): Promise<Lib.T.Locks.GetSubLocks> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.subEndPoint).concat(args ? DOM.objectToSearch(args) : ''),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response
    if (status !== 200 || !data.subLocks) {
      swal({
        title: 'Alt locks oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, data: data.subLocks, total: data.total }
  }
  async findOneSubLock(id: number): Promise<Lib.T.Locks.FindOneResult> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.subEndPoint}/${id}`),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200 || !data.subLock) {
      swal({
        text: 'we couldnt get the piece details',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }

    return {
      success: true,
      subLock: data.subLock,
    }
  }
  static dontAccept() {
    throw 'not-acceptable'
  }
}
