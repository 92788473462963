import { FC } from 'react'
import * as Lib from './lib'
import { Link } from 'react-router-dom'
import { Icon } from '../icon'

export const Button: FC<Lib.T.ButtonProps> = ({
  children,
  callback,
  href,
  icon,
  text,
  iconProps,
  className,
  id,
  parentStyles,
  containerStyles,
  iconStyles,
  textStyles,
  mood,
  onClick,
  disabled,
  onKeyPress,
  onMouseDown,
  onKeyDown,
  onMouseUp,
}): JSX.Element => {
  return (
    <>
      <Lib.S.buttonContainer
        className={className}
        id={id}
        style={{ ...parentStyles, pointerEvents: disabled ? 'none' : 'initial' }}
        mood={mood}
        onClick={onClick}
        onKeyPress={onKeyPress}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onKeyDown={onKeyDown}
      >
        <Container href={href} callback={callback} containerStyles={containerStyles}>
          {icon ? <Icon name={icon} {...iconProps} size={iconProps?.size || 15} /> : null}

          {text && (
            <span className="text" style={textStyles}>
              {text}
            </span>
          )}

          {children && children}
        </Container>
      </Lib.S.buttonContainer>
    </>
  )
}

const Container: FC<Pick<Lib.T.ButtonProps, 'href' | 'callback' | 'containerStyles'>> = ({
  children,
  href,
  callback,
  containerStyles,
}): JSX.Element => {
  if (href) {
    return (
      <>
        <Link to={href} className="parent" style={containerStyles}>
          {children && children}
        </Link>
      </>
    )
  } else if (callback) {
    return (
      <>
        <div className="parent" onClick={() => callback()} style={containerStyles}>
          {children && children}
        </div>
      </>
    )
  } else return <>{children && children}</>
}
