import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { NewsAndAnnouncements, MediaService } from 'common/services'
import { newsAndAnnouncementsArgs } from 'common/services/lib/types/newsAndAnnouncements'
import { useCallback, useEffect, useState } from 'react'
import swal from 'sweetalert'
import * as Lib from '.'
import { Switch } from 'antd'
import moment from 'moment'
import { imageAddresser } from 'common/helpers/image.helper'

const modalInitial: Lib.T.modalProps = {
  kind: '',
  id: 0,
  editProps: {
    announcements: [
      {
        id: 0,
        type: '',
        title: '',
        description: '',
        active: false,
        deleted: false,
        image: null,
        writer: {
          id: 0,
          firstName: '',
          lastName: '',
        },
        createdAt: '',
        updatedAt: '',
      },
    ],
  },
}

export const useAnnouncements = () => {
  const { setLoader } = useLoader()

  const tableIdIndex = 8

  const [paginate, setPaginate] = useState({ limit: 30, page: 1 })
  const [totalData, setTotalData] = useState<number | undefined>()
  const [fetchAgain, setFetchAgain] = useState(false)
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const tableColumns = ['', 'Başlık', 'Açıklama', 'Tarih', '']
  const tableSizes = ['3%', 'unset', 'unset', 'unset', '7%']
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalProps, setModalProps] = useState<Lib.T.modalProps>(modalInitial)
  const [paginateLoader, setPaginateLoader] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [clearPreview, setClearPreview] = useState(false)
  const [image, setImage] = useState<File | null>(null)
  const [defaultImage, setDefaultImage] = useState<string>('')

  const getNews = async () => {
    const service = new NewsAndAnnouncements()
    setLoader(true)
    const { success, totalData, announcements } = await service.getAnnouncementsAndNews({
      limit: paginate.limit,
      type: 'announcement-with-image',
      isDeleted: false,
    })
    setLoader(false)
    if (success && announcements) {
      setTotalData(parseInt(totalData! + ''))
      setTableData(prevData => [
        ...announcements.map(announcement => {
          return [
            <Lib.C.TableImage
              src={announcement.image ? imageAddresser(announcement.image.imageThumbnail, true) : '/images/jpg/placeholder.jpg'}
            />,
            <div>{announcement.title.length > 25 ? `${announcement.title.substring(0, 25)} ...` : announcement.title}</div>,
            <div>
              {announcement.description.length > 60 ? `${announcement.description.substring(0, 60)} ...` : announcement.description}
            </div>,
            <div>{moment(announcement?.createdAt).format('DD/MM/YYYY')}</div>,
            <Lib.S.tableButtons>
              <Switch
                defaultChecked={announcement.active}
                size="small"
                className="me-1"
                onChange={() => handleSwitchBtn(announcement.id, announcement.active)}
              />
              <Lib.C.TableButton icon="edit_outline" callback={() => getOneAnnouncements(announcement.id)} />
              <Lib.C.TableButton
                icon="trash_bin"
                callback={() => {
                  setShowDeleteModal(true)
                  setModalProps({ ...modalInitial, kind: 'delete', id: announcement.id })
                }}
              />
            </Lib.S.tableButtons>,
          ]
        }),
      ])
    }
  }

  const handleSwitchBtn = async (id: number, active: boolean) => {
    const service = new NewsAndAnnouncements()
    setLoader(true)
    const { success } = await service.putAnnouncementsAndNews({ active: !active }, id)
    setLoader(false)
  }

  const getOneAnnouncements = async (id: number) => {
    const service = new NewsAndAnnouncements()
    setLoader(true)
    const { success, announcementsItem } = await service.getOneAnnouncementAndNews(id)
    setLoader(false)
    if (success && announcementsItem) {
      setShowModal(true)
      setModalProps({
        ...modalInitial,
        kind: 'edit',
        id: announcementsItem?.id,
        editProps: {
          announcements: [
            {
              image: announcementsItem?.image,
              title: announcementsItem?.title,
              description: announcementsItem?.description,
              active: announcementsItem?.active,
              createdAt: announcementsItem?.createdAt,
            },
          ],
        },
      })
    }
  }

  const DeleteAnnouncement = useCallback(
    async (id: number) => {
      const service = new NewsAndAnnouncements()
      setShowDeleteModal(false)
      setLoader(true)
      try {
        const success = await service.delete(id)

        if (success) {
          swal({ className: 'rounded-swal', icon: 'success', timer: 700, buttons: [false] }).then(() => {
            setShowDeleteModal(false)
          })
          setFetchAgain(prev => !prev)
        }
      } catch (error) {
        console.error('Failed to edit/create neighbourhood: ', error)
      } finally {
        setLoader(false)
      }
    },
    [modalProps],
  )

  const validateArgs = (item: Lib.T.announcements): boolean => {
    const hasEmptyFields = !item.title.trim() || !item.description.trim()

    if (hasEmptyFields) {
      swal({
        text: 'Lütfen tüm alanları doldurun.',
        icon: 'error',
      })
      setIsEmpty(true)
      return false
    }

    setIsEmpty(false)
    return true
  }

  const handleSwal = (success: boolean, onSuccess: () => void) => {
    if (success) {
      swal({
        className: 'rounded-swal',
        icon: 'success',
        timer: 700,
        buttons: [false],
      }).then(() => {
        onSuccess()
      })
      setLoader(false)
      handleModal()
    }
  }

  const upload = async (image: File): Promise<string> => {
    const endPoint = 'automation/minio/upload'
    if (!image) {
      return swal({
        text: 'please select an image',
        icon: 'error',
      })
    }
    const filesize = parseFloat((image.size / 1024 / 1024).toFixed(1))

    if (filesize > 2.5) {
      return swal({
        text: 'file size is more thn 2.5 megabyte',
        icon: 'error',
      })
    }
    const mediaService = new MediaService()
    const result = await mediaService.upload(image, 'announcement', endPoint)
    const { name, success } = result
    if (!success || !name) {
      return swal({
        text: 'something went wrong',
        icon: 'error',
      })
    }
    return name
  }

  const handleAnnouncementsOperation = async (operation: 'create' | 'edit', id?: number) => {
    const service = new NewsAndAnnouncements()
    let uploadedImage: string = ''

    if (image) {
      try {
        setLoader(true)
        uploadedImage = await upload(image)
      } catch (e) {
        return
      } finally {
        setLoader(false)
      }
    }

    setLoader(true)

    const newsData = {
      title: title,
      description: description,
    }

    if (!validateArgs(newsData)) {
      setLoader(false)
      return
    }

    setLoader(true)

    const args: newsAndAnnouncementsArgs = {
      type: 'announcement-with-image',
      title: title,
      description: description,
      active: isActive,
      image: uploadedImage,
    }

    let success = false

    if (operation === 'create') {
      success = await service.create(args)
    } else if (operation === 'edit' && id !== undefined) {
      const response = await service.putAnnouncementsAndNews(args, id)
      success = response.success
    }

    handleSwal(success, () => {
      setTitle('')
      setDescription('')
      setIsActive(false)
      setShowModal(false)
      setDefaultImage('')
    })

    setFetchAgain(prev => !prev)
    setLoader(false)
  }

  const CreateAnnouncement = async () => {
    await handleAnnouncementsOperation('create')
  }

  const EditAnnouncement = async (id: number) => {
    await handleAnnouncementsOperation('edit', id)
  }

  const handleShowMore = () => {
    if (totalData! / paginate.page <= paginate.limit) {
      return
    }
    setPaginateLoader(true)
    setPaginate(prev => {
      return { ...prev, limit: prev.limit + 30 }
    })
  }

  const handleDeleteModalVisibility = useCallback(() => {
    setShowDeleteModal(prev => !prev)
  }, [])

  const handleModal = () => {
    setShowModal(false)
    setModalProps(modalInitial)
    setTitle('')
    setDescription('')
    setIsActive(false)
    setDefaultImage('')
  }

  useAsyncEffect(getNews, [paginate, fetchAgain])

  useEffect(() => {
    setTitle(modalProps?.editProps?.announcements?.[0].title ?? '')
    setDescription(modalProps?.editProps?.announcements?.[0].description ?? '')
    setDefaultImage(modalProps?.editProps?.announcements?.[0].image?.imageThumbnail ?? '')
  }, [modalProps])

  return {
    val: {
      tableData,
      tableColumns,
      tableSizes,
      showDeleteModal,
      modalProps,
      totalData,
      paginateLoader,
      tableIdIndex,
      showModal,
      isEmpty,
      title,
      description,
      isActive,
      image,
      clearPreview,
      defaultImage,
    },

    set: {
      setFetchAgain,
      setShowDeleteModal,
      setModalProps,
      setShowModal,
      setTitle,
      setDescription,
      setIsActive,
      setClearPreview,
      setImage,
    },

    on: {
      DeleteAnnouncement,
      handleDeleteModalVisibility,
      handleShowMore,
      handleModal,
      CreateAnnouncement,
      EditAnnouncement,
    },

    get: {
      paginate,
      totalData,
    },
  }
}
