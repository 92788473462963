import React from 'react'
import { Switch, BrowserRouter, Route } from 'react-router-dom'
import { useAccessToken } from 'common/hooks/use-accesstoken'
import { Private } from './private'
import { Public } from './public'

export const Routes: React.FC = (): JSX.Element => {
  const { hasPermission } = useAccessToken()

  return (
    <>
      <BrowserRouter>
        <Switch>{hasPermission ? <Private /> : <Public />}</Switch>
      </BrowserRouter>
    </>
  )
}
