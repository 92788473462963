import { ChangeEvent, DragEvent, useEffect, useState } from 'react'
import { File2Base64, resizeImage, sieveFilesFormat } from 'common/helpers/image.helper'
import Resizer from 'react-image-file-resizer'
import * as Lib from '.'
import { ModalProps } from 'antd/lib/modal'

export const useUploader = ({ onChange, defaultImage, modalImage }: Pick<Lib.T.UploaderProps, 'onChange' | 'defaultImage' | 'modalImage'>) => {
  const [b64State, setB64State] = useState<string | null>(null)
  const [fileState, setFileState] = useState<File | null>(null)
  const [resizing, setResizing] = useState<boolean>(false)
  const [isOver, setIsOver] = useState<boolean>(false)
  const [imageModal, setImageModal] = useState<boolean>(false)

  const setCollections = (collection: File | string) => {
    setResizing(true)
    const fileHolder = sieveFilesFormat(collection as any)[0]
    let b64Holder: string = ''
    File2Base64(fileHolder).then((b64: string) => {
      resizeImage(b64, 700).then((sizes: { w: number; h: number }) => {
        Resizer.imageFileResizer(
          fileHolder,
          sizes.w,
          sizes.h,
          'JPEG',
          100,
          0,
          (uri: any) => {
            b64Holder = uri
            setB64State(b64Holder)
            setFileState(fileHolder)
            setResizing(false)
          },
          'base64',
          sizes.w,
          sizes.h,
        )
      })
    })
    return
  }

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    bubbling(event)
    let collection = event.dataTransfer.files as unknown as File
    setCollections(collection)
    setIsOver(false)
  }

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    bubbling(event)
    event.dataTransfer.dropEffect = 'copy'
    setIsOver(true)
  }

  const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    bubbling(event)
    setIsOver(false)
  }

  const openDialog = (id: string) => document.getElementById(id)!.click()

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setCollections(e.target.files as unknown as File)
    } catch {}
  }

  const handleRemove = () => {
    setFileState(null)
    setB64State(null)
  }

  useEffect(() => {
    if (onChange) {
      onChange(fileState)
    }
  }, [fileState])

  const onDefaultImageClick = () => {
    if (modalImage || b64State) {
      setImageModal(true)
    }
  }
  const imageModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: imageModal,
    onCancel: () => {
      setImageModal(false)
    },
    className: 'noHeaderModal',
    style: { top: 15 },
  }
  return {
    onDrop,
    onDragOver,
    onDragLeave,
    isOver,
    openDialog,
    handleFileChange,
    handleRemove,
    b64State,
    setB64State,
    setFileState,
    fileState,
    onDefaultImageClick,
    modal: {
      props: imageModalProps,
      toggle: () => setImageModal(!imageModal),
    },
  }
}

const bubbling = (event: DragEvent<HTMLDivElement>) => {
  event.preventDefault()
  event.stopPropagation()
}
