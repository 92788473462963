import React from 'react'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { Button } from 'common/components/ui-kit/button'
import { Col } from 'reactstrap'
import { List } from 'common/components/ui-kit/list'
import { Sticky } from 'common/components/ui-kit/sticky'
import { Content } from 'common/components/ui-kit/content'
import * as Lib from './lib'

export const StoneShapes: React.FC = ({}) => {
  const { list, on, addStates, editStates } = Lib.H.useStoneShapes()

  return (
    <>
      <PanelWrapper tab={['options', '/options/stone-shapes']} title="Seçenek Oluşturma">
        <Sticky>
          <p className="title">Taş Şekli</p>
          <Button text="Ekle" callback={on.create} mood="accent" className="submitButton" />
        </Sticky>

        <Content>
          <Col lg={6} md={12} className="section">
            <Lib.C.Contents {...addStates} onEnter={on.create} />
          </Col>

          <Col lg={6} md={12} className="section">
            {list && (
              <List
                items={list}
                border
                onDelete={on.delete}
                edit={{
                  content: <Lib.C.Contents {...editStates} onEnter={on.edit} />,
                  beforeConfirm: on.beforeConfirmEdit,
                  onSubmit: on.edit,
                  overrideVisibilityState: editStates.visibility,
                }}
              />
            )}
          </Col>
        </Content>
      </PanelWrapper>
    </>
  )
}
