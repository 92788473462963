import React from 'react'
import { Gap } from 'common/components/ui-kit/gap'
import { Input } from 'common/components/ui-kit/input'
import * as Lib from '.'
import { Uploader } from 'common/components/ui-kit/uploader'

export const Contents: React.FC<Lib.T.ContentsProps> = ({ name, image, uploaderId, onEnter }): JSX.Element => (
  <>
    <Input label="name" required onEnter={onEnter} value={name.val || ''} onInput={evt => name.set(evt.currentTarget.value)} />
    <Gap mood="H" size={10} />
    <Uploader id={uploaderId} onChange={image.set} />
    <Gap mood="H" size={20} />
  </>
)
