import { ModalProps, Switch } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { TableSubDataType } from 'common/components/ui-kit/table-2/lib/typing'
import { imageAddresser } from 'common/helpers/image.helper'
import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { usePermissions } from 'common/routes/hooks'
import { RolesTR } from 'common/routes/types/roles.type'
import { CustomerService, OptionService, UserService } from 'common/services'
import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Lib from '.'
import * as C from './components'
import { Customer } from 'common/services/lib/types/customer'
import _ from 'lodash'
import { Icon } from '../../../../../common/components/ui-kit/icon'

const allRoles: any = RolesTR

export const useTable = () => {
  const history = useHistory()

  const [showFilters, toggleShowFilters] = useState<boolean>(window.innerWidth <= 992 ? false : true)
  const [query, setQuery] = useState<string>()
  const [tableData, setTableData] = useState<(React.ReactNode | JSX.Element)[][]>([])
  const [userIdToDisable, setUserIdToDisable] = useState<number | null>(null)
  const [confirmModal, setConfirmModal] = useState<boolean>(false)

  const [countries, setCountries] = useState<Lib.T.RadioSelector[]>([])
  const [salesMen, setSalesMen] = useState<Lib.T.RadioSelector[]>([])
  const [type, setType] = useState<'none-personnel' | 'personnel' | 'all' | undefined>('none-personnel')
  const [statusType, setStatusType] = useState<'active' | 'de-active' | undefined>('active')
  const [saleMan, setSaleMan] = useState<CheckboxValueType[]>([])
  const [country, setCountry] = useState<CheckboxValueType[]>([])
  const [saleCountry, setSaleCountry] = useState<CheckboxValueType[]>([])
  const [carats, setCarats] = useState<Lib.T.RadioSelector[]>([])
  const [carat, setCarat] = useState<CheckboxValueType[]>([])

  const [totalData, setTotalData] = useState<number | undefined>()
  const [paginate, setPaginate] = useState({ limit: 30, page: 1 })
  const group = usePermissions()
  const [fetchAgain, setFetchAgain] = useState<boolean>(true)
  const { setLoader } = useLoader()
  const [tableSubData, setTableSubData] = useState<TableSubDataType[]>([])

  const tableIdIndex = 6
  const tableColumns = ['Resim', 'Firma', '', 'Statu', 'Aktif']
  const tableSizes = ['unset', '100%', 'unset', 'unset', 'unset']
  // detail Modal States
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [detailModalData, setDetailModalData] = useState<Lib.T.DetailModalData | undefined>() //continue after ui

  const clearFilters = () => {
    setCountry([])
    setSaleMan([])
    setSaleCountry([])
    setCarat([])
    setQuery('')
    setFetchAgain(!fetchAgain)
    setType('none-personnel')
  }

  const fetchSalesMans = async () => {
    const service = new UserService()
    setLoader(true)

    const { success, users } = await service.getList({ group: 'Satış Temsilcisi', disabled: false })
    if (success && users?.length) {
      const dataTransform = users?.map(usr => {
        return { id: usr.id, name: usr.name }
      })
      setSalesMen(dataTransform)

      setLoader(false)
    }
    setLoader(false)
  }

  const fetchAllCustomers = async (reload?: boolean) => {
    const service = new CustomerService()
    setLoader(true)
    const { customers, total, success } = await service.getList({
      ...paginate,
      company: query,
      country: country as number[],
      saleCountries: saleCountry as number[],
      karat: carat as number[],
      salePersons: saleMan as number[],
      showDisabled: statusType === 'active' ? false : true,
      type,
    })
    setLoader(false)

    if (customers && success) {
      setTotalData(parseInt(total! + ''))
      setTableData(prevData => [
        ...(reload ? [] : prevData),
        ...customers.map(i => {
          return [
            <Lib.C.TableImage src={i.avatar ? imageAddresser(i.avatar.imageThumbnail, true) : '/images/jpg/placeholder.jpg'} />,
            <div onClick={() => handleTableRowClick(i)}>{i.company}</div>,
            <>
              {i.needUpdateFirstName || i.needUpdateLastName || i.needUpdatePhone || i.needUpdateUsername ? (
                <Icon name="warning" color="red" size={20} />
              ) : (
                ''
              )}
            </>,
            <>
              {i.isPersonnel ? null : (
                <Lib.C.TableImage
                  src={
                    i.rank.customerRankImage.imageThumbnail
                      ? imageAddresser(i.rank.customerRankImage.imageThumbnail, true)
                      : '/images/jpg/placeholder.jpg'
                  }
                />
              )}
            </>,
            <Lib.S.tableButtons>
              {i.status.includes('pending') && (
                <Lib.C.TableButton icon="pending-clock" color="#F0AD4B" callback={() => history.push(`/customers/edit/${i.id}`)} />
              )}

              <Switch
                defaultChecked={!i.disabled}
                onChange={val => {
                  disableUser(!val, i.id)
                }}
              />
              <Lib.C.TableButton icon="edit_outline" callback={() => history.push(`/customers/edit/${i.id}`)} />
            </Lib.S.tableButtons>,
            i.disabled,
            i.id,
          ]
        }),
      ])
      setTableSubData(prevData => [
        ...(reload ? [] : prevData),
        ...customers?.map(i => {
          return {
            columns: ['Ad', 'Soyad', 'Telefon', 'Email'],
            data: i.employees?.map(j => [j.firstName, j.lastName, j.phone, j.email]),
          }
        }),
      ])
    }
  }

  const handleShowMore = () => {
    if (totalData! / paginate.page <= paginate.limit) {
      return
    }
    setPaginate(perval => {
      return { ...perval, page: perval.page + 1 }
    })
  }

  const disableUser = async (isDisabled: boolean, id: number) => {
    setLoader(true)
    const userService = new CustomerService()
    const success = await userService.edit({ disabled: isDisabled }, id)
    if (success) {
      changeUserDisabledStatus()
    }
    setLoader(false)
    setConfirmModal(false)
  }

  const changeUserDisabledStatus = () => {
    setTableData(prev =>
      prev.map(item => {
        if (item[tableIdIndex] === userIdToDisable) {
          const otherDetails = item.slice(0, tableIdIndex - 1)
          otherDetails.push(!item[tableIdIndex - 1])
          otherDetails.push(item[tableIdIndex])
          return otherDetails
        } else {
          return item
        }
      }),
    )
  }

  const confirmModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: confirmModal,
    onCancel: () => setConfirmModal(false),
    className: 'noHeaderModal',
    afterClose: () => {
      setUserIdToDisable(null)
    },
  }

  const CountriesFilter: FC<Lib.T.CountriesFilterProps> = props => (
    <C.FilterCountries country={props.country} setCountry={props.setCountry} countries={countries} />
  )

  const filterItems: Lib.T.FilterItemsType[] = [
    {
      title: 'Ülke',
      children: <CountriesFilter country={country} setCountry={setCountry} />,
      permissions: allRoles,
      visible: true,
    },
    {
      title: 'Sattığı Pazar',
      children: <CountriesFilter country={saleCountry} setCountry={setSaleCountry} />,
      permissions: allRoles,
    },
    {
      title: 'Sattığı Ayar',
      children: <C.FilterCarats carat={carat} setCarat={setCarat} carats={carats} />,
      permissions: allRoles,
    },
    {
      title: 'Satış sorumlusu',
      children: <C.FilterSalesMan salesMen={salesMen} saleMan={saleMan} setSaleMan={setSaleMan} />,
      permissions: allRoles,
    },
    {
      title: 'Müşteri Türü',
      children: <C.FilterCustomerKind type={type} setType={setType} />,
      maxHeight: 50,
      permissions: allRoles,
    },
    {
      title: 'Durum',
      children: <C.FilterStatus type={statusType} setType={setStatusType} />,
      maxHeight: 50,
      permissions: allRoles,
    },
  ]

  const getCountries = async () => {
    const optionService = new OptionService({ type: 'country' })

    const { success, options } = await optionService.read({})
    if (success && options) {
      setCountries(prev => [
        ...prev,
        ...options.map(i => {
          return { id: i.id, name: i.country! }
        }),
      ])
    }
  }

  const getCarats = async () => {
    const optionService = new OptionService({ type: 'karat' })

    const { success, options, total } = await optionService.read({})
    if (success && options) {
      setCarats(prev => [
        ...prev,
        ...options.map(i => {
          return {
            id: i.id,
            name: `${i.karat}`,
            // name: `${i.karat}${i.purity ? ' / ' + i.purity : ''}${i.gramMultiplier ? ' / ' + i.gramMultiplier : ''}`,
          }
        }),
      ])
    }
  }

  const handleFilter = () => {
    setPaginate(perv => ({ ...perv, page: 1 }))
    setFetchAgain(perv => !perv)
  }

  const handleTableRowClick = (customer: Customer) => {
    if (!_.isEmpty(customer)) {
      const karats = customer.karats.map(krt => krt.karat)
      const countries = customer.saleCountries.map(country => country.country)
      const salePersons = customer.salePersons.map(person => person.name)

      const flattenedProducts = customer.productCategories.map(category => {
        return { id: category.id, categoryName: category.productCategory, subCategories: category.productSubCategories }
      })

      setDetailModalData({
        avatar: customer.avatar,
        avatarThumbnail: customer.avatarThumbnail,
        address: customer.address,
        company: customer.company,
        companyEmail: customer.email,
        country: customer.country.country,
        employees: customer.employees,
        karats,
        productCategories: flattenedProducts,
        saleCountries: countries,
        salePersons,
      })
    }

    setShowDetailModal(true)
  }

  const toggleDetailModalVisibility = () => {
    setShowDetailModal(perv => !perv)
  }

  const detailModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: showDetailModal,
    width: '80%',
    onCancel: () => setShowDetailModal(false),
    className: 'noHeaderModal scrollWidthModal',
  }

  useEffect(() => setTableData([]), [fetchAgain])
  useAsyncEffect(fetchSalesMans, [fetchAgain])
  useAsyncEffect(fetchAllCustomers, [paginate, fetchAgain])
  useAsyncEffect(getCountries, [])
  useAsyncEffect(getCarats, [])
  return {
    get: {
      tableData,
      group,
      totalData,
      paginate,
      showFilters,
      filterItems,
      query,
      fetchAgain,
      tableIdIndex,
      tableColumns,
      tableSizes,
      tableSubData,
    },
    set: {
      toggleShowFilters,
      setQuery,
      setFetchAgain,
    },
    on: {
      handleShowMore,
      clearFilters,
      disableUser,
      handleFilter,
    },
    modal: {
      confirmModalProps,
    },
    detailModal: {
      detailModalProps,
      detailModalData,
      toggleDetailModalVisibility,
    },
  }
}
