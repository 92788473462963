import styled from 'styled-components'
import { mediaQueries as mq } from 'common/constants/media-queries'

export const searchSection = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  gap: 10px;

  div > .ant-select-selector {
    border-width: 1px !important;
  }

  > p {
    margin: 0 20px 0 0;
    font-size: 26px;
    font-family: var(--f-bo);
  }

  > div {
    flex: 1;
    display: flex;
    align-items: center;

    > .inputContainer {
      flex: 1;
      position: relative;

      > .filterButton {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 45px;
        right: 0;
        display: none;
        align-items: center;
        justify-content: center;

        ${mq.max[768]} {
          display: flex;
        }
      }
    }

    > .buttonContainer {
      width: unset;
      margin: 0 0 0 5px;

      > .parent {
        padding: 0;
        min-height: unset;
        width: 45px;
        height: 45px;
      }
    }
  }
`

export const tableButton = styled.span`
  display: block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.effect {
    svg {
      transition: all 150ms linear;
    }

    &.rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &:hover {
    background: #f8f8f8;
  }
`

export const tableButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const content = styled.div`
  flex: 1;
  padding: 0;

  ${mq.max[768]} {
    width: 100%;
    float: right;
    padding: 0;
    margin-top: 10px;
  }
  .tableContainer {
    border-spacing: 0;
  }
  > .tableContainer {
    float: left;
    width: 100%;
    margin: 10px 0 0 0;
    border-radius: 15px;
    padding: 0;

    .tr.body:nth-child(odd) {
      background-color: #e5edec;
    }

    .tr.body > .td:nth-child(2) {
      border-radius: 0 !important;
    }
    .tr.body > .td:last-child {
      border-radius: 0 !important;
    }

    .td.header {
      border-bottom: 2px solid rgba(204, 204, 204, 1);
    }

    .body {
      > .td {
        background-color: transparent !important;
        padding: 5px 0;
        cursor: auto !important;
      }
    }

    .tableContainer {
      padding: 0 0px;

      .filter {
        border: none;
        background: #f8f8f8 !important;
      }
    }

    > p {
      margin: 0;
      padding: 5px 0 15px 25px;
      font-size: 19px;
      color: #252733;
      font-family: var(--f-se-bo);
    }
  }
`

export const PageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  overflow-x: scroll;
  padding: 10px;
`

export const DeleteModalContent = styled.div`
  float: left;
  width: 100%;
  height: auto;

  > p {
    margin: 0;
    float: left;
    width: 100%;
    font-size: 18px;
    text-align: center;
  }

  > div {
    float: left;
    width: 100%;
    text-align: center;
    margin: 30px 0 0 0;

    > div {
      width: 110px !important;
      float: none !important;
      display: inline-flex !important;
      padding: 0 2px;

      > div {
        min-height: 35px !important;
      }
    }
  }
`

export const ModalContainer = styled.div`
  div > .ant-select-selector {
    border-width: 1px !important;
  }

  .isManager {
    height: 43px;
    margin-top: 37px;
    padding: 0 10px;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
  }

  .isLogin {
    height: 43px;
    margin-top: 38px;
    padding: 0 10px;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
  }
  .modalTitle {
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    background: var(--accent);
    color: #fff;
    border-radius: 10px 10px 0 0;
    padding: 5px 10px;

    div {
      display: flex;
    }
    span {
      font-weight: 600;
      font-size: 18px;
    }
    svg {
      margin-top: 5px;
      padding: 2px;
    }
  }

  .uploader {
    div {
      margin-top: 5px;
    }
  }

  .btn {
    display: flex;
    margin: 10px auto 0 auto;
  }
`

export const tableImage = styled.div`
  width: 40px;
  height: 40px;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }

  ${mq.max[992]} {
    width: 60px;
    height: 60px;
  }
`