export const months = ['ocak', 'şubat', 'mart', 'nisan', 'Mayıs', 'haziran', 'temmuz', 'ağustos', 'eylül', 'ekim', 'kasım', 'aralık']
export const threeLetterMonths = ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
export const monthMapping = {
  Jan: 'Oca',
  Feb: 'Şub',
  Mar: 'Mar',
  Apr: 'Nis',
  May: 'May',
  Jun: 'Haz',
  Jul: 'Tem',
  Aug: 'Ağu',
  Sep: 'Eyl',
  Oct: 'Eki',
  Nov: 'Kas',
  Dec: 'Ara',
}
