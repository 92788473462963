import { ModalProps } from 'antd'
import { NestTableItem } from 'common/components/ui-kit/nest-table/lib/typing'
import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { AutomationRoleService } from 'common/services'
import { Role } from 'common/services/lib/types'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert'

export const useCreateRole = () => {
  const { setLoader } = useLoader()
  const history = useHistory()

  const [name, setName] = useState<string>('')
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [categories, setCategories] = useState<NestTableItem[]>([])
  const [role, setRole] = useState<Role.Role>()

  const [categoriesState, setCategoriesState] = useState<
    | {
        name: string
        isParent: string
        isSelected: boolean
        id: number
        subItems: { name: string; isParent: number; isSelected: boolean; id: number; disabled: boolean }[]
      }[]
    | []
  >([])
  const [editMode, setEditMode] = useState<boolean>(false)

  const error = (msg: string) =>
    swal({
      text: msg,
      dangerMode: false,
      icon: 'error',
    })

  const validateForm = (): boolean => {
    if (!name) {
      error('please enter role name')
      return false
    }
    return true
  }

  const createOrEdit = async () => {
    if (editMode) {
      if (!validateForm()) {
        return
      }
    } else {
      if (!validateForm()) {
        return
      }
    }

    let success = false
    setLoader(true)

    let permissionIds: { permissionIds: number[]; name: string }
    let ids: number[] = []
    categoriesState?.forEach(cat => {
      cat.subItems?.forEach(sub => {
        if (sub.isSelected) {
          ids.push(sub.id)
        }
      })
      if (cat.isSelected) {
        ids.push(cat.id)
      }
    })
    permissionIds = {
      permissionIds: ids,
      name: name,
    }

    if (editMode) {
      success = await edit(permissionIds)
    } else {
      success = await create(permissionIds)
    }
    setLoader(false)
    if (success) {
      return swal({
        icon: 'success',
        timer: 500,
        buttons: [false],
        className: 'rounded-swal',
      }).then(() => history.replace('/automation/roles'))
    }
  }

  const edit = async (permissionIds: { name: string; permissionIds: number[] }): Promise<boolean> => {
    const service = new AutomationRoleService()
    return await service.edit(permissionIds, role?.id || 0)
  }

  const create = async (permissionIds: { name: string; permissionIds: number[] }): Promise<boolean> => {
    const service = new AutomationRoleService()
    return await service.create(permissionIds)
  }

  const checkForEdit = async () => {
    const { href } = window.location
    if (!href.includes('edit')) {
      return
    }
    const id = href.split('/').pop()
    if (!id) {
      return
    }
    setEditMode(true)
    const service = new AutomationRoleService()
    const { success, role } = await service.findOne(parseInt(id))

    if (!success || !role) {
      return
    }

    setRole(role)
    const { name } = role
    setName(name)

    setCategoriesState(perv => {
      let copy = [...perv]

      copy?.forEach((copyItem: any, parentIndex) => {
        role?.permissions?.forEach((role: any) => {
          if (role.id === copyItem.id) {
            const subItemsWithDisabled = copy[parentIndex]?.subItems?.map((sub: any) => ({
              ...sub,
              disabled: true,
            }))
            copy[parentIndex] = {
              name: role.access,
              id: role.id,
              isParent: '',
              isSelected: true,
              subItems: subItemsWithDisabled ? [...subItemsWithDisabled] : [],
            }
          } else {
            copyItem.subItems?.forEach((sub: any) => {
              if (sub.id === role.id) {
                copy[parentIndex] = {
                  ...copy[parentIndex],
                  subItems: [
                    ...copy[parentIndex]?.subItems,
                    {
                      name: role.access,
                      isParent: role.id,
                      isSelected: true,
                      id: sub.id,
                      disabled: false,
                    },
                  ],
                }
              }
              const unique = [...new Map(copy[parentIndex].subItems.map(m => [m.id, m])).values()]
              copy[parentIndex] = { ...copy[parentIndex], subItems: [...unique] }
            })
          }
        })
      })

      return copy
    })
  }

  const deleteRole = async () => {
    const service = new AutomationRoleService()
    setLoader(true)
    const success = await service.delete(role?.id!)
    if (success) {
      history.replace('/automation/roles')
      setLoader(false)
    } else {
      setLoader(false)
      setDeleteModal(false)
      return swal({
        text: 'something went wrong',
        icon: 'error',
        timer: 2000,
      })
    }
    setLoader(false)
  }

  const getCategories = async () => {
    const service = new AutomationRoleService()
    setLoader(true)
    const { success, permissions } = await service.getPermissionList()
    setLoader(false)
    if (!success || !permissions) {
      return
    }

    setCategoriesState(
      permissions.map(cat => {
        return {
          name: cat.name || cat.access,
          id: cat.id,
          isParent: '',
          isSelected: false,
          subItems: cat.children?.map(sub => {
            return {
              name: sub.name || sub.access,
              isParent: cat.id,
              id: sub.id,
              isSelected: false,
              disabled: false,
            }
          }),
        }
      }),
    )

    setCategories(
      permissions.map(cat => {
        return {
          key: cat.id,
          name: cat.access,
          subItems: cat.children?.map(sub => {
            return {
              key: sub.id,
              name: sub.access,
            }
          }),
        }
      }),
    )
  }

  const toggleDeleteModal = () => {
    setDeleteModal(perv => !perv)
  }

  const deleteModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: deleteModal,
    onCancel: () => setDeleteModal(false),
    className: 'noHeaderModal',
  }

  useEffect(() => {
    editMode && checkForEdit()
  }, [editMode])

  useAsyncEffect(getCategories, [])
  useAsyncEffect(checkForEdit, [editMode])

  return {
    get: {
      name,
      categoriesState,
      categories,
      editMode,
      deleteModalProps,
      role,
    },
    set: {
      setName,
      setCategories,
      setCategoriesState,
    },
    on: {
      toggleDeleteModal,
      createOrEdit,
      deleteRole,
    },
  }
}
