import { Content } from 'antd/lib/layout/layout'
import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { FC } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import * as Lib from './lib'
export const Notifications: FC = () => {
  const { val, on } = Lib.H.useNotifications()

  return (
    <>
      <PanelWrapper tab={['notifications', '/notifications']} title="Notifications">
        <Content>
          <InfiniteScroll
            dataLength={val.data.length}
            next={on.handleShowMoreNotification}
            hasMore={val.notificationTotalData / val.pagination.page > val.pagination.limit}
            loader={''}
            height="100vh"
          >
            <Lib.C.NotificationList list={val.data} events={on} loader={val.loader} />
          </InfiniteScroll>
        </Content>
      </PanelWrapper>
    </>
  )
}
