import { useEffect, useState } from 'react'
import { ModalProps } from 'antd/lib/modal'
import * as Lib from '.'
import swal from 'sweetalert'

export const useList = (onDelete?: (id: number) => void, edit?: Lib.T.Edit) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState<boolean>(false)
  const [imgModal, setImgModal] = useState<{ status: boolean; img: string }>({ status: false, img: '' })
  const [idToDelete, setIdToDelete] = useState<number>(-1)

  const deleteModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: deleteModal,
    onCancel: () => setDeleteModal(false),
    className: 'noHeaderModal',
  }
  useEffect(() => {}, [])
  const editModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: edit?.overrideVisibilityState ? edit.overrideVisibilityState.val : editModal,
    width: edit?.width,

    onCancel: () => {
      edit?.onClosed?.set(true)
      if (edit?.overrideVisibilityState) {
        edit.overrideVisibilityState.set(false)
      } else {
        setEditModal(false)
      }
    },
    className: 'noHeaderModal',
    afterClose: () => {
      if (edit && edit.afterClose) {
        edit.afterClose()
      }
    },
  }

  const imgModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: imgModal.status,
    onCancel: () => setImgModal({ status: false, img: '' }),
    className: 'noHeaderModal',
  }

  const onConfirmDelete = (id: number) => {
    if (onDelete) {
      setIdToDelete(id)
      setDeleteModal(true)
    }
  }

  const onConfirmEdit = (item: Lib.T.ListObject) => {
    if (edit) {
      edit.beforeConfirm(item)
      edit.onClosed?.set(false)
      if (edit.overrideVisibilityState) {
        edit.overrideVisibilityState.set(true)
      } else {
        setEditModal(true)
      }
    }
  }

  const onDeleteHandler = () => {
    if (idToDelete > 0 && onDelete) {
      onDelete(idToDelete)
      setDeleteModal(false)
    } else {
      swal({
        icon: 'error',
        dangerMode: true,
        text: 'Bir şeyler ters gitti, lütfen tekrar deneyin.',
      })
      setIdToDelete(-1)
      setDeleteModal(false)
    }
  }

  const onEditHandler = () => {
    if (edit) {
      if (!edit.overrideVisibilityState) {
        setEditModal(false)
      }
      edit.onSubmit()
    }
  }

  return {
    deleteModal: {
      props: deleteModalProps,
      toggle: () => setDeleteModal(!deleteModal),
    },
    editModalProps: {
      props: editModalProps,
      toggle: () => {
        if (edit?.overrideVisibilityState) {
          edit.overrideVisibilityState.set(!edit.overrideVisibilityState.val)
        } else {
          setEditModal(!editModal)
        }
      },
    },
    imgModal: {
      props: imgModalProps,
      set: setImgModal,
      val: imgModal,
    },
    on: {
      confirmDelete: onConfirmDelete,
      deleteHandler: onDeleteHandler,
      confirmEdit: onConfirmEdit,
      editHandler: onEditHandler,
    },
  }
}
