import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import swal from 'sweetalert'
import { MethodsArgs } from './lib/types/printer'

export class PrinterService {
  accessToken: string

  constructor() {

    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      PrinterService.dontAccept()
    }

    if (!user!.accessToken) {
      PrinterService.dontAccept()
    }

    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async create(args: Lib.T.Printer.MethodsArgs): Promise<Lib.T.Printer.CreateOrEditReturnType> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat('printer'),
      headers: { authorization: this.accessToken },
      data:{...args}
    })

    const { status } = response
    if (status !== 201) {
      PrinterService.unhandled(response.data.message)
      return { success: false }
    }

    return {
      success: true,
    }
  }

  async edit(id: number, args: Lib.T.Printer.MethodsArgs): Promise<Lib.T.Printer.CreateOrEditReturnType> {
    const { status, data } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat('printer/').concat(id.toString()),
      headers: { authorization: this.accessToken },
      data:{...args}
    })

    if (status !== 200) {
      PrinterService.unhandled(data.message)
      return { success: false }
    }

    return { success: true }
  }

  async toggleDisabled(id: number): Promise<Lib.T.Printer.CreateOrEditReturnType> {
    const { status, data } = await ApiCaller({
      method: 'PATCH',
      url: process.env.REACT_APP_SERVER_URL!.concat('printer/').concat(id.toString())!.concat('/toggle-disabled'),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      //PrinterService.unhandled(data.message)
      return { success: false, message: data.message }
    }

    return { success: true }
  }

  async readSingle(id: number): Promise<Lib.T.Printer.CreateOrEditReturnType> {
    const { status, data } = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat('printer/').concat(id.toString()),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200 || !data) {
      PrinterService.unhandled(data.message)
      return { success: false }
    }

    return { success: true, data: data }
  }


  async read(args?: Lib.T.Printer.ReadArgs): Promise<Lib.T.Printer.ReadReturnType> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(
        `printer?page=${args?.page || 1}&limit=${args?.limit || 99999999}`,
      ),
      headers: { authorization: this.accessToken },
    })

    try {
      const { status } = response
      if (status !== 200) {
        return { success: false, total: 0 }
      }

      const data = response.data

      return {
        success: true,
        data: data.printers,
        total: data.total,
      }
    } catch (e) {
      return { success: false, total: 0 }
    }
  }

  async delete(id: number): Promise<boolean> {
    const response = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`printer/${id}`),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response
    if (status !== 200 || data !== '') {
      PrinterService.unhandled(data.message)
      return false
    }

    return true
  }

  static dontAccept() {
    throw 'not-acceptable'
  }

  static unhandled(errorMessage?: string) {
    if (errorMessage?.includes('image is required.')) {
      return swal({
        text: 'Tüm alanlar doldurulmalıdır.',
        dangerMode: true,
        icon: 'error',
      })
    } else if (errorMessage?.includes('duplicate')) {
      return swal({
        text: 'Bu bir kez oluşturulmuş.',
        dangerMode: true,
        icon: 'error',
      })
    } else if (errorMessage?.includes('expireLicenceDate must greater than now')) {
      return swal({
        text: 'Lisansın sona erme tarihi satın alınan tarih den sonra olması gerekiyor.',
        dangerMode: true,
        icon: 'error',
      })
    } else if (errorMessage?.includes('this printer have problems or task and you can not deleted this !')) {
      return swal({
        text: 'Bu Makinenin  görevleri var ve bunu silemezsiniz.',
        dangerMode: true,
        icon: 'error',
      })
    } else {
      return swal({
        text: 'İşlem bir hatayla karşılaştı.',
        dangerMode: true,
        icon: 'error',
      })
    }
  }
}