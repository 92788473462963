import { useEffect, useState } from 'react'
import { OptionService } from 'common/services'
import { Options } from 'common/services/lib/types'
import * as Lib from '.'
import { UNHANDLED } from 'common/constants/errors'
import { ListObject } from 'common/components/ui-kit/list/lib/typing'
import { useDispatch } from 'react-redux'
import { Global } from 'common/store/actions'
import { combineStoneSizes } from 'common/helpers/stone-size'
import swal from 'sweetalert'
import { PaginationState } from 'common/typing/pagination-state'

export const useStoneSize = () => {
  const [length, setLength] = useState<string | null>(null)
  const [width, setWidth] = useState<string | null>(null)
  const [height, setHeight] = useState<string | null>(null)

  const [editLength, setEditLength] = useState<string | null>(null)
  const [editWidth, setEditWidth] = useState<string | null>(null)
  const [editHeight, setEditHeight] = useState<string | null>(null)
  const [itemIdToEdit, setItemIdToEdit] = useState<number | null>(null)
  const [editModal, setEditModal] = useState<boolean>(false)

  const [list, setList] = useState<ListObject[]>([])
  const dispatch = useDispatch()
  const setLoader = (payload: boolean) => dispatch(Global.fsLoader(payload))

  const [pagination, setPagination] = useState<PaginationState>({
    loading: true,
    page: 1,
    total: 0,
  })

  const onStartUp = async () => {
    const optionService = new OptionService({ type: 'stone-size' })
    setPagination({ ...pagination, loading: true })

    const result = await optionService.read({
      page: pagination.page,
      limit: 20,
    })

    setPagination({ ...pagination, loading: false, total: result.total })

    if (!result.success || !result.options) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      return
    }

    const options = <Lib.T.List[]>result.options
    const listArray: ListObject[] = []
    options.forEach(item =>
      listArray.push({
        id: item.id,
        name: `${item.stoneLength}${item.stoneWidth ? 'x' + item.stoneWidth : ''}${item.stoneHeight ? 'x' + item.stoneHeight : ''}`,
      }),
    )
    setList(listArray)
  }

  useEffect(() => {
    ;(async () => onStartUp())()
  }, [pagination.page])

  const onSubmit = async () => {
    if (!length) {
      swal({
        text: 'Lütfen uzunluğu girin.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }
    if (height && !width) {
      swal({
        text: 'Lütfen önce genişliği girin.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }
    setLoader(true)

    const requestBody: Options.ServiceArgs = {
      type: 'stone-size',
      stoneLength: parseFloat(length),
    }
    if (width) requestBody.stoneWidth = parseFloat(width)
    if (height) requestBody.stoneHeight = parseFloat(height)

    const optionService = new OptionService(requestBody)

    const result = await optionService.create()
    if (!result.success || !result.option) {
      swal({
        text: UNHANDLED,
        icon: 'error',
        dangerMode: true,
      })
      setLoader(false)
      return
    }

    const { option } = result
    const { id, stoneLength, stoneWidth, stoneHeight } = option

    const name = `${stoneLength}${stoneWidth ? 'x' + stoneWidth : ''}${stoneHeight ? 'x' + stoneHeight : ''}`
    setList([...list, { id, name }])
    setLength(null)
    setWidth(null)
    setHeight(null)
    setLoader(false)
  }

  const onDelete = async (id: number) => {
    const optionService = new OptionService({ type: 'stone-size' })
    const result = await optionService.delete(id)
    setLoader(true)

    if (!result) {
      setLoader(false)
      return
    }

    const listArray: ListObject[] = []
    list.forEach(item => {
      if (item.id !== id) {
        listArray.push(item)
      }
    })

    setList(listArray)
    setLoader(false)
  }

  const beforeConfirmEdit = (item: ListObject) => {
    const splitted = <[string, string?, string?]>item.name.split('x')
    setEditLength(splitted[0])
    setEditWidth(splitted[1] || '')
    setEditHeight(splitted[2] || '')
    setItemIdToEdit(+item.id)
  }

  const edit = async () => {
    if (!editLength) {
      swal({
        text: 'Lütfen uzunluğu girin.',
        icon: 'info',
        dangerMode: true,
      })

      return
    }
    if (editHeight && !editWidth) {
      swal({
        text: 'Lütfen önce genişliği girin.',
        icon: 'info',
        dangerMode: true,
      })
      return
    }
    if (!itemIdToEdit) {
      return swal({
        text: 'Düzenlenemedi. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
    }
    setLoader(true)

    const requestBody: Options.ServiceArgs = {
      type: 'stone-size',
      stoneLength: parseFloat(editLength),
    }
    if (editWidth) requestBody.stoneWidth = parseFloat(editWidth)
    if (editHeight) requestBody.stoneHeight = parseFloat(editHeight)

    const optionService = new OptionService(requestBody)
    const { success } = await optionService.edit(itemIdToEdit)
    setLoader(false)
    setEditModal(false)
    if (!success) {
      return
    }

    const listArray: ListObject[] = []
    list.forEach(item => {
      if (item.id === itemIdToEdit) {
        listArray.push({
          ...item,
          name: combineStoneSizes(editLength, editWidth, editHeight),
        })
      } else {
        listArray.push(item)
      }
    })

    setList(listArray)
  }

  return {
    addStates: {
      length: {
        val: length,
        set: setLength,
      },
      width: {
        val: width,
        set: setWidth,
      },
      height: {
        val: height,
        set: setHeight,
      },
    },
    pagination: {
      val: pagination,
      set: setPagination,
    },
    editStates: {
      length: {
        val: editLength,
        set: setEditLength,
      },
      width: {
        val: editWidth,
        set: setEditWidth,
      },
      height: {
        val: editHeight,
        set: setEditHeight,
      },
      visibility: {
        val: editModal,
        set: setEditModal,
      },
    },
    on: {
      create: onSubmit,
      delete: onDelete,
      beforeConfirmEdit,
      edit,
    },
    list,
  }
}
