import { AuthContainer } from 'common/components/layouts/auth/auth-container'
import { Button } from 'common/components/ui-kit/button'
import { Gap } from 'common/components/ui-kit/gap'
import { Input } from 'common/components/ui-kit/input'
import { requestPermission } from 'firebase'
import React, { useEffect } from 'react'
import * as Lib from './lib'
import { Storage } from 'common/helpers/storage'

export const Login: React.FC = ({}): JSX.Element => {
  const { email, password, onSubmit } = Lib.H.useLogin()

 useEffect(() => {
   const uuid = "10000000100040008000100000000000".replace(/[018]/g, (c:any) =>
     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
   )
   Storage.set('deviceId', uuid)
 }, [])

  return (
    <>
      <AuthContainer title="Giriş Yap">
        <Lib.S.Container>
          <Input
            label="Kullanıcı Adı"
            placeholder="Kullanıcı adınızı giriniz"
            name="username"
            required
            onEnter={onSubmit}
            value={email.value}
            onInput={evt => email.setValue(evt.currentTarget.value)}
          />

          <Gap mood="H" size={10} />

          <Input
            label="Şifre"
            required
            placeholder="Şifrenizi Girin"
            name="password"
            type="password"
            value={password.value}
            onEnter={onSubmit}
            onInput={evt => password.setValue(evt.currentTarget.value)}
          />
          <Gap mood="H" size={15} />

          {/* <Checkbox label="Beni Hatırla" /> */}
          <Gap mood="H" size={15} />

          <Button callback={onSubmit} text="Giriş Yap" mood="light" containerStyles={{ border: 0 }} className="button" />
        </Lib.S.Container>
      </AuthContainer>
    </>
  )
}
