import styled from 'styled-components'

export const PageWrapper = styled.div``
export const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`
export const NotificationsHolder = styled.ul`
  margin-right: 70px;
  padding-left: 44px;
  padding-top: 10px;
`
export const NotificationView = styled.li<{ read: boolean }>`
  background: ${props => (props.read ? 'rgba(0, 78, 66, 0.05)' : '#ffffff')};
  border-radius: 10px;
  list-style: none;
  position: relative;
  margin-bottom: 6px;
  color: #000;
  text-decoration: none;

  .read {
    display: block;
    width: 8px;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: ${props => (props.read ? 'rgba(0, 78, 66, 0.4)' : '#f0ad4b')};
    position: absolute;
    left: 0;
    top: 0;
  }
  & > hr {
    width: calc(100% - 80px);
    margin: 0 auto;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    margin-top: 5px;
    transition: 0.3s all;
    width: calc(100% - 8px);
    padding-left: 40px;
  }
`
export const Message = styled.div`
  height: 100%;
  color: #000;
  display: flex;
  align-items: center;
  cursor: pointer;
`
export const Time = styled.span``
export const CloseButton = styled.span`
  padding-right: 15px;
  padding-left: 15px;

  cursor: pointer;
  z-index: 115;
`
