import { OrdersService } from 'common/services'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Lib from '.'
import { useLoader } from './../../../../../common/hooks/use-loader'

export const useLog = () => {
  const { id } = useParams() as { id: string }
  const { setLoader } = useLoader()

  const [detailData, setDetailData] = useState<Lib.T.DetailData | undefined>()
  const [orderLog, setOrderLog] = useState<Lib.T.OrderLogTableType>()

  //footer state
  const [footerData, setFooterData] = useState<Lib.T.FooterDataType>({
    productsCount: 0,
    piecesCount: 0,
    totalWeight: 0,
    accurateWeight: false,
  })

  const fetchSingleOrderLog = useCallback(
    async function fetchSingleOrderLog() {
      const service = new OrdersService()
      setLoader(true)
      const { success, orderLog } = await service.getSingleLogs(id)

      if (!success && !_.isEmpty(orderLog)) {
        setLoader(false)
        return
      }

      setDetailData({
        customer: orderLog?.response.customer?.username,
        deliveryDate: orderLog?.response.deliveryDate,
        description: orderLog?.response.description,
        karat: orderLog?.response.karat.karat,
        orderCreatedAt: orderLog?.response.createdAt,
        orderId: orderLog?.orderId,
        patent: orderLog?.response.patent?.patent,
        seller: orderLog?.response.seller?.name,
      })

      // @ts-ignore
      setOrderLog(orderLog?.response)

      // set footer data
      setFooterData({
        piecesCount: orderLog?.response.pieceIds!.length,
        productsCount: orderLog?.response.productIds.length,
        totalWeight: orderLog?.response.weight,
        accurateWeight: orderLog?.response.accurateWeight,
      })

      setLoader(false)
    },

    [id],
  )

  useEffect(() => {
    fetchSingleOrderLog()
  }, [fetchSingleOrderLog])

  return {
    get: { detailData, orderLog, footerData },
    set: {},
    on: {},
  }
}
