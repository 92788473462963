import { ModalProps } from 'antd/lib/modal'
import { Icon } from 'common/components/ui-kit/icon'
import { DOM } from 'common/helpers/dom'
import { doneTaskSuccessfully } from 'common/helpers/doneTaskSuccessfully'
import { useQuery } from 'common/helpers/getQueryParam'
import { typeLocalizer } from 'common/helpers/localization'
import { useAsyncEffect } from 'common/hooks/use-async-effect'
import { useLoader } from 'common/hooks/use-loader'
import { CustomerService, OptionService, ProductSubCategory, SubPiecesService, UserService } from 'common/services'
import { Order } from 'common/services/lib/types'
import { EditOrCreateOrderType, GroupedByProducts } from 'common/services/lib/types/orders'
import { FullProduct } from 'common/services/lib/types/product'
import { SubCategory } from 'common/services/lib/types/product-sub-category'
import { OrdersService } from 'common/services/orders'
import _ from 'lodash'
import * as OrderDetailLib from 'pages/private/orders/orderDetail/lib'
import * as ProductLib from 'pages/private/products/list/lib'
import { ChangeEvent, FormEvent, ReactNode, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert'
import * as Lib from '.'
import { tableInnerListHeight as listHeight } from './constants'
import { ImageDetail } from 'common/components/ui-kit/image-list-slider/lib/typing'

export const useDetailNumbers = ({ defaultValue, onChange }: Pick<Lib.T.DetailNumbersProps, 'defaultValue' | 'onChange'>) => {
  const [number, setNumber] = useState<number>(defaultValue)
  const NUMBERS = [0, 1, 2, 3, 4]

  const getValueFromInput = (evt: FormEvent<HTMLInputElement>) => {
    const value = parseInt(evt.currentTarget.value)
    const initialValue = value

    setNumber(initialValue)
    if (onChange) {
      onChange(initialValue)
    }
  }

  const getValueFromItems = (num: number) => {
    setNumber(num)
    if (onChange) {
      onChange(num)
    }
  }
  useEffect(() => {
    setNumber(defaultValue)
  }, [defaultValue])
  return {
    NUMBERS,
    number,
    getValueFromInput,
    getValueFromItems,
  }
}

export const useExtraDetailsFilter = () => {
  const { setLoader } = useLoader()

  //necklace sizes and sets
  const [NecklaceSize, setNecklaceSize] = useState<any[]>([])
  const [selectedNecklacePiece, setSelectedNecklacePiece] = useState<number>(0)
  //bracelet sizes and sets
  const [braceletSize, setBraceletSize] = useState<any[]>([])
  const [selectedBraceletPiece, setSelectedBraceletPiece] = useState<number>(0)
  //ring sizes and sets
  const [RingSize, setRingSize] = useState<any[]>([])
  const [selectedRingPiece, setSelectedRingPiece] = useState<number>(0)
  //pendant sizes and sets
  const [selectedPendantPiece, setSelectedPendantPiece] = useState<number>(0)
  //bangle sizes and sets
  const [selectedBanglePiece, setSelectedBanglePiece] = useState<number>(0)
  //ear-ring pieces
  const [selectedEaringPiece, setSelectedEaringPiece] = useState<number>(0)
  //price and color
  const [productColorList, setProductColorList] = useState<any[]>([])

  const fetchNecklaceSizes = async () => {
    const service = new OptionService({ type: 'necklace-size' })
    setLoader(true)

    const { success, total, options } = await service.read()

    if (success) {
      const necklaces = options?.map(option => {
        return {
          id: option?.id.toString(),
          title: option?.size,
        }
      })

      setNecklaceSize(necklaces!)
      setLoader(false)
    }
    setLoader(false)
  }

  const fetchRingSizes = async () => {
    const service = new OptionService({ type: 'ring-size' })
    setLoader(true)

    const { success, total, options } = await service.read()

    if (success) {
      const rings = options?.map(option => {
        return {
          id: option?.id.toString(),
          title: option?.size,
        }
      })
      setRingSize(rings!)
      setLoader(false)
    }
    setLoader(false)
  }

  const fetchColorsList = async () => {
    const service = new OptionService({ type: 'made-color' })

    const { success, total, options } = await service.read()

    if (success) {
      const colorsList = options?.map(option => {
        return {
          id: option?.id.toString(),
          title: option?.madeTitle,
        }
      })
      setProductColorList(colorsList!)
    }
  }

  const fetchBraceletSizes = async () => {
    const service = new OptionService({ type: 'bracelet-size' })
    setLoader(true)

    const { success, total, options } = await service.read()

    if (success) {
      const bracelets = options?.map(option => {
        return {
          id: option?.id.toString(),
          title: option?.size,
        }
      })
      setBraceletSize(bracelets!)
      setLoader(false)
    }
    setLoader(false)
  }

  useEffect(() => {
    fetchNecklaceSizes()
  }, [selectedNecklacePiece])
  useEffect(() => {
    fetchBraceletSizes()
  }, [selectedBraceletPiece])

  useEffect(() => {
    fetchRingSizes()
  }, [selectedRingPiece])

  useAsyncEffect(fetchColorsList, [])

  return {
    get: {
      selectedNecklacePiece,
      NecklaceSize,
      selectedBraceletPiece,
      braceletSize,
      selectedRingPiece,
      RingSize,
      selectedEaringPiece,
      selectedBanglePiece,
      selectedPendantPiece,
      productColorList,
    },
    set: {
      setSelectedNecklacePiece,
      setBraceletSize,
      setSelectedBraceletPiece,
      setSelectedRingPiece,
      setSelectedPendantPiece,
      setSelectedBanglePiece,
      setSelectedEaringPiece,
      setProductColorList,
    },
    on: {
      fetchBraceletSizes,
      fetchRingSizes,
      fetchColorsList,
    },
  }
}

export const useTable = () => {
  const query = useQuery()
  const isFirstRender = useRef(true)

  const [isTestOrder, setIsTestOrder] = useState(false)
  const [isPersonalOrder, setIsPersonalOrder] = useState(false)

  const [data, setData] = useState<(string | JSX.Element | ReactNode)[][]>([[]])
  const [monacoData, setMonacoData] = useState<(string | JSX.Element | ReactNode)[][]>([[]])
  const [subCategoryData, setSubCategoryData] = useState<(string | JSX.Element | ReactNode)[][]>([[]])
  const [subCategoryMonacoData, setSubCategoryMonacoData] = useState<(string | JSX.Element | ReactNode)[][]>([[]])
  const [fullDescription, setFullDescription] = useState<string>('')
  const [descriptionModal, setDescriptionModal] = useState<boolean>(false)
  const [trashView, setTrashView] = useState<boolean>(false)
  const [monacoTrashView, setMonacoTrashView] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [editView, setEditView] = useState<boolean>(false)
  const [monacoEditView, setMonacoEditView] = useState<boolean>(false)
  const [productToEdit, setProductToEdit] = useState<number>(0)
  const editModal = ProductLib.H.useCommonAddToCart() //from another file
  const [imageModal, setImageModal] = useState<boolean>(false)
  const [imageActiveIndex, setImageActiveIndex] = useState(0)
  const [imageSliderImageDetail, setImageSliderImageDetail] = useState<ImageDetail[]>([])
  const [categories, setCategories] = useState<any>([])
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(-1)
  const [subCategories, setSubCategories] = useState<SubCategory[]>([])
  const [selectedSubCategoriesIndex, setSelectedSubCategoriesIndex] = useState<number>(-1)
  const [imageInModal, setImageInModal] = useState<string>('')
  const [imageView, setImageView] = useState<boolean>(true)
  const [carat, setCarat] = useState<number>()
  const [carats, setCarats] = useState<{ id: number; title: string }[]>([])
  const [customers, setCustomers] = useState<{ id: number; title: string }[]>([])
  const [patents, setPatents] = useState<{ id: number; title: string }[]>([])
  const [sellers, setSellers] = useState<{ id: number; title: string }[]>([])
  const [isAyarSubmitted, setIsAyarSubmitted] = useState(false)
  const [addToCartColors, setAddToCartColors] = useState<ProductLib.T.AddToCartStates[]>([])
  const [addToCartEnamels, setAddToCartEnamels] = useState<ProductLib.T.AddToCartStates[]>([])
  const [statesContainer, setStatesContainer] = useState<ProductLib.T.AddToCartStatesContainer[]>([])
  const [addToCartPieces, setAddToCartPieces] = useState<ProductLib.T.TableRows[]>([])
  const [customer, setCustomer] = useState<number>()
  const [patent, setPatent] = useState<number>()
  const [seller, setSeller] = useState<number>()
  const [productCategoryState, setProductCategoryState] = useState('')
  const [categorySearchReseter, setCategorySearchReseter] = useState({
    showMonaco: true,
    showOthers: true,
  })

  //number of pieces each product has
  const [numberOfPieces, setNumberOfPieces] = useState<number[]>([])
  const [monacoNumberOfPieces, setMonacoNumberOfPieces] = useState<number[]>([])
  //variant copies of products
  const [variants, setVariants] = useState<
    | {
        color: number
        description: string
        enamel: number[]
        id: number
        image: string
        minMaxPrice: string
        name: string
        number: number
        price: number
        productCode: string
        size: number
        weight: number
      }[]
    | []
  >([])
  const [testingProp, setTestingProp] = useState(false)
  const [totalVariantsWeight, setTotalVariantsWeight] = useState<number[]>([])
  //order after being filtered by category or sub category
  const [piecesIds, setPiecesIds] = useState<number[]>([])
  const [pieceIdType, setPieceIdType] = useState<{ type: string; id: number }[]>()
  const [productIds, setProductIds] = useState<number[]>([])
  const [AmountOfPieces, setAmountOfPieces] = useState<number[]>([])
  const [date, setDate] = useState<string | undefined>('')
  //reason for deleting a product
  const [orderWeight, setOrderWeight] = useState<number | undefined>(undefined)
  const [description, setDescription] = useState<string | undefined>('')
  const [SWK, setSWK] = useState<boolean>(false)
  const { setLoader } = useLoader()
  const [rawOrder, setRawOrder] = useState<Order.OrderInGetCard>()
  const [enamelsModal, setEnamelsModal] = useState<Lib.T.Enamel[]>()
  const TABLE_ID_INDEX = 10
  const MONACO_TABLE_ID_INDEX = 9
  const history = useHistory()

  //profits
  const [orderId, setOrderId] = useState(0)
  const [groupProducts, setGroupProducts] = useState<GroupedByProducts[]>([])
  const [orderTotalWeight, setOrderTotalWeight] = useState(0)
  const [orderTotalProfit, setOrderTotalProfit] = useState(0)
  const [orderEfficiency, setOrderEfficiency] = useState(0)
  const [showProfitTable, setShowProfitTable] = useState(false)

  //filter categories states
  const filterDataInitialState = {
    categories: [],
    subCategories: [],
    fetchSingleOrder: () => {},
    productCategorySelected: { isSelected: false, productCategoryId: 0 },
    productSubCategorySelected: { isSelected: false, productSubCategoryId: 0 },
  }

  const [filterData, setFilterData] = useState<OrderDetailLib.T.FillterDataType>(filterDataInitialState)

  const [leavePopup, setLeavePopup] = useState(false)

  useEffect(() => {
    if (query.get('isTestProduct')) {
      setLeavePopup(true)
      setIsTestOrder(true)
    }
  }, [])

  const onStartup = async (refetchTableOnly = false) => {
    const colors = getAddToCartColors()
    const enamels = getAddToCartEnamels()
    const patents = getPatents()
    const customer = getCustomers()
    const order = fetchOrder()
    if (!refetchTableOnly) {
      setLoader(true)

      await colors
      await enamels
      await patents
      await customer
      setLoader(false)
    }
    setLoader(true)

    await order

    setLoader(false)
  }

  const fetchOrder = async (productCategory?: number, productSubCategory?: number) => {
    const ordersService = new OrdersService()
    setLoader(true)

    const { success, order } = await ordersService.getCart({ productCategory, productSubCategory })
    if (!success || !order) {
      setLoader(false)

      return
    }
    const dataForPieceIdTypes: { type: string; id: number }[] = []
    order.products.forEach(prod => {
      prod.pieces.forEach(piece => {
        dataForPieceIdTypes.push({ id: piece.id, type: piece.type as string })
      })
    })

    setPieceIdType(dataForPieceIdTypes)

    setCustomer(order.customer?.id)

    order.submittedAyar && setCarat(order.customer ? order.karat?.id : undefined)

    setPatent(order.patent?.id)
    setCategories(order?.productCategories)
    setPatent(order.patent?.id)
    setDate(order?.deliveryDate)
    setCustomer(order.customer?.id)
    setSWK(order?.swarovski!)
    setDescription(order.description!)
    setSeller(order.seller?.id)
    setIsAyarSubmitted(order.submittedAyar as boolean)

    setRawOrder(order)
    setOrderId(order.id)

    setOrderWeight(order.weight)
    setPiecesIds(order.pieceIds)
    setProductIds(order.productIds)
    setCategories(order?.productCategories)

    setFilterData(filterDataInitialState)

    const filterProductCategory = order?.productCategories.filter(cat => cat.id === productCategory)[0]
    if (filterProductCategory) {
      setFilterData(perval => ({ ...perval, subCategories: filterProductCategory.productSubCategories }))
    }

    // set image slider state
    const dataForImageModal: ImageDetail[] = order.products?.map(prod => ({
      image: prod.productImage as string,
      productImageThumbnail: prod.productImageThumbnail,
      productImageLink: prod.productImageLink,
      productCode: prod.productCode,
      weight: prod.weight.toFixed(2).toString(),
      chainImage: prod.productSubCategoryType?.image,
      lockImage: prod.lock?.image,
    }))!

    setImageSliderImageDetail(perv => [...perv, ...dataForImageModal])

    // setSubCategories

    const tempData: Lib.T.TableDataType[] = []

    order.products.forEach((product, productIndex: number) => {
      const names: string[] = []
      const colors: string[] = []
      const counts: number[] = []
      const heights: string[] = []
      const widths: any = []
      const descriptions: string[] = []
      const prices: string[] = []
      const makeups: Lib.T.MakeUpType[] = []
      const dots: ReactNode[] = []
      const pieceImages: string[] = []
      const enamels: Lib.T.Enamel[][] = []
      const ids: number[] = []

      product.pieces.forEach((piece, index: number) => {
        names.push(typeLocalizer(piece.type))
        counts.push(piece?.count)
        if (product.category === 'monaco') {
          heights.push(piece.size ? `${piece.size?.sizeInInches} "` : '--')
        } else {
          heights.push(piece.size ? `${piece.size?.size}` : '--')
        }
        widths.push(piece.width ? `${piece.width?.width} mm` : '--')
        colors.push(piece?.madeColor?.madeTitle ? piece?.madeColor?.madeTitle : '--')
        prices.push(`${piece?.price ?? 0} $`)
        makeups.push({ pattern: piece.hasKalem, gradient: piece.enamels.length > 0 })
        descriptions.push(piece.description)
        if (piece.description) {
          dots.push(
            <Lib.S.tableThreeDots onClick={() => toggleDescriptionModal(piece.description)}>
              <Icon name="menu_dots" color="#004E42" size={12} />
            </Lib.S.tableThreeDots>,
          )
        }

        pieceImages.push(piece.pieceImage)
        enamels.push(piece.enamels)
        ids.push(piece.id)
      })

      setAmountOfPieces((prev: number[]) => [...prev, product.pieces.length])
      setNumberOfPieces((prev: number[]) => [...prev, names.length])
      tempData.push([
        <Lib.C.TableInnerCheckbox minHeight={names.length * listHeight} checkboxCallback={checkboxes.toggleOrders} id={product.id} />,
        <Lib.C.TableInnerName
          subText={product.weight}
          onClick={() => {
            if (product.category === 'monaco') {
              setProductCategoryState(product.category)
            } else {
              setProductCategoryState(product.category)
            }
            handleOpenImageModal(productIndex)
          }}
          minHeight={names.length * listHeight}
          name={product.productCode}
          image={product.productImageThumbnail}
          imageAction={productImageAction}
          isMonacoProduct={product?.category === 'monaco'}
          monacoImage={{ chainImagePath: product?.productSubCategoryType?.image, lockImagePath: product?.lock?.image }}
        />,
        <Lib.C.TableInnerList
          list={names}
          trashView
          checkboxCallback={checkboxes.togglePieces}
          onNameClick={toggleImageModal}
          pieceImages={pieceImages}
          ids={ids}
        />,

        <Lib.C.TableInnerList list={counts} />,
        <Lib.C.TableInnerList list={widths} />,
        <Lib.C.TableInnerList list={heights} />,
        <Lib.C.TableInnerList list={colors} />,
        <Lib.C.TableMakeUp list={makeups} onGradientClick={() => setEnamelsModal(enamels[productIndex])} />,
        <Lib.C.TableInnerList list={prices} className="product-price" />,
        <Lib.C.TableInnerList list={descriptions} className="product-description" maxWidth={400} />,
        product.id,
      ])
    })

    setData(tempData)
    setLoader(false)
  }

  const addDeletedPieces = async (
    productId: number,
    data: { minPrice: number; maxPrice: number; productCode: string; productImage: string; productCategory: string },
  ) => {
    const service = new OrdersService()
    const { maxPrice, minPrice, productCategory, productCode, productImage } = data
    setLoader(true)
    const { success, pieces } = await service.addDeletedPieces(productId, orderId.toString())

    if (!success) {
      setLoader(false)
      return
    }

    const sizes: { title: string; id: number }[][] = []
    editModal.toggleShowModal(true)
    for (let i = 0; i < pieces!.length; i++) {
      const type = editModal.on.translatePieceType(pieces![i].type)
      if (type) {
        const size = await editModal.on.getAddToCartSizes(type)
        if (size) {
          sizes.push(
            size.map(j => {
              return { title: j.size! + '', id: j.id }
            }),
          )
        } else {
          sizes.push([])
        }
      } else {
        sizes.push([])
      }
    }

    setAddToCartPieces(perv => [
      ...perv,
      ...pieces!.map((p, i) => {
        return {
          name: p?.type,
          sizes: sizes[i],
          image: p?.pieceImage,
          weight: p.weight,
        }
      }),
    ])

    setStatesContainer(perv => [
      ...perv,
      ...pieces!.map((i, index) => {
        return {
          color: i.madeColor?.id,
          enamel: i.enamels.length > 0 ? i.enamels.map(j => j.id) : [],
          size: i.size?.id,
          number: i.count,
          description: i.description || '',
          price: i.price,
          id: i.id,
          minMaxPrice: minPrice + '_' + maxPrice,
          productCode: productCode,
          weight: i.weight,
          image: productImage,
          category: productCategory,
          multiple: i.type === 'bracelet' ? i.multiple : undefined,
          purity: rawOrder?.karat?.purity,
          savedPrice: i.savedPrice,
          canHasEnamel: i.canHasEnamel,
        }
      }),
    ])

    setLoader(false)
  }

  const descriptionModalProps: ModalProps = {
    footer: null,
    title: 'Tam tanım',
    visible: descriptionModal,
    onCancel: () => setDescriptionModal(false),
  }
  const productImageAction = (image: string) => {
    setImageInModal(image)
    setImageModal(true)
  }
  const deleteModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: deleteModal,
    onCancel: () => setDeleteModal(false),
    className: 'noHeaderModal',
  }

  const enamelsModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: enamelsModal !== undefined,
    onCancel: () => setEnamelsModal(undefined),
    className: 'noHeaderModal',
  }

  const toggleImageView = () => {
    const handlers = document.querySelectorAll('.imageViewHandler')!
    handlers.forEach(item => {
      const { classList } = item
      if (classList.contains('true')) {
        classList.remove('true')
      } else {
        classList.add('true')
      }
    })
    setImageView(p => !p)
  }

  const toggleDescriptionModal = (payloadDescription: string) => {
    setFullDescription(payloadDescription)
    setDescriptionModal(true)
  }

  const toggleImageModal = (payloadImage: string) => {
    setImageModal(true)
    setImageInModal(payloadImage)
  }

  const fixColumns = [
    'Ürün kod',
    'Parçalar',
    'Adet',
    'Genişlık',
    'Boy',
    'Renk',
    'Makyaj',
    'Fiyat',
    'Açıklama',
    rawOrder?.type && rawOrder?.type !== 'test' && <Lib.C.TableActionColumn
      style={{ marginRight: '18px' }}
      onEdit={() => setEditView(!editView)}
      trashView={trashView}
      onImageView={toggleImageView}
    />,
    <Lib.C.TableRemoveAction onTrash={() => setTrashView(!trashView)} editView={editView} trashView={trashView} />,
  ]

  let columnsWith = ['300px', '75px', '50px', '40px', '50px', '20px', '50px', '20px', '700px', '10px', '10px']

  const fixColumnWidths = trashView ? ['105px', ...columnsWith] : editView ? columnsWith : columnsWith

  const toggleInnerListTrashView = () => {
    const names = document.querySelectorAll('.trashView')
    names.forEach(item => {
      const { classList } = item
      if (classList.contains('true')) {
        classList.remove('true')
      } else {
        classList.add('true')
      }
    })
  }

  const monacoToggleInnerListTrashView = () => {
    const names = document.querySelectorAll('.trashView')
    names.forEach(item => {
      const { classList } = item
      if (classList.contains('true')) {
        classList.remove('true')
      } else {
        classList.add('true')
      }
    })
  }

  const checkboxes = {
    togglePieces: (evt: ChangeEvent<HTMLInputElement>) => {
      const parent = DOM.parentNode(evt.currentTarget, 4)!
      const directChildren = parent.querySelectorAll('span.directChild')!
      const inputs: HTMLInputElement[] = []
      const topParent = DOM.parentNode(parent, 3)!

      const orderCheckbox = topParent.querySelector('input.orderCheckbox')! as HTMLInputElement

      let isAllChecked = true
      directChildren.forEach(item => inputs.push(item.querySelector('input')!))
      inputs.forEach(item => {
        if (!item.checked) {
          isAllChecked = false
          return
        }
      })

      orderCheckbox.checked = isAllChecked
      checkboxes.isAllCheckedOrderInputs()
    },
    toggleOrders: (evt: ChangeEvent<HTMLInputElement>) => {
      const topParent = DOM.parentNode(evt.currentTarget, 6)!

      const pieceCheckboxes = topParent.querySelectorAll('input.pieceCheckbox')! as NodeListOf<HTMLInputElement>
      const payloadStatus = evt.currentTarget.checked
      pieceCheckboxes.forEach(item => (item.checked = payloadStatus))
      checkboxes.isAllCheckedOrderInputs()
    },
    toggleList: (evt: ChangeEvent<HTMLInputElement>) => {
      const allCheckboxes = document.querySelectorAll('input.pieceCheckbox, input.orderCheckbox')! as NodeListOf<HTMLInputElement>
      allCheckboxes.forEach(item => (item.checked = evt.currentTarget.checked))
    },
    isAllCheckedOrderInputs: () => {
      const orderCheckboxes = document.querySelectorAll('input.orderCheckbox')! as NodeListOf<HTMLInputElement>
      const listCheckbox = document.querySelector('input.listCheckbox') as HTMLInputElement
      let isAllChecked = true
      orderCheckboxes.forEach(item => {
        if (!item.checked) {
          isAllChecked = false
          return
        }
      })
      listCheckbox.checked = isAllChecked
    },
    uncheckAll: () => {
      const allCheckboxes = document.querySelectorAll(
        'input.pieceCheckbox, input.orderCheckbox, input.listCheckbox',
      )! as NodeListOf<HTMLInputElement>

      Array.from(allCheckboxes).forEach(item => (item.checked = false))
    },
    getChecked: (): { productIds: number[]; pieceIds: number[] } => {
      let pieceIds: number[] = []
      const productIds: number[] = []
      const listCheckbox = document.querySelector('input.listCheckbox') as HTMLInputElement
      const pieceCheckboxes = document.querySelectorAll('input.pieceCheckbox')! as NodeListOf<HTMLInputElement>
      const orderCheckbox = document.querySelectorAll('input.orderCheckbox')! as NodeListOf<HTMLInputElement>

      Array.from(orderCheckbox).forEach(item => {
        const { id } = item
        if (item.checked && id) {
          productIds.push(+id)
        }
      })

      if (!listCheckbox.checked) {
        Array.from(pieceCheckboxes).forEach(item => {
          const { id } = item
          if (item.checked && id) {
            pieceIds.push(+id)
          }
        })
        productIds.map(item => {
          const innerIds: number[] = []
          const productCheckbox = document.getElementById(item.toString())
          if (!productCheckbox) {
            return
          }
          const parent = DOM.parentNode(productCheckbox, 6)

          const innerCheckboxes = parent?.querySelectorAll('input.pieceCheckbox')! as NodeListOf<HTMLInputElement>
          Array.from(innerCheckboxes).map(i => innerIds.push(+i.id))
          innerIds.forEach(innerId => (pieceIds = pieceIds.filter(i => i !== innerId)))
        })
      }

      return {
        pieceIds,
        productIds,
      }
    },
  }

  const deleteItems = async () => {
    const { pieceIds: pieceI, productIds: productI } = checkboxes.getChecked()
    const orderService = new OrdersService()
    const args: Order.DeleteArgs = {}

    if (!rawOrder) return
    if (pieceI.length > 0) args.pieceIds = pieceI
    if (productI.length > 0) args.productIds = productI

    if (pieceI.length === 0 && productI.length === 0) {
      setDeleteModal(false)
      return swal({
        text: 'lütfen silmek için en az bir parça seçini.',
        buttons: [false],
        icon: 'error',
        timer: 2000,
      })
    }

    setLoader(true)
    const success = await orderService.deleteOrder(
      {
        productIds: args?.productIds,
        pieceIds: args?.pieceIds,
      },
      rawOrder.id,
    )
    if (success) {
      setData([])
      setTrashView(false)
      setMonacoTrashView(false)
      checkboxes.uncheckAll()
      setFilterData(filterDataInitialState)

      await onStartup(false)
      if (pieceI.length === piecesIds.length && productI === productIds) {
        resetForm()
      }
      setDeleteModal(false)
    }
    setLoader(false)
  }

  function resetForm() {
    setCustomer(undefined)
    setCarat(undefined)
    setPatent(undefined)
    setSeller(undefined)
    setDate(undefined)
    setDescription(undefined)
    setSWK(false)
  }

  const onRowClickToEdit = async (rowId: number) => {
    setProductToEdit(rowId)

    if (!rawOrder || !editView) {
      return
    }

    const foundProduct = rawOrder.products.filter(i => i.id === rowId)

    if (foundProduct.length === 0) {
      return
    }
    const product = foundProduct[0]

    const sizes: { title: string; id: number }[][] = []
    setLoader(true)
    for (let i = 0; i < product.pieces.length; i++) {
      const type = editModal.on.translatePieceType(product.pieces[i].type)
      if (type) {
        const size = await editModal.on.getAddToCartSizes(type)
        if (size) {
          sizes.push(
            size.map(j => {
              return { title: j.size! + '', id: j.id }
            }),
          )
        } else {
          sizes.push([])
        }
      } else {
        sizes.push([])
      }
    }
    setLoader(false)

    setAddToCartPieces(
      product.pieces.map((p, i) => {
        return {
          name: p?.type,
          sizes: sizes[i],
          image: p?.pieceImage,
          weight: p.weight,
        }
      }),
    )
    if (product.category !== 'monaco') {
      setStatesContainer(
        product.pieces.map((i, index) => {
          return {
            color: i.madeColor?.id,
            enamel: i.enamels.length > 0 ? i.enamels.map(j => j.id) : [],
            size: i.size?.id,
            number: i.count,
            description: i.description || '',
            price: i.price,
            id: i.id,
            minMaxPrice: product.priceCategory?.minPrice + '_' + product.priceCategory?.maxPrice,
            productCode: product.productCode,
            weight: i.weight,
            image: product.productImage,
            category: product.category,
            multiple: i.type === 'bracelet' ? i.multiple : undefined,
            purity: rawOrder.karat?.purity,
            savedPrice: i.savedPrice,
            canHasEnamel: i.canHasEnamel,
          }
        }),
      )
    } else {
      setStatesContainer(
        product.pieces.map((i, index) => {
          return {
            color: i?.madeColor?.id,
            // enamel: i?.enamels.map(j => j.id),
            size: i?.size?.id,
            number: i?.count,
            description: i?.description || '',
            price: i?.price,
            id: i?.id,
            minMaxPrice: product?.priceCategory?.minPrice + '-' + product?.priceCategory?.maxPrice,
            productCode: product?.productCode,
            weight: i?.weight,
            image: product?.productImage,
            category: product.category,
            purity: rawOrder.karat?.purity,
            savedPrice: i.savedPrice,
          }
        }),
      )
    }
    editModal.toggleShowModal(true)
  }

  const getPatents = async () => {
    const optionsService = new OptionService({ type: 'patent' })

    const { success, options } = await optionsService.read({ limit: 99999999999 })
    if (success && options) {
      setPatents(
        options?.map(item => {
          return {
            id: item.id,
            title: item.patent!,
          }
        }),
      )
    }
  }

  const fetchCategories = async () => {
    const service = new OptionService({ type: 'product-category' })

    setLoader(true)
    const { success, options } = await service.read()
    if (success) {
      setCategories(options!)
      setLoader(false)
    }
    setLoader(false)
  }

  const fetchSubCategories = async (mainCategoryId: number) => {
    const service = new ProductSubCategory()
    setLoader(true)
    const { success, data } = await service.getList()

    if (success && data!.length > 0) {
      const subCategoryOfMainCategory = data!.filter(cat => cat.parent.id === mainCategoryId)
      setSubCategories(subCategoryOfMainCategory!)
      setLoader(false)
    }
    setLoader(false)
  }

  const getCustomers = async () => {
    const customerService = new CustomerService()

    setLoader(true)

    const { success, customers } = await customerService.getList({ limit: 9999999999 })
    if (success && customers) {
      setCustomers(
        customers?.map(item => {
          return {
            id: item.id,
            title: item.company,
          }
        }),
      )
      setLoader(false)
    }
    setLoader(false)
  }

  const getAddToCartEnamels = async () => {
    const subPiecesService = new SubPiecesService('enamels')

    const { success, data } = await subPiecesService.read<{
      enamels: { id: number; color: string }[]
    }>(`limit=9999999`)
    if (!success || !data) {
      setLoader(false)

      return
    }
    setAddToCartEnamels(
      data.enamels.map(e => {
        return {
          id: e.id,
          title: e.color,
        }
      }),
    )
  }

  const getAddToCartColors = async () => {
    const optionService = new OptionService({ type: 'made-color' })

    const { success, options } = await optionService.read({
      limit: 99999999,
    })
    if (!success || !options) {
      setLoader(false)
      return
    }
    setAddToCartColors(
      options.map(o => {
        return {
          id: o.id,
          title: o.madeTitle!,
        }
      }),
    )
  }

  const submit = async (isForTest: boolean) => {
    const orderService = new OrdersService()

    if (isForTest) {
      if (!rawOrder?.products.length) {
        return swal({ text: 'Sepette ürün yokken sipariş oluşturamazsiniz.', dangerMode: true, icon: 'warning' })
      }

      if (!customer && !isTestOrder && !query.get('isTestProduct')) {
        return swal({ text: 'lütfen müşteri seçiniz.', dangerMode: true, icon: 'error' })
      }
      if (!patent) {
        return swal({ text: 'lütfen patenti seçiniz.', dangerMode: true, icon: 'error' })
      }
      if (!carat) {
        return swal({ text: 'lütfen bir ayar seçin.', dangerMode: true, icon: 'error' })
      }
      if (!date) {
        return swal({ text: 'lütfen bir tarih girin.', dangerMode: true, icon: 'error' })
      }
    }

    setLoader(true)

    const { success, order } = await orderService.newCreate({
      customer: customer as number,
      ...(date ? { deliveryDate: new Date(date).toISOString() } : undefined),
      description: description,
      karat: carat as number,
      patent: +patent!,
      swarovski: SWK,
      seller: seller!,
      type: isTestOrder ? 'test' : isPersonalOrder ? 'personnel' : 'customer',
      submit: isForTest,
    })

    setLeavePopup(false)
    setLoader(false)

    if (success && isForTest) {
      history.push('/orders/list?sort=Bekleyen')
    } else if (success && !isForTest) {
      await onStartup(true)

      if (order) {
        setCustomer(order.customer?.id)
        setCarat(order.customer ? order.karat?.id : undefined)
        setPatent(order.patent?.id)
        setCategories(order?.productCategories)
        setPatent(order.patent?.id)
        setDate(order?.deliveryDate)
        setSWK(order?.swarovski!)
        setDescription(order.description!)
        setSeller(order.seller?.id)
      }
    }
  }

  const fetchAllSellers = async () => {
    const service = new UserService()
    setLoader(true)
    const { success, users } = await service.getList({ group: 'Satış Temsilcisi', disabled: false })

    if (success && users?.length) {
      const transformedSellers = users.map(seller => {
        return { id: seller.id, title: seller.name }
      })
      setSellers(transformedSellers)
      setLoader(false)
    }
    setLoader(false)
  }

  const fetchAllKarats = async () => {
    const service = new OptionService({ type: 'karat' })
    setLoader(true)
    const { success, options } = await service.read()

    if (success && options?.length) {
      const transformedKarats = options.map(opt => {
        return { id: opt.id, title: opt.karat as string }
      })
      setCarats(transformedKarats)
      setLoader(false)
    }
    setLoader(false)
  }

  const fetchSellers = async () => {
    const service = new UserService()
    setLoader(true)
    const { success, total, sellers } = await service.getSellers(customer ?? 0)
    if (success) {
      const transformedSellers = sellers.map(seller => {
        return { id: seller.id, title: seller.name }
      })

      setSellers(transformedSellers)
      setLoader(false)
    }
    setLoader(false)
  }

  const fetchKarats = async () => {
    const service = new UserService()

    setLoader(true)
    const { success, total, karats } = await service.getKarats(customer ?? 0)
    if (success) {
      const transformedKarats = karats.map(karat => {
        return { id: karat.id, title: karat.karat }
      })

      setCarats(transformedKarats)
      setLoader(false)
    }
    setLoader(false)
  }

  const fetchProfits = async () => {
    const service = new OrdersService()
    setLoader(true)
    const { success, order } = await service.orderProfit(orderId)
    if (success) {
      setGroupProducts(order!.groupedByProducts)
      setOrderTotalWeight(order?.orderTotalWeight!)
      setOrderTotalProfit(order?.orderTotalProfit!)
      setOrderEfficiency(order?.orderEfficiency! ?? 0)
      setLoader(false)
    }
    setLoader(false)
  }

  const updateProductsData = async (upDatedData: any, filter?: { productCategory: number; productSubCategory: number }) => {
    const service = new OrdersService()
    const { success, order } = await service.editProduct(
      upDatedData,
      orderId,
      filter?.productCategory || filter?.productSubCategory ? filter : undefined,
    )
    if (success && !_.isEmpty(order)) {
      setRawOrder(order as any)
      setOrderId(order.id)
      setOrderWeight(order.weight)
      setProductIds(order.productIds)

      const tempData: Lib.T.TableDataType[] = []

      order.products.forEach((product: any, productIndex: number) => {
        const names: string[] = []
        const colors: string[] = []
        const counts: number[] = []
        const heights: string[] = []
        const widths: any = []
        const descriptions: string[] = []
        const rings: string[] = []
        const prices: string[] = []
        const makeups: Lib.T.MakeUpType[] = []
        const dots: ReactNode[] = []
        const pieceImages: string[] = []
        const enamels: Lib.T.Enamel[][] = []
        const ids: number[] = []
        product.pieces.forEach((piece: any, index: number) => {
          names.push(typeLocalizer(piece.type))
          counts.push(piece?.count)
          if (product.category === 'monaco') {
            heights.push(piece.size ? `${piece.size?.size} "` : '--')
          } else {
            heights.push(piece.size ? `${piece.size?.size}` : '--')
          }
          widths.push(piece.width ? `${piece.width?.width} mm` : '--')
          colors.push(piece?.madeColor?.madeTitle ? piece?.madeColor?.madeTitle : '--')
          rings.push(piece?.size?.size ? piece.size?.size + '' : '--')
          prices.push(`${piece?.price ?? 0} $`)
          makeups.push({ pattern: piece.hasKalem, gradient: piece.hasMine })
          descriptions.push(piece.description)
          if (piece.description) {
            dots.push(
              <Lib.S.tableThreeDots onClick={() => toggleDescriptionModal(piece.description)}>
                <Icon name="menu_dots" color="#004E42" size={12} />
              </Lib.S.tableThreeDots>,
            )
          }

          pieceImages.push(piece.pieceImage)
          enamels.push(piece.enamels)
          ids.push(piece.id)
        })

        setAmountOfPieces((prev: number[]) => [...prev, product.pieces.length])
        setNumberOfPieces((prev: number[]) => [...prev, names.length])
        tempData.push([
          <Lib.C.TableInnerCheckbox minHeight={names.length * listHeight} checkboxCallback={checkboxes.toggleOrders} id={product.id} />,
          <Lib.C.TableInnerName
            subText={product.weight}
            onClick={() => {}}
            minHeight={names.length * listHeight}
            name={product.productCode}
            image={product.productImage}
            imageAction={productImageAction}
            isMonacoProduct={product?.category === 'monaco'}
            monacoImage={{ chainImagePath: product?.productSubCategoryType?.image, lockImagePath: product?.lock?.image }}
          />,
          <Lib.C.TableInnerList
            list={names}
            trashView
            checkboxCallback={checkboxes.togglePieces}
            onNameClick={toggleImageModal}
            pieceImages={pieceImages}
            ids={ids}
          />,

          <Lib.C.TableInnerList list={counts} />,
          <Lib.C.TableInnerList list={widths} />,
          <Lib.C.TableInnerList list={heights} />,
          <Lib.C.TableInnerList list={colors} />,
          <Lib.C.TableMakeUp list={makeups} onGradientClick={() => setEnamelsModal(enamels[productIndex])} />,
          <Lib.C.TableInnerList list={prices} className="product-price" />,
          <Lib.C.TableInnerList list={descriptions} className="product-description" maxWidth={400} />,
          product.id,
        ])
      })

      setData(tempData)
      setLoader(false)
      return
    }
  }
  async function transformSizes(product: FullProduct) {
    const sizes: { title: string; id: number }[][] = []
    for (let i = 0; i < product.pieces.length; i++) {
      const type = editModal.on.translatePieceType(product.pieces[i].type)
      if (type) {
        const size = await editModal.on.getAddToCartSizes(type)
        if (size) {
          sizes.push(
            size.map(j => {
              return { title: j.size! + '', id: j.id }
            }),
          )
        } else {
          sizes.push([])
        }
      } else {
        sizes.push([])
      }
    }
    return sizes
  }

  const editProduct = async (isForGetSavedPrice?: boolean) => {
    if (!productToEdit || !rawOrder) {
      return
    }

    let canSendCall = { canDo: false, message: '' }

    if (variants && variants.length > 0) {
      statesContainer.forEach(i => {
        variants.forEach((v: any) => {
          if (v.id === i.id) {
            if (v.category === 'monaco') {
              if (
                i.number !== v.number ||
                !!i.description !== !!v.description ||
                i.color !== v.color ||
                i.size !== v.size.id ||
                i.price !== v.price
              ) {
                canSendCall = { canDo: true, message: '' }
              } else {
                canSendCall = {
                  canDo: false,
                  message: 'ürün parçası güncellenemiyor lütfen varyantın ve orijinal parçanın aynı olmadığından emin olun.',
                }
              }
            } else {
              if (
                i.number !== v.number ||
                i.description !== v.description ||
                i.color !== v.color ||
                JSON.stringify(i.enamel) !== JSON.stringify(v.enamel) ||
                i.size !== v.size ||
                i.price !== v.price
              ) {
                canSendCall = { canDo: true, message: '' }
              } else {
                canSendCall = {
                  canDo: false,
                  message: 'ürün parçası güncellenemiyor lütfen varyantın ve orijinal parçanın aynı olmadığından emin olun.',
                }
              }
            }
            if (!v.number) {
              canSendCall = { canDo: false, message: 'Adet alanı boş olamaz, lütfen bir sayı giriniz.' }
            }
          }
        })
      })
    } else if (variants!.length === 0) {
      canSendCall = { canDo: true, message: '' }
    }

    const ordersService = new OrdersService()
    let success: any
    if (canSendCall.canDo) {
      const pieces = statesContainer.map(i => {
        if (variants!.length > 0) {
          const variantsToCreate = variants
            .filter(vari => vari.id === i.id)
            .map((vari: any) => {
              if (i.category !== 'monaco') {
                return {
                  size: vari?.size,
                  madeColor: vari?.color,
                  ...(vari?.enamel?.length > 0 ? { enamels: vari.enamel } : null),
                  count: vari?.number,
                  price: parseFloat(vari?.price.toString().replace(',', '.')),
                  description: vari?.description,
                }
              } else {
                return {
                  size: vari?.size.id,
                  madeColor: vari?.color,
                  count: vari?.number,
                  price: parseFloat(vari?.price.toString().replace(',', '.')),
                  description: vari?.description,
                }
              }
            })

          if (i.category !== 'monaco') {
            return {
              count: i.number,
              description: i.description,
              ...(i?.enamel?.length > 0 ? { enamels: i.enamel } : { enamels: [] }),
              id: i.id,
              madeColor: i.color,
              price: parseFloat(i?.price.toString().replace(',', '.')),
              size: i.size,
              ...(variantsToCreate.length > 0 ? { variants: variantsToCreate } : null),
            }
          } else {
            return {
              count: i.number,
              description: i.description,
              id: i.id,
              madeColor: i.color,
              price: parseFloat(i?.price.toString().replace(',', '.')),
              size: i.size,
              ...(variantsToCreate.length > 0 ? { variants: variantsToCreate } : null),
            }
          }
        } else {
          if (i.category !== 'monaco') {
            return {
              count: i.number,
              description: i.description,
              ...(i?.enamel?.length > 0 ? { enamels: i.enamel } : { enamels: [] }),
              id: i.id,
              madeColor: i.color,
              price: parseFloat(i?.price.toString().replace(',', '.')),
              ...(i.size ? { size: i.size } : null),
            }
          } else {
            return {
              count: i.number,
              description: i.description,
              id: i.id,
              madeColor: i.color,
              price: parseFloat(i?.price.toString().replace(',', '.')),
              ...(i.size ? { size: i.size } : null),
            }
          }
        }
      })
      setLoader(true)
      success = await ordersService.editProduct(
        {
          productId: productToEdit,
          pieces,
        },
        rawOrder.id,
      )
    } else if (!canSendCall.canDo) {
      return swal({
        text: canSendCall.message,
        dangerMode: true,
        icon: 'error',
      })
    }

    if (success?.success) {
      if (isForGetSavedPrice) {
        const product = success.order?.products.find((prod: any) => prod.id === productToEdit)

        if (_.isEmpty(product)) return

        const sizes = await transformSizes(product)

        if (product.category !== 'monaco') {
          setAddToCartPieces(
            product.pieces.map((p: any, i: number) => {
              return {
                name: p?.type,
                sizes: sizes[i],
                image: p?.pieceImage,
                weight: p.weight,
              }
            }),
          )

          setStatesContainer(
            product.pieces.map((i: any, index: number) => {
              return {
                color: i.madeColor?.id,
                enamel: i.enamels.length > 0 ? i.enamels.map((j: any) => j.id) : [],
                size: i.size?.id,
                number: i.count,
                description: i.description || '',
                price: i.price,
                id: i.id,
                minMaxPrice: product.priceCategory?.minPrice + '_' + product.priceCategory?.maxPrice,
                productCode: product.productCode,
                weight: i.weight,
                image: product.productImage,
                category: product.category,
                multiple: i.type === 'bracelet' ? i.multiple : undefined,
                purity: rawOrder.karat?.purity,
                savedPrice: i.savedPrice,
                canHasEnamel: i.canHasEnamel,
              }
            }),
          )
        } else if (product.category === 'monaco') {
          setStatesContainer(
            product.pieces.map((i: any, index: number) => {
              return {
                color: i?.madeColor?.id,
                size: i?.size?.id,
                number: i?.count,
                description: i?.description || '',
                price: i?.price,
                id: i?.id,
                minMaxPrice: product?.priceCategory?.minPrice + '-' + product?.priceCategory?.maxPrice,
                productCode: product?.productCode,
                weight: i?.weight,
                image: product?.productImage,
                category: product.category,
                purity: rawOrder.karat?.purity,
                savedPrice: i.savedPrice,
              }
            }),
          )
        }
        setVariants([])
      } else {
        editModal.toggleShowModal(false)
        setStatesContainer([])
        setMonacoEditView(false)
        setVariants([])
      }
      await onStartup(true)

      doneTaskSuccessfully()
      setLoader(false)
    } else {
      swal({
        text: 'ürün parçası güncellenemiyor lütfen varyantın ve orijinal parçanın aynı olmadığından emin olun.',
        dangerMode: true,
        icon: 'error',
      })
      setLoader(false)
    }
    setLoader(false)
  }

  const onCreateVariant: any = (stateItem: any, item: any) => {
    if (!stateItem) return
    // we want color and number be 0 after we create variants
    let itemCopy = item
    itemCopy.color = undefined
    itemCopy.number = undefined

    setVariants((prev: any) => [
      ...prev,
      {
        ...stateItem,
        ...itemCopy,
      },
    ])
    setTotalVariantsWeight((prev: number[]) => [...prev, stateItem?.weight * stateItem?.number])
  }

  const removeVariants = () => {
    setVariants([])
  }

  const onDeleteVariant = (index: number, array: any) => {
    let altVariants = array
    altVariants.splice(index, 1)
    let altTotalVariantsWeight = totalVariantsWeight
    altTotalVariantsWeight.splice(index, 1)

    setVariants(altVariants)
    setTotalVariantsWeight(altTotalVariantsWeight)
  }

  const onChangeVariantValues = (val: any, itemIndex: number, type: string) => {
    let secondaryVariants = variants
    secondaryVariants!.map((v: any, index: number) => {
      if (index === itemIndex) {
        if (type === 'number') {
          const secondaryVariantWeights = totalVariantsWeight
          secondaryVariants![index].number = val
          secondaryVariantWeights[index] = val * v.weight
          setTotalVariantsWeight(secondaryVariantWeights)
          setTestingProp((prev: boolean) => !prev)
        } else if (type === 'color') {
          secondaryVariants![index].color = val
        } else if (type === 'size') {
          secondaryVariants![index].size = val
        } else if (type === 'enamel') {
          secondaryVariants![index].enamel = val
        } else if (type === 'price') {
          secondaryVariants[index].price = val
        } else if (type === 'description') {
          secondaryVariants![index].description = val
        }
      }
    })

    setVariants(secondaryVariants)
    setTestingProp((prev: boolean) => !prev)
  }

  const editOrder = async (arg: EditOrCreateOrderType, id: number) => {
    const ordersService = new OrdersService()
    setLoader(true)

    await ordersService
      .edit(arg, orderId)
      .then(async order => {
        if (order) {
          setOrderWeight(order.weight)
          const tempData: Lib.T.TableDataType[] = []

          order.products.forEach((product, productIndex: number) => {
            const names: string[] = []
            const colors: string[] = []
            const counts: number[] = []
            const heights: string[] = []
            const widths: any = []
            const descriptions: string[] = []
            const prices: string[] = []
            const makeups: Lib.T.MakeUpType[] = []
            const dots: ReactNode[] = []
            const pieceImages: string[] = []
            const enamels: Lib.T.Enamel[][] = []
            const ids: number[] = []
            product.pieces.forEach((piece, index: number) => {
              names.push(typeLocalizer(piece.type))
              counts.push(piece?.count)
              if (product.category === 'monaco') {
                heights.push(piece.size ? `${piece.size?.sizeInInches} "` : '--')
              } else {
                heights.push(piece.size ? `${piece.size?.size}` : '--')
              }
              widths.push(piece.width ? `${piece.width?.width} mm` : '--')
              colors.push(piece?.madeColor?.madeTitle ? piece?.madeColor?.madeTitle : '--')
              prices.push(`${piece?.price ?? 0} $`)
              makeups.push({ pattern: piece.hasKalem, gradient: piece.enamels.length > 0 })
              descriptions.push(piece.description)
              if (piece.description) {
                dots.push(
                  <Lib.S.tableThreeDots onClick={() => toggleDescriptionModal(piece.description)}>
                    <Icon name="menu_dots" color="#004E42" size={12} />
                  </Lib.S.tableThreeDots>,
                )
              }

              pieceImages.push(piece.pieceImage)
              enamels.push(piece.enamels)
              ids.push(piece.id)
            })

            setAmountOfPieces((prev: number[]) => [...prev, product.pieces.length])
            setNumberOfPieces((prev: number[]) => [...prev, names.length])
            tempData.push([
              <Lib.C.TableInnerCheckbox minHeight={names.length * listHeight} checkboxCallback={checkboxes.toggleOrders} id={product.id} />,
              <Lib.C.TableInnerName
                subText={product.weight}
                onClick={() => {
                  if (product.category === 'monaco') {
                    setImageInModal(`${product.productSubCategoryType.image},${product.lock.image}`)
                    setProductCategoryState(product.category)
                  } else {
                    setImageInModal(product.productImage)
                    setProductCategoryState(product.category)
                  }
                  setImageModal(true)
                }}
                minHeight={names.length * listHeight}
                name={product.productCode}
                image={product.productImage}
                imageAction={productImageAction}
                isMonacoProduct={product?.category === 'monaco'}
                monacoImage={{ chainImagePath: product?.productSubCategoryType?.image, lockImagePath: product?.lock?.image }}
              />,
              <Lib.C.TableInnerList
                list={names}
                trashView
                checkboxCallback={checkboxes.togglePieces}
                onNameClick={toggleImageModal}
                pieceImages={pieceImages}
                ids={ids}
              />,

              <Lib.C.TableInnerList list={counts} />,
              <Lib.C.TableInnerList list={widths} />,
              <Lib.C.TableInnerList list={heights} />,
              <Lib.C.TableInnerList list={colors} />,
              <Lib.C.TableMakeUp list={makeups} onGradientClick={() => setEnamelsModal(enamels[productIndex])} />,
              <Lib.C.TableInnerList list={prices} className="product-price" />,
              <Lib.C.TableInnerList list={descriptions} className="product-description" maxWidth={400} />,
              product.id,
            ])
          })

          setData(tempData)
        } else {
          swal({ text: 'Karat seçmeden önce ürünü sepete ekleyiniz.', dangerMode: true, icon: 'error' })
        }
      })
      .finally(() => setLoader(false))
  }

  const imageModalProps: ModalProps = {
    footer: null,
    title: null,
    visible: imageModal,
    width: '50%',
    style: { top: '20px', height: '90vh' },
    bodyStyle: { padding: 0 },
    onCancel: () => setImageModal(false),
    className: 'noHeaderModal scrollWidthModal',
  }

  const handleOpenImageModal = (index: number) => {
    setImageModal(true)
    setImageActiveIndex(index)
  }

  const getPersonnelCustomers = async () => {
    const service = new CustomerService()

    try {
      setLoader(true)
      const { customers } = await service.getList({ type: 'personnel' })

      const transformedCustomers = customers.map(customer => {
        return { id: customer.id, title: customer.company }
      })
      setCustomers(transformedCustomers)
      setLoader(false)
    } catch (e) {
      setLoader(false)
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    }
    setFilterData(perval => ({ ...perval, fetchSingleOrder: fetchOrder }))
  }, [])

  useEffect(() => {
    if (isPersonalOrder) {
      getPersonnelCustomers()
    } else if (!isPersonalOrder && !isFirstRender.current) {
      getCustomers()
    }
  }, [isPersonalOrder])

  useEffect(() => {
    setVariants([])
  }, [editModal.showModal])

  useAsyncEffect(onStartup, [])

  useEffect(() => {
    if (query.get('isTestProduct')) {
      fetchAllKarats()
      fetchAllSellers()
    }
  }, [query])

  useEffect(() => {
    if (customer && !isPersonalOrder) {
      fetchSellers()
      fetchKarats()
    }
  }, [customer])

  useEffect(toggleInnerListTrashView, [trashView])
  useEffect(monacoToggleInnerListTrashView, [monacoTrashView])

  return {
    table: {
      data,
      monacoData,
      fixColumns,
      fixColumnWidths,
      testingProp,
      leavePopup,
    },
    descriptionModal: {
      props: descriptionModalProps,
      text: fullDescription,
    },
    profitTable: {
      on: {
        fetchProfits,
        fetchSubCategories,
        // onCategoryChange,
        updateProductsData,
      },
      set: {
        setShowProfitTable,
        setSelectedCategoryIndex,
        setSelectedSubCategoriesIndex,
        setFilterData,
        setEnamelsModal,
        setRawOrder,
        setOrderId,
        setOrderWeight,
        setPiecesIds,
        setProductIds,
        setFullDescription,
        setDescriptionModal,
        setImageInModal,
        setImageModal,
        setVariants,
        onCreateVariant,
        removeVariants,
        setAmountOfPieces,
        setNumberOfPieces,
        setMonacoNumberOfPieces,
        setMonacoData,
        setData,
        setCategorySearchReseter,
        setSubCategoryMonacoData,
        setSubCategoryData,
      },
      get: {
        groupProducts,
        productCategoryState,
        pieceIdType,
        orderTotalWeight,
        orderTotalProfit,
        orderEfficiency,
        orderId,
        filterData,
        showProfitTable,
        categories,
        subCategories,
        selectedCategoryIndex,
        selectedSubCategoriesIndex,
        piecesIds,
        productIds,
        checkboxes,
        rawOrder,
        numberOfPieces,
        monacoNumberOfPieces,
        variants,
        totalVariantsWeight,
        productToEdit,
        AmountOfPieces,
        categorySearchReseter,
        subCategorydata: subCategoryData,
        subCategorymonacoData: subCategoryMonacoData,
      },
    },
    enamelsModal: {
      props: enamelsModalProps,
      close: () => setEnamelsModal(undefined),
      data: enamelsModal,
    },
    trash: {
      visibility: trashView,
      toggle: () => setTrashView(!trashView),
    },
    monacoTrash: {
      visibility: monacoTrashView,
      toggle: () => setMonacoTrashView(!monacoTrashView),
    },
    checkboxes,
    deleteModal: {
      props: deleteModalProps,
      toggle: () => setDeleteModal(!deleteModal),
    },
    edit: {
      editView,
      onRowClickToEdit,
      editModal,
    },
    monacoEdit: {
      monacoEditView,
      editModal,
    },
    imageModal: {
      image: imageInModal,
      toggle: { get: imageModal, set: setImageModal },
      imageModalProps,
      imageActiveIndex,
      imageSliderImageDetail,
    },
    imageView: {
      val: imageView,
      set: setImageView,
      toggle: () => setImageView(!imageView),
    },
    values: {
      isAyarSubmitted,

      carat: {
        val: carat,
        set: (value: number | undefined) => {
          setCarat(value)
          setIsAyarSubmitted(true)
          editOrder({ karat: value }, rawOrder?.id!)
        },
        list: carats,
      },
      patent: { val: patent, set: setPatent, list: patents },
      isTestOrder: { val: isTestOrder, set: setIsTestOrder },
      isPersonalOrder: { val: isPersonalOrder, set: setIsPersonalOrder },
      customer: { val: customer, set: setCustomer, list: customers },
      date: { val: date, set: setDate },
      description: { val: description, set: setDescription },
      SWK: { val: SWK, set: setSWK },
      TABLE_ID_INDEX,
      MONACO_TABLE_ID_INDEX,
      query,
      addToCartPieces: { val: addToCartPieces, set: setAddToCartPieces },
      addToCartColors: { val: addToCartColors, set: setAddToCartColors },
      addToCartEnamels: { val: addToCartEnamels, set: addToCartEnamels },
      statesContainer: { val: statesContainer, set: setStatesContainer },
      orderWeight,
      seller: {
        val: { seller, sellers },
        set: { setSeller, setSellers },
        list: sellers,
      },
    },
    on: {
      submit,
      editProduct,
      deleteItems,
      editOrder,
      onCreateVariant,
      onDeleteVariant,
      onChangeVariantValues,
      onStartup,
      fetchSellers,
      fetchKarats,
      fetchCategories,
      toggleInnerListTrashView,
      fetchAllSellers,
      monacoToggleInnerListTrashView,
      fetchAllKarats,
      addDeletedPieces,
    },
  }
}
