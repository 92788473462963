import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import swal from 'sweetalert'

export class AutomationCoffeeTime {
  accessToken: string
  endpoint = 'automation/restaurant/drink/coffee-time'

  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      AutomationCoffeeTime.dontAccept()
    }
    if (!user!.accessToken) {
      AutomationCoffeeTime.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async getDrinkTypes(): Promise<Lib.T.CoffeeTime.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
    })
    const { status, data } = response
    if (status !== 200 || !data) {
      swal({
        text: 'Grup listesine ulaşılamadı.',
        dangerMode: true,
        icon: 'error',
      })
      return { success: false }
    }
    return { success: true, coffeeTimes: data }
  }

  async deleteCoffeeTime(id: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint + `/${id}`),
      headers: { authorization: this.accessToken },
    })
    return status === 200
  }

  async createCoffeeTime(args: Lib.T.CoffeeTime.coffeeTimeArgs): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endpoint),
      headers: { authorization: this.accessToken },
      data: args,
    })
    return status === 201
  }

  static dontAccept() {
    throw 'not-acceptable'
  }

  static unhandled(errorMessage?: string) {
    if (errorMessage) {
      return swal({
        text: errorMessage,
        dangerMode: true,
        icon: 'error',
      })
    }
  }
}
