import styled from 'styled-components'

const TRANSITION = 'all 100ms linear'

export const checkboxContainer = styled('div')<{ checkedColor: string | undefined }>`
  float: left;
  width: 100%;
  height: 35px;
  user-select: none;

  > label {
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;

    > input {
      display: none;

      &:checked + span.visual {
        background: ${({ checkedColor }) => (checkedColor ? checkedColor : 'var(--accent)')};

        > svg {
          transform: none;
        }
      }
    }

    > span.label {
      float: left;
      height: 100%;
      padding: 7px 0 7px 10px;
    }

    > span.visual {
      float: left;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 3px solid rgb(0 0 0 / 10%);
      border-radius: 5px;
      margin: 8px 0;
      transition: ${TRANSITION};
      > svg {
        transition: ${TRANSITION};
        transform: scale(0) translate(-10px, 10px);
      }
    }
  }
  .disabled {
    color: #ccc;
    cursor: default;
  }
`
