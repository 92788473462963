import { createQuery as cq, mediaQueries as mq } from 'common/constants/media-queries'
import styled from 'styled-components'

const INFO_WIDTH = '150px'
const INFO_WIDTH_768PX = '65px'
const INFO_WIDTH_390PX = '17px'

const COLUMN_WIDTH = '100px'
const COLUMN_WIDTH_768PX = '60px'

const CHART_BOTTOM_PADDING = '40px'

const ROW_SIZE = '50px'

export const Wrapper = styled.div`
  .tabs {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-bottom: 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    font-weight: bold;
    background-color: #eeee;
  }
  .tab {
    border-right: 1px solid #ccc;
    padding-right: 10px;
    cursor: pointer;
    font-size: 0.9rem;
  }
  .active {
    color: var(--accent);
  }
`

export const chartContainer = styled.div`
  float: left;
  border: 1px solid #c4c4c4;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 100%;
  background-color: #f8f8f8;

  ${mq.max[576]} {
    padding-bottom: 45px;
  }

  > div.body {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;

    .chart-wrapper {
      height: 450px;
    }

    ${mq.max[768]} {
      width: calc(100% - ${INFO_WIDTH_768PX});

      > .header {
        > .rowName {
          width: ${COLUMN_WIDTH_768PX} !important;
          font-size: 10pt;
        }
      }
    }

    ${cq(390, 'max')} {
      width: calc(100% - ${INFO_WIDTH_390PX});
    }

    > .header {
      float: left;
      width: 100%;
      display: flex;
      height: 45px;
      flex-direction: row-reverse;

      > .rowName {
        width: ${COLUMN_WIDTH};
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: var(--accent);
        font-family: var(--f-se-bo);
      }

      ${cq(390, 'max')} {
        display: block;
        margin: 15px 0 0 0;

        > .rowName {
          width: 100% !important;
          float: left;
          display: block;
          padding: 0 0 0 9px;
          margin: 10px 0 0 0;
        }
      }

      > .buttons {
        flex: 1;
        align-items: center;
        justify-content: center;
        display: flex;

        > span {
          display: inline-block;
          padding: 0 15px;
          user-select: none;
          cursor: pointer;
          color: var(--accent);
          font-family: var(--f-se-bo);
          opacity: 0.5;
          transition: all 150ms linear;

          &.true {
            opacity: 1;
          }

          ${mq.max[768]} {
            font-size: 10pt;
          }
        }
      }
    }

    > .chart {
      float: left;
      width: 100%;
      height: auto;
      padding-bottom: ${CHART_BOTTOM_PADDING};
      position: relative;

      ${mq.max[768]} {
        margin-top: -30px;
      }

      > .rows {
        float: right;
        width: calc(100% - ${COLUMN_WIDTH});
        height: ${ROW_SIZE};
        position: relative;

        > svg {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }

        &::after {
          position: absolute;
          content: attr(data-value);
          left: -55px;
          text-align: right;
          min-width: 45px;
          bottom: -10px;
          color: #969696;
        }

        &[data-column]::before {
          content: attr(data-column);
          position: absolute;
          right: -65px;
          bottom: -29px;
          color: #11263c;
          font-family: var(--f-se-bo);

          ${cq(390, 'max')} {
            transform: rotate(-90deg);
            margin: 0 0 22px 0;
            right: -26px !important;
          }
        }

        ${mq.max[768]} {
          width: calc(100% - ${COLUMN_WIDTH_768PX});

          &::after {
            font-size: 10pt;
          }

          &[data-column]::before {
            font-size: 10pt;
            right: -50px;
          }
        }
      }

      > .columns {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        width: calc(100% - ${COLUMN_WIDTH});
        height: calc(100% - ${ROW_SIZE});
        display: flex;
        margin-top: ${ROW_SIZE};
        align-items: center;
        justify-content: center;
        padding-bottom: ${CHART_BOTTOM_PADDING};

        ${mq.max[768]} {
          width: calc(100% - ${COLUMN_WIDTH_768PX});
        }
      }
    }
  }

  > div.info {
    width: ${INFO_WIDTH};
    float: left;
    height: auto;
    text-align: center;
    padding: 25px 0;

    ${mq.max[768]} {
      display: none;
    }

    > span {
      width: 30px;
      display: block;
      height: 8px;
      border-radius: 50px;
      background-color: var(--accent);
      margin: 0 auto;
    }

    > p {
      margin: 10px 0 0 0;
      color: #11263c;
    }
  }
`
export const TableWrapper = styled.div`
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid #c4c4c4;
  padding: 10px;
  background-color: #f8f8f8;

  .filters {
    .dropdown {
      span {
        font-weight: bold;
        font-size: 0.9rem;
      }
      width: 100%;
      gap: 10px;
      display: flex;
      align-items: center;
    }

    width: 100%;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
  }

  table {
    width: 100%;

    thead {
      .header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
      }
      font-size: 0.9rem;
      tr {
        border-bottom: 1px solid #000;
      }
    }
    tbody {
      tr {
        font-weight: bold;
        font-size: 0.9rem;
        :first-child {
          td:first-child {
            border-top-left-radius: 7px;
          }
          td:last-child {
            border-top-right-radius: 7px;
          }
        }

        :last-child {
          td:first-child {
            border-bottom-left-radius: 7px;
          }
          td:last-child {
            border-bottom-right-radius: 7px;
          }
        }

        :nth-child(even) {
          background-color: #f3f3f3;
        }
      }

      td {
        text-align: center;
        .image {
          width: 40px;
          height: 40px;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
`

export const CategoryContainer = styled('section')<{ isCountryHasItems?: boolean; isCustomerHasItems?: boolean; isKaratItems: boolean }>`
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid #c4c4c4;
  background-color: #f8f8f8;

  .header {
    display: flex;
    margin: 10px 0 30px 0;
    padding: 0 10px;
    width: 100%;
    gap: 20px;
    align-items: start;

    .pie-btns {
      display: flex;
      gap: 5px;
    }

    .countries-multiple {
      .ant-select-selector {
        overflow-y: ${({ isCountryHasItems }) => (isCountryHasItems ? 'scroll' : 'unset')};
      }
    }
    .customers-multiple {
      .ant-select-selector {
        overflow-y: ${({ isCustomerHasItems }) => (isCustomerHasItems ? 'scroll' : 'unset')};
      }
    }
    .karats-multiple {
      .ant-select-selector {
        overflow-y: ${({ isKaratItems }) => (isKaratItems ? 'scroll' : 'unset')};
      }
    }

    .dropdown {
      span {
        font-weight: bold;
        font-size: 0.9rem;
      }
      width: 100%;
      gap: 10px;
      display: flex;
      align-items: center;
    }
  }

  .chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 700px;
    padding: 10px 10px;
    text {
      font-size: 13px !important;
      font-weight: bold;
    }

    .tooltip {
      padding: 10px;
      background-color: #fff;
    }

    path {
      cursor: pointer;
    }
    .detail {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 100%;
      > div {
        display: flex;
        gap: 5px;
        align-items: center;

        span {
          color: #979797;
        }
      }
      h3 {
        width: 100%;
        text-align: left;
        color: var(--accent);
      }
    }
  }

  .table {
    padding: 0 10px;
    height: 500px;
    overflow-y: scroll;

    .width {
      display: flex;
      align-items: center;
      gap: 5px;

      > div {
        width: 70px;
      }
    }

    table {
      width: 100%;
      border-style: unset !important;
      text-align: left;
      position: relative;

      thead {
        tr {
          border-style: unset !important;
          position: sticky;
          top: 0;
          z-index: 20000;

          background-color: #eeeeee;
          th {
            padding: 5px;
            border-style: unset !important;

            :first-child {
              border-top-left-radius: 7px;
            }

            :last-child {
              border-top-right-radius: 7px;
            }
          }
        }
      }

      tbody {
        tr {
          border-style: unset !important;

          :nth-child(even) {
            background-color: #e5edec;
          }
        }

        td {
          border-style: unset !important;

          padding: 2px 5px;
          border-style: unset;

          .image {
            display: flex;
            align-content: center;
            gap: 20px;
            cursor: pointer;
          }
        }

        .empty-table {
          display: flex;
          width: 100% !important;
          position: absolute;
          left: 0;

          top: 150px;
          padding: 0 !important;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 10px;

          span {
            font-weight: bold;
            color: var(--accent);
          }
        }
      }
    }
  }

  .footer {
    margin-right: 30px;
    text-align: right;
    .title {
      color: var(--accent);
      font-weight: bold;
    }
  }
`
