import { PanelWrapper } from 'common/components/layouts/panel/wrapper'
import { firstLetterUpperCase } from 'common/helpers/firstLetterUpperCase'
import { imageAddresser } from 'common/helpers/image.helper'
import * as Lib from './lib'

const FavoriteProduct = () => {
  const { get, set, on } = Lib.H.usePDF()

  function checkNumberType(number: number) {
    if (number % 1 === 0) {
      return true
    }
    return false
  }

  return (
    <PanelWrapper tab={['featured', '/featured/ad']} title="featured" hiddenSidebar noCircles backgroundColor="#fff">
      <Lib.S.PageWrapper>
        {get.favoriteAds?.length
          ? get.favoriteAds?.map((fav, index) => {
              return (
                <div className={`item ${index % 2 === 0 ? 'end' : 'start'}`} key={fav.favoriteProduct.id}>
                  <div className="logo">
                    <img src="/images/logo/new_green_logo.png" alt="" />
                  </div>
                  <div className="image-wrapper">
                    <div className="image">
                      <img src={imageAddresser(fav.favoriteProduct.productImage, false)} alt={fav.favoriteProduct.productCode} />
                    </div>

                    <div className="code">
                      <span>{fav.favoriteProduct.productCode}</span>
                      <span>{checkNumberType(fav.favoriteProduct.weight) ? fav.favoriteProduct.weight : fav.favoriteProduct.weight.toFixed(2)} gr</span>
                    </div>
                  </div>

                  <div className="info">
                    {fav.favoriteProduct.pieces.map(piece => {
                      let overWritePiece = piece.type

                      if (piece.type === 'ear-rings') {
                        overWritePiece = 'Earings' as any
                      }
                      if (piece.type === 'cuff') {
                        overWritePiece = 'Bangle' as any
                      }
                      return (
                        <span>
                          {firstLetterUpperCase(overWritePiece)}:{' '}
                          <span>{checkNumberType(piece.weight) ? piece.weight : piece.weight.toFixed(2)} gr</span>
                        </span>
                      )
                    })}
                  </div>
                </div>
              )
            })
          : ''}
      </Lib.S.PageWrapper>
    </PanelWrapper>
  )
}

export default FavoriteProduct
