import * as Lib from './lib'
import { ApiCaller } from 'common/helpers/api-caller.helper'
import { Storage } from 'common/helpers/storage'
import { SavedUser } from 'common/typing/saved-user.interface'
import { Crypto } from 'common/helpers/crypto.helper'
import { DOM } from 'common/helpers/dom'
import swal from 'sweetalert'

export class MonacoPieces {
  accessToken: string
  endPoint = 'monaco-pieces'
  monacoProducts = 'monaco-products'
  constructor() {
    const user = Storage.get<SavedUser>('user')
    if (!user || !user.accessToken) {
      MonacoPieces.dontAccept()
    }
    if (!user!.accessToken) {
      MonacoPieces.dontAccept()
    }
    this.accessToken = 'Bearer '.concat(<string>user!.accessToken!)
  }

  async create(createArg: Lib.T.MonacoPieces.CreateArgs): Promise<Lib.T.MonacoPieces.CreateResult> {
    const response = await ApiCaller({
      method: 'POST',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint),
      headers: { authorization: this.accessToken },
      data: {
        ...createArg,
      },
    })

    const { status, data } = response
    if (status !== 201 || !data.monacoPiece) {
      swal({
        title: 'Alt monaco pieces oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, data: data.monacoPiece }
  }

  async findOne(id: number): Promise<Lib.T.MonacoPieces.FindOneResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint).concat(`/${id}`),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response

    if (status !== 200) {
      swal({
        title: 'couldnt find a piece',
        icon: 'error',
      })
      return { success: false }
    }

    return { success: true, monacoPieces: data.monacoPiece }
  }

  async getList(subTypeId: number, args?: Lib.T.MonacoPieces.GetListArgs): Promise<Lib.T.MonacoPieces.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint).concat(
        args
          ? DOM.objectToSearch({
              subcategoryType: subTypeId,
              limit: args.limit || 99999999,
              ...args,
            })
          : `?subcategoryType=${subTypeId}&limit=9999999`,
      ),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response
    if (status !== 200 || !data.monacoPieces) {
      swal({
        title: 'Alt monacoPieces oluşturulamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, monacoPieces: data.monacoPieces }
  }

  async getExcel(subTypeId: number, args?: Lib.T.MonacoPieces.GetListArgs): Promise<Lib.T.MonacoPieces.ListResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(this.endPoint).concat(
        args
          ? DOM.objectToSearch({
            subcategoryType: subTypeId,
            limit: args.limit || 99999999,
            ...args,
          })
          : `?subcategoryType=${subTypeId}&limit=9999999`,
      ).concat(`&exportExcel=true`),
      headers: { authorization: this.accessToken, ContentType: 'blob' },
      responseType: 'arraybuffer'
    })
    const { status, data } = response
    if (status !== 200) {
      return { success: false, result: [] }
    }
    return { success: true, result: data }
  }

  async delete(id: number): Promise<boolean> {
    const { status } = await ApiCaller({
      method: 'DELETE',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endPoint}/${id}`),
      headers: { authorization: this.accessToken },
    })

    if (status !== 200) {
      swal({
        title: 'Alt Parça Silinemez !',
        icon: 'error',
        dangerMode: true,
      })
    }

    return status === 200
  }

  async edit(id: number, args: Lib.T.MonacoPieces.CreateArgs): Promise<Lib.T.MonacoPieces.EditResult> {
    const { status, data } = await ApiCaller({
      method: 'PUT',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endPoint}/${id}`),
      headers: { authorization: this.accessToken },
      data: args,
    })

    if (status !== 200 || !data.monacoPiece) {
      swal({
        text: 'Alt monaco pieces düzenlenemedi. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })

      return { success: false }
    }

    return { success: true, data: data.monacoPiece }
  }
  async getSubBaklaWithWidth(width: string): Promise<Lib.T.MonacoPieces.SubBaklaResult> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.endPoint}/sub-baklas/${width}`),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response
    if (status !== 200 || !data.subBaklas) {
      swal({
        title: 'Sub monaco-pieces-subBaklas alınamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false }
    }

    return { success: true, data: data.subBaklas }
  }

  async getLocksBaseOnCategoryTypeId(id: number): Promise<Lib.T.MonacoPieces.getLocksBaseOnCategoryTypeIdType> {
    const response = await ApiCaller({
      method: 'GET',
      url: process.env.REACT_APP_SERVER_URL!.concat(`${this.monacoProducts}/findLock/${id}`),
      headers: { authorization: this.accessToken },
    })

    const { status, data } = response
    if (status !== 200) {
      swal({
        title: 'locks alınamadı. Lütfen tekrar deneyin.',
        icon: 'error',
        dangerMode: true,
      })
      return { success: false, monacoProducts: [] }
    }

    return { success: true, monacoProducts: data.monacoProducts }
  }

  static dontAccept() {
    throw 'not-acceptable'
  }
}
