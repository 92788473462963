import styled from 'styled-components'
import { mediaQueries as mq } from 'common/constants/media-queries'

const INFO_WIDTH = '270px'

export const Container = styled.div`
  float: left;
  width: 100%;
  padding-top: 20px;

  ${mq.max[768]} {
    padding: 10px;
  }

  > .cards {
    float: left;
    width: 100%;
  }

  > .chart {
    float: left;
    width: 100%;
    padding: 10px;

    > .chartContainer {
      float: left;
      /* width: calc(100% - ${INFO_WIDTH}); */
      width: 100%;
      height: auto;
    }

    > .infoContainer {
      float: left;
      width: ${INFO_WIDTH};
      height: auto;
      padding: 0 0 0 10px;

      > div {
        background-color: white;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        padding: 10px;
        float: left;
        min-height: 337.86px;

        ${mq.max[1200]} {
          min-height: unset;

          > div {
            width: calc(100% / 3) !important;
          }
        }

        > .title {
          color: #4d4d4d;
          width: 100%;
          float: left;
          margin: 15px 0 30px 0;
          text-align: center;
          position: relative;
          padding: 0 0 15px 0;

          &::after {
            content: '';
            background: #f0ad4b;
            width: 50%;
            height: 3px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            max-width: 60px;
          }
        }

        > div {
          float: left;
          width: 100%;
          height: auto;
          padding: 10px 0;

          > .title {
            margin: 0;
            color: #4d4d4d;
            text-align: center;
            float: left;
            width: 100%;
            font-size: 14px;
          }

          > .value {
            display: block;
            float: left;
            width: 100%;
            text-align: center;
            font-size: 20px;
          }
        }
      }
    }

    ${mq.max[1200]} {
      > .chartContainer {
        width: 100%;
      }

      > .infoContainer {
        width: 100%;
        padding: 0;
        margin-top: 10px;
      }
    }
  }

  > .progressContainer {
    float: left;
    width: 100%;
    margin-top: 30px;

    > .header {
      float: left;
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;

      ${mq.max[576]} {
        > .title {
          flex: 1;
        }

        > .date {
          display: none;
        }
      }

      > .title {
        margin: 0;
        font-size: 11pt;
      }

      > div {
        width: 180px;
      }

      .datePicker {
        input {
          font-size: 11pt;
        }

        .dropdownContainer {
          height: 35px;
        }

        .optionsContainer {
          bottom: 35px;
        }
      }
    }

    > .header > .date,
    > .date {
      flex: 1;
      margin: 0;
      text-align: center;
      font-family: var(--f-bo);
      color: var(--accent);
      font-size: 11pt;
    }

    > .date {
      text-align: left;
    }

    .progressSection {
      float: left;
      width: 100%;
      height: auto;
      margin-top: 20px;

      > .info {
        float: left;
        width: 50%;
        font-size: 13px;
        font-family: var(--f-se-bo);
        padding: 0 5px;
        margin: -10px 0 7px 0;

        &.right {
          text-align: right;
        }
      }

      > div {
        background-color: white;
        float: left;
        width: 100%;
        height: 40px;
        overflow: hidden;
        border-radius: 10px;
        position: relative;

        > div {
          position: absolute;
          background: linear-gradient(270deg, #004e42 -5.37%, rgba(196, 196, 196, 0) 115.88%);
          height: 100%;
          left: 0;
          top: 0;
          bottom: 0;
        }
      }
    }
  }
`
