import { useLoader } from 'common/hooks/use-loader'
import { MediaService, ProductService } from 'common/services'
import { MonacoChainAndLockPicture } from 'pages/private/products/list/monacoList/lib/components'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { Icon } from '../icon'
import * as Lib from './lib'
import { typeLocalizer } from '../../../helpers/localization'

export const ImageListSlider: FC<Lib.T.ImageSliderProps> = ({
  imageDetail,
  baseUrl,
  hideCloseIcon,
  handleRemove,
  activeIndex,
  addToCard,
  alreadyAddedProducts,
  setAlreadyAddedProducts,
  fromProductList
}) => {
  const { setLoader } = useLoader()
  const [pieces, setPieces]:any = useState()
  const { get, on, set } = Lib.H.useImageSlider(imageDetail, activeIndex)
  const [productsAdded, setProductsAdded] = useState(alreadyAddedProducts ?? undefined)
  const arrow = <Icon name="arrow_2_left" size={20} color="#fff" />

  const handleDownload = async () => {
    const service = new MediaService()
    setLoader(true) 
    const { data } = await service.download(imageDetail[get.active]?.productImageLink.trim())
    const url = URL.createObjectURL(data)
    // Create a temporary link element
    const link = document.createElement('a')
    link.href = url
    link.download = imageDetail[get.active].productCode

    // Programmatically click the link to initiate the download
    link.click()

    // Clean up the temporary URL and link element
    URL.revokeObjectURL(url)
    link.remove()
    setLoader(false)
  }

  useEffect(() => {
    set.setActive(activeIndex)
  }, [activeIndex])

  const handleKeyDown = useCallback(
    async (event: any) => {
      if (event.key === 'ArrowLeft') {
        on.change(false)
      } else if (event.key === 'ArrowRight') {
        on.change(true)
      } else if (
        event.key === 'Enter' &&
        productsAdded?.length &&
        !productsAdded.includes(imageDetail[get.active]?.productId as number) &&
        addToCard &&
        setAlreadyAddedProducts &&
        productsAdded
      ) {
        await addToCard(imageDetail[get.active].productId as number)
        // @ts-ignore
        setProductsAdded(perv => [...perv, imageDetail[get.active]?.productId])
        setAlreadyAddedProducts(perv => [...perv, imageDetail[get.active]?.productId as number])
      }
    },
    [get.active, imageDetail, productsAdded],
  )

  useEffect(() => {
    // Add event listeners for keyboard events
    window.addEventListener('keydown', handleKeyDown)

    // Remove event listeners on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [get.active, productsAdded, handleKeyDown])

  useEffect(() => {
    (async () => {
      const service = new ProductService()
      imageDetail[get.active]?.productId && fromProductList && await service.findOne(imageDetail[get.active]?.productId as number).then(result => {
        if (result.data) {
          setPieces(result.data?.pieces)
        }
      })
    })()
  }, [imageDetail[get.active]?.productId, fromProductList])

  const monacoImageWidth = useMemo(() => 600, [])

  return (
    <Lib.S.imageSlider>
      {!hideCloseIcon && (
        <span className="close" onClick={() => handleRemove && handleRemove(get.active)}>
          <Icon name="trash_bin" color="black" size={20} />
        </span>
      )}
      <div style={{ position: 'relative', height: '100%', width: '100%', borderRadius: '9px' }}>
        {/* <span className="imgHolder" style={{ backgroundImage: `url(${baseUrl ? baseUrl + images[get.active] : images[get.active]})` }} /> */}
        <div className='d-flex'>
          <TransformWrapper key={2}>
            <TransformComponent
              key={2}
              wrapperStyle={{
                justifyContent: 'center',
                height: imageDetail[get.active].lockImage && imageDetail[get.active].chainImage ? 500 : 'unset',
              }}
              contentStyle={{ justifyContent: 'center', alignItems: 'center' }}
            >
              {imageDetail[get.active].lockImage && imageDetail[get.active].chainImage ? (
                <div style={{ marginRight: monacoImageWidth / 5 }}>
                  <MonacoChainAndLockPicture
                    chainImagePath={imageDetail[get.active].chainImage!}
                    lockImagePath={imageDetail[get.active].lockImage!}
                    width={monacoImageWidth}
                  />
                </div>
              ) : (
                <img
                  src={baseUrl ? imageDetail[get.active]?.image : imageDetail[get.active]?.image}
                  alt=""
                  style={{ height: '83vh', objectFit: 'contain' }}
                  className="image"
                  onError={e => (e.currentTarget.src = '/images/jpg/placeholder.jpg')}
                />
              )}
            </TransformComponent>
          </TransformWrapper>
          {pieces && <div className='p-3 me-4' style={{width: 200}}>
            <h6 className='text-center fw-bold m-0'>Parçalar</h6>
            <img src='/images/svg/line.svg' className='mb-3' alt='' />
            <section>
              {pieces?.map((item: any) => {
                return (
                  <div className='piece py-1 px-2 mb-3 rounded-3'>
                    <div className='d-flex justify-content-between fw-bold'>
                      <span>{typeLocalizer(item.type)}:</span>
                      <span style={{color: '#004e42'}}>{item.weight} gr</span>
                    </div>
                    {item?.pieceTranslates.length > 0 && item?.pieceTranslates?.map((translate:any) => {
                      return translate.lang === 'tr' && translate.translateDescription ? <div className='border-top border-[#EEE] mt-2 pt-1 text-muted'>{translate.translateDescription}</div> : ''
                    })}
                  </div>
                )
              })}
              <div className='d-flex justify-content-between fw-bold totalWeight text-light py-1 px-2 mb-3 rounded-3'>
                <span>Ağırlık:</span>
                <span>{imageDetail[get.active]?.weight} gr</span>
              </div>
            </section>
          </div>}
        </div>
        {imageDetail?.length > 1 && (
          <>
            <div onClick={() => on.change(false)} className={`${get.active === 0} left arrow`}>
              {arrow}
            </div>
            <div onClick={() => on.change(true)} className={`${get.active === imageDetail.length - 1} right arrow`}>
              {arrow}
            </div>
          </>
        )}
      </div>

      <div className="footer">
        {imageDetail[get.active]?.productCode && imageDetail[get.active]?.weight ? (
          <p style={{ textAlign: 'left', margin: 0, fontWeight: 'bold', fontSize: '1rem', color: 'var(--accent)' }}>
            <span style={{ marginRight: '30px' }}>Ürün kodu : {imageDetail[get.active]?.productCode}</span>
            {/*<span>Ağırlık : {imageDetail[get.active]?.weight}</span>*/}
          </p>
        ) : null}

        {addToCard && setAlreadyAddedProducts && alreadyAddedProducts ? (
          <div>
            {productsAdded?.includes(imageDetail[get.active]?.productId as number) ? (
              <Icon name="cart" size={22} color="var(--accent)" style={{ cursor: 'not-allowed' }} />
            ) : (
              <Icon
                name="cart_outline"
                size={22}
                color="var(--accent)"
                style={{ cursor: 'pointer' }}
                onClick={async () => {
                  await addToCard(imageDetail[get.active]?.productId as number)
                  setProductsAdded(perv => [...perv!, imageDetail[get.active]?.productId as number])
                  setAlreadyAddedProducts(perv => [...perv, imageDetail[get.active]?.productId as number])
                }}
              />
            )}
          </div>
        ) : null}
        {!imageDetail[get.active].lockImage || !imageDetail[get.active].chainImage ? (
          <Icon name="save" size={21} color="var(--accent)" onClick={handleDownload} style={{ cursor: 'pointer', margin: '0 10px' }} />
        ) : null}
      </div>
    </Lib.S.imageSlider>
  )
}
