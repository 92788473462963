import { FC, KeyboardEvent } from 'react'
import * as Lib from './lib'

export const Textarea: FC<Lib.T.TextareaProps<string | symbol>> = ({
  label,
  containerStyles,
  minHeight,
  lessBorder,
  autoFocus,
  onEnter,
  labelStyles,
  textareaStyles,
  ...inputAttrs
}): JSX.Element => {
  const onKeyUp = (evt: KeyboardEvent<HTMLTextAreaElement>) => {
    if ((evt.keyCode === 10 || evt.keyCode === 13) && onEnter) {
      if (onEnter) {
        onEnter()
      }
    }
  }

  return (
    <>
      <Lib.S.inputContainer style={containerStyles} minHeight={minHeight}>
        <label>
          {label && (
            <span className="label" style={labelStyles}>
              {label}
              {inputAttrs.required && <span style={{ color: '#D32738' }}>*</span>}
            </span>
          )}

          <textarea
            autoFocus={autoFocus}
            {...inputAttrs}
            style={{ borderWidth: lessBorder ? '1px' : '3px', ...textareaStyles }}
            onKeyUp={onKeyUp}
          >
            {inputAttrs.value}
          </textarea>
        </label>
      </Lib.S.inputContainer>
    </>
  )
}
