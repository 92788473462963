import { SavedUser } from 'common/typing/saved-user.interface'
import { RootState } from 'common/store/root-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { Crypto } from 'common/helpers/crypto.helper'
import { Global } from 'common/store/actions'
import { useLogout } from './use-logout'
import { Storage } from 'common/helpers/storage'

export type UseAccessToken = {
  session: SavedUser | null
  hasPermission: boolean
}

export const initialValues: UseAccessToken = {
  session: null,
  hasPermission: false,
}

export const useAccessToken = (): UseAccessToken => {
  const globalStates = useSelector((state: RootState) => state.globals)
  const dispatch = useDispatch()
  const logOut = useLogout()

  try {
    const accessToken = globalStates?.user?.accessToken
    const userInStorage = Storage.get<SavedUser | null>('user')

    if (!accessToken) {
      if (userInStorage) {
        if (userInStorage.accessToken) {
          const user: SavedUser = {
            ...userInStorage,
          }
          dispatch(Global.saveUser(user))
          return {
            session: user,
            hasPermission: true,
          }
        } else {
          logOut()
        }
      } else {
        logOut()
      }
    } else {
      return {
        session: globalStates.user,
        hasPermission: true,
      }
    }
  } catch (error: any) {
    throw new Error(error)
  }

  return initialValues
}
